import React, { Fragment, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  Button,
  Col,
  Label,
  Row,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Progress,

} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";
import scrollToComponent from "react-scroll-to-component";

import { dctAlert, dctClsAddRemove } from "../../../helpers/Commons";
import {
  dctPutDosespotData,
  dctPostDosespotData,
  dctGetDosespotItems,
} from "../../../helpers/backend_helper";

import { Modal } from "./../../DoctegrityModal/DoctegrityModal";
import axios from "axios";
import { Config } from "../../../Config";
// const qs = require('qs');

function Documents(props) {
  let DCformSection = React.createRef();

  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState(false);

  const dispatch = useDispatch();
  const { authMemberId, auth_token, authUser } = useSelector((state) => state.Login);

  const [openDocument, setOpenDocument] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openDocUploader, setOpenDocUploader] = useState(true);
  const [modal, setModal] = useState(false);
  const [gImg, setGImg] = useState({
    src: "",
    alt: ""
  });


  const [documents, setDocuments] = useState({
    document: "",
    title: "",
    description: "",
  });
  const [selectedFiles, setselectedFiles] = useState([]);
  const [uploadPercent, setuploadPercent] = useState({ percent: 0 });

  const [_documentList, _setDocumentList] = useState({
    data: [],
    loading: true,
  });
  const [_singleDocument, _setSingleDocument] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  useEffect(() => {
    if (props.location.hash === "#document") {
      setOpenDocument(true);
    }
    getDocumentById();
  }, []);

  const getDocumentById = async () => {
    let url = `member/${authMemberId}/document?populate=false`;
    if (props.patientType === "dependent") {
      url = `dependent/${props.activePatientId}/document?populate=false`;
    }
    const response = await dctGetDosespotItems(url, "").then((data) => data);

    let responseData = response;
    if (responseData && responseData.length > 0) {
      _setDocumentList({
        data: responseData,
        loading: false,
      });
    } else {
      _setDocumentList({
        data: null,
        loading: false,
      });
    }
  };

  const handleDocumentSubmit = async (event, values) => {
    setFormErrorMsg(false);
    let doc = values.doc ? values.doc[0] : "";
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
      onUploadProgress: (progressEvent) => {
        var percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percent >= 100) {
          setuploadPercent({ percent: 100 });
        } else {
          setuploadPercent({ percent });
        }
      },
    };

    dctClsAddRemove("dr-search-spinner", "d-none", "remove");

    let bodyFormData = new FormData();
    bodyFormData.append("title", values.title);
    bodyFormData.append("description", values.description);
    bodyFormData.append("memberId", values.memberId);
    if (values.patientType === "dependent") {
      bodyFormData.append("dependentId", values.dependentId);
    }
    if (doc) {
      bodyFormData.append("document", doc);
    }

    dctClsAddRemove("dr-search-spinner", "d-none", "remove");

    
    let response = "";
    if (values.action === "edit" && values.id) {
      let docId = values.id;
      let url = `${process.env.REACT_APP_API_PATH}/document/${docId}`;
      response = await dctPutDosespotData(url, bodyFormData, headers).then(
        (data) => data
      );
    } else {
      // For create action
      let url = `${process.env.REACT_APP_API_PATH}/document`;
      response = await dctPostDosespotData(url, bodyFormData, headers).then(
        (data) => data
      );

    }

    if (response) {
      if (response.id) {
        dctClsAddRemove("dr-search-spinner", "d-none", "add");
        let message = props.t(`Document created succuessfully.`);
        setSuccessMsg(message);
        setFormErrorMsg("");
        //Call the Get Document method
        getDocumentById();
        setOpenEditForm(!openEditForm);
      }
      if (response.status >= 400 || !response.id || response.id == '') {
        dctClsAddRemove("dr-search-spinner", "d-none", "add");
        setFormErrorMsg(
          response && response.data
            ? response.data.message
            : "Something went wrong. Please try again."
        );
      }
    } else {
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      setFormErrorMsg(
        response && response.data
          ? response.data.message
          : "Something went wrong. Please try again."
      );
      setSuccessMsg("");
    }
  };

  const renderDocThumb = (data) => {
    if (!data) return;

    if (data.docType === "application/pdf") {
      return (
        <h2 className={`${Config.names.slugName}-upload-preview-icon mb-0`}>
          <a href={data.docUrl} download>
            <i className="bx bxs-file-pdf mr-1"></i>
          </a>
        </h2>
      );
    } else {
      return (
        <img
          style={{ cursor: 'pointer' }}
          data-dz-thumbnail=""
          height="100"
          className="avatar-sm rounded bg-light"
          alt={data.title}
          src={data.docUrl}
          onClick={() => {
            setGImg({
              src: data.docUrl,
              alt: data.title
            })
            setModal(true)
          }}
        />

      );
    }
  };
  const documentItemsBody = (_documentList) => {
    return _documentList && _documentList.length > 0 ? (
      _documentList.map((data, index) => {
        return (
          <Tr key={index} data-row-id={data.id}>
            <Td>{renderDocThumb(data)}</Td>
            <Td>{data.title}</Td>
            <Td>{data.description}</Td>
            <Td>
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      editDocument(data);
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                  </span>
                </li>
              </ul>
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="4" className="text-center"></Td>
      </Tr>
    );
  };

  const editDocument = (data) => {
    setOpenDocument(true);
    setOpenEditForm(true);
    setOpenDocUploader(false);
    setDocuments({
      docUrl: data && data.docUrl ? data.docUrl : "",
      title: data && data.title ? data.title : "",
      description: data && data.description ? data.description : "",
    });
    _setSingleDocument(data);

    if (openEditForm) {
      scrollToComponent(DCformSection.current, {
        offset: 0,
        align: "middle",
        duration: 500,
        ease: "inExpo",
      });
    }
  };

  const onEntered = () => {
    scrollToComponent(DCformSection.current, {
      offset: 0,
      align: "middle",
      duration: 500,
      ease: "inExpo",
    });
  };
  const { percent } = uploadPercent;

  return (
    <Fragment>
      <Card className="mb-0 mt-3">
        <CardHeader
          className="p-3 bg-white cursor-pointer"
          onClick={() => {
            setOpenDocument(!openDocument);
          }}
        >
          <div className="float-right ml-2 dropdown show">
            <i aria-haspopup="true" className="text-muted" aria-expanded="true">
              <i className="mdi mdi-square-edit-outline font-size-18"></i>
            </i>
          </div>

          <div className="d-flex align-items-center">
            <div className={`avatar-xs mr-3 ${Config.names.slugName}-desktop`}>
              <span className="avatar-title text-white rounded-circle font-size-18">
                <i className="mdi mdi-cloud-upload"></i>
              </span>
            </div>
            <div className="head">
              <h6 className="mb-1 font-14 font-weight-bold text-uppercase">
                {props.t(`Upload Documents`)}
              </h6>
              <Label className="mb-0 font-size-14 font-weight-normal">
                {props.t(
                  `Indicate the drug type, dosage, and frequency of any medications you have taken/are currently taking.`
                )}
              </Label>
            </div>
          </div>
        </CardHeader>
      </Card>

      <Collapse isOpen={openDocument}>
        <Card>
          <CardBody className="mb-0">
            {errorMsg ? dctAlert(3, errorMsg) : ""}
            {successMsg ? dctAlert(1, successMsg) : ""}

            <Fragment>
              <Row>
                <Col lg={12} className="mb-3">
                  {_documentList?.data?.length == undefined ? <p className="font-size-12 text-muted">
                    {props.t(`You have no documents on record. Click "Upload Documents" button below to add`)}
                  </p> : ""}
                </Col>
              </Row>
              <div className={`${Config.names.slugName}-sm--15`}>
                <Row>
                  <Col lg={12} className="mb-3">
                    <div className="table-responsive">
                      <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table ${Config.names.slugName}-odd-even`}>
                        <Thead className="thead-light">
                          <Tr>
                            <Th scope="col" style={{ width: "200px" }}>
                              {props.t("Document")}
                            </Th>
                            <Th scope="col">{props.t("Title")}</Th>
                            <Th scope="col">{props.t("Description")}</Th>
                            <Th scope="col" style={{ width: "70px" }}>
                              {props.t("Action")}
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>{documentItemsBody(_documentList?.data)}</Tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </div>
            </Fragment>

            <div>
              <Button
                color="primary"
                onClick={() => {
                  setOpenEditForm(!openEditForm);
                  _setSingleDocument([]);
                  setOpenDocUploader(true);
                }}
                style={{ marginBottom: "1rem" }}
              >
                {props.t(`Upload Documents`)}
              </Button>

              <Collapse isOpen={openEditForm} onEntered={onEntered}>
                <div className="mt-2" ref={DCformSection}>
                  <h4>{props.t(`Upload Documents`)}</h4>
                  <AvForm
                    className="form-horizontal needs-validation"
                    onValidSubmit={(e, v) => {
                      handleDocumentSubmit(e, v);
                    }}
                  >
                    {_singleDocument && _singleDocument.id ? (
                      <div>
                        <AvField name="action" type="hidden" value="edit" />
                        <AvField
                          name="id"
                          type="hidden"
                          value={
                            _singleDocument && _singleDocument.id
                              ? _singleDocument.id
                              : ""
                          }
                        />

                        <Row>
                          <Col lg={6}>
                            {renderDocThumb(_singleDocument)}
                            <Button
                              color="secondary"
                              type="button"
                              className="ml-3"
                              onClick={() => {
                                setselectedFiles([]);
                                setOpenDocUploader(true);
                              }}
                            >
                              {props.t(`Change Document`)}
                            </Button>
                          </Col>
                          <Col lg={6}></Col>
                        </Row>
                      </div>
                    ) : null}

                    <AvField
                      name="memberId"
                      type="hidden"
                      value={authMemberId}
                    />
                    {props.patientType === "dependent" ? (
                      <AvField
                        name="dependentId"
                        type="hidden"
                        value={props.activePatientId}
                      />
                    ) : null}
                    <AvField
                      name="patientType"
                      type="hidden"
                      value={props.patientType}
                    />

                    <AvField
                      name="doc"
                      className="form-control"
                      type="hidden"
                      value={selectedFiles ? selectedFiles : ""}
                    />

                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="title"
                          label={props.t("Your Document Title")}
                          className="form-control"
                          placeholder={props.t("Your Document Title")}
                          type="text"
                          required
                          value={
                            _singleDocument && _singleDocument.title
                              ? _singleDocument.title
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="description"
                          label={props.t("Your Document Description")}
                          className="form-control"
                          placeholder={props.t("Your Document Description")}
                          type="text"
                          required
                          value={
                            _singleDocument && _singleDocument.description
                              ? _singleDocument.description
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    {openDocUploader ? (
                      <Row>
                        <Col lg={12}>
                          <Dropzone
                            required
                            accept={process.env.REACT_APP_ACCEPTED_DOC_FILES}
                            maxSize={parseInt(
                              process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE
                            )}
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                  </div>
                                  <h4>
                                    {props.t(
                                      "Drop files here or click to upload"
                                    )}
                                    .
                                  </h4>
                                  <div className="font-size-14 text-danger">
                                    {props.t(
                                      "Only *.jpeg, *.jpg, *.png, *.gif and *.pdf files will be accepted."
                                    )}
                                  </div>
                                  <p className="font-size-14 text-danger">
                                    {props.t("Max file 20MB.")}
                                  </p>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div className="mt-3 d-none">
                            <Progress color="primary" value={percent}>
                              {percent}%
                            </Progress>
                          </div>

                          <div
                            className="dropzone-previews mt-3 mb-4"
                            id="file-previews"
                          >
                            {selectedFiles.map((f, i) => {
                              return (
                                <div
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        {f.type === "application/pdf" ? (
                                          <h2 className={`${Config.names.slugName}-upload-preview-icon mb-0`}>
                                            <i className="bx bxs-file-pdf mr-1"></i>
                                          </h2>
                                        ) : (
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        )}
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      <Col lg={12}>
                        {formErrorMsg ? dctAlert(3, formErrorMsg) : ""}
                        <div className="form-group text-right">
                          <button
                            type="button"
                            className="btn btn-secondary mr-2"
                            onClick={() => setOpenEditForm(!openEditForm)}
                          >
                            {props.t("Cancel")}
                          </button>

                          {selectedFiles[0] ? (
                            <Button
                              color="primary"
                              type="submit"
                              onClick={() => { }}
                            >
                              <span className="dr-search-spinner d-none">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                              </span>{" "}
                              {props.t(`Save`)}
                            </Button>
                          ) : (
                            <Button
                              disabled={true}
                              color="primary"
                              type="submit"
                              onClick={() => { }}
                            >
                              <span className="dr-search-spinner d-none">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                              </span>{" "}
                              {props.t(`Save`)}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </div>
              </Collapse>
            </div>
          </CardBody>
        </Card>
      </Collapse>
      <Modal
        centered={true}
        size="xl"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
      >
        <img
          height="auto"
          alt={gImg.alt}
          src={gImg.src}
        />
      </Modal>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(Documents))
);
