import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
  Label,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

import AsyncSelect from "react-select/async";

import debounce from "lodash/debounce";

import {
  createPartner,
  updatePartner,
  createPartnerFailed,
  partnerActionSuccessful,
  setSinglePartner,
} from "../../store/actions";

//Include Both Helper File with needed methods
import { dctGetAsyncData } from "../../helpers/backend_helper";
import { Config } from "../../Config";

const CreateUpdatePartner = (props) => {
  const [modal, setModal] = useState(false);
  const [msgEnable, setMsgEnable] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);

  let partnerData = useSelector((state) => state.Partner.singlePartner);
  partnerData = partnerData ? partnerData : [];
  useEffect(() => {
    if (partnerData.companyId) {
      setSelectedCompany(partnerData.companyId);
    }
  }, [partnerData]);

  // Load the fetched Company data for the select dropdown
  const loadCompanyOptions = (inputValue, callback) => {
    const axiosArgs = {
      params: inputValue,
      URL:
        process.env.REACT_APP_API_PATH +
        "/company/names/?limit=3000&fields=companyName&q=" +
        inputValue,
    };
    dctGetAsyncData(axiosArgs).then((results) => callback(results));
    return;
  };
  const companyListOptions = debounce(loadCompanyOptions, 250);

  const handleCompanyChange = (company) => {
    setSelectedCompany(company);
    if (!company || company.id == "") {
      document.querySelector("#select-company").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-company").classList.remove("text-danger");
  };

  function toggleModalClose(e) {
    if (props.singlePartner) {
      props.updatePartnerDataFlag();
    }
  }

  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-cform-spinner")[0]
      .classList.remove("d-none");

    setMsgEnable(true);

    if (!selectedCompany || selectedCompany.id == "") {
      document.querySelector("#select-company").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-company").classList.remove("text-danger");
    values.companyId = selectedCompany.id;

    //call reducer method
    if (values.actionType === "edit") {
      props.updatePartner(values, props.history);
    } else {
      props.createPartner(values, props.history);
      // props.fetchPartnerData("", 1)
    }
  }

  const dispatch = useDispatch();

  const editFormPartnerItem = (e) => {
    setMsgEnable(false);
    dispatch(partnerActionSuccessful(null, "", ""));
  };

  const createFormPartnerItem = (e) => {
    setMsgEnable(false);
    dispatch(partnerActionSuccessful(null, "", ""));
    dispatch(setSinglePartner(null, "", ""));
  };

  const action_type = props.partner_action_type === "edit" ? "edit" : "create";
  const button_label =
    props.partner_action_type === "edit" ? "Update" : "Create  Partner";
  const modal_title =
    props.partner_action_type === "edit"
      ? "Update  Partner"
      : "Create  Partner";
  return (
    <React.Fragment>
      {(() => {
        switch (props.partner_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="partner_update_modal"
                onClick={() => {
                  setModal(!modal);
                  editFormPartnerItem();
                }}
              ></i>
            );
          case "edit_btn":
            return (
              <Button
                type="button"
                color="primary"
                id=" partner_update_modal"
                className="waves-effect waves-light mr-2"
                onClick={() => {
                  setModal(!modal);
                  editFormPartnerItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1"></i>
                {props.t("Edit ")}
              </Button>
            );
          default:
            return (
              <Link
                onClick={() => {
                  setModal(!modal);
                  createFormPartnerItem();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id=" partner_create_modal"
              >
                <i className="mdi mdi-plus mr-1"></i>
                {props.t("Create Partner")}
              </Link>
            );
        }
      })()}

      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-partner-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.createPartnerError &&
              props.createPartnerError &&
              msgEnable ? (
                <Alert color="danger">{props.createPartnerError}</Alert>
              ) : null}
              {props.message && props.message && msgEnable ? (
                <Alert color="success">{props.message}</Alert>
              ) : null}

              {action_type === "edit" ? (
                <AvField name="id" type="hidden" value={partnerData.id} />
              ) : (
                ""
              )}
              <AvField name="actionType" type="hidden" value={action_type} />

              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvGroup>
                      <Label for="example">
                        {props.t("First Name")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={partnerData.userId?.firstName}
                        name="firstName"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("First Name")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-company">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Last Name")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={partnerData.userId?.lastName}
                        name="lastName"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Last Name")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Email")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={partnerData.userId?.email}
                        name="email"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Email")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <AvGroup id="select-company">
                    <div className="form-group">
                      <label htmlFor="companyId">{props.t("Company")}</label>{" "}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                      <AsyncSelect
                        placeholder="Select Company"
                        name="companyId"
                        id="companyId"
                        getOptionLabel={(e) => e.companyName}
                        getOptionValue={(e) => e.id}
                        className={`${Config.names.slugName}-custom-select-box`}
                        cacheOptions
                        defaultOptions
                        loadOptions={companyListOptions}
                        value={selectedCompany}
                        onChange={handleCompanyChange}
                        required
                      />
                    </div>
                  </AvGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setModal(!modal)}
                    >
                      {props.t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                    >
                      <span className="dr-cform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      {button_label}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createPartnerError } = state.Partner;
  const { partner } = state.Partner;
  const { message } = state.Partner;
  return { createPartnerError, partner, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createPartner,
    updatePartner,
    createPartnerFailed,
    setSinglePartner,
  })(withNamespaces()(CreateUpdatePartner))
);

// export default withRouter(
//   connect(mapStatetoProps, { getSingleMember })(
//     withNamespaces()(MyAccount)
//   )
