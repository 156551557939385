import React, { Fragment, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  Button,
  Col,
  Label,
  Row,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import scrollToComponent from "react-scroll-to-component";

import {
  dctAlert,
  dctClsAddRemove,
  medicalHistoryStatuses,
} from "../../../helpers/Commons";
import {
  dctPostDosespotData,
  dctGetDosespotItems,
  dctPutDosespotData,
} from "../../../helpers/backend_helper";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Config } from "../../../Config";

function MedicalHistory(props) {
  let MHformSection = React.createRef();

  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState(false);

  const dispatch = useDispatch();
  const { authMemberId, auth_token } = useSelector((state) => state.Login);

  const [openMedicalHistory, setOpenMedicalHistory] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const pathId = props.pathId;

  const [medicalHistory, setMedicalHistory] = useState({
    conditionName: "",
    description: "",
    status: "",
  });

  const [_medicalHistoryList, _setMedicalHistoryList] = useState({
    data: [],
    loading: true,
  });
  const [_singleMedicalHistory, _setSingleMedicalHistory] = useState([]);

  useEffect(() => {
    if (props.location.hash === "#medicalhistory") {
      setOpenMedicalHistory(true);
    }
    getMedicalHistoryById();
  }, []);

  const getMedicalHistoryById = async () => {
    let url = `member/${pathId}/medicalhistory `;
    if (props.patientType === "dependent") {
      url = `dependent/${props.activePatientId}/medicalhistory `;
    }
    const response = await dctGetDosespotItems(url, "").then((data) => data);

    let responseData = response;
    if (responseData && responseData.length > 0) {
      _setMedicalHistoryList({
        data: responseData,
        loading: false,
      });
    } else {
      _setMedicalHistoryList({
        data: null,
        loading: false,
      });
    }
  };

  const handleMedicalHistorySubmit = async (event, values) => {
    setFormErrorMsg(false);
    const headers = {
      headers: {
        Authorization: auth_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    dctClsAddRemove("dr-search-spinner", "d-none", "remove");
    let response = "";
    // For edit action
    if (values.action === "edit" && values.id) {
      let editId = values.id;
      let url = `${process.env.REACT_APP_API_PATH}/medicalhistory/${editId}`;
      response = await dctPutDosespotData(url, values, headers).then(
        (data) => data
      );
    } else {
      // For create action
      let url = `${process.env.REACT_APP_API_PATH}/medicalhistory`;
      response = await dctPostDosespotData(url, values, headers).then(
        (data) => data
      );
    }

    if (response.id) {
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      let message = props.t(`Medical History record created succuessfully.`);
      setSuccessMsg(message);
      setFormErrorMsg("");
      //Call the Get Medication method
      getMedicalHistoryById();
      setOpenEditForm(!openEditForm);
    } else {
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      setFormErrorMsg(
        response && response.data
          ? response.data.message
          : "Something went wrong. Please try again."
      );
      setSuccessMsg("");
    }
  };

  //Medical History body
  const medicalHistoryItemsBody = (_medicalHistoryList) => {
    return _medicalHistoryList && _medicalHistoryList.length > 0 ? (
      _medicalHistoryList.map((data, index) => {
        return (
          <Tr key={index} data-row-id={data.id}>
            <Td>{data.conditionName}</Td>
            <Td>{data.description}</Td>
            <Td>{medicalHistoryStatuses[data.status - 1]}</Td>
            {/* <Td>
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      editMedicalHistory(data);
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                  </span>
                </li>
              </ul>
            </Td> */}
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="4" className="text-center"></Td>
      </Tr>
    );
  };

  const editMedicalHistory = (data) => {
    setOpenMedicalHistory(true);
    setOpenEditForm(true);
    setMedicalHistory({
      conditionName: data && data.conditionName ? data.conditionName : "",
      description: data && data.description ? data.description : "",
      status: data && data.status ? data.status : "",
    });
    _setSingleMedicalHistory(data);

    if (openEditForm) {
      scrollToComponent(MHformSection.current, {
        offset: 0,
        align: "middle",
        duration: 500,
        ease: "inExpo",
      });
    }
  };
  const onEntered = () => {
    scrollToComponent(MHformSection.current, {
      offset: 0,
      align: "middle",
      duration: 500,
      ease: "inExpo",
    });
  };

  return (
    <Fragment>
      {errorMsg ? dctAlert(3, errorMsg) : ""}
      {successMsg ? dctAlert(1, successMsg) : ""}
      <Fragment>
        {_medicalHistoryList?.data ? (
          <div className="${Config.names.slugName}-sm--15">
            <Row>
              <Col lg={12} className="mb-1">
                <div className="table-responsive">
                  <Table
                    className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table ${Config.names.slugName}-odd-even`}
                  >
                    <Thead className="thead-light">
                      <Tr>
                        <Th scope="col">{props.t("Condition Name")}</Th>
                        <Th scope="col">{props.t("Description")}</Th>
                        <Th scope="col" style={{ width: "100px" }}>
                          {props.t("Status")}
                        </Th>
                        {/* <Th scope="col" style={{ width: "70px" }}>
                          {props.t("Action")}
                        </Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {medicalHistoryItemsBody(_medicalHistoryList?.data)}
                    </Tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <span className="d-flex justify-content-center text-danger">
            User has no medication history
          </span>
        )}
      </Fragment>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(MedicalHistory))
);
