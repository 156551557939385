import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, FormGroup, Alert } from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Modal } from "../../../components/DoctegrityModal";

import { AvForm, AvInput, AvField } from "availity-reactstrap-validation";

import {
  updateConsultStepwise,
  getSingleConsultation,
  getMemberAndDependents,
  getDraftConsultation,
} from "../../../store/actions";

import {
  dctDateFormat,
  dctGetMemberAndDependPastData,
  loadingMessage,
  dctAlert,
  dctClsAddRemove,
  capitalizeWords,
} from "../../../helpers/Commons";
import ConsultFormFooter from "./consultFormFooter";
import ViewConsent from "./ViewConsent";
import { Config } from "../../../Config";

function EHR(props) {
  const [isEhrFormError, setIsEhrFormError] = useState(false);
  const [viewconsentmodal, setviewconsentmodal] = useState(false);
  const [customeModalClassName, setCustomModalClassName] = useState("");

  let memberObj = [];

  useEffect(() => {
    props.pageTitle(
      "A Diagnostic Consultation requires valid and up-to-date Electronic Health Records."
    );
  }, []);

  function tog_scroll() {
    setviewconsentmodal(!viewconsentmodal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  //Update the consultation
  function handleValidSubmit(event, values) {
    setIsEhrFormError(false);
    if (!values.ehr_acceptEMR || !values.ehr_acceptInformedConsent) {
      setIsEhrFormError(true);
      return;
    }
    dctClsAddRemove("dr-form-spinner", "d-none", "remove");
    props.updateConsultStepwise(values, props.history, 2);
  }

  memberObj = useSelector((state) => state.Member);
  const { singleConsult, draftConsult } = memberObj;
  const model = {
    ehr_acceptEMR: singleConsult ? singleConsult.ehr_acceptEMR : false,
    ehr_acceptInformedConsent: singleConsult
      ? singleConsult.ehr_acceptInformedConsent
      : false,
  };

  useEffect(() => {
    if (window.reacNativeWebView) {
      setCustomModalClassName("web-view-modal");
    }
  }, []);
  return (
    <Fragment>
      <AvForm
        model={model}
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        {isEhrFormError
          ? dctAlert(3, "Please fill all the requried fields.")
          : ""}

        {!singleConsult ? (
          <div className="mt-1 mb-3">{loadingMessage("Loading...")}</div>
        ) : null}
        <Row>
          <Col lg="12">
            <FormGroup>
              <Col lg={12}>
                {/* <p className="mb-0">
                  {props.t("Health Records Last updateed on")}{" "}
                  {draftConsult ? dctDateFormat(draftConsult?.healthRecordUpdatedDate) : dctDateFormat(singleConsult?.healthRecordUpdatedDate)}
                </p> */}
                <Row className="mt-2">
                  <Col md={12}>
                    <Alert color="info" className="text-dark mb-2">
                      <Link
                        to="/member/health-records"
                        className={`text-dark ${Config.names.slugName}-text-underline font-weight-medium`}
                      >
                        {props.t(`Click here`)}
                      </Link>{" "}
                      {props.t(
                        `if you'd like to make an update to your EHRs, otherwise continue to Step 3`
                      )}
                    </Alert>
                  </Col>
                  <Col md={12} className="ml-auto">
                    <div className="mt-2 mt-lg-0">
                      <AvField name="step" type="hidden" value="2" />
                      <AvField
                        name="id"
                        type="hidden"
                        value={singleConsult ? singleConsult.id : ""}
                      />
                      <AvField name="stepType" type="hidden" value="ehr" />

                      <p className="form-check mb-3">
                        <AvInput
                          className="form-check-input"
                          type="checkbox"
                          name="ehr_acceptEMR"
                          required
                        />
                        <label
                          className="form-check-label ml-1 font-weight-normal"
                          htmlFor="ehr_acceptEMR"
                        >
                          {props.t(
                            `I certify that the Electronic Medical Records of`
                          )}{" "}
                          {
                            <strong>
                              {singleConsult ? capitalizeWords(singleConsult.patient) : null}
                            </strong>
                          }{" "}
                          {props.t(
                            `are up-to-date to the best of my knowledge.`
                          )}
                        </label>
                      </p>

                      <div className="form-check mb-3">
                        <AvInput
                          className="form-check-input"
                          type="checkbox"
                          name="ehr_acceptInformedConsent"
                          required
                        />
                        <label
                          className="form-check-label ml-1 font-weight-normal"
                          htmlFor="ehr_acceptInformedConsent"
                        >
                          {props.t(
                            `By selecting this box, I hereby state that I have read, understand, and agree to the terms of the Informed Member Consent.`
                          )}
                        </label>
                      </div>
                      <p className="">
                        <Link
                          to="#"
                          className={`${Config.names.slugName}-text-soft-primary`}
                          onClick={() => {
                            tog_scroll();
                          }}
                          data-toggle="modal"
                        >
                          {props.t(
                            `Click here to view full version of the Informed Member Consent`
                          )}
                        </Link>
                      </p>
                    </div>

                    <Modal
                      className={customeModalClassName}
                      size="lg"
                      isOpen={viewconsentmodal}
                      toggle={() => setviewconsentmodal(!viewconsentmodal)}
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">
                          {props.t(`Telemedicine Informed Patient Consent`)}
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setviewconsentmodal(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <ViewConsent />
                        {!window.reacNativeWebView ? (
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() =>
                                setviewconsentmodal(!viewconsentmodal)
                              }
                            >
                              {props.t(`Close`)}
                            </button>
                          </div>
                        ) : (
                          <div className="modal-footer mb-5">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() =>
                                setviewconsentmodal(!viewconsentmodal)
                              }
                            >
                              {props.t(`Close`)}
                            </button>
                          </div>
                        )}
                      </div>
                    </Modal>

                    <ConsultFormFooter />
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { memberAndDependents, draftConsult, consultation, singleConsult } =
    state.Member;
  return {
    memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    updateConsultStepwise,
    getSingleConsultation,
    getMemberAndDependents,
    getDraftConsultation,
  })(withNamespaces()(EHR))
);
