import { CREATE_SERVICE, UPDATE_SERVICE, GET_SERVICE, GET_SERVICE_LIST, SET_SERVICE_LIST, CREATE_SERVICE_SUCCESS, CREATE_SERVICE_FAILED, API_ERROR, SET_SINGLE_SERVICE, SET_SERVICE_LISTING } from './actionTypes';

export const createService = (service) => ({
    type: CREATE_SERVICE,
    payload: { service }
})

export const updateService = (service) => ({
    type: UPDATE_SERVICE,
    payload: { service }
})

export const getService = (service) => ({
    type: GET_SERVICE,
    payload: { service }
})

export const getServiceList = (service) => ({
    type: GET_SERVICE_LIST,
    payload: { service }
})

export const setServiceList = (service, message, action) => {
    return {
        type: SET_SERVICE_LIST,
        payload: service,
        message: message,
        action_type: action
    }
}


export const setSingleService = (service, message, action) => {
    return {
        type: SET_SINGLE_SERVICE,
        payload: service,
        message: message,
        action_type: action
    }
}

export const setServiceListing = (service, message) => {
    return {
        type: SET_SERVICE_LISTING,
        payload: service,
        serviceListing: service,
        message: message,
    }
}

export const serviceActionSuccessful = (service, message, action) => {
    return {
        type: CREATE_SERVICE_SUCCESS,
        payload: service,
        message: message,
        action_type: action
    }
}

export const createServiceFailed = (error) => {
    return {
        type: CREATE_SERVICE_FAILED,
        payload: error
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}