import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
  Label,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

import AsyncSelect from "react-select/async";

import debounce from "lodash/debounce";

import {
  createCompany,
  updateCompany,
  createCompanyFailed,
  companyActionSuccessful,
  setSingleCompany,
} from "../../store/actions";

//Include Both Helper File with needed methods
import { dctGetAsyncData } from "../../helpers/backend_helper";
import { Config } from "../../Config";

const CreateUpdateCompany = (props) => {
  const [modal, setmodal] = useState(false);
  const [msgEnable, setmsgEnable] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [buttonActive, setButtonActive] = useState(true);
  const [planidGroupCode, setPlanidGroupCode] = useState({
    groupCode: "",
    planId: "",
  });
  const [fulfilmentStatus, setFulfilmentStatus] = useState("");
  const [marblesIntegrationStatus, setMarblesIntegrationStatus] = useState("");
  // const [dctAllow, setDctAllow] = useState("");

  let companyData = useSelector((state) => state.Company.singleCompany);
  companyData = companyData ? companyData : [];

  function setDefaultFulfiment() {
    if (companyData.fulfilment) {
      setFulfilmentStatus(companyData.fulfilment);
    }

    if (companyData?.marblesHRIntegration) {
      setMarblesIntegrationStatus("Yes");
    } else {
      setMarblesIntegrationStatus("No");
    }
  }

  // function setDefaultDctAllow() {
  //   if (companyData.dctAllowAccess) {
  //     setDctAllow(companyData.dctAllowAccess);
  //   }
  // }

  useEffect(() => {
    if (companyData.groupId) {
      setSelectedGroup(companyData.groupId);
    }
    if (companyData.brandId) {
      setSelectedBrand(companyData.brandId);
    }
    setDefaultFulfiment();
    // setDefaultDctAllow();

    if (companyData?.marblesHRIntegration) {
      setMarblesIntegrationStatus("Yes");
    }
  }, [companyData]);

  // Load the fetched Company data for the select dropdown
  const loadGroupOptions = (inputValue, callback) => {
    const axiosArgs = {
      params: inputValue,
      URL:
        process.env.REACT_APP_API_PATH +
        "/group/list/?limit=3000&fields=groupName,groupCode,planId&q=" +
        inputValue,
    };
    dctGetAsyncData(axiosArgs).then((results) => callback(results));
    return;
  };
  const loadBrandOptions = (inputValue, callback) => {
    let axiosArgs = {
      params: inputValue,
      URL:
        process.env.REACT_APP_API_PATH +
        "/brand/?limit=3000&select=name&populate=false" +
        inputValue,
    };
    if (inputValue && inputValue !== "") {
      axiosArgs = {
        params: inputValue,
        URL:
          process.env.REACT_APP_API_PATH +
          "/brand/?limit=3000&select=name&populate=false&q=" +
          inputValue,
      };
    }
    dctGetAsyncData(axiosArgs).then((results) => callback(results.data));
    return;
  };
  const groupListOptions = debounce(loadGroupOptions, 250);
  const brandListOptions = debounce(loadBrandOptions, 250);

  const handleGroupChange = (selectedGroup) => {
    setSelectedGroup(selectedGroup);
    if (!selectedGroup || selectedGroup.id == "") {
      document.querySelector("#select-group").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-group").classList.remove("text-danger");
    if (selectedGroup) {
      setPlanidGroupCode({
        ...planidGroupCode,
        groupCode: selectedGroup.groupCode,
        planId: selectedGroup.planId,
      });
      return;
    }
  };

  const handleBrandChange = (selectedBrand) => {
    setSelectedBrand(selectedBrand);
    if (!selectedBrand || selectedBrand.id == "") {
      document.querySelector("#select-brand").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-brand").classList.remove("text-danger");
  };

  function toggleModalClose(e) {
    if (props.singleCompany) {
      props.updateCompanyDataFlag();
    }
  }

  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-cform-spinner")[0]
      .classList.remove("d-none");

    setmsgEnable(true);

    if (!selectedGroup || selectedGroup.id == "") {
      document.querySelector("#select-group").classList.add("text-danger");
      return;
    }
    if (!selectedBrand || selectedBrand.id == "") {
      document.querySelector("#select-brand").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-group").classList.remove("text-danger");
    values.groupId = selectedGroup.id;
    values.brandId = selectedBrand.id;
    //call reducer method
    if (values.dctAllowAccess === "No") {
      values.dctAllowAccess = false;
    } else {
      values.dctAllowAccess = true;
    }

    if (action_type === "edit") {
      if (values.marblesHRIntegration === "Yes") {
        values.marblesHRIntegration = true;
      } else {
        values.marblesHRIntegration = false;
        values.marblesHRToken = "";
      }
    }

    if (values.actionType === "edit") {
      props.updateCompany(values, props.history);
    } else {
      // values.groupCode = "";
      // values.planId = "";
      values.planDetailId = "";
      props.createCompany(values, props.history);
    }
  }

  const dispatch = useDispatch();

  const editFormCompanyItem = (e) => {
    setmsgEnable(false);
    dispatch(companyActionSuccessful(null, "", ""));
  };

  const createFormCompanyItem = (e) => {
    setmsgEnable(false);
    dispatch(companyActionSuccessful(null, "", ""));
    dispatch(setSingleCompany(null, "", ""));
  };

  const action_type = props.company_action_type === "edit" ? "edit" : "create";
  const button_label =
    props.company_action_type === "edit" ? "Submit" : "Create Company";
  const modal_title =
    props.company_action_type === "edit" ? "Update Company" : "Create Company";

  return (
    <React.Fragment>
      {(() => {
        switch (props.company_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="company_update_modal"
                onClick={() => {
                  setmodal(!modal);
                  editFormCompanyItem();
                }}
              ></i>
            );
          case "edit_btn":
            return (
              <Button
                type="button"
                color="primary"
                id="company_update_modal"
                className="waves-effect waves-light mr-2"
                onClick={() => {
                  setmodal(!modal);
                  editFormCompanyItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1"></i>
                {props.t("Edit ")}
              </Button>
            );
          default:
            return (
              <Link
                onClick={() => {
                  setmodal(!modal);
                  createFormCompanyItem();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id="company_create_modal"
              >
                <i className="mdi mdi-plus mr-1"></i>
                {props.t("Create Company ")}
              </Link>
            );
        }
      })()}

      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setmodal(!modal);
        }}
        scrollable={false}
        className={`${Config.names.slugName}-company-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal(!modal);
            setDefaultFulfiment();
            // setDefaultDctAllow();
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.createCompanyError &&
              props.createCompanyError &&
              msgEnable ? (
                <Alert color="danger">{props.createCompanyError}</Alert>
              ) : null}
              {props.message && props.message && msgEnable ? (
                <Alert color="success">{props.message}</Alert>
              ) : null}

              {action_type === "edit" ? (
                <AvField name="id" type="hidden" value={companyData.id} />
              ) : (
                ""
              )}
              <AvField name="actionType" type="hidden" value={action_type} />

              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Company Name")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={companyData?.companyName}
                        name="companyName"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Company Name")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-company">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Company Email")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={companyData?.companyEmail}
                        name="companyEmail"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Company Email")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <AvGroup id="select-brand">
                    <div className="form-company">
                      <label htmlFor="brandId">{props.t("Brand")}</label>{" "}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                      <AsyncSelect
                        placeholder="Select Brand"
                        name="brandId"
                        id="brandId select-brand"
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.id}
                        className={`${Config.names.slugName}-custom-select-box`}
                        cacheOptions
                        defaultOptions
                        loadOptions={brandListOptions}
                        value={selectedBrand}
                        onChange={handleBrandChange}
                        required
                      />
                    </div>
                  </AvGroup>
                </Col>
                <Col lg={6}>
                  <AvGroup id="select-group">
                    <div className="form-company">
                      <label htmlFor="groupId">{props.t("Group")}</label>{" "}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                      <AsyncSelect
                        placeholder="Select Group"
                        name="groupId"
                        id="groupId select-group"
                        getOptionLabel={(e) => e.groupName}
                        getOptionValue={(e) => e.id}
                        className={`${Config.names.slugName}-custom-select-box`}
                        cacheOptions
                        defaultOptions
                        loadOptions={groupListOptions}
                        value={selectedGroup}
                        onChange={handleGroupChange}
                        required
                      />
                    </div>
                  </AvGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvGroup>
                      <Label for="example">{props.t("Company Type")}</Label>{" "}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                      <AvInput
                        value={companyData?.companyType}
                        name="companyType"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Company Type")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      type="select"
                      name="dctAllowAccess"
                      label="DCT Allow Access"
                      value={
                        action_type === "edit"
                          ? companyData?.dctAllowAccess?.toString() === "false"
                            ? "No"
                            : "Yes"
                          : "No"
                      }
                    >
                      <option>Yes</option>
                      <option>No</option>
                    </AvField>
                  </div>
                </Col>

                {action_type === "edit" && (
                  <>
                    <Col lg={6}>
                      <Label for="example">{props.t("DCT Plan Id")}</Label>
                      <input
                        type="text"
                        placeholder={props.t("DCT Plan Id")}
                        value={companyData?.dctPlanId}
                        className="form-control"
                        disabled
                      />
                    </Col>
                  </>
                )}

                {action_type === "edit" && (
                  <>
                    <Col lg={6}>
                      <div className="form-company">
                        <AvField
                          type="select"
                          name="marblesHRIntegration"
                          label="Marbles HR Integration"
                          value={
                            action_type === "edit"
                              ? companyData?.marblesHRIntegration
                                ? "Yes"
                                : "No"
                              : "No"
                          }
                          onChange={(e) =>
                            setMarblesIntegrationStatus(e.target.value)
                          }
                        >
                          <option>No</option>
                          <option>Yes</option>
                        </AvField>
                      </div>
                    </Col>
                    {marblesIntegrationStatus === "Yes" && (
                      <Col lg={12}>
                        <div className="form-company">
                          <AvGroup>
                            <Label for="example">
                              {props.t("Marbles HR Token")}{" "}
                              <i className="fas fa-star-of-life text-danger custom-star" />
                            </Label>
                            <AvInput
                              value={companyData?.marblesHRToken}
                              name="marblesHRToken"
                              className="form-control"
                              id="example"
                              type="text"
                              placeholder={props.t("Marbles HR Token")}
                              required
                            />
                          </AvGroup>
                        </div>
                      </Col>
                    )}
                  </>
                )}
              </Row>

              {action_type === "edit" ? (
                <>
                  <Row>
                    <Col lg={6}>
                      <div className="form-company">
                        <AvField
                          name="planDetailId"
                          label={props.t("Plan details id")}
                          className="form-control"
                          placeholder={props.t("Plan details id")}
                          type="text"
                          required
                          value={companyData?.planDetailId}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-company">
                        <AvField
                          type="select"
                          name="fulfilment"
                          label="Fulfilment"
                          value={companyData?.fulfilment}
                          onChange={(e) => setFulfilmentStatus(e.target.value)}
                        >
                          <option>Doctegrity</option>
                          <option>MTM</option>
                        </AvField>
                      </div>
                    </Col>
                  </Row>

                  {fulfilmentStatus === "MTM" && (
                    <Row>
                      <Col lg={6}>
                        <div className="form-company">
                          <AvField
                            name="groupCode"
                            label={props.t("MTM Group Code")}
                            className="form-control"
                            placeholder={props.t("MTM Group Code")}
                            type="text"
                            required
                            // value={companyData?.groupCode}
                            value={
                              planidGroupCode.groupCode
                                ? planidGroupCode.groupCode
                                : companyData.groupCode
                            }
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-company">
                          <AvField
                            name="planId"
                            label={props.t("MTM Plan Id")}
                            className="form-control"
                            placeholder={props.t("MTM Plan Id")}
                            type="text"
                            required
                            // value={companyData?.planId}
                            value={
                              planidGroupCode.groupCode
                                ? planidGroupCode.planId
                                : companyData.planId
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <Row>
                  <Col lg={6}>
                    <div className="form-company">
                      <AvField
                        name="groupCode"
                        label={props.t("MTM Group Code")}
                        className="form-control"
                        placeholder={props.t("MTM Group Code")}
                        type="text"
                        disabled
                        value={
                          planidGroupCode.groupCode
                            ? planidGroupCode.groupCode
                            : ""
                        }
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-company">
                      <AvField
                        name="planId"
                        label={props.t("MTM Plan Id")}
                        className="form-control"
                        placeholder={props.t("MTM Plan Id")}
                        type="text"
                        disabled
                        value={
                          planidGroupCode.planId ? planidGroupCode.planId : ""
                        }
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {action_type === "edit" ? (
                <React.Fragment>
                  <Row>
                    <Col lg={12}>
                      <div className="text-center mt-3">
                        <h4 className="font-size-14 text-muted mb-0">
                          {props.t("Other Information")}
                        </h4>
                      </div>
                    </Col>
                  </Row>

                  <div
                    className={`mt-2 mb-2 ${Config.names.slugName}-other-section p-4`}
                  >
                    <Row>
                      <Col lg={6}>
                        <div className="form-company">
                          <AvField
                            name="companyPhone"
                            label={props.t("Company Phone")}
                            className="form-control"
                            placeholder={props.t("Company Phone")}
                            type="text"
                            value={companyData?.companyPhone}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-company">
                          <AvField
                            name="companyAddress"
                            label={props.t("Company Address")}
                            className="form-control"
                            placeholder={props.t("Company Address")}
                            type="text"
                            value={companyData?.companyAddress}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="form-company">
                          <AvField
                            name="companyDetails"
                            label={props.t("Company Details")}
                            className="form-control"
                            placeholder={props.t("Company Details")}
                            type="textarea"
                            value={companyData?.companyDetails}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setmodal(!modal);
                        setDefaultFulfiment();
                        // setDefaultDctAllow();
                      }}
                    >
                      {props.t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                      // disabled={!buttonActive}
                    >
                      <span className="dr-cform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      {button_label}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createCompanyError } = state.Company;
  const { company } = state.Company;
  const { message } = state.Company;
  return { createCompanyError, company, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createCompany,
    updateCompany,
    createCompanyFailed,
    setSingleCompany,
  })(withNamespaces()(CreateUpdateCompany))
);

// export default withRouter(
//   connect(mapStatetoProps, { getSingleMember })(
//     withNamespaces()(MyAccount)
//   )
