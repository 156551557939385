export const CREATE_SERVICE = "CREATE_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICE_LIST = "GET_SERVICE_LIST";
export const SET_SERVICE_LIST = "SET_SERVICE_LIST";
export const SET_SERVICE_LISTING = "SET_SERVICE_LISTING";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAILED = "CREATE_SERVICE_FAILED";
export const SET_SINGLE_SERVICE = "SET_SINGLE_SERVICE";
export const API_ERROR = "API_ERROR";
