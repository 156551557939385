import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
} from "reactstrap";
import { Config } from "../../../Config";

const AuthMTMGroupCode = (props) => {
  const [authPassword, setAuthPassword] = useState({
    password: "",
    error: false,
    errMessage: "",
  });

  function toggleModalClose(e) {
    //checking Store props
    // if (props.singleGroup) {
    //   props.updateGroupDataFlag();
    // }
    props.setAuthModal(false);
    setAuthPassword({ ...authPassword, password: "", error: false });
  }

  const HandleSubmit = (e) => {
    // e.preventdefault();
    if (!authPassword.password) {
      setAuthPassword({
        ...authPassword,
        error: true,
        errMessage: "Please Enter Password",
      });
      return;
    } else if (
      process.env.REACT_APP_MTM_UNLOCK_PASSWORD === authPassword.password
    ) {
      setAuthPassword({ ...authPassword, password: "", error: false });
      props.setAuthModal(false);
      {
        props.code.codeAction === "external_id"
          ? props.setCode({
            ...props.code,
            extIdLock: false,
          })
          : props.setCode({
            ...props.code,
            mtmCodeLock: false,
          });
      }
      return;
    } else {
      setAuthPassword({
        ...authPassword,
        error: true,
        errMessage: "Invalid Password",
      });
      return;
    }
  };

  return (
    <React.Fragment>
      <Modal
        size="sm"
        isOpen={props.authModal}
        toggle={() => {
          props.setAuthModal(!props.authModal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-group-add-edit-modal d-flex justify-content-center align-items-center`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
        style={{ height: "100%" }}
      >
        <ModalHeader
          toggle={() => {
            props.setAuthModal(!props.authModal);
          }}
        >
          <p className="font-size-14 text-primary p-0 m-0">Unlock Password</p>
        </ModalHeader>
        <ModalBody>
          <Container>
            {authPassword.error && (
              <>
                <div className="alert alert-danger fade show">
                  {authPassword.errMessage}
                </div>
              </>
            )}
            <div>
              <label>Password</label>
              <input
                type="password"
                className={`${authPassword.error ? "form-control error" : "form-control"
                  }`}
                value={authPassword.password}
                onChange={(e) => {
                  setAuthPassword({
                    ...authPassword,
                    password: e.target.value,
                    error: false,
                  });
                }}
              />
            </div>
            <Row>
              <Col lg={12}>
                <div className="modal-footer px-0">
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="button"
                    onClick={(e) => {
                      HandleSubmit(e);
                    }}
                  >
                    {"Submit"}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AuthMTMGroupCode;
