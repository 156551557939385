import {
  CREATE_DOCTOR,
  UPDATE_DOCTOR,
  GET_DOCTOR,
  GET_DOCTOR_LIST,
  SET_DOCTOR_LIST,
  CREATE_DOCTOR_SUCCESS,
  CREATE_DOCTOR_FAILED,
  API_ERROR,
  SET_SINGLE_DOCTOR,
  SET_DOCTOR_LISTING,
  UPDATE_TELEVISIT,
  GET_TELEVISIT,
  GET_TELEVISIT_LIST,
  SET_TELEVISIT_LIST,
  SET_TELEVISIT_LISTING,
  SET_SINGLE_TELEVISIT,

  GET_TELEVISIT_HISTORY_LIST,
  SET_TELEVISIT_HISTORY_LISTING
} from "./actionTypes";

const initialState = {
  createDoctorError: null,
  message: null,
  loading: false,
  action_type: null,
  televisit: null,
  singleTelevisit: null,
};

const doctor = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TELEVISIT:
      state = {
        ...state,
        televisit: action.payload,
        loading: true,
      };
      break;

    case GET_TELEVISIT:
      state = {
        ...state,
        televisit: action.payload.result,
        loading: true,
      };
      break;
    case GET_TELEVISIT_LIST:
      state = {
        ...state,
        televisit: action.payload.result,
        loading: true,
      };
      break;
    case GET_TELEVISIT_HISTORY_LIST:
      state = {
        ...state,
        televisit: action.payload.result,
        loading: true,
      };
      break;
    case SET_SINGLE_TELEVISIT:
      state = {
        ...state,
        televisit: null,
        singleTelevisit: action.payload,
        loading: false,
      };
      break;

    case SET_TELEVISIT_LIST:
      state = {
        ...state,
        loading: false,
        televisit: action.payload,
        message: action.message,
      };
      break;
    case SET_TELEVISIT_LISTING:
      state = {
        ...state,
        loading: false,
        televisit: null,
        televisitListing: action.payload,
        message: action.message,
      };
      break;
    case SET_TELEVISIT_HISTORY_LISTING:
        state = {
          ...state,
          loading: false,
          televisit: null,
          televisitHistoryListing: action.payload,
          message: action.message,
        };
        break;
    case CREATE_DOCTOR:
      state = {
        ...state,
        doctor: action.payload,
        loading: true,
        createDoctorError: null,
      };
      break;
    case UPDATE_DOCTOR:
      state = {
        ...state,
        doctor: action.payload,
        loading: true,
        createDoctorError: null,
      };
      break;
    case GET_DOCTOR:
      state = {
        ...state,
        doctor: action.payload.result,
        loading: true,
        createDoctorError: null,
      };
      break;
    case GET_DOCTOR_LIST:
      state = {
        ...state,
        doctor: action.payload.result,
        loading: true,
        createDoctorError: null,
      };
      break;
    case SET_SINGLE_DOCTOR:
      state = {
        ...state,
        doctor: null,
        singleDoctor: action.payload,
        loading: false,
        createDoctorError: null,
        action_type: action.action_type,
      };
      break;
    case SET_DOCTOR_LIST:
      state = {
        ...state,
        loading: false,
        doctor: action.payload,
        message: action.message,
        createDoctorError: null,
        action_type: action.action_type,
      };
      break;
    case SET_DOCTOR_LISTING:
      state = {
        ...state,
        loading: false,
        doctor: null,
        doctorListing: action.payload,
        message: action.message,
        createDoctorError: null,
      };
      break;
    case CREATE_DOCTOR_SUCCESS:
      state = {
        ...state,
        loading: false,
        doctor: action.payload,
        message: action.message,
        createDoctorError: null,
        action_type: action.action_type,
      };
      break;
    case CREATE_DOCTOR_FAILED:
      state = {
        ...state,
        doctor: null,
        createDoctorError: action.payload,
        loading: false,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default doctor;
