import React, { Fragment, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  Button,
  Col,
  Label,
  Row,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import scrollToComponent from "react-scroll-to-component";

import {
  dctAlert,
  dctClsAddRemove,
  medicationStatuses,
  // loadingMessage,
} from "../../../helpers/Commons";
import {
  dctPostDosespotData,
  dctGetDosespotItems,
  dctPutDosespotData,
} from "../../../helpers/backend_helper";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Config } from "../../../Config";

function MedicationRecord(props) {
  let MRformSection = React.createRef();
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState(false);
  const pathId = props.pathId;
  const dispatch = useDispatch();
  const { authMemberId, auth_token } = useSelector((state) => state.Login);

  const [openMedication, setOpenMedication] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  // const [isEditMedication, setIsEditMedication] = useState(false);

  const [_medicationList, _setMedicationList] = useState({
    data: [],
    loading: true,
    error: "",
  });

  const EMPTY_MEDICATION = {
    medicationName: "Search Medication",
    status: "",
    refills: "",
    daysSupply: "",
    dispenseUnitId: "",
    quantity: "",
    directions: "",
    comment: "",
    ndc: "",
  };

  const [medicationRecord, setMedicationRecord] = useState(EMPTY_MEDICATION);
  const [_singleMedication, _setSingleMedication] = useState({});

  useEffect(() => {
    if (props.location.hash === "#medication") {
      setOpenMedication(true);
    }
    getMedicationById();
  }, [false]);

  const getMedicationById = async () => {
    let url = `member/${pathId}/medication`;
    if (props.patientType === "dependent") {
      url = `dependent/${props.activePatientId}/medication`;
    }
    const response = await dctGetDosespotItems(url, "").then((data) => data);

    let responseData = response?.data;
    if (responseData && response.success === true) {
      _setMedicationList({
        data: responseData,
        loading: false,
        error: "",
      });
    } else {
      _setMedicationList({
        data: null,
        loading: false,
        error: response,
      });
      setErrorMsg(response);
      setSuccessMsg("");
    }
  };
  // Load the fetched Medication data for the select dropdown
  const loadMedicationOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 2) {
      let apiUrl = "medication";
      const axiosArgs = {
        name: inputValue,
      };
      dctGetDosespotItems(apiUrl, axiosArgs).then((results) =>
        callback(results.data)
      );
    }
    return;
  };
  const medicationListOptions = debounce(loadMedicationOptions, 250);

  const handleMedicationName = (e) => {
    if (!e) return;
    document.querySelector("#medicationName").classList.remove("text-danger");
    setMedicationRecord((prevState) => ({
      ...prevState,
      medicationName: e ? e.Name : "",
      ndc: e ? e.Strength[0].NDC : "",
    }));
  };

  const handlePersonalInfoSubmit = async (event, values) => {
    setFormErrorMsg(false);
    if (!medicationRecord.medicationName) {
      document.querySelector("#medicationName").classList.add("text-danger");
      return;
    }
    values.medicationName = medicationRecord.medicationName;
    const headers = {
      headers: {
        Authorization: auth_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    dctClsAddRemove("dr-search-spinner", "d-none", "remove");
    let response = "";
    // For edit action
    if (values.action === "edit" && values.id) {
      let editPatientID = values.id;
      values.ndc = medicationRecord.ndc;
      let url = `${process.env.REACT_APP_API_PATH}/member/${pathId}/medication/${editPatientID}`;
      if (values.patientType === "dependent") {
        url = `${process.env.REACT_APP_API_PATH}/dependent/${values.dependentId}/medication/${editPatientID}`;
      }
      response = await dctPutDosespotData(url, values, headers).then(
        (data) => data
      );
    } else {
      // For create action
      let url = `${process.env.REACT_APP_API_PATH}/member/${pathId}/medication`;
      if (values.patientType === "dependent") {
        url = `${process.env.REACT_APP_API_PATH}/dependent/${values.dependentId}/medication`;
      }
      values.ndc = medicationRecord.ndc;
      response = await dctPostDosespotData(url, values, headers).then(
        (data) => data
      );
    }

    if (response.success === true) {
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      if (response.data.Result.ResultCode === "ERROR") {
        setFormErrorMsg(response.data.Result.ResultDescription);
        setSuccessMsg("");
        return;
      }

      let message = props.t(
        `Medication record ${values.id ? "updated" : "created"} succuessfully.`
      );
      setSuccessMsg(message);
      setFormErrorMsg("");
      //Call the Get Medication method
      getMedicationById();
      setOpenEditForm(!openEditForm);
    } else {
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      setFormErrorMsg(
        response && response.data.message
          ? response.data.message
          : "Something went wrong. Please try again."
      );
      setSuccessMsg("");
    }
  };

  //_medicationData
  const medicationItemsBody = (_medicationList) => {
    return _medicationList && _medicationList.length > 0 ? (
      _medicationList.map((data, index) => {
        return (
          <Tr key={index} data-row-id={data.id}>
            <Td>
              <span className="dct__text_ellipses">{data.DisplayName}</span>
            </Td>
            <Td>{data.Refills}</Td>
            <Td>{data.DaysSupply}</Td>
            <Td>{data.DispenseUnitId}</Td>
            <Td>{data.Quantity}</Td>
            <Td>{data.Comment}</Td>
            <Td>{medicationStatuses[data.Status]}</Td>
            {/* <Td>
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      openMedicationForm(data);
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                  </span>
                </li>
              </ul>
            </Td> */}
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="8" className="text-center">
          <div className="text-danger">{props.t("No record found...")}</div>
        </Td>
      </Tr>
    );
  };

  const openMedicationForm = (data) => {
    if (data && data.DisplayName) {
      setOpenMedication(true);
      setOpenEditForm(true);
      // setIsEditMedication(true);
      setMedicationRecord((prevState) => ({
        ...prevState,
        medicationName: data.DisplayName
          ? data.DisplayName
          : "Search Medication",
        comment: data.Comment,
        daysSupply: data.DaysSupply,
        directions: data.Directions,
        dispenseUnitId: data.DispenseUnitId,
        quantity: data.Quantity,
        refills: data.Refills,
        status: data.Status,
      }));
      _setSingleMedication(data);
    } else {
      setOpenEditForm(true);
      // setIsEditMedication(false);
      setMedicationRecord(EMPTY_MEDICATION);
      _setSingleMedication({});
    }
    if (openEditForm) {
      scrollToComponent(MRformSection.current, {
        offset: 0,
        align: "middle",
        duration: 500,
        ease: "inExpo",
      });
    }
  };

  const closeAllergyForm = () => {
    setOpenEditForm(false);
    // setIsEditMedication(false);
  };

  const onEntered = () => {
    scrollToComponent(MRformSection.current, {
      offset: 0,
      align: "middle",
      duration: 500,
      ease: "inExpo",
    });
  };
  return (
    <CardBody className="mb-0">
      {/* {errorMsg ? dctAlert(3, errorMsg) : ""} */}
      {successMsg ? dctAlert(1, successMsg) : ""}
      {_medicationList.data?.length > 0 ? (
        <Fragment>
          {/* <Row>
                <Col lg={12} className="mb-3">
                  {_medicationList.data?.length < 1 ? <p className="font-size-12 text-muted">
                    {props.t(
                      `You have no medication items on record. Click "Add Medication Record" button below to add`
                    )}
                  </p> : ""}
                </Col>
              </Row> */}

          <div className="${Config.names.slugName}-sm--15">
            <Row>
              <Col lg={12} className="mb-1">
                <div className="table-responsive">
                  <Table
                    className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table ${Config.names.slugName}-odd-even`}
                  >
                    <Thead className="thead-light">
                      <Tr>
                        <Th scope="col" style={{ width: "70px" }}>
                          {props.t("Medication")}
                        </Th>
                        <Th scope="col" style={{ width: "70px" }}>
                          {props.t("Refills")}
                        </Th>
                        <Th scope="col" style={{ width: "70px" }}>
                          {props.t("Days Supply")}
                        </Th>
                        <Th scope="col" style={{ width: "70px" }}>
                          {props.t("Unit")}
                        </Th>
                        <Th scope="col" style={{ width: "70px" }}>
                          {props.t("Quantity")}
                        </Th>
                        <Th scope="col">{props.t("Comment")}</Th>
                        <Th scope="col" style={{ width: "70px" }}>
                          {props.t("Status")}
                        </Th>
                        {/* <Th scope="col" style={{ width: "70px" }}>
                          {props.t("Action")}
                        </Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>{medicationItemsBody(_medicationList?.data)}</Tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        </Fragment>
      ) : (
        <span className="d-flex justify-content-center text-danger">
          No records found
        </span>
      )}
      {/* <div>
              <Button
                color="primary mr-2"
                onClick={openMedicationForm}
                style={{ marginBottom: "1rem" }}
              >
                {props.t(`Add Medication Record`)}
              </Button>

              <Collapse isOpen={openEditForm} onEntered={onEntered}>
                <div className="mt-2" ref={MRformSection}>
                  <h4>{props.t(`Add Medication Record`)}</h4>
                  <AvForm
                    className="form-horizontal needs-validation"
                    onValidSubmit={(e, v) => {
                      handlePersonalInfoSubmit(e, v);
                    }}
                  >
                    <AvField
                      name="id"
                      type="hidden"
                      value={
                        _singleMedication && _singleMedication.id
                          ? _singleMedication.id
                          : ""
                      }
                    />
                    {_singleMedication && _singleMedication.id ? (
                      <AvField name="action" type="hidden" value="edit" />
                    ) : null}

                    <AvField
                      name="memberId"
                      type="hidden"
                      value={pathId}
                    />
                    {props.patientType === "dependent" ? (
                      <AvField
                        name="dependentId"
                        type="hidden"
                        value={props.activePatientId}
                      />
                    ) : null}
                    <AvField
                      name="patientType"
                      type="hidden"
                      value={props.patientType}
                    />

                    <Row>
                      <Col lg={6}>
                        <div
                          className={`form-group ${Config.names.slugName}-select-box`}
                          id="medicationName"
                        >
                          <label htmlFor="medicationName">
                            {props.t("Medication")}
                          </label>
                          <AsyncSelect
                            isClearable={true}
                            placeholder={props.t("Search Medication")}
                            name="medicationName"
                            getOptionLabel={(e) => e.Name}
                            getOptionValue={(e) => e.Name}
                            className={`${Config.names.slugName}-custom-select-box`}
                            cacheOptions
                            defaultOptions
                            loadOptions={medicationListOptions}
                            value={{ Name: medicationRecord?.medicationName }}
                            onChange={handleMedicationName}
                            required
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          type="select"
                          name="status"
                          id="medicationStatus"
                          placeholder={props.t(`Status`)}
                          label={props.t(`Status`)}
                          className="form-control"
                          value={_singleMedication?.Status}
                          required
                        >
                          <option value="">{props.t("Select Status")}</option>
                          {Object.keys(medicationStatuses).map((key, index) => (
                            <option key={index} value={key}>
                              {medicationStatuses[key]}
                            </option>
                          ))}
                        </AvField>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="refills"
                          label={props.t(`Refills`)}
                          placeholder={props.t(`Refills`)}
                          type="number"
                          min="0"
                          max="99"
                          className="form-control"
                          value={
                            _singleMedication && _singleMedication.Refills
                              ? _singleMedication.Refills
                              : ""
                          }
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="daysSupply"
                          label={props.t(`Days Supply`)}
                          placeholder={props.t(`Days Supply`)}
                          type="number"
                          className="form-control"
                          value={
                            _singleMedication && _singleMedication.DaysSupply
                              ? _singleMedication.DaysSupply
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="dispenseUnitId"
                          label={props.t(`Dispense Unit Id`)}
                          placeholder={props.t(`Dispense Unit Id`)}
                          type="number"
                          className="form-control"
                          value={
                            _singleMedication &&
                            _singleMedication.DispenseUnitId
                              ? _singleMedication.DispenseUnitId
                              : ""
                          }
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="quantity"
                          label={props.t(`Quantity`)}
                          placeholder={props.t(`Quantity`)}
                          type="number"
                          min="0"
                          max="99"
                          className="form-control"
                          value={
                            _singleMedication && _singleMedication.Quantity
                              ? _singleMedication.Quantity
                              : ""
                          }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="comment"
                          label={props.t(`Comment`)}
                          placeholder={props.t(`Comment`)}
                          type="textarea"
                          maxLength="500"
                          className="form-control"
                          value={
                            _singleMedication && _singleMedication.Comment
                              ? _singleMedication.Comment
                              : ""
                          }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        {formErrorMsg ? dctAlert(3, formErrorMsg) : ""}
                        <div className="form-group text-right">
                          <button
                            type="button"
                            className="btn btn-secondary mr-2"
                            onClick={closeAllergyForm}
                          >
                            {props.t("Cancel")}
                          </button>

                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => {}}
                          >
                            <span className="dr-search-spinner d-none">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                            </span>{" "}
                            {props.t(`Save`)}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </div>
              </Collapse>
            </div> */}
    </CardBody>
  );
}

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(MedicationRecord))
);
