import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Row, Col, Card, CardBody, Media, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
//Import Images
import profileImg from "../../assets/images/profile-img.png";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

const { Parser, transforms: { unwind } } = require('json2csv');


const CompanyDateRange = (props) => {
    const [dateWarn, setDateWarn] = useState(false)
    const [fromFullDate, setFromFullDate] = useState('');
    const [toFullDate, setToFullDate] = useState('')
    const [fromYear, setFromYear] = useState('');
    const [fromMonth, setFromMonth] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toYear, setToYear] = useState('');
    const [toMonth, setToMonth] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        let date = new Date();
        setFromFullDate(date);
        setToFullDate(date);
        setFromYear(moment(date).format('YYYY'));
        setFromMonth(moment(date).format('MM'));
        setFromDate(moment(date).format('DD'));
        setToYear(moment(date).format('YYYY'));
        setToMonth(moment(date).format('MM'));
        setToDate(moment(date).format('DD'));
    }, [])


    const handleFromDateChange = (date) => {
        setFromFullDate(date);
        setFromYear(moment(date).format('YYYY'));
        setFromMonth(moment(date).format('MM'));
        setFromDate(moment(date).format('DD'));
    };

    const handleToDateChange = (date) => {
        setDateWarn(false)
        setToFullDate(date);
        setToYear(moment(date).format('YYYY'));
        setToMonth(moment(date).format('MM'));
        setToDate(moment(date).format('DD'));
    }

    const handleSubmit = async () => {
        let diff = moment(fromFullDate).from(toFullDate);

        if (diff.includes('ago') || diff.includes('few seconds')) {
            setDateWarn(false);
        } else {
            setDateWarn(true);
            return;
        }

        const data = {
            fromYear: Number(fromYear),
            fromMonth: Number(fromMonth),
            fromDate: Number(fromDate),
            toYear: Number(toYear),
            toMonth: Number(toMonth),
            toDate: Number(toDate)
        }
        const options = {
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
        };

        const url = `${process.env.REACT_APP_API_PATH}/company/${props.companyId}/export-active-member`;
        await axios.post(url, data, options)
            .then(res => {
                if (res.status === 200) {
                    const fields = ['memberId.status', 'memberId.primaryExternalId', 'memberId.primaryExternalId', 'memberId.memberType', 'companyId.planId', 'companyId.planDetailId', 'userId.firstName', 'userId.lastName', 'memberId.address', 'memberId.addressTwo', 'memberId.city', 'memberId.state', 'memberId.postalCode', 'userId.email', 'memberId.primaryPhone', 'memberId.secondaryPhone', 'memberId.gender', 'memberId.dob', 'effectiveDate', 'termDate', 'companyId.companyName'];
                    let json2csvParser = new Parser({ quote: '', fields });
                    json2csvParser.opts.fields[0].label = 'Status';
                    json2csvParser.opts.fields[1].label = 'External Id';
                    json2csvParser.opts.fields[2].label = 'Primary External Id';
                    json2csvParser.opts.fields[3].label = 'Type';
                    json2csvParser.opts.fields[4].label = 'Plan Id';
                    json2csvParser.opts.fields[5].label = 'Plan Details Id';
                    json2csvParser.opts.fields[6].label = 'First';
                    json2csvParser.opts.fields[7].label = 'Last';
                    json2csvParser.opts.fields[8].label = 'Address';
                    json2csvParser.opts.fields[9].label = 'Address Line 2';
                    json2csvParser.opts.fields[10].label = 'City';
                    json2csvParser.opts.fields[11].label = 'State';
                    json2csvParser.opts.fields[12].label = 'Zipcode';
                    json2csvParser.opts.fields[13].label = 'Email';
                    json2csvParser.opts.fields[14].label = 'Primary Phone';
                    json2csvParser.opts.fields[15].label = 'Secondary Phone';
                    json2csvParser.opts.fields[16].label = 'Gender';
                    json2csvParser.opts.fields[17].label = 'DOB';
                    json2csvParser.opts.fields[18].label = 'Effective Date';
                    json2csvParser.opts.fields[19].label = 'Term Date';
                    json2csvParser.opts.fields[20].label = 'Company Name';

                    const csv = json2csvParser.parse(res.data);
                    const uid = uuidv4();
                    let cName = props.companyName;
                    cName = cName.replace(/\s+/g, '-');
                    const exportedFileName = cName + '-' + uid + '.csv';

                    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    let link = document.createElement("a");
                    let url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportedFileName);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }

            })
            .catch(err => console.log(err))

    }

    return (
        <React.Fragment>
            <div className="d-flex flex-row mr-3 ml-auto date-container">
                <div>
                    <ReactDatePicker
                        name="dob"
                        className="form-control left-date"
                        selected={fromFullDate}
                        dateFormat="MM-dd-yyyy"
                        onChange={handleFromDateChange}
                        placeholderText="MM-DD-YYYY"
                        showYearDropdown
                    />
                </div>
                <div>
                    <ReactDatePicker
                        name="dob"
                        className={dateWarn ? "form-control right-date border-danger" : "form-control right-date"}
                        selected={toFullDate}
                        dateFormat="MM-dd-yyyy"
                        onChange={handleToDateChange}
                        placeholderText="MM-DD-YYYY"
                        showYearDropdown
                    />
                </div>
                <div>
                    <Button className="btn btn-primary export-btn"
                        onClick={handleSubmit}
                    >Export</Button>
                </div>
            </div>

        </React.Fragment>
    );
}

export default withRouter(
    withNamespaces()(CompanyDateRange)
);