export const EmailFormatter = (email) => {
  const res = email.split("@");

  const x = res[0].substring(0, 2);
  const y = res[0].substring(2);

  const word = y.replace(/./g, "*");

  const finalEmail = x.concat(word).concat("@", res[1]);

  return finalEmail;
};
