import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  ModalBody,
  Row,
  Table,
} from "reactstrap";
import { Modal } from "../../../components/DoctegrityModal";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";

import DctHeroBanner from "../../../components/Common/DctHeroBanner";
import counselingBanner from "../../../assets/images/care-wide.png";
import { Config } from "../../../Config";

function BehavioralHealth(props) {
  const [state, setState] = useState({
    emergencyButton: "",
    selectedState: "",
    selectedReason: "",
    callingNo: "877-342-5152",
    modalState: "",
  });

  const [modalState1, setModalState1] = useState({
    title: "Call 911 immediately!",
    subtitle:
      "If this is a life-threatening emergency please call 911 immediately",
    display: "d-none",
    buttonDisplay: "d-none",
  });
  const [modalState2, setModalState2] = useState({
    title: "Call Counselor Service",
    subtitle: "Is this still a good number for you?",
    body:
      'The number above will be dialed right away upon clicking "Initiate Call". Please make sure you are close to your phone and have good reception.',
  });

  const [modal, setModal] = useState(false);

  const handleOnClick = (e) => {
    let gotName = e.target.name;

    if (gotName === "YesButton") {
      setState((prevState) => ({
        ...prevState,
        emergencyButton: gotName,
        modalState: modalState1,
      }));
      setModal(!modal);
    } else if (gotName === "CallButton") {
      setState((prevState) => ({
        ...prevState,
        emergencyButton: gotName,
        modalState: modalState2,
      }));
      setModal(!modal);
    } else {
      return setModal(!modal);
    }
  };

  const handleSelectedState = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      selectedState: e.target.value,
    }));
  };
  const handleSelectedReason = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      selectedReason: e.target.value,
    }));
  };

  const handleInputNo = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      callingNo: e.target.value,
    }));
  };

  const [commonReason, setCommonReason] = useState([
    "Stress and Anxiety",
    "COVID support",
    "Smoking Cessation",
    "Major Illness",
    "Depression",
    "Workplace issue",
    "Substance Abuse",
    "Parenting / Elderly care",
    "Death of Loved One",
    "Financial and Legal Consults",
    "Relationship issues",
    "Other Reasons of Concern",
    "Pet care resources"
  ]);

  const [selectState, setSelectState] = useState([
    "Select a state",
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "Armed Forces Americas",
    "Armed Forces Non-Americas",
    "Armed Forces Pacific",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Federated States of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Islands",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ]);
  const [selectReason, setSelectReason] = useState([
    "Select a reason",
    "Stress and anxiety",
    "Smoking Cessation",
    "Depression",
    "Substance abuse",
    "Death of a loved one",
    "Relationship issues",
    "Parenting issues",
    "Traumatic accident",
    "Major illness",
    "Workplace issues",
    "Change &amp; transition",
    "Financial stress",
    "Other reason that causes concern",
  ]);

  function handleValidSubmit(event, values) {
    if (!values.phone) return;
    setState((prevState) => ({
      ...prevState,
      callingNo: values.phone,
    }));
    // if (window.ReactNativeWebView)
    //   window.ReactNativeWebView.postMessage(state.callingNo);
    // else
    document.querySelector(`#${Config.names.slugName}-call-btn`).click();
  }

  const stopNativeLoader = () => {
    window.ReactNativeWebView.postMessage("STOP_NATIVE_LOADER");
  };
  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          {window.ReactNativeWebView ? (
            stopNativeLoader()
          ) : (
            <Row>
              <Col xl={12}>
                <DctHeroBanner
                  mainTitle={props.t("Behavioral Health")}
                  subTitle={props.t("We are here to help")}
                  isInfoBar={true}
                  infoBarTitle=""
                  isCall911={true}
                ></DctHeroBanner>
              </Col>
            </Row>
          )}

          <Row className="justify-content-between">
            <Col lg={5}>
              <Card>
                <CardBody className={`${Config.names.slugName}-sm--12`}>
                  <h5 className="mt-0 mb-3 text-uppercase text-primary">
                    {props.t("Teletherapy Benefit")}
                  </h5>
                  <p className="mb-4 text-muted font-weight-medium">
                    {props.t(
                      `Most people experience some personal or family distress in the course of their lives. Professional assistance helps to ensure successful management of personal challenges. Teletherapy Benefit is a convenient first step in getting such support`
                    )}
                    .
                  </p>
                  <img src={counselingBanner} width="100%"></img>
                  <p className="mb-0 mt-4 text-muted font-weight-medium ">
                    {props.t(
                      `Teletherapy Benefit provides confidential, unlimited telephonic consultation, counseling and referral services for members including`
                    )}
                    :
                  </p>
                  <ul className="mt-2 text-justify text-muted">
                    <li>
                      {props.t(
                        "24/7 availability to Licenced Mental Health Therapists"
                      )}
                    </li>
                    <li>{props.t("No co-pay or consult fee")}</li>
                    <li>{props.t("Immediate crisis support")}</li>
                    <li>
                      {props.t(
                        "Comprehensive problem assessment and resolution"
                      )}
                    </li>
                    <li>
                      {props.t("Supportive counseling and subsequent sessions")}
                    </li>
                    <li>{props.t("Ancillary Benefits")}: {props.t("Financial, Legal, Advocacy and more")}</li>
                    <li>
                      {props.t("100% follow up with original counselor")}
                    </li>
                    <li>
                      {props.t(`Custom referral (if needed) to medical, behavioral health plans or local community resources`)}
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody className={`${Config.names.slugName}-sm--12`}>
                  <h5 className="mt-0 mb-3 text-uppercase text-primary">
                    {props.t("Common Reason")}
                  </h5>
                  <div className="table-responsive">
                    <Table className="table mb-0 table">
                      {commonReason.map((value, key) => (
                        <tbody key={key}>
                          <tr>
                            <td className="text-uppercase">{value}</td>
                          </tr>
                        </tbody>
                      ))}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card>
                <CardBody className={`${Config.names.slugName}-sm--12`}>
                  <h5 className="mt-0 mb-3 text-uppercase text-primary">
                    {props.t(" Let's get started")}
                  </h5>
                  <div>
                    <p>
                      {props.t(
                        "Hi there. Is this a life-threatening emergency?"
                      )}
                    </p>
                    <Button
                      outline
                      color="primary"
                      name="YesButton"
                      onClick={handleOnClick}
                    >
                      {props.t("Yes")}
                    </Button>{" "}
                    <Button outline color="primary">
                      {props.t("No")}
                    </Button>{" "}
                    <p className="mt-4">
                      {props.t("What state are you currently in?")}
                    </p>
                    <div className="form-group row">
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleSelectedState}
                        ></input>
                        {/* <select
                          className="custom-select"
                          onChange={handleSelectedState}
                        >
                          {selectState.map((value, key) => (
                            <option key={key}> {value}</option>
                          ))}
                        </select> */}
                      </div>
                    </div>
                    <p className="mt-4">
                      {props.t(" Ok. What is the reason for your call today?")}
                    </p>
                    <div className="form-group row">
                      <div className="col-md-10">
                        <select
                          className="custom-select"
                          onChange={handleSelectedReason}
                        >
                          {selectReason.map((value, key) => (
                            <option key={key}> {value}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <p className="mt-4">
                      {props.t("Click to call our Counselor Service")}
                    </p>
                    <Button
                      outline
                      color="primary"
                      name="CallButton"
                      className="mb-3"
                      onClick={handleOnClick}
                    >
                      <i className="fas fas fa-phone-alt font-size-18 align-middle mr-2"></i>
                      {props.t(" Call Counselor")}
                    </Button>{" "}
                  </div>
                  <span className="mt-5">
                    {props.t("or dial at your convenience")}
                    <p className="text-danger">
                      <a
                        href="tel:1-844-200-8975"
                        onClick={() => {
                          if (window.ReactNativeWebView)
                            window.ReactNativeWebView.postMessage(
                              state.callingNo
                            );
                        }}
                      >
                        1-844-368-2088
                      </a>
                    </p>
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Modal isOpen={modal} centered={true} toggle={handleOnClick}>
          <ModalBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <h5 className="mt-0 mb-3 text-uppercase text-primary">
                {state.modalState.title}
              </h5>
              <p className="mb-3">{state.modalState.subtitle}</p>
              {/* <input
              type="text"
              className={`${state.modalState.display} form-control`}
              defaultValue={state.callingNo}
              onChange={handleInputNo}
            /> */}
              <AvField
                type="text"
                name="phone"
                className={`${state.modalState.display} form-control`}
                value={state.callingNo}
                onChange={handleInputNo}
                required
              />
              <p className="mt-3 mb-3">{state.modalState.body}</p>

              <div className="d-flex text-center justify-content-between">
                <Button
                  color="primary"
                  type="submit"
                  className={state.modalState.buttonDisplay}
                >
                  {props.t("Initiate Call")}
                </Button>
                <a
                  className="d-none"
                  id={`${Config.names.slugName}-call-btn`}
                  href={`tel:${state.callingNo}`}
                >
                  {state.callingNo}
                </a>
                <Button color="secondary" onClick={handleOnClick}>
                  {props.t("Cancel")}
                </Button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
      </div>
    </Fragment>
  );
}

export default withRouter(withNamespaces()(BehavioralHealth));
