import React from "react";
import { Redirect } from "react-router-dom";

// // Pages Component
// import Chat from "../pages/Chat/Chat";

// Profile
import UserProfile from "../pages/Authentication/user-profile";
import PasswordManagement from "../pages/PasswordManagement";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

// //Groups
import GroupsGrid from "../pages/Groups/groups-grid";
import Groups from "../pages/Groups/groups";
import GroupsOverview from "../pages/Groups/groups-overview";
import GroupsCreate from "../pages/Groups/groups-create";

// //Brand
// import BrandGrid from "../pages/Brand/brand-grid";
import Brand from "../pages/Brand/brand";
import BrandOverview from "../pages/Brand/brand-overview";
// import BrandCreate from "../pages/Brand/brand-create";

// //Televisits
// import TelevisitsGrid from "../pages/Televisits/televisits-grid";
// import Televisits from "../pages/Televisits/televisits";
import Televisits from "../pages/Doctor/Televisits";
import TelevisitsForMember from "../pages/Doctor/TelevisitsForMember";
import TelevisitsOverview from "../pages/Televisits/televisits-overview";
import TelevisitsLogs from "../pages/Televisits/televisits-logs";
// import TelevisitsCreate from "../pages/Televisits/televisits-create";

//Consultation notification for doctor
import NotificationPage from "../pages/Doctor/NotificationPage";

// //Services
import ServicesGrid from "../pages/Services/services-grid";
import Services from "../pages/Services/services";
import ServicesCreate from "../pages/Services/services-create";

// //Company
import Company from "../pages/Company/company";
import CompanyOverview from "../pages/Company/company-overview";

// //Partner
import Partner from "../pages/Partner/partners";
import PartnerAuth from "../pages/PartnerAuth/partners";
import PartnerOverview from "../pages/Partner/partners-overview";

// //Pharmacies
import Pharmacy from "../pages/Pharmacy/pharmacies";
import RxPrescriptionInterface from "../pages/PageHelper/RxPrescriptionInterface";
import PharmacyOverview from "../pages/Pharmacy/pharmacy-overview";
import PrescriptionOverview from "../pages/Prescription/prescription-overview";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import CreatePwd from "../pages/Authentication/CreatePassword";
import ResetPwd from "../pages/Authentication/ResetPassword";
import UnSubscribeEmail from "../pages/Authentication/UnSubscribe";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Dashboard
import DashboardInterface from "../pages/PageHelper/DashboardInterface";
import DashboardDoctor from "../pages/Dashboard-doctor/index";
import DashboardMember from "../pages/Dashboard-member/index";
import DashboardPartner from "../pages/Dashboard-partner/index";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ToastUIChart from "../pages/Charts/ToastUIChart";
import ChartsKnob from "../pages/Charts/charts-knob";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import Unauthorize from "../pages/Utility/unauthorize";
import PageLoader from "../pages/Utility/Loader";

import Members from "../pages/Members/Admin/members";
import MemberOverview from "../pages/Members/Admin/members-overview";
import HealthRecords from "../pages/Members/HealthRecords/index";

import ScheduleCenter from "../pages/Members/ScheduleCenter";

import Consultations from "../pages/Members/Consultations";
import MyConsultations from "../pages/Members/MyConsultations";
import ConsultationReport from "../pages/Members/ConsultationReport";
import BehavioralHealth from "../pages/Members/BehavioralHealth/BehavioralHealth";
import MemberNotification from "../pages/Members/MemberNotification/EmailInbox";
import MyAccount from "../pages/Members/MyAccount";
import FailedConsultations from "../pages/Dashboard/failed-consultations";
import FailedConsultationOverview from "../pages/Dashboard/failed-consultations-overview";

/* Message A Doctor */
import MessageADoctor from "../pages/PostMessage/PostMessage";
import CreateUpdatePostMessage from "../components/PostMessage/CreateUpdatePostMessage";

import PostMessageOverview from "../pages/PostMessage/Admin/PostMessageOverview";

/* Logs */
import EventLogs from "../pages/Logs/Admin/EventLogs";

/* Doctors Admin */
import Doctors from "../pages/Doctor/Admin/doctors";
import DoctorOverview from "../pages/Doctor/Admin/doctor-overview";
import DoctorsGrid from "../pages/Doctor/Admin/doctors-grid";
import DoctorsCreate from "../pages/Doctor/Admin/doctors-create";
import JoinHazeAdmin from "../pages/Doctor/Admin/JoinHazeAdmin";
import JoinHazePatientOverview from "../pages/Doctor/JoinHazePatientOverview";

/* Doctor */
// import Televisits from "../pages/Doctor/Televisits";
import DoctorPostMessages from "../pages/Doctor/PostMessage/PostMessage";
import LockMessages from "../pages/Doctor/PostMessage/LockMessage";
import AccountSetting from "../pages/Doctor/Account/AccountSetting";

import JoinHaze from "../pages/Doctor/Account/JoinHaze";

// import VideoComponent from "../pages/Dashboard-doctor/VideoComponent";
// import VideoApp from "../pages/Dashboard-doctor/index1";
import VideoCall from "../pages/Members/VideoCallWindow/VideoCall";
import TBVideoCall from "../pages/VideoCall/TokBox/TBVideoCall";

import AllPostMessage from "../pages/PostMessage/Admin/AllPostMessage";

// Physician data Uplaod
import DropZoneDataUpload from "../pages/DataUpload/DropzoneDataUpload";
import RxReport from "../pages/Pharmacy/RxReport";
import RxArchive from "../pages/Pharmacy/RxArchive";
import Benefit from "../pages/activation";

// SSO authentication page
import ssoAuth from "../pages/SSO/ssoAuth";
import JoinHazeOverView from "../pages/Doctor/JoinHazeOverView";

// Members
import DiscountCard from "../pages/Members/DiscountCard";

// V3 IMPORTS
import V3Login from "../pages/V3/Login";
import V3ForgetPassword from "../pages/V3/ForgetPassword";
import V3ResetPassword from "../pages/V3/ResetPassword";
import ActivateBenefit from "../pages/V3/ActivateBenifit";
import FindAccount from "../pages/V3/FindAccount";
import V3IdentifyAccount from "../pages/V3/IdentifyAccount";
import { updateDependent } from "../store/actions";
import DependentOverview from "../pages/Members/Admin/dependent-overview";

const userRoutes = [
  { path: "/dashboard", component: DashboardInterface },
  { path: "/dashboard-doctor", component: DashboardDoctor },

  { path: "/dashboard-member", component: DashboardMember },

  // //calendar
  { path: "/calendar", component: Calendar },

  // //profile
  { path: "/profile", component: UserProfile },
  // //profile
  { path: "/password-management", component: PasswordManagement },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },

  //Groups
  { path: "/groups-grid", component: GroupsGrid },
  { path: "/groups", component: Groups },
  { path: "/groups-overview/id/:id", component: GroupsOverview },
  { path: "/groups-create", component: GroupsCreate },
  //Brand
  // { path: "/brand-grid", component: BrandGrid },
  { path: "/brand", component: Brand },
  { path: "/brand-overview/id/:id", component: BrandOverview },
  // { path: "/brand-create", component: BrandCreate },

  //Televisits
  // { path: "/televisits-grid", component: TelevisitsGrid },
  { path: "/televisits", component: Televisits },
  { path: "/televisits-overview/id/:id", component: TelevisitsOverview },
  // { path: "/televisits-create", component: TelevisitsCreate },
  //MyConsultation
  { path: "/member/my-consultations", component: MyConsultations },
  { path: "/consultation/:id/logs", component: TelevisitsLogs },
  { path: "/member/consultationsreport", component: ConsultationReport },
  //Services
  { path: "/services-grid", component: ServicesGrid },
  { path: "/services", component: Services },
  { path: "/services-create", component: ServicesCreate },

  //Company
  { path: "/company", component: Company },
  { path: "/company-overview/id/:id", component: CompanyOverview },

  { path: "/dashboard-partner", component: DashboardPartner },
  { path: "/partners", component: Partner },
  { path: "/partnersauth", component: PartnerAuth },
  // { path: "/partners-grid", component: PartnersGrid},
  { path: "/partners-overview/id/:id", component: PartnerOverview },
  // { path: "/partner-create", component: PartnersCreate },

  //Pharmacy
  { path: "/pharmacies", component: Pharmacy },
  { path: "/pharmacy-overview/id/:id", component: PharmacyOverview },
  { path: "/prescription-overview/id/:id", component: PrescriptionOverview },

  { path: "/rxprescriptionlist", component: RxPrescriptionInterface },
  { path: "/rxreport", component: RxReport },
  { path: "/rxarchive", component: RxArchive },

  //Physician Data Uplaod
  // { path: "/physician-directories", component: DropZoneDataUpload },

  //member
  { path: "/members", component: Members, exact: true },
  {
    path: "/member-overview/id/:id/dependent-overview/:id",
    component: DependentOverview,
    // exact: true,
  },
  { path: "/member-overview", component: MemberOverview },
  { path: "/member/discount-card", component: DiscountCard },

  { path: "/member/health-records", component: HealthRecords },
  { path: "/member/health-records/id/:id", component: HealthRecords },

  { path: "/member/schedule-center", component: ScheduleCenter },
  { path: "/member/consultation/phone/step/:step", component: Consultations },
  { path: "/member/consultation/video/step/:step", component: Consultations },

  { path: "/member/ask-doctor", exact: true, component: MessageADoctor },
  {
    path: "/member/ask-doctor/id/:id",
    exact: true,
    component: MessageADoctor,
  },
  {
    path: "/member/ask-doctor/create",
    exact: true,
    component: CreateUpdatePostMessage,
  },
  {
    path: "/member/ask-doctor/edit/id/:id",
    exact: true,
    component: CreateUpdatePostMessage,
  },
  {
    path: "/member/message-overview/id/:id",
    exact: true,
    component: PostMessageOverview,
  },

  { path: "/member/behavioral-health", component: BehavioralHealth },
  { path: "/member/inbox", component: MemberNotification },
  { path: "/member/my-account", component: MyAccount },
  { path: "/member/my-account/personalinfo", component: MyAccount },
  { path: "/member/my-account/dependents", component: MyAccount },
  { path: "/member/my-account/pharmacy", component: MyAccount },
  { path: "/member/my-account/password", component: MyAccount },
  { path: "/member/my-account/change-language", component: MyAccount },
  { path: "/member/my-account/dependents/id/:id", component: MyAccount },
  { path: "/member/messages", component: AllPostMessage },

  // Failed Consultations
  { path: "/failed-consultations", component: FailedConsultations },
  {
    path: "/failed-consultations-overview/:id",
    component: FailedConsultationOverview,
  },

  //Logs
  { path: "/eventlogs", component: EventLogs },

  //Doctors
  { path: "/doctors", component: Doctors },
  { path: "/doctors-grid", component: DoctorsGrid },
  { path: "/doctors-overview/id/:id", component: DoctorOverview },
  { path: "/doctor-create", component: DoctorsCreate },
  { path: "/doctor/messages", component: DoctorPostMessages, exact: true },

  { path: "/joinhaze", component: JoinHazeAdmin },
  {
    path: "/joinhaze-overview-patient/id/:id",
    component: JoinHazePatientOverview,
  },

  {
    path: "/doctor/messages/id/:id",
    component: DoctorPostMessages,
    exact: true,
  },
  {
    path: "/lock/message/id/:id",
    component: LockMessages,
    exact: true,
  },
  { path: "/doctor/televisits", component: Televisits, exact: true },
  {
    path: "/doctor/televisits/member/:id",
    component: TelevisitsForMember,
    exact: true,
  },
  { path: "/doctor/notifications", component: NotificationPage },
  { path: "/doctor/my-account", component: AccountSetting },

  { path: "/doctor/join-haze", component: JoinHaze },
  { path: "/joinhaze-overview/id/:id", component: JoinHazeOverView },

  {
    path: "/message-overview/id/:id",
    component: PostMessageOverview,
  },
  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  // { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/reports", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/tui-charts", component: ToastUIChart },
  { path: "/charts-knob", component: ChartsKnob },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  {
    path: "/activation",
    exact: true,
    component: () => <Redirect to="/login" />,
  },

  {
    path: "/member/consultation",
    exact: true,
    component: () => <Redirect to="/member/schedule-center" />,
  },
  {
    path: "/member/consultation/video",
    exact: true,
    component: () => <Redirect to="/member/consultation/video/step/1" />,
  },
  {
    path: "/member/consultation/video/step",
    exact: true,
    component: () => <Redirect to="/member/consultation/video/step/1" />,
  },
  {
    path: "/member/consultation/phone",
    exact: true,
    component: () => <Redirect to="/member/consultation/phone/step/1" />,
  },
  {
    path: "/member/consultation/phone/step",
    exact: true,
    component: () => <Redirect to="/member/consultation/phone/step/1" />,
  },
  {
    path: "/member/consultation/audio/",
    exact: true,
    component: () => <Redirect to="/member/consultation/phone/step/1" />,
  },
  {
    path: "/member/consultation/audio/step/",
    exact: true,
    component: () => <Redirect to="/member/consultation/phone/step/1" />,
  },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  // { path: "/login", component: Login },
  { path: "/login", component: V3Login },

  // { path: "/activation", component: Benefit },
  // { path: "/forgot-password", component: ForgetPwd },
  { path: "/forgot-password", component: V3ForgetPassword },
  { path: "/activate-benefit", component: ActivateBenefit },
  { path: "/find-account", component: FindAccount },
  { path: "/identify-account", component: V3IdentifyAccount },

  { path: "/create-password/:id", component: CreatePwd },
  // { path: "/reset-password/:id", component: ResetPwd },
  { path: "/reset-password/:id", component: V3ResetPassword },

  // { path: "/unsubscribe-email/:id", component: UnSubscribeEmail },

  // { path: "/activation", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/unauthorized", component: Unauthorize },
  { path: "/loader", component: PageLoader, exact: true },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-forget-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/consultation/:id/room/:URLRoomName", component: VideoCall },
  { path: "/consultation/:id/room-token/:videoToken", component: VideoCall },
  { path: "/tokbox", component: TBVideoCall },
  { path: "/sso/:ssotoken", component: ssoAuth },
  //   {
  //   path: "/member-overview/id/:id/dependent-overview/:dependentId",
  //   exact: true,
  //   component: DependentOverview,
  // },
];

export { userRoutes, authRoutes };
