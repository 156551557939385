import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Media,
  Label,
  Input,
  Button,
  Badge,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  Alert,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { connect, useSelector, useDispatch } from "react-redux";

import {
  // televisitActionSuccessful,
  setMemberListing,
  setSingleTelevisit,
} from "../../store/actions";

import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

//Include Both Helper File with needed methods
import {
  loadingMessage,
  dctDateFormat,
  dctUtcToLocal,
  capitalizeWords,
} from "../../helpers/Commons";

import axios from "axios";

import ReactPaginate from "react-paginate";

import moment from "moment";
import VideoCallJsPanel from "../Dashboard-doctor/VideoCallWindow/VideoCallJsPanel";
import VoiceCallInterface from "../Dashboard-doctor/VoiceCallInterface";
import PharmacyJsPanel from "../../components/Televisit/PharmacyWindow/PharmacyJsPanel";
import SweetAlert from "react-bootstrap-sweetalert";

import TBVideoCallJsPanel from "../VideoCall/TokBox/TBVideoCallJsPanel";
import { Config } from "../../Config";
import ExcuseNote from "../../components/Member/Admin/ExcuseNote";
import SuccessModal from "../../components/Member/Admin/SuccessModal";
import ConsultationUpdateStatusModal from "../../components/Member/Admin/ConsultationUpdateStatusModal";
import CreateEditDoctor from "../../components/Member/Admin/AdminNotesModal";
import AdminNotesModal from "../../components/Member/Admin/AdminNotesModal";

const TelevisitsOverview = (props) => {
  const { authDoctorId } = useSelector((state) => state.Login);
  const [postsPerPage, setPostsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);

  const [modal, setModal] = useState(false);
  const [currentNote, setCurrentNote] = useState("");
  const [currentConsultId, setCurrentConsultId] = useState("");
  const [Medications, setMedications] = useState([]);

  const [televisitData, setTelevisitData] = useState({
    gloading: true,
    gdata: null,
    gid: null,
    gmessage: null,
  });

  const [pharmacyData, setPharmacyData] = useState(null);
  const [logsData, setLogsData] = useState(null);
  const [prescriptions, setPrescriptions] = useState(null);

  const [mdata, setMData] = useState({
    mloading: true,
    mmembers: [],
    mmessage: null,
  });

  const [searchFormDetails, setSearchFormDetails] = useState({
    memberSearchParam: "",
    type: "filter",
    reset: false,
  });

  const [confirm_both, setconfirm_both] = useState(false);
  const [doctorData, setDoctorData] = useState("");
  const [click, setClick] = useState(false);
  const [resetLoader, setResetLoader] = useState(false);
  const [authUserType, setAuthUserType] = useState(
    JSON.parse(localStorage.getItem("auth_user")).userType
  );
  const [excuseNoteModal, setExcuseNoteModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);
  const [pdfModal, setOpenPdfModal] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [statusUpdateMessage, setStatusUpdateMessage] = useState("");
  const [emailSentMessage, setEmailSentMessage] = useState("");
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [editNoteModal, setEditNoteModal] = useState(false);
  const [successAdminNote, setSuccessAdminNote] = useState(false);
  const [showWaring, setShowWaring] = useState(false);
  const [waringMessage, setWaringMessage] = useState("");
  const [authUserId, setAuthUserEmail] = useState(
    JSON.parse(localStorage.getItem("auth_user")).id
  );
  function toggleModalClose(e) {
    //checking Store props
  }

  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-gform-spinner")[0]
      .classList.remove("d-none");
    // setmsgEnable(true);

    const URL = `${process.env.REACT_APP_API_PATH}/consultation/${currentConsultId}/notes`;
    let data = values; /**{notes: ..., } */
    axios
      .post(URL, data, {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      })
      .then((response) => {
        fetchSingleTelevisit(currentConsultId);
        setModal(false);
        // fetchTelevisitData();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const dispatch = useDispatch();

  const getDataOnEnter = (selectedPage) => {
    //get user entered data on search box
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("search");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        memberSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: false,
      });
      return fetchMemberData(searchParam, selectedPage);
    }
    fetchMemberData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("search");
    let searchParam = "";

    if (msearch) {
      let searchParam = {
        memberSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });

      return fetchMemberData(searchParam, selectedPage);
    }
    fetchMemberData(searchParam, selectedPage);
  };
  //goup_id

  const fetchSingleTelevisit = (goup_id) => {
    if (!goup_id) {
      return;
    }
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = process.env.REACT_APP_API_PATH + "/consultation/" + goup_id;

    axios
      .get(url, options)
      .then(function (response) {
        let response_data = response.data;
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(setSingleTelevisit(response_data, message, null));
        setTelevisitData({
          gloading: false,
          gdata: response_data,
          // gid: "5fa3d926dc86ba3e9df2a945",
          gid: goup_id,

          gmessage: message,
        });
      })
      .catch(function (err) {
        console.log(err.message);
        var message = checkAjaxError(err);
        setTelevisitData({
          gloading: false,
          gdata: null,
          gid: null,
          gmessage: message,
        });
        dispatch(setSingleTelevisit(null, message));
      })
      .then(function () {});
  };

  //Get Televisit Member Data
  const fetchMemberData = (param, currentPage = 1) => {
    let televisit_id = 0;
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      televisit_id = split_url ? split_url[3] : "";
      televisit_id = televisit_id ? televisit_id.split("&") : "";
      televisit_id = televisit_id ? televisit_id[0] : "";
    }
    let url = process.env.REACT_APP_API_PATH + `/consultation/${televisit_id}`;
    let searchParam = "";
    // if (param) {
    //   searchParam = param.memberSearchParam
    //     ? "&search=" + param.memberSearchParam
    //     : "";
    //   url =
    //     process.env.REACT_APP_API_PATH +
    //     `/consultation?page=${currentPage}&limit=${postsPerPage}&groupId=${televisit_id}` +
    //     searchParam;
    // }

    const headers = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    axios
      .get(url, headers)
      .then(function (response) {
        const data = response.data;
        let message = "";
        message = data.result.total > 0 ? "" : "No Result found!";

        dispatch(setMemberListing(data.result.data, message));

        setMData({
          mloading: false,
          mmembers: data.result.data,
          mmessage: message,
        });

        setTotalPosts(data.result.total / postsPerPage);
        setCurrentPage(currentPage - 1);
        document
          .getElementsByClassName("dr-msearch-spinner")[0]
          .classList.add("d-none");
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        setMData({
          mloading: false,
          mmembers: null,
          mmessage: message,
        });
      })
      .then(function () {});
  };

  const fetchPharmacyData = (pharmacyId, fulfilment) => {
    if (fulfilment == "MTM") {
      let pharmaciesArr = televisitData?.gdata?.memberId?.mtmPharmacies;
      const pharmacyObj = pharmaciesArr?.find(
        (e) => e.pharmacy.PharmacyId == pharmacyId
      );
      setPharmacyData(pharmacyObj?.pharmacy);
    } else {
      let url = process.env.REACT_APP_API_PATH + `/pharmacy/${pharmacyId}`;
      const options = {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      };
      axios
        .get(url, options)
        .then((response) => {
          setPharmacyData(response.data?.data);
        })
        .catch((err) => {
          console.log(err.message);
          setPharmacyData(null);
        });
    }
  };

  const fetchLogsData = (consultId) => {
    let url =
      process.env.REACT_APP_API_PATH + `/consultation/${consultId}/logs`;
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios
      .get(url, options)
      .then((response) => {
        setLogsData(response?.data);
      })
      .catch((err) => {
        console.log(err.message);
        setLogsData(null);
      });
  };

  const fetchPrescriptions = (consultId) => {
    let url =
      process.env.REACT_APP_API_PATH +
      `/consultation/${consultId}/prescriptions`;
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios
      .get(url, options)
      .then((response) => {
        setPrescriptions(response?.data);
      })
      .catch((err) => {
        console.log(err.message);
        setPrescriptions(null);
      });
  };

  const fetchDoctorData = () => {
    if (loginInfo.authUserType !== "doctor") return;
    let url =
      process.env.REACT_APP_API_PATH +
      `/doctor/${loginInfo.authDoctorId}?populate=false`;
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios
      .get(url, options)
      .then((response) => {
        setDoctorData(response?.data);
      })
      .catch((err) => {
        console.log(err.message);
        setDoctorData(null);
      });
  };
  useEffect(() => {
    let fulfilment = televisitData?.gdata?.consultationPlatform;
    let pharmacyId;
    if (fulfilment == "MTM") {
      pharmacyId = televisitData?.gdata?.mtmSureScriptPharmacyId;
    } else {
      pharmacyId = televisitData?.gdata?.pharmacy_pharmacyId;
    }
    if (pharmacyId) {
      fetchPharmacyData(pharmacyId, fulfilment);
    }
    let consultId = televisitData?.gdata?.id;
    if (consultId) {
      fetchLogsData(consultId);
      fetchPrescriptions(consultId);
    }
  }, [televisitData]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    fetchDoctorData();
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("search");
    let goup_id = "";
    if (msearch) {
      let searchParam = {
        memberSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });

      fetchMemberData(searchParam);
    }
    //  fetchMemberData();
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    fetchSingleTelevisit(goup_id);
    fetchMemberData();
    // let eventId = localStorage.getItem('eventId');
    // if (eventId) {
    //   reserveEventLog(eventId)
    // }
    return cleanup;
  }, []);

  const cleanup = () => {
    let eventId = localStorage.getItem("eventId");
    if (eventId) {
      releaseEventLog(eventId);
    }
  };

  const reserveEventLog = async (eventId) => {
    if (!eventId || eventId == "") return;
    let url = `${process.env.REACT_APP_API_PATH}/eventlog/${eventId}/reserved`;
    let bearer_token = localStorage.getItem("auth_token");
    const options = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const data = {
      userId: JSON.parse(localStorage.getItem("auth_user")).id,
    };
    axios
      .patch(url, data, options)
      .then((response) => {})
      .catch((error) => {
        console.log("Could not reserve. Error -> ", error.response.data);
      });
  };

  const releaseEventLog = async (eventId) => {
    if (!eventId || eventId == "") return;
    let url = `${process.env.REACT_APP_API_PATH}/eventlog/${eventId}/released`;
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const data = {
      userId: JSON.parse(localStorage.getItem("auth_user")).id,
    };
    axios
      .patch(url, data, options)
      .then((response) => {
        localStorage.removeItem("eventId");
      })
      .catch((error) => {
        console.log("Could not release. Error -> ", error.response.data);
      });
  };

  const propsUpdateTelevisitData = () => {};

  const { gloading, gid, gmessage } = televisitData;
  const { mloading, mmembers, mmessage } = mdata;
  const member_action = useSelector((state) => state.Member.action_type);
  const loginInfo = useSelector((state) => state.Login);

  const gdata = useSelector((state) => state.Doctor.singleTelevisit);
  const getTelevisitObj = useSelector((state) => state.Televisit);
  const [singleConsultData, setSingleConsultData] = useState({});
  const [singleAdminNote, setSingleAdminNote] = useState({});
  //Logic for Upcoming
  const scheduleFrom = moment(gdata?.schedule_from, moment.ISO_8601);
  const currentDate = moment();
  const isUpcoming = scheduleFrom.isAfter(currentDate);

  const newAdminNote = {
    notesBy: JSON.parse(localStorage.getItem("auth_user")).id,
    patientName: gdata?.patient,
    partnerId: gdata?.partnerId?.id,
    consultationId: gdata?.id,
    isActive: true,
    isInternal: true,
  };

  // Apply Member Search
  function handleSearch(e) {
    e.preventDefault();
    document
      .getElementsByClassName("dr-msearch-spinner")[0]
      .classList.remove("d-none");

    fetchMemberData(searchFormDetails);
    setSearchFormDetails({
      reset: true,
    });
    props.history.push(
      "/televisits-overview/id/" +
        `${gid}&search=` +
        searchFormDetails.memberSearchParam
    );
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    fetchMemberData();
    setSearchFormDetails({
      reset: false,
    });
    document.getElementById("member-search-form").reset();
    props.history.push("/televisits-overview/id/" + `${gid}`);
  }
  // get the Medications data
  const featchMadication = () => {
    let bearer_token = localStorage.getItem("auth_token");
    if (televisitData?.gdata?.memberId?.id) {
      let baseurl = `${process.env.REACT_APP_API_PATH}/member/${televisitData?.gdata?.memberId?.id}/medication`;

      axios
        .get(baseurl, {
          headers: {
            Authorization: bearer_token,
            PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
          },
        })
        .then((response) => {
          setMedications(response.data.data);
        });
    }
  };

  const propsUpdateMemberData = (data) => {
    if (!mmembers && !props.member) {
      return;
    }

    if (mmembers && props.member) {
      let member_id = props.member._id;
      //Update record
      if (member_action === 2) {
        var index = mmembers.findIndex((x) => x._id === member_id);
        mmembers[index] = props.member;
        props.history.push("/televisits-overview/id/" + televisitData.gid);
      }
      //Insert new record
      if (member_action === 1) {
        mmembers.unshift(props.member);
      }

      let activeRow = document.querySelectorAll(`[data-row-id="${member_id}"]`);
      if (activeRow[0]) {
        activeRow[0].classList.add("active");
        const timer = setTimeout(() => {
          activeRow[0].classList.remove("active");
        }, 3500);
        return () => clearTimeout(timer);
      }
      setMData({
        mloading: false,
        mmembers: mmembers,
      });
      return mmembers;
    }
  };

  const resetConsultation = () => {
    let split_url = props.location.pathname.split("/");
    let consultation_id = split_url ? split_url[3] : "";
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    let url =
      process.env.REACT_APP_API_PATH +
      "/consultation/" +
      consultation_id +
      "/reset";

    let data = {};

    axios
      .patch(url, data, headers)
      .then(function (response) {
        setResetLoader(false);
        fetchSingleTelevisit(consultation_id);
        fetchMemberData();
      })
      .catch(function (err) {
        // var message = checkAjaxError(err);
        // if (err.response) {
        //   if (err.response.status === 400) {
        //     message = "No record found.";
        //   }
        // }
      });
  };

  useEffect(() => {
    featchMadication();
  }, [televisitData]);

  // fetch the single televisitData after admin note create
  useEffect(() => {
    if (successAdminNote) {
      let goup_id = "";
      if (props.location.pathname) {
        let split_url = props.location.pathname.split("/");
        goup_id = split_url ? split_url[3] : "";
      }
      fetchSingleTelevisit(goup_id);
      setSuccessAdminNote(false);
    }
  }, [successAdminNote]);

  const backToTelevisitListLink = () => {
    const televisitsLocation = localStorage.getItem("televisits_location");
    return televisitsLocation ? (
      <button
        className="btn btn-info waves-effect mr-2"
        onClick={() => {
          localStorage.removeItem("televisits_location");
          props.history.push(televisitsLocation);
        }}
      >
        <i className="mdi mdi-arrow-left mr-1"></i> {props.t("Back to List")}
      </button>
    ) : (
      ""
    );
  };
  //Update consultation status button
  const updateConsultationStatus = () => {
    return authUserType === "admin" ? (
      <button
        className=""
        onClick={() => {
          setOpenUpdateStatusModal(true);
        }}
        className="btn mr-3 cursor-na"
        style={{
          backgroundColor: "#963b58",
          color: "#f9f4fa",
          cursor: isUpcoming ? "not-allowed" : "",
        }}
        disabled={isUpcoming}
      >
        {/* {resetLoader && (
          <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
        )} */}
        {props.t("Update Consultation Status")}
      </button>
    ) : null;
  };

  //Excuse note button
  const excuseNoteButton = () => {
    return authUserType === "admin" &&
      gdata?.consultationExcuseNote.length > 0 ? (
      <button
        onClick={() => {
          singleConsultData.consultationId =
            gdata?.consultationExcuseNote[0]?.consultationId;
          singleConsultData.id = gdata?.consultationExcuseNote[0]?.id;
          singleConsultData.to = gdata?.consultationExcuseNote[0]?.to;
          singleConsultData.date = gdata?.consultationExcuseNote[0]?.date;
          singleConsultData.pleaseExcuse = capitalizeWords(
            gdata?.consultationExcuseNote[0]?.pleaseExcuse
          );
          singleConsultData.from = gdata?.consultationExcuseNote[0]?.from;
          singleConsultData.through = gdata?.consultationExcuseNote[0]?.through;
          singleConsultData.notes = gdata?.consultationExcuseNote[0]?.notes;
          setExcuseNoteModal(true);
        }}
        className="btn mr-3"
        style={{ backgroundColor: "#2c7f96", color: "#f9f4fa" }}
      >
        {props.t("Excuse Note")}
      </button>
    ) : null;
  };

  const reactNativeBackToTelevisitListLink = () => {
    const televisitsLocation = localStorage.getItem("televisits_location");
    return televisitsLocation ? (
      // <button
      //   className="btn btn-info waves-effect waves-light mr-2"
      //   onClick={() => {
      //     localStorage.removeItem("televisits_location");
      //     props.history.push(televisitsLocation);
      //   }}
      // >
      <i
        style={{ position: "relative", top: "-51px", right: "-14px" }}
        onClick={() => {
          localStorage.removeItem("televisits_location");
          props.history.push(televisitsLocation);
        }}
        className="mdi mdi-arrow-left font-size-18"
      ></i>
    ) : (
      // </button>
      ""
    );
  };

  const completedPopup = async () => {
    setconfirm_both(true);
  };

  const handleCompleted = async () => {
    let pathname = props?.history?.location?.pathname;
    let pathParts = pathname.split("/");
    let consultationId = pathParts[pathParts.length - 1];

    const data = {
      doctorId: authDoctorId,
    };
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/consultation/${consultationId}/completed`;
    axios
      .patch(url, data, options)
      .then((response) => {
        props.history.push("/dashboard");
      })
      .catch((error) => {
        console.log("Completed failed.", error.response.data);
      });
  };

  const renderPharmacyDetails = (pharmacyData) => {
    return (
      <p className="text-muted font-weight-13 ">
        <b>{pharmacyData.StoreName}</b>
        <br />
        <span className="text-justify">
          {pharmacyData.Address1}
          <br />
          <span>
            {pharmacyData.City}, {pharmacyData.State} {pharmacyData.ZipCode}
          </span>
          <br />
          {props.t("P")}: {pharmacyData.PrimaryPhone}
          <br />
          {props.t("F")}: {pharmacyData.PrimaryFax}
        </span>
      </p>
    );
  };

  const renderTelevisitLogs = (logs) => {
    return logs && logs.length > 0 ? (
      logs.map((log, index) => {
        return (
          <Tr key={index} data-row-id={log.id}>
            <Td>
              <div>{dctDateFormat(log.createdAt)}</div>
            </Td>
            <Td>
              <h5 className="text-truncate font-size-14">
                {log.actionBy?.firstName + " " + log.actionBy?.lastName}
              </h5>
            </Td>
            <Td>{log.action}</Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="3" className="text-center"></Td>
      </Tr>
    );
  };

  const MEDICATION_STATUS = [
    "Unknown",
    "Active",
    "2",
    "Discontinued",
    "Deleted",
    "Completed",
    "6",
    "7",
    "Cancelled",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
  ];

  const handleEditAdminNoteModal = (adminNotes) => {
    setSingleAdminNote(adminNotes);
    setOpenNoteModal(true);
    setEditNoteModal(true);
  };

  const renderPrescriptions = (medications) => {
    return medications && medications.length > 0 ? (
      medications.map((medication, index) => {
        return (
          <Tr key={index} data-row-id={medication.PrescriptionId}>
            <Td>{medication.DisplayName ? medication.DisplayName : ""}</Td>
            <Td>{medication.Strength ? medication.Strength : ""}</Td>
            <Td>{medication.Dosage ? medication.Dosage : ""}</Td>
            <Td>{medication.Quantity ? medication.Quantity : ""}</Td>
            <Td>{medication.Refills ? medication.Refills : ""}</Td>
            <Td>{MEDICATION_STATUS[medication.Status]}</Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="6" className="text-center"></Td>
      </Tr>
    );
  };
  return (
    <React.Fragment>
      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-company-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          Notes
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <AvField
                      name="notes"
                      label={props.t("Notes")}
                      className="form-control"
                      placeholder={props.t("Make a new note")}
                      type="textarea"
                      value={currentNote}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setModal(!modal)}
                    >
                      {props.t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                    >
                      <span className="dr-gform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                {gmessage ? (
                  <Alert color="danger" className="text-center">
                    {gmessage}
                  </Alert>
                ) : null}

                {gdata ? (
                  <CardBody>
                    <div
                      className="televisit-details-section"
                      data-id={gdata.id}
                    >
                      <Row>
                        <Col lg="4">
                          <Media>
                            <div className="avatar-sm mr-4">
                              <span className="avatar-title font-size-20 rounded-circle text-capitalize">
                                {gdata?.patient.charAt(0)}
                              </span>
                            </div>
                            <Media className="overflow-hidden" body>
                              <h5 className="font-size-15">{gdata.patient}</h5>
                              <p className="text-muted">
                                {props.t(" Televisit Status")}:
                                <Badge
                                  color="primary"
                                  className="font-size-12 ml-2 text-capitalize"
                                >
                                  {isUpcoming ?props.t("Upcoming") :props.t(gdata?.status)}
                                </Badge>
                              </p>
                            </Media>
                          </Media>
                        </Col>

                        <Col lg="8">
                          <div className="text-right">
                            {/* <CreateUpdateTelevisit
                              updateTelevisitDataFlag={propsUpdateTelevisitData}
                              televisit_action_type="edit"
                              televisit_button_type="edit_btn"
                              gdata={gdata}
                              {...props}
                            /> */}

                            {excuseNoteButton()}
                            {updateConsultationStatus()}
                            {window.ReactNativeWebView
                              ? reactNativeBackToTelevisitListLink()
                              : backToTelevisitListLink()}
                          </div>
                        </Col>
                      </Row>

                      {!window.ReactNativeWebView ? null : gdata?.status ===
                        "completed" ? (
                        props.t("Consultation has been closed")
                      ) : gdata?.status === "cancelled" ? (
                        props.t("Consultation has been Cancelled")
                      ) : moment().diff(gdata?.schedule_from, "minutes") <
                        -1 ? (
                        props.t("Consultation has not yet started")
                      ) : moment().diff(gdata?.schedule_to, "minutes") > 0 ? (
                        props.t("Consultation time has passed.")
                      ) : JSON.parse(localStorage.getItem("auth_user"))
                          .userType === "member" ? (
                        <Row>
                          <div style={{ width: "100%" }} className="mt-2">
                            {gdata?.consultationType !== "phone" &&
                            window.ReactNativeWebView ? (
                              <button
                                style={{ width: "100%" }}
                                className="btn btn-primary"
                                onClick={() => {
                                  setClick(true);
                                  document
                                    .getElementsByClassName(
                                      "dr-mform-spinner"
                                    )[0]
                                    .classList.remove("d-none");
                                  document.getElementsByTagName("button")[
                                    document.getElementsByTagName("button")
                                      .length - 1
                                  ].disabled = true;
                                  const v = window.location.pathname.split("/");
                                  let url = `${
                                    process.env.REACT_APP_API_PATH
                                  }/consultation/${
                                    v[v.length - 1]
                                  }/video-room-token`;
                                  let bearer_token =
                                    localStorage.getItem("auth_token");
                                  const headers = {
                                    headers: {
                                      Authorization: bearer_token,
                                      PartnerAuth:
                                        process.env
                                          .REACT_APP_PARTNER_AUTH_TOKEN,
                                    },
                                  };
                                  axios.get(url, headers).then((response) => {
                                    if (response.status === 200) {
                                      document
                                        .getElementsByClassName(
                                          "dr-mform-spinner"
                                        )[0]
                                        .classList.add("d-none");
                                      document.getElementsByTagName("button")[
                                        document.getElementsByTagName("button")
                                          .length - 1
                                      ].disabled = false;
                                      setClick(false);
                                    }

                                    if (window.ReactNativeWebView) {
                                      window.ReactNativeWebView.postMessage(
                                        "TOKEN:" +
                                          `/consultation/${
                                            v[v.length - 1]
                                          }/room-token/` +
                                          response.data.token
                                      );
                                    }
                                  });
                                }}
                              >
                                <span className="dr-mform-spinner d-none">
                                  <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>
                                </span>
                                {!click && <i className="fas fa-video"> </i>}
                                &nbsp; Join Video Room
                              </button>
                            ) : null}
                          </div>
                        </Row>
                      ) : null}
                      <h5 className="font-size-15 mt-4">
                        {props.t("Televisit Details")} :
                      </h5>

                      <div className="text-muted mt-4">
                        <Row>
                          <Col lg="6">
                            <p>
                              <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                              <strong>{props.t("Main Complaint")}: </strong>{" "}
                              {gdata?.details_chiefcomplaint}
                            </p>
                            <p>
                              <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                              <strong>{props.t("Common Symptoms")}: </strong>{" "}
                              {gdata?.details_commonSymptoms?.map(
                                (value, index) => {
                                  return index <=
                                    gdata?.details_commonSymptoms.length - 2
                                    ? value + ", "
                                    : value;
                                }
                              )}
                            </p>

                            <p>
                              <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                              <strong>{props.t("Other Symptoms")}: </strong>{" "}
                              {gdata?.details_allOtherSymptoms?.map(
                                (value, index) => {
                                  return index <=
                                    gdata?.details_allOtherSymptoms.length - 2
                                    ? value + ", "
                                    : value;
                                }
                              )}
                            </p>
                            <p>
                              <i className="bx bxs-droplet-half text-primary mr-1"></i>{" "}
                              <strong>{props.t("Blood Type")}: </strong>{" "}
                              {gdata?.memberId.bloodType}
                            </p>
                            <p>
                              <i className="bx bx-donate-blood text-primary mr-1"></i>{" "}
                              <strong>
                                {props.t("Systolic Blood Pressure")}:{" "}
                              </strong>{" "}
                              {gdata?.memberId.bloodPressureSystolic}
                            </p>
                            <p>
                              <i className="bx bx-donate-blood text-primary mr-1"></i>{" "}
                              <strong>
                                {props.t("Diastolic Blood Pressure")}:{" "}
                              </strong>{" "}
                              {gdata?.memberId.bloodPressureDiastolic}
                            </p>
                            <p>
                              <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                              <strong>{props.t("Smoke")}: </strong>{" "}
                              {gdata?.memberId.smoke}
                            </p>
                            <p>
                              <i className="bx bxs-drink text-primary mr-1"></i>{" "}
                              <strong>{props.t("Drink")}: </strong>{" "}
                              {gdata?.memberId.drink}
                            </p>
                            <p>
                              <i className="bx bx-dumbbell text-primary mr-1"></i>{" "}
                              <strong>{props.t("Exercise")}: </strong>{" "}
                              {gdata?.memberId.exercise}
                            </p>
                            <div>
                              <i className="bx bx-plus-medical text-primary mr-1"></i>{" "}
                              <strong>{props.t("Pharmacy")}: </strong>{" "}
                              {pharmacyData
                                ? renderPharmacyDetails(pharmacyData)
                                : gdata?.pharmacy_pharmacyId}
                            </div>
                          </Col>

                          <Col lg="6">
                            <p className="text-capitalize">
                              <i className="bx bx-user text-primary mr-1"></i>{" "}
                              <strong>{props.t("Patient Name")} : </strong>{" "}
                              {gdata?.patient}
                            </p>
                            <p>
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <strong>{props.t("Main Contact Email")}: </strong>{" "}
                              <a
                                href={"mailto:" + gdata.mainContactEmail}
                                className="text-dark"
                              >
                                {gdata?.mainContactEmail}
                              </a>
                            </p>

                            <p>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("Primary Phone")}: </strong>{" "}
                              <a
                                href={"tel:" + gdata.phone_tel}
                                className="text-dark"
                              >
                                {gdata?.phone_tel}
                              </a>
                            </p>
                            <p>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("Consultation Type")}: </strong>{" "}
                              {gdata?.consultationType === "video"
                                ? "Video Call"
                                : "Audio Call"}
                            </p>
                            <p>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("Phone Service")}: </strong>{" "}
                              {gdata?.phone_service}
                            </p>
                            <p>
                              <i className="bx bxs-map text-primary mr-1"></i>{" "}
                              <strong>{props.t("Country")}: </strong>{" "}
                              {gdata?.state_country}
                            </p>
                            <p>
                              <i className="bx bxs-map text-primary mr-1"></i>{" "}
                              <strong>{props.t("State")}: </strong>{" "}
                              {gdata?.state_currentState}
                            </p>
                          </Col>
                          <Col lg="12 mt-3">
                            <strong>{props.t("Details")}: </strong>
                          </Col>
                          <Col lg="12 mb-3" className="mr-1">
                            {gdata?.details_description}
                          </Col>

                          {televisitData?.gdata?.consultationPlatform ===
                          "MTM" ? (
                            <>
                              <Col lg="12" className="mt-2">
                                <strong>{props.t("Notes")}: </strong>
                              </Col>
                              <Col lg="12" className="mr-1">
                                <span className="text-wrap">
                                  {" "}
                                  {gdata?.mtmConsultationDetails?.consultNotes}
                                </span>
                              </Col>

                              <Col lg="12" className="mt-2">
                                <strong>{props.t("Doctor Notes")}: </strong>
                              </Col>
                              <Col lg="12" className="mr-2">
                                <ul style={{ paddingLeft: "17px" }}>
                                  {gdata?.mtmConsultationDetails?.doctorNote?.map(
                                    (val, i) => {
                                      return <li key={i}>{val.comment}</li>;
                                    }
                                  )}
                                </ul>
                              </Col>
                            </>
                          ) : (
                            <>
                              {gdata?.notes[0]?.consultationNotes &&
                                gdata?.notes[0]?.consultationNotes?.length >
                                  0 && (
                                  <>
                                    <Col lg="12 mt-2">
                                      <strong>
                                        {props.t("Consultation Notes")}:{" "}
                                      </strong>
                                      {gdata?.notes[0]?.consultationNotes?.map(
                                        (val, i) => {
                                          return (
                                            <Col
                                              key={i}
                                              lg="12"
                                              className="mr-2 my-2"
                                            >
                                              <div>
                                                <span>{val?.notes}</span>
                                              </div>
                                            </Col>
                                          );
                                        }
                                      )}
                                    </Col>
                                  </>
                                )}
                            </>
                          )}
                        </Row>
                      </div>
                      <Row className="task-dates">
                        <Col sm="6" xs="6">
                          <div className="mt-4">
                            <h5 className="font-size-14">
                              <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                              {props.t(" Scheduled At")}
                            </h5>
                            <p className="text-muted mb-0">
                              {/* {gdata?.schedule_timezoneAbbr}, {gdata?.schedule_timezoneUTC}
                              <br/> */}
                              {!gdata?.schedule_from
                                ? ""
                                : dctUtcToLocal(
                                    gdata.schedule_from,
                                    doctorData?.timezoneAbbr
                                  )}
                              <br />
                              {/* {gdata?.schedule_from} */}
                            </p>
                          </div>
                        </Col>
                        <Col sm="6" xs="6">
                          <div className="mt-4">
                            <h5 className="font-size-14">
                              <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                              {props.t("Scheduled Till")}
                            </h5>
                            <p className="text-muted mb-0">
                              {/* {gdata?.schedule_timezoneAbbr}, {gdata?.schedule_timezoneUTC}
                              <br/> */}
                              {!gdata?.schedule_to
                                ? ""
                                : dctUtcToLocal(
                                    gdata.schedule_to,
                                    doctorData?.timezoneAbbr
                                  )}
                              <br />
                              {/* {gdata?.schedule_to} */}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      {/* Admin Notes and Doctor Notes section */}
                      {authUserType === "admin" ? (
                        <Row className="mt-5">
                          <Col lg="6">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div>
                                <strong>{props.t("Admin Notes")}: </strong>
                                <Link
                                  to="#"
                                  className={
                                    "badge badge-soft-danger font-size-11 m-1"
                                  }
                                >
                                  {props.t("Internal")}
                                </Link>
                              </div>
                            </div>
                            <Card
                              style={{
                                backgroundColor: "#F4F4F4",
                                maxHeight: "500px",
                                overflowY: "auto",
                                marginTop: "20px",
                              }}
                            >
                              <CardBody
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {gdata?.notes[0]?.adminNotes?.length > 0 ===
                                true ? (
                                  <div style={{ flex: 1 }}>
                                    <Col lg="12" className="my-2">
                                      {gdata?.notes[0]?.adminNotes
                                        ?.sort(
                                          (a, b) =>
                                            new Date(b?.createdAt) -
                                            new Date(a?.createdAt)
                                        )
                                        ?.map((adminNotes, id) => (
                                          <div
                                            style={{
                                              backgroundColor: "#ffffff",
                                              padding: "20px",
                                              borderRadius: "5px",
                                              marginTop: "10px",
                                              minHeight: "80px",
                                              marginBottom: "30px",
                                            }}
                                            key={id}
                                          >
                                            <div
                                              className="d-flex align-items-center justify-content-between"
                                              style={{
                                                marginTop: "-10px",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <div>
                                                <span className="badge badge-soft-primary font-size-11 m-1">
                                                  {/* { let fullName = adminNotes?.notesBy?.firstName + adminNotes?.notesBy?.lastName} */}
                                                  {capitalizeWords(
                                                    adminNotes?.notesBy
                                                      ?.firstName
                                                  )}{" "}
                                                  {capitalizeWords(
                                                    adminNotes?.notesBy
                                                      ?.lastName
                                                  )}
                                                </span>
                                                <span className="ml-2 font-size-11">
                                                  {moment(
                                                    new Date(
                                                      adminNotes?.createdAt
                                                    ),
                                                    "MM-DD-YYYY"
                                                  ).format(
                                                    "MMMM DD, YYYY"
                                                  )}{" "}
                                                  -{" "}
                                                  {moment(
                                                    new Date(
                                                      adminNotes?.createdAt
                                                    )
                                                  ).format("hh:mm a")}
                                                </span>
                                              </div>
                                              {/* 24 hrs condition for edit the admin notes */}
                                              {Math.floor(
                                                (new Date() -
                                                  new Date(
                                                    adminNotes?.createdAt
                                                  )) /
                                                  (1000 * 60 * 60)
                                              ) > 24 ||
                                              adminNotes?.notesBy?.userId !==
                                                authUserId ? (
                                                <span
                                                  to="#"
                                                  style={{
                                                    cursor: "not-allowed",
                                                  }}
                                                  title="Lock"
                                                >
                                                  <i className="bx bx-lock-alt font-size-18"></i>
                                                </span>
                                              ) : (
                                                <span
                                                  to="#"
                                                  onClick={() =>
                                                    handleEditAdminNoteModal(
                                                      adminNotes
                                                    )
                                                  }
                                                  className="cursor-pointer"
                                                  title="Edit"
                                                >
                                                  <i className="bx bx-edit-alt font-size-18"></i>
                                                </span>
                                              )}
                                            </div>
                                            <p className="m-1">
                                              {adminNotes?.notes}
                                            </p>
                                          </div>
                                        ))}
                                    </Col>
                                  </div>
                                ) : (
                                  <p className="text-danger">
                                    {props.t(
                                      "Currently no existing any admin notes for this consultations."
                                    )}
                                  </p>
                                )}

                                <div
                                  style={{
                                    position: "sticky",
                                    bottom: "0",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    backgroundColor: "#F4F4F4",
                                  }}
                                >
                                  <div className="text-right mr-3">
                                    <Button
                                      className="bg-primary"
                                      onClick={() => setOpenNoteModal(true)}
                                    >
                                      + {props.t("New Note")}
                                    </Button>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          {/* Doctor section */}
                          <Col lg="6">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div>
                                <strong>{props.t("Doctor Notes")}: </strong>
                                <Link
                                  to="#"
                                  className={
                                    "badge badge-soft-danger font-size-11 m-1"
                                  }
                                >
                                  {props.t("Internal")}
                                </Link>
                              </div>
                            </div>
                            <Card
                              style={{
                                backgroundColor: "#F4F4F4",
                                maxHeight: "500px",
                                overflowY: "auto",
                                marginTop: "20px",
                              }}
                            >
                              <CardBody
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {gdata?.notes[0]?.doctorNotes?.length > 0 ===
                                true ? (
                                  <div style={{ flex: 1 }}>
                                    <Col lg="12" className="my-2">
                                      {gdata?.notes[0]?.doctorNotes
                                        ?.sort(
                                          (a, b) =>
                                            new Date(b?.createdAt) -
                                            new Date(a?.createdAt)
                                        )
                                        ?.map((notes, index) => (
                                          <div
                                            style={{
                                              backgroundColor: "#ffffff",
                                              padding: "20px",
                                              borderRadius: "5px",
                                              marginTop: "10px",
                                              minHeight: "80px",
                                              marginBottom: "30px",
                                            }}
                                            key={index}
                                          >
                                            <div
                                              className="d-flex align-items-center justify-content-between"
                                              style={{
                                                marginTop: "-10px",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <div>
                                                <span className="badge badge-soft-primary font-size-11 m-1">
                                                  {capitalizeWords(
                                                    notes?.notesBy?.firstName
                                                  )}{" "}
                                                  {capitalizeWords(
                                                    notes?.notesBy?.lastName
                                                  )}
                                                </span>
                                                <span className="ml-2 font-size-11">
                                                  {moment(
                                                    new Date(notes?.createdAt),
                                                    "MM-DD-YYYY"
                                                  ).format(
                                                    "MMMM DD, YYYY"
                                                  )}{" "}
                                                  -{" "}
                                                  {moment(
                                                    new Date(notes?.createdAt)
                                                  ).format("hh:mm a")}
                                                </span>
                                              </div>
                                              <span
                                                to="#"
                                                style={{
                                                  cursor: "not-allowed",
                                                }}
                                                title="Lock"
                                              >
                                                <i className="bx bx-lock-alt font-size-18"></i>
                                              </span>
                                            </div>
                                            <p className="m-1">
                                              {notes?.notes}
                                            </p>
                                          </div>
                                        ))}
                                    </Col>
                                  </div>
                                ) : (
                                  <p className="text-danger">
                                    {props.t(
                                      "Currently no existing any doctor notes for this consultations."
                                    )}
                                  </p>
                                )}

                                <div
                                  style={{
                                    position: "sticky",
                                    bottom: "0",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    backgroundColor: "#F4F4F4",
                                  }}
                                >
                                  <div className="text-right mr-3">
                                    <Button
                                      className="btn btn-primary waves-effect waves-light"
                                      style={{
                                        opacity: "0.5",
                                        cursor: "not-allowed",
                                      }}
                                      onClick={() => setOpenNoteModal(true)}
                                      disabled={true}
                                    >
                                      + {props.t("New Note")}
                                    </Button>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}

                      <hr className="mt-3 mb-3" />
                      <h5 className="font-size-15 mt-4">
                        {props.t("Televisit Logs")} :
                      </h5>
                      <Row>
                        <Col lg="12">
                          <div className="table-responsive">
                            <Table
                              className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}
                            >
                              <Thead className="thead-light">
                                <Tr>
                                  <Th scope="col">{props.t("Date/Time")}</Th>
                                  <Th scope="col">{props.t("User")}</Th>
                                  <Th scope="col">{props.t("Action")}</Th>
                                </Tr>
                              </Thead>
                              <Tbody>{renderTelevisitLogs(logsData)}</Tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                      <hr className="mt-3 mb-3" />
                      <h5 className="font-size-15 mt-4">
                        {props.t("Medications")} :
                      </h5>
                      <Row>
                        <Col lg="12">
                          <div className="table-responsive">
                            <Table
                              className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}
                            >
                              <Thead className="thead-light">
                                <Tr>
                                  <Th scope="col">{props.t("Name")}</Th>
                                  <Th scope="col">{props.t("Strength")}</Th>
                                  <Th scope="col">{props.t("Dosage")}</Th>
                                  <Th scope="col">{props.t("Quantity")}</Th>
                                  <Th scope="col">{props.t("Refills")}</Th>
                                  <Th scope="col">{props.t("Status")}</Th>
                                </Tr>
                              </Thead>
                              <Tbody>{renderPrescriptions(Medications)}</Tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                      <hr className="mt-3 mb-3" />
                      <Row>
                        <Col sm="6" xs="6">
                          <div className="mt-2">
                            <h5 className="font-size-14">
                              <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                              {props.t("Created")}
                            </h5>
                            <p className="text-muted mb-0">
                              {moment(gdata?.createdAt).calendar()}
                            </p>
                          </div>
                        </Col>
                        <Col sm="6" xs="6">
                          <div className="mt-2">
                            <h5 className="font-size-14">
                              <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                              {props.t("Updated")}
                            </h5>
                            <p className="text-muted mb-0">
                              {moment(gdata?.updatedAt).calendar()}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <hr className="mt-3 mb-3" />
                      {gdata?.status === "completed" ? (
                        props.t("Consultation has been closed")
                      ) : gdata?.status === "cancelled" ? (
                        props.t("Consultation has been Cancelled")
                      ) : moment().diff(gdata?.schedule_from, "minutes") <
                        -1 ? (
                        props.t("Consultation has not yet started")
                      ) : moment().diff(gdata?.schedule_to, "minutes") > 0 ? (
                        props.t("Consultation time has passed.")
                      ) : gdata.consultationPlatform === "Doctegrity" ? (
                        JSON.parse(localStorage.getItem("auth_user"))
                          .userType === "doctor" ? (
                          <Row>
                            <div className="mt-2">
                              {gdata?.consultationType !== "phone" ? (
                                <VideoCallJsPanel
                                  updateLogsData={fetchLogsData}
                                  data={gdata?.id}
                                ></VideoCallJsPanel>
                              ) : (
                                <VoiceCallInterface
                                  addtionalID={gdata?.id}
                                  flag={false}
                                  phone={gdata?.phone_tel}
                                />
                              )}
                            </div>
                            &nbsp;
                            <div className="mt-2">
                              <button
                                className="btn btn-warning"
                                onClick={() => {
                                  setModal(true);
                                  setCurrentNote(
                                    gdata.notes[gdata.notes?.length - 1]?.notes
                                  );
                                  setCurrentConsultId(gdata?.id);
                                }}
                              >
                                {props.t(`Notes`)}{" "}
                                <i className="bx bx-notepad"></i>
                              </button>
                            </div>
                            &nbsp;
                            {gdata?.state_country === "US" ? (
                              <div className="mt-2">
                                <PharmacyJsPanel
                                  consultationId={gdata?.id}
                                ></PharmacyJsPanel>
                              </div>
                            ) : null}
                            &nbsp;
                            <div className="mt-2">
                              <button
                                className="btn btn-success waves-effect waves-light"
                                type="submit"
                                onClick={completedPopup}
                              >
                                {props.t("Completed")}
                              </button>
                            </div>
                          </Row>
                        ) : JSON.parse(localStorage.getItem("auth_user"))
                            .userType === "member" ? (
                          <Row>
                            <div className="mt-2">
                              {gdata?.consultationType !== "phone" ? (
                                <VideoCallJsPanel
                                  updateLogsData={fetchLogsData}
                                  data={gdata?.id}
                                ></VideoCallJsPanel>
                              ) : null}
                            </div>
                          </Row>
                        ) : null
                      ) : JSON.parse(localStorage.getItem("auth_user"))
                          .userType === "member" ? (
                        <Row>
                          <div className="mt-2">
                            {gdata?.consultationType !== "phone" ? (
                              <TBVideoCallJsPanel
                                apikey={gdata?.mtmOpenTokApiKey}
                                session={gdata?.mtmVideoSessionId}
                                token={gdata?.mtmPrimaryPatientToken}
                              ></TBVideoCallJsPanel>
                            ) : null}
                          </div>
                        </Row>
                      ) : null}
                    </div>
                  </CardBody>
                ) : (
                  <CardBody>
                    {gmessage ? (
                      <div className="text-center">
                        {backToTelevisitListLink()}
                      </div>
                    ) : (
                      <div className="text-center">
                        {loadingMessage("Loading...")}
                      </div>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
          {confirm_both ? (
            <SweetAlert
              title={props.t("Are you sure?")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="secondary"
              onConfirm={() => {
                handleCompleted();
              }}
              onCancel={() => {
                setconfirm_both(false);
              }}
            >
              {props.t("This process will change the Consultation status!")}
            </SweetAlert>
          ) : null}
          {/* Excuse note modal */}
          {excuseNoteModal && (
            <ExcuseNote
              modal={excuseNoteModal}
              setModal={setExcuseNoteModal}
              setOpenSuccessModal={setOpenSuccessModal}
              openSuccessModal={openSuccessModal}
              setEmailMessage={setEmailMessage}
              setOpenPdfModal={setOpenPdfModal}
              singleConsultData={singleConsultData}
              setEmailSentMessage={setEmailSentMessage}
              t={props?.t}
            />
          )}
          {/* success modal */}
          {openSuccessModal && (
            <SuccessModal
              modal={openSuccessModal}
              setModal={setOpenSuccessModal}
              emailMessage={emailMessage}
              setEmailMessage={setEmailMessage}
              pdfModal={pdfModal}
              setOpenPdfModal={setOpenPdfModal}
              statusUpdate={statusUpdate}
              setStatusUpdate={setStatusUpdate}
              editNoteModal={editNoteModal}
              setEditNoteModal={setEditNoteModal}
              statusUpdateMessage={statusUpdateMessage}
              emailSentMessage={emailSentMessage}
              t={props?.t}
            />
          )}
          {/* Update consultation status */}
          {openUpdateStatusModal && (
            <ConsultationUpdateStatusModal
              modal={openUpdateStatusModal}
              setModal={setOpenUpdateStatusModal}
              setOpenSuccessModal={setOpenSuccessModal}
              openSuccessModal={openSuccessModal}
              setStatusUpdate={setStatusUpdate}
              consultationId={gdata?.id}
              setStatusUpdateMessage={setStatusUpdateMessage}
              currentStatus={gdata?.status}
              setSuccessAdminNote={setSuccessAdminNote}
              setShowWaring={setShowWaring}
              setWaringMessage={setWaringMessage}
              t={props?.t}
            />
          )}
          {/* Open create admin note */}
          {openNoteModal && (
            <AdminNotesModal
              modal={openNoteModal}
              setModal={setOpenNoteModal}
              setOpenSuccessModal={setOpenSuccessModal}
              openSuccessModal={openSuccessModal}
              editNoteModal={editNoteModal}
              setEditNoteModal={setEditNoteModal}
              singleAdminNote={singleAdminNote}
              newAdminNote={newAdminNote}
              setSuccessAdminNote={setSuccessAdminNote}
              t={props?.t}

            />
          )}
          {/* Warning modal */}
          {showWaring && (
            <SweetAlert
              title="Oops !"
              warning
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setShowWaring(false);
              }}
            >
              {waringMessage}
            </SweetAlert>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  // const { televisit } = state.Doctor.Televisit;
  // const { member } = state.Member;
  // const { message } = state.Doctor.Televisit;
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {
    setSingleTelevisit,
  })(withNamespaces()(TelevisitsOverview))
);
