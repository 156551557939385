import React from "react";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { Row, Col, Card } from "reactstrap";

import {Config} from './../../Config';

const DctConsultationBanner = (props) => {
  let mainTitle = props.mainTitle ? props.mainTitle : "Patient Details";
  let subTitle = props.subTitle
    ? props.subTitle
    : "Diagnostic Consultation by phone";
  let isCall911 = props.isCall911 ? true : false;
  let isInfoBar = props.isInfoBar ? true : false;
  let isMainBanner = props.isMainBanner ? true : false;
  let bannerImg = props.bannerImg ? props.bannerImg : Config.images.patientFamilyBanner;
  let bannerImgHeight = props.bannerImgHeight ? props.bannerImgHeight : 184;
  let infoBarTitle = props.infoBarTitle
    ? props.infoBarTitle
    : "In a life threatening emergency please call";

  return (
    <React.Fragment>
      <Card className={`${Config.names.slugName}-float-banner mb-0 rounded-0`}>
        <img
          src={bannerImg}
          alt=""
          className={`img-fluid ${Config.names.slugName}-dashboard-profile-img ${Config.names.slugName}-mobile`}
          style={{ height: bannerImgHeight + "px" }}
        />
        <div className={`bg-primary ${Config.names.slugName}-consult-header rounded-top`}>
          <Row>
            <Col className="col-8">
              <div className="text-white p-4">
                <h4 className="text-white font-size-30 sm-font-size-18">
                  <strong>{props.t(mainTitle)} </strong>
                </h4>
                <hr className={`mt-2 mb-1 ${Config.names.slugName}-soft-hr`} />
                <p className="text-white font-size-20 sm-font-size-13">
                  {props.t(subTitle)}{" "}
                </p>
              </div>
            </Col>
            <Col className="col-4 align-self-end mt-4"></Col>
          </Row>
        </div>
        {isInfoBar ? (
          <div className={`bg-warning overflow-hidden ${Config.names.slugName}-float-bar`}>
            <div className="pl-4 pr-4 pt-0 pb-0">
              <Row>
                <Col xs={9}>
                  <span className="text-white pt-2 pb-2 d-inline-block">
                    {props.t(infoBarTitle)}
                  </span>{" "}
                  <span className={`ml-2 bg-danger text-white text-bold rounded-circle p-2 d-inline-block ${Config.names.slugName}-call991 ${Config.names.slugName}-desktop`}>
                    <strong>{911}</strong>
                  </span>
                </Col>
                <Col xs={3}>
                  {isCall911 ? (
                    <span className={`ml-2 bg-danger text-white text-bold rounded-circle p-2 d-inline-block ${Config.names.slugName}-call991 ${Config.names.slugName}-mobile`}>
                      <strong>911</strong>
                    </span>
                  ) : null}
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
      </Card>
    </React.Fragment>
  );
};

// export default DctConsultationBanner;
export default withRouter(withNamespaces()(DctConsultationBanner));
