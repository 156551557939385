import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { RESET_PASSWORD } from "./actionTypes";
import { userResetPasswordSuccess, userResetPasswordError } from "./actions";

//Include Both Helper File with needed methods
import { postResetPwd } from "../../../helpers/backend_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.

//RESET_PASSWORD
function* resetUser({ payload: { user, history } }) {
  let path = history.location.pathname.split("/");
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
  try {
    const response = yield call(
      postResetPwd,
      process.env.REACT_APP_API_PATH + "/auth/reset-password",
      { newPass: user.password, resetLink: path[path.length - 1] }
    );
    document
      .getElementsByClassName("dr-login-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    if (response.success === true) {
      yield put(
        userResetPasswordSuccess(
          "Password Succesfully Changed. It will redirect to login page"
        )
      );
      setTimeout(function() {
        history.push('/login');
     }, 3000);
    } else {
      document
      .getElementsByClassName("dr-login-spinner")[0]
      .classList.add("d-none");
      yield put(userResetPasswordError(response.message));
    }
  } catch (error) {
    document
      .getElementsByClassName("dr-login-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    yield put(userResetPasswordError("Invalid token or it is expired!"));
  }
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetUser);
}

function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)]);
}

export default resetPasswordSaga;
