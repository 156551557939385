import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { Alert } from "reactstrap";
import { Config } from "../Config";

export const dctDefaultAvatar = Config.images.dctDefaultAvatar;

//Ajax Loading Message
export const loadingMessage = (message, type = false, url = false) => {
  let text = message ? message : "Loading...";
  return (
    <React.Fragment>
      <Link to="#" className="text-success">
        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
        {text}{" "}
      </Link>
    </React.Fragment>
  );
};

//Credit Message
export const DctFooterCredit = (name) => {
  return (
    <React.Fragment>
      {/* <p>
        © {new Date().getFullYear()} Doctegrity. Crafted with{" "}
        <i className="mdi mdi-heart text-danger"></i> by{" "}
        <a href="https://www.doctegrity.com/" target="_blank">
          Doctegrity
        </a>
      </p> */}
      <span>Copyright © {name}</span>{Config.names.slugName === "hbaehealth" && <span className="position-absolute" style={{ fontSize: '6px' }}>SM</span>}
    </React.Fragment>
  );
};

//Custom Header
export const DctCustomHeader = (title, subtitle = false) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">{title}</h4>
            {subtitle ? <div className="page-title-right">{subtitle}</div> : ""}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const getAuthUserId = (userType = false) => {
  let authUser = localStorage.getItem("auth_user");
  if (!authUser) return;
  authUser = JSON.parse(authUser);
  let authUserId = authUser.id;
  switch (userType) {
    case "member":
      authUserId = authUser.memberId;
      break;
    case "doctor":
      authUserId = authUser.doctorId;
      break;
    default:
      authUserId = authUser.id;
      break;
  }
  return authUserId;
};

export const getAuthIds = {
  member: getAuthUserId("member"),
  doctor: getAuthUserId("doctor"),
  user: getAuthUserId("admin"),
};

export const capitalizeWords = (string) => {
  return string.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
};

export const dctDateFormat = (date, utcTimezone, type = false) => {
  if (!date) return;
  let format = "DD MMM YYYY, hh:mm a z"; //"MMMM Do YYYY, h:mm:ss a z";
  if (type) {
    format = "MM/DD/YYYY";
  }
  if (!utcTimezone) {
    return moment(date).format(format);
  } else {
    return moment(date).tz(utcTimezone).format(format);
  }
};

export const dctHumanReadableFormat = (date) => {
  if (!date) return;
  let format = "DD MMM YYYY, h:mm:ss a z"; //"MMMM Do YYYY, h:mm:ss a z";
  return moment(date).format(format);
};
// MM-DD-YYYY
export const dctHumanReadableGlobalFormat = (date) => {
  if (!date) return;
  let format = "MM-DD-YYYY"; //"MM-DD-YYYY";
  return moment(date, format).format(format);
};

export const dctDobGlobalFormatForDatePicker = (date) => {
  if (!date) return;
  return new Date(moment(date, "MM-DD-YYYY").format("YYYY-MM-DD"))
};

export const dctHumanReadableDate = (date) => {
  if (!date) return;
  let format = "MMM DD YYYY  Z"; //"MMMM Do YYYY, h:mm:ss a z";

  return moment(date).format(format);
  // return moment.tz(date, "America/Los_Angeles").zoneAbbr();
};

export const dctUtcToLocal = (dateWithTime, timezone) => {
  let format = "DD MMM YYYY, h:mm:ss A z"; //"MMMM Do YYYY, h:mm:ss a z";
  let timezoneUTC = timezone;
  if (timezoneUTC) {
    return moment.utc(dateWithTime).tz(timezoneUTC).format(format);
  }
  timezoneUTC = moment.tz.guess();
  return moment.utc(dateWithTime).tz(timezoneUTC).format(format);
};

export const dctTimeStampToHumanDate = (createdAt, timezone) => {
  let timezoneUTC = timezone;
  if (timezoneUTC) {
    return moment(createdAt).tz(timezoneUTC).format("DD MMM YYYY, hh:mm A z");
  }
  timezoneUTC = "America/New_York"; //moment.tz.guess();
  return moment(createdAt).tz(timezoneUTC).format("DD MMM YYYY, hh:mm A z");
};
export const dctAlert = (type, msg, icon = false, modal) => {
  if (typeof msg != "string") {
    msg = "something went wrong";
  }
  if (!msg) return;
  let msgType = type ? type : 1;

  let alertColor = "success";
  let alertIcon = "mdi-check-all";
  if (msgType === 2) {
    alertColor = "warning";
    alertIcon = "mdi-alert-outline";
  } else if (msgType === 3) {
    alertColor = "danger";
    alertIcon = "mdi-block-helper";
  }
  alertIcon = icon ? icon : alertIcon;

  if (document.getElementById(`${Config.names.slugName}-alert`)) {
    document.getElementById(`${Config.names.slugName}-alert`).classList.remove("d-none");
  }
  var elems = document.querySelectorAll(`.${Config.names.slugName}-alert`);
  [].forEach.call(elems, function (el) {
    el.classList.remove(".d-none");
  });

  return (
    <div className={`${Config.names.slugName}-alert`}>
      <Alert color={alertColor}>
        <button
          type="button"
          className={`close ${Config.names.slugName}-close`}
          aria-label="Close"
          onClick={(e) => {
            var elems = document.querySelectorAll(`.${Config.names.slugName}-alert`);
            [].forEach.call(elems, function (el) {
              el.classList.add("d-none");
            });
          }}
        >
          <span aria-hidden="true">×</span>
        </button>
        <i className={"mdi " + alertIcon + " mdi-alert-outline mr-2"}></i>
        {msg}
      </Alert>
    </div>
  );
};

// Return Consultation status
export const dctConsultationSteps = (consultData) => {
  var statuses = {
    patient_step_status: 1,
    ehr_step_status: 2,
    phone_step_status: 3,
    state_step_status: 4,
    details_step_status: 5,
    schedule_step_status: 6,
    pharmacy_step_status: 7,
  };
  let activeStep = 1;
  for (var key in statuses) {
    if (consultData[key] === "draft") {
      activeStep = statuses[key];
      return activeStep;
    } else {
      activeStep = 8;
    }
  }
  return activeStep;
};

export const dctClsAddRemove = (selector, target, type = "remove") => {
  if (!selector || !target) return;
  if (type === "add") {
    // document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false

    let elems = document.querySelectorAll(`.${selector}`);
    [].forEach.call(elems, function (el) {
      el.classList.add(`${target}`);
    });
  } else {
    // document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    let elems = document.querySelectorAll(`.${selector}`);
    [].forEach.call(elems, function (el) {
      el.classList.remove(`${target}`);
    });
  }
};

export const dctMessages = {
  0: "Please fill all the requried fields.",
  1: "Test msg",
};

// Get Member or Dependent Primary Phone number
export const dctGetMemberAndDependPastData = (memberData, consultData) => {
  if (!memberData || !consultData) return;

  let pastData = {
    primaryPhone: "",
    state: "",
    timezoneAbbr: "",
    timezoneUTC: "",
  };

  if (!consultData.dependentId) {
    pastData.primaryPhone = memberData.primaryPhone;
    pastData.state = memberData.state;
    pastData.timezoneAbbr = memberData.timezoneAbbr;
    pastData.timezoneUTC = memberData.timezoneUTC;
    return pastData;
  } else {
    for (var i = 0; i < memberData.dependent.length; i++) {
      if (memberData.dependent[i].id === consultData.dependentId) {
        pastData.primaryPhone = memberData.primaryPhone;
        pastData.state = memberData.state;
        pastData.timezoneAbbr = memberData.timezoneAbbr;
        pastData.timezoneUTC = memberData.timezoneUTC;
      }
    }
  }
  return pastData;
};

export const medicationStatuses = {
  0: "Unknown",
  1: "Active",
  3: "Discontinued",
  4: "Deleted",
  5: "Completed",
  8: "Cancelled",
};

export const allergyStatusType = {
  1: "Active",
  2: "Inactive",
  3: "Deleted",
};

export const allergyReactionTypes = {
  1: "Allergy",
  2: "AdverseReaction",
};

export const allergyCodeTypes = ["AllergyClass", "GenericDrug", "SNOMED"];

export const medicalHistoryStatuses = [
  "Currently in such condition",
  "Had condition in the past",
];

export const dctDefaultThumb = "assets/images/default-img.jpg";

export const consultTabs = [
  {
    label: "Patient",
    disabled: true,
    class: "",
  },
  {
    label: "EHR",
    disabled: true,
    class: "",
  },
  {
    label: "Phone",
    disabled: true,
    class: "",
  },
  {
    label: "State",
    disabled: true,
    class: "",
  },
  {
    label: "Details",
    disabled: true,
    class: "",
  },
  {
    label: "Schedule",
    disabled: true,
    class: "",
  },
  {
    label: "Pharmacy",
    disabled: true,
    class: "",
  },
  {
    label: "Finish",
    disabled: true,
    class: "",
  },
];

export const renderPhysicanLincenseInfo = (licenseInfo) => {
  let noLicenseInfo = "License value is not present.";
  if (!licenseInfo) return noLicenseInfo;

  var licenseHtml = "";
  licenseInfo.map((items) => {
    Object.keys(items).map(function (k) {
      licenseHtml +=
        "<span class='badge-info badge mr-2'>" +
        k +
        ": " +
        items[k] +
        "</span>";
    });
  });
  return licenseHtml ? licenseHtml : noLicenseInfo;
};
