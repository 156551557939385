import {
  CREATE_MEMBER,
  UPDATE_MEMBER,
  GET_MEMBER,
  GET_MEMBER_LIST,
  SET_MEMBER_LIST,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILED,
  API_ERROR,
  SET_SINGLE_MEMBER,
  SET_MEMBER_LISTING,
  GET_SINGLE_MEMBER,
  GET_MEMBER_AND_DEPENDENTS,
  SET_MEMBER_AND_DEPENDENTS,
  GET_MEMBER_TAB_LIST,
  SET_MEMBER_TAB_LIST,
  CREATE_DEPENDENT,
  UPDATE_DEPENDENT,
  CREATE_DEPENDENT_SUCCESS,
  CREATE_DEPENDENT_FAILED,
  CREATE_CONSULTATION,
  UPDATE_CONSULTATION,
  UPDATE_CONSULTATION_STEPWISE,
  SET_SINGLE_CONSULTATION,
  GET_SINGLE_CONSULTATION,
  CONSULTATION_MSG,
  GET_DRAFT_CONSULTATION,
  SET_DRAFT_CONSULTATION,
  SET_CONSULTATION_LISTING,
  SET_MY_SINGLE_CONSULTATION,
  SCHEDULED_CONSULTATION_LIST,
  SET_DEPENDENT_DATA,
} from "./actionTypes";

const initialState = {
  createMemberError: null,
  message: null,
  loading: false,
  action_type: null,
  dependentError: null,
  depMessage: null,
  consultation: null,
  consultMsg: null,
  consultMsgType: null,
  singleConsult: null,
  draftConsult: null,
  tabList: null,
  scheduledConsultation: null,
  // mySingleConsult: null,
  // consultListing: null,
};

const member = (state = initialState, action) => {
  switch (action.type) {
    case CONSULTATION_MSG:
      state = {
        ...state,
        consultMsg: action.payload,
        loading: false,
        consultMsgType: action.msgType,
      };
      break;
    case SCHEDULED_CONSULTATION_LIST:
      state = {
        ...state,
        scheduledConsultation: action.payload,
      };
      break;
    case CREATE_CONSULTATION:
      state = {
        ...state,
        consultation: action.payload,
        loading: true,
        consultMsg: null,
      };
      break;
    case UPDATE_CONSULTATION:
      state = {
        ...state,
        consultation: action.payload,
        loading: true,
        consultMsg: null,
      };
      break;

    case UPDATE_CONSULTATION_STEPWISE:
      state = {
        ...state,
        consultation: action.payload,
        loading: true,
        consultMsg: null,
      };
      break;

    case GET_SINGLE_CONSULTATION:
      state = {
        ...state,
        singleConsult: action.payload.result,
        loading: false,
      };
      break;

    case GET_DRAFT_CONSULTATION:
      state = {
        ...state,
        draftConsult: action.payload.result,
        loading: false,
      };
      break;

    case SET_DRAFT_CONSULTATION:
      state = {
        ...state,
        consultation: null,
        draftConsult: action.payload,
        loading: false,
        consultMsg: null,
      };
      break;

    case SET_SINGLE_CONSULTATION:
      state = {
        ...state,
        consultation: null,
        singleConsult: action.payload,
        loading: false,
        consultMsg: action.message,
      };
      break;

    case CREATE_MEMBER:
      state = {
        ...state,
        member: action.payload,
        loading: true,
        createMemberError: null,
      };
      break;
    case UPDATE_MEMBER:
      state = {
        ...state,
        member: action.payload,
        loading: true,
        createMemberError: null,
      };
      break;
    case GET_MEMBER:
      state = {
        ...state,
        member: action.payload.result,
        loading: true,
        createMemberError: null,
      };
      break;
    case GET_MEMBER_LIST:
      state = {
        ...state,
        member: action.payload.result,
        loading: true,
        createMemberError: null,
      };
      break;
    case GET_SINGLE_MEMBER:
      state = {
        ...state,
        member: action.payload.result,
        loading: true,
        createMemberError: null,
      };
      break;

    case GET_MEMBER_AND_DEPENDENTS:
      state = {
        ...state,
        depMessage: action.message,
        message: null,
        memberAndDependents: action.payload.result,
        loading: true,
        createMemberError: null,
      };
      break;
    case GET_MEMBER_TAB_LIST:
      state = {
        ...state,
        depMessage: action.message,
        tabList: action.payload.result,
      };
      break;

    case SET_SINGLE_MEMBER:
      state = {
        ...state,
        member: null,
        singleMember: action.payload,
        memberAndDependents: action.payload,
        loading: false,
        message: action.message,
        createMemberError: null,
        action_type: action.action_type,
      };
      break;

    case SET_MEMBER_AND_DEPENDENTS:
      state = {
        ...state,
        member: null,
        memberAndDependents: action.payload,
        loading: false,
        depMessage: action.message,
        message: null,
        createMemberError: null,
        action_type: action.action_type,
      };
      break;
    case SET_MEMBER_TAB_LIST:
      state = {
        ...state,
        tabList: action.payload,
        depMessage: action.message,
      };
      break;

    case SET_MEMBER_LIST:
      state = {
        ...state,
        loading: false,
        member: action.payload,
        message: action.message,
        createMemberError: null,
        action_type: action.action_type,
      };
      break;
    case SET_MEMBER_LISTING:
      state = {
        ...state,
        loading: false,
        member: null,
        memberListing: action.payload,
        message: action.message,
        createMemberError: null,
      };
      break;
    case CREATE_MEMBER_SUCCESS:
      state = {
        ...state,
        loading: false,
        member: action.payload,
        message: action.message,
        createMemberError: null,
        action_type: action.action_type,
      };
      break;
    case CREATE_MEMBER_FAILED:
      state = {
        ...state,
        member: null,
        createMemberError: action.payload,
        loading: false,
      };
      break;

    case CREATE_DEPENDENT:
      state = {
        ...state,
        dependent: action.payload,
        loading: true,
        depMessage: action.message,
        dependentError: null,
      };
      break;
    case UPDATE_DEPENDENT:
      state = {
        ...state,
        dependent: action.payload,
        loading: true,
        depMessage: action.message,
        dependentError: null,
      };
      break;

    case CREATE_DEPENDENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        dependent: action.payload,
        depMessage: action.message,
        dependentError: null,
        action_type: action.action_type,
      };
      break;
    case CREATE_DEPENDENT_FAILED:
      state = {
        ...state,
        dependent: null,
        depMessage: null,
        dependentError: action.payload,
        loading: false,
      };
      break;
    case SET_CONSULTATION_LISTING:
      state = {
        ...state,
        loading: false,
        consultListing: action.payload,
        message: action.message,
      };
      break;
    case SET_MY_SINGLE_CONSULTATION:
      state = {
        ...state,
        loading: false,
        mySingleConsult: action.payload,
        message: action.message,
        action_type: action.action_type,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    case SET_DEPENDENT_DATA:
      state = {
        ...state,
        member: null,
        singleDependent: action.payload,
        memberAndDependents: action.payload,
        loading: false,
        message: action.message,
        createMemberError: null,
        action_type: action.action_type,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default member;
