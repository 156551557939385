import React, { useState, useEffect } from "react";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import { jsPanel } from "jspanel4/es6module/jspanel";
import "jspanel4/es6module/extensions/modal/jspanel.modal";
import "jspanel4/dist/jspanel.min.css";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Badge,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
} from "reactstrap";

import { connect, useSelector, useDispatch } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";

import {
  partnerActionSuccessful,
  setSinglePartner,
  setSinglePartnerAuth,
} from "../../store/actions";

import { withRouter, Link, useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

//Include Both Helper File with needed methods
import { loadingMessage, dctHumanReadableFormat } from "../../helpers/Commons";

import axios from "axios";
//Import Create and Update Group

const PrescriptionOverview = (props) => {
  const [partnerData, setPartnerData] = useState({
    gloading: true,
    gdata: null,
    gid: null,
    gmessage: null,
  });

  const [resetLoader, setResetLoader] = useState(false);
  const [loading, setLoading] = useState({
    state: false,
    show: false
  })
  const [rejectModal, setrejectModal] = useState(false);
  const [rejectMsg, setRejectMsg] = useState("");
  const [Doctor, setDoctor] = useState("");
  const { authUserType } = useSelector((state) => state.Login);

  const gdata = useSelector((state) => state.Partner.singlePartner);
  // const [gdata, setGdata] = useState(useSelector((state) => state.Partner.singlePartner))

  const [doctorNotes, setDoctorNotes] = useState("")

  // console.log("gdata >>>", gdata)
  const dispatch = useDispatch();

  const [uiPath, setUiPath] = useState("");

  const [sweetyallert, setsweetyallert] = useState(false);
  const [rxPatientData, setrxPatientData] = useState([]);

  useEffect(() => {
    setDoctorNotes(gdata?.doctorNotes)
    return;
  }, [gdata])

  useEffect(() => {
    if (authUserType === "doctor") {
      let split_url = props.location.pathname.split("/");
      let goup_id = split_url ? split_url[3] : "";
      axios
        .get(
          `${process.env.REACT_APP_API_PATH}/prescriptionfill/${goup_id}/prescribing-ui-data`,
          {
            headers: {
              Authorization: localStorage.getItem("auth_token"),
              PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
          }
        )
        .then(function (response) {
          setUiPath(response.data.uiPath);
        })
        .catch(function (err) { });
    }
  }, []);

  const prescriptionAcceptReject = (AcceptReject, Msg) => {
    let split_url = props.location.pathname.split("/");
    let goup_id = split_url ? split_url[3] : "";
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let addOn =
      AcceptReject === "accept"
        ? "/accepted"
        : AcceptReject === "reject"
          ? "/rejected"
          : AcceptReject === "reset"
            ? "/reset"
            : "/completed";
    let url =
      process.env.REACT_APP_API_PATH + "/prescriptionfill/" + goup_id + addOn;
    let data = {
      reject: Msg,
    };
    axios
      .patch(url, data, headers)
      .then(function (response) {
        if (AcceptReject === "reset" || AcceptReject === "accept") {
          fetchSinglePartner(goup_id);
        }
        setRejectMsg("");
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = "No record found.";
          }
        }
        setPartnerData({
          gloading: false,
          gdata: null,
          gid: null,
          gmessage: message,
        });
        dispatch(setSinglePartner(null, message));
      })
      .then(function () {
        setResetLoader(false);
      });
  };

  const fetchSinglePartner = (goup_id) => {
    if (!goup_id) {
      return;
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url =
      process.env.REACT_APP_API_PATH +
      "/prescriptionfill/" +
      goup_id +
      "?populate=doctorId&eventId&rxpharmacyId&userId";

    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data.data;
        // console.log('all data====...', response.data);
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(setSinglePartner(response_data, message, null));
        setPartnerData({
          gloading: false,
          gdata: response_data,
          gid: goup_id,
          gmessage: message,
        });
        if (response_data.status === "rejected") {
          fetchReasonToReject(goup_id);
        }
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = "No record found.";
          }
        }
        setPartnerData({
          gloading: false,
          gdata: null,
          gid: null,
          gmessage: message,
        });
        dispatch(setSinglePartner(null, message));
      })
      .then(function () { });
  };

  const fetchReasonToReject = (id) => {
    if (!id) {
      return;
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url =
      process.env.REACT_APP_API_PATH + "/doctorlogs/prescriptionfill/" + id;

    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data;

        setRejectMsg(response_data.reasonForRejection);
        setDoctor(response_data.doctorData.fullName);
        // console.log('rejected doctor--->>>', response_data)
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = "No record found.";
          }
        }
        setPartnerData({
          gloading: false,
          gdata: null,
          gid: null,
          gmessage: message,
        });
        dispatch(setSinglePartner(null, message));
      })
      .then(function () { });
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let goup_id = "";

    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    fetchSinglePartner(goup_id);
  }, []);

  const { gloading, gid, gmessage } = partnerData;

  const releaseEventLog = async (eventId) => {
    if (!eventId || eventId == "") return;
    let url = `${process.env.REACT_APP_API_PATH}/eventlog/${eventId}/released`;
    let bearer_token = localStorage.getItem("auth_token");
    const options = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const data = {
      userId: JSON.parse(localStorage.getItem("auth_user")).id,
    };
    axios
      .patch(url, data, options)
      .then((response) => {
        localStorage.removeItem("eventId");
        props.history.goBack();
      })
      .catch((error) => {
        console.log("Could not release. Error -> ", error.response.data);
        props.history.goBack();
      });
  };

  const getrxModule = async () => {
    if (!gdata) return;
    if (!gdata.dosespotPatientId) {
      console.log("Invalid patient. This patient is not sync with DoseSpot");
      return;
    }
    let url = `${process.env.REACT_APP_API_PATH}/patient/${gdata?.dosespotPatientId}/prescription`;
    let bearer_token = localStorage.getItem("auth_token");
    const options = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    await axios.get(url).then((response) => {
      setrxPatientData(response.data.data);
    });
  };

  useEffect(() => {
    getrxModule();
  }, [partnerData]);

  const backToPharmacyListLink = () => {
    return authUserType === "partner" ? (
      ""
    ) : (
      <Link
        to="#"
        onClick={() => {
          dispatch(setSinglePartner(null, ""));
          props.history.goBack();
          let eventId = localStorage.getItem("eventId");
          if (eventId) {
            releaseEventLog(eventId);
          }
        }}
      >
        <button type="button" className="btn btn-info">
          <i className="mdi mdi-arrow-left font-size-16 mr-1"></i>{" "}
          {props.t("Back to List")}
        </button>
      </Link>
    );
  };

  const resetPrescriptionStatus = () => {
    return authUserType === "admin" || authUserType === "pharmacy" ? (
      <button
        className=""
        onClick={() => {
          setResetLoader(true);
          prescriptionAcceptReject("reset", "");
        }}
        className="btn btn-primary waves-effect waves-light mr-4 mb-4"
      >
        {resetLoader && (
          <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
        )}
        {props.t("Reset Prescription Status")}
      </button>
    ) : null;
  };

  const uploadDoctorNotes = async () => {
    setLoading(prev => ({
      ...prev,
      state: true
    }));
    let url = `${process.env.REACT_APP_API_PATH}/prescriptionfill/${gdata?.id}`;
    let bearer_token = localStorage.getItem("auth_token");
    const options = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    await axios.patch(url, { doctorNotes: doctorNotes }).then((res) => {
      setLoading(prev => ({
        ...prev,
        state: false,
        show: true
      }));

      setTimeout(() => {
        setLoading(prev => ({
          ...prev,
          state: false,
          show: false
        }));
      }, 3000)
      // console.log(res.data);
    }).catch(err => {
      console.log("error doctor notes upload");
      setLoading(prev => ({
        ...prev,
        state: false,
        show: false
      }));
    });
  }

  // const commentData = () => {

  //   if (gdata) {
  //     if (gdata.bodyData) {
  //       var res = gdata.bodyData.filter(e => Object.keys(e)[0] === "Additional Comments")
  //       res.map((qa, index) => (<>
  //         <div className="mt-3" key={index}>

  //           <label htmlFor="question">
  //             <b >{Object.keys(qa)[0]}</b>
  //           </label>
  //           <br />
  //           <span >{Object.values(qa)[0]}</span>
  //         </div>
  //       </>
  //       ))
  //     }
  //   }
  // }

  // console.log("gdata.length >>> ", gdata.medProfileData)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                {gmessage ? (
                  <Alert color="danger" className="text-center">
                    {gmessage}
                  </Alert>
                ) : null}

                {gdata ? (
                  <CardBody>
                    <div className="group-details-section" data-id={gdata.id}>
                      <Row>
                        <Col lg="6" xl="6">
                          <Media>
                            <div className="avatar-sm mr-4">
                              <span className="avatar-title font-size-20 rounded-circle text-capitalize">
                                {gdata.firstName?.charAt(0)}
                              </span>
                            </div>
                            <Media className="overflow-hidden" body>
                              <h5 className="text-truncate font-size-16 text-capitalize">
                                {gdata?.firstName + " " + gdata?.lastName}
                              </h5>
                              <p className="text-muted font-size-16">
                                {props.t("Prescription Status")}:
                                <Badge
                                  color="primary"
                                  className="ml-2 text-capitalize"
                                >
                                  {gdata.status}
                                </Badge>
                              </p>
                            </Media>
                          </Media>
                        </Col>

                        <Col lg="6" xl="6" className="">
                          {/* <div className="text-right">{showapidetails()}</div> */}
                          <div className="col  d-flex align-items-cente justify-content-md-end">
                            {((authUserType === "admin" &&
                              gdata?.status === "completed") ||
                              gdata?.status === "accepted") &&
                              resetPrescriptionStatus()}
                            {backToPharmacyListLink()}
                          </div>
                        </Col>
                      </Row>

                      <h5 className="font-size-18 mt-4">
                        {props.t("Prescription Details")}:{" "}
                      </h5>

                      <div className="text-muted mt-4">
                        <Row>
                          <Col lg="6">
                            <p
                              style={{ textTransform: "capitalize" }}
                              className="font-size-15"
                            >
                              <i className="bx bx-building text-primary mr-1"></i>{" "}
                              <span style={{ fontWeight: "500" }}>
                                {props.t("Name")}:{" "}
                              </span>
                              {gdata?.firstName + " " + gdata?.lastName}
                            </p>

                            <p className="font-size-15">
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <span style={{ fontWeight: "500" }}>
                                {props.t("Created At")}:{" "}
                              </span>{" "}
                              {dctHumanReadableFormat(gdata.createdAt)}
                            </p>

                            <p className="font-size-15">
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <span style={{ fontWeight: "500" }}>
                                {props.t("Updated At")}:
                              </span>{" "}
                              {dctHumanReadableFormat(gdata.updatedAt)}
                            </p>
                          </Col>

                          <Col lg="6">
                            <p className="font-size-15">
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <span style={{ fontWeight: "500" }}>
                                {props.t("Email")}:{" "}
                              </span>{" "}
                              {gdata.email}
                            </p>
                            <p className="font-size-15">
                              <i className="bx bx-phone text-primary mr-1"></i>{" "}
                              <span style={{ fontWeight: "500" }}>
                                {props.t("Phone")}:{" "}
                              </span>{" "}
                              {gdata.phone}
                            </p>
                            <p
                              className="font-size-15"
                              style={{ textTransform: "capitalize" }}
                            >
                              <i className="bx bx-user text-primary mr-1"></i>{" "}
                              <span style={{ fontWeight: "500" }}>
                                {props.t("Gender")}:{" "}
                              </span>{" "}
                              {gdata.gender}
                            </p>
                          </Col>
                          <Col lg="6">
                            <p
                              className="font-size-15"
                              style={{ textTransform: "capitalize" }}
                            >
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <span style={{ fontWeight: "500" }}>
                                {props.t("Gender")}:{" "}
                              </span>{" "}
                              {gdata.gender}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="text-right">
                              {authUserType === "doctor" && (
                                <>
                                  <button
                                    className="btn btn-outline-primary mr-2"
                                    onClick={() => setrejectModal(true)}
                                  >
                                    {props.t("Reject")}
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      // prescriptionAcceptReject("accept", "");

                                      const options = {
                                        headerTitle: "action",
                                        position: "right-top 0% 0%",
                                        panelSize: {
                                          width: () => {
                                            return window.innerWidth;
                                          },
                                          height: () => {
                                            return window.innerHeight;
                                          },
                                        },
                                        dragit: {
                                          disable: true,
                                        },
                                        addClass: "dctCustom",
                                        animateIn: "jsPanelFadeIn",
                                        animateOut: "jsPanelFadeOut",
                                        theme: "none filled",
                                        closeOnEscape: true,
                                        borderRadius: ".1rem",
                                        borderStyle: "none",
                                        // maximizedMargin: ()=>{
                                        // 	return 	[69, 5, 5, 250 ]
                                        // },
                                        zIndex: 100000,

                                        setStatus: "maximized",
                                        header: false,
                                        border: "dimgrey",
                                        addCloseControl: 1,
                                        callback: function () {
                                          this.content.innerHTML = `<iframe
                                                                  src=${uiPath}
                                                                  width="1600px"
                                                                  height="900px"
                                                                />`;
                                        },

                                        onclosed: () => {
                                          // remove closed jsPanel and its mounted component from state
                                          // const appPanels = app.state.panels;
                                          // if (appPanels[action]) {
                                          //   delete appPanels[action];
                                          //   app.setState({ panels: { ...appPanels } });
                                          // }
                                        },
                                        onbeforeclose: [
                                          function () {
                                            setsweetyallert(true);
                                          },
                                          function () {
                                            return true;
                                          },
                                        ],
                                      };
                                      const panel = jsPanel.create(options);
                                    }}
                                  >
                                    {props.t("Accept")}
                                  </button>
                                </>
                              )}
                            </div>
                          </Col>

                          <Col lg="12">
                            <p className="font-size-15 mt-3 text-capitalize ml-2" style={{ fontWeight: "500" }}>
                              {props.t("Doctor Notes")}:{" "}
                            </p>
                            {authUserType === "doctor" && gdata.status === "pending" && <textarea rows={5} className="form-control" value={doctorNotes} onChange={(e) => {
                              setDoctorNotes(e.target.value)
                            }} />}
                            {authUserType === "admin" && <p className="font-size-12 mt-3 text-capitalize ml-2">
                              {gdata.doctorNotes}
                            </p>}

                            {authUserType === "doctor" && gdata.status === "pending" && <div className="d-flex flex-column">
                              <button
                                className="btn btn-outline-primary mt-2 ml-auto"
                                onClick={() => { uploadDoctorNotes() }}
                              >
                                {loading.state && <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>}{props.t("Save")}
                              </button>
                              {loading.show && <Alert color="success" className="ml-auto mt-3" >Doctor Notes saved.</Alert>}
                            </div>}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                ) : (
                  <CardBody>
                    {gmessage ? (
                      <div className="text-center">
                        {backToPharmacyListLink()}
                      </div>
                    ) : (
                      <div className="text-center">
                        {loadingMessage("Loading...")}
                      </div>
                    )}
                  </CardBody>
                )}
              </Card>
              {rejectMsg !== "" && (
                <Card>
                  <div className="container-fluid my-3">
                    <label htmlFor="name">
                      <h5 className="font-size-15">
                        {props.t("Reason to Reject")}
                      </h5>
                    </label>
                    <br />
                    <span>{rejectMsg}</span>
                    {Doctor ? (
                      <div className="mt-3">
                        <h5 className="font-size-15">
                          {props.t("Rejected By")}
                        </h5>
                        <span style={{ textTransform: "capitalize" }}>
                          {Doctor}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    <br />
                  </div>
                  <Row>
                    <Col lg="12">
                      <div className="text-right">
                        {resetPrescriptionStatus()}
                      </div>
                    </Col>
                  </Row>
                </Card>
              )}
              <Card>
                {gmessage ? (
                  <Alert color="danger" className="text-center">
                    {gmessage}
                  </Alert>
                ) : null}

                {gdata ? (
                  <CardBody>
                    <div className="group-details-section" data-id={gdata.id}>
                      <AvForm className="form-horizontal">
                        <Row>
                          <Col xl={6}>
                            <div className="mt-4">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  <label
                                    htmlFor="name"
                                    className="font-size-16"
                                  >
                                    {props.t(`Full Name`)}
                                  </label>
                                  <br />
                                  <span
                                    className="font-size-15 font-weight-lighter"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {gdata.firstName}&nbsp;{gdata.lastName}
                                  </span>
                                </div>
                              </Col>
                            </div>
                          </Col>

                          <Col xl={6}>
                            <div className="mt-4">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  <div className="mt-3">
                                    <label
                                      htmlFor="dob"
                                      className="font-size-16"
                                    >
                                      {props.t(`Date Of Birth`)}
                                    </label>
                                    <br />
                                    <span className="font-size-15 font-weight-lighter">
                                      {gdata.dob}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Col>

                          <Col xl={6}>
                            <div className="mt-4">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  <div className="mt-3">
                                    <label
                                      htmlFor="phone"
                                      className="font-size-16"
                                    >
                                      {props.t(`Phone`)}
                                    </label>
                                    <br />
                                    <span className="font-size-15 font-weight-lighter">
                                      {gdata.phone}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Col>

                          <Col xl={6}>
                            <div className="mt-4">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  <div className="mt-3">
                                    <label
                                      htmlFor="email"
                                      className="font-size-16"
                                    >
                                      {props.t(`Email`)}
                                    </label>
                                    <br />
                                    <span className="font-size-15 font-weight-lighter">
                                      {gdata.email}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Col>

                          <Col xl={6}>
                            <div className="mt-4">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  <div className="mt-3">
                                    <label
                                      htmlFor="phone"
                                      className="font-size-16"
                                    >
                                      {props.t(`State`)}
                                    </label>
                                    <br />
                                    <span className="font-size-15 font-weight-lighter">
                                      {gdata?.state}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Col>

                          <Col xl={6}>
                            <div className="mt-4">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  <div className="mt-3">
                                    <label
                                      htmlFor="phone"
                                      className="font-size-16"
                                    >
                                      {props.t(`Gender`)}
                                    </label>
                                    <br />
                                    <span className="font-size-15 font-weight-lighter text-capitalize">
                                      {gdata.gender}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Col>

                          {gdata.orderNo && (
                            <Col xl={6}>
                              <div className="mt-4">
                                <Col lg="10" sm="10">
                                  <div className="mt-3">
                                    <div className="mt-3">
                                      <label
                                        htmlFor="phone"
                                        className="font-size-16"
                                      >
                                        {props.t(`Order No`)}
                                      </label>
                                      <br />
                                      <span className="font-size-15 font-weight-lighter">
                                        {gdata.orderNo}
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          )}
                          <Col xl={6}>
                            <div className="mt-4">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  <div className="mt-3">
                                    <label
                                      htmlFor="phone"
                                      className="font-size-16"
                                    >
                                      {props.t(`Pharmacy Name`)}
                                    </label>
                                    <br />
                                    <span className="font-size-15 font-weight-lighter">
                                      {
                                        gdata?.rxpharmacyId
                                          ?.pharmacy_pharmacyName
                                      }
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Row>

                        <h5 className="font-size-18 mt-4">
                          {props.t("Requested rx")}:{" "}
                        </h5>

                        <Row>
                          <Col xl={12}>
                            <div>
                              <ul>
                                {gdata?.RequestedRx &&
                                  gdata?.RequestedRx?.map((rxdata, i) => (
                                    <li
                                      className="font-size-16 font-weight-light"
                                      key={i}
                                    >
                                      {rxdata}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </Col>
                        </Row>

                        <h5 className="font-size-18 mt-4">
                          {/* {props.t("Additional Form")}:{" "} */}
                          {gdata.bodyData &&
                            Object.keys(
                              gdata.bodyData[gdata.bodyData.length - 1]
                            )[0] === "Additional Comments" && (
                              <label
                                htmlFor="question"
                                style={{ color: "#bb1200" }}
                              >
                                <span>
                                  {
                                    Object.keys(
                                      gdata.bodyData[gdata.bodyData.length - 1]
                                    )[0]
                                  }
                                </span>{" "}
                                :
                              </label>
                            )}
                        </h5>

                        <Row>
                          <Col xl={12}>
                            <div className="mt-2">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  {gdata.bodyData &&
                                    Object.keys(
                                      gdata.bodyData[gdata.bodyData.length - 1]
                                    )[0] === "Additional Comments" && (
                                      <div className="mt-3">
                                        <span
                                          style={{
                                            color: "#bb1200",
                                            backgroundColor: "#ff0",
                                          }}
                                        >
                                          {
                                            Object.values(
                                              gdata.bodyData[
                                              gdata.bodyData.length - 1
                                              ]
                                            )[0]
                                          }
                                        </span>
                                      </div>
                                    )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Row>

                        <h5 className="font-size-18 mt-4">
                          {props.t("Referral Form")}:{" "}
                        </h5>

                        <Row>
                          <Col xl={12}>
                            <div className="mt-2">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  {gdata?.bodyData &&
                                    gdata.bodyData.map((qa, index) => (
                                      <div
                                        className={
                                          "mt-3 py-3 px-4 rounded-sm font-size-16"
                                        }
                                        key={index}
                                        style={{ backgroundColor: "#F5F7FF" }}
                                      >
                                        <label htmlFor="question">
                                          {Object.keys(qa)[0] ===
                                            "Additional Comments"
                                            ? (Object.keys(qa)[0] = "")
                                            : Object.keys(qa)[0]}
                                        </label>
                                        <br />
                                        <span className="font-weight-light">
                                          {Object.keys(qa)[0] ===
                                            "Additional Comments"
                                            ? (Object.values(qa)[0] = "")
                                            : typeof Object.values(qa)[0] ===
                                              "string"
                                              ? Object.values(qa)[0]
                                              : !Object.values(qa)[0] ?"NA"
                                              : Object.entries(
                                                Object.values(qa)[0]
                                              ).map((p, i) => {
                                                return (
                                                  <li
                                                    key={i}
                                                    className="font-weight-light"
                                                  >
                                                    {" "}
                                                    {p[0]} :{" "}
                                                    <span>
                                                      {p[1] === true
                                                        ? "Yes"
                                                        : "No"}
                                                    </span>
                                                  </li>
                                                );
                                              })}
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Row>

                        {gdata.medProfileData &&
                          gdata.medProfileData.length > 0 && (
                            <>
                              <h5 className="font-size-18 mt-4">
                                {props.t("Medication Profile Data")}:{" "}
                              </h5>

                              <Row>
                                <Col xl={12}>
                                  <div className="mt-2">
                                    <Col lg="10" sm="10">
                                      <div className="mt-3 font-size-16">
                                        {gdata.medProfileData &&
                                          gdata.medProfileData.map(
                                            (val, index) => (
                                              <div
                                                className="mt-3 py-2 px-4"
                                                key={index}
                                                style={{
                                                  backgroundColor: "#F5F7FF",
                                                }}
                                              >
                                                <label className="">
                                                  {Object.keys(val)[0]}
                                                </label>
                                                :{" "}
                                                <span className="font-weight-light">
                                                  {Object.values(val)[0]}
                                                </span>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </Col>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}

                        <Row>
                          <Col xl={12}>
                            <div className="mt-2">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  <div className="mt-3 ">
                                    <label
                                      htmlFor="Billing Address"
                                      className="font-size-18 "
                                    >
                                      {props.t(`Billing Address`)}:
                                    </label>
                                    <br />
                                    <span className="font-size-15 font-weight-light">
                                      {gdata.billingAddress}
                                      <br />
                                      {gdata.city}
                                      <br />
                                      {gdata.state}
                                      <br />
                                      {gdata.zipCode}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={12}>
                            <div className="mt-2">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  <div className="mt-3">
                                    <label
                                      htmlFor="Prescription Order"
                                      className="font-size-18 "
                                    >
                                      {props.t(`Prescription Order`)}:
                                    </label>
                                    <br />
                                    <span className="font-size-15 font-weight-light">
                                      {`Patient: ${gdata.firstName} ${gdata.lastName}`}
                                      <br />
                                      {`DOB: ${gdata.dob}`}
                                      <br />
                                      {`Shipping Address: ${gdata.shippingAddress}`}
                                      <br />
                                      {`Billing Address: ${gdata.billingAddress}`}
                                      <br />
                                      {`phone: ${gdata.phone}`}
                                      <br />
                                      {`Patient’s Email: ${gdata.email}`}
                                      <br />
                                      {`Date of Consult: ${dctHumanReadableFormat(
                                        gdata.createdAt
                                      )}`}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-none">
                          <Col xl={12}>
                            <div className="mt-2">
                              <Col lg="10" sm="10">
                                <div className="mt-3">
                                  <div className="mt-3">
                                    <label htmlFor="patientRequest">
                                      <b>{props.t(`Patient Request`)}:</b>
                                    </label>
                                    <br />
                                    <span></span>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Row>

                        {gdata?.doctorId ? (
                          <div className="doctorAccepted">
                            <Row>
                              <Col xl={12}>
                                <div className="mt-2">
                                  {/* <Col lg="10" sm="10">
                                    <div className="mt-3">
                                      <div className="mt-3">
                                        <label htmlFor="RX">
                                          <b>{props.t(`RX`)}:</b>
                                        </label>
                                        <br />
                                        <ul>
                                          {rxPatientData.length == 0 ? (
                                            <li>
                                              {props.t(`There is no rx data`)}
                                            </li>
                                          ) : (

                                            console.log(rxPatientData),

                                            rxPatientData.map((data, index) => (
                                              <li key={index}>{data.DisplayName}</li>
                                            ))
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </Col> */}

                                  {rxPatientData.length === 0 ? (
                                    <li>{props.t(`There is no rx data`)}</li>
                                  ) : (
                                    <>
                                      <Col lg="10" sm="12">
                                        {/* <div className="mt-3">
                                          <label htmlFor="RX">
                                            <b>{props.t(`RX`)}:</b>
                                          </label>
                                          <br />
                                          <ul>

                                            {rxPatientData.map((data, index) => (
                                              <li key={index}>{data.DisplayName}</li>
                                            ))}
                                          </ul>
                                        </div>
                                        <div className="mt-3">
                                          <label htmlFor="NDC">
                                            <b>{props.t(`NDC`)}:</b>
                                          </label>
                                          <br />
                                          <ul>

                                            {rxPatientData.map((data, index) => (
                                              <li key={index}>{data.NDC}</li>
                                            ))}
                                          </ul>
                                        </div> */}

                                        <div style={{ overflow: "auto" }}>
                                          <table className="mt-3 rx-table">
                                            <thead>
                                              <tr>
                                                <th>RX</th>
                                                <th>NDC</th>
                                                <th>Dispense</th>
                                                <th>Dispense Unit</th>
                                                <th>Refills</th>
                                                <th>Direction</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {rxPatientData.map(
                                                (data, index) => (
                                                  <tr key={index}>
                                                    <td>{data.DisplayName}</td>
                                                    <td>{data.NDC}</td>
                                                    <td>
                                                      {
                                                        data.DispenseUnitDescription
                                                      }
                                                    </td>
                                                    <td>
                                                      {data.DispenseUnitId}
                                                    </td>
                                                    <td>{data.Refills}</td>
                                                    <td>{data.Directions}</td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </Col>
                                    </>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            {/* 
                            <Row>
                              <Col xl={12}>
                                <div className="mt-2">
                                  <Col lg="10" sm="10">
                                    <div className="mt-3">
                                      <div className="mt-3">
                                        <span>
                                          {
                                            "Apply 1 drop to the base of each upper eyelid using provided applicator once nightly at bedtime as instructed."
                                          }
                                        </span>
                                        <br />
                                        <span>{`Dispense: ${"3ml (4-week supply) and 5ml (10-week supply) interchangeably"}`}</span>
                                        <br />
                                        <span>{`Refill: ${"PRN for one year"}`}</span>
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </Row> */}

                            <Row>
                              <Col xl={12}>
                                <div className="mt-2">
                                  <Col lg="10" sm="10">
                                    <div className="mt-3">
                                      <div className="mt-3">
                                        <label htmlFor="prescriberSignature">
                                          <b>
                                            {props.t(`Prescriber Signature`)}:
                                          </b>
                                        </label>
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "180px",
                                          }}
                                          src={
                                            gdata.doctorId.doctorSignatureUrl
                                          }
                                        ></img>
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12}>
                                <div className="mt-2">
                                  <Col lg="10" sm="10">
                                    <div className="mt-3">
                                      <div className="mt-3">
                                        <label htmlFor="date">
                                          <b>{props.t(`Date`)}:</b>
                                        </label>
                                        <span>{`${""} ${dctHumanReadableFormat(
                                          gdata?.updatedAt
                                        )}`}</span>
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12}>
                                <div className="mt-2">
                                  <Col lg="10" sm="10">
                                    <div className="mt-3">
                                      <div className="mt-3">
                                        <label htmlFor="prescribersName">
                                          <b>{props.t(`Prescriber’s Name`)}:</b>
                                        </label>
                                        <span>{`${gdata?.userId?.firstName} ${gdata?.userId?.lastName}`}</span>
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12}>
                                <div className="mt-2">
                                  <Col lg="10" sm="10">
                                    <div className="mt-3">
                                      <div className="mt-3">
                                        <label htmlFor="prescribersAddress">
                                          <b>
                                            {props.t(`Prescriber’s Address`)}:
                                          </b>
                                        </label>
                                        <span>{`${""} ${gdata?.doctorId?.address
                                          }`}</span>
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12}>
                                <div className="mt-2">
                                  <Col lg="10" sm="10">
                                    <div className="mt-3">
                                      <div className="mt-3">
                                        <label htmlFor="prescribersPhone">
                                          <b>
                                            {props.t(
                                              `Prescriber’s Phone Number`
                                            )}
                                            :
                                          </b>
                                        </label>
                                        <span>{`${""} ${gdata?.doctorId?.primaryPhone
                                          }`}</span>
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12}>
                                <div className="mt-2">
                                  <Col lg="10" sm="10">
                                    <div className="mt-3">
                                      <div className="mt-3">
                                        <label htmlFor="prescribersNpi">
                                          <b>{props.t(`Prescriber’s NPI`)}:</b>
                                        </label>
                                        <span>{`${""} ${gdata?.doctorId?.npi
                                          }`}</span>
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12}>
                                <div className="mt-2">
                                  <Col lg="10" sm="10">
                                    <div className="mt-3">
                                      <div className="mt-3">
                                        <label htmlFor="prescribersNpi">
                                          <b>{props.t(`Prescriber’s Note`)}:</b>
                                        </label>
                                        <span>
                                          {" "}
                                          {rxPatientData[0]
                                            ? rxPatientData[0]?.Directions
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12}>
                                <div className="mt-2">
                                  <Col lg="10" sm="10">
                                    <div className="mt-3">
                                      <div className="mt-3">
                                        <label
                                          className="mb-0"
                                          htmlFor="prescribersAttestation"
                                        >
                                          <b>
                                            {props.t(
                                              `Prescriber’s Attestation`
                                            )}
                                            :
                                          </b>
                                        </label>
                                        <span>
                                          {" "}
                                          <i>
                                            By signing the prescription, I
                                            attest that I maintain proper and
                                            valid license in the state where
                                            patient resides. I am aware of and
                                            my practice conforms with applicable
                                            state laws with regards to licenser
                                            in general and specifically as it
                                            relates to the requirements for
                                            telemedicine. I am aware of and my
                                            practice conforms with applicable
                                            state law as it relates to
                                            requirements for establishing a
                                            valid prescriber-patient
                                            relationship. I deemed the following
                                            medication medically necessary for
                                            the patient. This patient consents
                                            to have CarePartners Pharmacy
                                            fulfill his/her Prescription and
                                            understands he/she has an option to
                                            obtain the prescription from any
                                            pharmacy of his/her choice by
                                            contacting CarePartners Pharmacy
                                            support team.
                                          </i>
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          ""
                        )}
                      </AvForm>
                    </div>
                  </CardBody>
                ) : (
                  ""
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="lg"
        isOpen={rejectModal}
        toggle={() => {
          setrejectModal(!rejectModal);
        }}
        scrollable={true}
        onClosed={(e) => {
          //toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setrejectModal(!rejectModal);
          }}
        >
          {"Reject Message Section"}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <div className="form-group">
                <Label>{props.t("Reason to Reject")}:</Label>
                <br />
                <textarea
                  className="form-control"
                  name="replay"
                  style={{ height: "130px" }}
                  onChange={(e) => {
                    setRejectMsg(e.target.value);
                  }}
                ></textarea>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col lg={6}>
              <button
                className="btn btn-danger waves-effect waves-light"
                type="submit"
                onClick={() => {
                  prescriptionAcceptReject("reject", rejectMsg);
                  props.history.push("/rxprescriptionlist");
                }}
              >
                {props.t("Reject")}
                <span className="dr-gform-spinner d-none">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                </span>{" "}
              </button>
            </Col>
            <Col lg={6}>
              <button
                className="btn btn-secondary waves-effect waves-light"
                type="submit"
                onClick={() => {
                  setrejectModal(!rejectModal);
                  setRejectMsg("");
                }}
              >
                {props.t("Cancel")}
                <span className="dr-gform-spinner d-none">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                </span>{" "}
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
      {sweetyallert && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Submit"
          confirmBtnBsStyle="primary"
          title="Completed the prescription?"
          onConfirm={() => {
            prescriptionAcceptReject("accept", "");
            setsweetyallert(false);
            props.history.push("/rxprescriptionlist");
          }}
          onCancel={() => {
            setsweetyallert(false);
          }}
          focusCancelBtn
        >
          Once you complete the prescription you won't be able to change!
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { partner } = state.Partner;
  //const { member } = state.Member;
  const { message } = state.Partner;
  return { partner, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    partnerActionSuccessful,
    setSinglePartner,
    setSinglePartnerAuth,
  })(withNamespaces()(PrescriptionOverview))
);
