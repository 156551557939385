import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, apiError, setAuthToken } from "./actions";

//Include Both Helper File with needed methods
import { postLogin, processLogout } from "../../../helpers/backend_helper";

function* loginUser({ payload: { user, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true

  try {
    const headers = {
      headers: { PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN },
    };
    const response = yield call(
      postLogin,
      process.env.REACT_APP_API_PATH + "/login",
      { email: user.email, password: user.password },
      headers
    );

    document
      .getElementsByClassName("dr-login-spinner")[0]
      .classList.add("d-none");
    
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    
    if (response.success === true) {
      localStorage.setItem("auth_user", JSON.stringify(response.result.user));
      localStorage.setItem("auth_token", "Bearer " + response.result.bearer);
      yield put(loginSuccess(response.message));
      yield put(
        setAuthToken("Bearer " + response.result.bearer, response.result.user)
      );

      const redirectUrl = localStorage.getItem("redirect_to_url")

      if(response.result.user && redirectUrl !== "/" && response.result.user.userType !== "customerservice") {
        history.push(redirectUrl);
      }

      if(response.result.user && redirectUrl === "/" && response.result.user.userType !== "customerservice") {
        history.push("/dashboard");
      }

      if(response.result.user.rx) {
        history.push('/rxprescriptionlist');
      }

      if(response.result.user.userType === "customerservice") {
        history.push('/members');
      }
    } else {
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    processLogout();
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
