export const CREATE_PARTNER = "CREATE_PARTNER";
export const UPDATE_PARTNER = "UPDATE_PARTNER";
export const GET_PARTNER = "GET_PARTNER";
export const GET_PARTNER_LIST = "GET_PARTNER_LIST";
export const SET_PARTNER_LIST = "SET_PARTNER_LIST";
export const SET_PARTNER_LISTING = "SET_PARTNER_LISTING";
export const CREATE_PARTNER_SUCCESS = "CREATE_PARTNER_SUCCESS";
export const CREATE_PARTNER_FAILED = "CREATE_PARTNER_FAILED";
export const SET_SINGLE_PARTNER = "SET_SINGLE_PARTNER";
export const API_ERROR = "API_ERROR";
