import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
    Row, Col, Card, CardBody, Modal, ModalHeader, Button, ModalBody, Alert, Container
} from "reactstrap";
//Import Images
import profileImg from "../../assets/images/profile-img.png";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import { dctAlert, dctHumanReadableGlobalFormat } from './../../helpers/Commons'
import { Config } from '../../Config';

const DateRange = (props) => {
    const [modal, setModal] = useState(false);
    const [dateWarn, setDateWarn] = useState(false)
    const [fromFullDate, setFromFullDate] = useState(new Date());
    const [toFullDate, setToFullDate] = useState(new Date())

    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const [users, setUsers] = useState([]);
    const [noUser, setNoUser] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);

    const [successMsg, setSuccessMsg] = useState('')
    const [success, setSuccess] = useState(false)

    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        let date = new Date();
        setFrom(dctHumanReadableGlobalFormat(date));
        setTo(dctHumanReadableGlobalFormat(date));
    }, [])

    const handleFromDateChange = (date) => {
        setFromFullDate(date);
        setFrom(dctHumanReadableGlobalFormat(date));

    };

    const handleToDateChange = (date) => {
        setDateWarn(false)
        setToFullDate(date)
        setTo(dctHumanReadableGlobalFormat(date));

    }

    const handleSubmit = async (actionType) => {

        let diff = moment(fromFullDate).from(toFullDate);

        if (diff.includes('ago') || diff.includes('few seconds')) {
            setDateWarn(false);
        } else {
            setDateWarn(true);
            return;
        }

        setSuccess(false);
        setErrorMsg('')

        const data = {
            from: from,
            to: to,
            timezoneUTC: moment.tz.guess()
        }
        const options = {
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
        };

        const url = `${process.env.REACT_APP_API_PATH}/member/send-welcome-email`;

        if (actionType === "getUser") {
            setIsModalLoading(true);

            await axios.post(url, data, options)
                .then(res => {
                    if (res.status === 200) {
                        setIsModalLoading(false);
                        setModal(true);

                        if (res.data.data.length === 0) {
                            setNoUser(1);
                            setUsers([]);
                        }
                        else {
                            setNoUser(2);
                            setUsers(res.data.data);
                        }

                        setSuccessMsg('');
                        setSuccess(true);
                    }
                })
                .catch(err => {
                    if (err?.response?.status >= 400) {
                        setIsModalLoading(false);
                        setErrorMsg(err?.response?.data?.message);
                    };
                })
        } else if (actionType === "sendEmail") {
            const finalData = { ...data, confirmationKey: true };

            await axios.post(url, finalData, options)
                .then(res => {
                    if (res.status === 200) {

                        setModal(false);
                        setIsLoading(false);
                        setNoUser(0);
                        setSuccessMsg(res.data.message)
                        setSuccess(true);
                    }
                })
                .catch(err => console.log(err))
        }


    }

    return (

        <React.Fragment>
            <Col lg="7" md="6" sm="6">
                <Card>
                    <CardBody>
                        {success ? <div>
                            {dctAlert(1, successMsg)}
                        </div> : <div>
                            {dctAlert(3, errorMsg)}
                        </div>}
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs mr-3">
                                <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                    <i className="fas fa-envelope"></i>
                                </span>
                            </div>
                            <h5 className="font-size-14 mb-0">Send Welcome Email</h5>
                        </div>
                        <Row className="d-flex flex-row px-3 container-fluid">
                            <Col lg="6">
                                <h6>From</h6>
                                <ReactDatePicker
                                    name="dob"
                                    className="form-control"
                                    selected={fromFullDate}
                                    dateFormat="MM-dd-yyyy"
                                    onChange={handleFromDateChange}
                                    placeholderText="MM-DD-YYYY"
                                    showYearDropdown
                                />
                            </Col>
                            <Col lg="6" style={{zIndex:"9999"}}>
                                <h6>To</h6>
                                <ReactDatePicker
                                    name="dob"
                                    className={dateWarn ? "form-control border border-danger" : "form-control"}
                                    selected={toFullDate}
                                    dateFormat="MM-dd-yyyy"
                                    onChange={handleToDateChange}
                                    placeholderText="MM-DD-YYYY"
                                    showYearDropdown
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3 container-fluid">
                            <Col lg={12} className="text-right">
                                <button className="btn btn-primary waves-effect waves-light"
                                    onClick={() => handleSubmit('getUser')}
                                >{isModalLoading ? <>

                                    <span className="dr-cform-spinner">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                                    </span>

                                </> : null}
                                    {props.t(`Send`)}
                                </button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>


            {/* =====================Modal=================================== */}

            <Modal
                centered={true}
                size="lg"
                isOpen={modal}
                toggle={() => {
                    setModal(!modal);
                }}
                scrollable={true}
                className={`${Config.names.slugName}-member-add-edit-modal`}
                onClosed={(e) => {
                    setUsers([]);
                    setNoUser(0);
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setModal(!modal);
                    }}
                >
                    {props.t('Confirmation Alert')}
                </ModalHeader>
                <ModalBody>
                    <Container>

                        {noUser === 1 ?
                            <><Row className="d-flex justify-content-center align-items-center">
                                <h5 className="text-danger">{props.t('No such users found.')}</h5>
                            </Row></>
                            :

                            (noUser === 2 ?
                                <>
                                    <h5>{props.t(`Welcome Emails will be sent to ${users?.length} Users`)}</h5>

                                    <div style={{ overflowY: 'scroll', height: '16rem', marginTop: '20px' }}>
                                        <ul>
                                            {
                                                users && users.map((user, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <li style={{ marginBottom: '10px', marginLeft: '20px' }}>
                                                                {user.userId.email}
                                                            </li>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </>
                                : null)

                        }

                        <Row>
                            <Col lg={12}>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setModal(!modal)
                                            setUsers([]);
                                            setNoUser(0);

                                        }}
                                    >
                                        {props.t("Cancel")}
                                    </button>

                                    {noUser === 2 &&
                                        <button
                                            className="btn btn-success waves-effect waves-light"
                                            type="submit"
                                            onClick={() => {
                                                setIsLoading(true);
                                                handleSubmit('sendEmail')
                                            }}
                                        >
                                            {isLoading && <><span className="dr-mform-spinner">
                                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                                            </span>{" "}</>}

                                            {props.t("Confirm")}
                                        </button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default withRouter(
    withNamespaces()(DateRange)
);