import { all } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ResetSaga from "./auth/resetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import MemberSaga from "./member/saga";
import DoctorSaga from "./doctor/saga";
import GroupSaga from "./group/saga";
import BrandSaga from "./brand/saga";
import ServiceSaga from "./service/saga";
import PostMessageSaga from "./postMessage/saga";
import CompanySaga from "./company/saga";
import PartnerSaga from "./partner/saga";
import rxPharmacySaga from "./rxpharmacy/saga";
import rxPrescriptionSaga from "./rxprescription/saga";
import PartnerAuthSaga from "./partnerauth/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    ProfileSaga(),
    ForgetSaga(),
    ResetSaga(),
    LayoutSaga(),
    MemberSaga(),
    GroupSaga(),
    ServiceSaga(),
    PostMessageSaga(),
    CompanySaga(),
    DoctorSaga(),
    PartnerSaga(),
    rxPharmacySaga(),
    rxPrescriptionSaga(),
    PartnerAuthSaga(),
    BrandSaga(),
  ]);
}
