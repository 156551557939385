import React, { useState, useEffect } from "react";

import {
  dctGetPharmacyList,
  dctUpdateStatus,
} from "../../helpers/backend_helper";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Badge,
  TabContent, TabPane, NavLink, NavItem, Nav,
  Alert,
} from "reactstrap";

import { connect, useSelector, useDispatch } from "react-redux";
import classnames from "classnames";

import {
  partnerActionSuccessful,
  setSinglePartner,
  setSinglePartnerAuth,
} from "../../store/actions";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { capitalizeWords, dctHumanReadableFormat, loadingMessage } from "../../helpers/Commons";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

//Include Both Helper File with needed methods

import axios from "axios";

import Prescription from "./rxprescription";
import { Config } from "../../Config";

//Import Create and Update Group

const PharmacyOverview = (props) => {
  const [partnerData, setPartnerData] = useState({
    gloading: true,
    gdata: null,
    gid: null,
    gmessage: null,
  });

  const [statusLoader, setStatusLoader] = useState(false);

  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [dosespotPharmacyDetails, setDosespotPharmacyDetails] = useState({
    doseSpotPharmacyData: null,
  });

  const [prep, setPrep] = useState(false);

  const [partnerStatusMeta, setPartnerStatusMeta] = useState({
    rowIndex: "",
    groupId: "",
    groupStatus: 0,
  });

  const partner_action = useSelector((state) => state.Partner.action_type);

  const { authUserType, authPartnerId } = useSelector((state) => state.Login);

  const [apidetails, setapidetails] = useState(false);

  const gdata = useSelector((state) => state.Partner.singlePartner);

  const [activeTab, setactiveTab] = useState("1");
  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const dispatch = useDispatch();

  const fetchSinglePartner = (goup_id) => {
    if (!goup_id) {
      return;
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = process.env.REACT_APP_API_PATH + "/rxpharmacy/" + goup_id;

    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data;
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(setSinglePartner(response_data, message, null));
        setPartnerData({
          gloading: false,
          gdata: response_data,
          gid: goup_id,
          gmessage: message,
        });
        setPrep(true);
        getPharmacyById(
          response_data.pharmacy_pharmacyId
            ? response_data.pharmacy_pharmacyId
            : ""
        );
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = "No record found.";
          }
        }
        setPartnerData({
          gloading: false,
          gdata: null,
          gid: null,
          gmessage: message,
        });
        // dispatch(setSinglePartner(null, message));
      })
      .then(function () { });
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let goup_id = "";

    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    fetchSinglePartner(goup_id);
  }, []);


  const { gloading, gid, gmessage } = partnerData;

  const showapidetails = () => {
    return authUserType === "partner" ? (
      ""
    ) : (
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => setapidetails(true)}
      >
        {props.t("API Instructions")}
      </button>
    );
  };

  const backToPartnerListLink = () => {
    return authUserType === "partner" ? (
      ""
    ) : (
      <Link
        to="#"
        onClick={() => {
          props.history.goBack();
        }}
        className="btn btn-info waves-effect waves-light mr-2 ml-2"
      >
        <i className="mdi mdi-arrow-left mr-1"></i> {props.t("Back to List")}
      </Link>
    );
  };

  const getPharmacyById = async (pharmacyId) => {
    if (!pharmacyId) return;
    let url = `pharmacy/${pharmacyId}`;
    const response = await dctGetPharmacyList(url, "").then((data) => data);
    if (response.success === true) {
      // dispatch(setSinglePharmacy(response.data, ""));
      setDosespotPharmacyDetails({
        doseSpotPharmacyData: response.data,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                {gmessage ? (
                  <Alert color="danger" className="text-center">
                    {gmessage}
                  </Alert>
                ) : null}

                {gdata ? (
                  <CardBody>
                    <div className="group-details-section" data-id={gdata.id}>
                      <Row>
                        <Col lg="6">
                          <Media>
                            <div className="avatar-sm mr-4">
                              <span className="avatar-title font-size-20 rounded-circle text-capitalize">
                                {gdata.userId?.firstName?.charAt(0)}
                              </span>
                            </div>
                            <Media className="overflow-hidden" body>
                              <h5 className="text-truncate font-size-15">
                                {capitalizeWords(gdata.userId?.firstName +
                                  " " +
                                  gdata.userId?.lastName)}
                              </h5>
                              <p className="text-muted">
                                {props.t("Pharmacy Status")}:
                                <Badge
                                  color="primary"
                                  className="font-size-12 ml-2"
                                >
                                  {gdata.status === "active"
                                    ? "Active"
                                    : "Inactive"}
                                </Badge>
                              </p>
                            </Media>
                          </Media>
                        </Col>

                        <Col lg="6">
                          <div className="text-right">
                            {showapidetails()}
                            {backToPartnerListLink()}
                          </div>
                        </Col>
                      </Row>

                      <h5 className="font-size-15 mt-4">
                        {props.t("Partner Details")}:{" "}
                      </h5>

                      <div className="text-muted mt-4">
                        <Row>
                          <Col lg="6">
                            <p>
                              <i className="bx bx-building text-primary mr-1"></i>{" "}
                              <strong>{props.t("Pharmacy Name")}: </strong>{" "}
                              {capitalizeWords(gdata.userId?.firstName +
                                " " +
                                gdata.userId?.lastName)}
                            </p>

                            <p>
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <strong>{props.t("Created At")}: </strong>{" "}
                              {dctHumanReadableFormat(gdata.createdAt)}
                            </p>

                            <p>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("Updated At")}:</strong>{" "}
                              {dctHumanReadableFormat(gdata.updatedAt)}
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Address 1")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.Address1
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Primary Phone")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.PrimaryPhone
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("City")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.City
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Zip Code")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.ZipCode
                              }
                            </p>
                          </Col>

                          <Col lg="6">
                            <p>
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <strong>{props.t("Pharmacy Email")}: </strong>{" "}
                              {gdata.userId?.email}
                            </p>
                            <p>
                              <i className="bx bxs-key text-primary mr-1"></i>{" "}
                              <strong>{props.t("Secret Key")}: </strong>{" "}
                              {gdata.secretkey}
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Store Name")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.StoreName
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Address 2")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.Address2
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Primary Fax")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.PrimaryFax
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("State")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.State
                              }
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                ) : (
                  <CardBody>
                    {gmessage ? (
                      <div className="text-center">
                        {backToPartnerListLink()}
                      </div>
                    ) : (
                      <div className="text-center">
                        {loadingMessage("Loading...")}
                      </div>
                    )}
                  </CardBody>
                )}
              </Card>
              <Card>
                {gmessage ? (
                  <Alert color="danger" className="text-center">
                    {gmessage}
                  </Alert>
                ) : null}
              </Card>
            </Col>
          </Row>

          {apidetails && (
            <SweetAlert
              closeOnClickOutside={true}
              onConfirm={() => setapidetails(false)}
              onCancel={() => setapidetails(false)}
              style={{
                color: "white",
                width: "60%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Row lg={12} className="d-flex justify-content-end mr-3">
                <i
                  className="fas fa-times"
                  style={{
                    fontSize: "15px",
                    cursor: "pointer",
                    color: "#000",
                  }}

                  onClick={() => setapidetails(false)}
                ></i>
              </Row>
              <Row lg={12} className="d-flex justify-content-center m-2">
                <h2>{Config.names.appName} - Webhook Prescription API:</h2>
              </Row>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "1"
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    {props.t(`API V1`)}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2"
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    {props.t(`API V2`)}
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="p-3">
                  <Row>
                    <Col sm="12">
                      <h5>API: POST https://next.doctegrity.net/prescriptionfill</h5>
                      <h6 className="important">
                        <b>Secretkey</b> : {gdata.secretkey}
                      </h6>
                      <div
                        className="codeView"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          lineHeight: "22px",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          padding: "2rem",
                        }}
                      >
                        <code>{`{`}</code>
                        <code className="code_Margin">{`"firstName": "David",`}</code>{" "}
                        <code className="code_Margin">{`"lastName": "Robinson",`}</code>{" "}
                        <code className="code_Margin">{`"email": "david@gmail.com",`}</code>{" "}
                        <code className="code_Margin">{`"phone": "+15212365478",`}</code>{" "}
                        <code className="code_Margin">{`"dateOfBirth": "1997-01-23",`}</code>{" "}
                        <code className="code_Margin">{`"gender": "male",`}</code>{" "}
                        <code className="code_Margin">{`"country": "US",`}</code>{" "}
                        <code className="code_Margin">{`"state": "CA",`}</code>{" "}
                        <code className="code_Margin">{`"city": "Salt Lake City",`}</code>{" "}
                        <code className="code_Margin">{`"zipCode": "95201",`}</code>{" "}
                        <code className="code_Margin">{`"shippingAddress": "Salt Lake City, 95201, US",`}</code>{" "}
                        <code className="code_Margin">{`"billingAddress": "Salt Lake City, 95201, US",`}</code>{" "}
                        <code className="code_Margin">{`"bodyData": [`}</code>
                        <code className="code_Margin_extra">{`{`}</code>
                        <code className="code_Margin_extra_larg">{`"questionOne": "ans one"`}</code>{" "}
                        <code className="code_Margin_extra">{`},`}</code>
                        <code className="code_Margin_extra">{`{`}</code>
                        <code className="code_Margin_extra_larg">{`"questionTwo": "ans two"`}</code>{" "}
                        <code className="code_Margin_extra">{`}`}</code>
                        <code className="code_Margin">{`]`}</code>
                        <code>{`}`}</code>
                      </div>
                      <h5>
                        **NOTE:{" "}
                        {props.t(
                          "Please pass the SecretKey on Headers in order to create prescription using the webhook API."
                        )}
                      </h5>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2" className="p-3">
                  <Row>
                    <Col sm="12">
                      <h5>API: POST https://next.doctegrity.net/prescriptionfill/v2</h5>
                      <h6 className="important">
                        <b>Secretkey</b> : {gdata.secretkey}
                      </h6>
                      <div
                        className="codeView"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          lineHeight: "22px",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          padding: "2rem",
                        }}
                      >
                        <code>{`{`}</code>
                        <code className="code_Margin">{`"firstName": "David",`}</code>{" "}
                        <code className="code_Margin">{`"lastName": "Robinson",`}</code>{" "}
                        <code className="code_Margin">{`"email": "david@gmail.com",`}</code>{" "}
                        <code className="code_Margin">{`"phone": "+15212365478",`}</code>{" "}
                        <code className="code_Margin">{`"dateOfBirth": "1997-01-23",`}</code>{" "}
                        <code className="code_Margin">{`"gender": "male",`}</code>{" "}
                        <code className="code_Margin">{`"country": "US",`}</code>{" "}
                        <code className="code_Margin">{`"state": "CA",`}</code>{" "}
                        <code className="code_Margin">{`"city": "Salt Lake City",`}</code>{" "}
                        <code className="code_Margin">{`"zipCode": "95201",`}</code>{" "}
                        <code className="code_Margin">{`"shippingAddress": "Salt Lake City, 95201, US",`}</code>{" "}
                        <code className="code_Margin">{`"billingAddress": "Salt Lake City, 95201, US",`}</code>{" "}
                        <code className="code_Margin">{`"orderNo": "AJGHS12334546",`}</code>{" "}
                        <code className="code_Margin">{`"RequestedRx": ["Rx Item 1", "Rx Item 2", "Rx Item 3"],`}</code>{" "}
                        <code className="code_Margin">{`"bodyData": [`}</code>
                        <code className="code_Margin_extra">{`{`}</code>
                        <code className="code_Margin_extra_larg">{`"question": "Do you take any medications or supplements?",`}</code>{" "}
                        <code className="code_Margin_extra_larg">{`"answer": "No"`}</code>{" "}
                        <code className="code_Margin_extra">{`},`}</code>
                        <code className="code_Margin_extra">{`{`}</code>
                        <code className="code_Margin_extra_larg">{`"question": "Please list all your medical conditions, past and present.",`}</code>{" "}
                        <code className="code_Margin_extra_larg">{`"answer": "Weak, Strong"`}</code>{" "}
                        <code className="code_Margin_extra">{`}`}</code>
                        <code className="code_Margin">{`],`}</code>
                        <code className="code_Margin">{`"medProfileData": [{`}</code>
                        <code className="code_Margin_extra_larg">{`"Key1": "Value1"`}</code>{" "}
                        <code className="code_Margin_extra">{`}, {`}</code>
                        <code className="code_Margin_extra_larg">{`"Key2": "Value2"`}</code>{" "}
                        <code className="code_Margin">{`}]`}</code>
                        <code>{`}`}</code>
                      </div>
                      <h5>
                        **NOTE:{" "}
                        {props.t(
                          "Please pass the SecretKey on Headers in order to create prescription using the webhook API."
                        )}
                      </h5>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>


            </SweetAlert>
          )}
        </Container>
        {prep && gdata && gdata.secretkey && (
          <Prescription
            secretkey={gdata?.secretkey}
            propsHistoryPush={`/pharmacy-overview/id/${gdata?.id}`}
          ></Prescription>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { partner } = state.Partner;
  //const { member } = state.Member;
  const { message } = state.Partner;
  return { partner, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    partnerActionSuccessful,
    setSinglePartner,
    setSinglePartnerAuth,
  })(withNamespaces()(PharmacyOverview))
);
