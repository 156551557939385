import React, { useState, useEffect } from "react";

//Style from Reactstrap
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

import {
  getPartner,
  partnerActionSuccessful,
  setPartnerListing,
  setSinglePartner,
} from "../../store/actions";

//Import partnerList
import PartnerList from "../../components/Partner/PartnerList";

//Import Create and Update partner
import CreateUpdatePartner from "../../components/Partner/CreateUpdatePartner";

import axios from "axios";
import { Config } from "../../Config";

const Partner = (props) => {
  const [postsPerPage, setPostsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);

  const [gdata, setGData] = useState({
    loading: true,
    partners: [],
    message: null,
  });

  const [ajaxKey, setAjaxKey] = useState(false)
  let clickDetect = {
    search: false,
    reset: false,
    dropdown: false
  }

  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    partnerSearchParam: "",
    type: "filter",
    reset: false,
  });

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        partnerSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        partnerSearchParam: "",
        type: "filter",
        reset: false,
      });
      return fetchPartnerData(searchParam, selectedPage);
    }
    fetchPartnerData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        partnerSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        partnerSearchParam: "",
        type: "filter",
        reset: true,
      });
      return fetchPartnerData(searchParam, selectedPage);
    }
    // if(selectedPage > 1)
    fetchPartnerData(searchParam, selectedPage);
  };

  const fetchPartnerData = (param, currentPage = 1) => {
    if (clickDetect.reset || clickDetect.search || clickDetect.dropdown) {
      gdata.partners = []
    }
    setAjaxKey(false);
    let searchParam = "";
    if (param) {
      searchParam = param.partnerSearchParam
        ? "&q=" + param.partnerSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    let url =
      process.env.REACT_APP_API_PATH +
      `/partner` +
      `?page=${currentPage}&limit=${postsPerPage}` +
      searchParam;
    let cancel;

    axios
      .get(url, headers, {
        cancelToken: new axios.CancelToken(c => cancel = c),
      })
      .then(function (response) {
        if (response.status === 200) {
          setAjaxKey(true)
        }
        const data = response.data;
        let message = "";
        message = data?.data.length > 0 ? "" : "No record found.";
        let structured = data.data
        if ( (!param || param == '') || data.total > 30) {
          structured = [...gdata.partners,...data.data]
        }
        dispatch(setPartnerListing(structured, message));
        setGData({
          loading: false,
          partners: structured,
          message: message,
        });

        setTotalPosts(Math.ceil(data.total / postsPerPage));
        setCurrentPage(currentPage - 1);
        if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
          document
            .getElementsByClassName("dr-csearch-spinner")[0]
            .classList.add("d-none");
        }
      })
      .catch(function (err) {
        console.log(err);
        if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
          document
            .getElementsByClassName("dr-csearch-spinner")[0]
            .classList.add("d-none");
        }
        var message = checkAjaxError(err);
        setGData({
          loading: false,
          partners: null,
          message: message,
        });
        setTotalPosts(0);
        setCurrentPage(0);
        dispatch(setPartnerListing(null, message));
        if (axios.isCancel(err)) return
      })
      return () => cancel();
      // .then(function () {});
  };

  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;

    let csearch = urlParams.get("csearch");
    let searchParam = "";
    if (csearch) {
      searchParam = {
        partnerSearchParam: csearch,
        type: "filter",
        // key:  dropdown ? 'fulfilment': ''
      };
      setSearchFormDetails({
        partnerSearchParam: "",
        type: "filter",

        reset: true,
      });
    }
    fetchPartnerData(searchParam, currentPage+1)
  },[currentPage])

  const onScrollToEnd = () => {
    setCurrentPage(currentPage + 1);
  }
  window.onscroll = function() {
    //check if the page has scrolled to the bottom
    let innerHeight = window.innerHeight + document.documentElement.scrollTop + 700;
    let offsetHeight = document.documentElement.offsetHeight;
    if (currentPage+1 === totalPosts || gdata.partners?.length == 0) return;

    if (innerHeight >= offsetHeight) {
      if (ajaxKey) {
        onScrollToEnd();
      }
    }
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let csearch = urlParams.get("csearch");
    let searchParam = "";
    if (csearch) {
      let searchParam = {
        partnerSearchParam: csearch,
        type: "filter",
      };
      setSearchFormDetails({
        partnerSearchParam: "",
        type: "filter",
        reset: true,
      });
      // fetchPartnerData(searchParam);
    } else {
      // fetchPartnerData(searchParam);
    }
  }, []);

  // Apply Partner Search
  function handleSearch(e) {
    if (!searchFormDetails.partnerSearchParam || searchFormDetails.partnerSearchParam == '') {
      return;
    }
    e.preventDefault();
    if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
      document
        .getElementsByClassName("dr-csearch-spinner")[0]
        .classList.remove("d-none");
    }
    gdata.partners = [];

    fetchPartnerData(searchFormDetails);
    setSearchFormDetails({
      partnerSearchParam: searchFormDetails.partnerSearchParam,
      type: "filter",
      reset: true,
    });
    let param = searchFormDetails.partnerSearchParam
      ? searchFormDetails.partnerSearchParam
      : "";
    props.history.push("/partners?csearch=" + param);
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    clickDetect.reset = true;
    fetchPartnerData();
    setSearchFormDetails({
      partnerSearchParam: "",
      type: "filter",
      reset: false,
    });
    document.getElementById("partner-search-form").reset();
    props.history.push("/partners");
  }

  const { loading, partners, message } = gdata;
  const partner_action = useSelector((state) => state.Partner.action_type);

  const propsUpdatePartnerData = () => {
    if (!partners && !props.singlePartner) {
      return;
    }
    if (partners && props.singlePartner) {
      let partnerid = props.singlePartner.id;
      //Update record
      if (partner_action === 2) {
        var index = partners.data.findIndex((x) => x.id === partnerid);
        partners.data[index] = props.singlePartner;
        dispatch(setPartnerListing(partners.data, ""));
      }

      //Insert new record
      if (partner_action === 1) {
        partners.data.unshift(props.singlePartner);
        dispatch(setPartnerListing(partners.data, ""));
      }

      let activeRow = document.querySelectorAll(`[data-row-id="${partnerid}"]`);
      if (activeRow[0]) {
        activeRow[0].scrollIntoView({ behavior: "smooth" });
        activeRow[0].classList.add("active");
        const timer = setTimeout(() => {
          activeRow[0].classList.remove("active");
        }, 3000);
        return () => clearTimeout(timer);
      }
      setGData({
        loading: false,
        partners: partners,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="9">
              <Form
                inline
                onSubmit={handleSearch}
                id="partner-search-form"
                className={`mb-2 ${Config.names.slugName}-search-form`}
              >
                <FormGroup className="mr-sm-2 mb-sm-0 mb-0">
                  <Input
                    type="text"
                    name="partnerSearchParam"
                    style={{ minWidth: "250px" }}
                    placeholder={props.t("Search...")}
                    value={searchFormDetails.partnerSearchParam}
                    onChange={(e) => {
                      let { value } = e.target;
                      setSearchFormDetails((prevState) => ({
                        ...prevState,
                        partnerSearchParam: value,
                      }));
                    }}
                  />
                </FormGroup>
                <Button onClick={handleSearch}>
                  <span className="dr-csearch-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                  Search
                </Button>
                {searchFormDetails.reset ? (
                  <span
                    className={`ml-2 ${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-reset-search`}
                    onClick={resetSearch}
                  >
                    <i className="bx bx-reset font-size-22 align-middle mr-2"></i>
                  </span>
                ) : (
                  ""
                )}
              </Form>
            </Col>
            <Col lg="3">
              <div className="text-right mb-2">
                <CreateUpdatePartner
                  updatePartnerDataFlag={propsUpdatePartnerData}
                  partner_action_type="create"
                  partner_button_type="create"
                  {...props}
                />
                <CreateUpdatePartner
                  updatePartnerDataFlag={propsUpdatePartnerData}
                  partner_action_type="edit"
                  partner_button_type="edit_icon"
                  {...props}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PartnerList
                    {...props}
                    partnerListMessage={message}
                    total={totalPosts}
                    getDataOnEnter={getDataOnEnter}
                    // getUserData={getUserData}
                    currentPage={currentPage}
                    perPage={postsPerPage}
                    ajaxLoader={loading}
                  ></PartnerList>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { partner } = state.Partner;
  const { singlePartner } = state.Partner;
  const { message } = state.Partner;
  return { partner, message, singlePartner };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPartner,
    partnerActionSuccessful,
    setSinglePartner,
  })(withNamespaces()(Partner))
);
