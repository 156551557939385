import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  createGroup,
  updateGroup,
  createGroupFailed,
  groupActionSuccessful,
  setSingleGroup,
} from "../../store/actions";

//Include Both Helper File with needed methods
import { dctGetAsyncData } from "../../helpers/backend_helper";
import { Config } from "../../Config";

const CreateUpdateGroup = (props) => {
  const [modal, setmodal] = useState(false);
  const [msgEnable, setmsgEnable] = useState(true);

  function toggleModalClose(e) {
    //checking Store props
    if (props.singleGroup) {
      props.updateGroupDataFlag();
    }
  }

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    // console.log("Value data => ", values)
    document
      .getElementsByClassName("dr-gform-spinner")[0]
      .classList.remove("d-none");
    setmsgEnable(true);

    //call reducer method
    if (values.actionType === "edit") {
      props.updateGroup(values, props.history);
    } else {
      props.createGroup(values, props.history);
    }
  }

  const dispatch = useDispatch();
  const editFormGroupItem = (e) => {
    setmsgEnable(false);
    dispatch(groupActionSuccessful(null, "", ""));
  };

  const createFormGroupItem = (e) => {
    setmsgEnable(false);
    dispatch(groupActionSuccessful(null, "", ""));
    dispatch(setSingleGroup(null, "", ""));
  };

  let groupData = useSelector((state) => state.Group.singleGroup);
  // console.log("group data ---", groupData)

  groupData = groupData ? groupData : [];
  const action_type = props.group_action_type === "edit" ? "edit" : "create";
  const button_label =
    props.group_action_type === "edit" ? "Submit" : "Create Group";
  const modal_title =
    props.group_action_type === "edit" ? "Update Group" : "Create Group";

  return (
    <React.Fragment>
      {(() => {
        switch (props.group_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="group_update_modal"
                onClick={() => {
                  setmodal(!modal);
                  editFormGroupItem();
                }}
              ></i>
            );
          case "edit_btn":
            return (
              <Button
                type="button"
                color="primary"
                id="group_update_modal"
                className="waves-effect waves-light mr-2"
                onClick={() => {
                  setmodal(!modal);
                  editFormGroupItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1"></i> {props.t("Edit")}
              </Button>
            );
          default:
            return (
              <Link
                onClick={() => {
                  setmodal(!modal);
                  createFormGroupItem();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id="group_create_modal"
              >
                <i className="mdi mdi-plus mr-1"></i> {props.t("Create Group")}
              </Link>
            );
        }
      })()}

      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          setmodal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-group-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal(!modal);
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.createGroupError && props.createGroupError && msgEnable ? (
                <Alert color="danger">{props.createGroupError}</Alert>
              ) : null}
              {props.message && props.message && msgEnable ? (
                <Alert color="success">{props.message}</Alert>
              ) : null}

              {action_type === "edit" ? (
                <AvField
                  name="id"
                  type="hidden"
                  //label = {props.t("Group Id")}
                  value={
                    groupData.id != undefined ? groupData.id : groupData._id
                  }
                  readOnly={true}
                />
              ) : (
                ""
              )}
              <AvField name="actionType" type="hidden" value={action_type} />

              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <AvField
                      name="groupName"
                      label={props.t("Group Name")}
                      className="form-control"
                      placeholder={props.t("Group Name")}
                      type="text"
                      required
                      value={groupData.groupName}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <AvField
                      name="groupDescription"
                      label={props.t("Group Description")}
                      className="form-control"
                      placeholder={props.t("Group Description")}
                      type="textarea"
                      value={groupData.groupDescription}
                    />
                  </div>
                </Col>
              </Row>

              {
                action_type === "edit" ? "" : <Row>
                  <Col lg={6}>
                    <div className="form-group">
                      <AvField
                        name="groupCode"
                        label={props.t("MTM Group Code")}
                        className="form-control"
                        placeholder={props.t("MTM Group Code")}
                        type="text"
                        value={groupData.groupCode}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group">
                      <AvField
                        name="planId"
                        label={props.t("MTM Plan id")}
                        className="form-control"
                        placeholder={props.t("MTM Plan id")}
                        type="text"
                        value={groupData.planId}
                      />
                    </div>
                  </Col>
                </Row>
              }



              {action_type === "edit" ? (
                <React.Fragment>
                  <Row>
                    <Col lg={12}>
                      <div className="text-center mt-3">
                        <h4 className="font-size-14 text-muted mb-0">
                          {props.t("Default MTM Group Information")}
                        </h4>
                      </div>
                    </Col>
                  </Row>

                  <div className={`mt-2 mb-2 ${Config.names.slugName}-other-section p-4`}>
                    <Row>
                      <Col lg={6}>
                        <div className="form-group">
                          <AvField
                            name="groupCode"
                            label={props.t("MTM Group Code")}
                            className="form-control"
                            placeholder={props.t("MTM Group Code")}
                            type="text"
                            value={groupData.groupCode}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-group">
                          <AvField
                            name="planId"
                            label={props.t("MTM Plan id")}
                            className="form-control"
                            placeholder={props.t("MTM Plan id")}
                            type="text"
                            value={groupData.planId}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="form-group">
                          <AvField
                            name="mainContactName"
                            label={props.t("Main Contact Name")}
                            className="form-control"
                            placeholder={props.t("Main Contact Name")}
                            type="text"
                            value={groupData.mainContactName}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-group">
                          <AvField
                            name="mainContactEmail"
                            label={props.t("Main Contact Email")}
                            className="form-control"
                            placeholder={props.t("Main Contact Email")}
                            type="text"
                            value={groupData.mainContactEmail}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="form-group">
                          <AvField
                            name="mainContactPhone"
                            label={props.t("Main Contact Phone")}
                            className="form-control"
                            placeholder={props.t("Main Contact Phone")}
                            type="text"
                            value={groupData.mainContactPhone}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-group">
                          <AvField
                            name="secondaryPhone"
                            label={props.t("Secondary Phone")}
                            className="form-control"
                            placeholder={props.t("Secondary Phone")}
                            type="text"
                            value={groupData.secondaryPhone}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group">
                          <AvField
                            name="address"
                            label={props.t("Address")}
                            className="form-control"
                            placeholder={props.t("Address")}
                            type="textarea"
                            value={groupData.address}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setmodal(!modal)}
                    >
                      {props.t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                    >
                      <span className="dr-gform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      {button_label}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createGroupError } = state.Group;
  const { group } = state.Group;
  const { message } = state.Group;
  return { createGroupError, group, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createGroup,
    updateGroup,
    createGroupFailed,
    setSingleGroup,
  })(withNamespaces()(CreateUpdateGroup))
);
