import React, { useState, useEffect } from "react";

//Style from Reactstrap
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  DropdownMenu,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
//Include Both Helper File with needed methods
import { checkAjaxError } from "../../../helpers/backend_helper";
import { withNamespaces } from "react-i18next";
import {
  getMember,
  memberActionSuccessful,
  setMemberListing,
  setSingleMember,
} from "../../../store/actions";

//Import MemberList
import MemberList from "../../../components/Member/Admin/MemberList";

//Import Create and Update Member
import CreateUpdateMember from "../../../components/Member/Admin/CreateUpdateMember";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import consultFormFooter from "../Consultation/consultFormFooter";
import { v4 as uuidv4 } from "uuid";
import { Config } from "../../../Config";
import zIndex from "@material-ui/core/styles/zIndex";

const {
  Parser,
  transforms: { unwind },
} = require("json2csv");

const Members = (props) => {
  const { authUserType } = useSelector((state) => state.Login);

  const [postsPerPage, setPostsPerPage] = useState(
    process.env.REACT_APP_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [dropdownValue, setdropdownValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [dependentSearchDropdown, setDependentSearchDropdown] = useState(false);
  const [dependentSearchValue, setDependentSearchValue] = useState("member");
  const [dependentValue, setDependentValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [expTitle, setExpTitle] = useState("");
  const [expDesc, setExpDesc] = useState("");
  const [gdata, setGData] = useState({
    loading: true,
    members: [],
    message: null,
  });

  const [ajaxKey, setAjaxKey] = useState(false);
  let clickDetect = {
    search: false,
    reset: false,
    dropdown: false,
    dependentSearchDropdown: false,
  };
  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    memberSearchParam: "",
    type: "filter",
    reset: false,
  });

  const dependentType = localStorage.getItem("type") || "";
  let toggleValue = [
    {
      options: [
        { label: "All", value: "all" },
        { label: "MTM", value: "mtm" },
        { label: "Doctegrity", value: "doctegrity" },
      ],
    },
  ];

  let dependentValueToggle = [
    {
      options: [
        { label: "Member", value: "member" },
        { label: "Dependent", value: "dependent" },
      ],
    },
  ];
  let updateMemberValue = dependentValueToggle[0].options.filter(
    (type) => type.value == dependentType
  );

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        memberSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        memberSearchParam: "",
        type: "filter",
        reset: false,
      });
      return fetchMemberData(searchParam, selectedPage);
    }
    fetchMemberData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        memberSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        memberSearchParam: "",
        type: "filter",
        reset: true,
      });
      return fetchMemberData(searchParam, selectedPage);
    }
    // if(selectedPage > 1)
    fetchMemberData(searchParam, selectedPage);
  };

  const fetchMemberData = (param, currentPage = 1) => {
    if (
      clickDetect.reset ||
      clickDetect.search ||
      clickDetect.dropdown ||
      clickDetect.dependentSearchDropdown
    ) {
      gdata.members = [];
    }
    setAjaxKey(false);

    let searchParam = "";
    if (param) {
      searchParam = param.memberSearchParam
        ? param.key == "fulfilment"
          ? "&fulfilment=" + param.memberSearchParam
          : "&q=" + param.memberSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    // let url =
    //   process.env.REACT_APP_API_PATH +
    //   `/member?populate=userId,partnerId,companyId,dependent&page=${currentPage}&limit=${postsPerPage}&type=${
    //     param?.value !== undefined ? param?.value : dependentType
    //   }` +
    //   searchParam;
    let url =
      process.env.REACT_APP_API_PATH +
      `/member?populate=userId,partnerId,companyId,dependent&page=${currentPage}&limit=${postsPerPage}` +
      searchParam;
    let cancel;
    axios
      .get(url, headers, {
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
      .then(function (response) {
        if (response.status === 200) {
          setAjaxKey(true);
        }

        const data = response.data;
        // setDependentSearchValue(data);
        setDependentValue(data.data);
        let message = "";
        message = data.data.length > 0 ? "" : "No record found.";
        let structured = data.data;
        if (!param || param == "" || data.total > 30) {
          structured = [...gdata.members, ...data.data];
        }
        dispatch(setMemberListing(data.data.length > 0 ? structured : gdata.members, message));
        setGData({
          loading: false,
          members: structured,
          message: message,
        });

        setTotalItems(data.total);
        setTotalPages(Math.ceil(data.total / postsPerPage));
        setCurrentPage(currentPage - 1);
        if (document.getElementsByClassName("dr-msearch-spinner").length > 0) {
          document
            .getElementsByClassName("dr-msearch-spinner")[0]
            .classList.add("d-none");
        }
      })
      .catch(function (err) {
        if (document.getElementsByClassName("dr-msearch-spinner").length > 0) {
          document
            .getElementsByClassName("dr-msearch-spinner")[0]
            .classList.add("d-none");
        }
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = err.response.data.cause.details;
          }
        }

        setGData({
          loading: false,
          members: null,
          message: message,
        });
        setTotalItems(0);
        setTotalPages(0);
        setCurrentPage(0);
        dispatch(setMemberListing(null, message));
        if (axios.isCancel(err)) return;
      });
    return () => cancel();
    // .then(function () { });
  };

  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      searchParam = {
        memberSearchParam: msearch,
        type: "filter",
        key: dropdown ? "fulfilment" : "",
      };
      setSearchFormDetails({
        memberSearchParam: "",
        type: "filter",
        reset: true,
      });
    }
    fetchMemberData(searchParam, currentPage + 1);
  }, [currentPage]);

  const onScrollToEnd = () => {
    setCurrentPage(currentPage + 1);
  };
  window.onscroll = function () {
    //check if the page has scrolled to the bottom
    let innerHeight =
      window.innerHeight + document.documentElement.scrollTop + 700;
    let offsetHeight = document.documentElement.offsetHeight;
    if (currentPage + 1 === totalPages || gdata.members?.length == 0) return;

    if (innerHeight >= offsetHeight) {
      if (ajaxKey) {
        onScrollToEnd();
      }
    }
  };

  const getSearchData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchValue = urlParams.get("msearch");
    return searchValue;
  };

  const value = getSearchData();

  const [searchInput, setSearchInput] = useState(value);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        memberSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        memberSearchParam: "",
        type: "filter",
        reset: true,
      });
      // fetchMemberData(searchParam);
    } else {
      // fetchMemberData(searchParam);
    }
  }, []);

  // Apply Member Search
  function handleSearch(e) {
    // if (
    //   !searchFormDetails.memberSearchParam ||
    //   searchFormDetails.memberSearchParam == ""
    // ) {
    //   return;
    // }
    // setdropdownValue("");
    // setDependentSearchValue("");
    e.preventDefault();
    // setSearchInput("");
    // document.getElementById("member-search-form").reset();
    document
      .getElementsByClassName("dr-msearch-spinner")[0]
      .classList.remove("d-none");
    gdata.members = [];
    fetchMemberData(searchFormDetails);
    setSearchFormDetails({
      memberSearchParam: searchFormDetails?.memberSearchParam,
      type: "filter",
      reset: true,
    });
    let param =
      searchFormDetails?.memberSearchParam !== undefined
        ? searchFormDetails?.memberSearchParam
        : param?.memberSearchParam;
    // setSearchInput("");
    document.getElementById("member-search-form").reset();
    props.history.push("/members?msearch=" + param);
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    setDropdown(false);
    setDependentSearchDropdown(false);
    clickDetect.reset = true;
    fetchMemberData();
    setSearchFormDetails({
      memberSearchParam: "",
      type: "filter",
      reset: false,
    });
    setSearchInput("");
    document.getElementById("member-search-form").reset();
    props.history.push("/members");
  }
  function resetSearchAll() {
    fetchMemberData();
    setSearchFormDetails({
      memberSearchParam: "",
      type: "filter",
      reset: false,
    });
    document.getElementById("member-search-form").reset();
    props.history.push("/members");
  }

  const { loading, members, message } = gdata;
  const member_action = useSelector((state) => state.Member.action_type);

  const propsUpdateMemberData = () => {
    if (!members && !props.singleMember) {
      return;
    }

    if (members && props.singleMember) {
      let memberid = props.singleMember.id;
      //Update record
      if (member_action === 2) {
        var index = members.findIndex((x) => x.id === memberid);
        members[index] = props.singleMember;
        dispatch(setMemberListing(members, "3"));
      }

      //Insert new record
      if (member_action === 1) {
        members.unshift(props.singleMember);
        dispatch(setMemberListing(members, "3"));
      }

      let activeRow = document.querySelectorAll(`[data-row-id="${memberid}"]`);
      if (activeRow[0]) {
        activeRow[0].scrollIntoView({ behavior: "smooth" });
        activeRow[0].classList.add("active");
        const timer = setTimeout(() => {
          activeRow[0].classList.remove("active");
        }, 3000);
        return () => clearTimeout(timer);
      }
      setGData({
        loading: false,
        members: members,
      });
    }
  };

  const colorStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#abb7f0" : isSelected ? "#b0bdf6" : "",
        color: "black",
        ":hover": {
          color: isFocused ? "white" : undefined,
        },
      };
    },
  };
  return (
    <React.Fragment>
      <div className="page-content relative">
        <Container fluid>
          <Row>
            <Col sm="12" lg="6">
              <Form
                // inline
                onSubmit={handleSearch}
                id="member-search-form"
                className={`mb-2 ${Config.names.slugName}-search-form`}
              >
                <div className="d-flex flex-column  flex-sm-row sm:justify-content-center align-items-center align-items-sm-start">
                  <FormGroup className="mb-sm-0 mb-0 mr-2">
                    <Input
                      type="text"
                      name="memberSearchParam"
                      placeholder={props.t("Search...")}
                      defaultValue={
                        searchInput == "mtm" || searchInput == "doctegrity"
                          ? ""
                          : searchInput
                      }
                      // value={searchFormDetails.memberSearchParam || ""}
                      onChange={(e) => {
                        let { value } = e.target;
                        setSearchFormDetails((prevState) => ({
                          ...prevState,
                          memberSearchParam: value,
                        }));
                        setSearchInput(value);
                        if (value.trim().length > 0) {
                          setIsLoading(true);
                        } else {
                          setIsLoading(false);
                        }
                      }}
                    />
                  </FormGroup>
                  {/* {authUserType !== "member" && (
                    <FormGroup className="member-select mr-2">
                      <Select
                        styles={colorStyles}
                        defaultValue={
                          updateMemberValue.length > 0
                            ? updateMemberValue
                            : dependentValueToggle[0].options[0]
                        }
                        // value={dependentSearchValue}
                        onChange={(e) => {
                          setDependentSearchValue(e);
                          localStorage.setItem("type", e.value);
                          setDependentSearchDropdown(true);
                          clickDetect.dependentSearchDropdown = true;
                          fetchMemberData(e);
                          // props.history.push(
                          //   "/members?msearch=" + param.memberSearchParam
                          // );
                          // handleSearch(e.value);
                        }}
                        // onChange={(e) => {
                        //   setDependentSearchValue(e);
                        //   localStorage.setItem("type", e.value);
                        //   setDependentSearchDropdown(true);
                        //   clickDetect.dropdown = true;
                        //   if (e.value == "all") {
                        //     resetSearchAll();
                        //   } else {
                        //     let { value } = e;
                        //     const param = {
                        //       memberSearchParam: "",
                        //       key: "fulfilment",
                        //     };
                        //     fetchMemberData(param);
                        //     props.history.push(
                        //       "/members?msearch=" + param.memberSearchParam
                        //     );
                        //   }
                        // }}
                        options={dependentValueToggle}
                        classNamePrefix="select2-selection"
                        // style={{ minWidth: "900px" }}
                        // styles={{
                        //   option: (provided, state) => ({
                        //     ...provided,
                        //     color: state.isFocused ? "white" : "black",
                        //   }),
                        // }}
                      />
                    </FormGroup>
                  )} */}
                  <Button
                    onClick={handleSearch}
                    style={{ maxHeight: "38px", width: "140px" }}
                  >
                    <span className="dr-msearch-spinner d-none">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    </span>{" "}
                    Search
                  </Button>
                  {searchFormDetails.reset ? (
                    <span
                      className={`ml-2 ${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-reset-search mt-2`}
                      onClick={resetSearch}
                    >
                      <i className="bx bx-reset font-size-22 align-middle mr-2"></i>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </Form>
            </Col>
            <Col sm="6" className="d-flex flex-row">
              {authUserType !== "member" && (
                <FormGroup className="member-select ml-auto mr-2">
                  <Select
                    styles={colorStyles}
                    value={dropdownValue}
                    onChange={(e) => {
                      setdropdownValue(e);
                      setDropdown(true);
                      clickDetect.dropdown = true;
                      if (e.value == "all") {
                        resetSearchAll();
                      } else {
                        let { value } = e;
                        const param = {
                          memberSearchParam: value,
                          key: "fulfilment",
                        };
                        fetchMemberData(param);
                        props.history.push(
                          "/members?msearch=" + param.memberSearchParam
                        );
                      }
                    }}
                    options={toggleValue}
                    // styles={{
                    //   option: (provided, state) => ({
                    //     ...provided,
                    //     color: state.isFocused ? "white" : "black",
                    //   }),
                    // }}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              )}
              <div className="text-right mb-2">
                <CreateUpdateMember
                  fetchMemberData={fetchMemberData}
                  updateMemberDataFlag={propsUpdateMemberData}
                  member_action_type="create"
                  member_button_type="create"
                  updateData={fetchMemberData}
                  param={""}
                  {...props}
                />
                <CreateUpdateMember
                  fetchMemberData={fetchMemberData}
                  updateMemberDataFlag={propsUpdateMemberData}
                  member_action_type="edit"
                  member_button_type="edit_icon"
                  updateData={fetchMemberData}
                  param={""}
                  {...props}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <MemberList
                    {...props}
                    memberListMessage={message}
                    totalPages={totalPages}
                    totalItems={totalItems}
                    getDataOnEnter={getDataOnEnter}
                    // getUserData={getUserData}
                    currentPage={currentPage}
                    perPage={postsPerPage}
                    ajaxLoader={loading}
                    dependentData={dependentValue}
                    type={dependentSearchValue.value}
                    searchInput={searchInput}
                  ></MemberList>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { member } = state.Member;
  const { singleMember } = state.Member;
  const { message } = state.Member;
  return { member, message, singleMember };
};

export default withRouter(
  connect(mapStatetoProps, {
    getMember,
    memberActionSuccessful,
    setSingleMember,
  })(withNamespaces()(Members))
);
