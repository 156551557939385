import React from "react";
import { useState } from "react";
import { Row, Col } from "reactstrap";
import { Config } from "../../../../Config/Config";
import axios from "axios";
import { EmailFormatter } from "../../service/emailFormatter";
const IdentifyAccountComponent = (props) => {
  const [loading, setLoading] = useState(false);

  const [successId, setSuccessId] = useState("");
  const [email, setEmail] = useState();
  const [renderOption, setRenderOption] = useState("account");
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    message: "",
  });

  const HandleClick = (id, email) => {
    props.userData.map((data, i) => {
      if (id === data.id) {
        setSuccessId(id);
      }
    });
    setEmail(email);
    // console.log({ id, email });
  };

  const handleSubmit = (e) => {
    if (!email) {
      props.setAlertData({
        ...props.alertData,
        type: "error",
        title: "Warning",
        message: "Please Select Email.",
      });
      props.setShowAlert(true);
      setTimeout(() => props.setShowAlert(false), 5000);
      return;
    }
    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_PATH + "/auth/forgot-password", {
        email: email,
      })
      .then((res) => {
        if (res.status === 200 && res.data.success === true) {
          props.setEmail(email);
          setLoading(false);
          props.setRenderOption("success");
        }
      })
      .catch((err) => {
        setLoading(false);
        props.setRenderOption("noEmail");
      });
  };

  return (
    <>
      <Col
        lg="5"
        md="6"
        sm="8"
        xs="10"
        className={`${Config.names.slugName}-box-shadow rounded-lg mt-3`}
      >
        <div className="py-5 px-3">
          <h5 className="text-primary font-weight-bol text-uppercase">
            Confirm your account
          </h5>
          <p className="font-size-15 font-weight-light mt-3">
            Please select the correct email address associated with your account
            below
          </p>

          <div
            style={{
              marginTop: "1rem",
              marginBottom: "1.25rem",
            }}
          >
            {props.userData.map((data, i) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1.5rem",
                  marginBottom: "0.75rem",
                  cursor: "pointer",
                }}
                key={i}
                onClick={(e) => HandleClick(data.id, data.email)}
              >
                <div
                  className={`border rounded-lg p-2 pl-3 flex ${
                    data.id === successId
                      ? "bg-white border-primary"
                      : "bg-v3-gray border-secondary"
                  } `}
                  style={{ display: "flex" }}
                >
                  <div
                    style={{
                      fontSize: "0.87rem",
                      fontWeight: "300",
                      width: "91%",
                    }}
                  >
                    {EmailFormatter(data.email)}
                  </div>
                  {data.id === successId && (
                    <div className="ml-2 flex justify-content-end">
                      <i className="fas fa-check v3-tick-green-color h-3"></i>
                    </div>
                  )}
                </div>
                <div
                  className="mt-1 ml-1 v3-black-text-color d-flex flex-row align-items-center"
                  style={{
                    fontSize: "0.75rem",
                    fontWeight: "300",
                  }}
                >
                  <p>Account Status : </p>
                  <p
                    className={`ml-2 text-capitalize rounded-lg px-2 ${
                      data.status === "inactive"
                        ? "text-alertError bg-alertError"
                        : "text-alertSuccess bg-alertSuccess"
                    }`}
                    style={{ paddingTop: "1px", paddingBottom: "1px" }}
                  >
                    {data.status}
                  </p>
                  <div
                    className={`text-capitalize rounded-lg  px-2 ml-1 ${
                      data.status === "inactive"
                        ? "text-alertError bg-alertError"
                        : "text-alertSuccess bg-alertSuccess"
                    } `}
                  ></div>
                </div>
              </div>
            ))}
          </div>

          <button
            onClick={handleSubmit}
            className="btn btn-primary mt-3 w-100 btn-shadow"
          >
            {loading ? (
              <>
                <span className="dr-mform-spinner">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>
                </span>
              </>
            ) : (
              "Send Access Email"
            )}
          </button>
          <button
            className="btn border border-primary mt-3 w-100 btn-shadow"
            onClick={() => props.setRenderOption("noSeeEmail")}
          >
            I Don't See My Email
          </button>
        </div>
      </Col>
    </>
  );
};

export default IdentifyAccountComponent;
