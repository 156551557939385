import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Config } from "../../../Config";


function ViewConsent(props) {
  return (
    <Fragment>
      <h4>{props.t(`Informed Consent of Services Performed`)}</h4>
      <p>
        {props.t(
          `${Config.names.appName} involves the use of electronic communications to enable healthcare providers at different locations to share individual patient medical information for the purpose of improving patient care. Providers may include primary care practitioners, specialists, and/or subspecialists. The information may be used for diagnosis, therapy, follow-up and/or education, and may include any of the following`
        )}
        :
      </p>
      <ul>
        <li>{props.t(`Patient medical records`)}</li>
        <li>{props.t(`Medical images`)}</li>
        <li>{props.t(`Live two-way audio and video`)}</li>
        <li>
          {props.t(
            `Output data from medical devices and sound and video files`
          )}
        </li>
      </ul>
      <p>
        {props.t(
          `Electronic systems used will incorporate network and software security protocols to protect the confidentiality of patient identification and imaging data and will include measures to safeguard the data and to ensure its integrity against intentional or unintentional corruption.`
        )}
      </p>
      <p>
        {props.t(
          `Responsibility for the patient care should remain with the patient's local clinician, if you have one, as does the patient's medical record.`
        )}
      </p>
      <h4>{props.t(`Expected Benefits`)}:</h4>
      <ul>
        <li>
          {props.t(
            `Improved access to medical care by enabling a patient to remain in his/her local healthcare site (i.e. home) while the physician consults and obtains test results at distant/other sites.`
          )}
        </li>
        <li>{props.t(`More efficient medical evaluation and management.`)}</li>
        <li>{props.t(`Obtaining expertise of a specialist.`)}</li>
      </ul>
      <h4>{props.t(`Possible Risks`)}:</h4>
      <p>
        {props.t(
          `As with any medical procedure, there are potential risks associated with the use of telemedicine. These risks include, but may not be limited to`
        )}
        :
      </p>
      <ul>
        <li>
          {props.t(
            `In rare cases, the consultant may determine that the transmitted information is of inadequate quality, thus necessitating a face-to-face meeting with the patient, or at least a rescheduled video consult`
          )}
          ;
        </li>
        <li>
          {props.t(
            `Delays in medical evaluation and treatment could occur due to deficiencies or failures of the equipment`
          )}
          ;
        </li>
        <li>
          {props.t(
            `In very rare instances, security protocols could fail, causing a breach of privacy of personal medical information`
          )}
          ;
        </li>
        <li>
          {props.t(
            `In rare cases, a lack of access to complete medical records may result in adverse drug interactions or allergic reactions or other judgment errors`
          )}
          ;
        </li>
      </ul>
      <p>
        {props.t(
          `By using this service, you acknowledge that you understand and agree with the following`
        )}
        :
      </p>
      <ul>
        <li>
          {props.t(
            `I understand that the laws that protect privacy and the confidentiality of medical information also apply to telemedicine, and that no information obtained in the use of telemedicine, which identifies me, will be disclosed to researchers or other entities without my written consent.`
          )}
        </li>
        <li>
          {props.t(
            `I understand that I have the right to withhold or withdraw my consent to the use of telemedicine in the course of my care at any time, without affecting my right to future care or treatment.`
          )}
        </li>
        <li>
          {props.t(
            `I understand the alternatives to telemedicine consultation as they have been explained to me, and in choosing to participate in a telemedicine consultation, I understand that some parts of the exam involving physical tests may be conducted by individuals at my location, or at a testing facility, at the direction of the consulting healthcare provider.`
          )}
        </li>
        <li>
          {props.t(
            `I understand that telemedicine may involve electronic communication of my personal medical information to other medical practitioners who may be located in other areas, including out of state.`
          )}
        </li>
        <li>
          {props.t(
            `I understand that I may expect the anticipated benefits from the use of telemedicine in my care, but that no results can be guaranteed or assured.`
          )}
        </li>
        <li>
          {props.t(
            `I understand that my healthcare information may be shared with other individuals for scheduling and billing purposes. Others may also be present during the consultation other than my healthcare provider and consulting healthcare provider in order to operate the video equipment. The above mentioned people will all maintain confidentiality of the information obtained. I further understand that I will be informed of their presence in the consultation and thus will have the right to request the following: (1) omit specific details of my medical history/physical examination that are personally sensitive to me; (2) ask non-medical personnel to leave the telemedicine examination room; and/or (3) terminate the consultation at any time.`
          )}
        </li>
      </ul>
      <h4>{props.t(`Patient Consent To The Use of ${Config.names.appName}`)}</h4>
      <p>
        {props.t(
          `I have read and understand the information provided above regarding telemedicine, have discussed it with my physician or such assistants as may be designated, and all of my questions have been answered to my satisfaction.`
        )}
      </p>
      <p>
        {props.t(
          `I have read this document carefully, and understand the risks and benefits of the teleconferencing consultation and have had my questions regarding the procedure explained and I hereby give my informed consent to participate in a telemedicine visit under the terms described herein.`
        )}
      </p>
      <p className="font-weight-medium">
        {props.t(
          `By using this service I hereby state that I have read, understood, and agree to the terms of this document.`
        )}
      </p>
    </Fragment>
  );
}

export default withRouter(
  connect()(withNamespaces()(ViewConsent))
);
