import React, { useState, useEffect } from "react";

//Style from Reactstrap
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../../helpers/backend_helper";

import {
  getDoctor,
  doctorActionSuccessful,
  setDoctorListing,
  setSingleDoctor,
} from "../../../store/actions";

//Import MemberList
import DoctorList from "../../../components/Doctor/Admin/DoctorList";

//Import Create and Update Member
import CreateUpdateDoctor from "../../../components/Doctor/Admin/CreateUpdateDoctor";

import axios from "axios";
import { withNamespaces } from "react-i18next";
import { Config } from "../../../Config";

const Doctors = (props) => {
  const [postsPerPage, setPostsPerPage] = useState(
    process.env.REACT_APP_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [editMenu, seteditMenu] = useState(false);

  const [gdata, setGData] = useState({
    loading: true,
    doctors: [],
    message: null,
  });

  const [ajaxKey, setAjaxKey] = useState(false);
  let clickDetect = {
    search: false,
    reset: false,
    dropdown: false,
  };

  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    doctorSearchParam: "",
    type: "filter",
    reset: false,
  });

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        doctorSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        doctorSearchParam: "",
        type: "filter",
        reset: false,
      });
      return fetchDoctorData(searchParam, selectedPage);
    }
    fetchDoctorData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        doctorSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        doctorSearchParam: "",
        type: "filter",
        reset: true,
      });
      return fetchDoctorData(searchParam, selectedPage);
    }
    // if(selectedPage > 1)
    fetchDoctorData(searchParam, selectedPage);
  };

  const editmodeToggale = () => {
    seteditMenu(false);
  };

  const fetchDoctorData = (param, currentPage = 1) => {
    if (clickDetect.reset || clickDetect.search || clickDetect.dropdown) {
      gdata.doctors = [];
    }
    setAjaxKey(false);
    let searchParam = "";
    if (param) {
      searchParam = param.doctorSearchParam
        ? "&q=" + param.doctorSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    let url =
      process.env.REACT_APP_API_PATH +
      `/doctor?populate=userId&page=${currentPage}&limit=${postsPerPage}` +
      searchParam;
    let cancel;


    axios
      .get(url, headers, {
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
      .then(function (response) {
        if (response.status === 200) {
          setAjaxKey(true);
        }
        const data = response.data;
        let message = "";
        message = data.data.length > 0 ? "" : "No record found.";
        let structured = data.data;
        if (!param || param == "" || data.total > 30) {
          structured = [...gdata.doctors, ...data.data];
        }
        dispatch(setDoctorListing(structured, message));
        setGData({
          loading: false,
          doctors: structured,
          message: message,
        });

        setTotalItems(data.total);
        setTotalPages(Math.ceil(data.total / postsPerPage));
        setCurrentPage(currentPage - 1);
        if (document.getElementsByClassName("dr-msearch-spinner").length > 0) {
          document
            .getElementsByClassName("dr-msearch-spinner")[0]
            .classList.add("d-none");
        }
      })
      .catch(function (err) {
        if (document.getElementsByClassName("dr-msearch-spinner").length > 0) {
          document
            .getElementsByClassName("dr-msearch-spinner")[0]
            .classList.add("d-none");
        }
        var message = checkAjaxError(err);
        if (err?.response?.status === 400) {
          message = err.response.data.cause.details;
        }

        setGData({
          loading: false,
          doctors: null,
          message: message,
        });
        setTotalItems(0);
        setTotalPages(0);
        setCurrentPage(0);
        dispatch(setDoctorListing(null, message));
        if (axios.isCancel(err)) return;
      });
    return () => cancel();
    // .then(function () {});
  };

  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      searchParam = {
        doctorSearchParam: msearch,
        type: "filter",
        // key:  dropdown ? 'fulfilment': ''
      };
      setSearchFormDetails({
        doctorSearchParam: "",
        type: "filter",

        reset: true,
      });
    }
    fetchDoctorData(searchParam, currentPage + 1);
  }, [currentPage]);

  const onScrollToEnd = () => {
    setCurrentPage(currentPage + 1);
  };
  window.onscroll = function () {
    //check if the page has scrolled to the bottom
    let innerHeight =
      window.innerHeight + document.documentElement.scrollTop + 700;
    let offsetHeight = document.documentElement.offsetHeight;
    if (currentPage + 1 === totalPages || gdata.doctors?.length == 0) return;

    if (innerHeight >= offsetHeight) {
      if (ajaxKey) {
        onScrollToEnd();
      }
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        doctorSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        doctorSearchParam: "",
        type: "filter",
        reset: true,
      });
      // fetchDoctorData(searchParam);
    } else {
      //fetchDoctorData(searchParam);
    }
  }, []);

  // Apply Doctor Search
  function handleSearch(e) {
    if (
      !searchFormDetails.doctorSearchParam ||
      searchFormDetails.doctorSearchParam == ""
    ) {
      return;
    }
    e.preventDefault();
    document
      .getElementsByClassName("dr-msearch-spinner")[0]
      .classList.remove("d-none");
    gdata.doctors = [];

    fetchDoctorData(searchFormDetails);
    setSearchFormDetails({
      doctorSearchParam: searchFormDetails.doctorSearchParam,
      type: "filter",
      reset: true,
    });
    let param = searchFormDetails.doctorSearchParam
      ? searchFormDetails.doctorSearchParam
      : "";
    props.history.push("/doctors?msearch=" + param);
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    clickDetect.reset = true;
    fetchDoctorData();
    setSearchFormDetails({
      doctorSearchParam: "",
      type: "filter",
      reset: false,
    });
    document.getElementById("doctor-search-form").reset();
    props.history.push("/doctors");
  }

  const { loading, doctors, message } = gdata;
  const doctor_action = useSelector((state) => state.Doctor.action_type);

  const propsUpdateDoctorData = () => {
    if (!doctors && !props.singleDoctor) {
      return;
    }
    if (doctors && props.singleDoctor) {
      let doctorid = props.singleDoctor.id;
      //Update record
      if (doctor_action === 2) {
        var index = doctors.findIndex((x) => x.id === doctorid);
        doctors[index] = props.singleDoctor;
        dispatch(setDoctorListing(doctors, "3"));
      }

      //Insert new record
      if (doctor_action === 1) {
        doctors.unshift(props.singleDoctor);
        dispatch(setDoctorListing(doctors, "3"));
      }

      let activeRow = document.querySelectorAll(`[data-row-id="${doctorid}"]`);
      if (activeRow[0]) {
        activeRow[0].scrollIntoView({ behavior: "smooth" });
        activeRow[0].classList.add("active");
        const timer = setTimeout(() => {
          activeRow[0].classList.remove("active");
        }, 3000);
        return () => clearTimeout(timer);
      }
      setGData({
        loading: false,
        doctors: doctors,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="9">
              <Form
                className={`mb-2 ${Config.names.slugName}-search-form`}
                inline
                onSubmit={handleSearch}
                id="doctor-search-form"
              >
                <FormGroup className="mr-sm-2 mb-sm-0 mb-0">
                  <Input
                    type="text"
                    name="doctorSearchParam"
                    style={{ minWidth: "250px" }}
                    placeholder={props.t("Search...")}
                    value={searchFormDetails.doctorSearchParam}
                    onChange={(e) => {
                      let { value } = e.target;
                      setSearchFormDetails((prevState) => ({
                        ...prevState,
                        doctorSearchParam: value,
                      }));
                    }}
                  />
                </FormGroup>
                <Button onClick={handleSearch}>
                  <span className="dr-msearch-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                  Search
                </Button>
                {searchFormDetails.reset ? (
                  <span
                    className={`ml-2 ${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-reset-search`}
                    onClick={resetSearch}
                  >
                    <i className="bx bx-reset font-size-22 align-middle mr-2"></i>
                  </span>
                ) : (
                  ""
                )}
              </Form>
            </Col>
            <Col lg="3">
              <div className="text-right mb-2">
                <CreateUpdateDoctor
                  updateDoctorDataFlag={propsUpdateDoctorData}
                  doctor_action_type="create"
                  doctor_button_type="create"
                  editMenu={editMenu}
                  seteditMenu={seteditMenu}
                  {...props}
                />
                <CreateUpdateDoctor
                  updateDoctorDataFlag={propsUpdateDoctorData}
                  doctor_action_type="edit"
                  doctor_button_type="edit_icon"
                  editMenu={editMenu}
                  seteditMenu={seteditMenu}
                  {...props}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DoctorList
                    {...props}
                    doctorListMessage={message}
                    totalPages={totalPages}
                    totalItems={totalItems}
                    getDataOnEnter={getDataOnEnter}
                    // getUserData={getUserData}
                    currentPage={currentPage}
                    perPage={postsPerPage}
                    ajaxLoader={loading}
                    editmode={editmodeToggale}

                    fetchDoctorData = {fetchDoctorData}

                  ></DoctorList>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { doctor } = state.Doctor;
  const { singleDoctor } = state.Doctor;
  const { message } = state.Doctor;
  return { doctor, message, singleDoctor };
};

export default withRouter(
  connect(mapStatetoProps, {
    getDoctor,
    doctorActionSuccessful,
    setSingleDoctor,
  })(withNamespaces()(Doctors))
);
