import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";

import App from "./App";
import AppStateProvider, { useAppState } from "./state";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import LoginPage from "./components/LoginPage/LoginPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import theme from "./theme";
import "./types";
import { VideoProvider } from "./components/VideoProvider";
import useConnectionOptions from "./utils/useConnectionOptions/useConnectionOptions";
import UnsupportedBrowserWarning from "./components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";

import { processLogout } from "../../helpers/backend_helper";

const VideoApp = () => {
  const VideoAppInterface = () => {
    const { error, setError } = useAppState();
    const connectionOptions = useConnectionOptions();

    const [iaAuth, setIsAuth] = useState(false);
    const isAuthorized = () => {
      let user = localStorage.getItem("auth_user");
      if (!user) {
        processLogout();
      }
    };
    useEffect(() => {
      const v = window.location.pathname.split("/");

      if (v[v.length - 2] !== "room-token") isAuthorized();
    }, []);

    return (
      <UnsupportedBrowserWarning>
        <VideoProvider options={connectionOptions} onError={setError}>
          <ErrorDialog dismissError={() => setError(null)} error={error} />
          <App />
        </VideoProvider>
      </UnsupportedBrowserWarning>
    );
  };
  // const { error, setError } = useAppState();
  // const connectionOptions = useConnectionOptions();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppStateProvider>
          <VideoAppInterface />
        </AppStateProvider>
      </Router>
    </MuiThemeProvider>
  );
};
export default VideoApp;
