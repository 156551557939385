import React, {  } from "react";

import { Card, CardBody } from "reactstrap";
//i18n
import { withNamespaces } from "react-i18next";
// Redux
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { dctDefaultAvatar } from "../../../helpers/Commons";

const CardWelcome = (props) => {
  const { authUser } = useSelector((state) => state.Login);
  const { profilePhoto } = useSelector((state) => state.Profile);
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div>
            <div className="mb-3 mr-3">
              <img
                src={profilePhoto ? profilePhoto : dctDefaultAvatar}
                alt={authUser ? authUser.firstName : "Avatar"}
                className="img-fluid rounded-circle shadow-lg mb-3"
                style={{ height: "100px", width: "100px" }}
              />
            </div>

            <div className="mr-3">
              <h4>
                {authUser?.firstName} {authUser?.lastName}
              </h4>
              <p className="text-muted mb-1"></p>
              <p className="text-muted mb-0">{authUser?.email}</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(CardWelcome))
);
