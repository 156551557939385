import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { setSinglerxPharmacy, setPartnerListing } from "../../store/actions";

import { Row, Col, UncontrolledTooltip } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  dctGetPharmacyList,
  dctUpdateStatus,
} from "../../helpers/backend_helper";
//Include Both Helper File with needed methods

import { loadingMessage, dctHumanReadableFormat, capitalizeWords } from "../../helpers/Commons";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { Result } from "../../pages/Dashboard-doctor/components/PreJoinScreens/PreflightTest/PreflightTest";
import axios from "axios";
import { Config } from "../../Config";

const ArchiveList = (props) => {

  
  const { currentPage, perPage, total, getDataOnEnter, ajaxLoader, data } =
    props;

  let prescriptionObj = [data?.data];

  prescriptionObj = useSelector(
    (state) => state.rxPrescription.rxPrescriptionListing
  );
  prescriptionObj = prescriptionObj ? prescriptionObj : [];
  const dispatch = useDispatch();

  const [customPageNo, setCustomPageNo] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);

  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [partnerStatusMeta, setPartnerStatusMeta] = useState({
    rowIndex: "",
    groupId: "",
    groupStatus: 0,
  });

  const [prescriptionStatusMeta, setPrescriptionStatusMeta] = useState({
    rowIndex: "",
    prescriptionId: "",
    prescriptionStatus: 0,
  });

  const [pharmacyId, setpharmacyId] = useState("");

  // const getPharmacyById = async (pharmacyId) => {
  //   if (!pharmacyId) return;
  //   var pharmacyName = "no pharmacy id";
  //   let url = `pharmacy/${pharmacyId}`;
  //   const response = await dctGetPharmacyList(url, "").then((data) => data);

  //   if (response.success) {
  //     // dispatch(setSinglePharmacy(response.data, ""));
  //     pharmacyName = response.data.StoreName;
  //   }

  //   return pharmacyName;
  // };

  // const getuserpharmacydata = (id) => {
  //   var pharmacyName = "no pharmacy id";
  //   const response = getPharmacyById(id);

  //   return <p>{pharmacyName}</p>;
  // };

  useEffect(() => {
    // sleep()
  }, []);

  const updateToUnarchive = (id) => {
    // setconfirm_both(false);
    // setsuccess_dlg(true);
    // setdynamic_title("Request Sent...");
    // setdynamic_description("Please wait while its processeing!");

    let data = {
      archive: false
    }
    axios
      .patch(
        `${process.env.REACT_APP_API_PATH}/prescriptionfill/${id}`, data , {
          headers: {
            Authorization: localStorage.getItem("auth_token"),
            PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
          },
        }
      )
      .then(function (response) {
       
        if (response.status === 200) {
          // setdynamic_description("Unarchived successfully.");
          // setdynamic_title("Unarchived");
          props.dataReload("", currentPage+1)
        }
      })
      .catch(function (err) {});
  }
  const openPartnerEditModal = (data) => {
    document.querySelector("#rxpharmacy_update_modal").click();
    //Push Single Group Data to store
    props.setEditState(data);
    dispatch(setSinglerxPharmacy(data, "", ""));
  };

  const togglePharmacyStatus = async (partnerStatusMeta) => {
    if (!partnerStatusMeta) return;

    const { partnerId, partnerStatus, rowIndex } = partnerStatusMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Activation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    const loaderCls = ["bx-loader", "bx-spin", "font-size-18"];
    let toogleUserCls = partnerStatus === "active" ? "bx-check" : "bx-x";
    setStatusLoader(true);

    // Adding the bx-loader and removing the box-user-check class
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.add(...loaderCls);
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.remove(toogleUserCls);

    let gStatus = partnerStatus === "active" ? "inactive" : "active";
    const postData = {
      id: partnerId,
      status: gStatus,
    };
    const url = "rxpharmacy/" + partnerId;
    const response = await dctUpdateStatus(postData, url).then((data) => data);

    if (response.status === "active") {
      setdynamic_description("Activated successfully.");
      setdynamic_title("Activated");
    }
    if (response.status === "inactive") {
      setdynamic_description("Deactivated successfully.");
      setdynamic_title("Deactivated");
    }

    if (response.id) {
      seterror_dlg(false);
    } else {
      seterror_dlg(true);
      setsuccess_dlg(false);
    }
    prescriptionObj = data?.data;
    prescriptionObj[rowIndex].status = response?.status;

    dispatch(setPartnerListing(prescriptionObj, null));
    setStatusLoader(false);
    toogleUserCls = response.status === "active" ? "bx-check" : "bx-x";
    let toogleColorCls =
      response.status === "active" ? "text-success" : "text-danger";
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.remove(...loaderCls);
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.add(toogleUserCls);
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.add(toogleColorCls);
  };
  const prescriptionItemsBody = (prescriptionObj) => {
    return prescriptionObj && prescriptionObj.length > 0 ? (
      prescriptionObj.map((prescription, index) => {
        return (
          <Tr key={index} data-row-id={prescription.id}>
            <Td>
              <Link
                to={"/prescription-overview/id/" + prescription.id}
                className="text-dark"
              >
                {" "}
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle text-capitalize">
                    {prescription?.firstName?.charAt(0)}
                  </span>
                </div>
              </Link>
            </Td>

            <Td>
              <h5 className="text-truncate font-size-14">
                <Link
                  to={"/prescription-overview/id/" + prescription.id}
                  className="text-dark"
                >
                  {prescription?.firstName + " " + prescription?.lastName}
                </Link>
              </h5>
            </Td>
            <Td>
              <Link
                to={"/prescription-overview/id/" + prescription.id}
                className="text-dark"
              >
                <h5 className="text-truncate font-size-14">
                  {prescription?.rxpharmacyId.userId.fullName ? (
                    <span>
                      {capitalizeWords(prescription?.rxpharmacyId.userId.fullName)}
                    </span>
                  ) : (
                      ""
                    )}
                </h5>
              </Link>
            </Td>
            <Td className="text-center">
              <Link
                to={"/prescription-overview/id/" + prescription.id}
                className="text-dark"
              >
                {" "}
                <h5 className="text-truncate font-size-14">
                  {/* //{prescription.companyId.companyName === undefined ? prescription.companyId.companyName:"Nothing"} */}
                  {prescription?.state}
                </h5>
              </Link>
            </Td>
            <Td>
              <Link
                to={"/prescription-overview/id/" + prescription.id}
                className="text-dark"
              >
                {" "}
                <h5 className="text-truncate font-size-14">
                  {/* //{prescription.companyId.companyName === undefined ? prescription.companyId.companyName:"Nothing"} */}
                  {dctHumanReadableFormat(prescription?.createdAt)}
                </h5>
              </Link>
            </Td>
            <Td>
              <Link
                to={"/prescription-overview/id/" + prescription.id}
                className="text-dark"
              >
                <h5 className="text-truncate font-size-14">
                  {/* //{prescription.companyId.companyName === undefined ? prescription.companyId.companyName:"Nothing"} */}
                  {prescription.id}
                </h5>
              </Link>
            </Td>

            <Td className="text-center">
              <Link
                className="text-dark"
                to="#"
                id={"sync_" + prescription.id}
                data-status={prescription.status}
                data-id={prescription.id}
                onClick={() => {
                  setPrescriptionStatusMeta({
                    rowIndex: index,
                    prescriptionId: prescription.id,
                    prescriptionStatus: prescription.status,
                  });

                  updateToUnarchive(prescription.id);
                  // setconfirm_both(true);
                }}
              >
                <i className="bx bx-archive-out font-size-18"></i>
                <UncontrolledTooltip
                  placement="top"
                  target={"sync_" + prescription.id}
                >
                  {props.t("Unarchive")}
                </UncontrolledTooltip>
              </Link>
            </Td>

            <Td className="text-center">
              <Link
                to={"/prescription-overview/id/" + prescription.id}
                className={
                  prescription.status === "accepted"
                    ? "badge badge-soft-primary font-size-11 m-1"
                    : prescription.status === "rejected"
                      ? "badge badge-soft-danger font-size-11 m-1"
                      : prescription.status === "completed"
                        ? "badge badge-soft-primary font-size-11 m-1"
                        : "badge badge-soft-warning font-size-11 m-1"
                }
              >
                {prescription.status}
              </Link>
              {confirm_both ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="secondary"
                  onConfirm={() => {
                    updateToUnarchive(prescriptionStatusMeta.prescriptionId);
                  }}
                  onCancel={() => {
                    setconfirm_both(false);
                    setsuccess_dlg(false);
                  }}
                >
                  {props.t("This process will change the Prescription to unarchive!")}
                </SweetAlert>
              ) : null}
            </Td>
          </Tr>
        );
      })
    ) : (
        <Tr>
          <Td colSpan="7" className="text-center"></Td>
        </Tr>
      );
  };
  const handleSearchPage = (e) => {
    if (e.key === "Enter") {
      getDataOnEnter(e.currentTarget.value);
    }
  };
  return (
    <React.Fragment>
      <div className="group-list">
        <Row>
          <Col lg="12">
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {error_dlg ? (
              <SweetAlert
                error
                title={dynamic_title}
                onConfirm={() => {
                  seterror_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {ajaxLoader ? (
              <div className={`${Config.names.slugName}-listing-loader`}>
                {loadingMessage("Loading...")}
              </div>
            ) : (
                ""
              )}
            <div className="table-responsive">
              <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}>
                <Thead className="thead-light">
                  <Tr>
                    <Th
                      scope="col"
                      className="Table_dAta_first_child"
                      style={{ maxWidth: "70px" }}
                    >
                      {props.t("#")}
                    </Th>
                    <Th scope="col">{props.t("Member Name")}</Th>
                    <Th scope="col">{props.t("Pharmacy")}</Th>
                    <Th scope="col" className="text-center">{props.t("State")}</Th>
                    <Th scope="col">{props.t("Submission Date")}</Th>
                    <Th scope="col">{props.t("RxID")}</Th>
                    <Th scope="col" className="text-center">{props.t("Unarchive")}</Th>
                    <Th scope="col" className="text-center" style={{ width: "70px" }}>
                      {props.t("Status")}
                    </Th>
                    {/* <Th scope="col" style={{ width: "70px" }}>
                      <center>{props.t("Action")}</center>
                    </Th> */}
                  </Tr>
                </Thead>
                <Tbody>{prescriptionItemsBody(prescriptionObj)}</Tbody>
              </Table>
              {props.partnerListMessage ? (
                <div className="text-center text-danger">
                  <i className="bx bx-search-alt font-size-18 align-middle mr-2"></i>{" "}
                  {props.partnerListMessage}{" "}
                </div>
              ) : (
                  ""
                )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className={total > 1 ? `${Config.names.slugName}-pager` : `${Config.names.slugName}-pager d-none`}>
              <nav className="d-flex justify-content-center mt-4">
                <ReactPaginate
                  initialPage={currentPage}
                  forcePage={currentPage}
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={total}
                  itemsCountPerPage={30}
                  breakLabel={"..."}
                  label={<input />}
                  breakClassName={"break-me"}
                  onPageChange={props.getUserData}
                  containerClassName={
                    "pagination pagination-rounded align-items-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  activeLinkClassName={"active"}
                />
                <input
                  className="ml-3 text-center"
                  type="text"
                  size="5"
                  placeholder={props.t("Go to")}
                  value={customPageNo}
                  onChange={(e) => setCustomPageNo(e.currentTarget.value)}
                  onKeyPress={handleSearchPage}
                  style={{ height: "25px" }}
                />
              </nav>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(ArchiveList));
