import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Button,
  Container,
} from "reactstrap";

// Redux
import { connect, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
// import SweetAlert from "react-bootstrap-sweetalert";
import { SweetAlert } from "../../components/DoctegritySweetAlert/DoctegritySweetAlert";

import { setMySingleConsult } from "../../store/actions";
import { dctDateFormat } from "../../helpers/Commons";
import { dctGetDosespotItems } from "../../helpers/backend_helper";
import { Config } from "../../Config";

const ConsultationDetails = (props) => {
  const [modal, setmodal] = useState(false);
  const [isPDFDownload, setIsPDFDownload] = useState(false);
  const [pdfLinkForDownload, setPdfLinkForDownload] = useState(false);
  const [pdfDownloadMsg, setPdfDownloadMsg] = useState("");
  const [pdfDownloadTitle, setPdfDownloadTitle] = useState("");
  const [showPDFconfrBtn, setShowPDFconfrBtn] = useState(false);

  function toggleModalClose(e) {
    if (props.singleCompany) {
      props.updateCompanyDataFlag();
    }
  }
  const printConsultation = async (consultId) => {
    if (!consultId) return;
    setPdfLinkForDownload(false);
    setShowPDFconfrBtn(false);
    setPdfDownloadTitle("Preparing PDF");
    setPdfDownloadMsg("Your PDF is preparing for download. Please wait!");

    let url = `consultation/${consultId}/summary-pdf`;

    const response = await dctGetDosespotItems(url, "").then((data) => data);

    if (response && response.success === true) {
      setPdfLinkForDownload(response.accessUrl);
      setPdfDownloadTitle("Your PDF is ready");
      setPdfDownloadMsg("Your PDF is ready for Download.");
      setShowPDFconfrBtn(true);
    } else {
      setPdfDownloadTitle("Error!");
      setPdfDownloadMsg("Something went wrong.");
      setShowPDFconfrBtn(true);
    }
  };
  let consultData = [];
  consultData = useSelector((state) => state.Member.mySingleConsult);
  consultData = consultData ? consultData : [];

  return (
    <React.Fragment>
      <Link
        onClick={() => {
          setmodal(!modal);
        }}
        to="#"
        className="popup-form btn btn-primary d-none"
        id="consult_details_modal"
      >
        <i className="mdi mdi-eye mr-1"></i>
      </Link>

      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setmodal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-company-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal(!modal);
          }}
        >
          {props.t("Consultation Details")} -{" "}
          {consultData ? (
            <Badge
              color={consultData.status === "confirmed" ? "success" : "info"}
              className="font-size-12 ml-1 text-capitalize"
            >
              {consultData.status}
            </Badge>
          ) : (
            ""
          )}
        </ModalHeader>
        <ModalBody>
          <Container>
            {isPDFDownload ? (
              <SweetAlert
                title={props.t(`${pdfDownloadTitle}`)}
                showConfirm={false}
                showCancel={showPDFconfrBtn ? true : false}
                cancelBtnBsStyle="secondary"
                onConfirm={() => {
                  setIsPDFDownload(false);
                }}
                onCancel={() => {
                  setIsPDFDownload(false);
                }}
              >
                <p className="mb-2">{props.t(`${pdfDownloadMsg}`)}</p>
                {pdfLinkForDownload ? (
                  <p>
                    <a
                      className="btn btn-success text-left waves-light"
                      href={pdfLinkForDownload}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.t(`Download PDF`)}
                      <i className="mdi mdi-file-pdf-box font-size-18 align-middle ml-2"></i>{" "}
                    </a>
                  </p>
                ) : (
                  <div>
                    <i className="bx bx-loader bx-spin text-success align-middle font-size-20"></i>
                  </div>
                )}
              </SweetAlert>
            ) : null}

            {consultData ? (
              <div>
                <Row className="mb-3 text-right">
                  <Col lg={12}>
                    <Button
                      className=""
                      onClick={() => {
                        setIsPDFDownload(true);
                        printConsultation(consultData.id);
                      }}
                    >
                      <i className="bx bxs-printer font-size-18 align-middle mr-2"></i>
                      {props.t(`Print`)}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <p className="text-capitalize">
                      <strong>{props.t("Patient")}: </strong>{" "}
                      {consultData.patient}
                    </p>
                    <p>
                      <strong>{props.t("Type")}: </strong>{" "}
                      {consultData.consultationType}
                    </p>
                    <p>
                      <strong>{props.t("Phone")}: </strong>{" "}
                      {consultData.phone_tel}
                    </p>
                    <p>
                      <strong>{props.t("Service")}: </strong>{" "}
                      {consultData.phone_service}
                    </p>
                    <p>
                      <strong>{props.t("State")}: </strong>{" "}
                      {consultData.state_currentState}
                    </p>
                    <p>
                      <strong>{props.t("Common Symptoms")}: </strong>{" "}
                      {consultData.details_commonSymptoms}
                    </p>
                    <p>
                      <strong>{props.t("Other Symptoms")}: </strong>{" "}
                      {consultData.details_allOtherSymptoms}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <p>
                      <strong>{props.t("Complaint")}: </strong>{" "}
                      {consultData.details_chiefcomplaint}
                    </p>
                    <p>
                      <strong>{props.t("Description")}: </strong>{" "}
                      {consultData.details_description}
                    </p>
                    <p>
                      <strong>{props.t("Schedule From")}: </strong>{" "}
                      {dctDateFormat(consultData.schedule_from)}
                    </p>
                    <p>
                      <strong>{props.t("Schedule To")}: </strong>{" "}
                      {dctDateFormat(consultData.schedule_to)}
                    </p>
                    <p>
                      <strong>{props.t("Physician Language")}: </strong>{" "}
                      {consultData.schedule_physicianLanguages}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    {consultData.reasonForRejection ? (
                      <p>
                        <strong>{props.t("Reason")}: </strong>{" "}
                        {consultData.reasonForRejection}
                      </p>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <p>
                      <strong>{props.t("Created At")}: </strong>{" "}
                      {dctDateFormat(consultData.createdAt)}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <p>
                      <strong>{props.t("Updated At")}: </strong>{" "}
                      {dctDateFormat(consultData.updatedAt)}
                    </p>
                  </Col>
                </Row>
              </div>
            ) : (
              "No data found."
            )}
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { mySingleConsult } = state.Member;
  return { mySingleConsult };
};

export default withRouter(
  connect(mapStatetoProps, {
    setMySingleConsult,
  })(withNamespaces()(ConsultationDetails))
);
