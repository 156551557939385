import React, { Fragment, useEffect, useState } from "react";
// Redux
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";

import { Col, Row, Label, UncontrolledTooltip, Alert, Badge } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import Select from "react-select";

import ReactDatePicker from "react-datepicker";
import moment from "moment";

import { createDependent, updateDependent } from "../../../store/actions";
import { dctTimezones } from "../../../helpers/dctTimezones";

import { loadingMessage, getAuthIds, capitalizeWords } from "../../../helpers/Commons";
import { dctGetAsyncData } from "../../../helpers/backend_helper";
import PhoneInput from "react-phone-input-2";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { Config } from "../../../Config";

function DependentForm(props) {
  let tabIndex = props.tabIndex ? props.tabIndex - 1 : 0;
  let memberObj = props.memberObj ? props.memberObj : [];
  let member = [];
  let dependent = [];
  let ajaxLoading = true;
  const [selectedDepState, seteSelectedDepState] = useState(null);
  const [depDob, setDepDob] = useState(new Date());
  const [depAge, setDepAge] = useState(0);
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");
  const [stateName, setStateName] = useState("");
  const [countryName, setCountryName] = useState("US");
  const [timezoneData, seteTimezoneData] = useState({
    timezoneAbbr: "PST",
    timezoneUTC: "America/Los_Angeles",
  });
  const [email, setEmail] = useState("");
  const [memberId, setMemberId] = useState("");

  useEffect(() => {
    let d = memberObj?.memberAndDependents.dependent[tabIndex];
    setPrimaryPhone(d?.primaryPhone);
    setSecondaryPhone(d?.secondaryPhone);
    setStateName(d?.state);
    setCountryName(d?.country);
    seteTimezoneData({
      timezoneAbbr: d ? d.timezoneAbbr : "PST",
      timezoneUTC: d ? d.timezoneUTC : "America/Los_Angeles",
    });
    setDepDob(d?.dob);
    setEmail(d ? d.email : "");
    setMemberId(memberObj?.memberAndDependents.id);
  }, []);

  const handleTimezone = (e) => {
    seteTimezoneData((prevState) => ({
      ...prevState,
      timezoneAbbr: e.abbr ? e.abbr : "PST",
      timezoneUTC: e.utc ? e.utc[0] : "America/Los_Angeles",
    }));
  };

  // Load the fetched State data for the select dropdown
  const loadStateOptions = (inputValue, callback) => {
    var apiUrl =
      process.env.REACT_APP_API_PATH +
      "/state?populate=false&select=name,abbreviation";
    const axiosArgs = {
      whereCondition: { where: { name: { contains: `${inputValue}` } } },
      URL: apiUrl,
    };
    dctGetAsyncData(axiosArgs).then((results) => callback(results));
    return;
  };
  const stateListOptions = debounce(loadStateOptions, 250);

  const defualtStateValue = (data) => {
    if (!data) {
      return {
        name: "",
        id: "",
      };
    }
    return {
      name: data.state ? data.state : "",
      id: data.state ? data.state : "",
    };
  };

  const dctCustomValidations = (fieldID, wrapperID) => {
    if (!fieldID && !wrapperID) return;
    let inputEl = document.querySelectorAll(`input[name="${fieldID}"]`);
    if (inputEl) {
      let inputIdValue = inputEl[0]?.value;
      if (inputIdValue) {
        document.querySelector(`#${wrapperID}`).classList.remove("text-danger");
        return inputIdValue;
      } else {
        document.querySelector(`#${wrapperID}`).classList.add("text-danger");
        return;
      }
    }
  };

  const handleStateChange = (selectedDepState) => {
    seteSelectedDepState({ selectedDepState });
    if (selectedDepState === null) {
      document
        .querySelector(`#select-dep-state_${tabIndex}`)
        .classList.add("text-danger");
    } else {
      document
        .querySelector(`#select-dep-state_${tabIndex}`)
        .classList.remove("text-danger");
    }
  };

  const handleDateChange = (date) => {
    if (!date) {
      document
        .querySelector(`#date-dep-dob_${tabIndex}`)
        .classList.add("text-danger");
      return;
    }
    document
      .querySelector(`#date-dep-dob_${tabIndex}`)
      .classList.remove("text-danger");

    setDepDob(date);
    let formatDob = moment(date).format("YYYY-MM-DD");
    let dobAge = moment().diff(formatDob, "years");
    setDepAge(dobAge);
  };

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    if (document.querySelector(`#select-dep-country_${tabIndex}`))
      if (!countryName || countryName === "") {
        document
          .querySelector(`#select-dep-country_${tabIndex}`)
          .classList.add("text-danger");
        return;
      } else {
        document
          .querySelector(`#select-dep-country_${tabIndex}`)
          .classList.remove("text-danger");
        values.country = `${countryName}`;
      }

    if (document.querySelector(`#select-dep-state_${tabIndex}`))
      if (!stateName || stateName === "") {
        document
          .querySelector(`#select-dep-state_${tabIndex}`)
          .classList.add("text-danger");
        return;
      } else {
        document
          .querySelector(`#select-dep-state_${tabIndex}`)
          .classList.remove("text-danger");
        values.state = `${stateName}`;
      }

    if (document.querySelector("#primary_phone"))
      if (!primaryPhone || primaryPhone === "+") {
        document.querySelector("#primary_phone").classList.add("text-danger");
        return;
      } else {
        document
          .querySelector("#primary_phone")
          .classList.remove("text-danger");
        values.primaryPhone = `${primaryPhone}`;
      }

    values.secondaryPhone = secondaryPhone ? secondaryPhone : "";

    if (values.actionType === "edit") {
      values.email = email;
    }
    values.status = values.depStatus ? values.depStatus : "active";
    if (!depDob) {
      document
        .querySelector(`#date-dep-dob_${tabIndex}`)
        .classList.add("text-danger");
      return;
    }
    values.dob = moment(depDob).format("YYYY-MM-DD");

    values.timezoneAbbr = timezoneData.timezoneAbbr
      ? timezoneData.timezoneAbbr
      : "";
    values.timezoneUTC = timezoneData.timezoneUTC
      ? timezoneData.timezoneUTC
      : "";
    values.memberId = memberId;
    if (values.actionType === "create") {
      props.createDependent(values, "");
    } else {
      props.updateDependent(values, "");
    }
    // props.setActiveDepTab(1);
  }

  const sendRegistrationEmail = (dependent) => {
    document
      .getElementsByClassName("dr-email-spinner")[0]
      .classList.remove("d-none");
    ///Do the API call for memebr Registraion...
  };

  const handleCancelForm = (dependentData) => {
    dependentData.pop();
    props.setActiveDepTab(1);
    props.memberObj.depMessage = "";
    props.memberObj.dependentError = "";
  };
  const majorTypeFields = (dependent) => {
    return (
      <div className="majorsection">
        <Alert color="info">
          {props.t(
            "This dependent is over the age of 18. Below is the email address associated with their account."
          )}
        </Alert>
        <Row>
          <Col lg={6}>
            <div className="form-member">
              <AvField
                type="select"
                name="depStatus"
                label={props.t("Status")}
                defaultValue={dependent ? dependent.status : "active"}
                required
              >
                <option value="active">{props.t("Active")}</option>
                <option value="inactive">{props.t("Inactive")}</option>
              </AvField>
            </div>
          </Col>
          <Col lg={6}>
            <div className="form-member">
              <AvField
                type="select"
                name="relation"
                label={props.t("Relationship")}
                value={dependent ? dependent.relation : "child"}
                required
              >
                <option value="spouse">{props.t("Spouse")}</option>
                <option value="child">{props.t("Child")}</option>
                <option value="other">{props.t("Other")}</option>
              </AvField>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="form-member">
              {dependent.email ? (
                <div>
                  <h4>
                    <Badge color="secondary">{dependent.email}</Badge>
                  </h4>
                  <span
                    className="btn btn-success waves-effect waves-light d-none"
                    onClick={(e) => {
                      sendRegistrationEmail(dependent);
                    }}
                  >
                    <span className="dr-email-spinner d-none">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    </span>
                    {props.t("Send Registration Email")}
                  </span>
                </div>
              ) : (
                <AvField
                  type="email"
                  name="email"
                  label={props.t("Email")}
                  required
                ></AvField>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const minorTypeFields = (dependent) => {
    return (
      <div className="minorsection">
        {dependent.actionType && dependent.actionType === "create" ? (
          <Row>
            <Col lg={6}>
              <div className="form-member">
                <AvField
                  name="firstName"
                  label={props.t("First Name")}
                  className="form-control"
                  placeholder={props.t("First Name")}
                  type="text"
                  required
                  value={
                    dependent && dependent.id !== "" ? dependent.firstName : ""
                  }
                />
              </div>
              <div className="form-member">
                <AvField
                  name="lastName"
                  label={props.t("Last Name")}
                  className="form-control"
                  placeholder={props.t("Last Name")}
                  type="text"
                  required
                  value={
                    dependent && dependent.id !== "" ? dependent.lastName : ""
                  }
                />
              </div>

            </Col>
            <Col lg={6}>
              <div
                className={`form-member ${Config.names.slugName}-date-box`}
                id={`date-dep-dob_${tabIndex}`}
                style={{ marginBottom: "1rem" }}
              >
                <label htmlFor="dob">{props.t("Date of Birth")}</label>
                <ReactDatePicker
                  name="dob"
                  className="form-control"
                  selected={depDob}
                  onChange={handleDateChange}
                  placeholderText="YYYY-MM-DD"
                  showYearDropdown
                  dateFormat="yyyy-MM-dd"
                />
              </div>

              <div className="form-member">
                <AvField
                  type="select"
                  name="gender"
                  label={props.t("Gender")}
                  value={dependent ? dependent.gender : "unknown"}
                  required
                >
                  <option value="unknown">{props.t("Other")}</option>
                  <option value="male">{props.t("Male")}</option>
                  <option value="female">{props.t("Female")}</option>
                </AvField>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={6}>
              <div className="mb-5">
                <p className="font-weight-bold">
                  {props.t("First Name")}
                  <i
                    className="ml-1 bx bxs-help-circle"
                    id={`firstname_${tabIndex}`}
                  ></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={`firstname_${tabIndex}`}
                  >
                    {props.t(
                      `This data cannot be changed by you. You can work with customer service to get changes made by calling 888-243-4505`
                    )}
                    {""}
                  </UncontrolledTooltip>
                </p>
                <p>{dependent ? capitalizeWords(dependent.firstName) : ""}</p>
              </div>
              <div className="mb-5">
                <p className="font-weight-bold">
                  {props.t("Last Name")}
                  <i
                    className="ml-1 bx bxs-help-circle"
                    id={`lastname_${tabIndex}`}
                  ></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={`lastname_${tabIndex}`}
                  >
                    {props.t(
                      `This data cannot be changed by you. You can work with customer service to get changes made by calling 888-243-4505`
                    )}
                    {""}
                  </UncontrolledTooltip>
                </p>
                <p>{dependent ? capitalizeWords(dependent.lastName) : ""}</p>
              </div>

            </Col>
            <Col lg={6}>
              <div className="mb-5">
                <p className="font-weight-bold">
                  {props.t("Date of Birth")}
                  <i
                    className="ml-1 bx bxs-help-circle"
                    id={`dob_${tabIndex}`}
                  ></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={`dob_${tabIndex}`}
                  >
                    {props.t(
                      `This data cannot be changed by you. You can work with customer service to get changes made by calling 888-243-4505`
                    )}
                    {""}
                  </UncontrolledTooltip>
                </p>
                <p>{dependent ? dependent.dob : ""}</p>
              </div>
              <div className="form-member">
                <AvField
                  type="select"
                  name="gender"
                  label={props.t("Gender")}
                  value={dependent ? dependent.gender : "unknown"}
                  required
                >
                  <option value="unknown">{props.t("Other")}</option>
                  <option value="male">{props.t("Male")}</option>
                  <option value="female">{props.t("Female")}</option>
                </AvField>
              </div>
            </Col>
          </Row>
        )}

        <Row>
          <Col lg={6}>
            <div
              className={`${Config.names.slugName}-dependent-phone form-group ${Config.names.slugName}-phone-box`}
              id={`primary_phone`}
            >
              <label>{props.t(`Primary Phone`)}</label>
              <PhoneInput
                inputProps={{ name: "primaryPhone", required: true }}
                country={
                  !member || !member.country || member.country == ""
                    ? "us"
                    : member.country.toLowerCase()
                }
                value={dependent ? dependent.primaryPhone : member.primaryPhone}
                onChange={(primaryPhone) => {
                  setPrimaryPhone("+" + primaryPhone);
                }}
                enableSearch={true}
                onlyCountries={["us", "mx"]}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="form-group" id={`secondary_phone`}>
              <label>{props.t(`Secondary Phone`)}</label>
              <PhoneInput
                inputProps={{ name: "secondaryPhone" }}
                country={
                  !member || !member.country || member.country == ""
                    ? "us"
                    : member.country.toLowerCase()
                }
                value={
                  dependent ? dependent.secondaryPhone : member.secondaryPhone
                }
                onChange={(secondaryPhone) => {
                  setSecondaryPhone("+" + secondaryPhone);
                }}
                enableSearch={true}
                onlyCountries={["us", "mx"]}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <div className="form-member">
              <AvField
                type="select"
                name="depStatus"
                label={props.t("Status")}
                value={dependent ? dependent.status : "active"}
                required
              >
                <option value="active">{props.t("Active")}</option>
                <option value="inactive">{props.t("Inactive")}</option>
              </AvField>
            </div>
          </Col>
          <Col lg={6}>
            <div className="form-member">
              <AvField
                type="select"
                name="relation"
                label={props.t("Relationship")}
                value={dependent ? dependent.relation : "child"}
                required
              >
                <option value="spouse">{props.t("Spouse")}</option>
                <option value="child">{props.t("Child")}</option>
                <option value="other">{props.t("Other")}</option>
              </AvField>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <div className="form-member">
              <AvField
                name="address"
                label={props.t("Address")}
                className="form-control"
                placeholder={props.t("Address")}
                type="textarea"
                required
                value={dependent ? dependent.address : ""}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="form-member">
              <AvField
                name="addressTwo"
                label={props.t("Address Line 2")}
                className="form-control"
                placeholder={props.t("Address Line 2")}
                type="textarea"
                value={dependent ? dependent.addressTwo : ""}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="form-member">
              <AvField
                name="city"
                label={props.t("City")}
                className="form-control"
                placeholder={props.t("City")}
                type="text"
                required
                value={dependent ? dependent.city : ""}
              />
            </div>
          </Col>
        </Row>
        <Row className="d-flex">
          <Col md={4}>
            <div
              className={`form-group ${Config.names.slugName}-select-box`}
              id={`select-dep-country_${tabIndex}`}
            >
              <label htmlFor="state">{props.t(`Country`)}</label>
              <CountryDropdown
                defaultOptionLabel={
                  countryName !== "" ? countryName : dependent.country
                }
                labelType="short"
                valueType="short"
                whitelist={["US", "MX"]}
                classes="form-control"
                onChange={(val) => {
                  setCountryName(val);
                  setStateName("");
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div
              className={`form-group ${Config.names.slugName}-select-box`}
              id={`select-dep-state_${tabIndex}`}
            >
              <label htmlFor="state">{props.t("State")}</label>
              <RegionDropdown
                defaultOptionLabel={stateName}
                country={countryName !== "" ? countryName : dependent.country}
                countryValueType="short"
                labelType="full"
                valueType="short"
                value={stateName !== "" ? stateName : dependent.state}
                classes="form-control"
                onChange={(val) => {
                  setStateName(val);
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="form-member">
              <AvField
                name="postalCode"
                label={props.t("Postal Code")}
                className="form-control"
                placeholder={props.t("Postal Code")}
                type="text"
                required
                value={dependent ? dependent.postalCode : ""}
              />
            </div>
          </Col>
        </Row>
        <Row className="d-flex">
          <Col md={4}>
            <div className="form-member">
              <div className="form-member">
                <label htmlFor="groupId">{props.t("Select Timezone")}</label>
                <Select
                  defaultValue={{
                    value: dependent ? dependent.timezoneUTC : "",
                    abbr: dependent ? dependent.timezoneAbbr : "",
                  }}
                  placeholder="Select Timezone"
                  onChange={handleTimezone}
                  name="timezone"
                  getOptionLabel={(e) => e.value}
                  getOptionValue={(e) => e.abbr}
                  className={`${Config.names.slugName}-custom-select-box`}
                  options={dctTimezones}
                />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <Label htmlFor="validationCustom03">{props.t(`Height`)}</Label>
            <Row>
              <Col sm={6}>
                <AvField
                  name="height"
                  placeholder={props.t(`Height`)}
                  type="number"
                  className="form-control"
                  value={dependent?.height}
                ></AvField>
              </Col>
              <Col sm={6}>
                <AvField
                  name="heightMetric"
                  type="select"
                  errormessage={props.t(`Select Metric`)}
                  className={`${Config.names.slugName}-custom-select-box`}
                  value={dependent?.heightMetric ? dependent.heightMetric : 1}
                >
                  <option value="1">INCH</option>
                  <option value="2">CM</option>
                </AvField>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Label htmlFor="validationCustom03">{props.t(`weight`)}</Label>
            <Row>
              <Col sm={6}>
                <AvField
                  name="weight"
                  placeholder={props.t(`Weight`)}
                  type="number"
                  className="form-control"
                  value={dependent?.weight}
                ></AvField>
              </Col>
              <Col sm={6}>
                <AvField
                  name="weightMetric"
                  type="select"
                  errormessage={props.t(`Select Metric`)}
                  className={`${Config.names.slugName}-custom-select-box`}
                  value={dependent?.weightMetric ? dependent.weightMetric : 1}
                >
                  <option value="1">LB</option>
                  <option value="2">KG</option>
                </AvField>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {depAge >= 18 ? (
              <div className="form-member">
                <AvField
                  type="email"
                  name="email"
                  label={props.t("Email")}
                  value={dependent ? dependent.email : ""}
                  required
                ></AvField>
                <Alert color="info">
                  {props.t(
                    "This dependent is over the age of 18. Please add a valid email address for this dependent and they will be responsible for registering and accessing the system on their own."
                  )}
                </Alert>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
    );
  };

  const { loading, depMessage, memberAndDependents } = memberObj;
  member = memberAndDependents ? memberAndDependents : [];
  dependent = memberAndDependents
    ? memberAndDependents.dependent[tabIndex]
    : [];
  let dependentDob = dependent ? dependent.dob : "";
  ajaxLoading = loading;

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <div>
            {ajaxLoading ? (
              <div className={`${Config.names.slugName}-container-loader-`}>
                {loadingMessage("Loading...")}
              </div>
            ) : (
              ""
            )}{" "}
            {dependent ? (
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                {dependent.actionType && dependent.actionType === "create" ? (
                  <AvField name="actionType" type="hidden" value="create" />
                ) : (
                  <div>
                    <AvField
                      name="id"
                      type="hidden"
                      value={dependent ? dependent.id : ""}
                    />
                    <AvField name="actionType" type="hidden" value="edit" />
                    <AvField
                      name="firstName"
                      type="hidden"
                      value={dependent ? dependent.firstName : ""}
                    />
                    <AvField
                      name="lastName"
                      type="hidden"
                      value={dependent ? dependent.lastName : ""}
                    />
                  </div>
                )}

                <AvField
                  name="memberId"
                  type="hidden"
                  value={getAuthIds.member}
                />
                <AvField name="country" type="hidden" value="US" />

                {dependent.age >= 18
                  ? majorTypeFields(dependent)
                  : minorTypeFields(dependent)}

                <div className="mt-4">
                  <Row>
                    <Col lg={12}>
                      <div className="modal-footer">
                        {memberAndDependents.dependent[props.activeIndex]
                          .actionType === "create" ? (
                          <button
                            className="btn btn-outline-primary waves-effect waves-light"
                            type="button"
                            onClick={() =>
                              handleCancelForm(memberAndDependents.dependent)
                            }
                          >
                            {props.t("Cancel")}
                          </button>
                        ) : (
                          ""
                        )}
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          type="submit"
                          onClick={(e) => { }}
                        >
                          {ajaxLoading ? (
                            <span className="dr-mform-spinner">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                            </span>
                          ) : (
                            ""
                          )}
                          {props.t("Save")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </AvForm>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { memberAndDependents } = state.Member;
  return { memberAndDependents };
};

export default withRouter(
  connect(mapStatetoProps, {
    createDependent,
    updateDependent,
  })(withNamespaces()(DependentForm))
);
