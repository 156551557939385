import { SET_SINGLE_ERROR_CONSULTATION } from "./actionTypes";

const initialState = {}

const failedConsultReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SINGLE_ERROR_CONSULTATION: {
            state = action.payload
            return state
            break;
        }
        default: return state
    }
}

export default failedConsultReducer;
