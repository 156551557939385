import React, { useRef, useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Alert,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { connect, useSelector, useDispatch } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from "moment";

import {
  postMessageActionSuccessful,
  postCommentActionSuccessful,
  createPostCommentFailed,
  setSinglePostMessage,
  getSinglePostMessage,
  updatePostComment,
  createPostComment,
} from "../../../store/actions";

import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { loadingMessage, dctDateFormat } from "../../../helpers/Commons";
import { Config } from "../../../Config";

const PostMessageOverview = (props) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { authUserId, authDoctorId } = useSelector((state) => state.Login);

  const [singleComment, setSingleComment] = useState({
    id: null,
    body: null,
    ownerId: null,
    postId: null,
    action: "create",
  });

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let post_id = "";
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      post_id = split_url ? split_url[split_url.length - 1] : "";
    }
    props.getSinglePostMessage(post_id);
  }, []);

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-form-spinner")[0]
      .classList.remove("d-none");

    //call reducer method
    if (values.actionType === "edit") {
      props.updatePostComment(values, props.history);
      setSingleComment({
        ...singleComment,
        id: 0,
        action: "create",
      });
    } else {
      props.createPostComment(values, props.history);
    }
  }

  const editPostComment = (comment) => {
    if (!comment) return;
    inputRef.current.scrollIntoView({ behavior: "smooth" });
    setSingleComment({
      id: comment.id,
      body: comment.body,
      ownerId: comment.ownerId,
      postId: comment.postId,
      action: "edit",
    });
  };

  const renderComments = (comments, message) => {
    return comments && comments.length >= 0 ? (
      comments.map((comment, indx) => {
        let index = indx + 1;
        return (
          <li
            key={index}
            className={comment.userType === "member" ? "right" : "left"}
          >
            <div className="conversation-list">
              <div className="ctext-wrap">
                <div className="conversation-name">
                  {comment.userType === "member" ? "Patient" : "Doctor"}
                </div>
                <p>{comment.body}</p>
                <p className="chat-time mb-0">
                  <i className="bx bx-time-five align-middle mr-1"></i>{" "}
                  {moment(comment.updatedAt).fromNow()}
                </p>
              </div>
            </div>
          </li>
        );
      })
    ) : (
      <li className="text-center">{loadingMessage(message ? message : "")}</li>
    );
  };

  const pmData = useSelector((state) => state.PostMessage.singlePostMessage);
  const postMessageData = useSelector((state) => state.PostMessage);
  const message = postMessageData ? postMessageData.message : "";
  const pcData = postMessageData.postComment
    ? postMessageData.postComment.data
    : "";

  const backToPostMessageListLink = () => {
    const handleBack = () => {
      props.history.goBack();
    };
    return (
      <button
        onClick={handleBack}
        className="btn btn-info waves-effect waves-light mb-2 mr-2"
      >
        <i className="mdi mdi-arrow-left mr-1"></i> {props.t("Back to List")}
      </button>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                {pmData ? (
                  <CardBody>
                    <div className="details-section" data-id={pmData.id}>
                      <Row>
                        <Col lg="12">
                          <div className="text-right">
                            {backToPostMessageListLink()}
                          </div>
                        </Col>
                      </Row>

                      <div className="text-muted mt-4">
                        <Row>
                          <Col lg="12">
                            <h5>
                              <span>
                                <strong>{props.t("Question?")} </strong>{" "}
                              </span>
                            </h5>
                            <p>{pmData.question}</p>
                            <h5>
                              <span>
                                <strong>{props.t("Details")} </strong>{" "}
                              </span>
                            </h5>
                            <p>{pmData.details}</p>

                            {pmData.docUrl ? (
                              <div>
                                <h5>
                                  <span>
                                    <strong>{props.t("Document")} </strong>{" "}
                                  </span>
                                </h5>
                                <p
                                  style={{ width: "100%", overflow: "hidden" }}
                                >
                                  <img
                                    src={pmData.docUrl}
                                    style={{ maxWidth: "100%" }}
                                  />
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </div>

                      <Row className="task-dates">
                        <Col sm="6" xs="6">
                          <div className="mt-4">
                            <h5 className="font-size-14">
                              <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                              {props.t("Created Date")}
                            </h5>
                            <p className="text-muted mb-0">
                              {dctDateFormat(pmData.createdAt)}
                            </p>
                          </div>
                        </Col>

                        <Col sm="6" xs="6">
                          <div className="mt-4">
                            <h5 className="font-size-14">
                              <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                              {props.t("Updated Date")}
                            </h5>
                            <p className="text-muted mb-0">
                              {dctDateFormat(pmData.updatedAt)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                ) : (
                  <CardBody>
                    {pmData ? (
                      <div className="text-center">
                        {backToPostMessageListLink()}
                      </div>
                    ) : (
                      <div className="text-center">
                        {loadingMessage("Loading Message...")}
                      </div>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {pmData ? (
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <div id="comment_form" ref={inputRef}></div>
                      <AvField
                        name="ownerId"
                        type="hidden"
                        value={authUserId}
                      />
                      <AvField name="postId" type="hidden" value={pmData.id} />
                      {singleComment && singleComment.id ? (
                        <AvField
                          name="id"
                          type="hidden"
                          value={singleComment.id}
                        />
                      ) : (
                        ""
                      )}
                      <AvField
                        name="actionType"
                        type="hidden"
                        value={singleComment.action}
                      />

                      {pmData.status !== "accepted" ? (
                        <Alert color="danger">
                          {props.t(
                            "This Message is closed, so you can't comment on it."
                          )}
                        </Alert>
                      ) : (
                        ""
                      )}

                      <AvField
                        name="body"
                        label={props.t("Comment")}
                        className={
                          singleComment.action === "edit"
                            ? "form-control ${Config.names.slugName}-focus"
                            : "form-control"
                        }
                        placeholder={props.t("Write a comment...")}
                        type="textarea"
                        id="comment_body"
                        disabled={pmData.status !== "accepted" ? true : false}
                        value={singleComment?.body}
                        required
                      />

                      <div className="text-right">
                        <button
                          className="chat-send w-md waves-effect waves-light btn btn-primary"
                          type="submit"
                          disabled={pmData.status !== "accepted" ? true : false}
                        >
                          <span className="dr-form-spinner d-none">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                          </span>{" "}
                          {props.t("Send")}
                          <i className="ml-2 mdi mdi-send"></i>
                        </button>
                      </div>
                    </AvForm>
                  ) : (
                    <div className="text-center">
                      {loadingMessage("Loading comment from...")}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {pmData ? (
                    <div
                      className={`${Config.names.slugName}-comment-section chat-conversation`}
                    >
                      {pcData === "" ? (
                        <div className="text-center">
                          {loadingMessage("Loading comments...")}
                        </div>
                      ) : pcData.length === 0 ? (
                        <div className="text-center">
                          <span className="text-danger">
                            User has no comments from Doctor
                          </span>
                        </div>
                      ) : (
                        <ul className="list-unstyled">
                          {renderComments(pcData, message)}
                        </ul>
                      )}
                    </div>
                  ) : (
                    <div className="text-center">
                      {loadingMessage("Loading comments...")}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { singlePostMessage } = state.PostMessage;
  return { singlePostMessage };
};

export default withRouter(
  connect(mapStatetoProps, {
    createPostComment,
    updatePostComment,
    postMessageActionSuccessful,
    postCommentActionSuccessful,
    createPostCommentFailed,
    setSinglePostMessage,
    getSinglePostMessage,
  })(withNamespaces()(PostMessageOverview))
);
