import React from "react";

import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Container, Row, Col, Button, Input, Label, Card } from "reactstrap";

//Import Email Sidebar
import EmailSideBar from "./EmailSidebar";

//Import Email Topbar
import EmailToolbar from "./EmailToolbar";
import DctHeroBanner from "../../../components/Common/DctHeroBanner";

const EmailInbox = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <DctHeroBanner
                mainTitle="Inbox"
                subTitle="Message Center"
                isInfoBar={true}
                infoBarTitle=""
                isCall911={true}
              ></DctHeroBanner>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="mt-0">
              {/* Render Email SideBar */}
              <EmailSideBar />
              <div className="email-rightbar mb-3">
                <Card>
                  {/* Render Email Top Tool Bar */}
                  <EmailToolbar />

                  <ul className="message-list">
                    <li>
                      <div className="col-mail col-mail-1">
                        <div className="checkbox-wrapper-mail">
                          <Input type="checkbox" id="chk19" />
                          <Label htmlFor="chk19" className="toggle"></Label>
                        </div>
                        <Link to="#" className="title">
                          {props.t("Doctegrity")}
                        </Link>
                        <span className="star-toggle far fa-star"></span>
                      </div>
                      <div className="col-mail col-mail-2">
                        <Link to="#" className="subject">
                          <span className="badge-info badge mr-2">
                            {props.t("Welcome to Your Health!")}
                          </span>
                          <span className="teaser">
                            {props.t(
                              "Welcome to Your Portal! Please take a few minutes and familiarize yourself with our tools and..."
                            )}
                          </span>
                        </Link>
                        <div className="date">Dec 14, 2008</div>
                      </div>
                    </li>

                    <li className="unread">
                      <div className="col-mail col-mail-1">
                        <div className="checkbox-wrapper-mail">
                          <Input type="checkbox" id="chk15" />
                          <Label htmlFor="chk15" className="toggle"></Label>
                        </div>
                        <Link to="#" className="title">
                          {props.t("Doctegrity")}
                        </Link>
                        <span className="star-toggle far fa-star"></span>
                      </div>
                      <div className="col-mail col-mail-2">
                        <Link to="#" className="subject">
                          <span className="badge-info badge mr-2">
                            {props.t("Daily checkup update!")}
                          </span>
                          <span className="teaser">
                            {props.t(
                              "Your routine checkup for last friday....."
                            )}
                          </span>
                        </Link>
                        <div className="date">Feb 21</div>
                      </div>
                    </li>
                  </ul>
                </Card>
                <Row>
                  <Col xs="7" className="d-none">
                    {props.t(" Showing 1 - 20 of 1,524")}
                  </Col>
                  <Col xs="5">
                    <div className="btn-group float-right">
                      <Button
                        type="button"
                        color="success"
                        size="sm"
                        className="waves-effect"
                      >
                        <i className="fa fa-chevron-left"></i>
                      </Button>
                      <Button
                        type="button"
                        color="success"
                        size="sm"
                        className="waves-effect"
                      >
                        <i className="fa fa-chevron-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(withNamespaces("translation")(EmailInbox));
