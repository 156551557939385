import React, { useState } from "react";
import {
    Row,
    Col,
    Collapse,
    Button,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    Alert,
    Container,
    Progress,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import Dropzone from "react-dropzone";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

import axios from "axios";

import { setSingleCompany } from "../../store/actions";
import { Config } from "../../Config";

const UploadModal = (props) => {
    const [toggleUploader, setToggleUploader] = useState(true);
    const [uploadAgain, setUploadAgain] = useState(false);
    const [selectedFiles, setselectedFiles] = useState([]);
    const [uploadPercent, setuploadPercent] = useState({ percent: 0 });
    const [uploadData, setuploadData] = useState({
        laoding: true,
        data: [],
        message: null,
        status: null,
    });
    const dispatch = useDispatch();

    function handleAcceptedFiles(files) {
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setUploadAgain(false);
        setselectedFiles(files);
    }

    const uploadMemberCSV = (files) => {
        if (!files) return;

        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: {
                // "Content-Type" : "multipart/form-data",
                "Content-Type": "application/json",
                Authorization: bearer_token,
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
            onUploadProgress: (progressEvent) => {
                var percent = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                if (percent >= 100) {
                    setuploadPercent({ percent: 100 });
                } else {
                    setuploadPercent({ percent });
                }
            },
        };

        let bodyFormData = new FormData();
        // bodyFormData.append("companyId", companyId);
        bodyFormData.append("csvfile", files[0]);
        let url = process.env.REACT_APP_API_PATH + "/member/multi-import";
        axios
            .post(url, bodyFormData, headers)
            .then(function (response) {
                const data = response.data;
                console.log(data)
                setuploadData({
                    loading: false,
                    data: data.result,
                    message: data.result.message,
                    status: data.result.success,
                });
                setUploadAgain(true);
                setToggleUploader(false);
                if (data.success === true && data.result) {
                    //

                } else {
                    setuploadPercent({ percent: 0 });
                }
            })
            .catch(function (err) {
                // console.log("eeee", err.response.data.result)
                setuploadPercent({ percent: 0 });
                setUploadAgain(true);

                setuploadData({
                    loading: false,
                    data: err.response.data.result.message,
                    message: err.response.data.result.message,
                    status: false,
                });
                checkAjaxError(err);
            })
            .then(function () { });
    };

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    /* Reset all the state of CSV Modal */
    const resetCSVModalStates = (e) => {
        props.setModal(false);
        setselectedFiles([]);
        setuploadPercent({ percent: 0 });
        setToggleUploader(true);
        setUploadAgain(false);
        setuploadData({
            laoding: true,
            data: [],
            message: null,
            status: null,
        });
        // dispatch(setSingleCompany(null, "", ""));
    };

    function resetUpload() {
        setselectedFiles([]);
        setUploadAgain(false);
        setToggleUploader(true);
        setuploadPercent({ percent: 0 });
        setuploadData({
            laoding: true,
            data: [],
            message: null,
            status: null,
        });
    }

    function toggleModalClose(e) {

        resetCSVModalStates();
        //checking Store props
        // if(props.company) {
        //     props.updateCompanyDataFlag();
        // }
    }

    const { percent } = uploadPercent;


    return (
        <React.Fragment>

            <Modal
                centered={true}
                size="lg"
                isOpen={props.modal}
                toggle={() => {
                    props.setModal(!props.modal);
                }}
                scrollable={true}
                className={`${Config.names.slugName}-company-add-edit-modal`}
                onClosed={(e) => {
                    toggleModalClose(e)

                }}
            >
                <ModalHeader
                    toggle={() => {
                        props.setModal(!props.modal);
                    }}
                >
                    {props.t("Upload CSV")}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Collapse isOpen={uploadAgain}>
                            {uploadData.status === true ? (
                                <Alert color="success">
                                    <h5 style={{ marginBottom: '2rem' }}>
                                        <strong>CSV Upload in progress. A mail will be sent after complete upload.</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={() => {
                                            resetUpload();
                                        }}
                                    >
                                        <i className="bx bx-cloud-upload font-size-18 align-middle mr-2"></i>
                                        {props.t("Upload More CSV")}
                                    </button>
                                </Alert>
                            ) : uploadData.message ? (
                                <Alert color="danger">
                                    <p className="text-center">
                                        <strong>{uploadData.message}</strong>
                                    </p>
                                    {/* <p>{uploadData.data}</p> */}
                                    <p className="text-center">
                                        <Button
                                            className=""
                                            onClick={() => {
                                                resetUpload();
                                            }}
                                        >
                                            <i className="bx bx-cloud-upload font-size-18 align-middle mr-2"></i>
                                            {props.t("Try Again")}
                                        </Button>
                                    </p>
                                </Alert>
                            ) : (
                                ""
                            )}
                        </Collapse>

                        <Collapse isOpen={toggleUploader}>
                            <div className="container-inner">

                                <Form>
                                    <Dropzone
                                        accept=".csv"
                                        // acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                                        onDrop={(acceptedFiles) => {
                                            handleAcceptedFiles(acceptedFiles);
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                                <div
                                                    className="dz-message needsclick mt-2"
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    <div className="mb-3">
                                                        <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                    </div>
                                                    <h4>
                                                        {props.t("Drop files here or click to upload.")}
                                                    </h4>
                                                    <p className="font-size-14 text-danger">
                                                        {props.t("Only *.csv files will be accepted.")}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <div className="mt-3">
                                        <Progress color="primary" value={percent}>
                                            {percent}%
                                        </Progress>
                                    </div>
                                    <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                            return (
                                                <div
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={i + "-file"}
                                                >
                                                    <div className="p-2">
                                                        <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                                {f.type === "text/csv" ? (
                                                                    <h2>
                                                                        <i
                                                                            className="bx bx bx-file
                                                    mr-1"
                                                                        ></i>
                                                                    </h2>
                                                                ) : (
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        height="80"
                                                                        className="avatar-sm rounded bg-light"
                                                                        alt={f.name}
                                                                        src={f.preview}
                                                                    />
                                                                )}
                                                            </Col>
                                                            <Col>
                                                                <Link
                                                                    to="#"
                                                                    className="text-muted font-weight-bold"
                                                                >
                                                                    {f.name}
                                                                </Link>
                                                                <p className="mb-0">
                                                                    <strong>{f.formattedSize}</strong>
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Form>

                                <div className="text-center mt-4">
                                    {selectedFiles[0] ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={() => {
                                                uploadMemberCSV(selectedFiles);
                                            }}
                                        >
                                            <i className="bx bx-cloud-upload mr-1"></i>
                                            {props.t("Upload")}
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-secondary waves-effect waves-light disabled"
                                            disabled
                                        >
                                            <i className="bx bx-cloud-upload mr-1"></i>
                                            {props.t("Upload")}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </Collapse>
                    </Container>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { company } = state.Company;
    return { company };
};

export default withRouter(
    connect(mapStatetoProps, {})(withNamespaces("translation")(UploadModal))
);
