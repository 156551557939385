import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link, useParams, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Progress,
  Container,
} from "reactstrap";
import { SweetAlert } from "../../components/DoctegritySweetAlert/DoctegritySweetAlert";

import classnames from "classnames";

import Patient from "./Consultation/Patient";
import EHR from "./Consultation/EHR";
import Phone from "./Consultation/Phone";
import State from "./Consultation/State";
import Details from "./Consultation/Details";
import Schedule from "./Consultation/Schedule";
import Pharmacy from "../../components/Pharmacy/SearchPharmacy";
import Billing from "./Consultation/Billing";
import Finish from "./Consultation/Finish";
import DctConsultationBanner from "../../components/Common/DctConsultationBanner";

import {
  dctAlert,
  dctDateFormat,
  dctConsultationSteps,
  dctGetMemberAndDependPastData,
  consultTabs,
} from "../../helpers/Commons";

import {
  getMemberAndDependents,
  getSingleConsultation,
  getDraftConsultation,
  setDraftConsultation,
  setSingleConsultation,
  updateConsultation,
} from "../../store/actions";

// import { HorizontalTimeline } from 'wix-style-react';

import {Config} from './../../Config';

const Consultations = (props) => {
  const [activeTabProgress, setactiveTabProgress] = useState(1);
  const [isReasonActive, setIsReasonActive] = useState(false);
  const [isDraftCancelledReason, setIsDraftCancelledReason] = useState(false);
  const [draftCancelledReasonText, setDraftCancelledReasonText] = useState("");
  const [consultType, setConsultType] = useState("phone");
  const [pageTitle, setPageTitle] = useState("Who is the consultation for?");
  const [pageTitle1, setPageTitle1] = useState(props.t("Who is the consultation for?"));
  const [isDoseSpotPharmacy, setIsDoseSpotPharmacy] = useState(true);

  const dispatch = useDispatch();

  let memberObj = [];
  const { authMemberId } = useSelector((state) => state.Login);
  memberObj = useSelector((state) => state.Member);
  const {
    loading,
    memberAndDependents,
    consultMsg,
    consultMsgType,
    singleConsult,
    draftConsult,
  } = memberObj;

  const pastMaDData = () => {
    let consData = draftConsult ? draftConsult : singleConsult;
    let data = dctGetMemberAndDependPastData(memberAndDependents, consData);
    return data;
  };

  const checkValidStep = () => {
    let consultData = singleConsult ? singleConsult : draftConsult;
    if (consultData) {
      let consStep = dctConsultationSteps(consultData);
      toggleTabProgress(consStep, consultData.consultationType);
    } else {
      toggleTabProgress(1, consultType);
    }
  };

  useEffect(() => {
    props.getDraftConsultation(authMemberId);
    props.getMemberAndDependents(authMemberId);

    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      let consultStep = split_url ? split_url[5] : 1;
      let consultTypeFromURL = split_url ? split_url[3] : "phone";
      setConsultType(consultTypeFromURL);
      consultStep = parseInt(consultStep);
      toggleTabProgress(consultStep, consultTypeFromURL);
    }

    //check service for valid user
  }, []);

  function hideFistSetItems() {
    let tabHideCls = ["tabhide-on-mobile"];
    let hideTabItems = [".tab-item-1", ".tab-item-2", ".tab-item-3"];
    for (var i in hideTabItems) {
      if (document.querySelector(hideTabItems[i])) {
        document.querySelector(hideTabItems[i]).classList.add(...tabHideCls);
      }
    }
  }
  function showHideTabitems(type, items) {
    let actionType = type ? type : "hide";
    let tabHideCls = ["tabhide-on-mobile"];
    let tabItems = items ? items : ["1", "2", "3"];
    for (var i in tabItems) {
      if (document.querySelector(`.tab-item-${tabItems[i]}`)) {
        if (actionType === "hide") {
          document
            .querySelector(`.tab-item-${tabItems[i]}`)
            .classList.add(...tabHideCls);
        } else {
          document
            .querySelector(`.tab-item-${tabItems[i]}`)
            .classList.remove(...tabHideCls);
        }
      }
    }
  }
  function toggleTabProgress(tab, urlType) {
    if (window.ReactNativeWebView) {
      setTimeout(() => {
        window.ReactNativeWebView.postMessage("SCROLL_TO_TOP");
      },75)
    }
      
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 9) {
        setactiveTabProgress(tab);
        setConsultType(urlType);
        props.history.push(`/member/consultation/${urlType}/step/${tab}`);

        if (!singleConsult && !draftConsult) {
          props.history.push(`/member/consultation/${urlType}/step/1`);
          tab = 1;
        }
        switch (tab) {
          case 1:
            setPageTitle1("Who is this consultation for?");
            showHideTabitems("show", ["1", "2", "3"]);
            if (window.ReactNativeWebView)
              window.ReactNativeWebView.postMessage(
                "HEADER_MSG:Patient Details"
              );
            break;

          case 2:
            setPageTitle1(
              "A Diagnostic Consultation requires valid and up-to-date Electronic Health Records."
            );
            showHideTabitems("show", ["1", "2", "3"]);
            if (window.ReactNativeWebView)
              window.ReactNativeWebView.postMessage(
                `HEADER_MSG:Electronic Health Records verification for \n ${singleConsult?.patient}`
              );
            break;

          case 3:
            setPageTitle1(
              `By Appointment Medical Questionnaire for ${singleConsult?.patient}`
            );
            showHideTabitems("show", ["1", "2", "3"]);
            if (window.ReactNativeWebView)
              window.ReactNativeWebView.postMessage(
                `HEADER_MSG:Calling Details for ${singleConsult?.patient}`
              );
            break;

          case 4:
            setPageTitle1("State of Residence");
            showHideTabitems("hide", ["1", "2", "3"]);
            showHideTabitems("show", ["4", "5", "6"]);
            if (window.ReactNativeWebView)
              window.ReactNativeWebView.postMessage(
                `HEADER_MSG:State Details for ${singleConsult?.patient}`
              );
            break;

          case 5:
            setPageTitle1(
              "Now we need to tell the Doctor why you are scheduling a consultation"
            );
            showHideTabitems("hide", ["1", "2", "3"]);
            showHideTabitems("show", ["4", "5", "6"]);
            if (window.ReactNativeWebView)
              window.ReactNativeWebView.postMessage(
                `HEADER_MSG:Symptoms for ${singleConsult?.patient}`
              );
            break;

          case 6:
            setPageTitle1(
              "Please select a convenient time for this Diagnostic Phone Medical Consultation:"
            );
            showHideTabitems("hide", ["1", "2", "3"]);
            showHideTabitems("show", ["4", "5", "6"]);
            if (window.ReactNativeWebView)
              window.ReactNativeWebView.postMessage(
                `HEADER_MSG:Consultation time details for ${singleConsult?.patient}`
              );
            break;

          case 7:
            setPageTitle1("Verify Pharmacy Details");
            showHideTabitems("hide", ["1", "2", "3", "4", "5", "6"]);
            let _consultData = [];
            _consultData = draftConsult ? draftConsult : singleConsult;
            setIsDoseSpotPharmacy(true);
            if (!_consultData || _consultData.state_country !== "US") {
              setIsDoseSpotPharmacy(false);
            }
            if (window.ReactNativeWebView)
              window.ReactNativeWebView.postMessage(
                `HEADER_MSG:Pharmacy details for ${singleConsult?.patient}`
              );
            break;
          case 8:
            setPageTitle1("Your Request has been shared with us");
            showHideTabitems("hide", ["1", "2", "3", "4", "5", "6"]);
            if (window.ReactNativeWebView)
              window.ReactNativeWebView.postMessage(
                `HEADER_MSG:Consultations for ${singleConsult?.patient}`
              );
            break;
          default:
            break;
        }
      }
    }
  }

  // const preparePageTitle = (title) => {
  // 	setPageTitle(title);
  // }
  const retReactNative = () => {
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage("BACK_TO_HOME");
  };

  // Cancelled the drafted consultation
  const cancelledConsultation = (data) => {
    setIsReasonActive(false);
    setIsDraftCancelledReason(false);

    let cancellationData = {
      id: data.id,
      message: draftCancelledReasonText,
      _actionType: "cancelled",
    };
    props.updateConsultation(cancellationData);
    dispatch(setDraftConsultation(null, ""));
    retReactNative();
  };

  // Proceed consultation for next step
  const proceedConsultation = (data) => {
    let consStep = dctConsultationSteps(data);
    dispatch(setSingleConsultation(data, ""));
    setConsultType(data.consultationType);
    dispatch(setDraftConsultation(null, ""));
    toggleTabProgress(consStep, data.consultationType);
  };

  const stopNativeLoader = () => {
    window.ReactNativeWebView.postMessage("STOP_NATIVE_LOADER");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {window.ReactNativeWebView ? (
            stopNativeLoader()
          ) : (
            <div className={`${Config.names.slugName}-row-sm-0`}>
              <Row className="justify-content-between">
                <Col xl="12">
                  <DctConsultationBanner
                    mainTitle={props.t("Patient Details")}
                    subTitle={`Diagnostic Consultation by ${consultType}`}
                    isInfoBar={false}
                    isMainBanner={false}
                  ></DctConsultationBanner>
                </Col>
              </Row>
            </div>
          )}

          <div className={`${Config.names.slugName}-row-sm-0`}>
            <Row className={draftConsult ? "d-none" : "mt-0"}>
              <Col lg="12">
                <Card className={`${Config.names.slugName}-bg-none`}>
                  <div className={`${Config.names.slugName}-float-alert`}>
                    {window.ReactNativeWebView
                      ? ""
                      : consultMsg && consultMsgType !== 1
                      ? dctAlert(consultMsgType, consultMsg)
                      : ""}
                  </div>
                  <div
                    id="progrss-wizard"
                    className={`twitter-bs-wizard ${Config.names.slugName}-consult-wizard`}
                  >
                    <CardHeader
                      className={`${Config.names.slugName}-consultation-nav-section bg-soft-primary`}
                      style={{ position: "relative" }}
                    >
                      {window.ReactNativeWebView ? (
                        ""
                      ) : (
                        <img
                          src={Config.images.patientFamilyBanner}
                          alt="Mother and Child 1"
                          className={`img-fluid ${Config.names.slugName}-dashboard-profile-img ${Config.names.slugName}-consult-profile-img ${Config.names.slugName}-desktop`}
                        />
                      )}
                      <div className={`${Config.names.slugName}-consult-page-title font-size-16 mt-3 mb-3`}>
                        {props.t(pageTitle1)}
                      </div>
                      <div className={`${Config.names.slugName}-navscroller`}>
                        <ul
                          id="dctWizardNav"
                          className={`twitter-bs-wizard-nav nav-justified nav ${Config.names.slugName}-wizard-nav`}
                        >
                          {consultTabs.map((item, index) => (
                            <li
                              key={index}
                              className={`tab-item tab-item-${index + 1}`}
                            >
                              <NavLink
                                disabled={item.disabled}
                                className={classnames({
                                  active: activeTabProgress === index + 1,
                                })}
                              >
                                <span className={`${Config.names.slugName}-dot`}></span>
                                <span>{props.t(item.label)}</span>
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className={`${Config.names.slugName}-consult-progress-bar`}></div>
                    </CardHeader>

                    <CardBody>
                      <div className={`${Config.names.slugName}-sm--15`}>
                        <TabContent
                          activeTab={activeTabProgress}
                          className="twitter-bs-wizard-tab-content mt-5"
                        >
                          <TabPane tabId={1}>
                            <Patient
                              {...props}
                              activeTab={activeTabProgress}
                              refData={pastMaDData}
                              refCheckValidStep={checkValidStep}
                              pageTitle={setPageTitle}
                            />
                          </TabPane>
                          <TabPane tabId={2}>
                            <EHR
                              refData={pastMaDData}
                              refCheckValidStep={checkValidStep}
                              pageTitle={setPageTitle}
                            />
                          </TabPane>
                          <TabPane tabId={3}>
                            <Phone
                              {...props}
                              refData={pastMaDData}
                              refAllData={memberObj}
                              refCheckValidStep={checkValidStep}
                              pageTitle={setPageTitle}
                            />
                          </TabPane>
                          <TabPane tabId={4}>
                            <State
                              refData={pastMaDData}
                              refCheckValidStep={checkValidStep}
                              pageTitle={setPageTitle}
                            />
                          </TabPane>
                          <TabPane tabId={5}>
                            <Details
                              refData={pastMaDData}
                              refCheckValidStep={checkValidStep}
                              pageTitle={setPageTitle}
                            />
                          </TabPane>
                          <TabPane tabId={6}>
                            <Schedule
                              refData={pastMaDData}
                              refCheckValidStep={checkValidStep}
                              pageTitle={setPageTitle}
                            />
                          </TabPane>
                          <TabPane tabId={7}>
                            <div className="pl-2 pr-2">
                              <Pharmacy
                                refData={pastMaDData}
                                _isDoseSpotPharmacy={isDoseSpotPharmacy}
                                _pharmacyComponentType="consultation"
                                pageTitle={setPageTitle}
                              />
                            </div>
                          </TabPane>
                          {/* <TabPane tabId={8}>
														{singleConsult || draftConsult ? (
															<Billing
																refData={pastMaDData}
																refCheckValidStep={checkValidStep}
																pageTitle={setPageTitle}
															/>
														) : (
															""
														)}
													</TabPane> */}
                          <TabPane tabId={8}>
                            {/* {singleConsult || draftConsult ? ( */}
                            <div className="pl-2 pr-2">
                              <Finish
                                refData={pastMaDData}
                                refCheckValidStep={checkValidStep}
                                pageTitle={setPageTitle}
                              />
                            </div>
                            {/* ) : (
															""
														)} */}
                          </TabPane>
                        </TabContent>
                        <ul className="pager wizard twitter-bs-wizard-pager-link d-none">
                          <li
                            className={
                              activeTabProgress === 1
                                ? "previous disabled"
                                : "previous"
                            }
                          >
                            <Link
                              id="consultPrevTab"
                              to="#"
                              onClick={() => {
                                toggleTabProgress(
                                  activeTabProgress - 1,
                                  consultType
                                );
                              }}
                            >
                              {props.t("Previous")}
                            </Link>
                          </li>
                          <li
                            className={
                              activeTabProgress === 8 ? "next disabled" : "next"
                            }
                          >
                            <Link
                              id="consultNextTab"
                              to="#"
                              onClick={() => {
                                toggleTabProgress(
                                  activeTabProgress + 1,
                                  consultType
                                );
                              }}
                            >
                              {props.t("Next")}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          {draftConsult ? (
            <SweetAlert
              title={props.t(`You have one Draft consultation`)}
              warning
              showCancel
              closeOnClickOutside={false}
              allowEscape={false}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              confirmBtnText={props.t("Yes")}
              cancelBtnText={props.t("No")}
              onConfirm={() => {
                proceedConsultation(draftConsult);
                setIsDraftCancelledReason(false);

                // Canclled method call if Reason textbox is active
                if (isReasonActive) {
                  cancelledConsultation(draftConsult);
                }
              }}
              onCancel={() => {
                setIsDraftCancelledReason(true);
                setIsReasonActive(true);
                // Canclled method call if Reason textbox is active
                if (isReasonActive) {
                  cancelledConsultation(draftConsult);
                }
              }}
            >
              {isDraftCancelledReason ? (
                <div>
                  <h5>{props.t(`Please enter the cancellation Reason.`)}</h5>
                  <textarea
                    type="textarea"
                    name="cancelReason"
                    className="form-control"
                    onChange={(e) => {
                      setDraftCancelledReasonText(e.target.value);
                    }}
                  />
                </div>
              ) : (
                <div>
                  <p>{`You started a diagnostic ${
                    draftConsult.consultationType
                  } consultation for ${draftConsult.patient} on ${dctDateFormat(
                    draftConsult.createdAt
                  )}`}</p>
                  <h5>
                    {props.t(`Would you like to continue this consultation?`)}
                  </h5>
                </div>
              )}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {
    memberAndDependents,
    consultMsg,
    consultMsgType,
    draftConsult,
  } = state.Member;
  return { memberAndDependents, consultMsg, consultMsgType, draftConsult };
};

export default withRouter(
  connect(mapStatetoProps, {
    getMemberAndDependents,
    getSingleConsultation,
    getDraftConsultation,
    updateConsultation,
  })(withNamespaces()(Consultations))
);
