import React, { Component } from 'react';

import { OTPublisher } from 'opentok-react'
import RadioButtons from './RadioButtons';
import CheckBox from './CheckBox';
import { Config } from '../../../../Config';

export default class Publisher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      audio: true,
      video: true,
      videoSource: 'camera'
    };
  }

  setAudio = (audio) => {
    this.setState({ audio });
  }

  setVideo = (video) => {
    this.setState({ video });
  }

  setVideoSource = (videoSource) => {
    this.setState({ videoSource });
  }

  onError = (err) => {
    this.setState({ error: `Failed to publish: ${err.message}` });
  }

  render() {
    return (
      <div className="tok-box-publisher">
        {this.state.error ? <div>{this.state.error}</div> : null}
        <OTPublisher
          properties={{
            publishAudio: this.state.audio,
            publishVideo: this.state.video,
            videoSource: this.state.videoSource === 'screen' ? 'screen' : undefined
          }}
          onError={this.onError}
        />
        {/* <RadioButtons
          buttons={[
            {
              label: 'Camera',
              value: 'camera'
            },
            {
              label: 'Screen',
              value: 'screen'
            }
          ]}
          initialChecked={this.state.videoSource}
          onChange={this.setVideoSource}
        /> */}

        

        <div className={`${Config.names.slugName}-bottom-controls`}>
          <CheckBox
            label="Publish Audio"
            initialChecked={this.state.audio}
            onChange={this.setAudio}
          />
          <CheckBox
            label="Publish Video"
            initialChecked={this.state.video}
            onChange={this.setVideo}
          />
          {this.state.videoSource === "camera" ? 
          <button className={`btn btn-primary ${Config.names.slugName}-btn`} onClick={()=>{this.setVideoSource("screen")}}>
            <i className="mdi mdi-monitor-eye"></i>
          </button>
          :
          <button className={`btn btn-danger ${Config.names.slugName}-btn`} onClick={()=>{this.setVideoSource("camera")}}>
            <i className="mdi mdi-monitor-off"></i>
          </button>
        }
        </div>
        
      </div>
    );
  }
}
