import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { CREATE_BRAND, UPDATE_BRAND, GET_BRAND_LIST } from "./actionTypes";
import {
  brandActionSuccessful,
  setSingleBrand,
  createBrandFailed,
  setBrandList,
} from "./actions";

//Include Both Helper File with needed methods
import {
  dctPostRawData,
  dctPutRawData,
  // getBrandListObj,
  dctGetRawData,
} from "../../helpers/backend_helper";
import { Config } from "../../Config";

// Create Brand
function* createBrandSaga({ payload: { brand, history } }) {
  document.getElementsByTagName("button")[
    document.getElementsByTagName("button").length - 1
  ].disabled = true;

  try {
    const brand_data = brand;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPostRawData,
      process.env.REACT_APP_API_PATH + "/brand",
      brand_data,
      headers
    );

    document
      .getElementsByClassName("dr-cform-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName("button")[
      document.getElementsByTagName("button").length - 1
    ].disabled = false;

    if (response.id) {
      yield put(
        brandActionSuccessful(response, "Brand Created Successfully #1.", 1)
      );
      yield put(setSingleBrand(response, "Brand Created Successfully #2.", 1));
      if (document.querySelector(`.${Config.names.slugName}-brand-add-edit-modal .close`)) {
        document.querySelector(`.${Config.names.slugName}-brand-add-edit-modal .close`).click();
      }
    } else {
      yield put(createBrandFailed(response.message));
    }
  } catch (error) {
    yield put(createBrandFailed(error));
  }
}

// Update Brand
function* updateBrandSaga({ payload: { brand, history } }) {
  document.getElementsByTagName("button")[
    document.getElementsByTagName("button").length - 1
  ].disabled = true;
  try {
    const brand_data = brand;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPutRawData,
      process.env.REACT_APP_API_PATH + "/brand/" + brand_data.id,
      brand_data,
      headers
    );

    document
      .getElementsByClassName("dr-cform-spinner")[0]
      .classList.add("d-none");

    document.getElementsByTagName("button")[
      document.getElementsByTagName("button").length - 1
    ].disabled = false;

    if (response.id) {
      yield put(
        brandActionSuccessful(response, "brand Updated Successfully. #1", 2)
      );
      yield put(setSingleBrand(response, "Brand Updated Successfully #2.", 2));

      if (document.querySelector(`.${Config.names.slugName}-brand-add-edit-modal .close`)) {
        document.querySelector(`${Config.names.slugName}-brand-add-edit-modal .close`).click();
      }
    } else {
      yield put(createBrandFailed(response.message));
    }
  } catch (error) {
    yield put(createBrandFailed(error));
  }
}

// Get Brand
function* getBrandListSaga({ payload: { brand } }) {
  try {
    // const Brand_data = Brand;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctGetRawData,
      process.env.REACT_APP_API_PATH + "/brand",
      headers
    );

    if (response.id) {
      yield put(
        setBrandList(
          response.result.data,
          "Brand data fetched Successfully.",
          ""
        )
      );
    } else {
      yield put(createBrandFailed(response.message));
    }
  } catch (error) {
    yield put(createBrandFailed(error));
  }
}

export function* watchCreateBrand() {
  yield takeEvery(CREATE_BRAND, createBrandSaga);
}

export function* watchUpdateBrand() {
  yield takeEvery(UPDATE_BRAND, updateBrandSaga);
}
export function* watchGetBrand() {
  yield takeEvery(GET_BRAND_LIST, getBrandListSaga);
}

function* brandSaga() {
  yield all([
    fork(watchCreateBrand),
    fork(watchUpdateBrand),
    fork(watchGetBrand),
  ]);
}

export default brandSaga;
