import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import ResetPassword from "./auth/resetpwd/reducer";
import Profile from "./auth/profile/reducer";

import Member from "./member/reducer";
import Doctor from "./doctor/reducer";
import Group from "./group/reducer";
import Brand from "./brand/reducer";
import Service from "./service/reducer";
import PostMessage from "./postMessage/reducer";
import Company from "./company/reducer";
import Partner from "./partner/reducer";
import rxPharmacy from "./rxpharmacy/reducer";
import rxPrescription from "./rxprescription/reducer";
import PartnerAuth from "./partnerauth/reducer";
import Pharmacy from "./pharmacy/reducer";
import HealthRecords from "./healthRecords/reducer";
import FailedConsultation from "./failedConsultations/reducer";

// Activate benefit
import ActivateBenefit from "./auth/activateBenefit/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ResetPassword,
  Profile,
  Member,
  Group,
  Service,
  PostMessage,
  Company,
  Doctor,
  Partner,
  rxPharmacy,
  rxPrescription,
  PartnerAuth,
  Pharmacy,
  HealthRecords,
  Brand,
  ActivateBenefit,
  FailedConsultation
});

export default rootReducer;
