import { CREATE_RXPHARMACY, UPDATE_RXPHARMACY, GET_RXPHARMACY, GET_RXPHARMACY_LIST, SET_RXPHARMACY_LIST, CREATE_RXPHARMACY_SUCCESS, CREATE_RXPHARMACY_FAILED, API_ERROR, SET_SINGLE_RXPHARMACY, SET_RXPHARMACY_LISTING } from './actionTypes';

const initialState = {
    createrxPharmacyError: null, 
    message: null, 
    loading: false,
    action_type: null,
}

const rxpharmacy = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_RXPHARMACY:
            state = {
                ...state,
                rxpharmacy: action.payload,
                loading: true,
                createrxPharmacyError: null
            }
            break;
        case UPDATE_RXPHARMACY:
            state = {
                ...state,
                rxpharmacy: action.payload,
                loading: true,
                createrxPharmacyError: null
            }
            break;
        case GET_RXPHARMACY:
            state = {
                ...state,
                rxpharmacy: action.payload.result,
                loading: true,
                createrxPharmacyError: null
            }
            break;
        case GET_RXPHARMACY_LIST:
            state = {
                ...state,
                rxpharmacy: action.payload.result,
                loading: true,
                createrxPharmacyError: null
            }
            break;
        case SET_SINGLE_RXPHARMACY:
            state = {
                ...state,
                rxpharmacy: null,
                singlerxPharmacy: action.payload,
                loading: false,
                createrxPharmacyError: null,
                action_type: action.action_type,
            }
            break;
        case SET_RXPHARMACY_LIST:
            state = {
                ...state,
                loading: false,
                rxpharmacy: action.payload,
                message: action.message,
                createrxPharmacyError: null,
                action_type: action.action_type,
            }
            break;
        case SET_RXPHARMACY_LISTING:
            state = {
                ...state,
                loading: false,
                rxpharmacy: null,
                rxPharmacyListing: action.payload,
                message: action.message,
                createrxPharmacyError: null,
            }
            break;    
        case CREATE_RXPHARMACY_SUCCESS:
            state = {
                ...state,
                loading: false,
                rxpharmacy: action.payload,
                message: action.message,
                createrxPharmacyError: null,
                action_type: action.action_type,
            }
            break;
        case CREATE_RXPHARMACY_FAILED:
            state = {
                ...state,
                rxpharmacy: null,
                createrxPharmacyError: action.payload,
                loading: false,
            }
            break;
        case API_ERROR:
            state = { 
                ...state, 
                error: action.payload, 
                loading: false 
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default rxpharmacy;


