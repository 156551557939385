import React, { useState, useEffect } from "react";

//Style from Reactstrap
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

import {
  setConsultationListing,
  setMySingleConsult,
} from "../../store/actions";

import ConsultationList from "../../components/MyConsultations/ConsultationList";

import ConsultationDetails from "../../components/MyConsultations/ConsultationDetails";

import axios from "axios";
import DctHeroBanner from "../../components/Common/DctHeroBanner";
import { Config } from "../../Config";
import Select from "react-select";
import moment from "moment";

const MyConsultations = (props) => {
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [upcomingCount, setUpcomingCount] = useState(0);

  //Dropdown for filter

  const [dropdownValue, setDropdownValue] = useState({
    label:
      localStorage.getItem("Status") !== null
        ? localStorage.getItem("Status")
        : "All",
    value:
      localStorage.getItem("status_value") !== null
        ? localStorage.getItem("status_value")
        : "all",
  });
  let toggleValue = [
    {
      options: [
        { label: "Pending", value: "confirmed" },
        { label: "Completed", value: "completed" },
        { label: "No Answer", value: "noanswer" },
        { label: "Upcoming", value: "upcoming" },
        { label: "Flagged", value: "flagged" },
        { label: "All", value: "" },
      ],
    },
  ];
  const colorStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#abb7f0" : isSelected ? "#b0bdf6" : "",
        color: "black",
        ":hover": {
          color: isFocused ? "white" : undefined,
        },
      };
    },
  };

  // End
  const statusTextColors = {
    pending: "#f19337",
    upcoming: "#5593aa",
    completed: "#39a557",
    flagged: "#878787",
    confirmed: "#f19337",
    noanswer: "#788cec",
  };
  const statusSpanishTextColors = {
    pendiente: "#f19337",
    próxima: "#5593aa",
    completo: "#39a557",
    enlosado: "#878787",
    confirmado: "#f19337",
    norespuesta: "#788cec",
  };

  const [gdata, setGData] = useState({
    loading: true,
    consultations: null,
    message: null,
    totalCount: null,
  });
  const currentStatus = [
    {
      id: 1,
      label: "Pending",
      status: "confirmed",
      total: gdata?.totalCount?.confirmed ? gdata?.totalCount?.confirmed : 0,
    },
    {
      id: 2,
      label: "Upcoming",
      status: props.t("upcoming"),
      total: gdata?.totalCount?.upcoming ? gdata?.totalCount?.upcoming : 0,
    },
    {
      id: 3,
      label: "Flagged",
      status: props.t("flagged"),
      total: gdata?.totalCount?.flagged ? gdata?.totalCount?.flagged : 0,
    },
    {
      id: 4,
      label: "Completed",
      status: props.t("completed"),
      total: gdata?.totalCount?.completed ? gdata?.totalCount?.completed : 0,
    },
    {
      id: 5,
      label: "Noanswer",
      status: props.t("noanswer"),
      total: gdata?.totalCount?.noanswer ? gdata?.totalCount?.noanswer : 0,
    },
    {
      id: 6,
      label: "All",
      status: props.t("Show All"),
      total: gdata?.totalCount?.all ? gdata?.totalCount?.all : 0,
    },
  ];
  const { authMemberId } = useSelector((state) => state.Login);
  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    consultSearchParam: "",
    type: "filter",
    reset: false,
  });

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        consultSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: false,
      });
      return fetchConultationData(
        searchParam,
        dropdownValue?.value,
        selectedPage
      );
    }
    fetchConultationData(searchParam, dropdownValue?.value, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        consultSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      return fetchConultationData(
        searchParam,
        dropdownValue?.value,
        selectedPage
      );
    }
    fetchConultationData(searchParam, dropdownValue?.value, selectedPage);
  };

  const fetchConultationData = (param, status, currentPage = 1) => {
    let consultationStatus = status === "all" ? "" : status;
    let searchParam = "";
    if (param) {
      searchParam = param.consultSearchParam
        ? "&q=" + param.consultSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    let url = `${
      process.env.REACT_APP_API_PATH
    }/get-all-consultation?sort=createdAt%20DESC&page=${currentPage}&limit=${postsPerPage}&timezoneUTC=${moment.tz.guess()}&search=${
      param?.consultSearchParam ? param?.consultSearchParam : ""
    }${consultationStatus ? "&status=" + consultationStatus : ""}`;
    if (authMemberId !== "undefined") {
      url = `${
        process.env.REACT_APP_API_PATH
      }/get-all-consultation?sort=createdAt%20DESC&page=${currentPage}&limit=${postsPerPage}&timezoneUTC=${moment.tz.guess()}&status=${
        param?.consultSearchParam ? param?.consultSearchParam : ""
      }`;
    }
    axios
      .get(url, headers)
      .then(function (response) {
        let data = response.data;
        let message = "";
        message = data.data.length > 0 ? "" : "No record found.";
        // Condition for upcoming consultation
        if (consultationStatus === "upcoming" || !consultationStatus) {
          data.data = data?.data.filter((value, i) => {
            const scheduleFrom = moment(value?.schedule_from, moment.ISO_8601);
            const currentDate = moment();
            // const isUpcoming = param?.consultSearchParam === "upcoming"|| !param?.consultSearchParam? scheduleFrom.isAfter(currentDate):!scheduleFrom.isAfter(currentDate);
            const isUpcoming = scheduleFrom.isAfter(currentDate);
            if (isUpcoming) {
              return (value.status = "upcoming");
            }
            if (!consultationStatus) {
              return value;
            }
          });
        }
        dispatch(setConsultationListing(data.data, message));
        setGData({
          loading: false,
          consultations: data.data,
          message: message,
          totalCount: data?.totalCount,
        });
        setTotalPosts(Math.ceil(data.total / postsPerPage));
        setCurrentPage(currentPage - 1);
        if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
          document
            .getElementsByClassName("dr-csearch-spinner")[0]
            .classList.add("d-none");
        }
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err?.response) {
          if (err?.response?.status === 400) {
            message = err?.response?.data?.cause?.details;
          }
        }
        if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
          document
            .getElementsByClassName("dr-csearch-spinner")[0]
            .classList.add("d-none");
        }
        setGData({
          loading: false,
          consultations: null,
          message: message,
        });
        setTotalPosts(0);
        setCurrentPage(0);
        dispatch(setConsultationListing(null, message));
      })
      .then(function () {});
  };

  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let csearch = urlParams.get("csearch");
    let searchParam = "";
    if (csearch) {
      searchParam = {
        consultSearchParam: csearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      fetchConultationData(searchParam, dropdownValue?.value, currentPage);
    } else {
      // fetchConultationData('', dropdownValue?.value, 1);
    }
  }, []);

  // Apply Company Search
  function handleSearch(e) {
    e.preventDefault();
    // if (document.getElementsByClassName("dr-csearch-spinner")) {
    //   document
    //     .getElementsByClassName("dr-csearch-spinner")[0]
    //     .classList.add("d-none");
    // }
    fetchConultationData(searchFormDetails, dropdownValue?.value);
    setSearchFormDetails({
      reset: true,
    });
    let param = searchFormDetails.consultSearchParam
      ? searchFormDetails.consultSearchParam
      : "";
    props.history.push(`/member/my-consultations/?&csearch=${param}`);
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    fetchConultationData();
    setSearchFormDetails({
      reset: false,
    });
    document.getElementById("consult-search-form").reset();
    props.history.push(`/member/my-consultations`);
  }

  const { loading, consultations, message } = gdata;

  const stopNativeLoader = () => {
    window.ReactNativeWebView.postMessage("STOP_NATIVE_LOADER");
  };

  return (
    <React.Fragment>
      {window.ReactNativeWebView ? stopNativeLoader() : ""}
      <div className="page-content">
        <Container fluid>
          {/* {window.ReactNativeWebView ? (
            ""
          ) : (
            <>
              <Row>
                <Col xl={12}>
                  <DctHeroBanner
                    mainTitle="My Consultations"
                    subTitle={props.t("Manage Your Consultations")}
                    isInfoBar={true}
                    infoBarTitle=""
                    isCall911={true}
                  ></DctHeroBanner>
                </Col>
              </Row>
            </>
          )} */}

          <Row className="mb-2">
            <Col lg="3" md="6">
              <Form
                inline
                onSubmit={handleSearch}
                id="consult-search-form"
                className={`mb-2 ${Config.names.slugName}-search-form`}
              >
                <InputGroup className="mr-sm-2 mb-sm-0 mb-0">
                  {/* <span className="my__consultations__wrapper"> */}
                  <Input
                    type="text"
                    name="consultSearchParam"
                    placeholder={props.t("Search...")}
                    style={{ width: "" }}
                    onChange={(e) => {
                      let { value } = e.target;
                      setSearchFormDetails((prevState) => ({
                        ...prevState,
                        consultSearchParam: value,
                      }));
                    }}
                  />
                  <i
                    onClick={handleSearch}
                    className="bx bx-search font-size-18 my__consultations__search"
                  ></i>
                  {searchFormDetails.reset ? (
                    <i
                      onClick={resetSearch}
                      className="dripicons-cross font-size-18 my__consultations__reset"
                    ></i>
                  ) : (
                    ""
                  )}
                  {/* <i className="dripicons-cross font-size-18 my__consultations__hidden__reset"></i> */}
                  {/* </span> */}
                </InputGroup>
                {/* <Button onClick={handleSearch}>
                  <span className="dr-csearch-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                  Search
                </Button> */}
                {/* {searchFormDetails.reset ? (
                  <span
                    className="ml-2 ${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-reset-search"
                    onClick={resetSearch}
                  >
                    <i className="bx bx-reset font-size-22 align-middle mr-2"></i>
                  </span>
                ) : (
                  ""
                )} */}
              </Form>
            </Col>
            <Col lg="7" md="6">
              <div className="row md:flex-column align-items-center ml-auto">
                {/* status button */}
                <div className="row ml-auto ">
                  {currentStatus?.map((el, key) => (
                    <div
                      key={key}
                      style={{
                        color: `${
                          props?.lng ==='en' || el.status in statusTextColors
                            ? statusTextColors[el.status]
                            : props?.lng ==='sp' || el.status in statusSpanishTextColors? statusSpanishTextColors[el.status]:""
                        }`,
                        borderRadius: "5px",
                        backgroundColor: "#FCFCFC",
                        padding: "10px",
                        paddingInline: "20px",
                        width: "145px",
                        marginBottom: "5px",
                      }}
                      className="mr-2 shadow-sm"
                      // onClick={() => {
                      //   console.log(el.status);
                      //   fetchConultationData({
                      //     label: el.status == "Show All" ? "" : el.status,
                      //     consultSearchParam:
                      //       el.status == "Show All" ? "" : el.status,
                      //   });
                      //   setSearchFormDetails({
                      //     reset: false,
                      //   });
                      //   let param = el.status == "Show All" ? "" : el.status;
                      //   // localStorage.setItem("Status", el.status);
                      //   localStorage.setItem("status_value", el.status);
                      //   localStorage.setItem("Status", el.label);
                      //   param == ""
                      //     ? props.history.push(`/member/my-consultations`)
                      //     : props.history.push(
                      //         `/member/my-consultations/?&csearch=${param}`
                      //       );
                      // }}
                    >
                      <span className="text-capitalize mr-2">
                        {el.status == "confirmed"
                          ? props.t("Pending")
                          : el.status == "noanswer"
                          ? props.t("No Answer")
                          : el.status}
                      </span>
                      <span>{el.total}</span>
                    </div>
                  ))}
                </div>
                <div className="text-right mb-2">
                  <ConsultationDetails {...props} />
                </div>
              </div>
            </Col>
            {/* dropdown menu */}
            <Col lg="2" md="12">
              <FormGroup className="ml-auto mr-2">
                <Select
                  styles={colorStyles}
                  value={dropdownValue}
                  onChange={(e) => {
                    setDropdownValue(e);
                    fetchConultationData(
                      searchFormDetails?.consultSearchParam,
                      e?.value
                    );
                    setSearchFormDetails({
                      reset: false,
                    });
                    // let param1 = e.value? e.value:localStorage.getItem("status_value")
                    // let param = e.value ? e.value : "";
                    localStorage.setItem("Status", e.label);
                    localStorage.setItem("status_value", e.value);
                    // param == ""
                    //   ? props.history.push(`/member/my-consultations`)
                    //   : props.history.push(
                    //       `/member/my-consultations/?&csearch=${param}`
                    //     );
                  }}
                  options={toggleValue}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Col>
          </Row>

          {/* consultation list */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className={`${Config.names.slugName}-row-sm-0`}>
                  <ConsultationList
                    {...props}
                    consultListMessage={message}
                    total={totalPosts}
                    getDataOnEnter={getDataOnEnter}
                    getUserData={getUserData}
                    currentPage={currentPage}
                    perPage={postsPerPage}
                    ajaxLoader={loading}
                    fetchConultationData={fetchConultationData}
                    postsPerPage={postsPerPage}
                  ></ConsultationList>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { consultListing, mySingleConsult, message } = state.Member;
  return { consultListing, message, mySingleConsult };
};

export default withRouter(
  connect(mapStatetoProps, { setConsultationListing, setMySingleConsult })(
    withNamespaces()(MyConsultations)
  )
);
