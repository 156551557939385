import React from 'react'
import Dashboard from '../Dashboard/index'
import DashboardDoctor from '../Dashboard-doctor/index'
import DashboardMember from '../Dashboard-member/index'
import DashboardPartner from '../Dashboard-partner/index'
import DashboardPharmacy from '../Dashboard-pharmacy/index'
import { useSelector } from "react-redux"

const DashboardInterface = () => {

  let { authUserType } = useSelector((state) => state.Login);

  if (!authUserType) {
    authUserType = JSON.parse(localStorage.getItem('auth_user')).userType;

  }

  switch (authUserType) {
    case "admin": return (<Dashboard />);
    case "doctor": return (<DashboardDoctor />);
    case "member": return (<DashboardMember />);
    case "partner": return (<DashboardPartner />);
    case "pharmacy": return (<DashboardPharmacy />);
    default: return "";
  }
}

export default DashboardInterface
