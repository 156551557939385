import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, CHANGE_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

//Include Both Helper File with needed methods
import { postForgetPwd, dctPostRawData } from "../../../helpers/backend_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
  try {
    const response = yield call(
      postForgetPwd,
      process.env.REACT_APP_API_PATH + "/auth/forgot-password",
      { email: user.email }
    );
    
    document
      .getElementsByClassName("dr-login-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    if (response.success === true) {
      yield put(
        userForgetPasswordSuccess(
          `We sent an email to ${user.email} with a link to get back into your account.`
        )
      );
      // history.push('/dashboard');
    } else {
      yield put(userForgetPasswordError(response.message));
    }
  } catch (error) {
    yield put(userForgetPasswordError("User with this email does not exist."));
    document
      .getElementsByClassName("dr-login-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
  }
}

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* changePassword({ payload: { user, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true;
  try {
    let user_data = user;
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    user_data.userId = authUser?.id;
    const headers = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPostRawData,
      process.env.REACT_APP_API_PATH + "/auth/change-password",
      user_data,
      headers
    );
    document.getElementsByClassName("dr-spinner")[0].classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    if (response.id) {
      yield put(
        userForgetPasswordSuccess(
          "Your password has been updated successfully."
        )
      );
    } else {
      yield put(userForgetPasswordError(response.cause.message));
    }
  } catch (error) {
    document.getElementsByClassName("dr-spinner")[0].classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export function* watchUserChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
  yield all([fork(watchUserChangePassword)]);
}

export default forgetPasswordSaga;
