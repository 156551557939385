import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import moment from "moment";

import {
  setSinglePostMessage,
  setPostMessageListing,
} from "../../store/actions";

import {
  Row,
  Col,
  UncontrolledTooltip,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//Include Both Helper File with needed methods
import { processLogout } from "../../helpers/backend_helper";
import { loadingMessage } from "../../helpers/Commons";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import axios from "axios";
import { Config } from "../../Config";

const PostMessageList = (props) => {
  const singlePostRef = React.createRef();

  const { authUserType } = useSelector((state) => state.Login);

  const { currentPage, perPage, total, getDataOnEnter, ajaxLoader } = props;

  let postMessagesObj = [];

  postMessagesObj = useSelector(
    (state) => state.PostMessage.postMessageListing
  );
  postMessagesObj = postMessagesObj ? postMessagesObj : [];
  const dispatch = useDispatch();

  const [customPageNo, setCustomPageNo] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);

  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [postMessageStatusMeta, setPostMessageStatusMeta] = useState({
    rowIndex: "",
    postMessageId: "",
    postMessageStatus: 0,
  });
  const [selectedRowIndex, setSelectedRowIndex] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(0);

  useEffect(() => {
    let post_id = "";
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      post_id = split_url && split_url[4] ? split_url[4] : "";
    }
    if (post_id) {
      setSelectedPostId(post_id);
    }
  }, [props.location]);

  const openPostMessageEditModal = (data) => {
    if (!data) return;
    props.history.push(`/member/ask-doctor/edit/id/${data.id}`);
    //Push Single PostMessage Data to store
    dispatch(setSinglePostMessage(data, "", ""));
  };

  const togglePostMessageStatus = async (postMessageStatusMeta) => {
    if (!postMessageStatusMeta) return;
    const {
      postMessageId,
      postMessageStatus,
      rowIndex,
    } = postMessageStatusMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Activation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    setStatusLoader(true);

    let gStatus = postMessageStatus === "active" ? "inactive" : "active";
    const postData = {
      id: postMessageId,
      status: gStatus,
    };
    const url = `${process.env.REACT_APP_API_PATH}/post/${postMessageId}/toggle-status`;
    let res;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    // let postData = data ? data : "";
    await axios
      .get(url, headers)
      .then(function (response) {
        const data = response.data;
        res = response.data;
      })
      .catch((err) => {
        res = "";
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            processLogout();
            return;
          }

          let description = err.response.data
            ? err.response.data.message
            : "Something went wrong.";
          setdynamic_description(description);
          setdynamic_title("Error");

          return;
        }
        return err.response;
      });

    if (!res.data) {
      return;
    }

    if (res.data[0].status === "accepted") {
      setdynamic_description("Accepted successfully.");
      setdynamic_title("Accepted");
    }
    if (res.data[0].status === "active") {
      setdynamic_description("Activated successfully.");
      setdynamic_title("Activated");
    }
    if (res.data[0].status === "inactive") {
      setdynamic_description("Deactivated successfully.");
      setdynamic_title("Deactivated");
    }

    if (res.data[0].id) {
      seterror_dlg(false);
    } else {
      seterror_dlg(true);
      setsuccess_dlg(false);
    }

    postMessagesObj[rowIndex].status = res.data[0].status;
    // dispatch(setPostMessageListing(null));
    dispatch(setPostMessageListing(postMessagesObj, null));
    setStatusLoader(false);
    // let toogleUserCls =
    //   res.data[0].status !== "inactive"
    //     ? "mdi-message-text"
    //     : "mdi-message-bulleted-off";
    // document
    //   .querySelector("#status_" + postMessageId)
    //   .children[0].classList.add(toogleUserCls);

    let toggleStatusCls =
      res.data[0].status !== "inactive" ? "text-succedd" : "text-danger";
    document
      .querySelector("#status_" + postMessageId)
      .children[0].classList.add(toggleStatusCls);
  };

  const handleSearchPage = (e) => {
    if (e.key === "Enter") {
      getDataOnEnter(e.currentTarget.value);
    }
  };

  const UserChatOpen = (data) => {
    dispatch(setSinglePostMessage(data, "", ""));
    let dynamicMsgUrl = "/member/ask-doctor/id/" + data.id;
    if (authUserType === "doctor") {
      dynamicMsgUrl = "/doctor/messages/id/" + data.id;
    }
    props.history.push(dynamicMsgUrl);
    // alert(dynamicMsgUrl);
    // window.history.pushState({}, {}, dynamicMsgUrl);
    // props.isCommentData(data);
    // props.openPostListPanel(false);
    // props.openCommentWindow(true);
  };

  const urlParamChatOpen = (data) => {
    if (!data) return;
    setTimeout(() => {
      if (selectedPostId == data.id) {
        props.isCommentData(data);
        props.openPostListPanel(false);
        props.openCommentWindow(true);

        return;
      }
    }, 1000);
  };

  const getPostStatusInfo = (status, type) => {
    let statusCls = "";
    switch (status) {
      case "accepted":
        statusCls = "text-success";
        break;
      case "active":
        statusCls = "text-info";
        break;
      case "inactive":
        statusCls = "text-warning";
        break;
      case "archive":
        statusCls = "text-danger";
        break;
      default:
        statusCls = "";
        break;
    }
    return type === "class" ? statusCls : "";
  };

  const postMessageItemsList = (postMessagesObj) => {
    return postMessagesObj.length > 0 ? (
      postMessagesObj.map((postMessage, index) => {
        urlParamChatOpen(postMessage);

        return (
          <li
            key={postMessage.id + postMessage.status}
            className={
              selectedRowIndex === index || postMessage.id === selectedPostId
                ? "active position-relative"
                : "position-relative"
            }
            ref={singlePostRef}
            data-index={selectedPostId}
          >
            {window.ReactNativeWebView ? null:
            <ul className={`list-inline font-size-20 contact-links mb-0 position-absolute ${Config.names.slugName}-chat-float-dots`}>
              <li className="list-inline-item px-2 ">
                <UncontrolledDropdown direction="left">
                  <DropdownToggle href="#" className="btn nav-btn" tag="i">
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </DropdownToggle>
                  <DropdownMenu direction="left">
                    {authUserType === "member" ? (
                      <DropdownItem
                        href="#"
                        className="pt-2 pb-2"
                        onClick={(e) => {
                          openPostMessageEditModal(postMessage);
                          e.preventDefault();
                        }}
                      >
                        {props.t("Edit Post")}
                      </DropdownItem>
                    ) : null}
                    <DropdownItem
                      href="#"
                      className="pt-2 pb-2"
                      onClick={() => {
                        setconfirm_both(true);
                        setPostMessageStatusMeta({
                          rowIndex: index,
                          postMessageId: postMessage.id,
                          postMessageStatus: postMessage.status,
                        });
                      }}
                    >
                      {props.t("Change Status")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
            }

            <div
              className="chat-link"
              onClick={() => {
                UserChatOpen(postMessage);
                setSelectedRowIndex(index);
              }}
            >
              <Media>
                <div className="align-self-center mr-3">
                  <span
                    id={"status_" + postMessage.id}
                    className="cursor-pointer"
                  >
                    <i
                      className={`mdi mdi-circle font-size-10 ${getPostStatusInfo(
                        postMessage.status,
                        "class"
                      )}`}
                    ></i>

                    <UncontrolledTooltip
                      placement="top"
                      target={"status_" + postMessage.id}
                    >
                      <span className="text-capitalize">
                        {postMessage?.status}
                      </span>
                    </UncontrolledTooltip>
                  </span>
                </div>

                <div className="align-self-center mr-3">
                  <div className="avatar-xs">
                    <span className="avatar-title rounded-circle text-capitalize">
                      {postMessage.question?.charAt(0)}
                    </span>
                  </div>
                </div>

                <Media className="overflow-hidden" body>
                  <h5 className="text-truncate font-size-14 mb-1">
                    {postMessage.question}
                  </h5>
                  <p className="text-truncate mb-0">{postMessage.details}</p>
                </Media>
                <div className="font-size-11 align-self-center mr-4">
                  {moment(postMessage.updatedAt).fromNow(true)}
                </div>
              </Media>
            </div>
          </li>
        );
      })
    ) : (
      <li></li>
    );
  };

  return (
    <React.Fragment>
      <div className="postMessage-list">
        <Row>
          <Col lg="12">
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {error_dlg ? (
              <SweetAlert
                error
                title={dynamic_title}
                onConfirm={() => {
                  seterror_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {ajaxLoader ? (
              <div className={`${Config.names.slugName}-listing-loader`}>
                {loadingMessage("Loading...")}
              </div>
            ) : (
              ""
            )}
            <ul className={`list-unstyled chat-list ${Config.names.slugName}-sm--12`}>
              <PerfectScrollbar className={`${Config.names.slugName}-chat-window-scorll`}>
                {postMessageItemsList(postMessagesObj)}
              </PerfectScrollbar>
            </ul>
            {confirm_both ? (
              <SweetAlert
                title={props.t("Are you sure?")}
                warning
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="secondary"
                onConfirm={() => {
                  togglePostMessageStatus(postMessageStatusMeta);
                }}
                onCancel={() => {
                  setconfirm_both(false);
                  setsuccess_dlg(false);
                }}
              >
                {props.t(`This process will change the Message status!`)}
              </SweetAlert>
            ) : null}

            {props.postMessageListMessage ? (
              <div className="text-center text-danger">
                <i className="bx bx-search-alt font-size-18 align-middle mr-2"></i>{" "}
                {props.postMessageListMessage}{" "}
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className={total > 1 ? `${Config.names.slugName}-pager` : `${Config.names.slugName}-pager d-none`}>
              <nav className="d-flex justify-content-center mt-3 mb-2">
                <ReactPaginate
                  initialPage={currentPage}
                  forcePage={currentPage}
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={total}
                  itemsCountPerPage={perPage}
                  breakLabel={"..."}
                  label={<input />}
                  breakClassName={"break-me"}
                  onPageChange={props.getUserData}
                  containerClassName={
                    "pagination pagination-rounded align-items-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  activeLinkClassName={"active"}
                />
                {!window.ReactNativeWebView ? <input
                  className="ml-3 text-center"
                  type="text"
                  size="5"
                  placeholder="Go to"
                  value={customPageNo}
                  onChange={(e) => setCustomPageNo(e.currentTarget.value)}
                  onKeyPress={handleSearchPage}
                  style={{ height: "25px" }}
                /> : null}
              </nav>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(PostMessageList));
