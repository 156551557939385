import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
  Label,
} from "reactstrap";

import AsyncSelect from "react-select/async";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

import {
  createBrand,
  updateBrand,
  createBrandFailed,
  brandActionSuccessful,
  setSingleBrand,
} from "../../store/actions";

//Include Both Helper File with needed methods
import { dctGetAsyncData } from "../../helpers/backend_helper";
import { Config } from "../../Config";

const CreateUpdateBrand = (props) => {
  const [modal, setmodal] = useState(false);
  const [msgEnable, setmsgEnable] = useState(true);

  let brandData = useSelector((state) => state.Brand.singleBrand);
  brandData = brandData ? brandData : [];
  useEffect(() => {
    // if (brandData.companyId) {
    //   setSelectedCompany(brandData.companyId);
    // }
  }, [brandData]);

  function toggleModalClose(e) {
    //checking Store props
    if (props.singleGroup) {
      props.updateBrandDataFlag();
    }
  }

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-cform-spinner")[0]
      .classList.remove("d-none");
    setmsgEnable(true);
    let name = values.name;
    values.name = name.toLowerCase();
    //call reducer method
    if (values.actionType === "edit") {
      values.id = brandData.id;
      props.updateBrand(values, props.history);
    } else {
      props.createBrand(values, props.history);
    }
  }

  const dispatch = useDispatch();
  const editFormGroupItem = (e) => {
    setmsgEnable(false);
    dispatch(brandActionSuccessful(null, "", ""));
  };

  const createFormGroupItem = (e) => {
    setmsgEnable(false);
    dispatch(brandActionSuccessful(null, "", ""));
    dispatch(setSingleBrand(null, "", ""));
  };

  // let brandData = useSelector((state) => state.Brand.singleBrand);

  // brandData = brandData ? brandData : [];
  const action_type = props.brand_action_type === "edit" ? "edit" : "create";
  const button_label =
    props.brand_action_type === "edit" ? "Submit" : "Create Brand";
  const modal_title =
    props.brand_action_type === "edit" ? "Update Brand" : "Create Brand";

  return (
    <React.Fragment>
      {(() => {
        switch (props.brand_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="group_update_modal"
                onClick={() => {
                  setmodal(!modal);
                  editFormGroupItem();
                }}
              ></i>
            );
          case "edit_btn":
            return (
              <Button
                type="button"
                color="primary"
                id="group_update_modal"
                className="waves-effect waves-light mr-2"
                onClick={() => {
                  setmodal(!modal);
                  editFormGroupItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1"></i> {props.t("Edit")}
              </Button>
            );
          case "borderless_edit_btn":
            return (
              <Link
                color="primary"
                to="#"
                id="group_update_modal"
                onClick={() => {
                  setmodal(!modal);
                  editFormGroupItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1 text-muted"></i>
                <span className="text-muted">{props.t("Edit")}</span>
              </Link>
            );
          default:
            return (
              <Link
                onClick={() => {
                  setmodal(!modal);
                  createFormGroupItem();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id="group_create_modal"
              >
                <i className="mdi mdi-plus mr-1"></i> {props.t("Create Brand")}
              </Link>
            );
        }
      })()}

      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setmodal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-brand-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal(!modal);
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <AvField name="actionType" type="hidden" value={action_type} />
              {/* <AvField name="id" type="hidden" value={brandData.id} /> */}

              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Brand Name")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={brandData.name}
                        name="name"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Brand Name")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="pdfLogoUrl"
                      label={props.t("URL of Frontend App Logo ")}
                      className="form-control"
                      placeholder={props.t("URL of Frontend App Logo ")}
                      type="text"
                      value={brandData.pdfLogoUrl}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="emailTemplateMemberWelcome"
                      label={props.t("Member Welcome ")}
                      className="form-control"
                      placeholder={props.t(
                        "Email Template ID for Member Welcome "
                      )}
                      type="text"
                      value={brandData.emailTemplateMemberWelcome}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="emailTemplateUpdateEmailID"
                      label={props.t("Update Email ID Notice ")}
                      className="form-control"
                      placeholder={props.t(
                        "Email Template ID for Update Email ID Notice "
                      )}
                      type="text"
                      value={brandData.emailTemplateUpdateEmailID}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="emailTemplateConsultReminder"
                      label={props.t("Consultation Reminder ")}
                      className="form-control"
                      placeholder={props.t(
                        "Email Template ID for Consultation Reminder "
                      )}
                      type="text"
                      value={brandData.emailTemplateConsultReminder}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="emailTemplateLateConsultReminder"
                      label={props.t("Late Consultation Reminder ")}
                      className="form-control"
                      placeholder={props.t(
                        "Email Template ID for Late Consultation Reminder "
                      )}
                      type="text"
                      value={brandData.emailTemplateLateConsultReminder}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="emailTemplateConsultSubmitted"
                      label={props.t("Consultation Confirmed ")}
                      className="form-control"
                      placeholder={props.t(
                        "Email Template ID for Consultation Confirmed "
                      )}
                      type="text"
                      value={brandData.emailTemplateConsultSubmitted}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="emailTemplateVideoCall"
                      label={props.t("Video Call Invite ")}
                      className="form-control"
                      placeholder={props.t(
                        "Email Template ID for Video Call Invite "
                      )}
                      type="text"
                      value={brandData.emailTemplateVideoCall}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="emailTemplateMemberActivation"
                      label={props.t("Member Activation ")}
                      className="form-control"
                      placeholder={props.t(
                        "Email Template ID for Member Activation"
                      )}
                      type="text"
                      value={brandData.emailTemplateMemberActivation}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="emailTemplatePasswordReset"
                      label={props.t("Password Reset Invite ")}
                      className="form-control"
                      placeholder={props.t(
                        "Email Template ID for Reset Password "
                      )}
                      type="text"
                      value={brandData.emailTemplatePasswordReset}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="pdfLogoUrl"
                      label={props.t("Pdf Logo Url")}
                      className="form-control"
                      placeholder={props.t("Pdf Logo Url")}
                      type="text"
                      value={brandData.pdfLogoUrl}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setmodal(!modal)}
                    >
                      {props.t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                    >
                      <span className="dr-cform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      {button_label}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createBrandError } = state.Brand;
  const { brand } = state.Brand;
  const { message } = state.Brand;
  return { createBrandError, brand, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createBrand,
    updateBrand,
    createBrandFailed,
    setSingleBrand,
  })(withNamespaces()(CreateUpdateBrand))
);
