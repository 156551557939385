import * as React from 'react';
import {
  Modal as BaseModal
} from "reactstrap";

export class Modal extends BaseModal {
  state = {
    bodyHeight: document.body.offsetHeight
  }
  render() {
    let originalOnOpened = this.props.onOpened;
    let originalOnClosed = this.props.onClosed;
    let originalProps = Object.assign({}, this.props);
    delete originalProps.onOpened;
    delete originalProps.onClosed;
    return (
      <BaseModal {...originalProps} onOpened={(e) => {
        if(typeof window.reactNativeViewportHeight !== "undefined") {
          this.setState({
            bodyHeight: document.body.offsetHeight
          });
          document.body.style.height = window.reactNativeViewportHeight + "px"; // Run when Modal Opens
        }
        originalOnOpened(e);
      }} onClosed={(e) => {
        if(typeof window.reactNativeViewportHeight !== "undefined") {
          document.body.style.height = this.state.bodyHeight + "px"; // Run when Modal Closes
        }
        document.body.classList.remove("modal-open");
        originalOnClosed(e);
      }}/>
    )
  }
}

