import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

//Style from Reactstrap
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../../helpers/backend_helper";

import {
  getMember,
  memberActionSuccessful,
  setMemberListing,
  setSingleMember,
} from "../../../store/actions";

//Import MemberList
import MemberList from "../../../components/Member/Admin/MemberList";

//Import Create and Update Member
import CreateUpdateMember from "../../../components/Member/Admin/CreateUpdateMember";

import axios from "axios";
import { withNamespaces } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { Config } from "../../../Config";
const { Parser, transforms: { unwind } } = require('json2csv');

const Members = (props) => {
  let path = props?.location.pathname;
  path = path.split("/");
  let companyId = path[path.length - 1];

  let membersObj = [];
  membersObj = useSelector((state) => state.Member.memberListing);
  membersObj = membersObj ? membersObj : [];

  const [postsPerPage, setPostsPerPage] = useState(
    process.env.REACT_APP_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [confirm_both_email, setconfirm_both_email] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);


  const [gdata, setGData] = useState({
    loading: true,
    members: [],
    message: null,
  });

  const [ajaxKey, setAjaxKey] = useState(false)
  let clickDetect = {
    search: false,
    reset: false,
    dropdown: false
  }


  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    memberSearchParam: "",
    type: "filter",
    reset: false,
  });

  

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        memberSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: false,
      });
      return fetchMemberData(searchParam, selectedPage);
    }
    fetchMemberData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        memberSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      return fetchMemberData(searchParam, selectedPage);
    }
    // if(selectedPage > 1)
    fetchMemberData(searchParam, selectedPage);
  };

  const fetchMemberData = (param, currentPage = 1) => {
    if (clickDetect.reset || clickDetect.search || clickDetect.dropdown) {
      gdata.members = []
    }
    setAjaxKey(false);

    let searchParam = "";
    if (param) {
      searchParam = param.memberSearchParam
        ? "&q=" + param.memberSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    let url =
      process.env.REACT_APP_API_PATH +
      `/company/${companyId}/member?populate=userId,partnerId,dependent,companyId,memberStatusActivityLogsId&page=${currentPage}&limit=${postsPerPage}` +
      searchParam;
    let cancel;

    axios
      .get(url, headers, {
        cancelToken: new axios.CancelToken(c => cancel = c),
      })
      .then(function (response) {
        if (response.status === 200) {
          setAjaxKey(true)
        }

        const data = response.data;
        let message = "";
        message = data.data.length > 0 ? "" : "No record found.";
        let structured = data.data
        if ((!param || param == '') || data.total > 30) {
          structured = [...gdata.members, ...data.data]
        }
        dispatch(setMemberListing(structured, message));
        setGData({
          loading: false,
          members: structured,
          message: message,
        });

        setTotalItems(data.total);
        setTotalPages(Math.ceil(data.total / postsPerPage));
        setCurrentPage(currentPage - 1);
        if (document.getElementsByClassName("dr-msearch-spinner").length > 0) {
          document
            .getElementsByClassName("dr-msearch-spinner")[0]
            .classList.add("d-none");
        }
      })
      .catch(function (err) {
        if (document.getElementsByClassName("dr-msearch-spinner").length > 0) {
          document
            .getElementsByClassName("dr-msearch-spinner")[0]
            .classList.add("d-none");
        }
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = err.response.data.cause.details;
          }
        }

        setGData({
          loading: false,
          members: null,
          message: message,
        });
        setTotalItems(0);
        setTotalPages(0);
        setCurrentPage(0);
        dispatch(setMemberListing(null, message));
        if (axios.isCancel(err)) return
      })
    return () => cancel();
    // .then(function () {});
  };

  const sendWelcomeEmail = (companyId) => {
    if (!companyId || companyId == "") {
      return;
    }
    setconfirm_both_email(false);
    setsuccess_dlg(true);
    setdynamic_title("Sending Email...");
    setdynamic_description("Please wait while its processeing!");

    setStatusLoader(true);
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/company/${companyId}/welcome-email`;
    axios
      .post(url, {}, options)
      .then((response) => {
        setdynamic_description("Welcome email sent.");
        setdynamic_title("Success");
        setsuccess_dlg(true);
        setSuccessMsg("Welcome email sent.");
      })
      .catch((err) => {
        setdynamic_description("Welcome email could not be sent.");
        setdynamic_title("Error");
        seterror_dlg(true);
        setsuccess_dlg(false);
        // setErrorMsg("Welcome email could not be sent.");
      });
  };

  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      searchParam = {
        memberSearchParam: msearch,
        type: "filter",
        // key:  dropdown ? 'fulfilment': ''
      };
      setSearchFormDetails({
        memberSearchParam: "",
        type: "filter",

        reset: true,
      });
    }
    fetchMemberData(searchParam, currentPage + 1)
  }, [currentPage])

  const onScrollToEnd = () => {
    setCurrentPage(currentPage + 1);
  }
  window.onscroll = function () {
    //check if the page has scrolled to the bottom
    let innerHeight = window.innerHeight + document.documentElement.scrollTop + 700;
    let offsetHeight = document.documentElement.offsetHeight;
    if (currentPage + 1 === totalPages || gdata.members?.length == 0) return;

    if (innerHeight >= offsetHeight) {
      if (ajaxKey) {
        onScrollToEnd();
      }
    }
  }
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        memberSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      // fetchMemberData(searchParam);
    } else {
      // fetchMemberData(searchParam);
    }
  }, []);

  // Apply Member Search
  function handleSearch(e) {
    if (!searchFormDetails.memberSearchParam || searchFormDetails.memberSearchParam == '') {
      return;
    }
    e.preventDefault();
    document
      .getElementsByClassName("dr-msearch-spinner")[0]
      .classList.remove("d-none");
    gdata.members = [];

    fetchMemberData(searchFormDetails);
    setSearchFormDetails({
      reset: true,
    });
    let param = searchFormDetails.memberSearchParam
      ? searchFormDetails.memberSearchParam
      : "";
    props.history.push(
      "/company-overview/id/" + companyId + "?msearch=" + param
    );
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    clickDetect.reset = true;
    fetchMemberData();
    setSearchFormDetails({
      reset: false,
    });
    document.getElementById("member-search-form").reset();
    props.history.push("/company-overview/id/" + companyId);
  }

  const { loading, members, message } = gdata;
  const member_action = useSelector((state) => state.Member.action_type);

  const propsUpdateMemberData = () => {
    if (!members && !props.singleMember) {
      return;
    }
    if (members && props.singleMember) {
      let memberid = props.singleMember.id;
      //Update record
      if (member_action === 2) {
        var index = members.findIndex((x) => x.id === memberid);
        members[index] = props.singleMember;
        dispatch(setMemberListing(members, "3"));
      }

      //Insert new record
      if (member_action === 1) {
        members.unshift(props.singleMember);
        dispatch(setMemberListing(members, "3"));
      }

      let activeRow = document.querySelectorAll(`[data-row-id="${memberid}"]`);
      if (activeRow[0]) {
        activeRow[0].scrollIntoView({ behavior: "smooth" });
        activeRow[0].classList.add("active");
        const timer = setTimeout(() => {
          activeRow[0].classList.remove("active");
        }, 3000);
        return () => clearTimeout(timer);
      }
      setGData({
        loading: false,
        members: members,
      });
    }
  };
  const handleExport = () => {
    let baseurl = process.env.REACT_APP_API_PATH + `/company/${companyId}/member?populate=userId,partnerId,dependent,companyId,memberStatusActivityLogsId&page=${1}&limit=${3000000000}&alterValue=${true}`;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios
      .get(baseurl, headers)
      .then(function (response) {
        if (response.status === 200) {
          const fields = ['status', 'primaryExternalId', 'primaryExternalId', 'memberType', 'companyId.planId', 'companyId.planDetailId', 'userId.firstName', 'userId.lastName', 'address', 'addressTwo', 'city', 'state', 'postalCode', 'userId.email', 'primaryPhone', 'secondaryPhone', 'gender', 'dob', 'memberStatusActivityLogsId.effectiveDate', 'memberStatusActivityLogsId.termDate', 'companyId.companyName'];
          let json2csvParser = new Parser({ quote: '', fields });
          json2csvParser.opts.fields[0].label = 'Status';
          json2csvParser.opts.fields[1].label = 'External Id';
          json2csvParser.opts.fields[2].label = 'Primary External Id';
          json2csvParser.opts.fields[3].label = 'Type';
          json2csvParser.opts.fields[4].label = 'Plan Id';
          json2csvParser.opts.fields[5].label = 'Plan Details Id';
          json2csvParser.opts.fields[6].label = 'First';
          json2csvParser.opts.fields[7].label = 'Last';
          json2csvParser.opts.fields[8].label = 'Address';
          json2csvParser.opts.fields[9].label = 'Address Line 2';
          json2csvParser.opts.fields[10].label = 'City';
          json2csvParser.opts.fields[11].label = 'State';
          json2csvParser.opts.fields[12].label = 'Zipcode';
          json2csvParser.opts.fields[13].label = 'Email';
          json2csvParser.opts.fields[14].label = 'Primary Phone';
          json2csvParser.opts.fields[15].label = 'Secondary Phone';
          json2csvParser.opts.fields[16].label = 'Gender';
          json2csvParser.opts.fields[17].label = 'DOB';
          json2csvParser.opts.fields[18].label = 'Effective Date';
          json2csvParser.opts.fields[19].label = 'Term Date';
          json2csvParser.opts.fields[20].label = 'Company Name';

          const csv = json2csvParser.parse(response.data.data);
          const uid = uuidv4();
          let cName = props.companyName;
          cName = cName.replace(/\s+/g, '-');
          const exportedFileName = cName + '-' + uid + '.csv';

          let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          let link = document.createElement("a");
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="6">
              <Form
                inline
                onSubmit={handleSearch}
                id="member-search-form"
                className={`mb-2 ${Config.names.slugName}-search-form`}
              >
                <FormGroup className="mr-sm-2 mb-sm-0 mb-0">
                  <Input
                    type="text"
                    name="memberSearchParam"
                    style={{ minWidth: "250px" }}
                    placeholder={props.t("Search...")}
                    onChange={(e) => {
                      let { value } = e.target;
                      setSearchFormDetails((prevState) => ({
                        ...prevState,
                        memberSearchParam: value,
                      }));
                    }}
                  />
                </FormGroup>
                <Button onClick={handleSearch}>
                  <span className="dr-msearch-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                  {props.t(`Search`)}
                </Button>
                {searchFormDetails.reset ? (
                  <span
                    className={`ml-2 ${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-reset-search`}
                    onClick={resetSearch}
                  >
                    <i className="bx bx-reset font-size-22 align-middle mr-2"></i>
                  </span>
                ) : (
                  ""
                )}
              </Form>
            </Col>
            <Col lg="6">
              <div className="text-right mb-2">
                {membersObj.length > 0 &&
                  <>
                    <Button className="btn btn-primary waves-effect waves-light mr-2"
                      onClick={() => {
                        setconfirm_both_email(true);
                      }}
                    >
                      {props.t("Welcome Email")}
                    </Button>
                    <Button onClick={handleExport} className="btn btn-info waves-effect waves-light mr-2" type="button"><i className="mdi mdi-file-export-outline mr-1"></i> Export</Button>
                  </>}

                <CreateUpdateMember
                  origin={props.origin}
                  fetchMemberData={fetchMemberData}
                  updateMemberDataFlag={propsUpdateMemberData}
                  member_action_type="create"
                  member_button_type="create"
                  companyId={companyId}
                  companyName={props.companyName}
                  {...props}
                />
                <CreateUpdateMember
                  fetchMemberData={fetchMemberData}
                  updateMemberDataFlag={propsUpdateMemberData}
                  member_action_type="edit"
                  member_button_type="edit_icon"
                  {...props}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <MemberList
                    {...props}
                    memberListMessage={message}
                    totalPages={totalPages}
                    totalItems={totalItems}
                    getDataOnEnter={getDataOnEnter}
                    // getUserData={getUserData}
                    currentPage={currentPage}
                    perPage={postsPerPage}
                    ajaxLoader={loading}
                  ></MemberList>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {confirm_both_email && (
          <SweetAlert
            title={props.t("Are you sure?")}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="secondary"
            onConfirm={() => {
              sendWelcomeEmail(companyId);
            }}
            onCancel={() => {
              setconfirm_both_email(false);
              setsuccess_dlg(false);
            }}
          >
            {props.t(`This process will send the welcome email to this company members !`)}
            {dynamic_description}
          </SweetAlert>
        )}

        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              setsuccess_dlg(false);
              setdynamic_description("");
              setdynamic_title("");
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}

        {error_dlg ? (
          <SweetAlert
            danger
            title={dynamic_title}
            onConfirm={() => {
              seterror_dlg(false);
              setdynamic_description("");
              setdynamic_title("");
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { member } = state.Member;
  const { singleMember } = state.Member;
  const { message } = state.Member;
  return { member, message, singleMember };
};

export default withRouter(
  connect(mapStatetoProps, {
    getMember,
    memberActionSuccessful,
    setSingleMember,
  })(withNamespaces()(Members))
);
