import React, { useState, useEffect } from "react";
import { Col, Form, FormGroup } from "reactstrap";
import { Config } from "../../../../Config";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { phoneFormatter } from "../../service/phoneFormatter";
import moment from "moment";

// Redux
import {
  findYourAccount,
  activeAccountData,
} from "../../../../store/auth/activateBenefit/actions";

const ActivateBenefitCard = (props) => {
  const [inpData, setInpData] = useState({
    lastName: "",
    dob: "",
    zipCode: "",
    checked: false,
  });
  const [inpErrorData, setInpErrorData] = useState({
    lastName: false,
    dob: false,
    zipCode: false,
    checked: false,
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [pathName, setPathName] = useState("");

  useEffect(() => {
    let pathName = history?.location?.pathname;
    setPathName(pathName);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    //regex for dob validation========
    let p = /\d{2}-\d{2}-\d{4}/;
    // Edit dob split ===================
    const splitDob = inpData.dob.split("/");

    if (!inpData.lastName) {
      setInpErrorData({ ...inpErrorData, lastName: true });
      return;
    } else if (!p.test(inpData.dob)) {
      setInpErrorData({ ...inpErrorData, dob: true });
      return;
    } else if (
      Number(splitDob[0]) > 12 ||
      Number(splitDob[1]) > 31 ||
      Number(splitDob[2]) > new Date().getFullYear()
    ) {
      setInpErrorData({ ...inpErrorData, dob: true });
      return;
    } else if (!inpData.zipCode) {
      setInpErrorData({ ...inpErrorData, zipCode: true });
      return;
    } else if (inpData.checked === false) {
      setInpErrorData({ ...inpErrorData, checked: true });
      return;
    }
    // console.log("input data", inpData);

    setLoading(true);
    dispatch(activeAccountData(inpData));
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/api/find-my-account?lastName=${
          inpData.lastName
        }&dob=${moment(inpData.dob).format("MM-DD-YYYY")}&zipCode=${
          inpData.zipCode
        }`
      )
      .then((res) => {
        setLoading(false);
        if (res.status === 200 && res.data.data.length > 0) {
          console.log("response --- ", res);
          dispatch(findYourAccount(res.data.data));
          history.push("/identify-account");
        } else {
          props.setAlertData({
            ...props.alertData,
            type: "error",
            title: "Error",
            message: `${
              pathName === "/activate-benefit"
                ? `Activation Failed: The information provided does not match our records. Please try again, or contact support at ${phoneFormatter(
                    `${Config.names.supportContact}`,
                    1
                  )}`
                : `Could not find your account : The information provided does not match our records. Please try again, or contact support at ${phoneFormatter(
                    `${Config.names.supportContact}`,
                    1
                  )}`
            }`,
          });
          props.setShowAlert(true);
          setTimeout(() => props.setShowAlert(false), 10000);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
        if (
          err.response.status === 400 &&
          err.response.data.success === false
        ) {
          props.setAlertData({
            ...props.alertData,
            type: "error",
            title: "Error",
            message: err.response.data.message,
          });
          props.setShowAlert(true);
          setTimeout(() => props.setShowAlert(false), 10000);
        } else {
          props.setAlertData({
            ...props.alertData,
            type: "error",
            title: "Error",
            message: `Something went wrong. Please try again, or contact support at ${phoneFormatter(
              `${Config.names.supportContact}`,
              1
            )}`,
          });
          props.setShowAlert(true);
          setTimeout(() => props.setShowAlert(false), 10000);
        }
      });
  };

  return (
    <>
      <Col
        lg="5"
        md="6"
        sm="8"
        xs="10"
        className={`${Config.names.slugName}-box-shadow rounded-lg mt-3`}
      >
        <div className="py-5 px-3">
          <h5 className="text-primary font-weight-bold text-uppercase">
            {props.title}
          </h5>
          <p className="font-size-14 font-weight-light mt-3">
            Let's start by finding your account. Please enter your member
            information to begin.
          </p>

          <Form onSubmit={handleSubmit} className="mt-4">
            <FormGroup>
              <label className="text-secondary">Last Name</label>
              <input
                type="text"
                value={inpData.lastName}
                onChange={(e) => {
                  setInpData({
                    ...inpData,
                    lastName: e.target.value,
                  });
                  setInpErrorData({
                    ...inpErrorData,
                    lastName: false,
                  });
                }}
                className={`form-control ${
                  inpErrorData.lastName && "border-danger"
                }`}
                placeholder="Last Name"
              />
            </FormGroup>
            <FormGroup>
              <label className="text-secondary">Date Of Birth</label>
              <div
                className="d-flex flex-row "
                style={{
                  border: "1px solid",
                  borderRadius: "0.25rem",
                  borderColor: `${inpErrorData.dob ? "red" : "#ced4da"}`,
                }}
              >
                <input
                  type="text"
                  value={inpData.dob}
                  size={10}
                  maxLength={10}
                  onChange={(e) => {
                    setInpData({
                      ...inpData,
                      dob: e.target.value
                        .replace(/^(\d\d)(\d)$/g, "$1-$2")
                        .replace(/^(\d\d-\d\d)(\d+)$/g, "$1-$2")
                        .replace(/[^\d-]/g, ""),
                    });
                    setInpErrorData({
                      ...inpErrorData,
                      dob: false,
                    });
                  }}
                  className="form-control"
                  style={{ border: "0px" }}
                  placeholder="MM-DD-YYYY"
                />
                <i className="fa fa-calendar-alt font-size-18 align-self-center align-self-center mr-3"></i>
              </div>
              {inpErrorData.dob && (
                <p className="mt-1 font-size-12 text-danger font-italic">
                  * DOB should be in MM-DD-YYYY
                </p>
              )}
            </FormGroup>

            <FormGroup>
              <label className="text-secondary">Zip Code</label>
              <div
                className="d-flex flex-row "
                style={{
                  border: "1px solid",
                  borderRadius: "0.25rem",
                  borderColor: `${inpErrorData.zipCode ? "red" : "#ced4da"}`,
                }}
              >
                <input
                  type="text"
                  value={inpData.zipCode}
                  size={6}
                  maxLength={6}
                  onChange={(e) => {
                    setInpData({
                      ...inpData,
                      zipCode: e.target.value,
                    });
                    setInpErrorData({
                      ...inpErrorData,
                      zipCode: false,
                    });
                  }}
                  className="form-control"
                  style={{ border: "0px" }}
                  placeholder="Zip Code"
                />
                <i className="fa fa-map-marker font-size-18 align-self-center align-self-center mr-3"></i>
              </div>
            </FormGroup>

            <div
              className={`d-flex flex-row px-2 mt-4 ${
                inpErrorData.checked && "p-2 border border-danger rounded-lg"
              }`}
            >
              <input
                type="checkbox"
                className="align-self-baseline border-danger"
                onChange={() => {
                  setInpData({
                    ...inpData,
                    checked: !inpData.checked,
                  });
                  setInpErrorData({
                    ...inpErrorData,
                    checked: false,
                  });
                }}
                checked={inpData.checked}
              />
              <p className="ml-3 text-secondary font-weight-light font-size-12">
                I've read and agreed with the{" "}
                <span className="text-primary font-weight-normal">
                  Terms of Use, Privacy Policy
                </span>{" "}
                and{" "}
                <span className="text-primary font-weight-normal">
                  HIPAA Privacy Practices
                </span>
              </p>
            </div>

            <button
              type="submit"
              className="btn btn-primary mt-3 w-100 btn-shadow"
            >
              {loading ? (
                <>
                  <span className="dr-mform-spinner">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>
                  </span>
                </>
              ) : (
                "Find My Account"
              )}
            </button>
          </Form>
        </div>
      </Col>
    </>
  );
};

export default ActivateBenefitCard;
