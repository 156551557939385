import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Container, Card, CardBody, Button } from "reactstrap";
import { setSingleErrConsult } from "../../store/actions";
import moment from "moment";

const FailedConsultationOverview = (props) => {
  const { location } = useHistory();
  const dispatch = useDispatch();
  const singleErrConsult = useSelector((state) => state.FailedConsultation);
  const consultData = singleErrConsult.data;
  const [copy, setCopy] = useState({
    memberId: false,
    consultId: false,
  });
  const [phone, setPhone] = useState({
    countryCode: "",
    phoneNumber: null,
  });

  //Link for router-back

  const history = useHistory();

  const goBack = () => {
    history.goBack();
    // history.push("/member-overview/id/" + e);
  };

  const backToMemberListLink = () => {
    return (
      <Button onClick={() => goBack()} className="bg-primary mr-2">
        <i className="mdi mdi-arrow-left mr-1"></i> Back to List
      </Button>
    );
  };

  const setPhoneValue = (data) => {
    if (data.state_country === "MX") {
      setPhone((prev) => ({
        ...prev,
        countryCode: "+52",
        phoneNumber: Number(data.phone_tel.slice(3)),
      }));
    } else {
      setPhone((prev) => ({
        ...prev,
        countryCode: "+1",
        phoneNumber: Number(data.phone_tel.slice(2)),
      }));
    }
  };

  const copyToClipBoard = (data, option) => {
    try {
      navigator.clipboard.writeText(data);
      switch (option) {
        case "memberId": {
          setCopy((prev) => ({
            ...prev,
            memberId: true,
          }));
          break;
        }
        case "consultId": {
          setCopy((prev) => ({
            ...prev,
            consultId: true,
          }));
          break;
        }
        default:
          return;
      }
    } catch (err) {
      console.log("copy to clipboard error----");
    }

    setTimeout(() => {
      setCopy((prev) => ({
        ...prev,
        consultId: false,
        memberId: false,
      }));
    }, 4000);
  };

  useEffect(() => {
    if (!consultData) {
      // call api
      let headers = {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      };

      let consultId = location.pathname.split("/").pop();

      let url = `${process.env.REACT_APP_API_PATH}/consultation/${consultId}`;
      axios
        .get(url, headers)
        .then((res) => {
          console.log(res.data);
          dispatch(setSingleErrConsult(res.data));
          setPhoneValue(res.data);
          // setPhone(res.data.phone_tel)
        })
        .catch((err) => {
          console.log("fetch consultation error---");
        });
    } else {
      setPhoneValue(consultData);
    }
  }, []);

  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <div className="text-right">{backToMemberListLink()}</div>
          <p className="text-dark font-weight-bold">Error Message :</p>
          <pre className="ml-2 text-danger">
            {consultData?.errorMessage &&
              JSON.stringify(consultData?.errorMessage, null, 4)}
          </pre>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col lg="6" md="6" sm="6" xs="12">
              <label className="font-size-12 text-secondary">
                Patient Name:
              </label>
              <p className="font-size-14 pr-2 text-capitalize">
                {consultData?.patient}
              </p>
              <label className="font-size-12 text-secondary">Member Id:</label>
              <p
                onClick={() => {
                  copyToClipBoard(consultData?.memberId.id, "memberId");
                }}
                className="font-size-14 pr-2"
              >
                {consultData?.memberId.id}{" "}
                <span>
                  {copy.memberId ? (
                    <i
                      className="fa fa-check-circle text-success ml-2"
                      style={{ cursor: "pointer" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-copy ml-2"
                      style={{ cursor: "pointer" }}
                    ></i>
                  )}
                </span>
              </p>
              <label className="font-size-12 text-secondary">Country:</label>
              <p className="font-size-14 pr-2">{consultData?.state_country}</p>
              <label className="font-size-12 text-secondary">State:</label>
              <p className="font-size-14 pr-2">
                {consultData?.state_currentState}
              </p>
              <label className="font-size-12 text-secondary">Phone:</label>
              <p className="font-size-14 pr-2">{consultData?.phone_tel}</p>
            </Col>
            <Col lg="6" md="6" sm="6" xs="12">
              <label className="font-size-12 text-secondary">
                Consultation Id:
              </label>
              <p
                onClick={() => {
                  copyToClipBoard(consultData?.id, "consultId");
                }}
                className="font-size-14 pr-2"
              >
                {consultData?.id}{" "}
                <span>
                  {copy.consultId ? (
                    <i
                      className="fa fa-check-circle text-success ml-2"
                      style={{ cursor: "pointer" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-copy ml-2"
                      style={{ cursor: "pointer" }}
                    ></i>
                  )}
                </span>
              </p>
              <label className="font-size-12 text-secondary">
                Consultation Platform:
              </label>
              <p className="font-size-14 pr-2">
                {consultData?.consultationPlatform}
              </p>
              <label className="font-size-12 text-secondary">
                Consultation Type:
              </label>
              <span className="font-size-14 pr-2 text-capitalize ml-2">
                {consultData?.consultationType === "phone" ? (
                  <i className="fa fa-phone-alt text-warning"></i>
                ) : (
                  <i className="fa fa-video text-success"></i>
                )}
              </span>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6" sm="6" xs="12">
              <label className="font-size-12 text-secondary">
                Chief Complaint:
              </label>
              <p className="font-size-14">
                {consultData?.details_chiefcomplaint}
              </p>
              <label className="font-size-12 text-secondary">
                Common Symptoms:
              </label>
              <p className="font-size-14">
                {consultData?.details_commonSymptoms.length > 0
                  ? consultData?.details_commonSymptoms.join(", ")
                  : "--"}
              </p>
              <label className="font-size-12 text-secondary">
                All Other Symptoms:
              </label>
              <p className="font-size-14">
                {consultData?.details_allOtherSymptoms.length > 0
                  ? consultData?.details_allOtherSymptoms.join(", ")
                  : "--"}
              </p>
            </Col>
            <Col lg="6" md="6" sm="6" xs="12">
              <label className="font-size-12 text-secondary">
                Schedule Date:
              </label>
              <p className="font-size-14">
                {moment(consultData?.schedule_to).format("MMM Do YYYY")}
              </p>
              <label className="font-size-12 text-secondary">
                Schedule Time:
              </label>
              <p className="font-size-14 m-0">
                {moment(consultData?.schedule_from).format("hh:mm A")}-
                {moment(consultData?.schedule_to).format("hh:mm A")}
              </p>
              <p className="font-size-12 text-secondary">
                ({moment(consultData?.createdAt).fromNow()})
              </p>
            </Col>
          </Row>

          {/* <Col lg="6" md="6" sm="12">
                        <label>Phone:</label>
                        <div className='d-flex flex-row align-items-center'>
                            <p className='font-size-16 m-0 pr-2'>{phone.countryCode}</p>
                            <input type="number" className='form-control' value={phone.phoneNumber} />
                        </div>
                    </Col> */}
        </CardBody>
      </Card>
    </div>
  );
};

export default FailedConsultationOverview;
