import {
  GET_SINGLE_PHARMACY,
  GET_PHARMACY_LIST,
  SET_SINGLE_PHARMACY,
  SET_PHARMACY_LIST,
  SET_PHARMACY_MARKERS,
} from "./actionTypes";

export const getSinglePharmacy = (pharmacy) => ({
  type: GET_SINGLE_PHARMACY,
  payload: { pharmacy },
});

export const getPharmacyList = (pharmacy) => ({
  type: GET_PHARMACY_LIST,
  payload: { pharmacy },
});

export const setSinglePharmacy = (pharmacy, message) => {
  return {
    type: SET_SINGLE_PHARMACY,
    payload: pharmacy,
    message: message,
  };
};

export const setPharmacyList = (pharmacy, message) => {
  return {
    type: SET_PHARMACY_LIST,
    payload: pharmacy,
    pharmacyLis: pharmacy,
    message: message,
  };
};

export const setPharmacyMarkers = (markers) => {
  return {
    type: SET_PHARMACY_MARKERS,
    payload: markers,
    markers: markers,
  };
};
