import React from "react";

export const DctAvatar = (props) => {
  let size = props.size ? props.size : 66;
  let bgColor = props.bgColor ? props.bgColor : "#C4C4C4";
  let chColor = props.chColor ? props.chColor : "#E3E3E3";
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.547119"
        y="0.575439"
        width="64.6016"
        height="64.6016"
        rx="32.3008"
        fill={bgColor}
      />
      <path
        d="M51.6396 39.2916C49.2451 36.6391 46.3393 34.5224 43.1053 33.0746C39.8712 31.6268 36.379 30.8794 32.8485 30.8794C29.318 30.8794 25.8257 31.6268 22.5917 33.0746C19.3576 34.5224 16.4519 36.6391 14.0574 39.2916C13.6877 39.7095 13.4809 40.2516 13.4762 40.8151V49.9561C13.484 50.5569 13.723 51.1304 14.1413 51.5524C14.5597 51.9744 15.1238 52.211 15.7115 52.2109H49.9855C50.5783 52.2109 51.1469 51.9702 51.566 51.5416C51.9852 51.113 52.2207 50.5318 52.2207 49.9257V40.7846C52.2089 40.2317 52.0025 39.7016 51.6396 39.2916Z"
        fill={chColor}
      />
      <path
        d="M32.8486 27.8347C38.6096 27.8347 43.2798 23.06 43.2798 17.1701C43.2798 11.2802 38.6096 6.50553 32.8486 6.50553C27.0876 6.50553 22.4174 11.2802 22.4174 17.1701C22.4174 23.06 27.0876 27.8347 32.8486 27.8347Z"
        fill={chColor}
      />
    </svg>
  );
};
