import axios from "axios";
import { useState } from "react";
import { dctClsAddRemove } from "./Commons";

/*
 * Process Lgout
 */
let localLang = localStorage.getItem("i18nextLng");
const processLogout = () => {
  localStorage.removeItem("auth_token");
  localStorage.removeItem("auth_user");
  localStorage.removeItem("states");
  localStorage.clear();
  localStorage.setItem("i18nextLng", localLang);
  window.location.href = process.env.REACT_APP_HOST_URL;
};

axios.interceptors.request.use(function (config) {
  config.headers.AppSlug = process.env.REACT_APP_SLUG;
  return config;
});

// if(!document.getElementsByClassName("dr-cform-spinner")[0]?.classList.contains('d-none')){
//   console.log("spinner active")
//   // document.getElementsByClassName("dr-cform-spinner")[0].classList.add('d-none')
//   // document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
// }

const checkAjaxError = (err) => {
  if (!err) return;

  var message;

  if (err.response && err.response.status) {
    if (err.response.status === 401 || err.response.status === 403) {
      //processLogout();
      window.location.href = "/unauthorized";
      //return;
    }

    if (
      (window.ReactNativeWebView && err.response.status === 401) ||
      err.response.status === 403
    ) {
      window.ReactNativeWebView.postMessage("LOGOUT_NATIVE");
    }

    switch (err.response.status) {
      case 400:
        if (err.response.data && err.response.data.message) {
          message = err.response.data.message;
        } else if (err.response.data && err.response.data.cause) {
          message = err.response.data.cause?.message;
        } else {
          message = "Something went wrong.";
        }
        break;
      case 404:
        message = err.response.data
          ? err.response.data.message
          : "Sorry! the page you are looking for could not be found.";
        break;
      case 406:
        if (err.response.data && err.response.data.message) {
          message = err.response.data.message;
        } else if (err.response.data && err.response.data.cause) {
          message = err.response.data.cause?.message;
        } else {
          message = "Something went wrong.";
        }
        break;
      case 500:
        message =
          "Sorry! something went wrong, please contact our support team.";
        break;
      case 401:
        message = "Invalid credentials.";
        break;
      case 403:
        message = err.response.data
          ? err.response.data.cause.message
          : "Error!";
        break;
      default:
        message = err[1];
        break;
    }
  }
  return message;
};

// Update Member Status
export const memberActivation = async (id) => {
  if (!id) {
    return;
  }
  const URL = process.env.REACT_APP_API_PATH + "/members/activation";

  let bearer_token = localStorage.getItem("auth_token");
  let data = {
    _id: id,
  };
  let alertInfo = {
    title: "Failed",
    description:
      "Sorry! something went wrong, please contact our support team.",
    success: false,
  };

  return await axios
    .post(URL, data, {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    })
    .then((response) => {
      alertInfo = {
        title: response.data.success === true ? "Activate" : "Failed",
        description: response.data.message,
        success: response.data.success,
      };
      return alertInfo;
    })
    .catch((err) => {
      checkAjaxError(err);
      throw alertInfo;
    });
};

// Update Doctor Activation Status
export const doctorActivation = async (id) => {
  if (!id) {
    return;
  }
  const URL = process.env.REACT_APP_API_PATH + "/doctors/activation";

  let bearer_token = localStorage.getItem("auth_token");
  let data = {
    _id: id,
  };
  let alertInfo = {
    title: "Failed",
    description:
      "Sorry! something went wrong, please contact our support team.",
    success: false,
  };

  return await axios
    .post(URL, data, {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    })
    .then((response) => {
      alertInfo = {
        title: response.data.success === true ? "Activate" : "Failed",
        description: response.data.message,
        success: response.data.success,
      };
      return alertInfo;
    })
    .catch((err) => {
      checkAjaxError(err);
      throw alertInfo;
    });
};

// Update Member Status
export const updateMemberStatus = async (post_data) => {
  const URL = `${process.env.REACT_APP_API_PATH}/members/status`;
  if (!post_data) {
    return;
  }
  axios
    .patch(URL, post_data, {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.result ? response.data.result.status : "inactive";
      }
    })
    .catch((err) => {
      var message = checkAjaxError(err);
      throw message;
    });
};

// Update Doctor Status
export const updateDoctorStatus = async (post_data) => {
  const URL = `${process.env.REACT_APP_API_PATH}/doctors/status`;

  if (!post_data) {
    return;
  }
  axios
    .patch(URL, post_data, {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.result ? response.data.result.status : "inactive";
      }
      throw response.data;
    })
    .catch((err) => {
      var message = checkAjaxError(err);
      throw message;
    });
};

//Update Company Status
export const updateCompanyStatus = async (post_data) => {
  const URL = `${process.env.REACT_APP_API_PATH}/members/status`;
  if (!post_data) {
    return;
  }
  axios
    .patch(URL, post_data, {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.result ? response.data.result.status : "inactive";
      }
      throw response.data;
    })
    .catch((err) => {
      var message = checkAjaxError(err);
      throw message;
    });
};
//Update Partner Status
export const updatePartnerStatus = async (post_data) => {
  const URL = process.env.REACT_APP_API_PATH + "/members/status";
  if (!post_data) {
    return;
  }
  axios
    .patch(URL, post_data, {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    })
    .then((response) => {
      if (response.data.success === true) {
        return response.data.result ? response.data.result.status : "inactive";
      }
      throw response.data;
    })
    .catch((err) => {
      var message = checkAjaxError(err);
      throw message;
    });
};

// Update Status Global Method
export const dctUpdateStatus = async (post_data, url) => {
  const URL = `${process.env.REACT_APP_API_PATH}/${url}`;
  let bearer_token = localStorage.getItem("auth_token");
  let data = post_data;
  if (!post_data && !url) {
    return;
  } else {
    return await axios
      .patch(URL, data, {
        headers: {
          Authorization: bearer_token,
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.id) {
          return response.data;
        }
        throw response.data;
      })
      .catch((err) => {
        var message = checkAjaxError(err);
        return message;
      });
  }
};

export const dctSyncWithDoseSpot = async (post_data, url) => {
  const URL = `${process.env.REACT_APP_API_PATH}/${url}`;
  let bearer_token = localStorage.getItem("auth_token");
  let data = post_data;
  return await axios
    .post(URL, data, {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

/*
 * This method will change the status and
 * return the status message, no data will return
 */
export const dctChangeStatus = async (post_data, url) => {
  if (!post_data && !url) {
    return;
  } else {
    const URL = `${process.env.REACT_APP_API_PATH}/${url}`;
    return await axios
      .patch(URL, post_data, {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      })
      .then((response) => {
        if (response.status >= 200 || response.status <= 299)
          return response.data;
        throw response.data;
      })
      .catch((err) => {
        var message = checkAjaxError(err);
        return message;
      });
  }
};

// Global Get method for raw data
export const getGroupListObj = (url, headers) => {
  return axios
    .get(url, headers)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      var message = checkAjaxError(err);
      throw message;
    });
};

// Get all the State list
const getStateList = (url = false, data = false, token = false) => {
  let stateURL = url ? url : process.env.REACT_APP_API_PATH + "/members/states";

  let bearer_token = token ? token : localStorage.getItem("auth_token");
  const headers = {
    headers: {
      Authorization: bearer_token,
      PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
    },
  };
  let postData = data ? data : "";
  axios
    .get(stateURL, headers)
    .then(function (response) {
      const data = response.data;
      if (data.success === true) {
        localStorage.setItem("states", JSON.stringify(data.result));
      }
      return data;
    })
    .catch(function (err) {
      var message = checkAjaxError(err);
      return message;
    })
    .then(function () { });
};

//toggle post status
const togglePostStatus = async (url = false, data = false, token = false) => {
  // let url = url ? url : process.env.REACT_APP_API_PATH + "/members/states";

  let bearer_token = token ? token : localStorage.getItem("auth_token");
  const headers = {
    headers: {
      Authorization: bearer_token,
      PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
    },
  };
  // let postData = data ? data : "";
  await axios
    .get(url, headers)
    .then(function (response) {
      const data = response.data;
      return response.data;
    })
    .catch(function (err) {
      var message = checkAjaxError(err);
      return message;
    })
    .then(function () { });
};

// Does Spot Item Search
const dctGetDosespotItems = async (url, data = false) => {
  if (!url) return;
  let apiUrl = `${process.env.REACT_APP_API_PATH}/${url}`;
  return await axios
    .get(apiUrl, {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
      params: data ? data : "",
    })
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) {
        return response.data;
      }
      throw response.data;
    })
    .catch((err) => {
      var message = checkAjaxError(err);
      return message;
    });
};

// Pharmacy Search
const dctGetPharmacyList = async (url, data = false) => {
  if (!url) return;
  let apiUrl = `${process.env.REACT_APP_API_PATH}/${url}`;
  let bearer_token = localStorage.getItem("auth_token");
  let postData = data ? data : "";

  return await axios
    .get(apiUrl, {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
      params: postData,
    })
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) {
        dctClsAddRemove("dr-form-spinner", "d-none", "add");
        dctClsAddRemove("dr-search-spinner", "d-none", "add");
        return response.data;
      }
      throw response.data;
    })
    .catch((err) => {
      dctClsAddRemove("dr-form-spinner", "d-none", "add");
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      var message = checkAjaxError(err);
      return message;
    });
};

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      var message = checkAjaxError(err);
      throw message;
    });
};

// Global Post method for raw data
const dctPostDosespotData = (url, data, headers) => {
  return axios
    .post(url, data, headers)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
          //processLogout();
          return err.response;
        }
      }
      return err.response;
    });
};

const dctGetEmail = (url, data, headers) => {
  return axios
    .post(url, data, headers)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response;
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
          //processLogout();
          return err.response;
        }
      }
      return err.response;
    });
};

// Global Put method for raw data
const dctPutDosespotData = (url, data, headers) => {
  return axios
    .patch(url, data, headers)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403 || err.response.status === 400) {
          //processLogout();
          return err.response;
        }
      }
      return err.response;
    });
};

//Generic Put Method
const dctPutData = (url, data, headers) => {
  return axios
    .patch(url, data, headers)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
          //processLogout();
          return err.response;
        }
      }
      return err.response;
    });
};

// Global Post method for raw data
const dctPostRawData = (url, data, headers) => {
  return axios
    .post(url, data, headers)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      var message = checkAjaxError(err);
      // if (err.response.status === 400) {
      //   return err.response.data;
      // }
      // return err.response.data;
      throw message;
    });
};

// Global Put method for raw data
const dctPutRawData = (url, data, headers) => {
  return axios
    .patch(url, data, headers)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      var message = checkAjaxError(err);
      throw message;
    });
};

// Global Get method for raw data
const dctGetRawData = (url, headers) => {
  return axios
    .get(url, headers)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      var message;
      var message = checkAjaxError(err);
      throw message;
    });
};

// Login Method
const postLogin = (url, data, headers) => {
  return axios
    .post(url, data, headers)
    .then((response) => {
      if (response.status === 200) {
        let token =
          response.data.success === true ? response.data.result.bearer : "";
        // Get the state list
        // getStateList('', '', "Bearer " + token);
      }
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

// postForgetPwd
const postForgetPwd = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

// postResetPwd
const postResetPwd = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

const postProfile = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

/**
 * Get Method for Global Async Data
 *
 * @param {obj} args - API URL, params
 * @return {json} API response
 *
 * @since 1.0
 */
export const dctGetAsyncData = async (args) => {
  if (!args) return;
  const URL = args.URL ? args.URL : "";
  let bearer_token = localStorage.getItem("auth_token");
  let _condition = args.whereCondition ? args.whereCondition : {};
  return axios
    .get(URL, {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
      params: _condition,
    })
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response;
    })
    .catch((err) => {
      var message = checkAjaxError(err);
      throw message;
    });
};

/*
 * Clone memebr fieds to Dependents
 * */
const cloneMemebrDataToDependents = (data) => {
  if (!data) return;

  let response = data;
  let dependents = response.dependent ? response.dependent : "";
  let i;
  if (dependents) {
    for (i in dependents) {
      dependents[i].primaryPhone = dependents[i].primaryPhone
        ? dependents[i].primaryPhone
        : response.primaryPhone;
      dependents[i].city = dependents[i].city
        ? dependents[i].city
        : response.city;
      dependents[i].state = dependents[i].state
        ? dependents[i].state
        : response.state;
      dependents[i].country = dependents[i].country
        ? dependents[i].country
        : response.country;

      dependents[i].postalCode = dependents[i].postalCode
        ? dependents[i].postalCode
        : response.postalCode;

      dependents[i].address = dependents[i].address
        ? dependents[i].address
        : response.address;
      dependents[i].addressTwo = dependents[i].addressTwo
        ? dependents[i].addressTwo
        : response.addressTwo;
      dependents[i].timezoneAbbr = dependents[i].timezoneAbbr
        ? dependents[i].timezoneAbbr
        : response.timezoneAbbr;

      dependents[i].timezoneUTC = dependents[i].timezoneUTC
        ? dependents[i].timezoneUTC
        : response.timezoneUTC;

      dependents[i].status = dependents[i].status
        ? dependents[i].status
        : "active";
    }
  }

  return response;
};

// Update Async PUT Method
export const dctAsyncPutData = async (post_data, url) => {
  const URL = `${process.env.REACT_APP_API_PATH}/${url}`;
  if (!post_data && !url) {
    return;
  } else {
    return await axios
      .patch(URL, post_data, {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      })
      .then((response) => {
        if (response) {
          return response.data;
        }
        throw response.data;
      })
      .catch((err) => {
        var message = checkAjaxError(err);
        return message;
        throw message;
      });
  }
};

export {
  cloneMemebrDataToDependents,
  getLoggedInUser,
  isUserAuthenticated,
  postRegister,
  postLogin,
  postProfile,
  postForgetPwd,
  postResetPwd,
  dctPostRawData,
  dctGetRawData,
  dctPostDosespotData,
  dctGetEmail,
  dctPutDosespotData,
  dctPutData,
  dctPutRawData,
  getStateList,
  togglePostStatus,
  checkAjaxError,
  processLogout,
  dctGetPharmacyList,
  dctGetDosespotItems,
};
