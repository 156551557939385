export const CommonSymptoms = [
  {
    value: "Cold or Flu",
    label: "Cold or Flu",
    iswarning: false,
    warning: "",
  },
  {
    value: "Earache",
    label: "Earache",
    iswarning: false,
    warning: "",
  },
  {
    value: "Sinus congestion",
    label: "Sinus congestion",
    iswarning: false,
    warning: "",
  },
  {
    value: "Chills",
    label: "Chills",
    iswarning: false,
    warning: "",
  },
  {
    value: "Fever",
    label: "Fever",
    iswarning: false,
    warning: "",
  },
  {
    value: "Skin rash",
    label: "Skin rash",
    iswarning: false,
    warning: "",
  },
  {
    value: "Cough",
    label: "Cough",
    iswarning: false,
    warning: "",
  },
  {
    value: "Headache",
    label: "Headache",
    iswarning: false,
    warning: "",
  },
  {
    value: "Sore throat",
    label: "Sore throat",
    iswarning: false,
    warning: "",
  },
  {
    value: "Diarrhea",
    label: "Diarrhea",
    iswarning: false,
    warning: "",
  },
  {
    value: "Nausea, vomiting",
    label: "Nausea, vomiting",
    iswarning: false,
    warning: "",
  },
  {
    value: "Urinary problems",
    label: "Urinary problems",
    iswarning: false,
    warning: "",
  },
];
export const AllOtherSymptoms = [
  {
    value: "General malaise",
    label: "General malaise",
    iswarning: false,
    warning: "",
  },
  {
    value: "Lightheadedness or Dizziness",
    label: "Lightheadedness or Dizziness",
    iswarning: false,
    warning: "",
  },
  {
    value: "Abdominal pain",
    label: "Abdominal pain",
    iswarning: false,
    warning: "",
  },
  {
    value: "Foot pain",
    label: "Foot pain",
    iswarning: false,
    warning: "",
  },
  {
    value: "Tired",
    label: "Tired",
    iswarning: false,
    warning: "",
  },
  {
    value: "Chest pain",
    label: "Chest pain",
    iswarning: true,
    warning: "",
  },
  {
    value: "Female problems",
    label: "Female problems",
    iswarning: false,
    warning: "",
  },
  {
    value: "Weak",
    label: "Weak",
    iswarning: false,
    warning: "",
  },
  {
    value: "Hypertension (High blood pressure)",
    label: "Hypertension (High blood pressure)",
    iswarning: false,
    warning: "",
  },
  {
    value: "Male problems",
    label: "Male problems",
    iswarning: false,
    warning: "",
  },
  {
    value: "Eye problem",
    label: "Eye problem",
    iswarning: false,
    warning: "",
  },
  {
    value: "Loss of consciousness",
    label: "Loss of consciousness",
    iswarning: true,
    warning: "",
  },
  {
    value: "Shortness of breath",
    label: "Shortness of breath",
    iswarning: true,
    warning: "",
  },
  {
    value: "Backache",
    label: "Backache",
    iswarning: false,
    warning: "",
  },
];

export const chiefcomplaintList = [
  {
    value: "",
    label: "Please choose one",
    iswarning: false,
    warning: "",
  },
  {
    value: "Cold or Flu",
    label: "Cold or Flu",
    iswarning: false,
    warning: "",
  },
  {
    value: "Abdominal pain",
    label: "Abdominal pain",
    iswarning: false,
    warning: "",
  },
  {
    value: "Backache",
    label: "Backache",
    iswarning: false,
    warning: "",
  },
  {
    value: "Chest pain",
    label: "Chest pain",
    iswarning: true,
    warning: "",
  },
  {
    value: "Chills",
    label: "Chills",
    iswarning: false,
    warning: "",
  },
  {
    value: "Cough",
    label: "Cough",
    iswarning: false,
    warning: "",
  },
  {
    value: "Diarrhea",
    label: "Diarrhea",
    iswarning: false,
    warning: "",
  },
  {
    value: "Earache",
    label: "Earache",
    iswarning: false,
    warning: "",
  },
  {
    value: "Female problems",
    label: "Female problems",
    iswarning: false,
    warning: "",
  },
  {
    value: "Foot pain",
    label: "Foot pain",
    iswarning: false,
    warning: "",
  },
  {
    value: "General malaise",
    label: "General malaise",
    iswarning: false,
    warning: "",
  },
  {
    value: "Headache",
    label: "Headache",
    iswarning: false,
    warning: "",
  },
  {
    value: "Hypertension (High blood pressure)",
    label: "Hypertension (High blood pressure)",
    iswarning: false,
    warning: "",
  },
  {
    value: "Lightheadedness or Dizziness",
    label: "Lightheadedness or Dizziness",
    iswarning: false,
    warning: "",
  },
  {
    value: "Loss of consciousness",
    label: "Loss of consciousness",
    iswarning: true,
    warning: "",
  },
  {
    value: "Male problems",
    label: "Male problems",
    iswarning: false,
    warning: "",
  },
  {
    value: "Nausea, vomiting",
    label: "Nausea, vomiting",
    iswarning: false,
    warning: "",
  },
  {
    value: "Shortness of breath",
    label: "Shortness of breath",
    iswarning: true,
    warning: "",
  },
  {
    value: "Sinus congestion",
    label: "Sinus congestion",
    iswarning: false,
    warning: "",
  },
  {
    value: "Skin rash",
    label: "Skin rash",
    iswarning: false,
    warning: "",
  },
  {
    value: "Sore throat",
    label: "Sore throat",
    iswarning: false,
    warning: "",
  },
  {
    value: "Tired",
    label: "Tired",
    iswarning: false,
    warning: "",
  },
  {
    value: "Urinary problems",
    label: "Urinary problems",
    iswarning: false,
    warning: "",
  },
  {
    value: "Weak",
    label: "Weak",
    iswarning: false,
    warning: "",
  },
  {
    value: "Multiple",
    label: "Multiple",
    iswarning: false,
    warning: "",
  },
];
