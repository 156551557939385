import React, { Fragment, useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";

import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { AvForm, AvField } from "availity-reactstrap-validation";
import debounce from "lodash/debounce";
import {
  CountryDropdown,
  RegionDropdown,
  // CountryRegionData,
} from "react-country-region-selector";

import {
  updateConsultStepwise,
  getSingleConsultation,
  getMemberAndDependents,
  getDraftConsultation,
} from "../../../store/actions";

import {
  // dctGetMemberAndDependPastData,
  loadingMessage,
  dctClsAddRemove,
  dctAlert,
  capitalizeWords,
} from "../../../helpers/Commons";

import { dctGetAsyncData } from "../../../helpers/backend_helper";

import ConsultFormFooter from "./consultFormFooter";
import { Config } from "../../../Config";

function State(props) {
  const [isPrevState, setIsPrevState] = useState(true);
  const [isNewState, setIsNewState] = useState(false);
  const [isStateValidation, setIsStateValidation] = useState(false);

  let memberObj = [];
  memberObj = useSelector((state) => state.Member);
  const { singleConsult } = memberObj;

  useEffect(() => {
    props.pageTitle("State of Residence");
  }, [props]);

  // Load the fetched State data for the select dropdown
  const loadStateOptions = (inputValue, callback) => {
    var apiUrl =
      process.env.REACT_APP_API_PATH +
      "/state?populate=false&select=name,abbreviation";
    const axiosArgs = {
      whereCondition: { where: { name: { contains: `${inputValue}` } } },
      URL: apiUrl,
    };
    dctGetAsyncData(axiosArgs).then((results) => callback(results));
    return;
  };
  // const stateListOptions = debounce(loadStateOptions, 250);
  const [stateName, setStateName] = useState("");
  const [countryName, setCountryName] = useState("");

  //Update the consultation
  function handleValidSubmit(event, values) {
    setIsStateValidation(false);
    if (isPrevState) {
      values.state_currentState = singleConsult.state_currentState;
      values.state_country = singleConsult.state_country;
    }
    if (isNewState) {
      if (!stateName) {
        setIsStateValidation(true);
        return;
      }
      values.state_currentState = stateName;
      values.state_country = countryName;
    }
    dctClsAddRemove("dr-form-spinner", "d-none", "remove");
    props.updateConsultStepwise(values, props.history, 4);
  }

  return (
    <Fragment>
      <AvForm
        className="form-horizontal pl-2 pr-2"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        {!singleConsult ? (
          <div className="mt-1 mb-3">{loadingMessage("Loading...")}</div>
        ) : null}

        {isStateValidation ? dctAlert(3, "Please select State") : ""}

        <Row>
          <Col lg="12">
            {!isNewState ? (
              <div>
                <div className="mt-0">
                  <div className="mb-3 font-weight-medium">{`Is ${
                    singleConsult ? capitalizeWords(singleConsult.patient) : null
                  }  currently in ${
                    singleConsult && singleConsult.state_currentState
                      ? singleConsult.state_currentState
                      : ""
                  }?`}</div>
                </div>
              </div>
            ) : (
              <div className="mt-0">
                <div className="mb-3 font-weight-medium">
                  {props.t("What State is ")}{" "}
                  <strong>
                    {singleConsult ? singleConsult.patient : null}
                  </strong>
                  {props.t(" currently located in?")}
                </div>
                <Row>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col lg={12}>
                        <div className={`form-group ${Config.names.slugName}-select-box`}>
                          <label htmlFor="state">{props.t("Country")}</label>
                          <CountryDropdown
                            value={countryName}
                            labelType="short"
                            valueType="short"
                            whitelist={["US", "MX"]}
                            classes="form-control"
                            onChange={(val) => {
                              setCountryName(val);
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className={`form-group ${Config.names.slugName}-select-box`}>
                          <label htmlFor="state">{props.t("State")}</label>
                          <RegionDropdown
                            defaultOptionLabel={"Select State"}
                            country={countryName}
                            countryValueType="short"
                            labelType="full"
                            valueType="short"
                            value={stateName}
                            classes="form-control"
                            onChange={(val) => {
                              setStateName(val);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} sm={12}></Col>
                </Row>
              </div>
            )}
            <AvField name="step" type="hidden" value="4" />
            <AvField
              name="id"
              type="hidden"
              value={singleConsult ? singleConsult.id : ""}
            />
            <AvField name="stepType" type="hidden" value="state" />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            {!isNewState ? (
              <Button type="submit" color="primary" className="mr-1">
                {props.t("Yes")}
              </Button>
            ) : (
              " "
            )}

            {isNewState ? (
              <Button
                type="reset"
                color="secondary"
                onClick={() => {
                  setIsNewState(!isNewState);
                  setIsStateValidation(false);
                }}
              >
                {props.t("Back")}
              </Button>
            ) : (
              <Button
                type="reset"
                color="secondary"
                onClick={() => {
                  setIsNewState(true);
                  setIsStateValidation(false);
                }}
              >
                {props.t("No")}
              </Button>
            )}
          </Col>
          <Col xs={6}></Col>
        </Row>
        <Row>
          <Col lg={12}>
            <ConsultFormFooter />
          </Col>
        </Row>
      </AvForm>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const {
    // memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  } = state.Member;
  return {
    // memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    updateConsultStepwise,
    getSingleConsultation,
    getMemberAndDependents,
    getDraftConsultation,
  })(withNamespaces()(State))
);
