import React, { useState, useEffect, Fragment } from "react";
// Redux
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Tabs from "react-responsive-tabs";
import "react-responsive-tabs/styles.css";

import { Row, Col, Container } from "reactstrap";

import PersonalInfo from "./AccountDetailForm/PersonalInfo";
import DependentsInfo from "./AccountDetailForm/DependentsInfo";
import PasswordManagement from "../Authentication/PasswordManagement";
import SearchPharmacy from "../../components/Pharmacy/SearchPharmacy";
import DctHeroBanner from "../../components/Common/DctHeroBanner";
import LanguageChange from "./AccountDetailForm/LanguageChange";

import { getSingleMember } from "../../store/actions";
import { Config } from "../../Config";

const MyAccount = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { authMemberId } = useSelector((state) => state.Login);

  let memberObj = [];
  memberObj = useSelector((state) => state.Member);
  const { singleMember } = memberObj;
  let isDoseSpotPharmacy = true;
  isDoseSpotPharmacy =
    singleMember && singleMember.country === "US" ? true : false;
  useEffect(() => {
    props.getSingleMember(authMemberId);

    let tabPage = "";
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      tabPage = typeof split_url[3] !== "undefined" ? split_url[3] : 0;
    }

    switch (tabPage) {
      case "personalinfo":
        setActiveTab(0);
        break;

      case "dependents":
        setActiveTab(1);
        break;

      case "pharmacy":
        setActiveTab(2);
        break;

      case "password":
        setActiveTab(3);
        break;

      default:
        setActiveTab(0);
        break;
    }
  }, []);

  const tabChange = (tabKey) => {
    switch (tabKey) {
      case 0:
        props.history.push("/member/my-account/personalinfo");
        break;

      case 1:
        props.history.push("/member/my-account/dependents");
        break;

      case 2:
        props.history.push("/member/my-account/pharmacy");
        break;

      case 3:
        props.history.push("/member/my-account/password");
        break;
      case 4:
        props.history.push("/member/my-account/change-language");
        if (window.ReactNativeWebView)
        window.ReactNativeWebView.postMessage("CHANGE_LANG");
        break;

      default:
        props.history.push("/member/my-account");
        break;
    }
  };
  const dctProfileTabs = [
    { name: props.t(`Personal Info`), tabComponent: <PersonalInfo /> },
    {
      name: props.t(`Dependents`),
      tabComponent: <DependentsInfo />,
    },
    {
      name: props.t(`Pharmacy`),
      tabComponent: <SearchPharmacy _isDoseSpotPharmacy={isDoseSpotPharmacy} />,
    },
    {
      name: props.t(`Password Management`),
      tabComponent: <PasswordManagement />,
    },
    {
      name: props.t(`Change Language`),
      tabComponent: <LanguageChange />,
    },
  ];

  function getProfileTabs() {
    let data = dctProfileTabs.map((dctProfileTab, index) => ({
      title: dctProfileTab.name,
      getContent: () => dctProfileTab.tabComponent,
      key: index,
      showMore: true,
      tabClassName: "tab btn btn-outline-primary",
      panelClassName: "panel pl-2 pr-2",
    }));
    if(!window.ReactNativeWebView) {
      data.pop();
    }
    return data;
  }


  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          {window.ReactNativeWebView ? (
            ""
          ) : (
            <Row>
              <Col xl={12}>
                <DctHeroBanner
                  mainTitle={props.t("Account Details")}
                  subTitle={props.t("Manage Your Account")}
                  isInfoBar={true}
                  infoBarTitle=""
                  isCall911={true}
                ></DctHeroBanner>
              </Col>
            </Row>
          )}
          <Row className="mt-0">
            <Col xl={12}>
              <p className="mb-4">
                {props.t(
                  `To ensure that your consultation experience goes as smoothly as possible, please update your account information periodically.`
                )}{" "}
              </p>
              <div className={`${Config.names.slugName}-tab-container`}>
                <Tabs
                  items={getProfileTabs()}
                  selectedTabKey={activeTab}
                  onChange={tabChange}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { singleMember } = state.Member;
  return { singleMember };
};

// export default withRouter(withNamespaces()(MyAccount));

export default withRouter(
  connect(mapStatetoProps, { getSingleMember })(
    withNamespaces()(MyAccount)
  )
);
