import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { CREATE_GROUP, UPDATE_GROUP, GET_GROUP_LIST } from "./actionTypes";
import {
    groupActionSuccessful,
    setSingleGroup,
    createGroupFailed,
    setGroupList,
} from "./actions";

//Include Both Helper File with needed methods
import {
    dctPostRawData,
    dctPutRawData,
    // getGroupListObj,
    dctGetRawData,
} from "../../helpers/backend_helper";
import { Config } from "../../Config";


// Create Group
function* createGroupSaga({ payload: { group, history } }) {
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true

    try {
        const group_data = group;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: {
                Authorization: bearer_token,
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN
            },
        };
        const response = yield call(
            dctPostRawData,
            process.env.REACT_APP_API_PATH + "/group",
            group_data,
            headers
        );

        document
            .getElementsByClassName("dr-gform-spinner")[0]
            .classList.add("d-none");
        document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false

        if (response.id) {
            yield put(
                groupActionSuccessful(response, "Group Created Successfully #1.", 1)
            );
            yield put(
                setSingleGroup(response, "Group Created Successfully #2.", 1),
            );
            if (document.querySelector(`.${Config.names.slugName}-group-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-group-add-edit-modal .close`).click();
            }
        } else {
            yield put(createGroupFailed(response.message));
            document
                .getElementsByClassName("dr-gform-spinner")[0]
                .classList.add("d-none");
            document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false

        }
    } catch (error) {
        yield put(createGroupFailed(error));
        document
            .getElementsByClassName("dr-gform-spinner")[0]
            .classList.add("d-none");
        document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    }
}

// Update Group
function* updateGroupSaga({ payload: { group, history } }) {
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
    try {
        const group_data = group;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: {
                Authorization: bearer_token,
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN
            },
        };
        const response = yield call(
            dctPutRawData,
            process.env.REACT_APP_API_PATH + "/group/" + group_data.id,
            group_data,
            headers
        );

        document
            .getElementsByClassName("dr-gform-spinner")[0]
            .classList.add("d-none");
        document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false

        if (response.id) {
            yield put(
                groupActionSuccessful(response, "Group Updated Successfully. #1", 2),
            );
            yield put(
                setSingleGroup(response, "Group Updated Successfully #2.", 2),
            );

            if (document.querySelector(`.${Config.names.slugName}-group-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-group-add-edit-modal .close`).click();
            }
        } else {
            yield put(createGroupFailed(response.message));
        }
    } catch (error) {
        yield put(createGroupFailed(error));
    }
}

// Get Group
function* getGroupListSaga({ payload: { group } }) {
    try {
        // const group_data = group;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: {
                Authorization: bearer_token,
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN
            },
        };

        const response = yield call(
            dctGetRawData,
            process.env.REACT_APP_API_PATH + "/group",
            headers
        );

        if (response.id) {
            yield put(
                setGroupList(
                    response.result.data,
                    "Group data fetched Successfully.",
                    ""
                )
            );
        } else {
            yield put(createGroupFailed(response.message));
        }
    } catch (error) {
        yield put(createGroupFailed(error));
    }
}

export function* watchCreateGroup() {
    yield takeEvery(CREATE_GROUP, createGroupSaga);
}

export function* watchUpdateGroup() {
    yield takeEvery(UPDATE_GROUP, updateGroupSaga);
}
export function* watchGetGroup() {
    yield takeEvery(GET_GROUP_LIST, getGroupListSaga);
}

function* groupSaga() {
    yield all([
        fork(watchCreateGroup),
        fork(watchUpdateGroup),
        fork(watchGetGroup),
    ]);
}

export default groupSaga;
