import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Config } from '../../../Config';

    const DountChart = (props) => {

        const data = {
            labels: [
                "Desktops",
                "Tablets"
            ],
            datasets: [
                {
                    data: [300, 210],
                    backgroundColor: [
                        `${Config.colors.primaryColor}`,
                        "#ebeff2"
                    ],
                    hoverBackgroundColor: [
                        `${Config.colors.primaryColor}`,
                        "#ebeff2"
                    ],
                    hoverBorderColor: "#fff"
                }]
        };

      return (
              <Doughnut width={474} height={260} data={data}/>
          );
    }

export default DountChart;