import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  CardBody,
  Alert,
  Container,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import login from "../../store/auth/login/reducer";
import { Config } from "../../Config";
import axios from "axios";
import AlertMessage from "./Components/Alerts/AlertMessage";
// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link, Redirect, useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { loginUser, apiError, setAuthToken } from "../../store/actions";
import { phoneFormatter } from "./service/phoneFormatter";

const ResetPassword = () => {
  const history = useHistory();

  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState({
    errorNewPassword: false,
    errorConfirmPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    message: "",
  });

  const [isResponse, setIsResponse] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    let token = history?.location?.pathname.split("/").pop();
    let url = `${process.env.REACT_APP_API_PATH}/auth/get-user-email`;
    if (!token || token === "") {
      setAlertData({
        ...alertData,
        type: "error",
        title: "Error",
        message: "Token is missing in the URL.",
      });
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    } else {
      setToken(token.toString());
      axios
        .post(url, { token: token })
        .then((response) => {
          if (response.data.success === true) {
            setIsResponse(true);
            setData(response.data.user.email);
          }
        })
        .catch((err) => {
          setIsResponse(false);
          console.log("get user email error------", err.response);
        });
    }
  }, []);

  const HandleSubmit = (e) => {
    e.preventDefault();

    if (!password.newPassword) {
      setPasswordError({ ...passwordError, errorNewPassword: true });
      return;
    } else if (
      !/(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/.test(
        password.newPassword
      )
    ) {
      setPasswordError({ ...passwordError, errorNewPassword: true });
      return;
    } else if (password.newPassword != password.confirmPassword) {
      setPasswordError({ ...passwordError, errorConfirmPassword: true });
      return;
    }

    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_PATH + "/auth/reset-password", {
        resetLink: token,
        newPass: password.confirmPassword,
      })
      .then((res) => {
        console.log("response", res);
        setLoading(false);
        if (res.status === 200 && res.data.success === true) {
          setAlertData({
            ...alertData,
            type: "success",
            title: "Success",
            message:
              "Password successfully changed. It will redirect to login page",
          });
          setShowAlert(true);
          setTimeout(() => history.push("/login"), 4000);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
        if (err.response.status === 404) {
          setAlertData({
            ...alertData,
            type: "error",
            title: "Error",
            message: err.response.statusText,
          });
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 8000);
        } else if (err.response.status === 400) {
          setAlertData({
            ...alertData,
            type: "error",
            title: "Error",
            message: "User with this token does not exist",
          });
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 8000);
        } else {
          setAlertData({
            ...alertData,
            type: "error",
            title: "Error",
            message: err.response.data.message,
          });
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 8000);
        }
      });
  };

  return (
    <>
      <React.Fragment>
        <div
          className={`py-5 bg-black app-${process.env.REACT_APP_SLUG} global-gradient`}
          style={{ minHeight: "100vh", height: "100%", marginTop: 0 }}
        >
          <div className="account-pages my-5 pt-sm-2">
            <Container className={`${Config.names.slugName}-container-sm`}>
              <Row className="justify-content-center mt-5">
                <Col
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img
                    src={Config.logos.logoDark}
                    alt=""
                    style={{ width: "14rem" }}
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center">
                <Col lg="5" md="6" sm="8" xs="10" className="p-0">
                  <AlertMessage
                    forgot={false}
                    login={true}
                    show={showAlert}
                    type={alertData.type}
                    title={alertData.title}
                    message={alertData.message}
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-center align-items-center">
                <Col
                  lg="5"
                  md="6"
                  sm="8"
                  xs="10"
                  className={`${Config.names.slugName}-box-shadow rounded-lg mt-5`}
                >
                  <div className="py-5 px-3">
                    <h5 className="text-primary font-weight-bold">
                      SET PASSWORD
                    </h5>
                    <p className="mt-3 font-size-14 font-weight-light">
                      You are setting up a new password for your account under
                      the following email:
                    </p>

                    {isResponse && (
                      <>
                        <p className="font-size-14 font-weight-light mt-3">
                          Account Email:{" "}
                          <span className="text-primary font-weight-normal">
                            {data}
                          </span>
                        </p>

                        <div
                          style={{
                            background: "#EEF1FF",
                            paddingInline: "1rem",
                            color: "#A8A8A8",
                            paddingBlock: "0.2rem",
                          }}
                          className="rounded"
                        >
                          <p style={{ paddingTop: "0.8rem" }}>
                            Don't recognize this email?
                          </p>
                          <p>
                            Please contact support at
                            {phoneFormatter(Config.names.supportContact, 1)}
                          </p>
                        </div>
                      </>
                    )}

                    <Form className="mt-4" onSubmit={(e) => HandleSubmit(e)}>
                      <label className="text-secondary">New Password</label>
                      <input
                        type="password"
                        placeholder="Enter new password"
                        value={password.newPassword}
                        onChange={(e) => {
                          setPassword({
                            ...password,
                            newPassword: e.target.value,
                          });
                          setPasswordError({
                            ...passwordError,
                            errorNewPassword: false,
                          });
                        }}
                        className={`form-control ${
                          passwordError.errorNewPassword
                            ? "border-danger mb-2"
                            : "mb-4"
                        }`}
                      />

                      {passwordError.errorNewPassword && (
                        <p className="text-danger font-weight-light font-italic font-size-12">
                          *Password must be a minimum of 8 characters and
                          contain at least 3 of the following: uppercase,
                          lowercase, numeric or special characters.
                        </p>
                      )}

                      <label className="text-secondary">Confirm Password</label>
                      <input
                        type="password"
                        placeholder="Confirm new password"
                        value={password.confirmPassword}
                        onChange={(e) => {
                          setPassword({
                            ...password,
                            confirmPassword: e.target.value,
                          });
                          setPasswordError({
                            ...passwordError,
                            errorConfirmPassword: false,
                          });
                        }}
                        className={`form-control ${
                          passwordError.errorConfirmPassword
                            ? "border-danger mb-2"
                            : "mb-4"
                        }`}
                      />
                      {passwordError.errorConfirmPassword && (
                        <p className="text-danger font-weight-light font-italic font-size-12">
                          *Password must match the new password entered above.
                        </p>
                      )}

                      <button
                        type="submit"
                        className="btn btn-primary mt-3 w-100"
                      >
                        {loading ? (
                          <>
                            <span className="dr-mform-spinner">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>
                            </span>
                          </>
                        ) : (
                          "Save New Password"
                        )}
                      </button>
                    </Form>
                  </div>
                </Col>
              </Row>

              <Row className="d-flex justify-content-center mt-4">
                <Link
                  to={"/login"}
                  className="text-primary font-weight-bold font-size-13"
                  style={{ wordSpacing: "2px" }}
                >
                  Click Here to Sign In
                </Link>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default ResetPassword;
