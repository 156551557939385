import React, { useState, useEffect } from "react";
import axios from "axios";
// import { checkAjaxError } from "../../helpers/backend_helper";

const { Twilio, Device } = require("twilio-client");
const VoiceCall = (props) => {
  let device;
  const [token, setToken] = useState([]);

  const getVoiceToken = () => {
    // let bearer_token = localStorage.getItem("auth_token");
    // const headers = {
    //   headers: {
    //     Authorization: bearer_token,
    //     PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
    //   },
    // }; 

    // let url = process.env.REACT_APP_API_PATH + `/voice-token`;
    // axios
    //   .get(url, headers)
    //   .then(function (response) {
    //     const data = response.data;
    //     //console.log(response);
    //     let message = "";
    //     message = data.length > 0 ? "" : "No record found.";
    //   })
    //   .catch(function (err) {
    //     var message = checkAjaxError(err);
    //     if (err.response) {
    //       if (err.response.status === 400) {
    //         message = err.response.data.cause.details;
    //       }
    //     }
    //   })
    //   .then(function () {});
  };
  useEffect(() => {
    // getVoiceToken();
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const data ={
      identity:"SKS"
  
    }
    const v = (window.location.pathname).split('/');
    const authUser = JSON.parse(localStorage.getItem("auth_user"));
    const authDoctorId = authUser?.doctorId;

    let url = `${process.env.REACT_APP_API_PATH}/consultation/${v[v.length - 1]}/voice-token?doctorId=${authDoctorId}`;
    
    axios
      .get(url,headers)
      .then((response) => {

        document.getElementById(
          "button-call-" + props.addtionalID
        ).style.display = "none";
        document.getElementById(
          "button-hangup-" + props.addtionalID
        ).style.display = "none";
        // Setup Twilio.Device
        device = new Device(response.data.token, {
          codecPreferences: ["opus", "pcmu"],
          fakeLocalDTMF: true,
          enableRingingState: true,
        });

        device.on("ready", function (device) {
          document.getElementById(
            "button-call-" + props.addtionalID
          ).style.display = "inline";
          document.getElementById(
            "button-hangup-" + props.addtionalID
          ).style.display = "none";
        });

        device.on("error", function (error) {
          document.getElementById(
            "button-call-" + props.addtionalID
          ).style.display = "none";
          document.getElementById(
            "button-hangup-" + props.addtionalID
          ).style.display = "none";
        });


        device.on("connect", function (conn) {
          document.getElementById(
            "button-call-" + props.addtionalID
          ).style.display = "none";
          document.getElementById(
            "button-hangup-" + props.addtionalID
          ).style.display = "inline";

          // bindVolumeIndicators(conn);
        });

        device.on("disconnect", function (conn) {
          document.getElementById(
            "button-call-" + props.addtionalID
          ).style.display = "inline";
          document.getElementById(
            "button-hangup-" + props.addtionalID
          ).style.display = "none";
        });

        document.getElementById(
          "button-call-" + props.addtionalID
        ).onclick = function () {
          // get the phone number to connect the call to
          var params = {
            To: props.phone,
          };

          if (device) {
            var outgoingConnection = device.connect(params);
            outgoingConnection.on("ringing", function () {

              document.getElementById(
                "button-call-" + props.addtionalID
              ).style.display = "none";
              document.getElementById(
                "button-hangup-" + props.addtionalID
              ).style.display = "inline";
    
            });
          }
        };

        // Bind button to hangup call
        document.getElementById(
          "button-hangup-" + props.addtionalID
        ).onclick = function () {
          if (device) {
            device.disconnectAll();
          }
        };
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {/* <i className="bx bx-phone ml-1 text-success"></i> */}
      <button
        id={"button-call-" + props.addtionalID}
        className="btn btn-success"
        style={{ display: "none" }}
      >
        <i className="fas fa-phone"></i> Start Call
      </button>
      <button
        id={"button-hangup-" + props.addtionalID}
        className="btn btn-danger"
        style={{ display: "none" }}
      >
        <i className="fas fa-phone-slash"></i> Hang Up
      </button>
    </div>
  );
};

export default VoiceCall;
