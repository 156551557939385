import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SET_AUTH_TOKEN,
} from "./actionTypes";

let auth_token = null;
let authUser = null;
authUser = localStorage.getItem("auth_user");
auth_token = localStorage.getItem("auth_token");
authUser = JSON.parse(authUser);

const initialState = {
  error: "",
  loading: false,
  auth_token: auth_token,
  authUser: authUser,
  authUserType: authUser?.userType,
  authUserId: authUser?.id,
  authMemberId: authUser?.memberId ? authUser.memberId : "undefined",
  authDoctorId: authUser?.doctorId ? authUser.doctorId : "undefined",
  authPartnerId: authUser?.partnerId,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case SET_AUTH_TOKEN:
      // console.log('from reducer----', action);
      state = {
        ...state,
        login_info: action.loginInfo,
        auth_token: action.payload,
        authUser: action.loginInfo,
        authUserType: action.loginInfo.userType,
        authUserId: action.loginInfo.id,
        authMemberId: action.loginInfo.memberId ? action.loginInfo.memberId : "undefined",
        authDoctorId: action.loginInfo.doctorId ? action.loginInfo.doctorId : "undefined",
        authPartnerId: action.loginInfo.partnerId,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
