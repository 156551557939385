import {
  CREATE_MEMBER,
  UPDATE_MEMBER,
  GET_MEMBER,
  GET_MEMBER_LIST,
  SET_MEMBER_LIST,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILED,
  API_ERROR,
  SET_SINGLE_MEMBER,
  SET_MEMBER_LISTING,
  GET_SINGLE_MEMBER,
  GET_MEMBER_AND_DEPENDENTS,
  SET_MEMBER_AND_DEPENDENTS,
  GET_MEMBER_TAB_LIST,
  SET_MEMBER_TAB_LIST,
  CREATE_DEPENDENT,
  UPDATE_DEPENDENT,
  CREATE_DEPENDENT_SUCCESS,
  CREATE_DEPENDENT_FAILED,
  CREATE_CONSULTATION,
  UPDATE_CONSULTATION,
  UPDATE_CONSULTATION_STEPWISE,
  SET_SINGLE_CONSULTATION,
  GET_SINGLE_CONSULTATION,
  CONSULTATION_MSG,
  GET_DRAFT_CONSULTATION,
  SET_DRAFT_CONSULTATION,
  SET_MY_SINGLE_CONSULTATION,
  SET_CONSULTATION_LISTING,
  SCHEDULED_CONSULTATION_LIST,
  SET_DEPENDENT_DATA,
} from "./actionTypes";

export const setSceduleConsultation = (data) => ({
  type: SCHEDULED_CONSULTATION_LIST,
  payload: data,
});

export const setConsultationMsg = (message, msgType) => ({
  type: CONSULTATION_MSG,
  payload: message,
  msgType: msgType,
});

export const createConsultation = (consultation, history, step) => ({
  type: CREATE_CONSULTATION,
  payload: { consultation, history, step },
});

export const updateConsultation = (consultation, history) => ({
  type: UPDATE_CONSULTATION,
  payload: { consultation, history },
});

export const updateConsultStepwise = (consultation, history, step) => ({
  type: UPDATE_CONSULTATION_STEPWISE,
  payload: { consultation, history, step },
});

export const getSingleConsultation = (consultationId) => ({
  type: GET_SINGLE_CONSULTATION,
  payload: { consultationId },
});

export const getDraftConsultation = (memberId) => ({
  type: GET_DRAFT_CONSULTATION,
  payload: { memberId },
});

export const setDraftConsultation = (consultation, message) => {
  return {
    type: SET_DRAFT_CONSULTATION,
    payload: consultation,
    message: message,
  };
};

export const setSingleConsultation = (consultation, message) => {
  return {
    type: SET_SINGLE_CONSULTATION,
    payload: consultation,
    message: message,
  };
};

export const createMember = (member) => ({
  type: CREATE_MEMBER,
  payload: { member },
});

export const updateMember = (member) => ({
  type: UPDATE_MEMBER,
  payload: { member },
});

export const getMember = (member) => ({
  type: GET_MEMBER,
  payload: { member },
});

export const getMemberList = (member) => ({
  type: GET_MEMBER_LIST,
  payload: { member },
});

export const getSingleMember = (memberId) => ({
  type: GET_SINGLE_MEMBER,
  payload: { memberId },
});
export const getMemberAndDependents = (memberId) => ({
  type: GET_MEMBER_AND_DEPENDENTS,
  payload: { memberId },
});
export const getMemberTabList = (memberId) => ({
  type: GET_MEMBER_TAB_LIST,
  payload: { memberId },
});
export const setMemberList = (member, message, action) => {
  return {
    type: SET_MEMBER_LIST,
    payload: member,
    message: message,
    action_type: action,
  };
};

export const setSingleMember = (member, message, action) => {
  return {
    type: SET_SINGLE_MEMBER,
    payload: member,
    message: message,
    action_type: action,
  };
};
export const setMemberAndDependents = (
  memberAndDependents,
  message,
  action
) => {
  return {
    type: SET_MEMBER_AND_DEPENDENTS,
    payload: memberAndDependents,
    message: message,
    action_type: action,
  };
};

export const setMemberTabList = (tabList, message, action) => {
  return {
    type: SET_MEMBER_TAB_LIST,
    payload: tabList,
    message: message,
    action_type: action,
  };
};

export const setMemberListing = (member, message) => {
  return {
    type: SET_MEMBER_LISTING,
    payload: member,
    memberListing: member,
    message: message,
  };
};

export const memberActionSuccessful = (member, message, action) => {
  return {
    type: CREATE_MEMBER_SUCCESS,
    payload: member,
    message: message,
    action_type: action,
  };
};

export const createMemberFailed = (error) => {
  return {
    type: CREATE_MEMBER_FAILED,
    payload: error,
  };
};

export const createDependent = (dependent) => ({
  type: CREATE_DEPENDENT,
  payload: { dependent },
});

export const updateDependent = (dependent) => ({
  type: UPDATE_DEPENDENT,
  payload: { dependent },
});

export const dependentActionSuccessful = (dependent, message, action) => {
  return {
    type: CREATE_DEPENDENT_SUCCESS,
    payload: dependent,
    depMessage: message,
    action_type: action,
  };
};

export const createDependentFailed = (error) => {
  return {
    type: CREATE_DEPENDENT_FAILED,
    payload: error,
  };
};

export const setConsultationListing = (consult, message) => {
  return {
    type: SET_CONSULTATION_LISTING,
    payload: consult,
    message: message,
  };
};
export const setMySingleConsult = (consult, message, action) => {
  return {
    type: SET_MY_SINGLE_CONSULTATION,
    payload: consult,
    message: message,
    action_type: action,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

//show dependent data

export const showDependentData = (member, message, action) => {
  return {
    type: SET_DEPENDENT_DATA,
    payload: member,
    message: message,
    action_type: action,
  };
};
