import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Badge,
  Alert,
} from "reactstrap";

// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { connect, useSelector, useDispatch } from "react-redux";

import { groupActionSuccessful, setSingleGroup } from "../../store/actions";

import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

//Include Both Helper File with needed methods
import { loadingMessage, dctDateFormat } from "../../helpers/Commons";

import axios from "axios";

//Import Create and Update Group
import CreateUpdateGroup from "../../components/Group/CreateUpdateGroup";
// import CreateUpdateCompany from "../../components/Group/CreateUpdateCompany";
import Company from "./Company/company";

const GroupsOverview = (props) => {
  const [groupData, setGroupData] = useState({
    gloading: true,
    gdata: null,
    gid: null,
    gmessage: null,
  });

  const dispatch = useDispatch();

  const fetchSingleGroup = (goup_id) => {
    if (!goup_id) {
      return;
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = process.env.REACT_APP_API_PATH + "/group/" + goup_id;

    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data;
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(setSingleGroup(response_data, message, null));
        setGroupData({
          gloading: false,
          gdata: response_data,
          gid: goup_id,

          gmessage: message,
        });
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response.status === 400) {
          message = "No record found.";
        }
        setGroupData({
          gloading: false,
          gdata: null,
          gid: null,
          gmessage: message,
        });
        dispatch(setSingleGroup(null, message));
      })
      .then(function () { });
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let goup_id = "";

    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    fetchSingleGroup(goup_id);
  }, []);

  const propsUpdateGroupData = () => { };

  const { gloading, gid, gmessage } = groupData;
  const gdata = useSelector((state) => state.Group.singleGroup);
  console.log("gdata", gdata)

  const backToGroupListLink = () => {
    return (
      <Link to="/groups" className="btn btn-info waves-effect waves-light mr-2">
        <i className="mdi mdi-arrow-left mr-1"></i> {props.t("Back to List")}
      </Link>
    );
  };

  // const companyList = (companyList) => {
  //   return companyList.length > 0 ? (
  //     companyList.map((company, index) => {
  //       return (
  //         <Tr key={index} data-row-id={company.id}>
  //           <Td>
  //             <div className="avatar-xs">
  //               <Link
  //                 to={"/companies-overview/id/" + company.id}
  //                 className="text-dark"
  //               >
  //                 <span className="avatar-title rounded-circle">
  //                   {company.companyName[0]}
  //                 </span>
  //               </Link>
  //             </div>
  //           </Td>
  //           <Td>
  //             <h5 className="text-truncate font-size-14">
  //               <Link
  //                 to={"/companies-overview/id/" + company.id}
  //                 className="text-dark"
  //               >
  //                 {company.companyName}
  //               </Link>
  //             </h5>
  //           </Td>
  //           <Td>{company.companyEmail}</Td>
  //           <Td>{company.companyPhone}</Td>
  //           <Td>{company.companyType}</Td>
  //           <Td>
  //             <Link
  //               to="#"
  //               className={
  //                 company.status === "active"
  //                   ? "badge badge-soft-primary font-size-11 m-1"
  //                   : "badge badge-soft-danger font-size-11 m-1"
  //               }
  //             >
  //               {company.status === "active" ? "Active" : "Inactive"}
  //             </Link>
  //           </Td>
  //           {/* <Td>
  //             <ul className="list-inline font-size-20 contact-links mb-0">
  //               <li className="list-inline-item px-2">
  //                 <Link
  //                   to="#"
  //                   id={"edit" + company.id}
  //                   onClick={() => {
  //                     // opencompanyEditModal(company);
  //                   }}
  //                 >
  //                   <i className="bx bx-edit-alt"></i>
  //                   <UncontrolledTooltip
  //                     placement="top"
  //                     target={"edit" + company.id}
  //                   >
  //                     {props.t("Edit")}
  //                   </UncontrolledTooltip>
  //                 </Link>
  //               </li>
  //               <li className="list-inline-item px-2">
  //                 <Link
  //                   to="#"
  //                   id={"status_" + company.id}
  //                   data-status={company.status}
  //                   data-id={company.id}
  //                   onClick={() => {
  //                     // setconfirm_both(true);
  //                     // setcompanyStatusMeta({
  //                     //   rowIndex: index,
  //                     //   companyId: company.id,
  //                     //   companyStatus: company.status,
  //                     // });
  //                   }}
  //                 >
  //                   {company.status === "active" ? (
  //                     <i className="bx bx-user-check text-success"></i>
  //                   ) : (
  //                     <i className="bx bx-user-x text-danger"></i>
  //                   )}
  //                   <UncontrolledTooltip
  //                     placement="top"
  //                     target={"status_" + company.id}
  //                   >
  //                     {company.status ? "Active" : "Inactive"}
  //                   </UncontrolledTooltip>
  //                 </Link>
  //               </li>
  //             </ul>
  //             {confirm_both ? (
  //               <SweetAlert
  //                 title={props.t("Are you sure?")}
  //                 warning
  //                 showCancel
  //                 confirmBtnBsStyle="success"
  //                 cancelBtnBsStyle="secondary"
  //                 onConfirm={() => {
  //                   toggleDoctorStatus(doctorStatusMeta);
  //                 }}
  //                 onCancel={() => {
  //                   setconfirm_both(false);
  //                   setsuccess_dlg(false);
  //                 }}
  //               >
  //                 {props.t("This process will change the Doctor status!")}
  //               </SweetAlert>
  //             ) : null}
  //           </Td>*/}
  //         </Tr>
  //       );
  //     })
  //   ) : (
  //     <Tr>
  //       <Td colSpan="7" className="text-center"></Td>
  //     </Tr>
  //   );
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                {gmessage ? (
                  <Alert color="danger" className="text-center">
                    {gmessage}
                  </Alert>
                ) : null}

                {gdata ? (
                  <>
                    <CardBody>
                      <div className="group-details-section" data-id={gdata.id}>
                        <Row>
                          <Col lg="8">
                            <Media>
                              <div className="avatar-sm mr-4">
                                <span className="avatar-title font-size-20 rounded-circle text-capitalize">
                                  {gdata.groupName?.charAt(0)}
                                </span>
                              </div>
                              <Media className="overflow-hidden" body>
                                <h5 className="text-truncate font-size-15">
                                  {gdata.groupName}
                                </h5>
                                <p className="text-muted">
                                  {props.t("Group Status")}:
                                  <Badge
                                    color="primary"
                                    className="font-size-12 ml-2"
                                  >
                                    {gdata.status === "active"
                                      ? "Active"
                                      : "Inactive"}
                                  </Badge>
                                </p>
                              </Media>
                            </Media>
                          </Col>

                          <Col lg="4">
                            <div className="text-right">
                              <CreateUpdateGroup
                                updateGroupDataFlag={propsUpdateGroupData}
                                group_action_type="edit"
                                group_button_type="edit_btn"
                                gdata={gdata}
                                {...props}
                              />
                              {backToGroupListLink()}
                            </div>
                          </Col>
                        </Row>

                        <h5 className="font-size-15 mt-4">
                          {props.t("Group Details")} :
                        </h5>

                        <div className="text-muted mt-4">
                          <Row>
                            <Col lg="6">
                              <p>
                                <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Group Description")}:{" "}
                                </strong>{" "}
                                {gdata.groupDescription}
                              </p>

                              <p>
                                <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                                <strong>{props.t("Archive Group")}: </strong>{" "}
                                {gdata.archiveGroup}
                              </p>
                              <p>
                                <i className="bx bxs-map text-primary mr-1"></i>{" "}
                                <strong>{props.t("Address")}: </strong>{" "}
                                {gdata.address}
                              </p>
                              <p>
                                <i className="bx bx-user text-primary mr-1"></i>{" "}
                                <strong>{props.t("Group Code")}: </strong>{" "}
                                {gdata.groupCode && gdata.groupCode}
                              </p>
                              <p>
                                <i className="bx bx-user text-primary mr-1"></i>{" "}
                                <strong>{props.t("Group Plan Id")}: </strong>{" "}
                                {gdata.planId && gdata.planId}
                              </p>
                            </Col>

                            <Col lg="6">
                              <p>
                                <i className="bx bx-user text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Main Contact Name")} :{" "}
                                </strong>{" "}
                                {gdata.mainContactName}
                              </p>
                              <p>
                                <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Main Contact Email")}:{" "}
                                </strong>{" "}
                                <a
                                  href={"mailto:" + gdata.mainContactEmail}
                                  className="text-dark"
                                >
                                  {gdata.mainContactEmail}
                                </a>
                              </p>

                              <p>
                                <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Main Contact Phone")}:{" "}
                                </strong>{" "}
                                <a
                                  href={"tel:" + gdata.mainContactPhone}
                                  className="text-dark"
                                >
                                  {gdata.mainContactPhone}
                                </a>
                              </p>
                              <p>
                                <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                <strong>{props.t("Secondary Phone")}: </strong>{" "}
                                <a
                                  href={"tel:" + gdata.secondaryPhone}
                                  className="text-dark"
                                >
                                  {gdata.secondaryPhone}
                                </a>
                              </p>
                            </Col>
                          </Row>

                        </div>

                        <Row className="task-dates">
                          <Col sm="6" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14">
                                <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                                {props.t(" Created Date")}
                              </h5>
                              <p className="text-muted mb-0">
                                {dctDateFormat(gdata.createdAt)}
                              </p>
                            </div>
                          </Col>

                          <Col sm="6" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14">
                                <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                                {props.t("Updated Date")}
                              </h5>
                              <p className="text-muted mb-0">
                                {dctDateFormat(gdata.updatedAt)}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>

                    <Company
                      groupId={gdata.id}
                      groupName={gdata.groupName}
                    ></Company>
                  </>
                ) : (
                  <CardBody>
                    {gmessage ? (
                      <div className="text-center">{backToGroupListLink()}</div>
                    ) : (
                      <div className="text-center">
                        {loadingMessage("Loading...")}
                      </div>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { group } = state.Group;
  const { message } = state.Group;
  return { group, message };
};

export default withRouter(
  connect(mapStatetoProps, { groupActionSuccessful, setSingleGroup })(
    withNamespaces()(GroupsOverview)
  )
);
