import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { CREATE_COMPANY, UPDATE_COMPANY, GET_COMPANY_LIST } from "./actionTypes";
import {
    companyActionSuccessful,
    setSingleCompany,
    createCompanyFailed,
    setCompanyList,
} from "./actions";

//Include Both Helper File with needed methods
import {
    dctPostRawData,
    dctPutRawData,
    // getCompanyListObj,
    dctGetRawData,
} from "../../helpers/backend_helper";
import { Config } from "../../Config";

// Create Company
function* createCompanySaga({ payload: { company, history } }) {
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true

    try {
        const company_data = company;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN},
        };
        const response = yield call(
            dctPostRawData,
            process.env.REACT_APP_API_PATH + "/company",
            company_data,
            headers
        );

        document
        .getElementsByClassName("dr-cform-spinner")[0]
        .classList.add("d-none");
        document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false

        if (response.success) {
            yield put(
                companyActionSuccessful(response.result, "Company Created Successfully.", 1)
            );
            yield put(
                setSingleCompany(response.result, "Company Created Successfully.", 1),
            );
            if (document.querySelector(`.${Config.names.slugName}-company-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-company-add-edit-modal .close`).click();
            }
        } else {
            yield put(createCompanyFailed(response.message));
        }
    } catch (error) {
        yield put(createCompanyFailed(error));
    }
}

// Update Company
function* updateCompanySaga({ payload: { company, history } }) {
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true

    try {
        const company_data = company;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,
                PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN },
        };
        
        const response = yield call(
            dctPutRawData,
            process.env.REACT_APP_API_PATH + "/company/"+company_data.id,
            company_data,
            headers
        );

        

        document
        .getElementsByClassName("dr-cform-spinner")[0]
        .classList.add("d-none");
        
        document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false


       
        if (response.id) {
            yield put(
                companyActionSuccessful(response, "Company Updated Successfully. #1", 2),
            );
            yield put(
                setSingleCompany(response, "Company Updated Successfully #2.", 2),
            );
            
            if (document.querySelector(`.${Config.names.slugName}-company-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-company-add-edit-modal .close`).click();
            }
        } else {
            yield put(createCompanyFailed(response.message));
        }
    } catch (error) {
        yield put(createCompanyFailed(error));
    }
}

// Get Company
function* getCompanyListSaga({ payload: { company } }) {
    try {
        // const company_data = company;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,
                PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN  },
        };
        const response = yield call(
            dctGetRawData,
            process.env.REACT_APP_API_PATH + "/company/list",
            headers
        );
        
        if (response.id) {
            yield put(
                setCompanyList(
                response.result.data,
                
                "Company data fetched Successfully.",
                ""
                )
            );
        } else {
            yield put(createCompanyFailed(response.message));
        }
    } catch (error) {
        yield put(createCompanyFailed(error));
    }
}

export function* watchCreateCompany() {
    yield takeEvery(CREATE_COMPANY, createCompanySaga);
}

export function* watchUpdateCompany() {
    yield takeEvery(UPDATE_COMPANY, updateCompanySaga);
}
export function* watchGetCompany() {
    yield takeEvery(GET_COMPANY_LIST, getCompanyListSaga);
}

function* companySaga() {
    yield all([
        fork(watchCreateCompany),
        fork(watchUpdateCompany),
        fork(watchGetCompany),
    ]);
}

export default companySaga;
