import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import AsyncSelect from "react-select/async";

import debounce from "lodash/debounce";

import {
  createCompany,
  updateCompany,
  createCompanyFailed,
  companyActionSuccessful,
  setSingleCompany,
} from "../../../store/actions";

//Include Both Helper File with needed methods
import { dctGetAsyncData } from "../../../helpers/backend_helper";
import { Config } from "../../../Config";

const CreateUpdateCompany = (props) => {
  const [modal, setModal] = useState(false);
  const [msgEnable, setMsgEnable] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(null);

  let companyData = useSelector((state) => state.Company.singleCompany);
  companyData = companyData ? companyData : [];

  useEffect(() => {
    if (companyData.groupId) {
      setSelectedGroup(companyData.groupId);
    }
  }, [companyData]);

  // Load the fetched Company data for the select dropdown
  const loadGroupOptions = (inputValue, callback) => {
    const axiosArgs = {
      params: inputValue,
      URL:
        process.env.REACT_APP_API_PATH +
        "/group/list/?limit=30&fields=groupName&q=" +
        inputValue,
    };
    dctGetAsyncData(axiosArgs).then((results) => callback(results));
    return;
  };
  const groupListOptions = debounce(loadGroupOptions, 250);

  const handleGroupChange = (selectedGroup) => {
    setSelectedGroup(selectedGroup);
    if (!selectedGroup) {
      document.querySelector("#select-group").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-group").classList.remove("text-danger");
  };

  function toggleModalClose(e) {
    if (props.singleCompany) {
      props.updateCompanyDataFlag();
    }
  }

  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-cform-spinner")[0]
      .classList.remove("d-none");

    setMsgEnable(true);

    if (!selectedGroup || selectedGroup.id == "") {
      document.querySelector("#select-group").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-group").classList.remove("text-danger");
    values.groupId = selectedGroup.id;

    //call reducer method
    if (values.actionType === "edit") {
      props.updateCompany(values, props.history);
    } else {
      props.createCompany(values, props.history);
    }
  }

  const dispatch = useDispatch();

  const editFormCompanyItem = (e) => {
    setMsgEnable(false);
    dispatch(companyActionSuccessful(null, "", ""));
  };

  const createFormCompanyItem = (e) => {
    setMsgEnable(false);
    dispatch(companyActionSuccessful(null, "", ""));
    dispatch(setSingleCompany(null, "", ""));
  };

  const action_type = props.company_action_type === "edit" ? "edit" : "create";
  const button_label =
    props.company_action_type === "edit" ? "Submit" : "Create Company";
  const modal_title =
    props.company_action_type === "edit" ? "Update Company" : "Create Company";

  return (
    <React.Fragment>
      {(() => {
        switch (props.company_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="company_update_modal"
                onClick={() => {
                  setModal(!modal);
                  editFormCompanyItem();
                }}
              ></i>
            );
          case "edit_btn":
            return (
              <Button
                type="button"
                color="primary"
                id="company_update_modal"
                className="waves-effect waves-light mr-2"
                onClick={() => {
                  setModal(!modal);
                  editFormCompanyItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1"></i>
                {props.t("Edit ")}
              </Button>
            );
          default:
            return (
              <Link
                onClick={() => {
                  setModal(!modal);
                  createFormCompanyItem();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id="company_create_modal"
              >
                <i className="mdi mdi-plus mr-1"></i>
                {props.t("Create Company ")}
              </Link>
            );
        }
      })()}

      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-company-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.createCompanyError &&
              props.createCompanyError &&
              msgEnable ? (
                <Alert color="danger">{props.createCompanyError}</Alert>
              ) : null}
              {props.message && props.message && msgEnable ? (
                <Alert color="success">{props.message}</Alert>
              ) : null}

              {action_type === "edit" ? (
                <AvField name="id" type="hidden" value={companyData.id} />
              ) : (
                ""
              )}
              <AvField name="actionType" type="hidden" value={action_type} />

              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="companyName"
                      label={props.t("Company Name ")}
                      className="form-control"
                      placeholder={props.t("Company Name")}
                      type="text"
                      required
                      value={companyData.companyName}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group" id="select-group">
                    <label htmlFor="groupId">{props.t("Group")}</label>
                    <AsyncSelect
                      placeholder="Select Group"
                      name="groupId"
                      id="groupId"
                      getOptionLabel={(e) => e.groupName}
                      getOptionValue={(e) => e.id}
                      className={`${Config.names.slugName}-custom-select-box`}
                      cacheOptions
                      defaultOptions
                      loadOptions={groupListOptions}
                      value={selectedGroup}
                      onChange={handleGroupChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="companyType"
                      label={props.t("Company Type")}
                      className="form-control"
                      placeholder={props.t("Company Type")}
                      type="text"
                      required
                      value={companyData.companyType}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="companyEmail"
                      label={props.t("Company Email")}
                      className="form-control"
                      placeholder={props.t("Company Email")}
                      type="email"
                      required
                      value={companyData.companyEmail}
                    />
                  </div>
                </Col>
              </Row>

              {action_type === "edit" ? (
                <React.Fragment>
                  <Row>
                    <Col lg={12}>
                      <div className="text-center mt-3">
                        <h4 className="font-size-14 text-muted mb-0">
                          {props.t("Other Information")}
                        </h4>
                      </div>
                    </Col>
                  </Row>

                  <div className={`mt-2 mb-2 ${Config.names.slugName}-other-section p-4`}>
                    <Row>
                      <Col lg={6}>
                        <div className="form-company">
                          <AvField
                            name="companyPhone"
                            label={props.t("Company Phone")}
                            className="form-control"
                            placeholder={props.t("Company Phone")}
                            type="text"
                            value={companyData.companyPhone}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-company">
                          <AvField
                            name="companyAddress"
                            label={props.t("Company Address")}
                            className="form-control"
                            placeholder={props.t("Company Address")}
                            type="text"
                            value={companyData.companyAddress}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="form-company">
                          <AvField
                            name="companyDetails"
                            label={props.t("Company Details")}
                            className="form-control"
                            placeholder={props.t("Company Details")}
                            type="textarea"
                            value={companyData.companyDetails}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setModal(!modal)}
                    >
                      {props.t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                    >
                      <span className="dr-cform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      {button_label}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createCompanyError } = state.Company;
  const { company } = state.Company;
  const { message } = state.Company;
  return { createCompanyError, company, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createCompany,
    updateCompany,
    createCompanyFailed,
    setSingleCompany,
  })(withNamespaces()(CreateUpdateCompany))
);

// export default withRouter(
//   connect(mapStatetoProps, { getSingleMember })(
//     withNamespaces()(MyAccount)
//   )
