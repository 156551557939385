import React from 'react';

const ActionButton = ({ title, comp,consult, handleClick, modal, ...rest }) => {
  localStorage.setItem("consultId", consult)
  return (
    <button type="button" id={title} title={title} onClick={() => handleClick(title, comp, modal)} {...rest} className="btn btn-danger">
      {/* {title} */}
      Video <i className="bx bxs-video"></i>
    </button>
  );
};

export default ActionButton;
