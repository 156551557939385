import React, { useEffect, useState } from "react";
import { Config } from "../../Config";
import ssoError from "../../assets/images/sso-error.svg";
import { useHistory } from "react-router-dom";
import { loginSuccess, apiError, setAuthToken } from "../../store/auth/login/actions";
import { useDispatch } from "react-redux";

import axios from 'axios';

const SSOAuth = () => {
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const history = useHistory()
  const dispatch = useDispatch();

  const validate = async (token) => {
    let url = `${process.env.REACT_APP_API_PATH}/sso/verify-sso-url`
    // let url = `http://172.16.5.138:1337/sso/verify-sso-url`

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    };

    await axios.get(url, headers).then((response) => {

      localStorage.setItem("auth_user", JSON.stringify(response.data.result.user));
      localStorage.setItem("auth_token", "Bearer " + response.data.result.bearer);

      dispatch(setAuthToken("Bearer " + response.data.result.bearer, response.data.result.user))

      localStorage.setItem("redirect_to_url", "/dashboard");
      const redirectUrl = "/dashboard";

      if (response.data.result.user && redirectUrl !== "/" && response.data.result.user.userType !== "customerservice") {
        history.push(redirectUrl);
      }

      if (response.data.result.user && redirectUrl === "/" && response.data.result.user.userType !== "customerservice") {
        history.push("/dashboard");
      }

      if (response.data.result.user.rx) {
        history.push('/rxprescriptionlist');
      }

      if (response.data.result.user.userType === "customerservice") {
        history.push('/members');
      }

    }).catch((err) => {
      console.log(err);
      setErrMsg("Invalid Authorization Token, Login Failed.");
      setError(true);
    })
  }


  useEffect(() => {
    let token = history?.location?.pathname.split('/').pop()
    validate(token)
  }, [])


  return (
    <>
      <div className="container-fluid h-auto w-auto d-flex justify-content-center">
        <div>
          {error ? (
            <>
              <p className="sso-text text-danger mt-5">{errMsg}</p>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={ssoError}
                  className="mt-4 heart"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>
            </>
          ) : (
            <>
              <p className="sso-text text-primary mt-5">
                Please wait, you are being redirected...
              </p>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={Config.logos.logo}
                  className="mt-4 heart"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SSOAuth;
