import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect, useSelector } from "react-redux";

import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  updateConsultStepwise,
  getSingleConsultation,
  getMemberAndDependents,
  getDraftConsultation,
} from "../../../store/actions";

import {  dctClsAddRemove } from "../../../helpers/Commons";

import ConsultFormFooter from "./consultFormFooter";

function Billing(props) {
  let memberObj = [];
  memberObj = useSelector((state) => state.Member);
  const { singleConsult } = memberObj;

  useEffect(() => {}, []);

  //Update the consultation
  function handleValidSubmit(event, values) {
    if (!values.id) {
      return;
    }
    dctClsAddRemove("dr-form-spinner", "d-none", "remove");
    props.updateConsultStepwise(values, props.history, 8);
  }

  return (
    <Fragment>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <AvField name="step" type="hidden" value="8" />
        <AvField
          name="id"
          type="hidden"
          value={singleConsult ? singleConsult.id : ""}
        />
        <AvField name="stepType" type="hidden" value="billing" />

        <Row>
          <Col lg="12">
            <h4 className="font-size-18 font-weight-bold">
              {props.t("Submit your Billing information")}
            </h4>
            <h5 className="text-muted font-weight-13">
              {props.t(" We are almost done")}
            </h5>
            <p className="text-muted font-weight-13 mt-4 mb-4">
              {props.t(
                "Based on your plan through Doctegrity, the $0.00 fee will be waived for this consult. You will owe a total of $0.00."
              )}
            </p>
          </Col>
        </Row>

        <ConsultFormFooter />
      </AvForm>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const {
    memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  } = state.Member;
  return {
    memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    updateConsultStepwise,
    getSingleConsultation,
    getMemberAndDependents,
    getDraftConsultation,
  })(withNamespaces()(Billing))
);
