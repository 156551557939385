import React from 'react';

const ActionButton = ({ title, comp, handleClick, modal, ...rest }) => {
  return (
    <button type="button" id={title} title={title} onClick={() => handleClick(title, comp, modal)} {...rest} className="btn btn-primary">
      {/* {title} */}
      Pharmacy <i className="bx bx-plus-medical
"></i>
    </button>
  );
};

export default ActionButton;
