import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import AsyncSelect from "react-select/async";

import debounce from "lodash/debounce";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";

import {
  createMember,
  updateMember,
  createMemberFailed,
  memberActionSuccessful,
  setSingleMember,
} from "../../store/actions";

//Include Both Helper File with needed methods
import { dctGetAsyncData } from "../../helpers/backend_helper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { Config } from "../../Config";

const CreateUpdateMember = (props) => {
  const [modal, setModal] = useState(false);
  const [msgEnable, setMsgEnable] = useState(true);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [memberDob, setMemberDob] = useState(new Date());
  const [memberAge, setMemberAge] = useState(0);
  const [phone, setPhone] = useState("");

  const [stateName, setStateName] = useState("");
  const [countryName, setCountryName] = useState("");

  const dispatch = useDispatch();

  let memberData = useSelector((state) => state.Member.singleMember);
  memberData = memberData ? memberData : [];

  const action_type = props.member_action_type === "edit" ? "edit" : "create";
  const button_label =
    props.member_action_type === "edit" ? "Submit" : "Create Member";
  const modal_title =
    props.member_action_type === "edit" ? "Update Member" : "Create Member";

  useEffect(() => {
    if (memberData.dob) {
      setMemberDob(new Date(memberData.dob));
    }
    if (memberData.primaryPhone) {
      setPhone(memberData.primaryPhone);
    }
    if (memberData.country) {
      setCountryName(memberData.country);
    }
    if (memberData.state) {
      setStateName(memberData.state);
    }
    if (memberData.partnerId) {
      setSelectedPartner(memberData.partnerId);
    }
  }, [memberData]);

  // Load the fetched Partner data for the select dropdown
  const loadPartnerOptions = (inputValue, callback) => {
    let qParam = (inputValue && inputValue.length > 2) ? `q=${inputValue}` : '';
    const url = `${process.env.REACT_APP_API_PATH}/partner?limit=30&select=name&populate=false&${qParam}`;
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios
    .get(url, options)
    .then((response) => {
      callback(response.data?.data);
      return;
    })
    .catch((error)=> {
      console.log('Could not fetch partners. Error -> ', error.response.data);
      return;
    });
  };

  const partnerOptions = debounce(loadPartnerOptions, 250);

  const handlePartnerChange = (selectedPartner) => {
    setSelectedPartner(selectedPartner);
    if (!selectedPartner) {
      document.querySelector("#select-partner").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-partner").classList.remove("text-danger");
  };

  const dctCustomValidations = (fieldID, wrapperID) => {
    if (!fieldID && !wrapperID) return;

    let inputEl = document.querySelectorAll(`input[name="${fieldID}"]`);
    if (inputEl) {
      let inputIdValue = inputEl[0].value;
      if (inputIdValue) {
        document.querySelector(`#${wrapperID}`).classList.remove("text-danger");
        return inputIdValue;
      } else {
        document.querySelector(`#${wrapperID}`).classList.add("text-danger");
        return;
      }
    }
  };

  function toggleModalClose(e) {
    if (props.singleMember) {
      props.updateMemberDataFlag();
    }
  }

  function handleValidSubmit(event, values) {
    if (!selectedPartner.id || selectedPartner.id == "") {
      document.querySelector("#select-partner").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-partner").classList.remove("text-danger");
    values.partnerId = selectedPartner.id;

    if (!countryName || countryName == "") {
      document.querySelector("#select-country").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-country").classList.remove("text-danger");
    values.country = countryName;

    if (!stateName || stateName == "") {
      document.querySelector("#select-state").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-state").classList.remove("text-danger");
    values.state = stateName;

    if (!phone || phone == "") {
      document.querySelector("#phone").classList.add("text-danger");
      return;
    }
    document.querySelector("#phone").classList.remove("text-danger");
    values.primaryPhone = `${phone}`;

    let formatDob = moment(memberDob).format("YYYY-MM-DD");
    let dobAge = moment().diff(formatDob, "years");
    if (dobAge < 18) {
      document.querySelector("#date-dob").classList.add("text-danger");
      return;
    }
    document.querySelector("#date-dob").classList.remove("text-danger");
    values.dob = moment(memberDob).format("YYYY-MM-DD");

    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.remove("d-none");
    setMsgEnable(true);

    //call reducer method
    if (values.actionType === "edit") {
      props.updateMember(values, props.history);
    } else {
      props.createMember(values, props.history);
      setInterval(1000);
      props.onComplete(props.groupId);
    }
  }

  const editFormMemberItem = (e) => {
    setMsgEnable(false);
    dispatch(memberActionSuccessful(null, "", ""));
  };

  const createFormMemberItem = (e) => {
    memberData = {};
    setMsgEnable(false);
    setPhone("");
    setMemberDob("");
    setMemberAge(0);
    dispatch(memberActionSuccessful(null, "", ""));
    dispatch(setSingleMember(null, "", ""));
  };

  const handleDateChange = (date) => {
    setMemberDob(date);
    let formatDob = moment(date).format("YYYY-MM-DD");
    let dobAge = moment().diff(formatDob, "years");
    setMemberAge(dobAge);
    if (dobAge <= 18) {
      document.querySelector("#date-dob").classList.add("text-danger");
    } else {
      document.querySelector("#date-dob").classList.remove("text-danger");
    }
  };

  return (
    <React.Fragment>
      {(() => {
        switch (props.member_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="member_update_modal"
                onClick={() => {
                  setModal(!modal);
                  editFormMemberItem();
                }}
              ></i>
            );
          case "edit_btn":
            return (
              <Button
                type="button"
                color="primary"
                id="member_update_modal"
                className="waves-effect waves-light mr-2"
                onClick={() => {
                  setModal(!modal);
                  editFormMemberItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1"></i> {props.t("Edit")}
              </Button>
            );
          default:
            return (
              <Link
                onClick={() => {
                  setModal(!modal);
                  createFormMemberItem();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id="member_create_modal"
              >
                <i className="mdi mdi-plus mr-1"></i> {props.t("Create Member")}
              </Link>
            );
        }
      })()}

      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-member-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.createMemberError && msgEnable ? (
                <Alert color="danger">{props.createMemberError}</Alert>
              ) : null}
              {props.message && props.message && msgEnable ? (
                <Alert color="success">{props.message}</Alert>
              ) : null}

              {action_type === "edit" ? (
                <AvField
                  name="id"
                  type="hidden"
                  value={
                    memberData.id != undefined ? memberData.id : memberData._id
                  }
                />
              ) : (
                ""
              )}
              <AvField name="actionType" type="hidden" value={action_type} />

              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <AvField
                      name="firstName"
                      label={props.t(`First Name`)}
                      className="form-control"
                      placeholder={props.t(`First Name`)}
                      type="text"
                      required
                      value={
                        memberData.userId != undefined
                          ? memberData.userId.firstName
                          : ""
                      }
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <AvField
                      name="lastName"
                      label={props.t(`Last Name`)}
                      className="form-control"
                      placeholder={props.t(`Last Name`)}
                      type="text"
                      required
                      value={
                        memberData.userId != undefined
                          ? memberData.userId.lastName
                          : ""
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <AvField
                      name="email"
                      label={props.t(`Email`)}
                      className="form-control"
                      placeholder={props.t(`Email`)}
                      type="text"
                      required
                      value={
                        memberData.userId != undefined
                          ? memberData.userId.email
                          : ""
                      }
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className={`form-group ${Config.names.slugName}-select-box`}
                    id="select-partner"
                  >
                    <label htmlFor="partnerId">
                      {props.t("Select Partner")}
                    </label>
                    <AsyncSelect
                      isClearable={true}
                      placeholder={props.t(`Select Partner`)}
                      name="partnerId"
                      id="partnerId"
                      getOptionLabel={(e) => e.partnerName}
                      getOptionValue={(e) => e.id}
                      className={`${Config.names.slugName}-custom-select-box`}
                      cacheOptions
                      defaultOptions
                      loadOptions={partnerOptions}
                      value={selectedPartner}
                      onChange={handlePartnerChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <label>{props.t(`Phone`)}</label>
                    <PhoneInput
                      inputProps={{ name: "Phone", required: true }}
                      country={
                        !countryName || countryName == ""
                          ? "us"
                          : countryName.toLowerCase()
                      }
                      value={phone}
                      onChange={(phone) => {
                        setPhone("+" + phone);
                      }}
                      enableSearch={true}
                      onlyCountries={["us", "mx"]}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <AvField
                      type="select"
                      name="gender"
                      label={props.t(`Gender`)}
                      value={memberData.gender}
                    >
                      <option value="unknown">{props.t("Other")}</option>
                      <option value="male">{props.t("Male")}</option>
                      <option value="female">{props.t("Female")}</option>
                    </AvField>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className={`form-member ${Config.names.slugName}-date-box`} id={`date-dob`}>
                    <label htmlFor="dob">{props.t("Date of Birth")}</label>
                    <ReactDatePicker
                      name="dob"
                      className="form-control"
                      selected={memberDob}
                      dateFormat="yyyy-MM-dd"
                      onChange={handleDateChange}
                      placeholderText="YYYY-MM-DD"
                      showYearDropdown
                    />
                    {memberAge < 18 ? (
                      <div className="invalid-feedback">
                        {props.t(`Age should 18 above`)}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <label htmlFor="state">{props.t(`Select Country`)}</label>
                    <CountryDropdown
                      defaultOptionLabel={
                        countryName !== "" ? countryName : memberData.country
                      }
                      labelType="short"
                      valueType="short"
                      whitelist={["US", "MX"]}
                      classes="form-control"
                      onChange={(val) => {
                        setCountryName(val);
                        setStateName("");
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className={`form-group ${Config.names.slugName}-select-box`} id="select-state">
                    <label htmlFor="state">{props.t(`Select State`)}</label>
                    <RegionDropdown
                      country={countryName}
                      countryValueType="short"
                      labelType="full"
                      valueType="short"
                      value={stateName}
                      classes="form-control"
                      onChange={(val) => {
                        setStateName(val);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <AvField
                      name="city"
                      label={props.t(`City`)}
                      className="form-control"
                      placeholder={props.t(`City`)}
                      type="text"
                      required
                      value={memberData.city}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <AvField
                      name="postalCode"
                      label={props.t(`Postal Code`)}
                      className="form-control"
                      placeholder={props.t(`Postal Code`)}
                      type="text"
                      validate={{
                        required: { value: true },
                        pattern: { value: "^[0-9]{5}$" },
                        minLength: {
                          value: 5,
                          errorMessage: "Postal Code should be 5 digits.",
                        },
                      }}
                      value={memberData.postalCode}
                    />
                  </div>
                </Col>
                <Col lg={6}></Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <AvField
                      name="address"
                      label={props.t(`Address`)}
                      className="form-control"
                      placeholder={props.t(`Address`)}
                      type="text"
                      required
                      value={memberData.address}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <AvField
                      name="addressTwo"
                      label={props.t(`Address Two`)}
                      className="form-control"
                      placeholder={props.t(`Address Two`)}
                      type="text"
                      value={memberData.addressTwo}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setModal(!modal)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                      onClick={(e) => {
                        dctCustomValidations("partnerId", "select-partner");
                        // dctCustomValidations("state", "select-state");
                      }}
                    >
                      <span className="dr-mform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      {button_label}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createMemberError } = state.Member;
  const { member } = state.Member;
  const { message } = state.Member;
  return { createMemberError, member, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createMember,
    updateMember,
    createMemberFailed,
    setSingleMember,
  })(withNamespaces()(CreateUpdateMember))
);
