import React from "react";
import { useState } from "react";
import { Row, Col } from "reactstrap";
import { Config } from "../../../../Config/Config";
import { phoneFormatter } from "../../service/phoneFormatter";
import { useSelector } from "react-redux";

const IdentifyNoEmail = (props) => {
  // const findAccountData = useState(
  //   useSelector((state) => state.findAccountData)
  // );
  console.log("redux props.userData", props.userData);

  return (
    <>
      <Col
        lg="5"
        md="6"
        sm="8"
        xs="10"
        className={`${Config.names.slugName}-box-shadow rounded-lg mt-3`}
      >
        <div className="py-5 px-3">
          <h5 className="text-primary font-weight-bold text-uppercase">
            Account Details Located
          </h5>

          <div style={{ fontSize: "0.87rem", marginTop: "1rem" }}>
            <p style={{ fontWeight: "600" }}>Success:</p>
            <p style={{ fontWeight: "300", marginTop: "0.5rem" }}>
              We found an account which matches the details you have provided.
            </p>
          </div>

          <Row
            className="d-flex flex-row justify-content-around"
            style={{
              borderRadius: "0.37rem",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              marginTop: "1rem",
              background: "#EEF1FF",
            }}
          >
            <Col lg="4" md="4" sm="12">
              <p className="font-size-14 font-weight-light mb-2">Last Name</p>
              <p className="font-weight-normal text-primary font-size-16 text-capitalize">
                {props.userData?.lastName ? props.userData.lastName : ""}
              </p>
            </Col>
            <Col lg="4" md="4" sm="12">
              <p className="font-size-14 font-weight-light mb-2">
                Date of Birth
              </p>
              <p className="font-weight-normal text-primary font-size-16">
                {props.userData?.dob ? props.userData.dob : ""}
              </p>
            </Col>
            <Col lg="4" md="4" sm="12">
              <p className="font-size-14 font-weight-light mb-2">Zip Code</p>
              <p className="font-weight-normal text-primary font-size-16">
                {props.userData?.zipCode ? props.userData.zipCode : ""}
              </p>
            </Col>
          </Row>

          <div style={{ fontSize: "0.87rem", marginTop: "1rem" }}>
            <p style={{ fontWeight: "600" }}>Missing Email:</p>
            <p style={{ fontWeight: "300", marginTop: "0.5rem" }}>
              Unfortunately, your account is missing a valid email address to
              allow access.
            </p>
          </div>
          <div style={{ fontSize: "0.87rem", marginTop: "1rem" }}>
            <p style={{ fontWeight: "600" }}>Next Steps:</p>
            <p style={{ fontWeight: "300", marginTop: "0.5rem" }}>
              Please contact our support team to verify your information and
              provide a valid email address for your account.
            </p>
          </div>

          <div
            className="v3-black-text-color"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i className="fas fa-phone-alt"></i>
            <p
              style={{
                fontWeight: "500",
                fontSize: "1rem",
                marginTop: "1rem",
                marginLeft: "1rem",
              }}
            >
              Call us
              <span className="text-primary" style={{ marginLeft: "0.7rem" }}>
                {phoneFormatter(`${Config.names.callContact}`, 2)}
              </span>
            </p>
          </div>
        </div>
      </Col>
    </>
  );
};

export default IdentifyNoEmail;
