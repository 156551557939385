import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardBody, Row } from "reactstrap";

// import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
// import axios from "axios";
// import { getAuthIds } from "../../helpers/Commons";
// import { useSelector } from "react-redux";

const MiniWidget = (props) => {
  // const { authDoctorId } = useSelector((state) => state.Login);

  // const [dctScheduledTelevisit, setDctScheduledTelevisit] = useState();

  useEffect(() => {
    /**
     * Consultation is no more assigned to any specific doctor
     **/
    // let bearer_token = localStorage.getItem("auth_token");
    // let doctorId = authDoctorId;
    // const headers = {
    //   headers: {
    //     Authorization: bearer_token,
    //     PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
    //   },
    // };
    // let url = `${process.env.REACT_APP_API_PATH}/consultation/total-scheduled?doctorId=${doctorId}`;
    // axios
    //   .get(url, headers)
    //   .then((response) => {
    //     console.log(response.data);
    //     setDctScheduledTelevisit(response.data.total);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, []);

  const [dctCompletedTelevisit, setDctCompletedTelevisit] = useState();
  useEffect(() => {
    /**
     * Consultation is no more assigned to any specific doctor
     **/
    // let bearer_token = localStorage.getItem("auth_token");
    // const headers = {
    //   headers: {
    //     Authorization: bearer_token,
    //     PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
    //   },
    // };
    // let doctorId =authDoctorId;
    // let url = `${process.env.REACT_APP_API_PATH}/consultation/total-completed?doctorId=${doctorId}`;
    // axios
    //   .get(url, headers)
    //   .then((response) => {
    //     console.log(response.data);
    //     setDctCompletedTelevisit(response.data.total);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, []);

  return (
    <Fragment>
      <Col xl="4" className="d-none">
        <Card>
          <CardBody>
            <Row>
              <Col xs="12">
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i className="bx bx-video"></i>
                    </span>
                  </div>
                  <h5 className="font-size-16 mb-0">
                    {props.t("Scheduled Televisits")}
                  </h5>
                </div>
              </Col>
              <Col xs="12" className="align-self-end">
                <div className="text-muted mt-3 pb-0">
                  {/* <h1>{dctScheduledTelevisit}</h1> */}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col xl="4" className="d-none">
        <Card>
          <CardBody>
            <Row>
              <Col xs="12">
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i className="bx bx-clipboard"></i>
                    </span>
                  </div>
                  <h5 className="font-size-16 mb-0">
                    {props.t("Televisits History")}
                  </h5>
                </div>
              </Col>
              <Col xs="12" className="align-self-end">
                <div className="text-muted mt-3 pb-0">
                  <h1>{dctCompletedTelevisit}</h1>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default withRouter(withNamespaces()(MiniWidget));
