// import { connect } from "react-redux";
import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
// import {setRedirectToURL} from './../../store/actions'

const Authmiddleware = ({ component: Component, layout: Layout }) => (
  <Route
    render={(props) => {
      localStorage.setItem('redirect_to_url', props.location.pathname)
      // here you can apply condition
      if (
        !localStorage.getItem("auth_token") &&
        props.location.pathname !== "/loader"
      ) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default withRouter(
   (Authmiddleware)
);
