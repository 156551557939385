export const CREATE_BRAND = "CREATE_BRAND";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const GET_BRAND = "GET_BRAND";
export const GET_BRAND_LIST = "GET_BRAND_LIST";
export const SET_BRAND_LIST = "SET_BRAND_LIST";
export const SET_BRAND_LISTING = "SET_BRAND_LISTING";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const CREATE_BRAND_FAILED = "CREATE_BRAND_FAILED";
export const SET_SINGLE_BRAND = "SET_SINGLE_BRAND";
export const API_ERROR = "API_ERROR";
