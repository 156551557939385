import { CREATE_GROUP, UPDATE_GROUP, GET_GROUP, GET_GROUP_LIST, SET_GROUP_LIST, CREATE_GROUP_SUCCESS, CREATE_GROUP_FAILED, API_ERROR, SET_SINGLE_GROUP, SET_GROUP_LISTING } from './actionTypes';

const initialState = {
    createGroupError: null, 
    message: null, 
    loading: false,
    action_type: null
}

const group = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_GROUP:
            state = {
                ...state,
                group: action.payload,
                loading: true,
                createGroupError: null
            }
            break;
        case UPDATE_GROUP:
            state = {
                ...state,
                group: action.payload,
                loading: true,
                createGroupError: null
            }
            break;
        case GET_GROUP:
            state = {
                ...state,
                group: action.payload.result,
                loading: true,
                createGroupError: null
            }
            break;
        case GET_GROUP_LIST:
            state = {
                ...state,
                group: action.payload.result,
                loading: true,
                createGroupError: null
            }
            break;
        case SET_SINGLE_GROUP:
            state = {
                ...state,
                group: null,
                singleGroup: action.payload,
                loading: false,
                createGroupError: null,
                action_type: action.action_type,
            }
            break;
        case SET_GROUP_LIST:
            state = {
                ...state,
                loading: false,
                group: action.payload,
                message: action.message,
                createGroupError: null,
                action_type: action.action_type,
            }
            break;
        case SET_GROUP_LISTING:
            state = {
                ...state,
                loading: false,
                group: null,
                groupListing: action.payload,
                message: action.message,
                createGroupError: null,
            }
            break;    
        case CREATE_GROUP_SUCCESS:
            state = {
                ...state,
                loading: false,
                group: action.payload,
                message: action.message,
                createGroupError: null,
                action_type: action.action_type,
            }
            break;
        case CREATE_GROUP_FAILED:
            state = {
                ...state,
                group: null,
                createGroupError: action.payload,
                loading: false,
            }
            break;
        case API_ERROR:
            state = { 
                ...state, 
                error: action.payload, 
                loading: false 
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default group;


