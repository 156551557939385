import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { setSinglePartner, setPartnerListing } from "../../store/actions";

import { Row, Col, UncontrolledTooltip } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Include Both Helper File with needed methods
import { dctUpdateStatus } from "../../helpers/backend_helper";
import { loadingMessage } from "../../helpers/Commons";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { BeatLoader } from "react-spinners";
import { Config } from "../../Config";

const PartnerList = (props) => {
  const { currentPage, perPage, total, getDataOnEnter, ajaxLoader } = props;

  let partnersObj = [];

  partnersObj = useSelector((state) => state.Partner.partnerListing);
  partnersObj = partnersObj ? partnersObj : [];
  const dispatch = useDispatch();

  const [customPageNo, setCustomPageNo] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);

  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [partnerStatusMeta, setPartnerStatusMeta] = useState({
    rowIndex: "",
    groupId: "",
    groupStatus: 0,
  });

  useEffect(() => {
    // sleep()
  }, []);

  const openPartnerEditModal = (data) => {
    document.querySelector("#partner_update_modal").click();
    //Push Single Group Data to store
    dispatch(setSinglePartner(data, "", ""));
  };

  const togglePartnerStatus = async (partnerStatusMeta) => {
    if (!partnerStatusMeta) return;

    const { partnerId, partnerStatus, rowIndex } = partnerStatusMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Activation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    const loaderCls = ["bx-loader", "bx-spin", "font-size-18"];
    let toogleUserCls = partnerStatus === "active" ? "bx-check" : "bx-x";
    setStatusLoader(true);

    // Adding the bx-loader and removing the box-user-check class
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.add(...loaderCls);
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.remove(toogleUserCls);

    let gStatus = partnerStatus === "active" ? "inactive" : "active";
    const postData = {
      id: partnerId,
      status: gStatus,
    };
    const url = "partner/" + partnerId;
    const response = await dctUpdateStatus(postData, url).then((data) => data);

    if (response.status === "active") {
      setdynamic_description("Activated successfully.");
      setdynamic_title("Activated");
    }
    if (response.status === "inactive") {
      setdynamic_description("Deactivated successfully.");
      setdynamic_title("Deactivated");
    }

    if (response.id) {
      seterror_dlg(false);
    } else {
      seterror_dlg(true);
      setsuccess_dlg(false);
    }

    partnersObj[rowIndex].status = response.status;
    // dispatch(setGroupListing(null));
    dispatch(setPartnerListing(partnersObj, null));
    setStatusLoader(false);
    toogleUserCls = response.status === "active" ? "bx-check" : "bx-x";
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.remove(...loaderCls);
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.add(toogleUserCls);
  };
  const partnerItemsBody = (partnersObj) => {
    return partnersObj.length > 0 ? (
      partnersObj.map((partner, index) => {
        return (
          <Tr key={index} data-row-id={partner.id}>
            <Td>
              <div className="avatar-xs">
                <Link
                  to={"/partners-overview/id/" + partner.id}
                  className="text-dark"
                >
                  <span className="avatar-title rounded-circle text-capitalize">
                    {partner.name?.charAt(0)}
                  </span>
                </Link>
              </div>
            </Td>
            <Td className="text-left">
              <h5 className="text-truncate font-size-14">
                <Link
                  to={"/partners-overview/id/" + partner.id}
                  className="text-dark"
                >
                  {partner?.name}
                </Link>
              </h5>
            </Td>
            <Td className="text-left">
              <h5 className="text-truncate font-size-14">
                {/* //{partner.companyId.companyName === undefined ? partner.companyId.companyName:"Nothing"} */}
                {partner.companyId != undefined
                  ? partner.companyId.companyName
                  : ""}
              </h5>
            </Td>

            <Td className="text-center">
              <Link
                to="#"
                className={
                  partner.status === "active"
                    ? "badge badge-soft-primary font-size-11 m-1"
                    : "badge badge-soft-danger font-size-11 m-1"
                }
              >
                {partner.status === "active" ? "Active" : "Inactive"}
              </Link>
            </Td>
            <Td className="text-center">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2 d-none">
                  <Link
                    to="#"
                    id="message_1"
                    data-partner-id={partner.id}
                    onClick={() => {}}
                  >
                    <i className="bx bx-cloud-upload"></i>
                    <UncontrolledTooltip placement="top" target="message_1">
                      {props.t("Upload")}
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"edit" + partner.id}
                    onClick={() => {
                      openPartnerEditModal(partner);
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"edit" + partner.id}
                    >
                      {props.t("Edit")}
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"status_" + partner.id}
                    data-status={partner.status}
                    data-id={partner.id}
                    onClick={() => {
                      setconfirm_both(true);
                      setPartnerStatusMeta({
                        rowIndex: index,
                        partnerId: partner.id,
                        partnerStatus: partner.status,
                      });
                    }}
                  >
                    {partner.status === "active" ? (
                      <i className="bx text-success bx-check"></i>
                    ) : (
                      <i className="bx text-danger  bx-x"></i>
                    )}
                    <UncontrolledTooltip
                      placement="top"
                      target={"status_" + partner.id}
                    >
                      {partner.status === "active" ? "Active" : "Inactive"}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
              {confirm_both ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="secondary"
                  onConfirm={() => {
                    togglePartnerStatus(partnerStatusMeta);
                  }}
                  onCancel={() => {
                    setconfirm_both(false);
                    setsuccess_dlg(false);
                  }}
                >
                  {props.t(`This process will change the Partner status!`)}
                </SweetAlert>
              ) : null}
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="7" className="text-center"></Td>
      </Tr>
    );
  };
  const handleSearchPage = (e) => {
    if (e.key === "Enter") {
      getDataOnEnter(e.currentTarget.value);
    }
  };
  return (
    <React.Fragment>
      <div className="group-list">
        <Row>
          <Col lg="12">
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {error_dlg ? (
              <SweetAlert
                error
                title={dynamic_title}
                onConfirm={() => {
                  seterror_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {/* {ajaxLoader ? (
              <div className="${Config.names.slugName}-listing-loader">
                {loadingMessage("Loading...")}
              </div>
            ) : (
              ""
            )} */}
            <div className="table-responsive">
              <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}>
                <Thead className="thead-light">
                  <Tr>
                    <Th scope="col" className="text-center" style={{ width: "70px" }}>
                      {props.t("#")}
                    </Th>
                    <Th scope="col" className="text-left">{props.t("Partner Name")}</Th>
                    <Th scope="col" className="text-left">{props.t("Company Name")}</Th>
                    <Th scope="col" className="text-center" style={{ width: "70px" }}>
                      {props.t("Status")}
                    </Th>
                    <Th scope="col" className="text-center" style={{ width: "70px" }}>
                      <center>{props.t("Action")}</center>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>{partnerItemsBody(partnersObj)}</Tbody>
              </Table>
              {props.partnerListMessage ? (
                <div className="text-center text-danger">
                  <i className="bx bx-search-alt font-size-18 align-middle mr-2"></i>{" "}
                  {props.partnerListMessage}{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className={total > 1 ? `${Config.names.slugName}-pager d-none` : `${Config.names.slugName}-pager d-none`}>
              <nav className="d-flex justify-content-center mt-4">
                <ReactPaginate
                  initialPage={currentPage}
                  forcePage={currentPage}
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={total}
                  // itemsCountPerPage={perPage}
                  breakLabel={"..."}
                  label={<input />}
                  breakClassName={"break-me"}
                  onPageChange={props.getUserData}
                  containerClassName={
                    "pagination pagination-rounded align-items-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  activeLinkClassName={"active"}
                />
                <input
                  className="ml-3 text-center"
                  type="text"
                  size="5"
                  placeholder={props.t("Go to")}
                  value={customPageNo}
                  onChange={(e) => setCustomPageNo(e.currentTarget.value)}
                  onKeyPress={handleSearchPage}
                  style={{ height: "25px" }}
                />
              </nav>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <BeatLoader size={12} color={Config.colors.pieChartActiveColor} loading={ajaxLoader} />
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(PartnerList));
