import React, { Fragment, useState, useEffect } from "react";
import { FormGroup, Label, Col, Row, Button, Badge } from "reactstrap";
import { connect, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  AvForm,
  AvInput,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";

import {
  updateConsultStepwise,
  getSingleConsultation,
  getMemberAndDependents,
  getDraftConsultation,
} from "../../../store/actions";

// import SweetAlert from "react-bootstrap-sweetalert";
import { SweetAlert } from "../../../components/DoctegritySweetAlert/DoctegritySweetAlert";

import { loadingMessage, dctClsAddRemove, capitalizeWords } from "../../../helpers/Commons";
import {
  chiefcomplaintList,
  CommonSymptoms,
  AllOtherSymptoms,
} from "../../../helpers/dctSymptoms";

import ConsultFormFooter from "./consultFormFooter";
import { Config } from "../../../Config";

function Details(props) {
  const [details, setDetails] = useState({
    chiefcomplaint: "",
    commonSymptoms: [],
    otherSymptoms: [], //
    allOtherSymptoms: [],
    detailMessage: "",
    medicationRefillMessage: [],
    medicineRefillComponent: false,
    addAnotherComponent: false,
    show: false,
    no: "defaultChecked",
    yes: "",
  });
  const [isSymptomsWarningWindow, setIsSymptomsWarningWindow] = useState(false);

  let memberObj = [];

  useEffect(() => {
    props.pageTitle(
      "Now we need to tell the Doctor why you are scheduling a consultation"
    );
  }, []);

  const [rows1, setrows1] = useState([]);

  const [newMsg, setNewMsg] = useState([]);

  const handleDeleteMedicationRefillRow = (idx) => {
    let deleteRow = rows1.splice(idx, 1);
    document.getElementById("nested" + idx).style.display = "none";
    return deleteRow;
  };
  const newmessage = (event) => {
    event.persist();
    let msgdata = event.target.value;
    setNewMsg([msgdata]);
  };

  function handleAddRowNested(e) {
    let id = parseInt(e.currentTarget.getAttribute("data_id"));

    const item1 = { text: `${newMsg}`, id: id };

    setrows1([...rows1, item1]);
    setDetails((prevState) => ({
      ...prevState,
      medicineRefillComponent: true,
    }));
    e.currentTarget.setAttribute("data_id", id + 1);
  }

  const handleChiefcomplaintChange = (e) => {
    e.persist();
    if (chiefcomplaintList[e.target.selectedIndex].iswarning) {
      setIsSymptomsWarningWindow(true);
    }
  };
  const handleCommonSymptomsChange = (e) => {
    e.persist();
    if (CommonSymptoms[e.target.attributes.index.nodeValue].iswarning) {

      setIsSymptomsWarningWindow(true);
    }
  };
  const handleAllOtherSymptomsChange = (e) => {
    e.persist();
    if (AllOtherSymptoms[e.target.attributes.index.nodeValue].iswarning) {

      setIsSymptomsWarningWindow(true);
    }
  };

  const handleMedicationRefill = (e) => {
    // if (window.ReactNativeWebView)
    //   window.ReactNativeWebView.postMessage("SCROLL_TO_TOP");
    e.persist();
    let getId = e.target.id;
    if (getId === "refillYes") {
      setDetails((prevState) => ({
        ...prevState,
        show: true,
        no: "",
        yes: "defaultChecked",
      }));
    } else if (getId == "refillNo") {
      setDetails((prevState) => ({
        ...prevState,
        show: false,
        no: "defaultChecked",
        yes: "",
      }));
    } else return setDetails();
  };

  //Update the consultation
  function handleValidSubmit(event, values) {
    dctClsAddRemove("dr-form-spinner", "d-none", "remove");
    props.updateConsultStepwise(values, props.history, 5);
  }

  memberObj = useSelector((state) => state.Member);
  const { singleConsult } = memberObj;

  return (
    <Fragment>
      <AvForm
        className="form-horizontal pl-2 pr-2"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        {!singleConsult ? (
          <div className="mt-1 mb-3">{loadingMessage("Loading...")}</div>
        ) : null}

        {isSymptomsWarningWindow ? (
          <SweetAlert
            show={true}
            closeOnClickOutside= {false}
            title={props.t(`Warning!`)}
            danger
            confirmBtnBsStyle="danger"
            confirmBtnText={props.t("I Understand")}
            onConfirm={() => {
              setIsSymptomsWarningWindow(false);
            }}
          >
            <p>
              {props.t(`You have selected a symptom that is sometimes associated with a life-threatening condition.`)}{" "}
              <strong className="text-danger">
                {props.t(`This service is not for emergencies.`)}
              </strong>
              {props.t(`If you believe this is an emergency please`)}{" "}
              <strong className="text-danger">dial 911 right away.</strong>
            </p>
          </SweetAlert>
        ) : null}

        <Row>
          <Col lg="12">
            <p className="mb-0 text-muted  font-size-13">
              {props.t(
                `Please fill the form below with accurate details regarding`
              )}{" "}
              <b>{singleConsult ? capitalizeWords(singleConsult.patient) : ""}</b>{" "}
              {props.t("current condition")}
            </p>

            <Row className="mt-2">
              <Col lg={5}>
                <span style={{display: "none"}}>{props.t("Cold or Flu")} {props.t("Earache")} {props.t("Sinus congestion")} {props.t("Chills")} {props.t("Fever")} {props.t("Skin rash")} {props.t("Cough")} {props.t("Headache")} {props.t("Sore throat")} {props.t("Diarrhea")} {props.t("Nausea, vomiting")} {props.t("Urinary problems")} {props.t("General malaise")} {props.t("Lightheadedness or Dizziness")} {props.t("Abdominal pain")} {props.t("Foot pain")} {props.t("Tired")} {props.t("Chest pain")} {props.t("Female problems")} {props.t("Weak")} {props.t("Hypertension (High blood pressure)")} {props.t("Male problems")} {props.t("Eye problem")} {props.t("Loss of consciousness")} {props.t("Shortness of breath")} {props.t("Backache")} {props.t("Please choose one")} {props.t("Cold or Flu")} {props.t("Abdominal pain")} {props.t("Backache")} {props.t("Chest pain")} {props.t("Chills")} {props.t("Cough")} {props.t("Diarrhea")} {props.t("Earache")} {props.t("Female problems")} {props.t("Foot pain")} {props.t("General malaise")} {props.t("Headache")} {props.t("Hypertension (High blood pressure)")} {props.t("Lightheadedness or Dizziness")} {props.t("Loss of consciousness")} {props.t("Male problems")} {props.t("Nausea, vomiting")} {props.t("Shortness of breath")} {props.t("Sinus congestion")} {props.t("Skin rash")} {props.t("Sore throat")} {props.t("Tired")} {props.t("Urinary problems")} {props.t("Weak")} {props.t("Multiple")}</span>
                <AvField name="step" type="hidden" value="3" />
                <AvField
                  name="id"
                  type="hidden"
                  value={singleConsult ? singleConsult.id : ""}
                />
                <AvField name="stepType" type="hidden" value="details" />
                <div className="mb-2 font-weight-medium">
                  {props.t("Please choose what best describes your problem")}
                </div>
                <AvField
                  className="custom-select mt-0"
                  type="select"
                  name="details_chiefcomplaint"
                  id="details_chiefcomplaint"
                  onChange={handleChiefcomplaintChange}
                  required
                  value={
                    singleConsult ? singleConsult.details_chiefcomplaint : ""
                  }
                >
                  {chiefcomplaintList.map((option, key) => (
                    <option
                      data-iswarning={option.iswarning}
                      key={key}
                      value={option.value || ""}
                    >
                      {props.t(option.label)}
                    </option>
                  ))}
                </AvField>
              </Col>
              <Col lg={12}>
                <div className="mb-2 font-weight-medium">
                  {props.t(
                    "Please check all other symptoms you are experiencing"
                  )}
                </div>
                <Row className={`mt-1 ${Config.names.slugName}-checkbox-group`}>
                  <Col md={6}>
                    <h5>
                      <Badge variant="info">{props.t("Common Symptoms")}</Badge>{" "}
                    </h5>
                    <AvCheckboxGroup
                      className={`${Config.names.slugName}-bg-none`}
                      name="details_commonSymptoms"
                      validate={{ min: { value: 1 } }}
                      onChange={handleCommonSymptomsChange}
                    >
                      {CommonSymptoms.map((option, key) => (
                        <AvCheckbox
                          data-iswarning={option.iswarning}
                          key={key}
                          index={key}
                          label={props.t(option.label)}
                          value={option.value}
                        />
                      ))}
                    </AvCheckboxGroup>
                  </Col>

                  <Col md={6}>
                    <h5>
                      <Badge variant="info">
                        {props.t("All Other Symptoms")}
                      </Badge>{" "}
                    </h5>
                    <AvCheckboxGroup
                      className={`${Config.names.slugName}-bg-none`}
                      name="details_allOtherSymptoms"
                      validate={{ min: { value: 1 } }}
                      onChange={handleAllOtherSymptomsChange}
                    >
                      {AllOtherSymptoms.map((option, key) => (
                        <AvCheckbox
                          data-iswarning={option.iswarning}
                          key={key}
                          index={key}
                          label={props.t(option.label)}
                          value={option.value}
                        />
                      ))}
                    </AvCheckboxGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="mb-2 font-weight-medium">
                  {props.t(
                    "Please describe the medical condition for which you are scheduling a consultation"
                  )}
                </div>
                <div className="mt-1">
                  <AvField
                    type="textarea"
                    name="details_description"
                    maxLength="225"
                    rows="3"
                    md="8"
                    required
                    value={
                      singleConsult ? singleConsult.details_description : ""
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="mt-2 mb-2 font-weight-medium">
                  {props.t("Do you need a medication refill?")}
                </div>
                <Row>
                  <Col md={6}>
                    <FormGroup check>
                      <AvGroup check>
                        <AvInput
                          type="radio"
                          name="details_refillType"
                          id="refillYes"
                          value="yes"
                          onClick={handleMedicationRefill}
                        />
                        <Label check for="refillYes">
                          {props.t("Yes")}
                        </Label>
                      </AvGroup>
                      <AvGroup check>
                        <AvInput
                          type="radio"
                          name="details_refillType"
                          id="refillNo"
                          value="no"
                          onClick={handleMedicationRefill}
                        />
                        <Label check for="refillNo">
                          {props.t("No")}
                        </Label>
                      </AvGroup>
                    </FormGroup>
                  </Col>
                  <Col md={6} className="ml-auto"></Col>
                </Row>
              </Col>
            </Row>

            {details.show ? (
              <Row>
                <Col lg={12} className="mt-4">
                  <div className="mt-2 mb-2 font-weight-medium">
                    {props.t("Medication Refill")}
                  </div>
                  <Row>
                    <Col md={10}>
                      <p className="mb-2 text-muted font-size-13">
                        {props.t(`Name of Medication(s) and Dosing Frequency`)}:
                      </p>
                      <div>
                        <AvField
                          type="textarea"
                          name="details_medicationNameAndFrequency"
                          maxLength="225"
                          rows="3"
                          md="8"
                          // value={details.medicationRefillMessage}
                        />
                      </div>
                      <p className="mb-2 mt-2 text-muted font-size-12">
                        {props.t(
                          `Non-controlled prescription medication previously prescribed by another physician. Medication dosing frequency.`
                        )}
                      </p>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                </Col>

                {details.medicineRefillComponent ? (
                  <Col lg={12} className="mt-4">
                    {rows1 &&
                      rows1.map((item1, idx) => (
                        <Row id={"nested" + idx} key={idx}>
                          <Col md={10}>
                            <p className="mb-0 text-muted  font-size-13">
                              {props.t(
                                `Name of Medication(s) and Dosing Frequency`
                              )}
                              :
                            </p>
                            <div>
                              <AvField
                                type="textarea"
                                name="MedicationNameAndFrequency"
                                id={idx}
                                onChange={(e) => {
                                  newmessage(e);
                                }}
                                maxLength="225"
                                rows="3"
                                md="8"
                                // value={newMsg.text}
                              />
                            </div>
                            <p className="mb-3 mt-2 text-muted font-size-12">
                              {props.t(
                                `Non-controlled prescription medication previously prescribed by another physician. Medication dosing frequency.`
                              )}
                            </p>
                          </Col>
                          <Col md={2}>
                            <Button
                              color="primary"
                              className="btn-block inner mt-2"
                              style={{ width: "50%" }}
                              size="sm"
                              onClick={() => {
                                handleDeleteMedicationRefillRow(idx);
                              }}
                            >
                              {props.t("Delete")}
                            </Button>
                          </Col>
                        </Row>
                      ))}
                  </Col>
                ) : (
                  ""
                )}

                <Col lg={12}>
                  {details.addAnotherComponent ? (
                    "true"
                  ) : (
                    <Button
                      data_id="0"
                      onClick={handleAddRowNested}
                      color="success"
                      className="mt-1 d-none"
                    >
                      {props.t("Add Another")}
                    </Button>
                  )}
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <ConsultFormFooter />
          </Col>
        </Row>
      </AvForm>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const {
    memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  } = state.Member;
  return {
    memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    updateConsultStepwise,
    getSingleConsultation,
    getMemberAndDependents,
    getDraftConsultation,
  })(withNamespaces()(Details))
);
