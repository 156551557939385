import React, { useState } from "react";
import { Row, Col, Alert, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userChangePassword } from "../../../../store/actions";
import { getAuthIds } from "../../../../helpers/Commons";
import { Config } from "../../../../Config";

const PasswordManagement = (props) => {
  const [confirmPassErrorMsg, setConfirmPassErrorMsg] = useState("");

  function handleValidSubmit(event, values) {
    if (values.newPass !== values.confirmPass) {
      setConfirmPassErrorMsg(
        "New password and confirmation password didn't match."
      );
      return;
    }
    setConfirmPassErrorMsg("");

    document.getElementsByClassName("dr-spinner")[0].classList.remove("d-none");

    props.userChangePassword(values, "");
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Label className="font-size-18">
            {props.t("Password Management")}
          </Label>
          <p>
            {props.t(`Here you can change your Account's password.`)}
            {""}
          </p>
          <div className="p-2">
            {props.forgetError && props.forgetError ? (
              <Alert color="danger" style={{ marginTop: "13px" }}>
                {props.forgetError}
              </Alert>
            ) : null}
            {props.forgetSuccessMsg ? (
              <Alert color="success" style={{ marginTop: "13px" }}>
                {props.forgetSuccessMsg}
              </Alert>
            ) : null}

            {confirmPassErrorMsg ? (
              <Alert color="danger" style={{ marginTop: "13px" }}>
                {confirmPassErrorMsg}
              </Alert>
            ) : null}

            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <div className="form-group">
                <AvField
                  name="oldPass"
                  label={props.t("Old Password")}
                  className="form-control"
                  placeholder={props.t("Old Password")}
                  type="text"
                  value=""
                  required
                />
              </div>
              <div
                className={
                  confirmPassErrorMsg
                    ? `form-group ${Config.names.slugName}-invalid-field`
                    : "form-group"
                }
              >
                <AvField
                  name="newPass"
                  className="newspass"
                  label={props.t("New Password")}
                  className="form-control"
                  placeholder={props.t("New Password")}
                  type="text"
                  value=""
                  required
                />
              </div>

              <div
                className={
                  confirmPassErrorMsg
                    ? `form-group ${Config.names.slugName}-invalid-field`
                    : "form-group"
                }
              >
                <AvField
                  name="confirmPass"
                  className="newspass"
                  label={props.t("Confirm Password")}
                  className="form-control"
                  placeholder={props.t("Confirm Password")}
                  type="text"
                  value=""
                  required
                />
              </div>
              <Row className="form-group">
                <Col>
                  <AvField
                    name="userId"
                    type="hidden"
                    value={getAuthIds.user}
                  />

                  <button
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                  >
                    <span className="dr-spinner d-none">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    </span>
                    {props.t(`Save`)}
                  </button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userChangePassword })(
    withNamespaces()(PasswordManagement)
  )
);
