import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import {
  Col,
  Container,
  Row,
  Label,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

import axios from "axios";
import Dropzone from "react-dropzone";

import { dctPutDosespotData } from "../../../../helpers/backend_helper";
import { renderPhysicanLincenseInfo } from "../../../../helpers/Commons";

import { setSingleDoctor } from "../../../../store/actions";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//i18n
import { withNamespaces } from "react-i18next";
import PhoneInput from "react-phone-input-2";

import { dctTimezones } from "../../../../helpers/dctTimezones";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Config } from "../../../../Config";

function MyProfile(props) {
  const { authDoctorId } = useSelector((state) => state.Login);
  const dispatch = useDispatch();

  const optionGroup = [
    {
      options: [
        { label: "Arabic", value: "Arabic" },
        { label: "Armenian", value: "Armenian" },
        { label: "Bantu", value: "Bantu" },
        { label: "Bengali", value: "Bengali" },
        { label: "Chinese", value: "Chinese" },
        { label: "Gujarati", value: "Gujarati" },
        { label: "German", value: "German" },
        { label: "Khmer", value: "Khmer" },
        { label: "Korean", value: "Korean" },
        { label: "Navajo", value: "Navajo" },
        { label: "Persian", value: "Persian" },
        { label: "Polish", value: "Polish" },
        { label: "Portuguese", value: "Portuguese" },
        { label: "Punjabi", value: "Punjabi" },
        { label: "Russian", value: "Russian" },
        { label: "Serbo-Croatian", value: "Serbo-Croatian" },
        { label: "Spanish", value: "Spanish" },
        { label: "Tagalog", value: "Tagalog" },
        { label: "Tai-Kadai", value: "Tai-Kadai" },
        { label: "Tamil", value: "Tamil" },
        { label: "Telugu", value: "Telugu" },
        { label: "Urdu", value: "Urdu" },
        { label: "Vietnamese", value: "Vietnamese" },
      ],
    },
  ];

  const medicalCondition = [
    {
      options: [
        { label: "Annual physical exam", value: "Annual physical exam" },
        { label: "Follow-up appointments", value: "Follow-up appointments" },
        { label: "Heart Problems", value: "Heart Problems" },
        { label: "Blood Transfusion", value: "Blood Transfusion" },
        { label: "Check-up Appointments", value: "Check-up Appointments" },
      ],
    },
  ];

  const specialities = [
    {
      options: [
        { label: "Primary Care", value: "Primary Care" },
        { label: "Preventative Medicine", value: "Preventative Medicine" },
        { label: "Psychiatry", value: "Psychiatry" },
        { label: "Radiation Oncology", value: "Radiation Oncology" },
        { label: "Pulmonology", value: "Pulmonology" },
      ],
    },
  ];
  //globally Declared state
  let globalState;

  //US Multiple States LIST
  let USstates = [
    {
      options: [
        { label: "Alabama", value: "AL" },
        { label: "Alaska", value: "AK" },
        { label: "American Samoa", value: "AS" },
        { label: "Arizona", value: "AZ" },
        { label: "Arkansas", value: "AR" },
        { label: "Armed Forces Americas", value: "AA" },
        { label: "Armed Forces Pacific", value: "AP" },
        { label: "California", value: "CA" },
        { label: "Colorado", value: "CO" },
        { label: "Connecticut", value: "CT" },
        { label: "Delaware", value: "DE" },
        { label: "District of Columbia", value: "DC" },
        { label: "Federated States of Micronesia", value: "FM" },
        { label: "Florida", value: "FL" },
        { label: "Georgia", value: "GA" },
        { label: "Guam", value: "GU" },
        { label: "Hawaii", value: "HI" },
        { label: "Idaho", value: "ID" },
        { label: "Illinois", value: "IL" },
        { label: "Indiana", value: "IN" },
        { label: "Iowa", value: "IA" },
        { label: "Kansas", value: "KS" },
        { label: "Kentucky", value: "KY" },
        { label: "Louisiana", value: "LA" },
        { label: "Maine", value: "ME" },
        { label: "Marshall Islands", value: "MH" },
        { label: "Maryland", value: "MD" },
        { label: "Massachusetts", value: "MA" },
        { label: "Michigan", value: "MI" },
        { label: "Minnesota", value: "MN" },
        { label: "Mississippi", value: "MS" },
        { label: "Missouri", value: "MO" },
        { label: "Montana", value: "MT" },
        { label: "Nebraska", value: "NE" },
        { label: "Nevada", value: "NV" },
        { label: "New Hampshire", value: "NH" },
        { label: "New Jersey", value: "NJ" },
        { label: "New Mexico", value: "NM" },
        { label: "New York", value: "NY" },
        { label: "North Carolina", value: "NC" },
        { label: "North Dakota", value: "ND" },
        { label: "Northern Mariana Islands", value: "MP" },
        { label: "Ohio", value: "OH" },
        { label: "Oklahoma", value: "OK" },
        { label: "Oregon", value: "OR" },
        { label: "Palau", value: "PW" },
        { label: "Pennsylvania", value: "PA" },
        { label: "Puerto Rico", value: "PR" },
        { label: "Rhode Island", value: "RI" },
        { label: "South Carolina", value: "SC" },
        { label: "South Dakota", value: "SD" },
        { label: "Tennessee", value: "TN" },
        { label: "Texas", value: "TX" },
        { label: "Utah", value: "UT" },
        { label: "Vermont", value: "VT" },
        { label: "Virgin Islands", value: "VI" },
        { label: "Virginia", value: "VA" },
        { label: "Washington", value: "WA" },
        { label: "West Virginia", value: "WV" },
        { label: "Wisconsin", value: "WI" },
        { label: "Wyoming", value: "WI" },
      ],
    },
  ];

  //MAXICO Multiple States LIST
  let MXstates = [
    {
      options: [
        { label: "Aguascalientes", value: "AGU" },
        { label: "Baja California Norte", value: "BCN" },
        { label: "Baja California Sur", value: "BCS" },
        { label: "Campeche", value: "CAM" },
        // { label: "Ciudad de México", value: "DIF" },
        { label: "Chiapas", value: "CHP" },
        { label: "Chihuahua", value: "CHH" },
        { label: "Coahuila de Zaragoza", value: "COA" },
        { label: "Colima", value: "COL" },
        { label: "Distrito Federal", value: "DF" },
        { label: "Durango", value: "DUR" },
        { label: "Estado de México", value: "MEX" },
        { label: "Guanajuato", value: "GUA" },
        { label: "Guerrero", value: "GRO" },
        { label: "Hidalgo", value: "HID" },
        { label: "Jalisco", value: "JAL" },
        { label: "Michoacán de Ocampo", value: "MIC" },
        { label: "Morelos", value: "MOR" },
        { label: "Nayarit", value: "NAY" },
        { label: "Nuevo León", value: "NLE" },
        { label: "Oaxaca", value: "OAX" },
        { label: "Puebla", value: "PUE" },
        { label: "Querétaro de Arteaga", value: "QUE" },
        { label: "Quintana Roo", value: "ROO" },
        { label: "San Luis Potosí", value: "SLP" },
        { label: "Sinaloa", value: "SIN" },
        { label: "Sonora", value: "SON" },
        { label: "Tabasco", value: "TAB" },
        { label: "Tamaulipas", value: "TAM" },
        { label: "Tlaxcala", value: "TLA" },
        { label: "Veracruz", value: "VER" },
        { label: "Yucatán", value: "YUC" },
        { label: "Zacatecas", value: "ZAC" },
      ],
    },
  ];

  const [selectedMulti, setselectedMulti] = useState(null);
  const [lang, setLang] = useState(null);

  const [alert, setAlert] = useState(false);
  const [alert_title, setAlertTitle] = useState("Title");
  const [alert_desc, setAlertDesc] = useState("Desc");

  const [selectedSpecialities, setSelectedSpecialities] = useState(null);
  const [speciality, setSpeciality] = useState(null);

  const [selectedMedicalCondition, setSelectedMedicalCondition] =
    useState(null);
  const [medicalConditions, setMedicalConditions] = useState(null);

  const [selectedMultiStates, setSelectedMultiStates] = useState(null);
  const [multiState, setMultiState] = useState(null);

  const [primaryKey, setPrimaryKey] = useState(false);
  const [secondaryKey, setSecondaryKey] = useState(false);
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");

  const [timezoneData, seteTimezoneData] = useState({
    timezoneAbbr: "",
    timezoneUTC: "",
  });
  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [tempCountryName, setTempCountryName] = useState("");
  const [files, setFiles] = useState([]);
  const [responsefile, setResponsefile] = useState([]);

  if (countryName == "US") {
    globalState = USstates;
  }

  if (countryName == "MX") {
    globalState = MXstates;
  }

  const handleMulti = (selectedMulti) => {
    let languages = [];
    selectedMulti &&
      selectedMulti.map((items) => {
        languages.push(items.value);
      });
    setselectedMulti(selectedMulti);
    setLang(languages);
    if (selectedMulti === null) {
      document.querySelector("#language").classList.add("text-danger");
    } else {
      document.querySelector("#language").classList.remove("text-danger");
    }
  };
  const [profile, setProfile] = useState({});

  useEffect(() => {
    DoctorProfile();
  }, []);
  const DoctorProfile = () => {
    let url = `${process.env.REACT_APP_API_PATH}/doctor/${authDoctorId}?populate=false`;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios.get(url, headers).then(function (response) {
      setProfile(response?.data);
      setTempCountryName(response?.data.country);
      setPrimaryPhone(response?.data.primaryPhone);
      setSecondaryPhone(response?.data.secondaryPhone);
      setResponsefile(response.data.doctorSignatureUrl);

      dispatch(
        setSingleDoctor(response.data, "Get Doctor details successfully.", "")
      );
      //Language
      const languageStored = [];
      const languages = response.data.language;
      if (typeof languages !== "string") {
        languages &&
          languages.map((items) => {
            languageStored.push({ label: items, value: items });
          });
      }
      setselectedMulti(languageStored);
      setLang(languages);

      //Specialities
      const specialitiesStored = [];
      const specialities = response.data.specialities;

      if (specialities && specialities.length > 0) {
        specialities.map((items) => {
          return specialitiesStored.push({ label: items, value: items });
        });
      }
      setSelectedSpecialities(specialitiesStored);
      setSpeciality(specialities);

      //Medical Conditions
      const medicalConditionsStored = [];
      const medicalConditions = response.data.medicalConditions;
      medicalConditions &&
        medicalConditions.map((items) => {
          medicalConditionsStored.push({ label: items, value: items });
        });
      setSelectedMedicalCondition(medicalConditionsStored);
      setMedicalConditions(medicalConditions);

      //Multi States
      const multiStatesStored = [];
      const multiStates = response.data.multiStates;

      multiStates &&
        multiStates.map((items) => {
          return multiStatesStored.push({
            label: items.label,
            value: items.value,
          });
        });
      setSelectedMultiStates(multiStatesStored);
      setMultiState(multiStates);
    });
  };

  const handleSpecialities = (selectedSpecialities) => {
    let specialities = [];

    selectedSpecialities &&
      selectedSpecialities.map((items) => {
        return specialities.push(items.value);
      });
    setSelectedSpecialities(selectedSpecialities);
    setSpeciality(specialities);
    if (selectedSpecialities === null) {
      document.querySelector("#specialities").classList.add("text-danger");
    } else {
      document.querySelector("#specialities").classList.remove("text-danger");
    }
  };

  const handleMedicalCondition = (selectedMedicalCondition) => {
    let medicalConditions = [];

    selectedMedicalCondition &&
      selectedMedicalCondition.map((items) => {
        medicalConditions.push(items.value);
      });
    setSelectedMedicalCondition(selectedMedicalCondition);
    setMedicalConditions(medicalConditions);
    if (selectedMedicalCondition === null) {
      document.querySelector("#medicalConditions").classList.add("text-danger");
    } else {
      document
        .querySelector("#medicalConditions")
        .classList.remove("text-danger");
    }
  };

  const updater = (data) => {
    if (countryName === "" || !countryName) {
      setCountryName(data?.country);
    }
    if (stateName === "" || !stateName) {
      setStateName(data?.state);
    }
    if (primaryPhone === "" || !primaryPhone) {
      setPrimaryPhone(data?.primaryPhone);
    }
    if (secondaryPhone === "" || !secondaryPhone) {
      setSecondaryPhone(data?.secondaryPhone);
    }

    if (
      timezoneData.timezoneAbbr == "" ||
      !timezoneData.timezoneAbbr ||
      timezoneData.timezoneUTC == "" ||
      !timezoneData.timezoneAbbr
    ) {
      seteTimezoneData({
        timezoneAbbr: data?.timezoneAbbr,
        timezoneUTC: data?.timezoneUTC,
      });
    }
  };

  useEffect(() => {
    updater(profile);
  }, [profile]);

  useEffect(() => {
    if (tempCountryName === countryName) {
      let url = `${process.env.REACT_APP_API_PATH}/doctor/${authDoctorId}?populate=false`;
      let bearer_token = localStorage.getItem("auth_token");
      const headers = {
        headers: {
          Authorization: bearer_token,
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      };
      axios.get(url, headers).then(function (response) {
        //Multi States
        const multiStatesStored = [];
        const multiStates = response.data.multiStates;

        multiStates &&
          multiStates.map((items) => {
            return multiStatesStored.push({
              label: items.label,
              value: items.value,
            });
          });
        setSelectedMultiStates(multiStatesStored);
        setMultiState(multiStates);
      });
    }
  }, [countryName]);

  const handleValidSubmit = async (event, values) => {
    // values.signature = true;
    // values.doctorSignature = files[0];

    if (lang?.length > 0) {
      values.language = lang;
      document.querySelector("#language").classList.remove("text-danger");
    } else {
      document.querySelector("#language").classList.add("text-danger");
      return;
    }
    if (primaryKey === true) {
      if (!primaryPhone || primaryPhone === "") {
        document.querySelector("#primary_phone").classList.add("text-danger");
        return;
      } else {
        document
          .querySelector("#primary_phone")
          .classList.remove("text-danger");
        values.primaryPhone = `${primaryPhone}`;
      }
    } else {
      if (!primaryPhone || primaryPhone === "") {
        document.querySelector("#primary_phone").classList.add("text-danger");
        return;
      } else {
        document
          .querySelector("#primary_phone")
          .classList.remove("text-danger");
        values.primaryPhone = `${primaryPhone}`;
      }
    }

    if (secondaryKey === true) {
      values.secondaryPhone = `${secondaryPhone}`;
    } else {
      values.secondaryPhone = `${secondaryPhone}`;
    }

    if (speciality?.length > 0) {
      values.specialities = speciality;
      document.querySelector("#specialities").classList.remove("text-danger");
    } else {
      document.querySelector("#specialities").classList.add("text-danger");
      return;
    }

    if (medicalConditions?.length > 0) {
      values.medicalConditions = medicalConditions;
      document
        .querySelector("#medicalConditions")
        .classList.remove("text-danger");
    } else {
      document.querySelector("#medicalConditions").classList.add("text-danger");
      return;
    }

    // if (multiState?.length > 0) {
    //   values.multiStates = multiState;
    //   document.querySelector("#multi-state").classList.remove("text-danger");
    // } else {
    //   document.querySelector("#multi-state").classList.add("text-danger");
    //   return;
    // }

    if (stateName) {
      values.state = stateName;
    } else {
      return;
    }

    if (countryName) {
      values.country = countryName;
    } else {
      return;
    }
    values.timezoneAbbr = timezoneData.timezoneAbbr;
    values.timezoneUTC = timezoneData.timezoneUTC;

    setAlertTitle("Updating");
    setAlertDesc("Updating Profile details");
    setAlert(true);

    let response = "";

    let url = `${process.env.REACT_APP_API_PATH}/doctor/${authDoctorId}`;
    let bearer_token = localStorage.getItem("auth_token");

    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    response = await dctPutDosespotData(url, values, headers).then(
      (data) => data
    );
    setAlertTitle("Updated");
    setAlertDesc("Updated Profile details");

    handaleFeatchsigneture();
  };

  //heare we featch the Signature to server

  const handaleFeatchsigneture = async () => {
    let responsedata = "";
    let signetureformData = new FormData();
    let url = `${process.env.REACT_APP_API_PATH}/doctor/${authDoctorId}`;
    let bearer_token = localStorage.getItem("auth_token");

    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    if (typeof files[0] == "undefined") {
      return "";
    } else {
      signetureformData.append("signature", true);
      signetureformData.append("doctorSignature", files[0]);

      responsedata = await dctPutDosespotData(
        url,
        signetureformData,
        headers
      ).then((data) => data);

      setResponsefile(responsedata?.doctorSignatureUrl);
    }
  };

  const handleUplodesigneture = (File) => {
    setFiles(
      File.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  const handleCancel = (props) => {
    props.history.push("/dashboard");
    return;
  };

  const prewiewFile = files.map((file) => (
    <div className="signature_container" key={file.name}>
      <div>
        <img className="signeture_img" src={file.preview} />
      </div>
    </div>
  ));

  const getUploadParams = ({ meta, file }) => {
    console.log(meta);
    console.log(file);
  };

  return (
    <Fragment>
      <Container fluid>
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            // v.append("doctorsigneture", files[0]);
            handleValidSubmit(e, v);
          }}
        >
          <Row>
            <Col xl={6}>
              <div className="mt-4">
                <h5 className="mb-3">{props.t(`Personal Information`)}</h5>
                <Col lg="10" sm="10">
                  <div className="mt-3">
                    <AvField
                      name="firstName"
                      label={props.t("First Name")}
                      className="form-control"
                      placeholder={props.t("First Name")}
                      type="text"
                      required
                      disabled
                      value={
                        JSON.parse(localStorage.getItem("auth_user")).firstName
                      }
                    />
                  </div>
                </Col>
                <Col lg="10" sm="10">
                  <div className="mt-3">
                    <AvField
                      name="lastName"
                      label={props.t("Last Name")}
                      className="form-control"
                      placeholder={props.t("Last Name")}
                      type="text"
                      required
                      disabled
                      value={
                        JSON.parse(localStorage.getItem("auth_user")).lastName
                      }
                    />
                  </div>
                </Col>
                <Col lg="10" sm="10">
                  <div className="mt-3" id={`language`}>
                    <Label>
                      {props.t(`Preferred spoken language`)}{" "}
                      <i className="fas fa-star-of-life text-danger" />
                    </Label>
                    <Select
                      value={selectedMulti ?? " "}
                      isMulti={true}
                      defaultValue={selectedMulti}
                      onChange={(val) => {
                        handleMulti(val);
                      }}
                      options={optionGroup}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col lg="10" sm="10">
                  <div className="mt-3">
                    <AvField
                      type="select"
                      name="gender"
                      label={props.t("Gender")}
                      value={profile ? profile.gender : "unknown"}
                      required
                    >
                      <option value="unknown">{props.t("Other")}</option>
                      <option value="male">{props.t("Male")}</option>
                      <option value="female">{props.t("Female")}</option>
                    </AvField>
                  </div>
                </Col>

                <Col lg="10" sm="10">
                  <div className={`mt-3 ${Config.names.slugName}-phone-box`} id={`primary_phone`}>
                    <label>{props.t(`Primary Phone`)}</label>
                    <PhoneInput
                      inputProps={{ name: "primaryPhone", required: true }}
                      country={
                        !profile || !profile.country || profile.country == ""
                          ? "us"
                          : profile.country.toLowerCase()
                      }
                      value={primaryPhone}
                      onChange={(primaryPhone) => {
                        setPrimaryPhone("+" + primaryPhone);
                        setPrimaryKey(true);
                      }}
                      enableSearch={true}
                      onlyCountries={["us", "mx"]}
                    />
                  </div>
                </Col>

                <Col lg="10" sm="10">
                  <div className="mt-3" id={`secondary_phone`}>
                    <label>{props.t(`Secondary Phone`)}</label>
                    <PhoneInput
                      inputProps={{ name: "secondaryPhone" }}
                      country={
                        !profile || !profile.country || profile.country == ""
                          ? "us"
                          : profile.country.toLowerCase()
                      }
                      value={secondaryPhone}
                      onChange={(secondaryPhone) => {
                        setSecondaryPhone("+" + secondaryPhone);
                        setSecondaryKey(true);
                      }}
                      enableSearch={true}
                      onlyCountries={["us", "mx"]}
                    />
                  </div>
                </Col>

                <Col lg="10" sm="10">
                  <div className="mt-3">
                    <AvField
                      name="address"
                      label={props.t("Address")}
                      className="form-control"
                      placeholder={props.t("Address")}
                      type="textarea"
                      required
                      value={profile?.address}
                    />
                  </div>
                </Col>

                <Col lg="10" sm="10">
                  <div className="mt-3">
                    <AvField
                      name="addressTwo"
                      label={props.t("Address Line 2")}
                      className="form-control"
                      placeholder={props.t("Address Line 2")}
                      type="textarea"
                      value={profile?.addressTwo}
                    />
                  </div>
                </Col>

                <Col lg="10" sm="10">
                  <div className="mt-3">
                    <AvField
                      name="city"
                      label={props.t("City")}
                      className="form-control"
                      placeholder={props.t("City")}
                      type="textarea"
                      required
                      value={profile?.city}
                    />
                  </div>
                </Col>

                <Col lg="10" sm="10">
                  <div className="form-group">
                    <label htmlFor="country">{props.t(`Select Country`)}</label>
                    <CountryDropdown
                      defaultOptionLabel={
                        countryName === "" ? profile?.country : countryName
                      }
                      labelType="short"
                      valueType="short"
                      whitelist={["US", "MX"]}
                      classes="form-control"
                      onChange={(val) => {
                        setCountryName(val);
                        if (val !== countryName) {
                          setSelectedMultiStates(null);
                          setMultiState(null);
                        }
                      }}
                    />
                  </div>
                </Col>

                <Col lg="10" sm="10">
                  <div className={`form-group ${Config.names.slugName}-select-box`} id="select-state">
                    <label htmlFor="state">{props.t(`Select State`)}</label>
                    <RegionDropdown
                      defaultOptionLabel={
                        stateName === "" ? profile?.state : stateName
                      }
                      country={
                        countryName === "" ? profile?.country : countryName
                      }
                      countryValueType="short"
                      labelType="full"
                      valueType="short"
                      value={stateName === "" ? profile?.state : stateName}
                      classes="form-control"
                      onChange={(val) => {
                        setStateName(val);
                      }}
                    />
                  </div>
                </Col>

                <Col lg="10" sm="10">
                  <div className="form-member">
                    <AvField
                      name="postalCode"
                      label={props.t("Postal Code")}
                      className="form-control"
                      placeholder={props.t("Postal Code")}
                      type="text"
                      required
                      value={profile?.postalCode}
                    />
                  </div>
                </Col>
              </div>
            </Col>

            <Col xl={6}>
              <div className="mt-4">
                <h5 className="mb-3">{props.t(`Professional Information`)}</h5>
                <Col lg="10" sm="10">
                  <div className="mt-3">
                    <Label>
                      {props.t(`NPI`)}{" "}
                      <i
                        className="mdi mdi-information text-info"
                        id="NPIinfo"
                      ></i>
                      <UncontrolledTooltip placement="top" target="NPIinfo">
                        {props.t(
                          `A National Provider Identifier(NPI) is a unique 10-digit identification number issued to health care providers in the United States by the Centers for Medicare and Medical Services(CMS)`
                        )}
                      </UncontrolledTooltip>
                    </Label>
                    <AvField
                      name="npi"
                      className="form-control"
                      placeholder={props.t("NPI")}
                      type="text"
                      required
                      value={profile?.npi}
                    />
                  </div>
                </Col>
                <Col lg="10" sm="10">
                  {/* <div className="mt-3">
                    <AvField
                      name="licenseNumber"
                      label={props.t("License Number")}
                      className="form-control"
                      placeholder={props.t("License Number")}
                      type="text"
                      required
                      value={profile?.licenseNumber}
                    />
                  </div> */}
                </Col>
                <Col lg="10" sm="10">
                  <div className="mt-3">
                    <AvField
                      name="taxIdNumber"
                      label={props.t("Tax ID Number")}
                      className="form-control"
                      placeholder={props.t("Tax ID Number")}
                      type="text"
                      required
                      value={profile?.taxIdNumber}
                    />
                  </div>
                </Col>
                {/* adding multiple state selection */}
                <Col lg="10" sm="10">
                  <div className="mt-3" id={`multi-state`}>
                    <Label>{props.t(`Licensed States`)} </Label>
                    <div>
                      {profile?.licenseInfo ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: renderPhysicanLincenseInfo(
                              profile.licenseInfo
                            ),
                          }}
                        ></p>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col lg="10" sm="10">
                  <div className="mt-3">
                    <AvField
                      name="doctorfees"
                      label={props.t("Doctor fee")}
                      className="form-control"
                      placeholder={props.t("Doctor fee")}
                      type="text"
                      required
                      value={50}
                    />
                  </div>
                </Col>
                <div className="mt-4">
                  <h5 className="mb-3">{props.t(`Timezone settings`)}</h5>
                  <Col lg="10" sm="10">
                    <div className="mt-3">
                      <Label>
                        {props.t(`Timezone`)}{" "}
                        <i className="fas fa-star-of-life text-danger" />
                      </Label>
                      <Select
                        value={{
                          label:
                            timezoneData.timezoneUTC !== ""
                              ? timezoneData.timezoneUTC
                              : profile.timezoneUTC,
                          value:
                            timezoneData.timezoneAbbr === ""
                              ? profile.timezoneAbbr
                              : timezoneData.timezoneAbbr,
                        }}
                        placeholder={props.t(`Timezone`)}
                        onChange={(e) => {
                          seteTimezoneData({
                            timezoneAbbr: e?.value,
                            timezoneUTC: e?.label,
                          });
                        }}
                        name="timezone"
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        className={`${Config.names.slugName}-custom-select-box`}
                        options={dctTimezones}
                      />
                    </div>
                  </Col>
                </div>
                <div className="mt-4">
                  <h5 className="mb-3">{props.t("Professional Statement")}</h5>
                  <Col lg="10" sm="10">
                    <div className="mt-3">
                      <AvField
                        name="type"
                        label={props.t("Type")}
                        className="form-control"
                        placeholder={props.t("Type")}
                        type="text"
                        value={profile?.type}
                      />
                    </div>
                  </Col>
                  <Col lg="10" sm="10">
                    <div className="mt-3" id={`specialities`}>
                      <Label>
                        {props.t(`Specialities`)}{" "}
                        <i className="fas fa-star-of-life text-danger" />
                      </Label>
                      <Select
                        value={selectedSpecialities}
                        defaultValue={selectedSpecialities}
                        isMulti={true}
                        onChange={(val) => {
                          handleSpecialities(val);
                        }}
                        options={specialities}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col lg="10" sm="10">
                    <div className="mt-3" id={`medicalConditions`}>
                      <Label>
                        {props.t(`Medical conditions`)}{" "}
                        <i className="fas fa-star-of-life text-danger" />
                      </Label>
                      <Select
                        value={selectedMedicalCondition}
                        defaultValue={selectedMedicalCondition}
                        isMulti={true}
                        onChange={(val) => {
                          handleMedicalCondition(val);
                        }}
                        options={medicalCondition}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>

                  <Col lg="10" sm="10">
                    <div className="mt-3">
                      <AvField
                        name="personalStatement"
                        label={props.t("Personal Statement")}
                        className="form-control"
                        placeholder={props.t("Personal Statement")}
                        type="textarea"
                        value={profile?.personalStatement}
                      />
                    </div>
                  </Col>
                  <Col lg="10" sm="10">
                    <div className="mt-3">
                      <AvField
                        name="awardsAndAchievements"
                        label={props.t("Awards and Achievements")}
                        className="form-control"
                        placeholder={props.t("Awards and Achievements")}
                        type="textarea"
                        value={profile?.awardsAndAchievements}
                      />
                    </div>
                  </Col>
                  <Col lg="10" sm="10">
                    <div className="mt-3">
                      <AvField
                        name="workExperience"
                        label={props.t("Work Experience")}
                        className="form-control"
                        placeholder={props.t("Work Experience")}
                        type="textarea"
                        value={profile?.workExperience}
                      />
                    </div>
                  </Col>
                  <Col lg="10" sm="10">
                    <p className="dropbox_label"> Signature</p>
                    <div>
                      <Dropzone
                        getUploadParams={getUploadParams}
                        onDrop={(acceptedFiles) => {
                          handleUplodesigneture(acceptedFiles);
                          setFiles(
                            acceptedFiles.map((file) =>
                              Object.assign(file, {
                                preview: URL.createObjectURL(file),
                              })
                            )
                          );
                        }}
                        accept="image/*"
                      // acceptedFiles={[
                      //   ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
                      // ]}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div>
                            <div
                              className="doctor_signature"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div>
                                <i
                                  className="display-4 text-muted bx bxs-cloud-upload"
                                  style={{ fontSize: "30px" }}
                                ></i>
                              </div>
                              <h6>
                                {props.t("Drop files here or click to upload.")}
                              </h6>
                              <p className="font-size-14 text-danger">
                                {props.t("Only *.csv files will be accepted.")}
                              </p>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </Col>
                  <Col lg="10" sm="10">
                    <aside>
                      {typeof files[0] == "undefined" ? (
                        <div className="signature_container">
                          <div>
                            {responsefile ? (
                              <img
                                className="signeture_img"
                                src={responsefile}
                              />
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        prewiewFile
                      )}
                    </aside>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col xl={6}></Col>
          </Row>

          <Row className="mt-3">
            <Col xl={6}></Col>
          </Row>

          <Row className="mt-3">
            <Col xl={11}>
              <Row className="float-right">
                <div className="mr-2">
                  <Button
                    onClick={() => {
                      handleCancel(props);
                    }}
                    color="secondary"
                  >
                    {props.t("Cancel")}
                  </Button>
                </div>
                <div className="ml-2 mr-2">
                  <Button className="My__Profile__btn" color="warning">
                    {props.t("Save Changes")}{" "}
                  </Button>
                </div>
              </Row>
            </Col>
          </Row>
        </AvForm>
        {alert ? (
          <SweetAlert
            success
            title={alert_title}
            onConfirm={() => {
              setAlert(false);
            }}
          >
            {alert_desc}
          </SweetAlert>
        ) : null}
      </Container>
    </Fragment>
  );
}

export default withRouter(withNamespaces()(MyProfile));
