import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Label,
  Badge,
  Alert,
  Button,
} from "reactstrap";

import { connect, useSelector, useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import {
  companyActionSuccessful,
  setMemberListing,
  setSingleCompany,
} from "../../store/actions";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

//Include Both Helper File with needed methods
import { loadingMessage, dctDateFormat } from "../../helpers/Commons";

import axios from "axios";

//Import Create and Update Group
import CreateUpdateCompany from "../../components/Company/CreateUpdateCompany";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CreateUpdateMember from "../../components/Company/CreateUpdateMember";
import CompanyDateRange from "../../components/Company/CompanyDateRange";
import Member from "./Member/members";

const CompanyOverview = (props) => {
  let url = props.history.location.pathname.split("/");
  const companyId = url[url.length - 1];

  const [checkBox, setCheckBox] = useState(false);
  const [isServiceLoader, seIsServiceLoader] = useState(true);

  const [companyData, setCompanyData] = useState({
    gloading: true,
    gdata: null,
    gid: null,
    gmessage: null,
  });

  const [mdata, setMData] = useState({
    mloading: true,
    mmembers: [],
    mmessage: null,
  });

  const [sdata, setSData] = useState({
    sloading: true,
    smembers: [],
    sid: null,
    smessage: null,
  });

  const [searchFormDetails, setSearchFormDetails] = useState({
    memberSearchParam: "",
    type: "filter",
    reset: false,
  });

  const [loading, setLoading] = useState(false);
  const [marblesIsAPICall, setMarblesIsAPICall] = useState(false);
  const [marblesIsAPIError, setMarblesIsAPIError] = useState({
    err: false,
    message: {},
  });

  const dispatch = useDispatch();

  const fetchSingleCompany = (goup_id) => {
    if (!goup_id) {
      return;
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = process.env.REACT_APP_API_PATH + "/company/" + goup_id;
    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data.data;
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(setSingleCompany(response_data, message, null));
        setCompanyData({
          gloading: false,
          gdata: response_data,
          gid: goup_id,

          gmessage: message,
        });
        dispatch(setMemberListing(response.data.data.member, message));
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = "No record found.";
          }
        }
        setCompanyData({
          gloading: false,
          gdata: null,
          gid: null,
          gmessage: message,
        });
        dispatch(setSingleCompany(null, message));
      })
      .then(function () {});
  };

  //Get Group Member Data
  const fetchCompanyServices = (param, currentPage = 1) => {
    let company_id = 0;
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      company_id = split_url ? split_url[3] : "";
      company_id = company_id ? company_id.split("&") : "";
      company_id = company_id ? company_id[0] : "";
    }
    let url = `${process.env.REACT_APP_API_PATH}/company/${company_id}/services`;

    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    axios
      .get(url, headers)
      .then(function (response) {
        const data = response.data;
        let message = "";
        message = data.length > 0 ? "" : "No Result found!";

        // dispatch(setMemberListing(data, message));

        setSData({
          sloading: false,
          smembers: data,
          smessage: message,
        });
        seIsServiceLoader(false);
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        setSData({
          sloading: false,
          smembers: null,
          smessage: message,
        });
        dispatch(setSData(null, message));
      })
      .then(function () {});
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("search");
    let goup_id = "";
    if (msearch) {
      let searchParam = {
        memberSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });

      fetchCompanyServices(searchParam);
    }
    //  fetchCompanyServices();
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    fetchSingleCompany(goup_id);
    fetchCompanyServices();

    //props.match.params.id
  }, []);

  const propsUpdateCompanyData = () => {};

  const { gloading, gid, gmessage } = companyData;
  const { mloading, mpartners, mmessage } = mdata;

  const partner_action = useSelector((state) => state.Partner.action_type);
  const gdata = useSelector((state) => state.Company.singleCompany);

  const backToCompanyListLink = () => {
    return (
      <Link
        to="/company"
        className="btn btn-info waves-effect waves-light mr-2 ml-2"
      >
        <i className="mdi mdi-arrow-left mr-1"></i>
        {props.t("Back to List")}
      </Link>
    );
  };

  const createServiceForCompany = async (serviceListId, companyId) => {
    try {
      seIsServiceLoader(true);
      //---------
      let bearer_token = localStorage.getItem("auth_token");
      const data = {
        serviceListId: serviceListId,
      };
      const headers = {
        headers: {
          Authorization: bearer_token,
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      };
      let url = `${process.env.REACT_APP_API_PATH}/company/${companyId}/service`;
      await axios.post(url, data, headers).then((response) => {
        fetchCompanyServices();
      });
    } catch {
      seIsServiceLoader(false);
    }
  };

  const memberList = (memberList) => {
    return memberList?.length > 0 ? (
      memberList?.map((member, index) => {
        return (
          <Tr key={index} data-row-id={member.id}>
            <Td>
              <div className="avatar-xs">
                <Link
                  // to={"/companies-overview/id/" + member.id}
                  className="text-dark"
                >
                  <span className="avatar-title rounded-circle text-capitalize">
                    {member?.userId?.firstName
                      ? member?.userId?.firstName[0]
                      : ""}
                  </span>
                </Link>
              </div>
            </Td>
            <Td>
              <h5 className="text-truncate font-size-14">
                <Link
                  // to={"/companies-overview/id/" + member.id}
                  className="text-dark"
                >
                  {member?.userId?.firstName}
                </Link>
              </h5>
            </Td>
            <Td>{member?.userId?.lastName}</Td>
            <Td>{member?.userId?.email}</Td>
            <Td>{member?.phone}</Td>
            <Td>
              <Link
                to="#"
                className={
                  member.status === "active"
                    ? "badge badge-soft-primary font-size-11 m-1"
                    : "badge badge-soft-danger font-size-11 m-1"
                }
              >
                {member.status === "active" ? "Active" : "Inactive"}
              </Link>
            </Td>
            {/* <Td>
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"edit" + company.id}
                    onClick={() => {
                      // opencompanyEditModal(company);
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"edit" + company.id}
                    >
                      {props.t("Edit")}
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"status_" + company.id}
                    data-status={company.status}
                    data-id={company.id}
                    onClick={() => {
                      // setconfirm_both(true);
                      // setcompanyStatusMeta({
                      //   rowIndex: index,
                      //   companyId: company.id,
                      //   companyStatus: company.status,
                      // });
                    }}
                  >
                    {company.status === "active" ? (
                      <i className="bx bx-user-check text-success"></i>
                    ) : (
                      <i className="bx bx-user-x text-danger"></i>
                    )}
                    <UncontrolledTooltip
                      placement="top"
                      target={"status_" + company.id}
                    >
                      {company.status ? "Active" : "Inactive"}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul> 
              {confirm_both ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="secondary"
                  onConfirm={() => {
                    toggleDoctorStatus(doctorStatusMeta);
                  }}
                  onCancel={() => {
                    setconfirm_both(false);
                    setsuccess_dlg(false);
                  }}
                >
                  {props.t("This process will change the Doctor status!")}
                </SweetAlert>
              ) : null} 
            </Td>*/}
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="7" className="text-center"></Td>
      </Tr>
    );
  };

  const ManuallyPullDataFromMarbles = () => {
    let bearer_token = localStorage.getItem("auth_token");

    let data = {
      companyId: companyId,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PATH}/api/pull-data-from-marbles`,
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
      data: data,
    };
    setLoading(true);
    axios(config)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          setMarblesIsAPICall(true);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setMarblesIsAPIError({
          ...marblesIsAPIError,
          err: true,
          message: err?.response?.data?.message
            ? err?.response?.data?.message
            : "Manually data pulling failed.",
        });
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                {gmessage ? (
                  <Alert color="danger" className="text-center">
                    {gmessage}
                  </Alert>
                ) : null}

                {gdata ? (
                  <>
                    <CardBody>
                      <div className="group-details-section" data-id={gdata.id}>
                        <Row>
                          <Col lg="4">
                            <Media>
                              <div className="avatar-sm mr-4">
                                <span className="avatar-title font-size-20 rounded-circle">
                                  {gdata.groupId
                                    ? gdata.companyName.charAt(0)
                                    : "D"}
                                </span>
                              </div>
                              <Media className="overflow-hidden" body>
                                <h5 className="text-truncate font-size-15">
                                  {gdata.companyName}
                                </h5>
                                <p className="text-muted">
                                  {props.t("Company Status")}:
                                  <Badge
                                    color="primary"
                                    className="font-size-12 ml-2"
                                  >
                                    {gdata.groupId.status === "active"
                                      ? "Active"
                                      : "Inactive"}
                                  </Badge>
                                </p>
                              </Media>
                            </Media>
                          </Col>
                          <Col lg="4">
                            <CompanyDateRange
                              companyId={companyId}
                              companyName={gdata.companyName}
                            />
                          </Col>
                          <Col lg="4">
                            <div className="text-right">
                              <CreateUpdateCompany
                                updateCompanyDataFlag={propsUpdateCompanyData}
                                company_action_type="edit"
                                company_button_type="edit_btn"
                                gdata={gdata}
                                {...props}
                              />

                              {backToCompanyListLink()}
                            </div>
                          </Col>
                        </Row>

                        <h5 className="font-size-15 mt-4">
                          {props.t("Company Details")}:{" "}
                        </h5>

                        <div className="text-muted mt-4">
                          <Row>
                            <Col lg="6">
                              <p>
                                <i className="bx bx-building text-primary mr-1"></i>{" "}
                                <strong>{props.t("Company Name")}: </strong>{" "}
                                {gdata.companyName}
                              </p>

                              <p>
                                <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                                <strong>{props.t("Company Email")}: </strong>{" "}
                                {gdata.companyEmail}
                              </p>

                              <p>
                                <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                <strong>{props.t("Company Type")}: </strong>{" "}
                                {gdata.companyType}
                              </p>
                              <p>
                                <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                                <strong>{props.t("Company Phone")}: </strong>{" "}
                                {gdata.companyPhone}
                              </p>
                              <p>
                                <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Company Fulfilment")}:{" "}
                                </strong>{" "}
                                {gdata.fulfilment}
                              </p>
                              <p>
                                <i className="bx bx-map text-primary mr-1"></i>{" "}
                                <strong>{props.t("Company Address")}: </strong>{" "}
                                {gdata.companyAddress}
                              </p>
                            </Col>

                            <Col lg="6">
                              <p>
                                <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                <strong>{props.t("Company Details")}: </strong>{" "}
                                {gdata.companyDetails}
                              </p>
                              <p>
                                <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                <strong>{props.t("Company Plan Id")}: </strong>{" "}
                                {gdata.dctPlanId}
                              </p>
                              <p>
                                <i className="bx bx-user text-primary mr-1"></i>{" "}
                                <strong>{props.t("Group Name")}: </strong>{" "}
                                {gdata.groupId.groupName}
                              </p>
                              <p>
                                <i className="bx bx-user text-primary mr-1"></i>{" "}
                                <strong>{props.t("Group Code")}: </strong>{" "}
                                {gdata.groupCode}
                              </p>
                              <p>
                                <i className="bx bx-user text-primary mr-1"></i>{" "}
                                <strong>{props.t("Group Plan Id")}: </strong>{" "}
                                {gdata.planId}
                              </p>
                              <p>
                                <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Group Description")}:{" "}
                                </strong>{" "}
                                {gdata.groupId.groupDescription}
                              </p>

                              <p>
                                <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                                <strong>{props.t("DCT Allow Access")}: </strong>{" "}
                                {gdata.dctAllowAccess === true ? "YES" : "No"}
                              </p>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12">
                              {gdata.groupDescription ? (
                                <div>
                                  <p>
                                    <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                                    <strong>{props.t("Description")}: </strong>{" "}
                                  </p>
                                  <p>{gdata.groupId.groupDescription}</p>
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        </div>

                        <Row className="task-dates">
                          <Col sm="6" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14">
                                <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                                {props.t("Created Date")}
                              </h5>
                              <p className="text-muted mb-0">
                                {dctDateFormat(gdata.createdAt)}
                              </p>
                            </div>
                          </Col>

                          <Col sm="6" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14">
                                <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                                {props.t("Updated Date")}
                              </h5>
                              <p className="text-muted mb-0">
                                {dctDateFormat(gdata.updatedAt)}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-5">
                          <Col lg={6}>
                            <Label className="mb-4 font-size-16">
                              <strong>{props.t("Services")}</strong>
                              {isServiceLoader
                                ? loadingMessage(
                                    props.t("Updating Service....")
                                  )
                                : null}
                            </Label>
                            <Row>
                              <Col lg={7}>
                                <div className="">
                                  <ul className="list-unstyled">
                                    {sdata.smembers.map(
                                      (servicelist, index) => (
                                        <li
                                          key={index}
                                          className="card-header mb-2"
                                        >
                                          <h5 className="font-size-14">
                                            <Label>{servicelist.name}</Label>
                                            <Link
                                              onClick={() => {
                                                createServiceForCompany(
                                                  servicelist.id,
                                                  companyId
                                                );
                                              }}
                                              type="checkbox"
                                              id={servicelist?.id}
                                              value={servicelist?.id}
                                              checked={
                                                servicelist.status === "active"
                                              }
                                              to="#"
                                              className={
                                                servicelist.status === "active"
                                                  ? "badge badge-soft-primary font-size-11 m-1"
                                                  : "badge badge-soft-danger font-size-11 m-1"
                                              }
                                            >
                                              {servicelist.status === "active"
                                                ? "Active"
                                                : "Inactive"}
                                            </Link>
                                          </h5>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          {gdata?.marblesHRIntegration && (
                            <Col lg={6}>
                              <Label className="mb-4 font-size-16">
                                <strong>
                                  {props.t("Marbles HR Integration")}
                                </strong>
                                {/* {isServiceLoader
                                ? loadingMessage(
                                    props.t("Updating Service....")
                                  )
                                : null} */}
                              </Label>
                              <Row>
                                <Col lg={7}>
                                  <div className="">
                                    <p>
                                      <strong>
                                        {props.t("Marbles HR Integration")}:{" "}
                                      </strong>{" "}
                                      {gdata?.marblesHRIntegration
                                        ? "YES"
                                        : "NO"}
                                    </p>
                                    <p>
                                      <strong>
                                        {props.t("Marbles HR Token")}:{" "}
                                      </strong>{" "}
                                      {gdata?.marblesHRToken &&
                                        gdata?.marblesHRToken}
                                    </p>
                                    {gdata?.marblesPullDataLastTime && (
                                      <p>
                                        <strong>
                                          {props.t(
                                            "Date/Time of Last Data Pull"
                                          )}
                                          :{" "}
                                        </strong>{" "}
                                        {/* 12/08/2022 2:07am CST */}
                                        {gdata?.marblesPullDataLastTime &&
                                          `${moment(
                                            gdata?.marblesPullDataLastTime
                                          ).format("MM-DD-YYYY h:mm a")} CST`}
                                      </p>
                                    )}

                                    <Button
                                      type="button"
                                      color="primary"
                                      className="waves-effect waves-light"
                                      style={{
                                        height: "2.7rem",
                                        width: "10rem",
                                      }}
                                      onClick={() => {
                                        ManuallyPullDataFromMarbles();
                                      }}
                                    >
                                      {loading ? (
                                        <>
                                          <span className="dr-mform-spinner">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>
                                          </span>
                                        </>
                                      ) : (
                                        "Manually Pull Data"
                                      )}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </CardBody>

                    <Member
                      origin={"company"}
                      companyId={gdata.id}
                      companyName={gdata.companyName}
                    />
                  </>
                ) : (
                  <CardBody>
                    {gmessage ? (
                      <div className="text-center">
                        {backToCompanyListLink()}
                      </div>
                    ) : (
                      <div className="text-center">
                        {loadingMessage("Loading...")}
                      </div>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        {marblesIsAPICall && (
          <SweetAlert
            title={"Success"}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setMarblesIsAPICall(false);
              // fetchSingleCompany(companyId);
            }}
          >
            {props.t("Manually data pulling started from marbles api ...")}
          </SweetAlert>
        )}

        {marblesIsAPIError.err && (
          <SweetAlert
            title={"Failure"}
            error
            confirmBtnBsStyle="error"
            onConfirm={() => {
              setMarblesIsAPIError({
                ...marblesIsAPIError,
                err: false,
                message: "",
              });
            }}
          >
            {marblesIsAPIError.message}
          </SweetAlert>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { company } = state.Company;
  const { member } = state.Member;
  const { message } = state.Company;
  return { company, member, message };
};

export default withRouter(
  connect(mapStatetoProps, { companyActionSuccessful, setSingleCompany })(
    withNamespaces()(CompanyOverview)
  )
);
