import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  // Table,
  Label,
  ModalFooter,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import { processLogout } from "../../helpers/backend_helper";
import { Config } from "../../Config";

const AcceptRejectMessage = (props) => {
  const { authDoctorId } = useSelector((state) => state.Login);

  const [acceptModal, setAcceptModal] = useState(false);

  const [rejectModal, setRejectModal] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [currentNotification, setCurrentNotification] = useState([]);
  const [currentReply, setCurrentReply] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    loadNotifications();
  }, []);

  const loadNotifications = async () => {
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/post/doctor/${authDoctorId}/notifications`;
    const result = await axios.get(url, headers);

    setNotifications(result.data); //Not pagination
    setCurrentNotification(result.data[0]);
    setIsRefresh(false);

    setInterval(function () {
      loadNotifications();
    }, 3000000);
  };

  const handleAcceptAndReply = async () => {
    let bearer_token = localStorage.getItem("auth_token");
    const data = {
      comment: currentReply.data,
      doctorId: authDoctorId,
    };
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/post/${currentNotification.id}/accepted`;

    await axios
      .patch(url, data, headers)
      .then(function (response) {
        setAcceptModal(false);
        loadNotifications();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            processLogout();
            return;
          }
          setAcceptModal(false);
          loadNotifications();
        }
      });
  };
  const handleRejectAndReply = async () => {
    let bearer_token = localStorage.getItem("auth_token");
    const data = {
      message: currentReply.data,
      doctorId: authDoctorId,
    };
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/post/${currentNotification.id}/rejected`;
    await axios
      .patch(url, data, headers)
      .then(function (response) {
        setRejectModal(false);
        loadNotifications();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            processLogout();
            return;
          }
          setRejectModal(false);
          loadNotifications();
        }
      });
  };
  const onReplyChange = (event) => {
    setCurrentReply({
      data: event.target.value,
    });
  };

  return (
    <Fragment>
      <Container fluid>
        <Modal
          centered={true}
          size="lg"
          isOpen={acceptModal}
          toggle={() => {
            setAcceptModal(!acceptModal);
          }}
          scrollable={true}
          onClosed={(e) => {
            //toggleModalClose(e);
          }}
        >
          <ModalHeader
            toggle={() => {
              setAcceptModal(!acceptModal);
            }}
          >
            {props.t(`Message Section`)}
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <Label>{props.t("Question")}:</Label>
                    <br />
                    <Label>
                      <strong>{currentNotification?.question}</strong>
                    </Label>
                    <br />
                    <Label>{currentNotification?.details}</Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <Label>{props.t("Reply")}:</Label>
                    <br />
                    <textarea
                      className="form-control"
                      name="replay"
                      style={{ height: "130px" }}
                      onChange={onReplyChange}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col lg={8}>
                <button
                  className="btn btn-primary waves-effect waves-light"
                  type="submit"
                  onClick={handleAcceptAndReply}
                >
                  {props.t("Accept & Reply")}
                  <span className="dr-gform-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                </button>
              </Col>
              <Col lg={4}>
                <button
                  className="btn btn-secondary waves-effect waves-light"
                  type="submit"
                  onClick={() => setAcceptModal(!acceptModal)}
                >
                  {props.t("Cancel")}
                  <span className="dr-gform-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                </button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={rejectModal}
          toggle={() => {
            setRejectModal(!rejectModal);
          }}
          scrollable={true}
          onClosed={(e) => {
            //toggleModalClose(e);
          }}
        >
          <ModalHeader
            toggle={() => {
              setRejectModal(!rejectModal);
            }}
          >
            {props.t(`Reject Message Section`)}
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <Label>{props.t("Question")}:</Label>
                    <br />
                    <Label>
                      <strong>{currentNotification?.question}</strong>
                    </Label>
                    <br />
                    <Label>{currentNotification?.details}</Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <Label>{props.t("Reject for Reason(optional)")}:</Label>
                    <br />
                    <textarea
                      className="form-control"
                      name="replay"
                      style={{ height: "130px" }}
                      onChange={onReplyChange}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col lg={6}>
                <button
                  className="btn btn-danger waves-effect waves-light"
                  type="submit"
                  onClick={handleRejectAndReply}
                >
                  {props.t("Reject")}
                  <span className="dr-gform-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                </button>
              </Col>
              <Col lg={6}>
                <button
                  className="btn btn-secondary waves-effect waves-light"
                  type="submit"
                  onClick={() => setRejectModal(!rejectModal)}
                >
                  {props.t("Cancel")}
                  <span className="dr-gform-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                </button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Row>
          <Col lg={7}>
            <Col lg="7">
              <div className="mt-3">
                <h5>{props.t("Messages")}</h5>
              </div>
            </Col>
          </Col>
          <Col lg={5}>
            <div className="text-right mb-2">
              <button
                className="btn btn-success waves-effect waves-light"
                type="button"
                onClick={() => {
                  loadNotifications();
                  setIsRefresh(true);
                }}
              >
                {isRefresh ? (
                  <span className="dr-gform-spinner">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>
                  </span>
                ) : (
                  <i className="mdi mdi-refresh font-size-18 align-middle"></i>
                )}
              </button>
            </div>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col lg={12}>
                <div className="table-responsive">
                  <Table className={`table table-centered table-hover ${Config.names.slugName}-table mt-2`}>
                    <Thead className="thead-light">
                      <Tr>
                        <Th scope="col" style={{ width: "70px" }}>
                          {" "}
                          {props.t("#")}
                        </Th>
                        <Th scope="col" style={{ width: "40%" }}>
                          {props.t("Question")}
                        </Th>
                        <Th scope="col">{props.t("")}</Th>
                        <Th scope="col" style={{ width: "70px" }}>
                          {props.t("Accept/Reject ")}
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {notifications?.map((notification, key) => (
                        <Tr key={key}>
                          <Td>
                            <div className="avatar-xs">
                              <span className="avatar-title rounded-circle">
                                {"D"}
                              </span>
                            </div>
                          </Td>
                          <Td>{notification.question} </Td>
                          <Td>{notification.details}</Td>
                          <Td>
                            <Link
                              to="#"
                              id={"add"}
                              onClick={() => {
                                setAcceptModal(true);
                                setCurrentNotification(notifications[key]);
                              }}
                            >
                              <i className="bx bxs-message-check text-primary ml-3 font-size-20"></i>
                              <UncontrolledTooltip
                                placement="top"
                                target={"add"}
                              >
                                {" "}
                                {props.t("Accept")}{" "}
                              </UncontrolledTooltip>
                            </Link>
                            <Link
                              to="#"
                              id={"reject"}
                              onClick={() => {
                                setRejectModal(true);
                                setCurrentNotification(notifications[key]);
                              }}
                            >
                              <i className="bx bxs-message-x text-danger ml-3 font-size-20"></i>
                            </Link>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};
export default withRouter(withNamespaces()(AcceptRejectMessage));
