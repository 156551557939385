import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;

const MapLeaflet = (props) => {
  const [zoomSize, setZoomSize] = useState(4);
  const [mapPosition, setMapPosition] = useState([33.19781, -86.622388]);

  let pharmacyMarkers = [];
  let pharmacyObj = [];
  pharmacyObj = useSelector((state) => state.Pharmacy);
  let singlePharmacy = pharmacyObj ? pharmacyObj.singlePharmacy : [];
  pharmacyMarkers = pharmacyObj ? pharmacyObj.markers : [];

  const onMarkerClick = (data) => {
    if (!data) return;
    data.PharmacyId = data.pharmacyId;
    data.Address1 = data.address;
    data.City = data.city;
    data.PrimaryFax = data.primaryFax;
    data.PrimaryPhone = data.primaryPhone;
    data.State = data.state;
    data.StoreName = data.name;
    data.ZipCode = data.zipCode;
    data.Latitude = data.lat;
    data.Longitude = data.lng;

    props.updatePharmacy(data);
  };

  useEffect(() => {
    if (pharmacyMarkers) {
      setMapPosition([
        pharmacyMarkers[0].lat,
        pharmacyMarkers[pharmacyMarkers.length - 1].lng,
      ]);
      setZoomSize(20);
    }
  }, [pharmacyMarkers]);

  let oldPharmacyPosition = singlePharmacy
    ? [singlePharmacy.Latitude, singlePharmacy.Longitude]
    : mapPosition;

  const renderPopup = (data) => {
    if (!data) return;

    return (
      <p className="text-muted font-weight-13 ">
        <b>{data.StoreName}</b>
        <br />
        <span className="text-justify">
          {data.Address1}
          <br />
          <span>
            {data.City}, {data.State}
          </span><br />
          <abbr title="Zip" style={{"color": "white"}}> {props.t("Z")}: </abbr>
          {data.ZipCode} <br />
          <abbr title="Phone" style={{"color": "white"}}>{props.t("P")}: </abbr>
          {data.PrimaryPhone} <br />
          <abbr title="Fax" style={{"color": "white"}}>{props.t("F")}: </abbr> {data.PrimaryFax}
        </span>
      </p>
    );
  };

  return (
    <MapContainer
      center={mapPosition}
      zoom={zoomSize}
      style={{ minHeight: "400px" }}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {pharmacyMarkers ? (
        pharmacyMarkers.map((marker) => (
          <Marker position={[marker.lat, marker.lng]} key={marker.key}>
            <Popup>
              <p className="text-muted font-weight-13">
                <b>{marker.name}</b>
                <br />
                <span className="text-justify">
                  {marker.address}
                  <br />
                  <span>
                    {marker.city}, {marker.state}
                  </span><br/>
                  <abbr title="Zip" style={{"color": "white"}}> {props.t("Z")}: </abbr>
                  {marker.zipCode} <br />
                  <abbr title="Phone" style={{"color": "white"}}>{props.t("P")}: </abbr>
                  {marker.primaryPhone} <br />
                  <abbr title="Fax" style={{"color": "white"}}>{props.t("F")}: </abbr>
                  {marker.primaryFax} <br />
                </span>
              </p>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-info btn-small"
                  onClick={() => {
                    onMarkerClick(marker);
                  }}
                >
                  {props.t(`Add Pharmacy`)}
                </button>
              </div>
            </Popup>
          </Marker>
        ))
      ) : (
        <Marker position={oldPharmacyPosition}>
          <Popup>{renderPopup(singlePharmacy)}</Popup>
        </Marker>
      )}
    </MapContainer>
  );
};

// export default withRouter(withNamespaces()(MapLeaflet));

const mapStatetoProps = (state) => {
  // const { singlePharmacy } = state.Pharmacy;
  return {};
  
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(MapLeaflet))
);
