import React, { useState, Fragment , useEffect} from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import axios from "axios";
import {Config} from './../../Config';

const SalesAnalytics = (props) => {
  let data = [
    props.gData ? props.gData.activeMembers : 0,
    props.gData ? props.gData.inactiveMembers : 0,
  ];
  const [options, setOptions] = useState({
    labels: ["Active", "Inactive"],
    colors: [Config.colors.pieChartActiveColor, Config.colors.pieChartInactiveColor, "#f46a6a"],
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  });

  useEffect(()=>{
    setOptions(options)
  })

  return (
    <Fragment>
      <Col xl="4">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">{props.t("Total Members")}</h4>
            <div>
              <div id="donut-chart" className="apex-charts">
                <ReactApexChart
                  options={options}
                  series={data}
                  type="donut"
                  height={240}
                />
              </div>
            </div>

            <div className="text-center text-muted">
              <Row>
                <Col xs="6">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i style={{color: Config.colors.pieChartActiveColor}} className="mdi mdi-circle mr-1"></i>{" "}
                      {props.t("Active")}
                    </p>
                    <h5>
                      {props.gData ? props.gData.activeMembers : "Loading..."}
                    </h5>
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i style={{color: Config.colors.pieChartInactiveColor}} className="mdi mdi-circle mr-1"></i>{" "}
                      {props.t("Inactive")}
                    </p>
                    <h5>
                      {props.gData ? props.gData.inactiveMembers : "Loading..."}
                    </h5>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default withRouter(withNamespaces()(SalesAnalytics));
