import React from "react";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvInput,
  AvFeedback,
  AvGroup,
} from "availity-reactstrap-validation";

// action
import {
  registerUser,
  apiError,
  registerUserFailed,
} from "../../store/actions";

//Include Common Helpers
import { DctFooterCredit } from "../../helpers/Commons";

// import images
import profile from "../../assets/images/profile.svg";
import logo from "../../assets/images/logo.svg";
import { Config } from "../../Config";

const Register = (props) => {
  // handleValidSubmit
  function handleValidSubmit(event, values) {
    // let dob = new Date(values.dob);
    // dob = (dob.getMonth() + 1) + '/' + dob.getDate() + '/' + dob.getFullYear();
    // values.dob = dob;
    document
      .getElementsByClassName("dr-login-spinner")[0]
      .classList.remove("d-none");
    props.registerUser(values);
  }

  //  useEffect(() => {
  //       props.registerUserFailed("");
  //     });

  /* Redirect to Dashboard if user alrady loggedin */
  // if (localStorage.getItem("auth_token")) {
  //   return (
  //       <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
  //   );
  // }

  if (props.user && props.user) {
    setTimeout(() => {
      window.location = "https://www.doctegrity.com";
    }, 5000);
  }
  return (
    <React.Fragment>
      <div
        className=" py-5 bg-black"
        style={{ minHeight: "100vh", height: "100%", marginTop: 0 }}
      >
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center mt-5">
              <Col md={8} lg={6} xl={6} className={`${Config.names.slugName}-desktop`}>
                <img
                  src={require("../../assets/images/loginImage.png")}
                  alt="Banner"
                  className="img-fluid"
                  // style={{objectFit:"cover"}}
                />
              </Col>
              <Col md={8} lg={6} xl={6}>
                <div className="overflow-hidden">
                  <div className={`${Config.names.slugName}-auth-header ${Config.names.slugName}-bg-black`}>
                    <Row>
                      <Col className="col-8">
                        <div className="text-primary p-4 mt-4">
                          <h4 className="text-white text-uppercase">
                            {props.t("Activate Your")}{" "}
                            <strong>{props.t("Benefit !")}</strong>
                          </h4>
                          <p className="text-white">
                            {props.t(
                              "Fill out the form below to activate your CaaeloHealth benefit"
                            )}
                            .
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className={`pt-0 ${Config.names.slugName}-bg-black`}>
                    <div className="p-0">
                      {props.user && props.user ? (
                        <Alert color="success">
                          {props.t(
                            `Doctegrity benefit activation successful! Please check your email for a link to set your password. Thank you!`
                          )}
                        </Alert>
                      ) : (
                        <AvForm
                          className={`form-horizontal ${Config.names.slugName}-dark-theme`}
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v);
                          }}
                        >
                          {props.registrationError &&
                          props.registrationError ? (
                            <Alert color="danger">
                              {props.registrationError}
                            </Alert>
                          ) : null}

                          <div className="form-group">
                            <AvGroup>
                              <Label for="lastName">Enter Your Last Name</Label>
                              <InputGroup className={`${Config.names.slugName}-input-group`}>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    {" "}
                                    <i className="bx bxs-rename"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <AvInput name="lastName" required />
                                <AvFeedback>This field is required.</AvFeedback>
                              </InputGroup>
                            </AvGroup>
                          </div>

                          <div className="form-group">
                            <AvGroup>
                              <Label for="dob">
                                Date of Birth {props.t("(MM/DD/YYYY)")}
                              </Label>
                              <InputGroup className={`${Config.names.slugName}-input-group`}>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    {" "}
                                    <i className="bx bx-calendar"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <AvInput
                                  name="dob"
                                  type="text"
                                  validate={{ date: { format: "MM/DD/YYYY" } }}
                                  required
                                  title={props.t("Use MM/DD/YYYY")}
                                />
                                <AvFeedback>This field is required.</AvFeedback>
                              </InputGroup>
                            </AvGroup>
                          </div>

                          <div className="form-group">
                            <AvGroup>
                              <Label for="postalCode">Postal Code</Label>
                              <InputGroup className={`${Config.names.slugName}-input-group`}>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    {" "}
                                    <i className="bx bx-pin"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <AvInput name="postalCode" required />
                                <AvFeedback>This field is required.</AvFeedback>
                              </InputGroup>
                            </AvGroup>
                          </div>

                          <div className="mt-4">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                            >
                              <span className="dr-login-spinner d-none">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                              </span>{" "}
                              {props.t("Activate my Doctegrity Benefit")}
                            </button>
                          </div>

                          {/* <div className="mt-4 text-center">
                            <p className="mb-0">
                              By registering you agree to the Doctegrity{" "}
                              <Link to="#" className="text-primary">
                                Terms of Use
                              </Link>
                            </p>
                          </div> */}
                        </AvForm>
                      )}
                    </div>
                  </CardBody>
                </div>
                <div className="mt-5 text-center">
                  <p>
                    {props.t(`Already have an account ?`)}{" "}
                    <Link
                      to="/login"
                      className="font-weight-medium text-primary"
                    >
                      {" "}
                      {props.t(`Login`)}
                    </Link>{" "}
                  </p>
                  {DctFooterCredit(Config.names.appName)}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default withRouter(
  connect(mapStatetoProps, { registerUser, apiError, registerUserFailed })(
    withNamespaces()(Register)
  )
);
