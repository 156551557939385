import {
  CREATE_DOCTOR,
  UPDATE_DOCTOR,
  GET_DOCTOR,
  GET_DOCTOR_LIST,
  SET_DOCTOR_LIST,
  CREATE_DOCTOR_SUCCESS,
  CREATE_DOCTOR_FAILED,
  API_ERROR,
  SET_SINGLE_DOCTOR,
  SET_DOCTOR_LISTING,
  UPDATE_TELEVISIT,
  GET_TELEVISIT,
  GET_TELEVISIT_LIST,
  SET_TELEVISIT_LIST,
  SET_TELEVISIT_LISTING,
  SET_SINGLE_TELEVISIT,

  GET_TELEVISIT_HISTORY_LIST,
  SET_TELEVISIT_HISTORY_LIST,
  SET_TELEVISIT_HISTORY_LISTING,
} from "./actionTypes";

export const updateTelevisit = (televisit) => ({
  type: UPDATE_TELEVISIT,
  payload: { televisit },
});

export const getTelevisit = (televisit) => ({
  type: GET_TELEVISIT,
  payload: { televisit },
});

export const getTelevisitList = (televisit) => ({
  type: GET_TELEVISIT_LIST,
  payload: { televisit },
});

export const getTelevisitHistoryList = (televisit) => ({
  type: GET_TELEVISIT_HISTORY_LIST,
  payload: { televisit },
});

export const setTelevisitList = (televisit, message, action) => {
  return {
    type: SET_TELEVISIT_LIST,
    payload: televisit,
    message: message,
    action_type: action,
  };
};
export const setTelevisitHistoryList = (televisit, message, action) => {
  return {
    type: SET_TELEVISIT_HISTORY_LIST,
    payload: televisit,
    message: message,
    action_type: action,
  };
};


export const setSingleTelevisit = (televisit, message, action) => {
  return {
    type: SET_SINGLE_TELEVISIT,
    payload: televisit,
    message: message,
    action_type: action,
  };
};

export const setTelevisitListing = (televisit, message) => {
  return {
    type: SET_TELEVISIT_LISTING,
    payload: televisit,
    televisitListing: televisit,
    message: message,
  };
};

export const setTelevisitHistoryListing = (televisit, message) => {
  return {
    type: SET_TELEVISIT_HISTORY_LISTING,
    payload: televisit,
    televisitHistoryListing: televisit,
    message: message,
  };
};

export const createDoctor = (doctor) => ({
  type: CREATE_DOCTOR,
  payload: { doctor },
});

export const updateDoctor = (doctor) => ({
  type: UPDATE_DOCTOR,
  payload: { doctor },
});

export const getDoctor = (doctor) => ({
  type: GET_DOCTOR,
  payload: { doctor },
});

export const getDoctorList = (doctor) => ({
  type: GET_DOCTOR_LIST,
  payload: { doctor },
});

export const setDoctorList = (doctor, message, action) => {
  return {
    type: SET_DOCTOR_LIST,
    payload: doctor,
    message: message,
    action_type: action,
  };
};

export const setSingleDoctor = (doctor, message, action) => {
  return {
    type: SET_SINGLE_DOCTOR,
    payload: doctor,
    message: message,
    action_type: action,
  };
};

export const setDoctorListing = (doctor, message) => {
  return {
    type: SET_DOCTOR_LISTING,
    payload: doctor,
    doctorListing: doctor,
    message: message,
  };
};

export const doctorActionSuccessful = (doctor, message, action) => {
  return {
    type: CREATE_DOCTOR_SUCCESS,
    payload: doctor,
    message: message,
    action_type: action,
  };
};

export const createDoctorFailed = (error) => {
  return {
    type: CREATE_DOCTOR_FAILED,
    payload: error,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
