import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardBody } from "reactstrap";

// import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import axios from "axios";

import { useSelector } from "react-redux";

// import { getAuthIds } from "../../helpers/Commons";

const MiniWidget = (props) => {
  const { authPartnerId } = useSelector((state) => state.Login);
  const { rxPrescriptionListing } = useSelector((state) => state.rxPrescription);

  // const [totalPartners, setTotalPartners] = useState("Loading...");
  const [totalMembers, setTotalMembers] = useState("Loading...");
  // const [totalAppointments, setTotalAppointments] = useState("Loading...");

  //Fetch Widget Data
  const getEssentials = () => {
    // let url =
    //   process.env.REACT_APP_API_PATH + "/partner/" + authPartnerId + "/essentials";
    // let bearer_token = localStorage.getItem("auth_token");
    // const headers = {
    //   headers: {
    //     Authorization: bearer_token,
    //     PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
    //   },
    // };

    // axios.get(url, headers).then(function (response) {
    //   let data = response.data?.result;
      setTotalMembers(rxPrescriptionListing ? rxPrescriptionListing.length : "0");
      // setTotalPartners(data ? data.totalPartners : "0");
      // setTotalAppointments(data ? data.totalAppointments : "0");
    // });
  };

  // const getTotalMembers = () => {
  //   let url = process.env.REACT_APP_API_PATH + "/partner/count";
  //   let bearer_token = localStorage.getItem("auth_token");
  //   const headers = {
  //     headers: {
  //       Authorization: bearer_token,
  //       PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
  //     },
  //   };

  //   axios.get(url, headers).then(function (response) {
  //     let data = response.data;
  //   });
  // };

  // const getTotalAppointmentsCount = () => {
  //   let url = process.env.REACT_APP_API_PATH + "/counsultation/count";
  //   let bearer_token = localStorage.getItem("auth_token");
  //   const headers = {
  //     headers: {
  //       Authorization: bearer_token,
  //       PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
  //     },
  //   };

  //   axios.get(url, headers).then(function (response) {
  //     let data = response.data;
  //     setTotalAppointments(data ? data.count : "0");
  //   });
  // };

  useEffect(() => {
    //the apis
    getEssentials();
    // getTotalMembers()
    // getTotalAppointmentsCount()
  }, [rxPrescriptionListing]);

  return (
    <Fragment>
      {/* <Col xl="3">
        <Card>
          <CardBody>
            <Row>
              <Col xs="12">
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i className="fas fa-handshake"></i>
                    </span>
                  </div>
                  <h5 className="font-size-16 mb-0">
                    {props.t("Total Partners")}
                  </h5>
                </div>
              </Col>
              <Col xs="12" className="align-self-end">
                <div className="text-muted mt-3 pb-0">
                  <h1>{totalPartners}</h1>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col> */}
      <Col sm="4">
        <Card>
          <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i className="fas fa-users"></i>
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">
                    {props.t("Total Prescriptions")}
                  </h5>
                </div>
                <div className="text-muted mt-4">
                  <h4 className="font-size-24">{rxPrescriptionListing ? rxPrescriptionListing.length : "0"}</h4>
                </div>
          </CardBody>
        </Card>
      </Col>
      {/* <Col xl="3">
        <Card>
          <CardBody>
            <Row>
              <Col xs="12">
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i className="fas fa-users"></i>
                    </span>
                  </div>
                  <h5 className="font-size-16 mb-0">
                    {props.t("Total Appointments")}
                  </h5>
                </div>
              </Col>
              <Col xs="12" className="align-self-end">
                <div className="text-muted mt-3 pb-0">
                  <h1>{totalAppointments}</h1>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col> */}
    </Fragment>
  );
};

export default withRouter(withNamespaces()(MiniWidget));
