import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { connect } from "react-redux";

import { withNamespaces } from "react-i18next";

import { dctDateFormat } from "../../../helpers/Commons";

import axios from "axios";
import { Config } from "../../../Config";

const EventLogs = (props) => {

  const [eventLogs, setEventLogs] = useState([]);

  const fetchEventLogs = () => {
    const headers = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/eventlog?sort=updatedAt%20ASC` 
        + `&where={"isActive":true,"servedBy":{"not":null}}`;
    axios
      .get(url, headers)
      .then((response) => {
        setEventLogs(response?.data?.data ? response.data.data : []);
      })
      .catch((err) => {
        console.log(err.response);
        setEventLogs([]);
      });
  };

  useEffect(() => {
    fetchEventLogs();
    const interval = setInterval(() => {
      fetchEventLogs()
    }, 10000);
    return () => {
      clearInterval(interval);
    }
  }, []);

  const renderEventLogs = (logs) => {
    return logs.length > 0 ? (
        logs.map((log, index) => {
        return (
          <Tr key={index} data-row-id={log.id}>
            <Td className="text-left">
              <div>{dctDateFormat(log.createdAt)}</div>
            </Td>
            <Td className="text-left">{log.objectType}</Td>
            <Td className="text-left">{log.eventType}</Td>
            <Td className="text-left">{log.channelName}</Td>
            <Td className="text-left">
              <div>{dctDateFormat(log.updatedAt)}</div>
            </Td>
            <Td className="text-left">
              <h5 className="text-truncate font-size-14">
              {log.servedBy?.firstName ? log.servedBy.firstName : ''}
              &nbsp;
              {log.servedBy?.lastName ? log.servedBy.lastName : ''}
              </h5>
            </Td>
            <Td className="text-left">
              <Link
                to={"#"}
                id={"release-" + log.id}
                onClick={()=>{releaseEventLog(log.id, log.servedBy?.id)}}
                className="text-dark"
              >
                <i className="mdi mdi-lock-open"></i>
                <UncontrolledTooltip
                    placement="top"
                    target={"release-" + log.id}
                >
                    {props.t("Release")}
                </UncontrolledTooltip>
              </Link>
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="7" className="text-center"></Td>
      </Tr>
    );
  };

  const releaseEventLog = async (eventId, userId) => {
    if (!userId || userId === '') return;
    if (!eventId || eventId === '') return;
    let url = `${process.env.REACT_APP_API_PATH}/eventlog/${eventId}/released`;
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const data= {
      userId: userId,
    };
    axios
    .patch(url, data, options)
    .then((response) => {
      removeReleasedEventLog(eventId);
    })
    .catch((error)=> {
      console.log('Could not release. Error -> ', error.response.data);
    }); 
  };

  const removeReleasedEventLog = async (eventId) => {
    const eventCount = eventLogs ? eventLogs.length : 0;
    let logs = [];
    for (let index = 0; index < eventCount; index++) {
        let log = eventLogs[index];
        if (log.id !== eventId) {
          logs.push(log);
        }
      }
    setEventLogs(logs);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h5 className="font-size-15 mt-4">
            {props.t("Event Logs")} :
          </h5>
          <Row>
            <Col lg="12">
              <div className="table-responsive">
                <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}>
                  <Thead className="thead-light">
                    <Tr>
                      <Th scope="col" className="text-left">{props.t("Created At")}</Th>
                      <Th scope="col" className="text-left">{props.t("Object Type")}</Th>
                      <Th scope="col" className="text-left">{props.t("Event Name")}</Th>
                      <Th scope="col" className="text-left">{props.t("Channel Name")}</Th>
                      <Th scope="col" className="text-left">{props.t("Reserved At")}</Th>
                      <Th scope="col" className="text-left">{props.t("Reserved By")}</Th>
                      <Th scope="col" className="text-left">{props.t("Actions")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>{renderEventLogs(eventLogs)}</Tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(EventLogs))
);