import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Config } from "../../Config";
import { loadingMessage } from "../../helpers/Commons";
import { useDispatch, useSelector } from "react-redux";
import { setSingleErrConsult } from "../../store/failedConsultations/actions";
import { Link, useHistory } from "react-router-dom";

const FailedConsultations = () => {
  const dispatch = useDispatch();
  const router = useHistory();
  const [loading, setLoading] = useState(false);
  const [consultList, setConsultList] = useState([]);
  const fetchConsultations = async () => {
    const headers = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/api/error-consultations`;
    setLoading(true);
    await axios
      .get(url, headers)
      .then((res) => {
        setLoading(false);
        // console.log(res.data);
        setConsultList(res.data.data.reverse());
      })
      .catch((err) => {
        setLoading(false);
        setConsultList([]);
      });
  };

  const storeAndNavigate = (data) => {
    dispatch(setSingleErrConsult(data));
    router.push(`/failed-consultations-overview/${data.id}`);
  };

  useEffect(() => {
    fetchConsultations();
  }, []);

  return (
    <div className="page-content">
      {loading ? (
        <div className="text-center">{loadingMessage("Loading...")}</div>
      ) : (
        <Card>
          <CardBody>
            <Row className="d-flex flex-row mr-2">
              <p className="font-size-16 text-white bg-danger ml-auto px-2 rounded-lg">
                Total- {consultList.length}
              </p>
            </Row>
            <div className="group-list">
              <div className="table-responsive  ">
                <Table
                  className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table rx_report `}
                >
                  <Thead className="thead-light ">
                    <Tr>
                      <Th scope="col" className="text-left">
                        #
                      </Th>
                      <Th scope="col" className="text-left">
                        Patient Name
                      </Th>
                      <Th scope="col" className="text-left">
                        Platform
                      </Th>
                      <Th scope="col" className="text-left">
                        Type
                      </Th>
                      <Th scope="col" className="text-left">
                        Country
                      </Th>
                      <Th scope="col" className="text-left">
                        Date & Time
                      </Th>
                      <Th scope="col" className="text-left">
                        Schedule Date
                      </Th>
                      <Th scope="col" className="text-left">
                        From-To
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {consultList.length > 0 && (
                      <>
                        {consultList.map((val, i) => {
                          return (
                            <Tr
                              rx_reportscope="row"
                              key={i}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                storeAndNavigate(val);
                              }}
                            >
                              <Td className="text-center">
                                <Link
                                 to={`/failed-consultations-overview/${val.id}`}
                                 className="text-dark"
                                >
                                  <span
                                    className="avatar-title rounded-circle text-capitalize"
                                    style={{ width: "30px", height: "30px" }}
                                  >
                                    {val.patient.slice(0, 1)}
                                  </span>
                                </Link>
                              </Td>
                              <Td className="text-left">{val?.patient}</Td>
                              <Td className="text-left">
                                {val?.consultationPlatform || "--"}
                              </Td>
                              <Td className="text-left text-capitalize">
                                {val.consultationType === "phone" ? (
                                  <i className="fa fa-phone-alt text-warning"></i>
                                ) : (
                                  <i className="fa fa-video text-success"></i>
                                )}
                              </Td>
                              <Td className="text-left">
                                {val?.state_country || "--"}
                              </Td>
                              <Td className="text-left">
                                <p className="m-0">
                                  {moment(val?.createdAt).format(
                                    "MMM Do YYYY , h:mm A"
                                  )}
                                </p>
                                <p className="m-0">
                                  {moment(val?.createdAt).fromNow()}
                                </p>
                              </Td>

                              <Td className="text-left">
                                {moment(val?.schedule_to).format("MMM Do YYYY")}
                              </Td>

                              <Td className="text-left">
                                {moment(val?.schedule_from).format("h:mm A")}-
                                {moment(val?.schedule_to).format("h:mm A")}
                              </Td>
                            </Tr>
                          );
                        })}
                      </>
                    )}
                  </Tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default FailedConsultations;
