import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { withRouter} from 'react-router-dom';
import { withNamespaces } from "react-i18next";
import { Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Card, Media } from "reactstrap";
import { Modal } from "../../../components/DoctegrityModal";

// Import Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const EmailSideBar = (props) => {

 const [modal, setmodal] = useState(false);

    return (
             <React.Fragment>
                <Card className="email-leftbar">
                    <Button type="button" color="danger" className="waves-effect waves-light" onClick={()=> { setmodal(!modal) }} block>
                       {props.t(" Compose")}
                    </Button>
                    <div className="mail-list mt-4">
                        <Link to="inbox" className="active"><i className="mdi mdi-email-outline mr-2"></i> {props.t("Inbox")} <span className="ml-1 float-right">(2)</span></Link>
                        <Link to="#"><i className="mdi mdi-star-outline mr-2"></i>{props.t("Starred")}</Link>
                        <Link to="#"><i className="mdi mdi-diamond-stone mr-2"></i>{props.t("Important")}</Link>
                        <Link to="#"><i className="mdi mdi-file-outline mr-2"></i>{props.t("Draft")}</Link>
                        <Link to="#"><i className="mdi mdi-email-check-outline mr-2"></i>{props.t("Sent Mail")}</Link>
                        <Link to="#"><i className="mdi mdi-trash-can-outline mr-2"></i>{props.t("Trash")}</Link>
                    </div>

                </Card>

                <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabIndex="-1" toggle={() => { setmodal(!modal) }}>

                    <div className="modal-content">
                        <ModalHeader toggle={() => { setmodal(!modal) }}>
                           {props.t(" New Message")}
                            </ModalHeader >
                        <ModalBody>
                            <form>
                                <FormGroup>
                                    <Input type="email" className="form-control" placeholder="To" />
                                </FormGroup>

                                <FormGroup>
                                    <Input type="text" className="form-control" placeholder="Subject" />
                                </FormGroup>
                                <Editor
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                />

                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={() => { setmodal(!modal) }}>{props.t("Close")}</Button>
                            <Button type="button" color="primary">{props.t("Send")} <i className="fab fa-telegram-plane ml-1"></i></Button>
                        </ModalFooter>
                    </div>
                </Modal>
            </React.Fragment>
          );
    }
    export default withRouter(withNamespaces("translation")(EmailSideBar)); 
        
