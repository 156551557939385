import React, { useEffect, useState } from "react";


const AlertMessage = (props) => {
  const [alertData, setAlertData] = useState({
    title: props.title,
    message: props.message,
    type: props.type,
    login: props.login,
    forgot: props.forgot,
  });

  useEffect(() => {
    setAlertData({
      ...alertData,
      title: props.title,
      message: props.message,
      type: props.type,
      login: props.login,
    });
  }, [props]);


  return (
    <>
      {props.show && <>
        <div className={`rounded-lg ${alertData.type === "success" ? "bg-alertSuccess" : "bg-alertError"} py-2 px-3 mt-4`}>
          <p className={`${alertData.type === "success" ? "text-alertSuccess" : "text-alertError"} font-italic font-size-14`}>
            <span className="font-weight-bold text-capitalize">
              {`${alertData.title}`} &#58;
            </span>
            {` ${alertData.message}`}
          </p>
        </div>
        {alertData.type === "success" && alertData.forgot && (
          <>

            <div
              className={`mt-2 py-3 px-3 font-weight-light bg-alertWarning font-italic rounded-lg`}
            >
              <p>
                <span className="text-capitalize font-weight-bold">*Note&#58;</span> In case you
                don&#8217;t see the email&#8218; please check your{" "}
                <span className="text-uppercase font-weight-bold">spam</span> folder
                or&nbsp;
                <span className="font-weight-bold">
                  refresh your email inbox.
                </span>
              </p>
            </div>
          </>
        )}
      </>}
    </>
  );
};

export default AlertMessage;
