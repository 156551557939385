import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledTooltip,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
} from "reactstrap";

import { connect, useSelector, useDispatch } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from "moment";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import scrollToComponent from "react-scroll-to-component";

import {
  postMessageActionSuccessful,
  postCommentActionSuccessful,
  createPostCommentFailed,
  setSinglePostMessage,
  getSinglePostMessage,
  updatePostComment,
  createPostComment,
} from "../../store/actions";

import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { loadingMessage, dctDateFormat } from "../../helpers/Commons";
import { Config } from "../../Config";

const PostMessageChatWindow = (props) => {
  const dispatch = useDispatch();
  const jumpToCommentBox = React.createRef();
  const { authUserId, authDoctorId, authMemberId, authUserType } = useSelector(
    (state) => state.Login
  );

  const [openPostDetails, setOpenPostDetails] = useState(false);

  const [singleComment, setSingleComment] = useState({
    id: null,
    body: null,
    ownerId: null,
    postId: null,
    action: "create",
  });

  let pcData = "";
  const pmData = useSelector((state) => state.PostMessage.singlePostMessage);

  const postMessageData = useSelector((state) => state.PostMessage);

  const message = postMessageData ? postMessageData.message : "";
  pcData = postMessageData.postComment ? postMessageData.postComment.data : "";
  let doctorLastCommentHour = "";

  if(pcData){
    const lastComment = pcData[pcData?.length - 1];
    if(lastComment?.doctorId) {
      const now = moment(); // today date
      const end = moment(lastComment.updatedAt); // another date
      const duration = moment.duration(now.diff(end));
      const hours = duration.asHours();
      doctorLastCommentHour = hours;
    }
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let post_id = "";
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      post_id = split_url && split_url[5] ? split_url[5] : "";
    }
    post_id = props.setPostData ? props.setPostData.id : post_id;
    props.getSinglePostMessage(post_id);
  }, [props.setPostData]);

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-form-spinner")[0]
      .classList.remove("d-none");

    // call reducer method
    if (values.actionType === "edit") {
      props.updatePostComment(values, props.history);
      setSingleComment({
        ...singleComment,
        id: 0,
        action: "create",
      });
    } else {
      props.createPostComment(values, props.history);
    }
    if (document.querySelector("#comment_body")) {
      document.querySelector("#comment_body").value = "";
    }
    setSingleComment({ ...singleComment, body: "" });
  }

  const editPostComment = (comment) => {
    if (!comment) return;

    scrollToComponent(jumpToCommentBox.current, {
      offset: 0,
      align: "top",
      duration: 500,
      ease: "inExpo",
    });

    setSingleComment({
      id: comment.id,
      body: comment.body,
      ownerId: comment.ownerId,
      postId: comment.postId,
      action: "edit",
    });
  };

  const resetCommentForm = () => {
    setSingleComment({
      id: "",
      body: "",
      ownerId: "",
      postId: "",
      action: "create",
    });
  };
  const getCommentAuthor = (comment) => {
    let author = "You";
    if (!comment) return author;
    if (authUserType === "doctor") {
      author = comment.ownerId === authUserId ? "You" : "Patient";
    }
    if (authUserType === "member") {
      author = comment.ownerId === authUserId ? "You" : "Doctor";
    }
    return author;
  };

  const renderComments = (comments, message) => {
    return comments && comments.length >= 0 ? (
      comments.map((comment, indx) => {
        let index = indx + 1;
        return (
          <li
            key={index}
            className={comment.ownerId === authUserId ? "right" : "left"}
          >
            <div className="conversation-list">
              {comment.ownerId === authUserId ? (
                <UncontrolledDropdown>
                  <DropdownToggle className="btn nav-btn" tag="i">
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </DropdownToggle>
                  <DropdownMenu direction="right">
                    <DropdownItem
                      onClick={() => {
                        editPostComment(comment);
                      }}
                    >
                      {props.t("Edit")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}

              <div className="ctext-wrap">
                <div className="conversation-name">
                  {getCommentAuthor(comment)}
                </div>
                <p>{comment.body}</p>
                <p className="chat-time mb-0">
                  <i className="bx bx-time-five align-middle mr-1"></i>{" "}
                  {moment(comment.updatedAt).fromNow()}
                </p>
              </div>
            </div>
          </li>
        );
      })
    ) : (
      <li className="text-center">{loadingMessage(message ? message : "")}</li>
    );
  };

  const backToPostListForSm = () => {
    let dynamicMsgUrl = "/member/ask-doctor";
    if (authUserType === "doctor") {
      dynamicMsgUrl = "/doctor/messages";
    }
    props.history.push(dynamicMsgUrl);
    dispatch(setSinglePostMessage(null, "", ""));
    props.isCommentData(false);
    props.openPostListPanel(true);
    props.openCommentWindow(false);
  };

  const openPostMessageCreateModal = () => {
    dispatch(setSinglePostMessage(null, "", ""));
    props.history.push(`/member/ask-doctor/create`);
  };

  const getMessageStatusInfo = (status, type) => {
    let statusCls = "";
    let statusMsg = "";
    let statusDisabled = false;

    switch (status) {
      case "accepted":
        statusCls = "text-success";
        statusMsg = "";
        break;
      case "active":
        statusCls = "text-info";
        statusDisabled = authUserType === "member" ? true : false;
        statusMsg =
          "This message is not accepted yet, so you can't comment on it.";
        break;
      case "inactive":
        statusCls = "text-warning";
        statusDisabled = true;
        statusMsg =
          "This message is not activated yet, so you can't comment on it.";
        break;
      case "archive":
        statusCls = "text-danger";
        statusDisabled = true;
        statusMsg = "This message is archived, so you can't comment on it.";
        break;
      case "timePassed":
        statusCls = "text-danger";
        statusDisabled = true;
        statusMsg = "You cannot comment on it as time has been passed.";
        break;
      default:
        statusCls = "";
        statusMsg = "";
        break;
    }
    if (type === "class") {
      return statusCls;
    }
    if (type === "message") {
      return statusMsg;
    }
    if (type === "disabled") {
      return statusDisabled;
    }
  };

  return (
    <React.Fragment>
      <div className={`${Config.names.slugName}-row-sm-0`}>
        <Row>
          <Col lg="12">
            <Card className="mb-0">
              <div className="p-4 border-bottom">
                <Row>
                  <Col md="8" xs="9">
                    <ul className="list-inline user-chat-nav text-left mb-0 d-flex">
                      <li className="list-inline-item d-lg-none d-sm-block">
                        <div
                          className="dropdown"
                          onClick={() => {
                            backToPostListForSm();
                          }}
                        >
                          <i id="reload_message" className="btn nav-btn">
                            <i className="bx bx-arrow-back"></i>
                          </i>

                          <UncontrolledTooltip
                            placement="top"
                            target="reload_message"
                          >
                            <span className="text-capitalize">
                              {props.t(`Reload Messages`)}
                            </span>
                          </UncontrolledTooltip>
                        </div>
                      </li>

                      <li className="list-inline-item d-block overflow-hidden">
                        <h5 className="font-size-15 mb-1 text-truncate">
                          <i
                            id="post_status"
                            className={`mdi mdi-circle align-middle mr-1 d-inline-block font-size-12 ${getMessageStatusInfo(
                              pmData?.status,
                              "class"
                            )}`}
                          ></i>
                          <UncontrolledTooltip
                            placement="top"
                            target="post_status"
                          >
                            <span className="text-capitalize">
                              {pmData?.status}
                            </span>
                          </UncontrolledTooltip>

                          <span>{pmData?.question}</span>
                        </h5>
                        <p className="text-truncate text-muted mb-0">
                          {pmData?.details}
                        </p>
                      </li>
                    </ul>
                  </Col>
                  <Col md="4" xs="3">
                    <ul className="list-inline user-chat-nav text-right mb-0">
                      {/* {authUserType === "member" ? (
                        <li className="list-inline-item d-none d-sm-inline-block">
                          <div
                            className="dropdown"
                            id="compose_message"
                            onClick={() => {
                              openPostMessageCreateModal();
                            }}
                          >
                            <i className="btn nav-btn">
                              <i className="bx bx-plus"></i>
                            </i>
                            <UncontrolledTooltip
                              placement="top"
                              target="compose_message"
                            >
                              {props.t(`Compose Message`)}
                            </UncontrolledTooltip>
                          </div>
                        </li>
                      ) : null} */}

                      <li className="list-inline-item d-sm-inline-block">
                        <div
                          className="dropdown"
                          id="view_post_details"
                          onClick={() => {
                            setOpenPostDetails(!openPostDetails);
                          }}
                        >
                          <i className="btn nav-btn">
                            <i className="bx bx-show-alt"></i>
                          </i>
                          <UncontrolledTooltip
                            placement="top"
                            target="view_post_details"
                          >
                            {props.t(`View Details`)}
                          </UncontrolledTooltip>
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>

              {pmData ? (
                <React.Fragment>
                  <Collapse isOpen={openPostDetails}>
                    <CardBody className="border-bottom">
                      <div className="details-section" data-id={pmData.id}>
                        <div className="text-muted mt-2">
                          <Row>
                            <Col lg="12">
                              <h5>
                                <span>{props.t("Question?")} </span>
                              </h5>
                              <p>{pmData.question}</p>
                              <h5>
                                <span>{props.t("Details")}: </span>
                              </h5>
                              <p>{pmData.details}</p>

                              {pmData.docUrl ? (
                                <div>
                                  <h5>
                                    <span>{props.t("Document")}: </span>
                                  </h5>
                                  <p
                                    style={{
                                      width: "100%",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <img
                                      src={pmData.docUrl}
                                      style={{ maxWidth: "100%" }}
                                    />
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        </div>

                        <Row className="task-dates">
                          <Col sm="6" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14">
                                <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                                {props.t("Created Date")}
                              </h5>
                              <p className="text-muted mb-0">
                                {dctDateFormat(pmData.createdAt)}
                              </p>
                            </div>
                          </Col>

                          <Col sm="6" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14">
                                <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                                {props.t("Updated Date")}
                              </h5>
                              <p className="text-muted mb-0">
                                {dctDateFormat(pmData.updatedAt)}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Collapse>
                </React.Fragment>
              ) : (
                <CardBody>
                  {pmData ? (
                    <div className="text-center"></div>
                  ) : (
                    <div className="text-center">
                      {loadingMessage("Featching Message...")}
                    </div>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                {pmData ? (
                        <div className={`${Config.names.slugName}-comment-section chat-conversation chat-conversation`}>
                      {doctorLastCommentHour && doctorLastCommentHour >= 36 ? (
                        <div className="text-center d-flex align-items-center justify-content-center h-100 p-4">
                        <div>
                          <h6 className="font-weight-normal text-danger mb-3">
                            {props.t(
                              getMessageStatusInfo("timePassed", "message")
                            )}
                          </h6>
                          <hr className="mt-1" />
                        </div>
                      </div>
                      ) :
                        pmData.status !== "accepted" ? (
                        <div className="text-center d-flex align-items-center justify-content-center h-100 p-4">
                          <div>
                            <h6 className="font-weight-normal text-danger mb-3">
                              {props.t(
                                getMessageStatusInfo(pmData?.status, "message")
                              )}
                            </h6>
                            <hr className="mt-1" />
                          </div>
                        </div>
                      
                        
                      ) : ""}

                    {pcData ? (
                      <React.Fragment>
                        <ul className="list-unstyled">
                          <PerfectScrollbar className={`${Config.names.slugName}-chat-window-scorll`}>
                            {renderComments(pcData, message)}
                            <span></span>
                          </PerfectScrollbar>
                        </ul>
                        <div
                          className="dropdown text-center"
                          onClick={() => {
                            if (pcData && pcData[0]) {
                              props.getSinglePostMessage(pcData[0].postId);
                            }
                          }}
                        >
                          <i id="reload_messages" className="btn nav-btn">
                            <i className="mdi mdi-reload"></i>
                          </i>
                          <UncontrolledTooltip
                            placement="top"
                            target="reload_messages"
                          >
                            {props.t(`Reload Messages`)}
                          </UncontrolledTooltip>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ul className="list-unstyled">
                          <PerfectScrollbar className={`${Config.names.slugName}-chat-window-scorll`}>
                            <div className="text-center mt-2">
                              {loadingMessage("")}
                            </div>
                          </PerfectScrollbar>
                        </ul>
                      </React.Fragment>
                    )}
                  </div>
                ) : null}

                {pmData ? (
                  <div className="chat-input-section">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <div id="comment_form" ref={jumpToCommentBox}></div>
                      <AvField
                        name="ownerId"
                        type="hidden"
                        value={authUserId}
                      />
                      <AvField name="postId" type="hidden" value={pmData.id} />
                      {singleComment && singleComment.id ? (
                        <AvField
                          name="id"
                          type="hidden"
                          value={singleComment.id}
                        />
                      ) : (
                        ""
                      )}
                      <AvField
                        name="actionType"
                        type="hidden"
                        value={singleComment.action}
                      />
                      {doctorLastCommentHour && doctorLastCommentHour >= 36 ? (
                        <Row>
                        <Col>
                          <div className="position-relative">
                            <AvField
                              name="body"
                              className={
                                singleComment.action === "edit"
                                  ? `form-control ${Config.names.slugName}-focus chat-input`
                                  : "form-control chat-input"
                              }
                              placeholder={props.t("Enter Message...")}
                              type="text"
                              id="comment_body"
                              disabled={getMessageStatusInfo(
                                "timePassed",
                                "disabled"
                              )}
                              value={singleComment?.body}
                              required
                            />

                            <div className="chat-input-links">
                              <ul className="list-inline mb-0">
                                <li className="list-inline-item">
                                  <Link
                                    to="#"
                                    className={
                                      singleComment && singleComment.id
                                        ? ""
                                        : "d-none"
                                    }
                                    onClick={() => {
                                      resetCommentForm();
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-reload"
                                      id="reset_comment_form"
                                    ></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="reset_comment_form"
                                    >
                                      {props.t(`Reset`)}
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>

                        <Col className="col-auto">
                          <Button
                            type="button"
                            color="primary"
                            type="submit"
                            disabled={getMessageStatusInfo(
                              "timePassed",
                              "disabled"
                            )}
                            onClick={() => {
                              // addMessage();
                            }}
                            className="btn-rounded chat-send w-md waves-effect waves-light"
                          >
                            <span className="dr-form-spinner d-none">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                            </span>{" "}
                            <span className="d-none d-sm-inline-block mr-2">
                              {props.t(`Send`)}
                            </span>{" "}
                            <i className="mdi mdi-send"></i>
                          </Button>
                        </Col>
                      </Row>
                      ) : (
                        <Row>
                        <Col>
                          <div className="position-relative">
                            <AvField
                              name="body"
                              className={
                                singleComment.action === "edit"
                                  ? `form-control ${Config.names.slugName}-focus chat-input`
                                  : "form-control chat-input"
                              }
                              placeholder={props.t("Enter Message...")}
                              type="text"
                              id="comment_body"
                              disabled={getMessageStatusInfo(
                                pmData?.status,
                                "disabled"
                              )}
                              value={singleComment?.body}
                              required
                            />

                            <div className="chat-input-links">
                              <ul className="list-inline mb-0">
                                <li className="list-inline-item">
                                  <Link
                                    to="#"
                                    className={
                                      singleComment && singleComment.id
                                        ? ""
                                        : "d-none"
                                    }
                                    onClick={() => {
                                      resetCommentForm();
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-reload"
                                      id="reset_comment_form"
                                    ></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="reset_comment_form"
                                    >
                                      {props.t(`Reset`)}
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                                {/* <li className="list-inline-item">
                                  <Link to="#">
                                    <i
                                      className="mdi mdi-file-image-outline"
                                      id="Imagetooltip"
                                    ></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="Imagetooltip"
                                    >
                                      {props.t(`Images`)}
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                                <li className="list-inline-item">
                                  <Link to="#">
                                    <i
                                      className="mdi mdi-file-document-outline"
                                      id="Filetooltip"
                                    ></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="Filetooltip"
                                    >
                                      {props.t(`Add Files`)}
                                    </UncontrolledTooltip>
                                  </Link>
                                </li> */}
                              </ul>
                            </div>
                          </div>
                        </Col>

                        <Col className="col-auto">
                          <Button
                            type="button"
                            color="primary"
                            type="submit"
                            disabled={getMessageStatusInfo(
                              pmData?.status,
                              "disabled"
                            )}
                            onClick={() => {
                              // addMessage();
                            }}
                            className="btn-rounded chat-send w-md waves-effect waves-light"
                          >
                            <span className="dr-form-spinner d-none">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                            </span>{" "}
                            <span className="d-none d-sm-inline-block mr-2">
                              {props.t(`Send`)}
                            </span>{" "}
                            <i className="mdi mdi-send"></i>
                          </Button>
                        </Col>
                      </Row>
                      )}
                      
                    </AvForm>
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { singlePostMessage } = state.PostMessage;
  return { singlePostMessage };
};

export default withRouter(
  connect(mapStatetoProps, {
    createPostComment,
    updatePostComment,
    postMessageActionSuccessful,
    postCommentActionSuccessful,
    createPostCommentFailed,
    setSinglePostMessage,
    getSinglePostMessage,
  })(withNamespaces()(PostMessageChatWindow))
);
