import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import {
  CREATE_DOCTOR,
  UPDATE_DOCTOR,
  GET_DOCTOR_LIST,
  GET_SINGLE_DOCTOR,
  UPDATE_TELEVISIT,
  GET_TELEVISIT_LIST,
  GET_TELEVISIT_HISTORY_LIST,
} from "./actionTypes";
import {
  doctorActionSuccessful,
  setSingleDoctor,
  createDoctorFailed,
  setDoctorList,
  setSingleTelevisit,
  setTelevisitList,
  setTelevisitHistoryList,
} from "./actions";

//Include Both Helper File with needed methods
import {
  dctPostRawData,
  dctPutRawData,
  // getDoctorListObj,
  dctGetRawData,
} from "../../helpers/backend_helper";
import { getAuthIds } from "../../helpers/Commons";
import { Config } from "../../Config";

// Update Televisit
function* updateTelevisitSaga({ payload: { televisit } }) {
  try {
    const televisit_data = televisit;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPutRawData,
      process.env.REACT_APP_API_PATH + "/televisit/" + televisit_data.id,
      televisit_data,
      headers
    );

    if (response.id) {
      yield put(
        setSingleTelevisit(response, "Televisit Updated Successfully", 2)
      );
    } else {
      // yield put(createTelevisitFailed(response.message));
    }
  } catch (error) {
    // yield put(createTelevisitFailed(error));
  }
}

// Get Televisit
function* getTelevisitListSaga({ payload: { televisit } }) {
  try {
    // const televisit_data = televisit;
    let bearer_token = localStorage.getItem("auth_token");
    // let doctorId = getAuthIds.doctor;
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctGetRawData,
      `${process.env.REACT_APP_API_PATH}/consultation/scheduled?page=1&limit=10`,
      headers
    );

    if (response.id) {
      yield put(
        setTelevisitList(
          response.result.data,
          "Televisit data fetched Successfully."
        )
      );
    } else {
      yield put(setTelevisitList("", response.message));
    }
  } catch (error) {
    yield put(setTelevisitList("", error));
  }
}
// Get Televisit History
function* getTelevisitHistoryListSaga({ payload: { televisit } }) {
  try {
    // const televisit_data = televisit;
    let bearer_token = localStorage.getItem("auth_token");
    let doctorId = getAuthIds.doctor;
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctGetRawData,
      `${process.env.REACT_APP_API_PATH}/consultation/doctor/${doctorId}/televisits?page=1&limit=10`,
      headers
    );

    if (response.id) {
      yield put(
        setTelevisitHistoryList(
          response.result.data,
          "Televisit data fetched Successfully."
        )
      );
    } else {
      yield put(setTelevisitHistoryList("", response.message));
    }
  } catch (error) {
    yield put(setTelevisitHistoryList("", error));
  }
}

// Create Doctor
function* createDoctorSaga({ payload: { doctor, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true

  try {
    const doctor_data = doctor;

    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPostRawData,
      process.env.REACT_APP_API_PATH + "/doctor",
      doctor_data,
      headers
    );

    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    if (response.id) {
      yield put(
        doctorActionSuccessful(
          response,
          "Doctor Created Successfully . ID#" + response.id,
          1
        )
      );
      yield put(
        setSingleDoctor(
          response,
          "Doctor Created Successfully . ID#" + response.id,
          1
        )
      );
      if (document.querySelector(`.${Config.names.slugName}-doctor-add-edit-modal .close`)) {
        document.querySelector(`.${Config.names.slugName}-doctor-add-edit-modal .close`).click();
      }
    } else {
      console.log("ELSE...")
      yield put(createDoctorFailed(response.cause.message));
    }
  } catch (error) {
    console.log("CATCH ERROR..")
    yield put(createDoctorFailed(error));
  }
}

// Update Doctor
function* updateDoctorSaga({ payload: { doctor, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true

  try {
    const doctor_data = doctor;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPutRawData,
      process.env.REACT_APP_API_PATH + "/doctor/" + doctor_data.id,
      // +doctor_data.id,
      doctor_data,
      headers
    );

    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false

    if (response.id) {
      yield put(
        doctorActionSuccessful(
          response,
          "Doctor Updated Successfully. ID#" + response.id,
          2
        )
      );
      yield put(
        setSingleDoctor(
          response,
          "Doctor Updated Successfully. ID#" + response.id,
          2
        )
      );

      if (document.querySelector(`.${Config.names.slugName}-doctor-add-edit-modal .close`)) {
        document.querySelector(`.${Config.names.slugName}-doctor-add-edit-modal .close`).click();
      }
    } else {
      yield put(createDoctorFailed(response.message));
    }
  } catch (error) {
    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    yield put(createDoctorFailed(error));
  }
}

// Get Doctor
function* getDoctorListSaga({ payload: { doctor } }) {
  try {
    const doctor_data = doctor;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctGetRawData,
      process.env.REACT_APP_API_PATH + "/doctor",
      headers
    );

    if (response.id) {
      yield put(
        setDoctorList(
          response.result.data,
          "Doctor data fetched Successfully.",
          ""
        )
      );
    } else {
      yield put(createDoctorFailed(response.message));
    }
  } catch (error) {
    yield put(createDoctorFailed(error));
  }
}

//get single doctor
function* getSingleDoctorSaga({ payload: { doctorId } }) {
  try {
    const doctor_id = doctorId;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctGetRawData,
      process.env.REACT_APP_API_PATH + "/doctor/" + doctor_id,
      headers
    );
    if (response.id) {
      yield put(
        setSingleDoctor(response, "Doctordata fetched Successfully.", "")
      );
    } else {
      yield put(setSingleDoctor("", "No Doctor found.", ""));
    }
  } catch (error) {
    yield put(setSingleDoctor("", error, ""));
  }
}

export function* watchUpdateTelevisit() {
  yield takeEvery(UPDATE_TELEVISIT, updateTelevisitSaga);
}
export function* watchGetTelevisit() {
  yield takeEvery(GET_TELEVISIT_LIST, getTelevisitListSaga);
}
export function* watchGetTelevisitHistory() {
  yield takeEvery(GET_TELEVISIT_HISTORY_LIST, getTelevisitHistoryListSaga);
}

export function* watchCreateDoctor() {
  yield takeEvery(CREATE_DOCTOR, createDoctorSaga);
}

export function* watchUpdateDoctor() {
  yield takeEvery(UPDATE_DOCTOR, updateDoctorSaga);
}
export function* watchGetDoctor() {
  yield takeEvery(GET_DOCTOR_LIST, getDoctorListSaga);
}
export function* watchGetSingleDoctor() {
  yield takeEvery(GET_SINGLE_DOCTOR, getSingleDoctorSaga);
}

function* doctorSaga() {
  yield all([
    fork(watchCreateDoctor),
    fork(watchUpdateDoctor),
    fork(watchGetDoctor),
    fork(watchGetSingleDoctor),
    fork(watchUpdateTelevisit),
    fork(watchGetTelevisit),
  ]);
}

export default doctorSaga;
