import React from 'react'
import RxPrescription from '../Pharmacy/rxprescription'
import RxPrescriptionDoctor from '../Doctor/Pharmacy/rxprescription'
import { useSelector } from "react-redux"

const RxPrescriptionInterface = () => {
  
  const { authUserType } = useSelector((state) => state.Login);
  
  switch (authUserType) {
    case "admin": return (<RxPrescription />);
    case "doctor": return (<RxPrescriptionDoctor />);
    default: return "";
  }
}

export default RxPrescriptionInterface
