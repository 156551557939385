import React, { useState, useEffect } from "react";

//Style from Reactstrap
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../../helpers/backend_helper";

import {
  getCompany,
  companyActionSuccessful,
  setCompanyListing,
  setSingleCompany,
} from "../../../store/actions";

//Import CompanyList
import CompanyList from "../../../components/Group/Company/CompanyList";

//Import Create and Update Company
import CreateUpdateCompany from "../../../components/Group/Company/CreateUpdateCompany";

//Import Upload CSV
import MemberCSVUpload from "../../../components/Company/MemberCSVUpload";

import axios from "axios";
import { Config } from "../../../Config";

const Company = (props) => {
  const [postsPerPage, setPostsPerPage] = useState(
    process.env.REACT_APP_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);

  const [gdata, setGData] = useState({
    loading: true,
    companies: [],
    message: null,
  });
  const [ajaxKey, setAjaxKey] = useState(false);
  let clickDetect = {
    search: false,
    reset: false,
    dropdown: false
  }

  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    companySearchParam: "",
    type: "filter",
    reset: false,
  });

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        companySearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: false,
      });
      return fetchCompanyData(searchParam, selectedPage);
    }
    fetchCompanyData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        companySearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      return fetchCompanyData(searchParam, selectedPage);
    }
    // if(selectedPage > 1)
    fetchCompanyData(searchParam, selectedPage);
  };

  const fetchCompanyData = (param, currentPage = 1) => {
    if (clickDetect.reset || clickDetect.search || clickDetect.dropdown) {
      gdata.companies = []
    }
    setAjaxKey(false);

    let searchParam = "";
    if (param) {
      searchParam = param.companySearchParam
        ? "&q=" + param.companySearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    const v = window.location.pathname.split("/");
    let url =
      process.env.REACT_APP_API_PATH +
      `/brand/${v[v.length - 1]
      }/company?page=${currentPage}&limit=${postsPerPage}` +
      searchParam;
    let cancel;

    axios
      .get(url, headers, {
        cancelToken: new axios.CancelToken(c => cancel = c),
      })
      .then(function (response) {
        if (response.status === 200) {
          setAjaxKey(true)
        }

        const data = response.data;
        let message = "";
        message = data.data.length > 0 ? "" : "No record found.";
        let structured = data.data
        if ((!param || param == '') || data.total > 30) {
          structured = [...gdata.companies, ...data.data]
        }
        dispatch(setCompanyListing(structured, message));
        setGData({
          loading: false,
          companies: structured,
          message: message,
        });

        setTotalPosts(Math.ceil(data.total / postsPerPage));
        setCurrentPage(currentPage - 1);
        document
          .getElementsByClassName("dr-csearch-spinner")[0]
          .classList.add("d-none");
      })
      .catch(function (err) {
        document
          .getElementsByClassName("dr-csearch-spinner")[0]
          .classList.add("d-none");
        var message = checkAjaxError(err);
        if (err?.response?.status === 400) {
          message = err.response.data.cause.details;
        }
        setGData({
          loading: false,
          companies: null,
          message: message,
        });
        setTotalPosts(0);
        setCurrentPage(0);
        dispatch(setCompanyListing(null, message));
        if (axios.isCancel(err)) return
      })
    return () => cancel();
    // .then(function () {});
  };

  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;

    let csearch = urlParams.get("csearch");
    let searchParam = "";
    if (csearch) {
      searchParam = {
        companySearchParam: csearch,
        type: "filter",
        // key:  dropdown ? 'fulfilment': ''
      };
      setSearchFormDetails({
        companySearchParam: "",
        type: "filter",

        reset: true,
      });
    }
    fetchCompanyData(searchParam, currentPage + 1)
  }, [currentPage])

  const onScrollToEnd = () => {
    setCurrentPage(currentPage + 1);
  }
  window.onscroll = function () {
    //check if the page has scrolled to the bottom
    let innerHeight = window.innerHeight + document.documentElement.scrollTop + 700;
    let offsetHeight = document.documentElement.offsetHeight;
    if (currentPage + 1 === totalPosts || gdata.companies?.length == 0) return;

    if (innerHeight >= offsetHeight) {
      if (ajaxKey) {
        onScrollToEnd();
      }
    }
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let csearch = urlParams.get("csearch");
    let searchParam = "";
    if (csearch) {
      let searchParam = {
        companySearchParam: csearch,
        type: "filter",
      };
      setSearchFormDetails({
        companySearchParam: searchFormDetails.companySearchParam,
        type: "filter",
        reset: true,
      });
      // fetchCompanyData(searchParam);
    } else {
      // fetchCompanyData(searchParam);
    }
  }, []);

  // Apply Company Search
  function handleSearch(e) {
    if (!searchFormDetails.companySearchParam || searchFormDetails.companySearchParam == '') {
      return;
    }
    e.preventDefault();
    document
      .getElementsByClassName("dr-csearch-spinner")[0]
      .classList.remove("d-none");
    gdata.companies = [];

    fetchCompanyData(searchFormDetails);
    setSearchFormDetails({
      companySearchParam: searchFormDetails.companySearchParam,
      type: "filter",
      reset: true,
    });
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    fetchCompanyData();
    setSearchFormDetails({
      companySearchParam: "",
      type: "filter",
      reset: false,
    });
    document.getElementById("company-search-form").reset();
    gdata.companies = [];
  }

  const { loading, companies, message } = gdata;
  const company_action = useSelector((state) => state.Company.action_type);

  const propsUpdateCompanyData = () => {
    if (!companies && !props.singleCompany) {
      return;
    }
    if (companies && props.singleCompany) {
      let companyid = props.singleCompany.id;
      //Update record
      if (company_action === 2) {
        var index = companies.findIndex((x) => x.id === companyid);
        companies[index] = props.singleCompany;
        dispatch(setCompanyListing(companies, "3"));
      }

      //Insert new record
      if (company_action === 1) {
        companies.unshift(props.singleCompany);
        dispatch(setCompanyListing(companies, "3"));
      }

      let activeRow = document.querySelectorAll(`[data-row-id="${companyid}"]`);
      if (activeRow[0]) {
        activeRow[0].scrollIntoView({ behavior: "smooth" });
        activeRow[0].classList.add("active");
        const timer = setTimeout(() => {
          activeRow[0].classList.remove("active");
        }, 3000);
        return () => clearTimeout(timer);
      }
      setGData({
        loading: false,
        companies: companies,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="9">
              <Form
                inline
                onSubmit={handleSearch}
                id="company-search-form"
                className={`mb-2 ${Config.names.slugName}-search-form`}
              >
                <FormGroup className="mr-sm-2 mb-sm-0 mb-0">
                  <Input
                    type="text"
                    name="companySearchParam"
                    style={{ minWidth: "250px" }}
                    placeholder={props.t("Search...")}
                    value={searchFormDetails.companySearchParam}
                    onChange={(e) => {
                      let { value } = e.target;
                      setSearchFormDetails((prevState) => ({
                        ...prevState,
                        companySearchParam: value,
                      }));
                    }}
                  />
                </FormGroup>
                <Button onClick={handleSearch}>
                  <span className="dr-csearch-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                  Search
                </Button>
                {searchFormDetails.reset ? (
                  <span
                    className={`ml-2 ${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-reset-search`}
                    onClick={resetSearch}
                  >
                    <i className="bx bx-reset font-size-22 align-middle mr-2"></i>
                  </span>
                ) : (
                  ""
                )}
              </Form>
            </Col>
            <Col lg="3">
              <div className="text-right mb-2">
                <CreateUpdateCompany
                  updateCompanyDataFlag={propsUpdateCompanyData}
                  company_action_type="create"
                  company_button_type="create"
                  groupId={props.groupId}
                  groupName={props.groupName}
                  {...props}
                />
                <CreateUpdateCompany
                  updateCompanyDataFlag={propsUpdateCompanyData}
                  company_action_type="edit"
                  company_button_type="edit_icon"
                  groupId={props.groupId}
                  groupName={props.groupName}
                  {...props}
                />
                <MemberCSVUpload upload_button_type="edit_icon" {...props} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CompanyList
                    {...props}
                    companyListMessage={message}
                    total={totalPosts}
                    getDataOnEnter={getDataOnEnter}
                    // getUserData={getUserData}
                    currentPage={currentPage}
                    perPage={postsPerPage}
                    ajaxLoader={loading}
                  ></CompanyList>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { company } = state.Company;
  const { singleCompany } = state.Company;
  const { message } = state.Company;
  return { company, message, singleCompany };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCompany,
    companyActionSuccessful,
    setSingleCompany,
  })(withNamespaces()(Company))
);
