import React, { useState } from "react";

import {
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
} from "reactstrap";

import classnames from "classnames";
import MyProfile from "./DetailsFromDataTable/MyProfile";
// import FeeSchedule from './DetailsFromDataTable/FeeSchedule';
import AccountSettings from "./DetailsFromDataTable/AccountSettings";

import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
const DetailsForm = (props) => {
  const [activeTabJustify, setactiveTabJustify] = useState("1");

  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab);
    }
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "1",
                      })}
                      onClick={() => {
                        toggleCustomJustified("1");
                      }}
                    >
                      <span className="d-sm-block">
                        {props.t("My Profile")}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "2",
                      })}
                      onClick={() => {
                        toggleCustomJustified("2");
                      }}
                    >
                      <span className="d-sm-block">
                        {props.t("Account Settings")}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTabJustify}>
                  <TabPane tabId="1" className="p-3">
                    <Row>
                      <Col sm="12">
                        <MyProfile />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2" className="p-3">
                    <Row>
                      <Col sm="12">
                        <AccountSettings />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(withNamespaces()(DetailsForm));
