import { CREATE_RXPRESCRIPTION, UPDATE_RXPRESCRIPTION, GET_RXPRESCRIPTION, GET_RXPRESCRIPTION_LIST, SET_RXPRESCRIPTION_LIST, CREATE_RXPRESCRIPTION_SUCCESS, CREATE_RXPRESCRIPTION_FAILED, API_ERROR, SET_SINGLE_RXPRESCRIPTION, SET_RXPRESCRIPTION_LISTING } from './actionTypes';

export const createrxPrescription = (rxprescription) => ({
    type: CREATE_RXPRESCRIPTION,
    payload: { rxprescription }
})

export const updaterxPrescription = (rxprescription) => ({
    type: UPDATE_RXPRESCRIPTION,
    payload: { rxprescription }
})

export const getrxPrescription = (rxprescription) => ({
    type: GET_RXPRESCRIPTION,
    payload: { rxprescription }
})

export const getrxPrescriptionList = (rxprescription) => ({
    type: GET_RXPRESCRIPTION_LIST,
    payload: { rxprescription }
})

export const setrxPrescriptionList = (rxprescription, message, action) => {
    return {
        type: SET_RXPRESCRIPTION_LIST,
        payload: rxprescription,
        message: message,
        action_type: action
    }
}


export const setSinglerxPrescription = (rxprescription, message, action) => {
    return {
        type: SET_SINGLE_RXPRESCRIPTION,
        payload: rxprescription,
        message: message,
        action_type: action
    }
}

export const setrxPrescriptionListing = (rxprescription, message) => {
    return {
        type: SET_RXPRESCRIPTION_LISTING,
        payload: rxprescription,
        rxprescriptionListing: rxprescription,
        message: message,
    }
}

export const rxPrescriptionActionSuccessful = (rxprescription, message, action) => {
    return {
        type: CREATE_RXPRESCRIPTION_SUCCESS,
        payload: rxprescription,
        message: message,
        action_type: action
    }
}

export const createrxPrescriptionFailed = (error) => {
    return {
        type: CREATE_RXPRESCRIPTION_FAILED,
        payload: error
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}