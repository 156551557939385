import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";

//Import Images
// import doctor from "../../assets/images/doctor/doctor.png";
import profileImg from "../../assets/images/profile-img.png";
import { Config } from "../../Config";


class CardWelcome extends Component {
  render() {
    return (
      <React.Fragment>
        <Col xl="4">
          <Card className="bg-soft-primary">
            <div>
              <Row>
                <Col xs="7">
                  <div className="text-primary p-3">
                    <h5 className="text-primary">Welcome Partner!</h5>
                    <p>{Config.names.appName}</p>
                    <ul className="pl-3 mb-0">
                        <li className="py-1">eHealthcare & Therapy</li>
                    </ul>
                  </div>
                </Col>
                <Col xs="5" className="align-self-end">
                  <img src={profileImg} alt="" className="img-fluid" />
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default CardWelcome;
