import React, { useState, useEffect } from "react";

//Style from Reactstrap
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormBrand,
  Input,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

import {
  getBrand,
  brandActionSuccessful,
  setBrandListing,
  setSingleBrand,
} from "../../store/actions";

//Import BrandList
import BrandList from "../../components/Brand/BrandList";

//Import Create and Update Brand
import CreateUpdateBrand from "../../components/Brand/CreateUpdateBrand";
import { withNamespaces } from "react-i18next";

import axios from "axios";
import { FormGroup } from "@material-ui/core";
import { Config } from "../../Config";

const Brands = (props) => {
  const [postsPerPage, setPostsPerPage] = useState(
    process.env.REACT_APP_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);

  const [gdata, setGData] = useState({
    loading: true,
    brands: null,
    message: null,
  });

  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    BrandSearchParam: "",
    type: "filter",
    reset: false,
  });

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("gsearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        BrandSearchParam: msearch,
        type: "filter",
      };

      setSearchFormDetails({
        BrandSearchParam: "",
        type: "filter",
        reset: false,
      });
      return fetchBrandData(searchParam, selectedPage);
    }
    fetchBrandData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("gsearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        BrandSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        BrandSearchParam: "",
        type: "filter",
        reset: true,
      });
      return fetchBrandData(searchParam, selectedPage);
    }
    // if(selectedPage > 1)
    fetchBrandData(searchParam, selectedPage);
  };

  const fetchBrandData = (param, currentPage = 1) => {
    let searchParam = "";
    if (param) {
      searchParam = param.BrandSearchParam
        ? "&q=" + param.BrandSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    let url =
      process.env.REACT_APP_API_PATH +
      `/brand?page=${currentPage}&limit=${postsPerPage}` +
      searchParam;

    axios
      .get(url, headers)
      .then(function (response) {
        const data = response.data;
        let message = "";
        message = data.data && data.data.length > 0 ? "" : "No record found.";
        dispatch(setBrandListing(data.data, message));
        setGData({
          loading: false,
          Brands: data.data,
          message: message,
        });
        setTotalPosts(Math.ceil(data.total / postsPerPage));
        setCurrentPage(currentPage - 1);
        document
          .getElementsByClassName("dr-gsearch-spinner")[0]
          .classList.add("d-none");
      })
      .catch(function (err) {
        // document
        //   .getElementsByClassName("dr-gsearch-spinner")[0]
        //   .classList.add("d-none");
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = err.response.data.cause.details;
          }
        }

        setGData({
          loading: false,
          Brands: null,
          message: message,
        });
        setTotalPosts(0);
        setCurrentPage(0);
        dispatch(setBrandListing(null, message));
      })
      .then(function () {});
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let gsearch = urlParams.get("gsearch");
    let searchParam = "";
    if (gsearch) {
      let searchParam = {
        BrandSearchParam: gsearch,
        type: "filter",
      };
      setSearchFormDetails({
        BrandSearchParam: searchFormDetails.BrandSearchParam,
        type: "filter",
        reset: true,
      });
      fetchBrandData(searchParam);
    } else {
      // fetchBrandData(searchParam);
    }
  }, []);

  // Apply Brand Search
  function handleSearch(e) {
    e.preventDefault();
    document
      .getElementsByClassName("dr-gsearch-spinner")[0]
      .classList.remove("d-none");
    fetchBrandData(searchFormDetails);
    setSearchFormDetails({
      BrandSearchParam: searchFormDetails.BrandSearchParam,
      type: "filter",
      reset: true,
    });
    let param = searchFormDetails.BrandSearchParam
      ? searchFormDetails.BrandSearchParam
      : "";
    props.history.push("/brand?gsearch=" + param);
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    fetchBrandData();
    setSearchFormDetails({
      BrandSearchParam: "",
      type: "filter",
      reset: false,
    });
    document.getElementById("Brand-search-form").reset();
    props.history.push("/brand");
  }

  const { loading, brands, message } = gdata;
  const brand_action = useSelector((state) => state.Brand.action_type);

  const propsUpdateBrandData = () => {
    if (!brands && !props.singleBrand) {
      return;
    }
    if (brands && props.singleBrand) {
      let brandid = props.singleBrand.id;
      //Update record
      if (brand_action === 2) {
        var index = brands.findIndex((x) => x.id === brandid);
        brands[index] = props.singleBrand;
        dispatch(setBrandListing(brands, "3"));
      }

      //Insert new record
      if (brand_action === 1) {
        brands.unshift(props.singleBrand);
        dispatch(setBrandListing(brands, "3"));
      }

      let activeRow = document.querySelectorAll(`[data-row-id="${brandid}"]`);
      if (activeRow[0]) {
        activeRow[0].scrollIntoView({ behavior: "smooth" });
        activeRow[0].classList.add("active");
        const timer = setTimeout(() => {
          activeRow[0].classList.remove("active");
        }, 3000);
        return () => clearTimeout(timer);
      }
      setGData({
        loading: false,
        brands: brands,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="9">
              <Form
                inline
                onSubmit={handleSearch}
                id="Brand-search-form"
                className={`mb-2 ${Config.names.slugName}-search-form`}
              >
                <FormGroup className="mr-sm-2 mb-sm-0 mb-0">
                  <Input
                    type="text"
                    name="BrandSearchParam"
                    style={{ minWidth: "250px" }}
                    placeholder={props.t("Search...")}
                    value={searchFormDetails.BrandSearchParam}
                    onChange={(e) => {
                      let { value } = e.target;
                      setSearchFormDetails((prevState) => ({
                        ...prevState,
                        BrandSearchParam: value,
                      }));
                    }}
                  />
                </FormGroup>
                <Button onClick={handleSearch}>
                  <span className="dr-gsearch-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                  Search
                </Button>
                {searchFormDetails.reset ? (
                  <span
                    className={`ml-2 ${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-reset-search`}
                    onClick={resetSearch}
                  >
                    <i className="bx bx-reset font-size-22 align-middle mr-2"></i>
                  </span>
                ) : (
                  ""
                )}
              </Form>
            </Col>
            <Col lg="3">
              <div className="text-right mb-2">
                <CreateUpdateBrand
                  updateBrandDataFlag={propsUpdateBrandData}
                  brand_action_type="create"
                  brand_button_type="create"
                  {...props}
                />
                <CreateUpdateBrand
                  updateGroupDataFlag={propsUpdateBrandData}
                  brand_action_type="edit"
                  brand_button_type="edit_icon"
                  {...props}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <BrandList
                    {...props}
                    BrandListMessage={message}
                    total={totalPosts}
                    getDataOnEnter={getDataOnEnter}
                    getUserData={getUserData}
                    currentPage={currentPage}
                    perPage={postsPerPage}
                    ajaxLoader={loading}
                  ></BrandList>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { brand } = state.Brand;
  const { singleBrand } = state.Brand;
  const { message } = state.Brand;
  return { brand, message, singleBrand };
};

export default withRouter(
  connect(mapStatetoProps, { getBrand, brandActionSuccessful, setSingleBrand })(
    withNamespaces()(Brands)
  )
);
