import { CREATE_SERVICE, UPDATE_SERVICE, GET_SERVICE, GET_SERVICE_LIST, SET_SERVICE_LIST, CREATE_SERVICE_SUCCESS, CREATE_SERVICE_FAILED, API_ERROR, SET_SINGLE_SERVICE, SET_SERVICE_LISTING } from './actionTypes';

const initialState = {
    createServiceError: null, 
    message: null, 
    loading: false,
    action_type: null
}

const service = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_SERVICE:
            state = {
                ...state,
                service: action.payload,
                loading: true,
                createServiceError: null
            }
            break;
        case UPDATE_SERVICE:
            state = {
                ...state,
                service: action.payload,
                loading: true,
                createServiceError: null
            }
            break;
        case GET_SERVICE:
            state = {
                ...state,
                service: action.payload.result,
                loading: true,
                createServiceError: null
            }
            break;
        case GET_SERVICE_LIST:
            state = {
                ...state,
                service: action.payload.result,
                loading: true,
                createServiceError: null
            }
            break;
        case SET_SINGLE_SERVICE:
            state = {
                ...state,
                service: null,
                singleService: action.payload,
                loading: false,
                createServiceError: null,
                action_type: action.action_type,
            }
            break;
        case SET_SERVICE_LIST:
            state = {
                ...state,
                loading: false,
                service: action.payload,
                message: action.message,
                createServiceError: null,
                action_type: action.action_type,
            }
            break;
        case SET_SERVICE_LISTING:
            state = {
                ...state,
                loading: false,
                service: null,
                serviceListing: action.payload,
                message: action.message,
                createServiceError: null,
            }
            break;    
        case CREATE_SERVICE_SUCCESS:
            state = {
                ...state,
                loading: false,
                service: action.payload,
                message: action.message,
                createServiceError: null,
                action_type: action.action_type,
            }
            break;
        case CREATE_SERVICE_FAILED:
            state = {
                ...state,
                service: null,
                createServiceError: action.payload,
                loading: false,
            }
            break;
        case API_ERROR:
            state = { 
                ...state, 
                error: action.payload, 
                loading: false 
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default service;


