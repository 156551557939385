import React from "react";
import { Config } from "../../../Config";

export const DctIcon = (props) => {
  let size = props.size ? props.size : 60;
  let iconClass = props.name ? props.name : `${Config.names.slugName}-health-icon`;
  return (
    <i className={`${Config.names.slugName} ${iconClass}`} style={{ fontSize: size + "px" }}></i>
  );
};
