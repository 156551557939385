import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
// import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import axios from "axios";

import ReactApexChart from "react-apexcharts";
import { Config } from './../../Config';

const Earning = (props) => {
  const dateObj = new Date();
  const monthName = dateObj.toLocaleString("default", { month: "long" });
  const yearName = new Date().getFullYear();

  const [monthChange, setMonthChange] = useState(monthName);
  const [yearChange, setYearChange] = useState(yearName);

  const [series, setSeries] = useState([
    {
      name: ["Members"],
      data: [],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      toolbar: false,
      dropShadow: {
        enabled: !0,
        color: ["#000"],
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    colors: [Config.colors.pieChartActiveColor],
    stroke: {
      curve: "smooth",
      width: 3,
    },
  });

  useEffect(() => {
    setOptions(options)
  }, [options])

  // const [activeMember, setActiveMember] = useState("4");

  const fetchLineChartData = (monthChange, yearChange) => {
    let url = `${process.env.REACT_APP_API_PATH}/members/chart?month=${monthChange}&year=${yearChange}`;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    axios.get(url, headers).then(function (response) {
      let data = response.data.result;
      // setActiveMember(data.activeMembers);
      let nameArray = [];
      let dataArray = [];
      data.membersGraph.map((value, index) => {
        nameArray.push(value.series);

        dataArray.push(value.labels);
        //    nameArray.push(value)
      });

      setSeries([
        {
          // name: nameArray,
          data: dataArray,
        },
      ]);
    });
  };

  useEffect(() => {
    fetchLineChartData(monthChange, yearChange);
  }, [monthChange, yearChange]);


  const handleMonthChange = (e) => {
    let monthChange = e.target.value;
    setMonthChange(monthChange);
  };

  const handleYearChange = (e) => {
    let yearChange = e.target.value;
    setYearChange(yearChange);
  };

  return (
    <React.Fragment>
      <Col xl="8">
        <Card>
          <CardBody>
            <div className="clearfix">
              <div className="float-right">
                <div className="input-group input-group-sm">
                  <select
                    className="custom-select custom-select-sm"
                    onChange={handleYearChange}
                  >
                    <option defaultValue>{yearName}</option>
                    <option value="2020">{props.t("2020")}</option>
                    <option value="2021">{props.t("2021")}</option>
                    <option value="2022">{props.t("2022")}</option>
                    <option value="2023">{props.t("2023")}</option>
                    <option value="2024">{props.t("2024")}</option>
                    <option value="2025">{props.t("2025")}</option>
                    <option value="2026">{props.t("2026")}</option>
                    <option value="2027">{props.t("2027")}</option>
                    <option value="2028">{props.t("2028")}</option>
                    <option value="2029">{props.t("2029")}</option>
                    <option value="2030">{props.t("2030")}</option>
                  </select>
                  <div className="input-group-append">
                    <label className="input-group-text">
                      {props.t("Year")}
                    </label>
                  </div>
                </div>
              </div>

              <div className="float-right">
                <div className="input-group input-group-sm">
                  <select
                    className="custom-select custom-select-sm"
                    onChange={handleMonthChange}
                  >
                    <option defaultValue>{monthName}</option>
                    <option value="January">{props.t("January")}</option>
                    <option value="February">{props.t("February")}</option>
                    <option value="March">{props.t("March")}</option>
                    <option value="April">{props.t("April")}</option>
                    <option value="May">{props.t("May")}</option>
                    <option value="June">{props.t("June")}</option>
                    <option value="July">{props.t("July")}</option>
                    <option value="August">{props.t("August")}</option>
                    <option value="September">{props.t("September")}</option>
                    <option value="October">{props.t("October")}</option>
                    <option value="November">{props.t("November")}</option>
                    <option value="December">{props.t("December")}</option>
                  </select>
                  <div className="input-group-append">
                    <label className="input-group-text">
                      {props.t("Month")}
                    </label>
                  </div>
                </div>
              </div>

              <h4 className="card-title mb-4">
                {props.t("Active Member Growth")}
              </h4>
            </div>
            <Row>
              {/* <Col lg="4">
                <div className="text-muted">
                  <div className="mb-4">
                    <p>{props.t("This month")}</p>
                    <h4>${activeMember * 5}</h4>
                    <div className="d-none">
                      <span className="badge badge-soft-success font-size-12 mr-1 ">
                        {" "}
                        + 0.2%{" "}
                      </span>{" "}
                      {props.t("From previous period")}
                    </div>
                  </div>

                  <div>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      {props.t("View Details")}{" "}
                      <i className="mdi mdi-chevron-right ml-1"></i>
                    </Link>
                  </div>

                  <div className="mt-4">
                    <p className="mb-2">{props.t("Last month")}</p>
                    <h5>$2281.04</h5>
                  </div>
                </div>
              </Col> */}

              <Col lg="12">
                <div id="line-chart" className="apex-charts" dir="ltr">
                  {/* <updateChartData/> */}
                  <ReactApexChart
                    series={series}
                    options={options}
                    type="line"
                    height={320}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default withRouter(withNamespaces()(Earning));
