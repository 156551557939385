import React, { Fragment } from 'react';
import { Col, Card, CardBody } from "reactstrap";

import { withRouter} from "react-router-dom";
import { withNamespaces } from "react-i18next";

// import axios from "axios";

const MiniWidget = (props) => {


    return (
        <Fragment>
            <Col sm="4">
                <Card>
                    <CardBody>
                    <div className="d-flex align-items-center mb-3">
                        <div className="avatar-xs mr-3">
                            <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                <i className="bx bx-group"></i>
                            </span>
                        </div>
                        <h5 className="font-size-14 mb-0">Groups</h5>
                    </div>
                    <div className="text-muted mt-4">
                        <h4 className="font-size-24">{props.gData?props.gData.groups:"Loading..."}<i className="mdi mdi-chevron-up ml-1 text-success d-none"></i></h4>
                        {/* <div className="d-flex">
                            <span className={"badge badge-soft-" + report.color + " font-size-12"}> {report.badgeValue} </span> <span className="ml-2 text-truncate">{report.desc}</span>
                        </div> */}
                    </div>
                    </CardBody>
                </Card>
            </Col>
            <Col sm="4">
                <Card>
                    <CardBody>
                    <div className="d-flex align-items-center mb-3">
                        <div className="avatar-xs mr-3">
                            <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                <i className="bx bx-building"></i>
                            </span>
                        </div>
                        <h5 className="font-size-14 mb-0">Companies</h5>
                    </div>
                    <div className="text-muted mt-4">
                        <h4 className="font-size-24">{props.gData?props.gData.companies:"Loading..."}<i className="mdi mdi-chevron-up ml-1 text-success d-none"></i></h4>
                        {/* <div className="d-flex">
                            <span className={"badge badge-soft-" + report.color + " font-size-12"}> {report.badgeValue} </span> <span className="ml-2 text-truncate">{report.desc}</span>
                        </div> */}
                    </div>
                    </CardBody>
                </Card>
            </Col>
            <Col sm="4">
                <Card>
                    <CardBody>
                    <div className="d-flex align-items-center mb-3">
                        <div className="avatar-xs mr-3">
                            <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                <i className="bx bxs-user-detail"></i>
                            </span>
                        </div>
                        <h5 className="font-size-14 mb-0">Active Members</h5>
                    </div>
                    <div className="text-muted mt-4">
                        <h4 className="font-size-24">{props.gData?props.gData.activeMembers:"Loading..."}<i className="mdi mdi-chevron-up ml-1 text-success d-none"></i></h4>
                        {/* <div className="d-flex">
                            <span className={"badge badge-soft-" + report.color + " font-size-12"}> {report.badgeValue} </span> <span className="ml-2 text-truncate">{report.desc}</span>
                        </div> */}
                    </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    )
}


export default withRouter(
    withNamespaces()(MiniWidget)
  );