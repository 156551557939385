import React, { useState } from "react";
import axios from "axios";
import { Button, Modal, ModalBody, Container, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Config } from "../../../Config";
import excuseNoteLogo from "../../../assets/images/ExcuseDoctegrityLogo.png";
import closeIcon from "../../../assets/images/closeIcon.png";
import { v4 as uuidv4 } from "uuid";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";

const ExcuseNote = (props) => {
  const modal = props?.modal;
  const setModal = props?.setModal;
  const setOpenSuccessModal = props?.setOpenSuccessModal;
  const setEmailMessage = props?.setEmailMessage;
  const setOpenPdfModal = props?.setOpenPdfModal;
  const singleConsultData = props?.singleConsultData;
  const setEmailSentMessage = props?.setEmailSentMessage;
  const [emailLoader, setEmailLoader] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(false);

  const [showWaring, setShowWaring] = useState(false);
  const [waringMessage, setWaringMessage] = useState("");
  // Open success Modal
  const sendEmailCopy = () => {
    setEmailLoader(true);
    if (singleConsultData?.id) {
      axios
        .get(
          `${process.env.REACT_APP_API_PATH}/excuse/${singleConsultData?.id}/send-email
        `,
          {
            headers: {
              Authorization: localStorage.getItem("auth_token"),
              PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
          }
        )
        .then((response) => {
          setEmailSentMessage(response?.data?.message);
          setEmailLoader(false);
          setOpenSuccessModal(true);
          setEmailMessage(true);
          setModal(!modal);
        })
        .catch(function (error) {
          setWaringMessage(error?.response?.data?.message);
          setShowWaring(true);
          setEmailLoader(false);
        });
    } else {
      console.log("excuse note id is missing");
      setEmailLoader(false);
    }
  };
  // Open download pdf Modal
  const downloadExcuseNotePdf = () => {
    setPdfLoader(true);
    if (singleConsultData?.id) {
      axios
        .get(
          `${process.env.REACT_APP_API_PATH}/excuse/${singleConsultData?.id}/excuse-note-pdf
        `,
          {
            headers: {
              Authorization: localStorage.getItem("auth_token"),
              PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
          }
        )
        .then((response) => {
          const uid = uuidv4();
          const downloadPdfFileName = uid + ".pdf";
          const link = document.createElement("a");
          link.href = response?.data?.url;
          link.setAttribute("download", downloadPdfFileName);
          document.body.appendChild(link);
          link.click();
          setPdfLoader(false);
          setOpenSuccessModal(true);
          setOpenPdfModal(true);
          setModal(!modal);
        })
        .catch(function (error) {
          setWaringMessage(error?.response?.data?.message);
          setShowWaring(true);
          setPdfLoader(false);
        });
    } else {
      console.log("excuse note id is missing");
      setPdfLoader(false);
    }
  };
  return (
    <React.Fragment>
      <Modal
        centered={true}
        size="md"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-member-add-edit-modal`}
      >
        <ModalBody>
          <Container>
            <AvForm className="form-horizontal">
              <div
                className="d-flex justify-content-end cursor-pointer"
                onClick={() => {
                  setModal(!modal);
                }}
              >
                <img src={closeIcon} alt="close" />
              </div>
              <div className="justify-content-center mt-2">
                <div
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img
                    src={excuseNoteLogo}
                    alt="logo"
                    style={{ width: "14rem" }}
                  />
                </div>
                <p className="d-flex justify-content-center align-items-center mr-4 mt-1">
                  Doctegrity.(877) 342-5152
                </p>
              </div>
              <div className="p-2">
                <div style={{ fontSize: 20 }}>{props.t("Excuse Note")}</div>
                {/*Excuse body data*/}
                <div className="form-group row mt-3">
                  <div className="row w-100">
                    <label htmlFor="to" className="col-sm-2 col-form-label">
                      {props.t("To")}:
                    </label>
                    <div className="col-sm-10 ">
                      <AvField
                        id="to"
                        name="to"
                        type="text"
                        value={singleConsultData?.to}
                        disabled={true}
                        className="excuse-note-disable"
                      />
                    </div>
                  </div>

                  <div className="row w-100">
                    <label htmlFor="date" className="col-sm-2 col-form-label">
                      {props.t("Date")}:
                    </label>
                    <div className="col-sm-10">
                      <AvField
                        id="date"
                        name="date"
                        type="text"
                        value={moment(
                          singleConsultData?.date,
                          "MM-DD-YYYY"
                        ).format("MM-DD-YYYY")}
                        readOnly={true}
                        className="excuse-note-disable"
                      />
                    </div>
                  </div>

                  <div className="row w-100">
                    <div className="col-sm-2">
                      <label htmlFor="excuse" className="">
                        {props.t("Please Excuse")}:
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <AvField
                        id="excuse"
                        name="excuse"
                        type="text"
                        value={singleConsultData?.pleaseExcuse}
                        readOnly={true}
                        className="excuse-note-disable"
                      />
                    </div>
                  </div>

                  <div className="row w-100">
                    <label htmlFor="from" className="col-sm-2 col-form-label">
                      {props.t("From")}:
                    </label>
                    <div className="col-sm-10">
                      <AvField
                        id="from"
                        name="from"
                        type="text"
                        value={singleConsultData?.from}
                        readOnly={true}
                        className="excuse-note-disable"
                      />
                    </div>
                  </div>

                  <div className="row w-100">
                    <label htmlFor="dates" className="col-sm-2 col-form-label">
                      {props.t("Dates")}:
                    </label>
                    <div className="col-sm-10">
                      <AvField
                        id="dates"
                        name="dates"
                        type="text"
                        value={moment(
                          singleConsultData?.date,
                          "MM-DD-YYYY"
                        ).format("MM-DD-YYYY")}
                        readOnly={true}
                        className="excuse-note-disable"
                      />
                    </div>
                  </div>

                  <div className="row w-100">
                    <label
                      htmlFor="through"
                      className="col-sm-2 col-form-label"
                    >
                      {props.t("Through")}:
                    </label>
                    <div className="col-sm-10">
                      <AvField
                        id="name"
                        name="name"
                        type="text"
                        value={moment(
                          singleConsultData?.through,
                          "MM-DD-YYYY"
                        ).format("MM-DD-YYYY")}
                        readOnly={true}
                        className="excuse-note-disable"
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div lg={12}>
                  <Label>{props.t("Notes")}:</Label>
                  <AvField
                    name="notes"
                    className={`form-control h-xl`}
                    type="textarea"
                    placeholder={`Write Excuse Reasons...`}
                    style={{ height: "150px" }}
                    readOnly={true}
                    value={singleConsultData?.notes}
                    className="excuse-note-disable"
                  />
                </div>
                <p style={{ fontWeight: "bold" }}>
                  Doctegrity Physician Services <br />
                  (877)342-5152
                </p>
                {/* Button */}
                <div className="d-flex justify-content-between mt-4">
                  <Button
                    type="button"
                    style={{
                      backgroundColor: "#4d4774",
                      height: "37px",
                      borderRadius: "5px",
                      width: "153px",
                      marginRight: "10px",
                    }}
                    className="text-nowrap"
                    onClick={() => {
                      downloadExcuseNotePdf();
                    }}
                  >
                    {pdfLoader && (
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    )}
                    {props.t("Download PDF")}
                  </Button>
                  <Button
                    type="button"
                    style={{
                      backgroundColor: "#066963",
                      height: "37px",
                      borderRadius: "5px",
                      width: "153px",
                    }}
                    className="text-nowrap"
                    onClick={() => {
                      sendEmailCopy();
                    }}
                  >
                    {emailLoader && (
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    )}
                    Send Email Copy
                  </Button>
                </div>
              </div>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
      {showWaring && (
        <SweetAlert
          title="Oops !"
          warning
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowWaring(false);
          }}
        >
          {waringMessage}
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

export default ExcuseNote;
