import React, { Fragment, useEffect, useState } from "react";
// Redux
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";

import { Col, Label, Row, UncontrolledTooltip, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import Select from "react-select";

import { updateMember, setSingleMember } from "../../../store/actions";
import { dctTimezones } from "../../../helpers/dctTimezones";

import { capitalizeWords, loadingMessage } from "../../../helpers/Commons";
import { dctGetAsyncData } from "../../../helpers/backend_helper";
import PhoneInput from "react-phone-input-2";

import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { ppid } from "process";
import { Config } from "../../../Config";

function PersonalInfo(props) {
  let memberObj = [];
  let member = [];
  let ajaxLoading = true;
  const [selectedState, seteSelectedState] = useState(null);
  const [timezoneData, seteTimezoneData] = useState({
    timezoneAbbr: "PST",
    timezoneUTC: "America/Los_Angeles",
  });
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");

  const [stateName, setStateName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [isSelectBoxOpen, setIsSelectBoxOpen] = useState(false);

  useEffect(() => {}, []);

  const handleTimezone = (e) => {
    seteTimezoneData((prevState) => ({
      ...prevState,
      timezoneAbbr: e.abbr ? e.abbr : "PST",
      timezoneUTC: e.utc ? e.utc[0] : "America/Los_Angeles",
    }));
  };

  // Load the fetched State data for the select dropdown
  const loadStateOptions = (inputValue, callback) => {
    var apiUrl = process.env.REACT_APP_API_PATH + "/state?country=us";
    const axiosArgs = {
      URL: apiUrl,
    };
    dctGetAsyncData(axiosArgs).then((results) => callback(results));
    return;
  };
  const stateListOptions = debounce(loadStateOptions, 250);

  const defualtStateValue = (data) => {
    if (!data) {
      return {
        name: "",
        id: "",
      };
    }
    return {
      name: data.state ? data.state : "",
      id: data.state,
    };
  };

  const dctCustomValidations = (fieldID, wrapperID) => {
    if (!fieldID && !wrapperID) return;

    let inputEl = document.querySelectorAll(`input[name="${fieldID}"]`);
    if (inputEl) {
      let inputIdValue = inputEl[0].value;
      if (inputIdValue) {
        document.querySelector(`#${wrapperID}`).classList.remove("text-danger");
        return inputIdValue;
      } else {
        document.querySelector(`#${wrapperID}`).classList.add("text-danger");
        return;
      }
    }
  };

  const handleStateChange = (selectedState) => {
    seteSelectedState({ selectedState });
    if (selectedState === null) {
      document.querySelector("#select-state").classList.add("text-danger");
    } else {
      document.querySelector("#select-state").classList.remove("text-danger");
    }
  };

  const updater = (member) => {
    if (countryName === "") {
      setCountryName(member.country);
    }
    if (stateName === "") {
      setStateName(member.state);
    }
    if (primaryPhone === "") {
      setPrimaryPhone(member.primaryPhone);
    }
  };

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    if (stateName) {
      values.state = stateName;
    } else {
      return;
    }

    if (countryName) {
      values.country = countryName;
    } else {
      return;
    }

    if (!primaryPhone || primaryPhone === "") {
      document.querySelector("#primary_phone").classList.add("text-danger");
      return;
    } else {
      document.querySelector("#primary_phone").classList.remove("text-danger");
      values.primaryPhone = `${primaryPhone}`;
    }

    values.secondaryPhone = secondaryPhone ? secondaryPhone : "";

    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.remove("d-none");

    values.timezoneAbbr = timezoneData.timezoneAbbr
      ? timezoneData.timezoneAbbr
      : "";
    values.timezoneUTC = timezoneData.timezoneUTC
      ? timezoneData.timezoneUTC
      : "";

    if (values.actionType === "edit") {
      props.updateMember(values, "");
    }
  }

  memberObj = useSelector((state) => state.Member);
  const { loading, message, singleMember } = memberObj;
  member = singleMember;
  ajaxLoading = loading;

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <div className="mt-2">
            <Label className="font-size-18">
              {props.t("Personal Information")}
            </Label>
            <p>
              {props.t(`Have you moved recently? Changed your telephone number? This is where you can update your personal information. Be sure to review and confirm that all changes are accurate before hitting "Save".`)}
              {""}
            </p>
            {ajaxLoading ? (
              <div className={`${Config.names.slugName}-container-loader-`}>
                {loadingMessage("Loading...")}
              </div>
            ) : (
              ""
            )}{" "}
            {message && member ? (
              <Alert color="success">{message}</Alert>
            ) : null}
            {message && !member ? (
              <Alert color="danger">{message}</Alert>
            ) : null}
            {member ? (
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <AvField
                  name="id"
                  type="hidden"
                  value={member ? member.id : ""}
                />
                <AvField name="actionType" type="hidden" value="edit" />
                <AvField
                  name="firstName"
                  type="hidden"
                  value={member && member.userId ? member.userId.firstName : ""}
                />
                <AvField
                  name="lastName"
                  type="hidden"
                  value={member && member.userId ? member.userId.lastName : ""}
                />
                <AvField name="country" type="hidden" value="US" />
                <div className="mt-4">
                  <Row>
                    <Col lg={6}>
                      <div className="mb-5">
                        <p className="font-weight-bold">
                          {props.t("First Name")}
                          <i
                            className="ml-1 bx bxs-help-circle"
                            id="firstname"
                          ></i>
                          <UncontrolledTooltip
                            placement="top"
                            target="firstname"
                          >
                            {props.t(
                              `This data cannot be changed by you. You can work with customer service to get changes made by calling 888-243-4505`
                            )}
                            {""}
                          </UncontrolledTooltip>
                        </p>
                        <p>
                          {member && member.userId
                            ? capitalizeWords(member.userId.firstName)
                            : ""}
                        </p>
                      </div>
                      <div className="mb-5">
                        <p className="font-weight-bold">
                          {props.t("Date of Birth")}
                          <i className="ml-1 bx bxs-help-circle" id="dob"></i>
                          <UncontrolledTooltip placement="top" target="dob">
                            {props.t(
                              `This data cannot be changed by you. You can work with customer service to get changes made by calling 888-243-4505`
                            )}
                            {""}
                          </UncontrolledTooltip>
                        </p>
                        <p>{member ? member.dob : ""}</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-5">
                        <p className="font-weight-bold">
                          {props.t("Last Name")}
                          <i
                            className="ml-1 bx bxs-help-circle"
                            id="lastname"
                          ></i>
                          <UncontrolledTooltip
                            placement="top"
                            target="lastname"
                          >
                            {props.t(
                              `This data cannot be changed by you. You can work with customer service to get changes made by calling 888-243-4505`
                            )}
                            {""}
                          </UncontrolledTooltip>
                        </p>
                        <p>
                          {member && member.userId
                            ? capitalizeWords(member.userId.lastName)
                            : ""}
                        </p>
                      </div>
                      <div className="form-member">
                        <AvField
                          type="select"
                          name="gender"
                          label={props.t("Gender")}
                          value={member ? member.gender : "unknown"}
                          required
                        >
                          <option value="unknown">{props.t("Other")}</option>
                          <option value="male">{props.t("Male")}</option>
                          <option value="female">{props.t("Female")}</option>
                        </AvField>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <div
                        className={`${Config.names.slugName}-phone-box form-group`}
                        id={`primary_phone`}
                      >
                        <label>{props.t(`Primary Phone`)}</label>
                        <PhoneInput
                          inputProps={{ name: "primaryPhone", required: true }}
                          country={(!countryName || countryName == '') ? 'us' : countryName.toLowerCase()}
                          value={primaryPhone ? primaryPhone : ''}
                          onChange={(primaryPhone) => {
                            setPrimaryPhone('+' + primaryPhone);
                          }}
                          enableSearch={true}
                          onlyCountries={['us', 'mx']}
                        />
                      </div>
                    </Col>
                    {/* <Col lg={6}>
                      <div className="form-group" id={`secondary_phone`}>
                        <label>{props.t(`Secondary Phone`)}</label>
                        <PhoneInput
                          inputProps={{ name: "secondaryPhone" }}
                          country={(!countryName || countryName == '') ? 'us' : countryName.toLowerCase()}
                          value={secondaryPhone ? secondaryPhone : ''}
                          onChange={(secondaryPhone) => {
                            setSecondaryPhone('+' + secondaryPhone);
                          }}
                          enableSearch={true}
                          onlyCountries={['us', 'mx']}
                        />
                      </div>
                    </Col> */}
                     <Col md={6}>
                      <div className="form-member">
                        <AvField
                          name="email"
                          label={props.t("Email")}
                          className="form-control"
                          placeholder={props.t("Email")}
                          type="text"
                          required
                          value={
                            member && member.userId ? member.userId.email : ""
                          }
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="d-flex">
                  
                    {/* <Col md={6}>
                      <div className="form-member">
                        <AvField
                          name="email"
                          label={props.t("Email")}
                          className="form-control"
                          placeholder={props.t("Email")}
                          type="text"
                          required
                          value={
                            member && member.userId ? member.userId.email : ""
                          }
                        />
                      </div>
                    </Col> */}
                    <Col xl={6}></Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="form-member">
                        <AvField
                          name="address"
                          label={props.t("Address")}
                          className="form-control"
                          placeholder={props.t("Address")}
                          type="textarea"
                          required
                          value={member ? member.address : ""}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-member">
                        <AvField
                          name="addressTwo"
                          label={props.t("Address Line 2")}
                          className="form-control"
                          placeholder={props.t("Address Line 2")}
                          type="textarea"
                          value={member ? member.addressTwo : ""}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex">
                    <Col md={6}>
                      <div className="form-member">
                        <AvField
                          name="city"
                          label={props.t("City")}
                          className="form-control"
                          placeholder={props.t("City")}
                          type="text"
                          required
                          value={member ? member.city : ""}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        <label htmlFor="country">
                          {props.t(`Select Country`)}
                        </label>
                        {updater(member)}
                        <CountryDropdown
                          defaultOptionLabel={countryName}
                          labelType="short"
                          valueType="short"
                          whitelist={["US", "MX"]}
                          classes="form-control"
                          onChange={(val) => {
                            setCountryName(val);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        className={`form-group ${Config.names.slugName}-select-box`}
                        id="select-state"
                      >
                        <label htmlFor="state">{props.t(`Select State`)}</label>
                        <RegionDropdown
                          defaultOptionLabel={stateName}
                          country={countryName}
                          countryValueType="short"
                          labelType="full"
                          valueType="short"
                          value={stateName}
                          classes="form-control"
                          onChange={(val) => {
                            setStateName(val);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-member">
                        <AvField
                          name="postalCode"
                          label={props.t("Postal Code")}
                          className="form-control"
                          placeholder={props.t("Postal Code")}
                          type="text"
                          required
                          value={member ? member.postalCode : ""}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className={isSelectBoxOpen ?  'd-flex h-400' : 'd-flex'}>
                    <Col md={6}>
                      <div className="form-member">
                        <label htmlFor="groupId">
                          {props.t("Select Timezone")}
                        </label>
                        <Select
                          defaultValue={{
                            value: member ? member.timezoneUTC : "",
                            abbr: member ? member.timezoneAbbr : "",
                          }}
                          placeholder="Select Timezone"
                          onChange={handleTimezone}
                          name="timezone"
                          getOptionLabel={(e) => e.value}
                          getOptionValue={(e) => e.abbr}
                          className={`${Config.names.slugName}-custom-select-box`}
                          options={dctTimezones}
                          onMenuOpen={() => setIsSelectBoxOpen(true)}
                          onMenuClose={() => setIsSelectBoxOpen(false)}

                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="modal-footer">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          type="submit"
                        >
                          <span className="dr-mform-spinner d-none">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                          </span>{" "}
                          {props.t("Save")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </AvForm>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { singleMember } = state.Member;
  return { singleMember };
};

export default withRouter(
  connect(mapStatetoProps, { updateMember, setSingleMember })(
    withNamespaces()(PersonalInfo)
  )
);
