import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import Notification from "../CommonForBoth/TopbarDropdown/Notification";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// if(process.env.REACT_APP_API_PATH === "canelo") {
// import logo from "../../assets/images/canelo/logo.svg";
// import logoLightPng from "../../assets/images/canelo/logo-light.png";
// import logoLightSvg from "../../assets/images/canelo/logo-light.svg";
// import logoDark from "../../assets/images/canelo/logo-dark.svg";

// }

//i18n
import { withNamespaces } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

import { Config } from "./../../Config";

const Header = (props) => {
  const [search, setsearch] = useState(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [url, setUrl] = useState("/")

  let { authUser, authUserType } = useSelector((state) => state.Login);

  if(!authUser){
    authUser= JSON.parse(localStorage.getItem('auth_user'));
    authUserType=authUser.userType;
  }


  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  }
  useEffect(() => {
    if(authUserType === 'customerservice') {
      setUrl('/members')
    }
  }, [])
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to={url} className="logo logo-dark">
                <span className="logo-sm">
                  <img src={Config.logos.logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={Config.logos.logoDark} alt="" style={{width:'140px'}} />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={Config.logos.logoLightSvg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={Config.logos.logoLightPng} alt="" style={{width:'140px'}} />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input type="text" className="form-control" placeholder={props.t('Search') + "..."} />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form> */}
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ml-2">
              {/* <button onClick={() => { setsearch(!search) }} type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                  <i className="mdi mdi-magnify"></i>
                </button> */}
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        {/* <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <LanguageDropdown />

            <div className="dropdown d-none d-lg-inline-block ml-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="d-none btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen"></i>
              </button>
            </div>
            {authUserType === "admin" && <Notification />}
            {/* {()=>{
              if(authUserType === "admin"){
                if()
              }
            }} */}
            {/* {authUserType === "doctor" || authUser.rx ? (
              ""
            ) : (
              <NotificationDropdown />
            )} */}
            {authUser.rx == false && <NotificationDropdown />}
            {authUserType === "doctor" ? "" : <NotificationDropdown />}

            <ProfileMenu />

            {/* <div onClick={() => { props.showRightSidebarAction(!props.showRightSidebar); }} className="dropdown d-inline-block">
                <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withNamespaces()(Header));
