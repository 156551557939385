import React from "react";
import { useState } from "react";
import { Row, Col } from "reactstrap";
import { Config } from "../../../../Config/Config";
import axios from "axios";
import { phoneFormatter } from "../../service/phoneFormatter";
const IdentifyNoSeeEmail = (props) => {
  return (
    <>
      <Col
        lg="5"
        md="6"
        sm="8"
        xs="10"
        className={`${Config.names.slugName}-box-shadow rounded-lg mt-3`}
      >
        <div className="py-5 px-3">
          <h5 className="text-primary font-weight-bold">DON'T WORRY</h5>
          <p className="font-size-15 font-weight-light mt-3">
            Please contact our support team and they will assist with locating
            your account
          </p>

          <div className="d-flex flex-column justify-content-center align-items-center mt-4">
            <p className="font-size-16"><i className="fas fa-phone-alt mr-2"></i> Call Us <span className="text-primary font-weight-bold ml-2">{phoneFormatter(`${Config.names.callContact}`, 2)}</span></p>
            <p className="font-size-16 text-secondary">OR</p>
            <p className="font-size-16"><i className="fas fa-envelope mr-2"></i> Email us <span className="text-primary font-weight-bold ml-2">{Config.names.helpMail}</span></p>
          </div>

        </div>
      </Col>
    </>
  );
};

export default IdentifyNoSeeEmail;
