import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
  Label,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

import AsyncSelect from "react-select/async";

import debounce from "lodash/debounce";

import {
  createrxPharmacy,
  updaterxPharmacy,
  createrxPharmacyFailed,
  rxPharmacyActionSuccessful,
  setSinglerxPharmacy,
} from "../../store/actions";

//Include Both Helper File with needed methods
import {
  dctGetPharmacyList,
  dctPostRawData,
  dctPutData,
} from "../../helpers/backend_helper";
import { Config } from "../../Config";

const CreateUpdaterxPharmacy = (props) => {
  const [modal, setModal] = useState(false);
  const [msgEnable, setMsgEnable] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [dosespotPharmacyId, setDosespotPharmacyId] = useState("");
  const [dosespotPharmacyName, setDosespotPharmacyName] = useState("");

  let rxpharmacyData = useSelector(
    (state) => state.rxPharmacy.singlerxPharmacy
  );
  rxpharmacyData = rxpharmacyData ? rxpharmacyData : [];

  useEffect(() => {
    getPharmacyById(rxpharmacyData?.pharmacy_pharmacyId);

    if (rxpharmacyData.companyId) {
      setSelectedCompany(rxpharmacyData.companyId);
    }
  }, [rxpharmacyData]);

  function toggleModalClose(e) {
    if (props.singlerxPharmacy) {
      props.updaterxPharmacyDataFlag();
    }
  }

  const getPharmacyById = async (pharmacyId) => {
    if (!pharmacyId) {
      return;
    } else {
      let url = `pharmacy/${pharmacyId}`;
      const response = await dctGetPharmacyList(url, "").then((data) => data);
      if (response.success === true) {
        // dispatch(setSinglePharmacy(response.data, ""));
        setDosespotPharmacyName(response.data.StoreName);
        setDosespotPharmacyId(response.data.PharmacyId);
      }
    }
  };

  // Load the fetched Pharmacy data for the select dropdown
  const loadPharmacyOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 2) {
      let url = "pharmacy";
      const axiosArgs = {
        name: inputValue,
      };
      dctGetPharmacyList(url, axiosArgs).then((results) =>
        callback(results.data)
      );
    }
    return;
  };
  const pharmacyListOptions = debounce(loadPharmacyOptions, 250);

  const handlePharmacyName = (e) => {
    if (!e) {
      setDosespotPharmacyId("");
      setDosespotPharmacyName("");
      return;
    }
    document.querySelector("#pharmacyName").classList.remove("text-danger");
    setDosespotPharmacyId(e.PharmacyId ? e.PharmacyId : "");
    setDosespotPharmacyName(e.StoreName ? e.StoreName : "");
  };

  async function handleValidSubmit(event, values) {
    if (!dosespotPharmacyName || dosespotPharmacyName == "") {
      document.querySelector("#pharmacyName").classList.add("text-danger");
      return;
    }
    document.querySelector("#pharmacyName").classList.remove("text-danger");

    document
      .getElementsByClassName("dr-cform-spinner")[0]
      .classList.remove("d-none");

    setMsgEnable(true);

    //call reducer method
    if (values.actionType === "edit") {
      const url = `${process.env.REACT_APP_API_PATH}/rxpharmacy/${values.id}`;
      const header = {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      };
      values.pharmacy_pharmacyId = dosespotPharmacyId;
      values.pharmacy_pharmacyName = dosespotPharmacyName;
      await dctPutData(url, values, header);
      setModal(false);
      props.fetchrxPharmacyData("", 1);
    } else {
      // await props.createrxPharmacy(values, props.history);
      const url = `${process.env.REACT_APP_API_PATH}/rxpharmacy`;
      const header = {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      };
      values.pharmacy_pharmacyId = dosespotPharmacyId;
      values.pharmacy_pharmacyName = dosespotPharmacyName;
      await dctPostRawData(url, values, header);
      setModal(false);
      props.fetchrxPharmacyData("", 1);
    }
  }

  const dispatch = useDispatch();

  const editFormrxPharmacyItem = (e) => {
    setMsgEnable(false);
    dispatch(rxPharmacyActionSuccessful(null, "", ""));
  };

  const createFormrxPharmacyItem = (e) => {
    getPharmacyById("");
    setDosespotPharmacyName("");
    setMsgEnable(false);
    dispatch(rxPharmacyActionSuccessful(null, "", ""));
    dispatch(setSinglerxPharmacy(null, "", ""));
  };

  const action_type =
    props.rxpharmacy_action_type === "edit" ? "edit" : "create";
  const button_label =
    props.rxpharmacy_action_type === "edit" ? "Update" : "Create RxPharmacy";
  const modal_title =
    props.rxpharmacy_action_type === "edit"
      ? "Update RxPharmacy"
      : "Create RxPharmacy";
  return (
    <React.Fragment>
      {(() => {
        switch (props.rxpharmacy_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="rxpharmacy_update_modal"
                onClick={() => {
                  setModal(!modal);
                  editFormrxPharmacyItem();
                }}
              ></i>
            );
          case "edit_btn":
            return (
              <Button
                type="button"
                color="primary"
                id=" rxpharmacy_update_modal"
                className="waves-effect waves-light mr-2"
                onClick={() => {
                  setModal(!modal);
                  editFormrxPharmacyItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1"></i>
                {props.t("Edit ")}
              </Button>
            );
          default:
            return (
              <Link
                onClick={() => {
                  setModal(!modal);
                  createFormrxPharmacyItem();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id=" rxpharmacy_create_modal"
              >
                <i className="mdi mdi-plus mr-1"></i>
                {props.t("Create RxPharmacy")}
              </Link>
            );
        }
      })()}

      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-rxpharmacy-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.createrxPharmacyError &&
              props.createrxPharmacyError &&
              msgEnable ? (
                <Alert color="danger">{props.createrxPharmacyError}</Alert>
              ) : null}
              {props.message && props.message && msgEnable ? (
                <Alert color="success">{props.message}</Alert>
              ) : null}

              {action_type === "edit" ? (
                <AvField name="id" type="hidden" value={rxpharmacyData.id} />
              ) : (
                ""
              )}
              <AvField name="actionType" type="hidden" value={action_type} />

              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvGroup>
                      <Label for="example">
                        {props.t("First Name")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={rxpharmacyData?.userId?.firstName}
                        name="firstName"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("First Name")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-company">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Last Name")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={rxpharmacyData?.userId?.lastName}
                        name="lastName"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Last Name")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-company">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Email")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={rxpharmacyData?.userId?.email}
                        name="email"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Email")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <AvGroup id="pharmacyName">
                    <div className="form-company">
                      <label htmlFor="pharmacyName">
                        {props.t("Pharmacy ")}
                      </label>
                      <i className="fas fa-star-of-life text-danger custom-star" />
                      <AsyncSelect
                        isClearable={true}
                        placeholder={props.t("Search Pharmacy")}
                        name="pharmacyName"
                        getOptionLabel={(e) => e.StoreName}
                        getOptionValue={(e) => e.PharmacyId}
                        className={`${Config.names.slugName}-custom-select-box`}
                        cacheOptions
                        defaultOptions
                        loadOptions={pharmacyListOptions}
                        onChange={handlePharmacyName}
                        value={{ StoreName: dosespotPharmacyName }}
                        // defaultValue={{ StoreName: dosespotPharmacyName }}
                        required
                      />
                    </div>
                  </AvGroup>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setModal(!modal)}
                    >
                      {props.t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                    >
                      <span className="dr-cform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      {button_label}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createrxPharmacyError } = state.rxPharmacy;
  const { rxpharmacy } = state.rxPharmacy;
  const { message } = state.rxPharmacy;
  return { createrxPharmacyError, rxpharmacy, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createrxPharmacy,
    updaterxPharmacy,
    createrxPharmacyFailed,
    setSinglerxPharmacy,
  })(withNamespaces()(CreateUpdaterxPharmacy))
);
