import React, { Fragment, useState, useEffect } from "react";
import { withRouter,  } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect, useSelector } from "react-redux";

import { Row, Col, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  updateConsultStepwise,
  getSingleConsultation,
  getMemberAndDependents,
  getDraftConsultation,
} from "../../../store/actions";

import { loadingMessage, dctClsAddRemove } from "../../../helpers/Commons";

import ConsultFormFooter from "./consultFormFooter";
import PhoneInput from "react-phone-input-2";
import { Config } from "../../../Config";

function Phone(props) {
  const [service, setService] = useState([
    "Visit Primary Care Physician",
    "Go to Urgent Care",
    "Go to Emergency Room",
    "Nothing",
  ]);
  useEffect(()=>{
    setService(service);
  },[service])
  // const [phone, setPhone] = useState("");

  let memberObj = [];
  const [_primaryPhone, set_primaryPhone] = useState("");
  const [primaryKey, setPrimaryKey] = useState(false);

  memberObj = useSelector((state) => state.Member);
  const { singleConsult } = memberObj;

  useEffect(() => {
    props.pageTitle(
      `By Appointment Medical Questionnaire for ${singleConsult?.patient}`
    );
    set_primaryPhone(singleConsult?.phone_tel);
  }, [singleConsult]);

  //Update the consultation
  function handleValidSubmit(event, values) {
    if (primaryKey === true) {
      if (!_primaryPhone || _primaryPhone === "") {
        document.querySelector("#primary_phone").classList.add("text-danger");
        return;
      } else {
        document
          .querySelector("#primary_phone")
          .classList.remove("text-danger");
        values.phone_tel = `${_primaryPhone}`;
      }
    } else {
      if (!_primaryPhone || _primaryPhone === "") {
        document.querySelector("#primary_phone").classList.add("text-danger");
        return;
      } else {
        document
          .querySelector("#primary_phone")
          .classList.remove("text-danger");
        values.phone_tel = `${_primaryPhone}`;
      }
    }

    dctClsAddRemove("dr-form-spinner", "d-none", "remove");
    props.updateConsultStepwise(values, props.history, 3);
  }

  return (
    <Fragment>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        {!singleConsult ? (
          <div className="mt-1 mb-3">{loadingMessage("Loading...")}</div>
        ) : null}

        <Row>
          <Col lg="12">
            <FormGroup>
              <Col lg={12}>
                <p className="mb-0 text-muted font-size-13">
                  {props.t(`You are about to schedule a diagnostic telephone Medical consultation with a physician. Diagnostic consultations are detailed discussions with an appropriately licensed physician to discuss symptoms, diagnose common conditions and even prescribe medication as appropriate`)}
                </p>

                <div className="mt-4 font-weight-medium">
                  {props.t(`Please verify that this is the phone number where you can be reached.`)}
                </div>
                <Row className="mt-0">
                  <Col md={5}>
                    <AvField name="step" type="hidden" value="3" />
                    <AvField
                      name="id"
                      type="hidden"
                      value={singleConsult ? singleConsult.id : ""}
                    />
                    <AvField name="stepType" type="hidden" value="phone" />
                    <div
                      className={`form-group ${Config.names.slugName}-phone-box`}
                      id={`primary_phone`}
                    >
                      <PhoneInput
                        inputProps={{ name: "phone_tel", required: true }}
                        country={
                          !singleConsult ||
                          !singleConsult.country ||
                          singleConsult.country === ""
                            ? "us"
                            : singleConsult.country.toLowerCase()
                        }
                        value={
                          singleConsult && singleConsult.phone_tel
                            ? singleConsult.phone_tel
                            : _primaryPhone
                        }
                        onChange={(_primaryPhone) => {
                          set_primaryPhone("+" + _primaryPhone);
                          setPrimaryKey(true);
                        }}
                        enableSearch={true}
                        onlyCountries={["us", "mx"]}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg={12}>
                    <div className="input-group">
                      <div className="mt-0 font-weight-medium">
                        {props.t(
                          `What would you have done if you didn't have the service?`
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col lg={5}>
                    <span style={{display: "none"}}>{props.t("Visit Primary Care Physician")} {props.t("Go to Urgent Care")} {props.t("Go to Emergency Room")} {props.t("Nothing")}</span>
                    <AvField
                      className="custom-select mt-2"
                      type="select"
                      name="phone_service"
                      placeholder={props.t(`Please Choose One`)}
                      id="phone_service"
                      required
                      value={singleConsult ? singleConsult.phone_service : ""}
                    >
                      <option value="">{props.t(`Please Choose One`)}</option>
                      {service.map((value, key) => (
                        <option key={key} value={value || ""}>
                          {props.t(value)}
                        </option>
                      ))}
                    </AvField>
                  </Col>
                </Row>

                <ConsultFormFooter />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const {
    memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  } = state.Member;
  return {
    memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    updateConsultStepwise,
    getSingleConsultation,
    getMemberAndDependents,
    getDraftConsultation,
  })(withNamespaces()(Phone))
);
