import React from "react";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  CardBody,
  Alert,
  Container,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

// availity-reactstrap-validation
import {
  AvForm,
  AvInput,
  AvFeedback,
  AvGroup,
} from "availity-reactstrap-validation";

// actions
import { loginUser, apiError } from "../../store/actions";

//Include Common Helpers
import { DctFooterCredit } from "../../helpers/Commons";

import { Config } from "./../../Config";

const Login = (props) => {
  // handleValidSubmit
  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-login-spinner")[0]
      .classList.remove("d-none");
    props.loginUser(values, props.history);
  }

  /* Redirect to Dashboard if user alrady loggedin */
  if (localStorage.getItem("auth_token")) {
    return (
      <Redirect
        to={{ pathname: "/dashboard", state: { from: props.location } }}
      />
    );
  }

  const layout_footer = (slug) => {
    switch (slug) {
      case "doctegrity":
        return (
          <div className={`mt-5 text-center ${Config.names.slugName}-copyright-section-2 text-primary`}>
            {/* <p>
              {props.t(`Activate Your`)}{" "}
              <Link
                to="/activation"
                className="font-weight-medium text-primary"
              >
                {props.t(`Benefits`)}
              </Link>{" "}
            </p> */}
            {DctFooterCredit(Config.names.appName)}
          </div>
        );

      case "telaq":
        return (
          <div className={`mt-5 text-center ${Config.names.slugName}-copyright-section-2 text-primary`}>
            {/* <p>
              {props.t(`Activate Your`)}{" "}
              <Link
                to="/activation"
                className="font-weight-medium text-primary"
              >
                {props.t(`Benefits`)}
              </Link>{" "}
            </p> */}
            {DctFooterCredit(Config.names.appName)}
          </div>
        );

      default:
        return (
          ""
        );
    }

  };

  return (
    <React.Fragment>
      <div
        className={`py-5 bg-black app-${process.env.REACT_APP_SLUG}`}
        style={{ minHeight: "100vh", height: "100%", marginTop: 0 }}
      >
        <div className="account-pages my-5 pt-sm-2">
          <Container className={`${Config.names.slugName}-container-sm`}>

            <Row className="justify-content-center mt-5">
              <Col md={12} lg={12} xl={12} className="d-flex justify-content-center align-items-center">
                <img src={Config.logos.logoDark} alt="" style={{ width: '14rem' }} />
              </Col>
            </Row>
            <div className={`${Config.names.slugName}-box-shadow ${Config.names.slugName}-login-container`}>
              <Row className="justify-content-center mt-5">
                <Col md={8} lg={6} xl={6} className={`${Config.names.slugName}-desktop p-0`}>
                  <img
                    src={Config.images.loginBanner}
                    alt="Banner"
                    className="img-fluid"
                  />
                </Col>
                <Col md={8} lg={6} xl={6}>
                  <div className={`overflow-hidden ${Config.names.slugName}-auth-form`}>
                    {/*<div className={`${Config.names.slugName}-login-logo`}>
                      <img className= {"telaImg"} src={Config.logos.loginLogo} alt="App" height="35" />
                    </div>*/}
                    <div className={`${Config.names.slugName}-auth-header ${Config.names.slugName}-bg-black`}>
                      <Row>
                        <Col className="col-12">
                          <div className={`mt-5 ml-4 mt-sm-0 ${Config.names.slugName}-auth-header-wrap`}>
                            <h4 className={`${Config.names.slugName}-auth-header`}>
                              {props.t("Welcome")}{" "}
                              <strong>{props.t("Back")}</strong>
                            </h4>
                            <p className="">
                              {props.t("Sign in to continue to")}{" "}
                              <strong>{`${Config.names.nickName}`}</strong>
                              {Config.names.slugName === "hbaehealth" && <span className="position-absolute" style={{ fontSize: '6px' }}>SM</span>}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className={`pt-0 ${Config.names.slugName}-bg-black`}>
                      <div className="">
                        <AvForm
                          className={`form-horizontal ${Config.names.slugName}-dark-theme`}
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v);
                          }}
                        >
                          {props.error && props.error ? (
                            <Alert color="danger">{props.error}</Alert>
                          ) : null}

                          <div className="form-group">
                            <AvGroup>
                              <Label for="email">{props.t("Email")}</Label>
                              <InputGroup className={`${Config.names.slugName}-input-group`}>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    {" "}
                                    <i className="dripicons-mail"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <AvInput name="email" required />
                                <AvFeedback>
                                  {props.t("This field is required")}.
                                </AvFeedback>
                              </InputGroup>
                            </AvGroup>
                          </div>

                          <div className="form-group">
                            <AvGroup>
                              <Label for="password">
                                {props.t("Password")}
                              </Label>
                              <InputGroup className={`${Config.names.slugName}-input-group`}>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    {" "}
                                    <i className="bx bx-lock"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <AvInput
                                  name="password"
                                  type="password"
                                  required
                                />
                                <AvFeedback>
                                  {props.t("This field is required.")}
                                </AvFeedback>
                              </InputGroup>
                            </AvGroup>
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light text-uppercase"
                              type="submit"
                            >
                              <span className="dr-login-spinner d-none">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                              </span>{" "}
                              {props.t("Log In")}
                            </button>
                          </div>

                          <div className="mt-4 text-right">
                            <Link
                              to="/forgot-password"
                              className={`text-muted ${Config.names.slugName}-override-link-text`}
                            >
                              {props.t("Forgot password?")}
                            </Link>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </div>
                  {process.env.REACT_APP_SLUG === "canelo" ? (
                    <div className={`mt-5 text-center ${Config.names.slugName}-copyright-section-1`}>
                      {DctFooterCredit(Config.names.appName)}
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>

            {layout_footer(process.env.REACT_APP_SLUG)}
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(withNamespaces()(Login))
);
