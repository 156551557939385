import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { withRouter, Link } from "react-router-dom";

import { capitalizeWords, dctHumanReadableDate, loadingMessage } from "../../helpers/Commons";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { BeatLoader } from "react-spinners";
import { Config } from "../../Config";
const {
  Parser,
  transforms: { unwind },
} = require("json2csv");

const RxReport = (props) => {
  const [rxData, setrxData] = useState([]);
  const [ajaxLoader, setajaxLoader] = useState(true);
  const [fromFullDate, setFromFullDate] = useState('');
  const [toFullDate, setToFullDate] = useState('');

  const [postsPerPage, setPostsPerPage] = useState(
    process.env.REACT_APP_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [ajaxKey, setAjaxKey] = useState(false)

  const prescriptionObj = useSelector(
    (state) => state.rxPrescription.rxPrescriptionListing
  );

  const featchData = (currentPage = 1) => {
    setAjaxKey(false)
    setajaxLoader(true);
    axios
      .get(`${process.env.REACT_APP_API_PATH}/prescriptionfill?page=${currentPage}&limit=${postsPerPage}`, {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      })
      .then(function (response) {
        const data = response.data
        let structured = data.data
        if (data.total > 30) {
          structured = [...rxData, ...data.data]
        }
        // console.log(structured.length)
        setrxData(structured);
        setajaxLoader(false);
        setTotalPages(Math.ceil(response.data.total / postsPerPage));
        setAjaxKey(true)
      })
      .catch(function (err) { });
  };

  const handleFromDateChange = (date) => {
    setFromFullDate(date);
    // setFromYear(moment(date).format('YYYY'));
    // setFromMonth(moment(date).format('MM'));
    // setFromDate(moment(date).format('DD'));
  };
  const handleToDateChange = (date) => {
    setToFullDate(date);
    // setFromYear(moment(date).format('YYYY'));
    // setFromMonth(moment(date).format('MM'));
    // setFromDate(moment(date).format('DD'));
  };

  useEffect(() => {
    featchData(currentPage + 1)
  }, [currentPage])

  const onScrollToEnd = () => {
    // console.log("currentPage..onScrolltoend", currentPage)
    setCurrentPage(currentPage + 1);
  }
  window.onscroll = function () {
    //check if the page has scrolled to the bottom
    let innerHeight = window.innerHeight + document.documentElement.scrollTop + 700;
    let offsetHeight = document.documentElement.offsetHeight;
    if (currentPage + 1 === totalPages || rxData?.length == 0) return;

    if (innerHeight >= offsetHeight) {
      if (ajaxKey) {
        onScrollToEnd();
      }
    }
  }

  const handleExport = () => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/prescriptionfill?page=${1}&limit=${9999999}&scriptkey=true&fromdate=${moment(fromFullDate).format('MM-DD-YYYY')}&todate=${moment(toFullDate).format('MM-DD-YYYY')}&timezoneUTC=${moment.tz.guess()}`, {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      })
      .then(function (response) {
        let data = response.data.data;
        data = data.filter(d => d.status === "accepted");
        // const fields = [
        //   "patientFullName",
        //   "rxpharmacyId.userId.fullName",
        //   "state",
        //   "doctorFullName",
        //   "createdAt",
        //   "updatedAt",
        // ];

        const fields = [
          "id",
          "patientFullName",
          "rxpharmacyId.userId.fullName",
          "state",
          "doctorFullName",
          "createdAt",
          "updatedAt",
          "duplicate",
        ];
        let json2csvParser = new Parser({ delimiter: "\t", fields });
        // console.log(json2csvParser);
        // json2csvParser.opts.fields[0].label = "Member Name";
        json2csvParser.opts.fields[0].label = "Script ID";

        json2csvParser.opts.fields[1].label = "Patient";

        json2csvParser.opts.fields[2].label = "Pharmacy";
        json2csvParser.opts.fields[3].label = "State";
        json2csvParser.opts.fields[4].label = "Doctor";
        json2csvParser.opts.fields[5].label = "Script In";
        json2csvParser.opts.fields[6].label = "Script Out";
        json2csvParser.opts.fields[7].label = "Duplicate Status";

        const tsv = json2csvParser.parse(data);
        // console.log(tsv);
        const uid = uuidv4();
        const exportedFileName = uid + ".tsv";

        let blob = new Blob([tsv], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="d-flex flex-md-row float-md-right mb-2 ml-10 justify-content-md-end float-sm-left float-left flex-sm-column flex-column justify-content-sm-between align-items-sm-center">
                <Col className='mr-2  d-flex flex-row align-items-center m-1' lg='3'>
                  <label htmlFor="dateFrom" className="mb-0 mr-2" style={{ width: '2rem' }} >From</label>
                  <ReactDatePicker
                    name="dateFrom"
                    className="form-control"
                    selected={fromFullDate}
                    dateFormat="MM-dd-yyyy"
                    onChange={handleFromDateChange}
                    placeholderText="MM-DD-YYYY"
                    showYearDropdown
                  />
                </Col>
                <Col className='mr-2  d-flex flex-row align-items-center m-1' lg='3'>
                  <label htmlFor="dateTo" className="mb-0 mr-2" style={{ width: '2rem' }}>To</label>
                  <ReactDatePicker
                    name="dateTo"
                    className="form-control"
                    selected={toFullDate}
                    dateFormat="MM-dd-yyyy"
                    onChange={handleToDateChange}
                    placeholderText="MM-DD-YYYY"
                    showYearDropdown
                    maxDate={new Date()}
                  />
                </Col>
                <Col lg='2' className=' d-flex float-right justify-content-md-start justify-content-end justify-content-sm-end m-1'>
                  <Link
                    onClick={() => {
                      handleExport();
                    }}
                    to="#"
                    className="popup-form btn btn-primary"
                    id="group_create_modal"
                  >
                    <i className="mdi mdi-file-export-outline mr-1"></i>{" "}
                    {"Export"}
                  </Link>
                </Col>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="group-list">
                <div className="table-responsive  ">
                  <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table rx_report `}>
                    <Thead className="thead-light ">
                      <Tr>
                        <Th scope="col" className="text-center">#</Th>
                        <Th scope="col" className="text-left">Member Name</Th>
                        <Th scope="col" className="text-left">Pharmacy</Th>
                        {/* <Th scope="col">Member Email</Th> */}
                        <Th scope="col" className="text-center">State</Th>
                        <Th scope="col" className="text-left">Doctor</Th>
                        <Th scope="col" className="text-left">Script In</Th>
                        <Th scope="col" className="text-left">Script Out</Th>
                        <Th scope="col" className="text-left">Script ID</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {rxData &&
                        rxData.map((rx) => {
                          if (rx.status === "accepted") {
                            return (
                              <Tr rx_reportscope="row" key={rx.id}>
                                <Td className="text-center">
                                  <span
                                    className="avatar-title rounded-circle text-capitalize"
                                    style={{ width: "30px", height: "30px" }}
                                  >
                                    {rx?.firstName.slice(0, 1)}
                                  </span>
                                </Td>

                                <Td className="text-left">{rx?.firstName + " " + rx.lastName}</Td>

                                <Td className="text-left">{capitalizeWords(rx?.rxpharmacyId?.userId?.fullName)}</Td>

                                <Td className="text-center">{rx.state}</Td>
                                <Td className="text-left">
                                  {rx.userId
                                    ? rx.userId.firstName +
                                    " " +
                                    rx.userId.lastName
                                    : ""}
                                </Td>

                                <Td className="text-left">{dctHumanReadableDate(rx.createdAt)}</Td>

                                <Td className="text-left">{dctHumanReadableDate(rx.updatedAt)}</Td>
                                <Td className="text-left"> {rx.id}</Td>
                              </Tr>
                            );
                          }
                        })}
                    </Tbody>
                  </Table>
                </div>
                <Row className="d-flex justify-content-center">
                  <BeatLoader size={12} color={Config.colors.pieChartActiveColor} loading={ajaxLoader} />
                </Row>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RxReport;
