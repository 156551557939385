import { CREATE_RXPRESCRIPTION, UPDATE_RXPRESCRIPTION, GET_RXPRESCRIPTION, GET_RXPRESCRIPTION_LIST, SET_RXPRESCRIPTION_LIST, CREATE_RXPRESCRIPTION_SUCCESS, CREATE_RXPRESCRIPTION_FAILED, API_ERROR, SET_SINGLE_RXPRESCRIPTION, SET_RXPRESCRIPTION_LISTING } from './actionTypes';

const initialState = {
    createrxPrescriptionError: null, 
    message: null, 
    loading: false,
    action_type: null,
}

const rxprescription = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_RXPRESCRIPTION:
            state = {
                ...state,
                rxprescription: action.payload,
                loading: true,
                createrxPrescriptionError: null
            }
            break;
        case UPDATE_RXPRESCRIPTION:
            state = {
                ...state,
                rxprescription: action.payload,
                loading: true,
                createrxPrescriptionError: null
            }
            break;
        case GET_RXPRESCRIPTION:
            state = {
                ...state,
                rxprescription: action.payload.result,
                loading: true,
                createrxPrescriptionError: null
            }
            break;
        case GET_RXPRESCRIPTION_LIST:
            state = {
                ...state,
                rxprescription: action.payload.result,
                loading: true,
                createrxPrescriptionError: null
            }
            break;
        case SET_SINGLE_RXPRESCRIPTION:
            state = {
                ...state,
                rxprescription: null,
                singlerxPrescription: action.payload,
                loading: false,
                createrxPrescriptionError: null,
                action_type: action.action_type,
            }
            break;
        case SET_RXPRESCRIPTION_LIST:
            state = {
                ...state,
                loading: false,
                rxprescription: action.payload,
                message: action.message,
                createrxPrescriptionError: null,
                action_type: action.action_type,
            }
            break;
        case SET_RXPRESCRIPTION_LISTING:
            state = {
                ...state,
                loading: false,
                rxprescription: null,
                rxPrescriptionListing: action.payload,
                message: action.message,
                createrxPrescriptionError: null,
            }
            break;    
        case CREATE_RXPRESCRIPTION_SUCCESS:
            state = {
                ...state,
                loading: false,
                rxprescription: action.payload,
                message: action.message,
                createrxPrescriptionError: null,
                action_type: action.action_type,
            }
            break;
        case CREATE_RXPRESCRIPTION_FAILED:
            state = {
                ...state,
                rxprescription: null,
                createrxPrescriptionError: action.payload,
                loading: false,
            }
            break;
        case API_ERROR:
            state = { 
                ...state, 
                error: action.payload, 
                loading: false 
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default rxprescription;


