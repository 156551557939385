import React from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//Include Common Helpers
import { DctFooterCredit } from "../../helpers/Commons";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { Config } from "../../Config";

const ForgetPasswordPage = (props) => {
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          {props.t(`Welcome Back !`)}
                        </h5>
                        <p>
                          {props.t("Sign in to continue to")}{" "}
                          {process.env.REACT_APP_NAME}.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="logo" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm className="form-horizontal mt-4">
                      <div className="form-group">
                        <AvField
                          name="email"
                          label={props.t("Email")}
                          className="form-control"
                          placeholder={props.t("Enter email")}
                          type="email"
                          required
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            {props.t("Reset")}
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {props.t("Go back to")}{" "}
                  <Link
                    to="pages-login"
                    className="font-weight-medium text-primary"
                  >
                    {props.t("Login")}
                  </Link>{" "}
                </p>
                {DctFooterCredit(Config.names.appName)}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(ForgetPasswordPage));
