import { CREATE_PARTNER, UPDATE_PARTNER, GET_PARTNER, GET_PARTNER_LIST, SET_PARTNER_LIST, CREATE_PARTNER_SUCCESS, CREATE_PARTNER_FAILED, API_ERROR, SET_SINGLE_PARTNER, SET_PARTNER_LISTING } from './actionTypes';

export const createPartner = (partner) => ({
    type: CREATE_PARTNER,
    payload: { partner }
})

export const updatePartner = (partner) => ({
    type: UPDATE_PARTNER,
    payload: { partner }
})

export const getPartner = (partner) => ({
    type: GET_PARTNER,
    payload: { partner }
})

export const getPartnerList = (partner) => ({
    type: GET_PARTNER_LIST,
    payload: { partner }
})

export const setPartnerList = (partner, message, action) => {
    return {
        type: SET_PARTNER_LIST,
        payload: partner,
        message: message,
        action_type: action
    }
}


export const setSinglePartner = (partner, message, action) => {
    return {
        type: SET_SINGLE_PARTNER,
        payload: partner,
        message: message,
        action_type: action
    }
}

export const setPartnerListing = (partner, message) => {
    return {
        type: SET_PARTNER_LISTING,
        payload: partner,
        partnerListing: partner,
        message: message,
    }
}

export const partnerActionSuccessful = (partner, message, action) => {
    return {
        type: CREATE_PARTNER_SUCCESS,
        payload: partner,
        message: message,
        action_type: action
    }
}

export const createPartnerFailed = (error) => {
    return {
        type: CREATE_PARTNER_FAILED,
        payload: error
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}