import React, { Fragment, useEffect, useState } from "react";
// Redux
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";

import { Col, Label, Row, UncontrolledTooltip, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import Select from "react-select";

import { updateMember, setSingleMember } from "../../../store/actions";

function LanguageChange(props) {
  return (
    <Fragment>
        {/* <h1>Hiiii</h1> */}
    </Fragment>
  );
}

const mapStatetoProps = () => {
    return {};
};

export default withRouter(
  connect(mapStatetoProps, { updateMember, setSingleMember })(
    withNamespaces()(LanguageChange)
  )
);
