import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
  Label,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

// import debounce from "lodash/debounce";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

import {
  createMember,
  updateMember,
  createMemberFailed,
  memberActionSuccessful,
  setSingleMember,
  setMemberListing,
  // setCompanyList,
} from "../../../store/actions";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../../helpers/backend_helper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  CountryDropdown,
  RegionDropdown,
  // CountryRegionData,
} from "react-country-region-selector";
import { dctUpdateStatus } from "../../../helpers/backend_helper";
import { Config } from "../../../Config";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import {
  dctDobGlobalFormatForDatePicker,
  dctHumanReadableGlobalFormat,
} from "../../../helpers/Commons";

const CreateUpdateDependent = (props) => {
  const modal = props.modal;
  const setModal = props.setModal;
  const [msgEnable, setMsgEnable] = useState(true);
  const [ajaxKey, setAjaxKey] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [dependentDob, setDependentDob] = useState(new Date());
  const [dependentAge, setDependentAge] = useState(0);
  const [error, setError] = useState("");
  const [sweetAlert, setSweetAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const membersObj = useSelector((state) => state.Member.memberListing);
  const [searchFormDetails, setSearchFormDetails] = useState({
    memberSearchParam: "",
    type: "filter",
    reset: false,
  });
  const [gdata, setGData] = useState({
    loading: true,
    members: [],
    message: null,
  });

  //Member CurrentPage
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [dependentFields, setDependentFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    postalCode: "",
    addressOne: "",
    addressTwo: "",
  });
  const [errorMsg, setErrorMsg] = useState({
    firstNameErr: false,
    lastNameErr: false,
    emailErr: false,
    phoneErr: false,
    dobErr: false,
    cityErr: false,
    postalCodeErr: false,
    addressOneErr: false,
    addressTwoErr: false,
  });
  const zipCodeRegex = /^[0-9]{5,8}$/;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const nameRegex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
  const addressRegex = /^(?!.*(<|>|{[^}]*}))/;
  const dispatch = useDispatch();

  // let dependentData = props.mdata[props.index];
  let dependentData = props?.index;
  const memberId = props?.location?.pathname?.slice(20);

  if (!dependentData) dependentData = {};

  const action_type =
    props.dependent_action_type === "edit" ? "edit" : "create";

  const button_label =
    props.dependent_action_type === "edit" ? "Submit" : "Create Dependent";

  const modal_title =
    props.dependent_action_type === "edit"
      ? "Update Dependent"
      : "Create Dependent";

  useEffect(() => {
    if (dependentData.dob) {
      setDependentDob(dependentData.dob);
      // setDependentDob(dctDobGlobalFormatForDatePicker(dependentData.dob));
    }
    // let formatDob = dctHumanReadableGlobalFormat(dependentData.dob);
    let formatDob = moment(dependentData.dob, "MM-DD-YYYY");
    let dobAge = moment().diff(formatDob, "years");
    setDependentAge(dobAge);

    if (dependentData.state) {
      setSelectedState(dependentData.state);
    }
    if (dependentData.country) {
      setSelectedCountry(dependentData.country);
    }
    if (dependentData) {
      setDependentFields({
        firstName: dependentData?.firstName,
        lastName: dependentData?.lastName,
        email: dependentData?.email,
        phone: dependentData?.primaryPhone,
        city: dependentData?.city,
        postalCode: dependentData?.postalCode,
        addressOne: dependentData?.address,
        addressTwo: dependentData?.addressTwo,
      });
    }
  }, [dependentData]);

  //API calling for Member details

  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("msearch");
    let searchParam = "";
    if (msearch) {
      searchParam = {
        memberSearchParam: msearch,
        type: "filter",
        key: "fulfilment",
      };
      setSearchFormDetails({
        memberSearchParam: "",
        type: "filter",
        reset: true,
      });
    }
    fetchMemberData(searchParam, currentPage + 1);
  }, [currentPage]);

  // Load the fetched Company data for the select dropdown
  // const loadCompanyOptions = (inputValue, callback) => {
  //   const axiosArgs = {
  //     params: inputValue,
  //     URL:
  //       process.env.REACT_APP_API_PATH +
  //       "/company/names?limit=30&fields=companyName&q=" +
  //       inputValue,
  //   };
  //   dctGetAsyncData(axiosArgs).then((results) => callback(results));
  //   return;
  // };
  // const companyListOptions = debounce(loadCompanyOptions, 250);

  // const handleCompanyChange = (selectedCompany) => {
  //   setSelectedCompany(selectedCompany);
  //   if (!selectedCompany) {
  //     document.querySelector("#select-company").classList.add("text-danger");
  //     return;
  //   }
  //   document.querySelector("#select-company").classList.remove("text-danger");
  // };

  //API for Member Calling

  const fetchMemberData = (param, currentPage = 1) => {
    setAjaxKey(false);
    let searchParam = `&q=${props.searchInput}`;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    let url =
      process.env.REACT_APP_API_PATH +
      `/member?populate=userId,partnerId,companyId,dependent&page=${currentPage}&limit=30` +
      searchParam;
    let cancel;
    axios
      .get(url, headers, {
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
      .then(function (response) {
        if (response.status === 200) {
          setAjaxKey(true);
        }

        const data = response.data;
        let message = "";
        message = data.data.length > 0 ? "" : "No record found.";
        let structured = data.data;
        if (!param || param == "" || data.total > 30) {
          structured = [...gdata.members, ...data.data];
        }
        dispatch(setMemberListing(data.data, null));
        setGData({
          loading: false,
          members: structured,
          message: message,
        });

        setTotalItems(data.total);
        setTotalPages(Math.ceil(data.total / 30));
        setCurrentPage(currentPage - 1);
        if (document.getElementsByClassName("dr-msearch-spinner").length > 0) {
          document
            .getElementsByClassName("dr-msearch-spinner")[0]
            .classList.add("d-none");
        }
      })
      .catch(function (err) {
        if (document.getElementsByClassName("dr-msearch-spinner").length > 0) {
          document
            .getElementsByClassName("dr-msearch-spinner")[0]
            .classList.add("d-none");
        }
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = err.response.data.cause.details;
          }
        }

        setGData({
          loading: false,
          members: null,
          message: message,
        });
        setTotalItems(0);
        setTotalPages(0);
        setCurrentPage(0);
        dispatch(setMemberListing(null, message));
        if (axios.isCancel(err)) return;
      });
    return () => cancel();
    // .then(function () { });
  };

  const handleStateChange = (state) => {
    setSelectedState(state);
  };

  const handleCountryChange = (country) => {
    if (country === "US") {
      setSelectedCountry(country);
      setSelectedState("TX");
    } else {
      setSelectedCountry(country);
      setSelectedState("");
    }
  };

  const handlePhoneChange = (phone) => {
    setDependentFields({ ...dependentFields, phone: "+" + phone });
  };

  function toggleModalClose(e) {
    if (props.singleMember) {
      props.updateMemberDataFlag();
    }
  }

  async function handleValidSubmit(event, values) {
    const trimmedFields = {
      firstName: dependentFields.firstName?.trim(),
      lastName: dependentFields.lastName?.trim(),
      addressOne: dependentFields.addressOne?.trim(),
      addressTwo: dependentFields.addressTwo?.trim(),
      city: dependentFields.city?.trim(),
      postalCode: dependentFields.postalCode?.trim(),
      phone: dependentFields.phone?.trim(),
    };
    setErrorMsg({
      ...errorMsg,
      firstNameErr: false,
      lastNameErr: false,
      emailErr: false,
      phoneErr: false,
      dobErr: false,
      cityErr: false,
      postalCodeErr: false,
      addressOneErr: false,
      addressTwoErr: false,
    });
    if (!nameRegex.test(trimmedFields.firstName)) {
      setErrorMsg({ ...errorMsg, firstNameErr: true });
      return;
    }
    values.firstName = trimmedFields.firstName;
    if (!nameRegex.test(trimmedFields.lastName)) {
      setErrorMsg({ ...errorMsg, lastNameErr: true });
      return;
    }
    values.lastName = trimmedFields.lastName;
    const trimmedPrimaryPhone = trimmedFields.phone;
    if (
      trimmedPrimaryPhone.length < 3 ||
      (trimmedPrimaryPhone.includes("+1") &&
        trimmedPrimaryPhone.length !== 12) ||
      (trimmedPrimaryPhone.includes("+52") && trimmedPrimaryPhone.length !== 13)
    ) {
      setErrorMsg({ ...errorMsg, phoneErr: true });
      document.querySelector("#phone").classList.add("text-danger");
      return;
    }
    document.querySelector("#phone").classList.remove("text-danger");
    values.primaryPhone = `${trimmedPrimaryPhone}`;

    if (dependentAge >= 18 && !emailRegex?.test(dependentFields.email)) {
      setErrorMsg({ ...errorMsg, emailErr: true });
      return;
    }

    values.email = dependentFields.email;
    if (!selectedCountry || selectedCountry === "") {
      document.querySelector("#select-country").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-country").classList.remove("text-danger");
    values.country = selectedCountry;

    if (!selectedState || selectedState === "") {
      document.querySelector("#select-state").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-state").classList.remove("text-danger");
    values.state = selectedState;
    if (!dependentDob) {
      document.querySelector("#date-dob").classList.add("text-danger");

      setErrorMsg({ ...errorMsg, dobErr: true });
      return;
    }
    document.querySelector("#date-dob").classList.remove("text-danger");

    values.dob = dependentDob;
    if (!zipCodeRegex?.test(trimmedFields.postalCode)) {
      setErrorMsg({ ...errorMsg, postalCodeErr: true });
      return;
    }
    values.postalCode = trimmedFields.postalCode;

    if (!addressRegex?.test(trimmedFields.addressOne)) {
      setErrorMsg({ ...errorMsg, addressOneErr: true });
      return;
    }
    values.address = trimmedFields.addressOne;
    if (!addressRegex?.test(trimmedFields.addressTwo)) {
      setErrorMsg({ ...errorMsg, addressTwoErr: true });
      return;
    }
    values.addressTwo = trimmedFields.addressTwo;
    if (!trimmedFields.city || trimmedFields.city.length === 0) {
      setErrorMsg({ ...errorMsg, cityErr: true });
      return;
    }
    values.city = trimmedFields.city;

    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.remove("d-none");
    setMsgEnable(true);
    setLoader(true);
    //call reducer method
    const url = `dependent/${dependentData.id}`;
    var result = await dctUpdateStatus(values, url);
    // dispatch(setSingleMember(values, null));
    fetchMemberData(searchFormDetails);
    if (typeof result === "string") {
      setError(result);
      setLoader(false);
    } else {
      setSweetAlert(true);
      setLoader(false);
    }
    props.fetchSingleMember(memberId);
  }

  const editFormMemberItem = (e) => {
    setMsgEnable(false);
    dispatch(memberActionSuccessful(null, "", ""));
  };

  // const createFormMemberItem = (e) => {
  //   dependentData = {};
  //   setMsgEnable(false);
  //   setPhone("");
  //   setDependentDob("");
  //   setDependentAge(0);
  //   dispatch(memberActionSuccessful(null, "", ""));
  //   dispatch(setSingleMember(null, "", ""));
  // };

  const handleDateChange = (event) => {
    // const selectedDate = event.target.value;
    const rawInput = event.target.value
      .replace(/^(\d\d)(\d)$/g, "$1-$2")
      .replace(/^(\d\d-\d\d)(\d+)$/g, "$1-$2")
      .replace(/[^\d-]/g, "");
    const selectedDate = rawInput.slice(0, 10); // dob must be 10 characters
    setDependentDob(selectedDate);
    if (selectedDate.length === 10) {
      // let formatDob = dctHumanReadableGlobalFormat(selectedDate);
      let formatDob = moment(selectedDate, "MM-DD-YYYY");
      let dobAge = moment().diff(formatDob, "years");
      setDependentAge(dobAge);
      // if (dobAge <= 18) {
      //   document.querySelector("#date-dob").classList.add("text-danger");
      // } else {
      //   document.querySelector("#date-dob").classList.remove("text-danger");
      // }
    }
  };

  return (
    <React.Fragment>
      {(() => {
        switch (props.dependent_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="dependent_update_modal"
                onClick={() => {
                  setModal(!modal);
                  editFormMemberItem();
                }}
              ></i>
            );
          case "edit_btn":
            return (
              <Button
                type="button"
                color="primary"
                id="dependent_update_modal"
                className="waves-effect waves-light mr-2"
                onClick={() => {
                  setModal(!modal);
                  editFormMemberItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1"></i> {props.t("Edit")}
              </Button>
            );
          // case "borderless_edit_btn":
          //   return (
          //       <Button
          //       type="button"
          //       color="primary"
          //       id="dependent_update_modal"
          //       className="btn btn-outline-light btn-light"
          //       onClick={() => {
          //         setModal(!modal);
          //         editFormMemberItem();
          //       }}
          //     >
          //       <i className="mdi mdi-pencil mr-1 text-muted"></i><span className="text-muted">{props.t("Edit")}</span>
          //     </Button>
          //   );
          default:
            return (
              <Link
                onClick={() => {
                  setModal(!modal);
                  editFormMemberItem();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id="dependent_update_modal"
              >
                <i className="mdi mdi-plus mr-1"></i> {props.t("Create Member")}
              </Link>
            );
        }
      })()}

      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-member-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            {error && <Alert color="danger">{error}</Alert>}
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.createMemberError && msgEnable ? (
                <Alert color="danger">{props.createMemberError}</Alert>
              ) : null}
              {props.message && props.message && msgEnable ? (
                <Alert color="success">{props.message}</Alert>
              ) : null}

              {action_type === "edit" ? (
                <AvField
                  name="id"
                  type="hidden"
                  value={
                    dependentData.id !== undefined
                      ? dependentData.id
                      : dependentData._id
                  }
                />
              ) : (
                ""
              )}
              <AvField name="actionType" type="hidden" value={action_type} />

              <Row>
                <Col lg={6}>
                  <div
                    id={`firstName`}
                    className={`form-group ${Config.names.slugName}-firstName-box`}
                  >
                    <Label>
                      {props.t(`First Name`)}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvField
                      name="firstName"
                      className={`form-control ${
                        errorMsg.firstNameErr ? "is-invalid" : ""
                      }`}
                      placeholder={props.t(`First Name`)}
                      type="text"
                      required
                      value={dependentFields.firstName}
                      onChange={(e) =>
                        setDependentFields({
                          ...dependentFields,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div id={`lastName`} className="form-group">
                    <Label>
                      {props.t(`Last Name`)}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvField
                      name="lastName"
                      className={`form-control ${
                        errorMsg.lastNameErr ? "is-invalid" : ""
                      }`}
                      placeholder={props.t(`Last Name`)}
                      type="text"
                      required
                      value={dependentFields.lastName}
                      onChange={(e) =>
                        setDependentFields({
                          ...dependentFields,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                {dependentAge >= 18 && (
                  <Col lg={6}>
                    <div id={`email`} className="form-group">
                      <Label>
                        {props.t(`Email`)}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvField
                        name="email"
                        className={`form-control ${
                          errorMsg.emailErr ? "is-invalid" : ""
                        }`}
                        placeholder={props.t(`Email`)}
                        type="email"
                        required
                        value={dependentFields.email}
                        onChange={(e) =>
                          setDependentFields({
                            ...dependentFields,
                            email: e.target.value,
                          })
                        }
                        maxLength={50}
                        readOnly={false}
                      />
                    </div>
                  </Col>
                )}
                <Col lg={6}>
                  <div
                    className={`form-group ${Config.names.slugName}-phone-box`}
                    id={`phone`}
                  >
                    <Label>
                      {props.t(`Phone`)}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>

                    <PhoneInput
                      inputProps={{
                        name: "phone",
                        required: true,
                        style: {
                          borderColor: errorMsg.phoneErr ? "red" : "",
                        },
                      }}
                      placeholder={""}
                      country={"us"}
                      value={dependentFields?.phone}
                      onChange={handlePhoneChange}
                      enableSearch={true}
                      onlyCountries={["us", "mx"]}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <Label>
                      {props.t(`Gender`)}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvField
                      type="select"
                      name="gender"
                      value={dependentData.gender}
                      required
                    >
                      <option value="unknown">{props.t("Other")}</option>
                      <option value="male">{props.t("Male")}</option>
                      <option value="female">{props.t("Female")}</option>
                    </AvField>
                  </div>
                </Col>

                <Col lg={6}>
                  <div
                    className={`form-member ${Config.names.slugName}-date-box`}
                    id={`date-dob`}
                  >
                    <Label>
                      {props.t(`Date of Birth`)}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    {/* <ReactDatePicker
                      name="dob"
                      className={`form-control ${
                        errorMsg.dobErr ? "is-invalid" : ""
                      }`}
                      selected={dependentDob}
                      dateFormat="MM-dd-yyyy"
                      onChange={handleDateChange}
                      placeholderText="MM-DD-YYYY"
                      showYearDropdown
                      className="form-control"
                      required
                      maxDate={new Date()}
                    /> */}
                    <AvInput
                      required
                      pattern="^(0[1-9]|1[0-2])-(0[1-9]|1\d|2[0-8])-(?!0000)[1-9]\d{3}|(0[13-9]|1[0-2])-(29|30)-(?!0000)[1-9]\d{3}|(0[13578]|1[02])-31-(?!0000)[1-9]\d{3}|02-29-(?!0000)(([1-9]\d)(0[48]|[2468][048]|[13579][26])|([2468][048]|[13579][26])00)$"
                      value={dependentDob}
                      minLength="10"
                      maxLength="10"
                      name="dob"
                      id="dob"
                      list="dates_pattern4_datalist"
                      placeholder="MM-DD-YYYY"
                      onChange={handleDateChange}
                    />
                    {dependentAge < 18 ? (
                      <div className="invalid-feedback">
                        {props.t(`Age should less than 18`)}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className={`form-group ${Config.names.slugName}-select-box`}
                    id="select-country"
                  >
                    <Label>
                      {props.t(`Select Country`)}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <CountryDropdown
                      name="country"
                      classes={`form-control ${
                        selectedCountry.length == 0 ? "is-invalid" : ""
                      }`}
                      labelType="short"
                      valueType="short"
                      whitelist={["US", "MX"]}
                      value={selectedCountry}
                      defaultOptionLabel={"Select Country"}
                      onChange={handleCountryChange}
                      required
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className={`form-group ${Config.names.slugName}-select-box`}
                    id="select-state"
                  >
                    <Label>
                      {props.t(`Select State`)}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <RegionDropdown
                      name="state"
                      country={selectedCountry}
                      countryValueType="short"
                      labelType="full"
                      valueType="short"
                      classes={`form-control ${
                        selectedState.length == 0 ? "is-invalid" : ""
                      }`}
                      value={selectedState}
                      defaultOptionLabel={"Select State"}
                      onChange={handleStateChange}
                      required
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <Label>
                      {props.t(`City`)}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvField
                      name="city"
                      className={`form-control ${
                        errorMsg.cityErr ? "is-invalid" : ""
                      }`}
                      placeholder={props.t(`City`)}
                      type="text"
                      required
                      value={dependentFields.city}
                      onChange={(e) =>
                        setDependentFields({
                          ...dependentFields,
                          city: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <Label>
                      {props.t(`Postal Code`)}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvField
                      name="postalCode"
                      className={`form-control ${
                        errorMsg.postalCodeErr ? "is-invalid" : ""
                      }`}
                      placeholder={props.t(`Postal Code`)}
                      type="text"
                      validate={{
                        required: { value: true },
                        pattern: { value: "^[0-9]{5,8}$" },
                      }}
                      maxLength={8}
                      value={dependentFields.postalCode}
                      onChange={(e) =>
                        setDependentFields({
                          ...dependentFields,
                          postalCode: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col lg={6}></Col>
                <Col lg={12}>
                  <div className="form-group">
                    <Label>
                      {props.t(`Address`)}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvField
                      name="address"
                      className={`form-control ${
                        errorMsg.addressOneErr ? "is-invalid" : ""
                      }`}
                      placeholder={props.t(`Address`)}
                      type="text"
                      required
                      value={dependentFields.addressOne}
                      onChange={(e) =>
                        setDependentFields({
                          ...dependentFields,
                          addressOne: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <AvField
                      name="addressTwo"
                      label={props.t(`Address Two`)}
                      className={`form-control ${
                        errorMsg.addressTwoErr ? "is-invalid" : ""
                      }`}
                      placeholder={props.t(`Address Two`)}
                      type="text"
                      value={dependentFields.addressTwo}
                      onChange={(e) =>
                        setDependentFields({
                          ...dependentFields,
                          addressTwo: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setModal(!modal)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                      onClick={(e) => {
                        // dctCustomValidations("companyId", "select-company");
                        // dctCustomValidations("state", "select-state");
                      }}
                    >
                      <span className="dr-mform-spinner d-none">
                        {loader ? (
                          <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                        ) : (
                          ""
                        )}
                      </span>

                      {button_label}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
          {sweetAlert && (
            <SweetAlert
              title={props.t("Success")}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                props.setModal(false);
              }}
            >
              {props.t("Dependent updated successfully!")}
            </SweetAlert>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createMemberError } = state.Member;
  const { member } = state.Member;
  const { message } = state.Member;
  return { createMemberError, member, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createMember,
    updateMember,
    createMemberFailed,
    setSingleMember,
  })(withNamespaces()(CreateUpdateDependent))
);
