import React, { useEffect, useState } from "react";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Alert,
  CardBody,
  Container,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

// availity-reactstrap-validation
import {
  AvForm,
  // AvField,
  AvInput,
  AvFeedback,
  AvGroup,
} from "availity-reactstrap-validation";

// action
import { userResetPassword } from "../../store/actions";

//Include Common Helpers
import { DctFooterCredit } from "../../helpers/Commons";

// import images
// import profile from "../../assets/images/profile.svg";
// import logo from "../../assets/images/logo.svg";
import { Config } from "../../Config";
import { dctGetEmail } from "../../helpers/backend_helper";

const ResetPasswordPage = (props) => {
  const [isResponse, setIsResponse] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    let path = props.history.location.pathname.split("/");
    path = path[path.length - 1]
    let values = {
      token: path,
    };
    let url = `${process.env.REACT_APP_API_PATH}/auth/get-user-email`;
    //call the API to get user email.
    dctGetEmail(url, values).then((response) => {
      if (response.data.success === true) {
        setIsResponse(true);
        setData(response.data);
      }
    })

  }, [])
  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-login-spinner")[0]
      .classList.remove("d-none");
    props.userResetPassword(values, props.history);
  }

  /* Redirect to Dashboard if user alrady loggedin */
  if (localStorage.getItem("auth_token")) {
    return (
      <Redirect
        to={{ pathname: "/dashboard", state: { from: props.location } }}
      />
    );
  }

  const layout_footer = (slug) => {
    switch (slug) {
      case "doctegrity":
        return (
          <div className={`mt-5 text-center ${Config.names.slugName}-copyright-section-2 text-primary`}>
            <p>
              {props.t(`Go back to`)}{" "}
              <Link to="/login" className="font-weight-medium text-primary">
                {props.t(`Login`)}
              </Link>{" "}
            </p>
            {DctFooterCredit(Config.names.appName)}
          </div>
        );

      case "telaq":
        return (
          <div className={`mt-5 text-center ${Config.names.slugName}-copyright-section-2 text-primary`}>
            <p>
              {props.t(`Go back to`)}{" "}
              <Link to="/login" className="font-weight-medium text-primary">
                {props.t(`Login`)}
              </Link>{" "}
            </p>
            {DctFooterCredit(Config.names.appName)}
          </div>
        );
      case "telemedicine":
        return (
          <div className={`mt-5 text-center ${Config.names.slugName}-copyright-section-2 text-primary`}>
            <p>
              {props.t(`Go back to`)}{" "}
              <Link to="/login" className="font-weight-medium text-primary">
                {props.t(`Login`)}
              </Link>{" "}
            </p>
            {DctFooterCredit(Config.names.appName)}
          </div>
        );
      case "hbaehealth":
        return (
          <div className={`mt-5 text-center ${Config.names.slugName}-copyright-section-2 text-primary`}>
            <p>
              {props.t(`Go back to`)}{" "}
              <Link to="/login" className="font-weight-medium text-primary">
                {props.t(`Login`)}
              </Link>{" "}
            </p>
            {DctFooterCredit(Config.names.appName)}
          </div>
        );



      default:
        return (
          ""
        );
    }

  };

  return (
    <React.Fragment>
      <div
        className={`py-5 bg-black app-${process.env.REACT_APP_SLUG}`}
        style={{ minHeight: "100vh", height: "100%", marginTop: 0 }}
      >
        <div className="account-pages my-5 pt-sm-5">
          <Container className={`${Config.names.slugName}-container-sm`}>
            <Row className="justify-content-center mt-5">
              <Col md={12} lg={12} xl={12} className="d-flex justify-content-center align-items-center">
                <img src={Config.logos.logoDark} alt="" style={{ width: '14rem' }} />
              </Col>
            </Row>
            <div className={`${Config.names.slugName}-box-shadow ${Config.names.slugName}-login-container`}>
              <Row className="justify-content-center mt-5">
                <Col md={8} lg={6} xl={6} className={`${Config.names.slugName}-desktop p-0`}>
                  <img
                    src={Config.images.loginBanner}
                    alt="Banner"
                    className="img-fluid"
                  />
                </Col>
                <Col md={8} lg={6} xl={6}>
                  <div className={`overflow-hidden ${Config.names.slugName}-auth-form`}>
                    {/*<div className={`${Config.names.slugName}-login-logo`}>
                        <img className= {"telaImg"}src={Config.logos.loginLogo} alt="App" height="35" />
                    </div>*/}
                    {window.ReactNativeWebView ? (
                      ""
                    ) : (
                      <>
                        <div className={`${Config.names.slugName}-auth-header ${Config.names.slugName}-bg-black`}>
                          <Row>
                            <Col className="col-12">
                              <div className={`mt-5 ml-4 mt-sm-0 ${Config.names.slugName}-auth-header-wrap`}>
                                <h4 className={`${Config.names.slugName}-auth-header`}>
                                  {props.t("Welcome to")}{" "}
                                  <strong>{props.t(`${Config.names.appName}`)}</strong>
                                </h4>
                                <p className="">
                                  {props.t(`Please create a new password for your ${Config.names.appName} account below.`)}{" "}
                                </p>
                                {isResponse ? <p className="">
                                  {props.t(`Account Email`)}{': '}<strong>{`${data?.user?.email}`}</strong>
                                </p> : null}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                    <CardBody className={`pt-0 ${Config.names.slugName}-bg-black`}>
                      <div className="">
                        <AvForm
                          className={`form-horizontal mt-4 ${Config.names.slugName}-dark-theme`}
                          onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                        >
                          {props.resetError && props.resetError ? (
                            <Alert color="danger">{props.resetError}</Alert>
                          ) : null}
                          {props.resetSuccessMsg ? (
                            <Alert color="success">
                              {props.resetSuccessMsg}
                            </Alert>
                          ) : null}

                          <div className="form-group">
                            <AvGroup>
                              <Label for="password">Enter New Password</Label>
                              <InputGroup className={`${Config.names.slugName}-input-group`}>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    {" "}
                                    <i className="bx bx-lock"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <AvInput
                                  name="password"
                                  type="password"
                                  required
                                />
                                <AvFeedback>This field is required.</AvFeedback>
                              </InputGroup>
                            </AvGroup>
                          </div>
                          <div className="mt-3">
                            <button
                              className="btn btn-primary btn-block w-md waves-effect waves-light"
                              type="submit"
                            >
                              <span className="dr-login-spinner d-none">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                              </span>
                              {props.t(`Create`)}
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </div>
                  {window.ReactNativeWebView ? (
                    ""
                  ) : (
                    <>
                      {process.env.REACT_APP_SLUG === "canelo" ? (
                        <div className={`mt-5 text-center ${Config.names.slugName}-copyright-section-1`}>
                          <p>
                            {props.t(`Go back to`)}{" "}
                            <Link
                              to="/login"
                              className="font-weight-medium text-primary"
                            >
                              {props.t(`Login`)}
                            </Link>{" "}
                          </p>
                          {DctFooterCredit(Config.names.appName)}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
            {layout_footer(process.env.REACT_APP_SLUG)}
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { resetError, resetSuccessMsg } = state.ResetPassword;
  return { resetError, resetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userResetPassword })(
    withNamespaces()(ResetPasswordPage)
  )
);
