export const CREATE_COMPANY = "CREATE_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_LIST = "GET_COMPANY_LIST";
export const SET_COMPANY_LIST = "SET_COMPANY_LIST";
export const SET_COMPANY_LISTING = "SET_COMPANY_LISTING";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILED = "CREATE_COMPANY_FAILED";
export const SET_SINGLE_COMPANY = "SET_SINGLE_COMPANY";
export const API_ERROR = "API_ERROR";
