import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  CardBody,
  Alert,
  Container,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import login from "../../store/auth/login/reducer";
import { Config } from "../../Config";
import axios from "axios";
import AlertMessage from "./Components/Alerts/AlertMessage";
import { phoneFormatter } from "./service/phoneFormatter";
// Redux
import { connect } from "react-redux";
import { withRouter, Link, Redirect, useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { loginUser, apiError } from "../../store/actions";

// import CryptoJS from "crypto-js";

// import ConfigDefaults from "../Config/ConfigDefaults";
// import { useState } from "react";
// import NonAuthLayout from "../components/Layout/NonAuthLayout";
// import Header from "../components/Layout/Header";
// import Link from "next/link";
// import AlertMessage from "../components/Alerts/AlertMessage";
// import { animate, motion } from "framer-motion";
// import Loader from "../components/loader/Loader";

// Redux Tool-Kit
// import { useSelector, useDispatch } from "react-redux";
// import { auth_user } from "../redux/auth/authDataReducer";

const ForgetPassword = () => {
  const router = useHistory();
  const [email, setEmail] = useState({
    value: "",
    err: false,
  });
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email.value) {
      setEmail({ ...email, err: true });
      return;
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)
    ) {
      setEmail({ ...email, err: true });
      return;
    }
    // else if (!captchaVerified) {
    //     setCaptchaErr(true);
    //     return;
    // }

    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_PATH + "/auth/forgot-password", {
        email: email.value,
      })
      .then((res) => {
        if (res.status === 200 && res.data.success === true) {
          setLoading(false);
          setAlertData({
            ...alertData,
            type: "success",
            title: "Success",
            message: "We have emailed your password reset link.",
          });
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            router.push("/login");
          }, 15000);
        } else if (res.status === 200 && res.data.success === false) {
          setLoading(false);
          setAlertData({
            ...alertData,
            type: "error",
            title: "Reset Failed",
            message: `The email you provided does not match our records. Please try again, or contact support at ${phoneFormatter(
              `${Config.names.supportContact}`,
              1
            )}`,
          });
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 10000);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 500) {
          console.log("Status Code 500: ", err.response.data);
          setAlertData({
            ...alertData,
            type: "error",
            title: "Reset Failed",
            message: `The email you provided does not match our records. Please try again, or contact support at ${phoneFormatter(
              `${Config.names.supportContact}`,
              1
            )}`,
          });
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 10000);
        }
        if (
          err.response.status === 400 &&
          err.response.data.success === false
        ) {
          setAlertData({
            ...alertData,
            type: "error",
            title: "Reset Failed",
            message: `The email you provided does not match our records. Please try again, or contact support at ${phoneFormatter(
              `${Config.names.supportContact}`,
              1
            )}`,
          });
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 10000);
        }
      });
  };

  return (
    <>
      <React.Fragment>
        <div
          className={`py-5 bg-black app-${process.env.REACT_APP_SLUG} global-gradient`}
          style={{ minHeight: "100vh", height: "100%", marginTop: 0 }}
        >
          <div className="account-pages my-5 pt-sm-2">
            <Container className={`${Config.names.slugName}-container-sm`}>
              <Row className="justify-content-center mt-5">
                <Col
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img
                    src={Config.logos.logoDark}
                    alt=""
                    style={{ width: "14rem" }}
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center">
                <Col lg="5" md="6" sm="8" xs="10" className="p-0">
                  <AlertMessage
                    login={false}
                    show={showAlert}
                    type={alertData.type}
                    title={alertData.title}
                    message={alertData.message}
                    forgot={true}
                  />
                </Col>
              </Row>

              <Row className="d-flex flex-column justify-content-center align-items-center">
                <Col lg="5" md="6" sm="8" xs="10" className="mt-5">
                  <i className="fa fa-arrow-left font-size-14 text-secondary"></i>
                  <Link
                    to="/login"
                    className="text-secondary font-size-14 font-weight-light ml-2"
                  >
                    Go back
                  </Link>
                </Col>
                <Col
                  lg="5"
                  md="6"
                  sm="8"
                  xs="10"
                  className={`${Config.names.slugName}-box-shadow rounded-lg mt-3`}
                >
                  <div className="py-5 px-3">
                    <h5 className="text-primary font-weight-bold">
                      FORGOT YOUR PASSWORD?
                    </h5>
                    <p className="font-size-15 font-weight-light mt-3">
                      Enter the email address associated with your account and
                      click{" "}
                      <span className="text-primary font-weight-normal">
                        “Reset Password”
                      </span>
                    </p>

                    <Form className="mt-4" onSubmit={(e) => handleSubmit(e)}>
                      <input
                        type="email"
                        value={email.value}
                        onChange={(e) => {
                          setEmail({
                            ...email,
                            value: e.target.value,
                            err: false,
                          });
                        }}
                        className={`form-control mb-4 ${
                          email.err && "border-danger"
                        }`}
                        placeholder="Email"
                      />

                      <button
                        type="submit"
                        className="btn btn-primary mt-3 w-100 btn-shadow"
                      >
                        {loading ? (
                          <>
                            <span className="dr-mform-spinner">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>
                            </span>
                          </>
                        ) : (
                          "Reset Password"
                        )}
                      </button>
                    </Form>
                  </div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center mt-4">
                <Link
                  className="text-primary font-weight-bold font-size-13"
                  style={{ wordSpacing: "2px" }}
                  to="/login"
                >
                  Already a Member? Click Here to Sign In
                </Link>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default ForgetPassword;
