import React, { PropsWithChildren } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import enhanceMessage from "./enhanceMessage";
import { TwilioError } from "twilio-video";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | null;
}

function ErrorDialog({
  dismissError,
  error,
}: PropsWithChildren<ErrorDialogProps>) {
  const history = useHistory();
  const { message, code } = error || {};
  const enhancedMessage = enhanceMessage(message, code);
  const [path, setPath] = useState("");

  console.log(enhancedMessage);

  useEffect(() => {
    console.log("path --", history.location.pathname);
    setPath(history.location.pathname);
  }, []);

  return (
    <Dialog
      open={error !== null}
      onClose={() => dismissError()}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle>Sorry</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {enhancedMessage === "token must be a string"
            ? "Invalid credentials"
            : enhancedMessage}
        </DialogContentText>
        {Boolean(code) && (
          <pre>
            <code>Error Code: {code}</code>
          </pre>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            localStorage.setItem("redirect_to_url", JSON.stringify(path));
            history.push("/login");
            dismissError();
          }}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
