import React, { Fragment, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  Button,
  Col,
  Label,
  Row,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { dctAlert, dctClsAddRemove } from "../../../helpers/Commons";
import {
  dctGetDosespotItems,
  dctPutDosespotData,
} from "../../../helpers/backend_helper";
// import SweetAlert from "react-bootstrap-sweetalert";
import { SweetAlert } from "../../DoctegritySweetAlert/DoctegritySweetAlert";
import { Config } from "../../../Config";

// import { getPersonalInfo } from "../../../store/actions";

function PersonalInformation(props) {
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState(false);

  const [isPDFDownload, setIsPDFDownload] = useState(false);
  const [pdfLinkForDownload, setPdfLinkForDownload] = useState(false);
  const [pdfDownloadMsg, setPdfDownloadMsg] = useState("");
  const [pdfDownloadTitle, setPdfDownloadTitle] = useState("");
  const [showPDFconfrBtn, setShowPDFconfrBtn] = useState(false);

  const dispatch = useDispatch();
  const { authMemberId, auth_token } = useSelector((state) => state.Login);
  const { personalInfo } = useSelector((state) => state.HealthRecords);

  const [openPersonalInfo, setOpenPersonalInfo] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [_singlePersonalInfo, _setSinglePersonalInfo] = useState([]);
  const [bloodType, setBloodType] = useState([
    ``,
    `Unknown`,
    `A`,
    `B`,
    `AB`,
    `O`,
  ]);

  const [maritalStatus, setMaritalStatus] = useState([
    ``,
    `single`,
    `married`,
    `divorced`,
  ]);
  const [selectSmoke, setSelectSmoke] = useState([
    ``,
    `No`,
    `1 to 5 cigarettes daily`,
    `6 to 10 cigarettes daily`,
    `over 10 cigarettes daily`,
  ]);

  const [selectDrink, setSelectDrink] = useState([
    ``,
    `No`,
    `Rarely`,
    `once a month`,
    `Twice a month`,
    `Once a week`,
    `Twice a week`,
    `Three times a week`,
    `Daily`,
  ]);

  const [selectExercise, setSelectExercise] = useState([
    ``,
    `No`,
    `Rarely`,
    `once a month`,
    `Twice a month`,
    `Once a week`,
    `Twice a week`,
    `Three times a week`,
    `Daily`,
  ]);

  const [selectExerciseTime, setSelectExerciseTime] = useState([
    ``,
    `10 min`,
    `20 min`,
    `30 min`,
    `40 min`,
    `50 min`,
    `60 min`,
    `Over 1 hour`,
  ]);

  const pathId = props.pathId;

  useEffect(() => {
    if (props.location.hash === "#personalinfo") {
      setOpenPersonalInfo(true);
    }
    getPersonalInfoById();
  }, []);

  const getPersonalInfoById = async () => {
    let url = `member/${pathId}?populate=dependent&medication&medicationAllergy&medicalHistory&document&userId&companyId`;
    if (props.patientType === "dependent") {
      url = `dependent/${props.activePatientId}?populate=medication&medicationAllergy&medicalHistory&document`;
    }
    const response = await dctGetDosespotItems(url, "").then((data) => data);

    let responseData = response;
    if (responseData && responseData.id) {
      _setSinglePersonalInfo({
        data: responseData,
        loading: false,
      });
    } else {
      _setSinglePersonalInfo({
        data: null,
        loading: false,
      });
    }
  };

  const downloadMyHealthRecords = async (patiendId, patientTYpe) => {
    if (!patiendId) return;

    setPdfLinkForDownload(false);
    setShowPDFconfrBtn(false);
    setPdfDownloadTitle("Preparing PDF");
    setPdfDownloadMsg("Your PDF is preparing for download. Please wait!");

    let url = `member/${pathId}/ehr-pdf`;

    const response = await dctGetDosespotItems(url, "").then((data) => data);
    if (response && response.success === true) {
      setPdfLinkForDownload(response.accessUrl);
      setPdfDownloadTitle("Your PDF is ready");
      setPdfDownloadMsg("Your PDF is ready for Download.");
      setShowPDFconfrBtn(true);
    } else {
      setPdfDownloadTitle("Error!");
      setPdfDownloadMsg("Something went wrong.");
      setShowPDFconfrBtn(true);
    }
  };

  const handlePersonalInfoSubmit = async (event, values) => {
    setFormErrorMsg(false);

    values.bloodPressure =
      values.bloodPressureDiastolic + values.bloodPressureSystolic;
    values.height = parseInt(values.height);
    values.heightMetric = values.heightMetric
      ? parseInt(values.heightMetric)
      : 1;
    values.weightMetric = values.weightMetric
      ? parseInt(values.weightMetric)
      : 1;

    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    dctClsAddRemove("dr-search-spinner", "d-none", "remove");
    let response = "";

    let url = `${process.env.REACT_APP_API_PATH}/member/${pathId}`;
    if (props.patientType === "dependent") {
      url = `${process.env.REACT_APP_API_PATH}/dependent/${props.activePatientId}`;
    }
    response = await dctPutDosespotData(url, values, headers).then(
      (data) => data
    );

    if (response.id) {
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      let message = props.t(`Personal Info updated succuessfully.`);
      setSuccessMsg(message);
      setFormErrorMsg("");
      _setSinglePersonalInfo({
        data: response,
        loading: false,
      });
      setOpenEditForm(!openEditForm);
    } else {
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      setFormErrorMsg(
        response && response.data
          ? response.data.message
          : "Something went wrong. Please try again."
      );
      setSuccessMsg("");
    }
  };

  let heightMetric = "CM";
  if (_singlePersonalInfo.data) {
    heightMetric = _singlePersonalInfo.data.heightMetric === 1 ? "INCH" : "CM";
  }
  let weightMetric = "KG";
  if (_singlePersonalInfo.data) {
    weightMetric = _singlePersonalInfo.data.weightMetric === 1 ? "LB" : "KG";
  }
  return (
    <Fragment>
      {/* <Row>
        <Col xl={12}>
          <div className="justify-content-between mb-3 text-right">
            <Button
              color="secondary"
              className="font-size-13 mr-2"
              size="sm"
              title={props.t(`Download PDF Version`)}
              onClick={() => {
                setIsPDFDownload(true);
                downloadMyHealthRecords(
                  props.activePatientId,
                  props.patientType
                );
              }}
            >
              <i className="mdi mdi-cloud-download font-size-15 mr-1" />
              {props.t(`Download`)}
            </Button>
          </div>
        </Col>
      </Row> */}
      {isPDFDownload ? (
        <SweetAlert
          title={props.t(`${pdfDownloadTitle}`)}
          showConfirm={
            window.ReactNativeWebView && showPDFconfrBtn ? true : false
          }
          showCancel={showPDFconfrBtn ? true : false}
          confirmBtnText="Download"
          cancelBtnBsStyle="secondary"
          onConfirm={() => {
            setIsPDFDownload(false);
            window.ReactNativeWebView.postMessage("PDF:" + pdfLinkForDownload);
          }}
          onCancel={() => {
            setIsPDFDownload(false);
          }}
        >
          <p className="mb-2">{props.t(`${pdfDownloadMsg}`)}</p>
          {pdfLinkForDownload ? (
            <p>
              <a
                className="btn btn-success text-left waves-light"
                href={pdfLinkForDownload}
                target="_blank"
              >
                {props.t(`Download PDF`)}
                <i className="mdi mdi-file-pdf-box font-size-18 align-middle ml-2"></i>{" "}
              </a>
            </p>
          ) : (
            <div>
              <i className="bx bx-loader bx-spin text-success align-middle font-size-20"></i>
            </div>
          )}
        </SweetAlert>
      ) : null}
      {/* <Card className="mb-0">
        <CardHeader
          className="p-3 bg-white cursor-pointer"
          onClick={() => {
            setOpenPersonalInfo(!openPersonalInfo);
          }}
        >
          <div className="float-right ml-2 dropdown show">
            <i aria-haspopup="true" className="text-muted" aria-expanded="true">
              <i className="mdi mdi-square-edit-outline font-size-18"></i>
            </i>
          </div>

          <div className="d-flex align-items-center">
            <div className={`avatar-xs mr-3 ${Config.names.slugName}-desktop`}>
              <span className="avatar-title text-white rounded-circle font-size-18">
                <i className="mdi mdi-account"></i>
              </span>
            </div>
            <div className="head">
              <h6 className="mb-1 font-14 font-weight-bold text-uppercase">
                {props.t(`Personal Information`)}
              </h6>
              <Label className="mb-0 font-size-14 font-weight-normal">
                {props.t(`Update Your Personal Information(e.g. height, weight, blood type, etc.)`)}
              </Label>
            </div>
          </div>
        </CardHeader>
      </Card> */}

      {/* <Collapse isOpen={openPersonalInfo}> */}
      {/* <Card> */}
      <CardBody className="mb-0">
        {errorMsg ? dctAlert(3, errorMsg) : ""}
        {successMsg ? dctAlert(1, successMsg) : ""}
        {/* {_singlePersonalInfo.data?.height ? ( */}
          <Fragment>
            <Row>
              <Col lg={12} className="mb-3">
                <Row>
                  <Col md={6}>
                    <p className="font-size-13 font-weight-bold">
                      {props.t(`Height`)} :{" "}
                      <span className="font-weight-normal">
                        {_singlePersonalInfo.data?.height} ( {heightMetric} )
                      </span>
                    </p>
                    <p className="font-size-13 font-weight-bold">
                      {props.t(`Weight`)}:{" "}
                      <span className="font-weight-normal">
                        {_singlePersonalInfo.data?.weight} ( {weightMetric} )
                      </span>
                    </p>
                    <p className="font-size-13 font-weight-bold">
                      {props.t(`Blood Type`)}:{" "}
                      <span className="font-weight-normal">
                        {_singlePersonalInfo.data?.bloodType}
                      </span>
                    </p>
                    <p className="font-size-13 font-weight-bold">
                      {props.t(`Blood Pressure`)}:{" "}
                      <span className="font-weight-normal">
                        {_singlePersonalInfo.data?.bloodPressureDiastolic} /{" "}
                        {_singlePersonalInfo.data?.bloodPressureSystolic}
                      </span>
                    </p>
                    <p className="font-size-13 font-weight-bold">
                      {props.t(`Marital Status`)}:{" "}
                      <span className="font-weight-normal">
                        {_singlePersonalInfo.data?.maritalStatus}
                      </span>
                    </p>
                  </Col>
                  <Col md={6} className="mt-3">
                    <p className="font-size-13 font-weight-bold">
                      {props.t(`Smoke`)}:{" "}
                      <span className="font-weight-normal">
                        {_singlePersonalInfo.data?.smoke}
                      </span>
                    </p>
                    <p className="font-size-13 font-weight-bold">
                      {props.t(`Drink`)}:{" "}
                      <span className="font-weight-normal">
                        {_singlePersonalInfo.data?.drink}
                      </span>
                    </p>
                    <p className="font-size-13 font-weight-bold">
                      {props.t(`Exercise`)}:{" "}
                      <span className="font-weight-normal">
                        {_singlePersonalInfo.data?.exercise}
                      </span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Fragment>
        {/* ) : (
          <span className="d-flex justify-content-center text-danger">
            No records found
          </span>
        )} */}

        {/* <div>
              <Button
                color="primary"
                onClick={() => {
                  setOpenEditForm(!openEditForm);
                }}
                style={{ marginBottom: "1rem" }}
              >
                {props.t(`Edit Personal Information`)}
              </Button>

              <Collapse isOpen={openEditForm}>
                <Card
                  style={{
                    backgroundColor: "#e6e3e3",
                    borderColor: "#e6e3e3",
                  }}
                >
                  <CardBody>
                    <div>
                      <h4>{props.t(`Edit Personal Information`)}</h4>
                      <AvForm
                        className="form-horizontal needs-validation"
                        onValidSubmit={(e, v) => {
                          handlePersonalInfoSubmit(e, v);
                        }}
                      >
                        <AvField
                          name="memberId"
                          type="hidden"
                          value={pathId}
                        />
                        {props.patientType === "dependent" ? (
                          <AvField
                            name="dependentId"
                            type="hidden"
                            value={props.activePatientId}
                          />
                        ) : null}
                        <AvField
                          name="patientType"
                          type="hidden"
                          value={props.patientType}
                        />
                        <AvField name="action" type="hidden" value="edit" />

                        <Row className="d-flex">
                          <Col md={6}>
                            <Label htmlFor="validationCustom03">
                              {props.t(`Height`)}
                            </Label>
                            <Row>
                              <Col sm={6}>
                                <AvField
                                  name="height"
                                  placeholder={props.t(`Height`)}
                                  type="number"
                                  className="form-control"
                                  value={_singlePersonalInfo.data?.height}
                                ></AvField>
                              </Col>
                              <Col sm={6}>
                                <AvField
                                  name="heightMetric"
                                  type="select"
                                  errormessage={props.t(`Select Metric`)}
                                  className={`${Config.names.slugName}-custom-select-box`}
                                  value={
                                    _singlePersonalInfo.data?.heightMetric 
                                    ? _singlePersonalInfo.data.heightMetric 
                                    : 1
                                  }
                                >
                                  <option value="1">INCH</option>
                                  <option value="2">CM</option>
                                </AvField>
                              </Col>
                            </Row>

                            <div>
                              <Label htmlFor="validationCustom03">
                                {props.t(`weight`)}
                              </Label>
                              <Row>
                                <Col sm={6}>
                                  <AvField
                                    name="weight"
                                    placeholder={props.t(`Weight`)}
                                    type="number"
                                    className="form-control"
                                    value={_singlePersonalInfo.data?.weight}
                                  ></AvField>
                                </Col>
                                <Col sm={6}>
                                  <AvField
                                    name="weightMetric"
                                    type="select"
                                    errormessage={props.t(`Select Metric`)}
                                    className={`${Config.names.slugName}-custom-select-box`}
                                    value={
                                      _singlePersonalInfo.data?.weightMetric
                                      ? _singlePersonalInfo.data.weightMetric
                                      : 1
                                    }
                                  >
                                    <option value="1">LB</option>
                                    <option value="2">KG</option>
                                  </AvField>
                                </Col>
                              </Row>
                            </div>

                            <div>
                              <Label htmlFor="validationCustom03">
                                {props.t(`Blood Type`)}
                              </Label>
                              <AvField
                                name="bloodType"
                                type="select"
                                errormessage={props.t(`Enter your Blood type`)}
                                className={`${Config.names.slugName}-custom-select-box`}
                                validate={{ required: { value: true } }}
                                id="validationCustom03"
                                value={_singlePersonalInfo.data?.bloodType}
                              >
                                {bloodType.map((value, key) => (
                                  <option key={key} value={value || ""}>
                                    {value}
                                  </option>
                                ))}
                              </AvField>
                            </div>

                            <div>
                              <Label
                                className="mt-3 ml-2"
                                htmlFor="validationCustom04"
                              >
                                {props.t(`Blood Pressure`)} - (SYS/DIA)
                              </Label>
                              <Row>
                                <Col sm={5}>
                                  <AvField
                                    name="bloodPressureSystolic"
                                    placeholder="SYS"
                                    type="number"
                                    errormessage={props.t(
                                      `Enter your Blood pressure`
                                    )}
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom04"
                                    value={
                                      _singlePersonalInfo.data
                                        ?.bloodPressureSystolic
                                    }
                                  />
                                </Col>
                                <span className="font-size-20">/</span>
                                <Col sm={5}>
                                  <AvField
                                    name="bloodPressureDiastolic"
                                    placeholder="DIA"
                                    type="number"
                                    errormessage={props.t(
                                      `Enter your Blood pressure`
                                    )}
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="validationCustom04"
                                    value={
                                      _singlePersonalInfo.data
                                        ?.bloodPressureDiastolic
                                    }
                                  />
                                </Col>
                              </Row>
                            </div>
                            <Row>
                              <Col md={12}>
                                <Label htmlFor="validationCustom06">
                                  {props.t(`Marital Status`)}
                                </Label>
                                <AvField
                                  name="maritalStatus"
                                  type="select"
                                  className={`${Config.names.slugName}-custom-select-box`}
                                  value={
                                    _singlePersonalInfo.data?.maritalStatus
                                  }
                                >
                                  {maritalStatus.map((value, key) => (
                                    <option key={key} value={value || ""}>
                                      {value}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={6}>
                            <Label htmlFor="validationCustom07">
                              {props.t(`Do you smoke?`)}
                            </Label>
                            <Row>
                              <Col md={12}>
                                <AvField
                                  name="smoke"
                                  type="select"
                                  className={`${Config.names.slugName}-custom-select-box`}
                                  value={_singlePersonalInfo.data?.smoke}
                                >
                                  {selectSmoke.map((value, key) => (
                                    <option key={key} value={value || ""}>
                                      {value}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Label htmlFor="validationCustom08">
                                  {props.t(`Do you drink?`)}
                                </Label>
                                <AvField
                                  name="drink"
                                  type="select"
                                  className={`${Config.names.slugName}-custom-select-box`}
                                  value={_singlePersonalInfo.data?.drink}
                                >
                                  {selectDrink.map((value, key) => (
                                    <option key={key} value={value || ""}>
                                      {value}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={12}>
                                <Label htmlFor="validationCustom09">
                                  {props.t(`Do you exercise?`)}
                                </Label>
                                <AvField
                                  name="exercise"
                                  type="select"
                                  className={`${Config.names.slugName}-custom-select-box`}
                                  value={_singlePersonalInfo.data?.exercise}
                                >
                                  {selectExercise.map((value, key) => (
                                    <option key={key} value={value || ""}>
                                      {value}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            </Row>
                           
                          </Col>
                        </Row>
                        {formErrorMsg ? dctAlert(3, formErrorMsg) : ""}
                        <div className="form-group text-right">
                          <button
                            type="button"
                            className="btn btn-secondary mr-2"
                            onClick={() => setOpenEditForm(!openEditForm)}
                          >
                            {props.t("Cancel")}
                          </button>

                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => {}}
                          >
                            <span className="dr-search-spinner d-none">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                            </span>{" "}
                            {props.t(`Save`)}
                          </Button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Collapse>
            </div> */}
      </CardBody>
      {/* </Card> */}
      {/* </Collapse> */}
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(PersonalInformation))
);
