import { CREATE_COMPANY, UPDATE_COMPANY, GET_COMPANY, GET_COMPANY_LIST, SET_COMPANY_LIST, CREATE_COMPANY_SUCCESS, CREATE_COMPANY_FAILED, API_ERROR, SET_SINGLE_COMPANY, SET_COMPANY_LISTING } from './actionTypes';

export const createCompany = (company) => ({
    type: CREATE_COMPANY,
    payload: { company }
})

export const updateCompany = (company) => ({
    type: UPDATE_COMPANY,
    payload: { company }
})

export const getCompany = (company) => ({
    type: GET_COMPANY,
    payload: { company }
})

export const getCompanyList = (company) => ({
    type: GET_COMPANY_LIST,
    payload: { company }
})

export const setCompanyList = (company, message, action) => {
    return {
        type: SET_COMPANY_LIST,
        payload: company,
        message: message,
        action_type: action
    }
}


export const setSingleCompany = (company, message, action) => {
    return {
        type: SET_SINGLE_COMPANY,
        payload: company,
        message: message,
        action_type: action
    }
}

export const setCompanyListing = (company, message) => {
    return {
        type: SET_COMPANY_LISTING,
        payload: company,
        companyListing: company,
        message: message,
    }
}

export const companyActionSuccessful = (company, message, action) => {
    return {
        type: CREATE_COMPANY_SUCCESS,
        payload: company,
        message: message,
        action_type: action
    }
}

export const createCompanyFailed = (error) => {
    return {
        type: CREATE_COMPANY_FAILED,
        payload: error
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}