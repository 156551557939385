import React, { useState, useEffect, Fragment } from "react";
// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Row, Col, Label, Alert } from "reactstrap";

import Tabs from "react-responsive-tabs";
import "react-responsive-tabs/styles.css";

import {
  getMemberAndDependents,
  setMemberAndDependents,
} from "../../../store/actions";
import DependentForm from "./DependentForm";
import { loadingMessage, capitalizeWords } from "../../../helpers/Commons";
import { Config } from "../../../Config";

const DependentsInfo = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [activeDepTab, setActiveDepTab] = useState(1);
  const [isActiveButton, setIsActiveButton] = useState(false);

  let memberObj = [];
  // let member = [];
  let dependents = [];
  const dispatch = useDispatch();
  const { authMemberId } = useSelector((state) => state.Login);
  useEffect(() => {
    props.getMemberAndDependents(authMemberId);

    let tabId = "";
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      tabId = typeof split_url[5] !== "undefined" ? split_url[5] : 1;
      setActiveTab(tabId);
    }
  }, [activeTab]);

  const addDependent = (data) => {
    if (!data || !memberObj) return;

    memberObj.memberAndDependents.dependent.push({
      actionType: "create",
      id: "",
      firstName: "New ",
      lastName: "Dependent",
      gender: "unknown",
      dob: "",
      primaryPhone: memberObj.memberAndDependents
        ? memberObj.memberAndDependents.primaryPhone
        : "",
      relation: "child",
      address: memberObj.memberAndDependents
        ? memberObj.memberAndDependents.address
        : "",
      addressTwo: memberObj.memberAndDependents
        ? memberObj.memberAndDependents.addressTwo
        : "",
      city: memberObj.memberAndDependents
        ? memberObj.memberAndDependents.city
        : "",
      state: memberObj.memberAndDependents
        ? memberObj.memberAndDependents.state
        : "",
      country: memberObj.memberAndDependents
        ? memberObj.memberAndDependents.country
        : "",
      postalCode: memberObj.memberAndDependents
        ? memberObj.memberAndDependents.postalCode
        : "",
      status: "active",
      timezone: memberObj.memberAndDependents
        ? memberObj.memberAndDependents.timezone
        : "PST",
    });
    dispatch(
      setMemberAndDependents(
        memberObj.memberAndDependents,
        "Creating Dependent Record. Please enter the details to create the Dependent.",
        ""
      )
    );
    setActiveDepTab(memberObj.memberAndDependents.dependent.length);
  };

  memberObj = useSelector((state) => state.Member);
  const {
    // loading,
    depMessage,
    memberAndDependents,
    dependentError,
  } = memberObj;
  // member = memberAndDependents;
  dependents = memberAndDependents ? memberAndDependents.dependent : [];

  useEffect(() => {
      if(dependents.length >= 7) {
        setIsActiveButton(true);
      }
  }, [dependents]);

  function getDependentsTabs(dependents) {
    return dependents.map((dependent, index) => ({
      title: `${capitalizeWords(dependent.firstName)} ${capitalizeWords(dependent.lastName)}`,
      getContent: () => (
        <DependentForm {...props} memberObj={memberObj} tabIndex={index + 1} activeIndex={index} setActiveDepTab={setActiveDepTab}/>
      ),
      key: index + 1,
      showMore: true,
      tabClassName: "tab btn btn-outline-primary",
      panelClassName: "panel pl-2 pr-2",
    }));
  }

  const tabChange = (tabId) => {
    if (tabId) {
      props.history.push(`/member/my-account/dependents/id/${tabId}`);
    }
  };

  const stopNativeLoader = ()=>{
    window.ReactNativeWebView.postMessage("STOP_NATIVE_LOADER")
  }
  return (
    <Fragment>
      {window.ReactNativeWebView ? stopNativeLoader():""}
      <Row>
        <Col xl={12}>
          <div className="tab-section">
            {depMessage && dependents ? (
              <Alert color="success">{depMessage}</Alert>
            ) : null}
            {depMessage && !dependents ? (
              <Alert color="danger">{depMessage}</Alert>
            ) : null}

            {dependentError ? (
              <Alert color="danger">{dependentError}</Alert>
            ) : null}

            <Label className="font-size-18">
              {props.t("Manage Dependents")}
            </Label>
            <p>
              {props.t(`Here you can add and edit your dependent information.`)}
              {""}
            </p>
            <p>
              {dependents[dependents.length - 1]?.actionType !== "create" ? <button
                disabled={isActiveButton}
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  addDependent(dependents);
                }}
              >
                <i className="mdi mdi-plus mr-1"></i> {props.t(`Add Dependent`)}
              </button> : ""}
            </p>
            <p>
              {props.t(
                `You may add up to 7 dependents. Your spouse and any dependents over 18 years of age will receive a registration email and must register to use the system. Your spouse will have access to all of your dependents records who are under the age of 18 but will not have access to your records or any dependents records who are over the age of 18.`
              )}{" "}
            </p>
            <p></p>
            <Label className="font-size-18">
              {props.t("Viewing records for")}
            </Label>
            <p className="mb-3">
              {props.t(
                `* Dependent is over 18 and must manage their own records.`
              )}{" "}
            </p>
            {dependents ? (
              <div className={`${Config.names.slugName}-tab-container`}>
                <Tabs
                  items={getDependentsTabs(dependents)}
                  selectedTabKey={activeDepTab}
                  onChange={tabChange}
                />
              </div>
            ) : (
              <div>{loadingMessage("Loading...")}</div>
            )}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { memberAndDependents } = state.Member;
  return { memberAndDependents };
};

export default withRouter(
  connect(mapStatetoProps, {
    getMemberAndDependents,
    setMemberAndDependents,
  })(withNamespaces()(DependentsInfo))
);
