import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { setSingleDoctor, setDoctorListing } from "../../../store/actions";

import { Row, Col, UncontrolledTooltip } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Include Both Helper File with needed methods
import { dctUpdateStatus } from "../../../helpers/backend_helper";
import { loadingMessage, capitalizeWords } from "../../../helpers/Commons";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import axios from "axios";
import { BeatLoader } from "react-spinners";
import { Config } from "../../../Config";

const DoctorList = (props) => {
  const {
    currentPage,
    perPage,
    totalPages,
    totalItems,
    getDataOnEnter,
    ajaxLoader,
    editmode,

  } = props;

  let doctorsObj = [];
  doctorsObj = useSelector((state) => state.Doctor.doctorListing);
  doctorsObj = doctorsObj ? doctorsObj : [];
  const dispatch = useDispatch();

  const [customPageNo, setCustomPageNo] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);

  const [confirm_both, setconfirm_both] = useState(false);
  const [confirm_joinHaze, setconfirm_joinHaze] = useState(false);
  const [success_joinHAze, setsuccess_joinHAze] = useState(false)


  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [doctorStatusMeta, setDoctorStatusMeta] = useState({
    rowIndex: "",
    doctorId: "",
    doctorStatus: 0,
  });
  const [doctorJoinHaze, setDoctorJoinHaze] = useState({
    index: "",
    doctorId: "",
    doctorStatus: 0,
  });

  useEffect(() => {
    // sleep()
  }, []);

  const openDoctorEditModal = (data) => {
    document.querySelector("#doctor_update_modal").click();
    //Push Single Doctor Data to store
    dispatch(setSingleDoctor(data, "", ""));
  };

  const syncDoctor = (data, index) => {
    const { doctorId, doctorStatus, rowIndex } = doctorStatusMeta;

    const loaderCls = ["bx-loader", "bx-spin", "font-size-18"];
    let toogleUserCls = "bx-sync";
    //   document
    //   .querySelector("#sync" + doctorId)
    //   .children[0].classList.add(...loaderCls);
    // document
    //   .querySelector("#sync" + doctorId)
    //   .children[0].classList.remove(toogleUserCls);

    axios
      .patch(
        `${process.env.REACT_APP_API_PATH}/doctor/${data}/dosespot-clinician-id-sync`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("auth_token"),
            PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
          },
        }
      )
      .then(function (response) {
        // setUiPath(response.data.uiPath);

        doctorsObj[rowIndex].status = response.status;
        // dispatch(setDoctorListing(null));
        dispatch(setDoctorListing(doctorsObj, null));
        setStatusLoader(false);
        toogleUserCls =
          response.status === "active" ? "bx-user-check" : "bx-user-x";
        // document
        //   .querySelector("#sync_" + doctorId)
        //   .children[0].classList.remove(...loaderCls);
        // document
        //   .querySelector("#sync_" + doctorId)
        //   .children[0].classList.add(toogleUserCls);
      })
      .catch(function (err) {});
  };

  const toggleDoctorStatus = async (doctorStatusMeta) => {
    if (!doctorStatusMeta) return;

    const { doctorId, doctorStatus, rowIndex } = doctorStatusMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Activation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    const loaderCls = ["bx-loader", "bx-spin", "font-size-18"];
    let toogleUserCls = doctorStatus === 1 ? "bx-user-check" : "bx-user-x";
    setStatusLoader(true);

    // Adding the bx-loader and removing the box-user-check class
    document
      .querySelector("#status_" + doctorId)
      .children[0].classList.add(...loaderCls);
    document
      .querySelector("#status_" + doctorId)
      .children[0].classList.remove(toogleUserCls);

    let gStatus = doctorStatus === "active" ? "inactive" : "active";
    const postData = {
      id: doctorId,
      status: gStatus,
    };
    const url = "doctor/" + doctorId;
    const response = await dctUpdateStatus(postData, url).then((data) => data);
    if (response.status === "active") {
      setdynamic_description("Activated successfully.");
      setdynamic_title("Activated");
    }
    if (response.status === "inactive") {
      setdynamic_description("Deactivated successfully.");
      setdynamic_title("Deactivated");
    }

    if (response.id) {
      seterror_dlg(false);
    } else {
      seterror_dlg(true);
      setsuccess_dlg(false);
    }

    doctorsObj[rowIndex].status = response.status;
    // dispatch(setDoctorListing(null));
    dispatch(setDoctorListing(doctorsObj, null));
    setStatusLoader(false);
    toogleUserCls =
      response.status === "active" ? "bx-user-check" : "bx-user-x";
    document
      .querySelector("#status_" + doctorId)
      .children[0].classList.remove(...loaderCls);
    document
      .querySelector("#status_" + doctorId)
      .children[0].classList.add(toogleUserCls);
  };

  async function JoinHazeDoctor(doctorData){
    const data = {
      doctorId : doctorData.doctorId,
      joinHaze : doctorData.joinHaze 
    }
    
    
    var config = {
      method: 'put',
      url: `${process.env.REACT_APP_API_PATH}/api/questionnaire-update`,
      headers: { 
        'Authorization': localStorage.getItem("auth_token"),
        'PartnerAuth': process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
      data : data
    };
    const res = await axios(config).then((data) => data);
    // console.log(res);
    doctorsObj[doctorData.index].joinHaze = res.data.data[0].joinHaze;
    // dispatch(setDoctorListing(doctorsObj));
    dispatch(setDoctorListing(doctorsObj, null));


    setconfirm_joinHaze(false);
    setsuccess_joinHAze(true)

  }



  const doctorItemsBody = (doctorsObj) => {
    return doctorsObj.length > 0 ? (
      doctorsObj.map((doctor, index) => {
        return (
          <Tr key={index} data-row-id={doctor.id}>
            <Td>
              <div className="avatar-xs">
                <Link
                  to={"/doctors-overview/id/" + doctor.id}
                  className="text-dark"
                >
                  <span className="avatar-title rounded-circle text-capitalize">
                    {doctor.userId.firstName
                      ? doctor.userId.firstName.charAt(0)
                      : "D"}
                  </span>
                </Link>
              </div>
            </Td>
            <Td className="text-left">
              <h5 className="text-truncate font-size-14">
                <Link
                  to={"/doctors-overview/id/" + doctor.id}
                  className="text-dark"
                >
                  {capitalizeWords(doctor.userId?.firstName)}
                </Link>
              </h5>
            </Td>
            <Td className="text-left">
              <h5 className="text-truncate font-size-14">
                <Link
                  to={"/doctors-overview/id/" + doctor.id}
                  className="text-dark"
                >
                  {capitalizeWords(doctor.userId?.lastName)}
                </Link>
              </h5>
            </Td>
            <Td className="text-left">{doctor.userId.email}</Td>
            <Td className="text-left">{doctor.primaryPhone}</Td>
            <Td className="text-center">
              <Link
                to="#"
                className={
                  doctor.status === "active"
                    ? "badge badge-soft-primary font-size-11 m-1"
                    : "badge badge-soft-danger font-size-11 m-1"
                }
              >
                {doctor.status === "active" ? "Active" : "Inactive"}
              </Link>
            </Td>
            <Td className="text-center">
              <ul className="list-inline font-size-20 contact-links mb-0 d-flex">
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"edit" + doctor.id}
                    onClick={() => {
                      openDoctorEditModal(doctor);
                      editmode();
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"edit" + doctor.id}
                    >
                      {props.t("Edit")}
                    </UncontrolledTooltip>
                  </Link>
                </li>
                {doctor.dosespotClinicianId === undefined ||
                doctor.dosespotClinicianId === null ||
                doctor.dosespotClinicianId === "" ||
                doctor.dosespotClinicianId === "0" ? (
                  <li className="list-inline-item px-2">
                    <Link
                      to="#"
                      id={"sync_" + doctor.id}
                      data-status={doctor.status}
                      data-id={doctor.id}
                      onClick={() => {
                        // syncDoctor(doctor,index);
                        setDoctorStatusMeta({
                          rowIndex: index,
                          doctorId: doctor.id,
                          doctorStatus: doctor.status,
                        });

                        syncDoctor(doctor.id, "");
                      }}
                    >
                      <i className="bx bx-sync"></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={"sync_" + doctor.id}
                      >
                        {props.t("Sync with DoseSpot")}
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                ) : null}
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"status_" + doctor.id}
                    data-status={doctor.status}
                    data-id={doctor.id}
                    onClick={() => {
                      setconfirm_both(true);
                      setDoctorStatusMeta({
                        rowIndex: index,
                        doctorId: doctor.id,
                        doctorStatus: doctor.status,
                      });
                    }}
                  >
                    {doctor.status === "active" ? (
                      <i className="bx bx-user-check text-success"></i>
                    ) : (
                      <i className="bx bx-user-x text-danger"></i>
                    )}
                    <UncontrolledTooltip
                      placement="top"
                      target={"status_" + doctor.id}
                    >
                      {doctor.status ? "Active" : "Inactive"}
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item px-2 font-size-1">
                  <Link id={"joinHaze" + doctor.id} to='#' onClick={() => {
                    setconfirm_joinHaze(true);
                    setDoctorJoinHaze({
                      index : index,
                      doctorId : doctor.id,
                      joinHaze : !doctor.joinHaze
                    })
                    }} >
                    {
                      doctor.joinHaze ? 
                      <i className="far fa-file-alt text-success" ></i> 
                      : 
                      <i className="far fa-file-alt text-secondary" ></i>
                    }
                    
                    <UncontrolledTooltip
                      placement="top"
                      target={"joinHaze" + doctor.id}
                    >
                      {props.t("Join Haze")}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
              {confirm_both ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="secondary"
                  onConfirm={() => {
                    toggleDoctorStatus(doctorStatusMeta);
                  }}
                  onCancel={() => {
                    setconfirm_both(false);
                    setsuccess_dlg(false);
                  }}
                >
                  {props.t("This process will change the Doctor status!")}
                </SweetAlert>
              ) : null}

              {confirm_joinHaze ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="secondary"
                  onConfirm={() => {
                    JoinHazeDoctor(doctorJoinHaze);
                  }}
                  onCancel={() => {
                    setconfirm_joinHaze(false);
                    // setsuccess_dlg(false);
                  }}
                >
                  {props.t("This process will change the Doctor JoinHaze status!")}
                </SweetAlert>
              ) : null}
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="7" className="text-center"></Td>
      </Tr>
    );
  };
  const handleSearchPage = (e) => {
    if (e.key === "Enter") {
      getDataOnEnter(e.currentTarget.value);
    }
  };
  return (
    <React.Fragment>
      <div className="doctor-list">
        <Row>
          <Col lg="12">
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {error_dlg ? (
              <SweetAlert
                error
                title={dynamic_title}
                onConfirm={() => {
                  seterror_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {success_joinHAze ? (
              <SweetAlert
                success
                title="Join Haze Status Changed Successfully"
                onConfirm={() => {
                  setsuccess_joinHAze(false);
                }}
              >
                {/* {dynamic_description} */}
              </SweetAlert>
            ) : null}

            {/* {ajaxLoader ? (
              <div className="${Config.names.slugName}-listing-loader">
                {loadingMessage("Loading...")}
              </div>
            ) : (
              ""
            )} */}
            <div className="table-responsive">
              <Table
                className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}
              >
                <Thead className="thead-light">
                  <Tr>
                    <Th
                      scope="col"
                      className="text-center"
                      style={{ width: "70px" }}
                    >
                      {props.t("#")}
                    </Th>
                    <Th scope="col" className="text-left">
                      {props.t("First Name ")}
                    </Th>
                    <Th scope="col" className="text-left">
                      {props.t("Last Name ")}
                    </Th>
                    <Th scope="col" className="text-left">
                      {props.t("Email ")}
                    </Th>
                    <Th scope="col" className="text-left">
                      {props.t("Phone ")}
                    </Th>
                    <Th
                      scope="col"
                      className="text-center"
                      style={{ width: "70px" }}
                    >
                      {props.t("Status ")}
                    </Th>
                    <Th
                      scope="col"
                      className="text-center"
                      style={{ width: "70px" }}
                    >
                      {props.t("Action ")}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>{doctorItemsBody(doctorsObj)}</Tbody>
              </Table>
              {props.doctorListMessage ? (
                <div className="text-center text-danger">
                  <i className="bx bx-search-alt font-size-18 align-middle mr-2"></i>{" "}
                  {props.doctorListMessage}{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div
              className={
                totalItems > perPage
                  ? `${Config.names.slugName}-pager d-none`
                  : `${Config.names.slugName}-pager d-none`
              }
            >
              <nav className="d-flex justify-content-center mt-4">
                <ReactPaginate
                  initialPage={currentPage}
                  forcePage={currentPage}
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={totalPages}
                  // itemsCountPerPage={perPage}
                  breakLabel={"..."}
                  label={<input />}
                  breakClassName={"break-me"}
                  onPageChange={props.getUserData}
                  containerClassName={
                    "pagination pagination-rounded align-items-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  activeLinkClassName={"active"}
                />
                <input
                  className="ml-3 text-center"
                  type="text"
                  size="5"
                  placeholder="Go to"
                  value={customPageNo}
                  onChange={(e) => setCustomPageNo(e.currentTarget.value)}
                  onKeyPress={handleSearchPage}
                  style={{ height: "25px" }}
                />
              </nav>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <BeatLoader
            size={12}
            color={Config.colors.pieChartActiveColor}
            loading={ajaxLoader}
          />
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withRouter(withNamespaces()(DoctorList));
