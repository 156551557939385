import {
  FIND_YOUR_ACCOUNT,
  ACTIVATE_ACCOUNT_DATA
} from "./actionTypes";

export const findYourAccount = (data) => {
    return {
      type: FIND_YOUR_ACCOUNT,
      payload: data,
    };
};

export const activeAccountData = (data) => {
    return {
      type: ACTIVATE_ACCOUNT_DATA,
      payload: data,
    };
};