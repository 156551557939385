export const CREATE_PARTNER_AUTH = "CREATE_PARTNER_AUTH";
export const UPDATE_PARTNER_AUTH = "UPDATE_PARTNER_AUTH";
export const GET_PARTNER_AUTH = "GET_PARTNER_AUTH";
export const GET_PARTNER_LIST_AUTH = "GET_PARTNER_LIST_AUTH";
export const SET_PARTNER_LIST_AUTH = "SET_PARTNER_LIST_AUTH";
export const SET_PARTNER_LISTING_AUTH = "SET_PARTNER_LISTING_AUTH";
export const CREATE_PARTNER_SUCCESS_AUTH = "CREATE_PARTNER_SUCCESS_AUTH";
export const CREATE_PARTNER_FAILED_AUTH = "CREATE_PARTNER_FAILED_AUTH";
export const SET_SINGLE_PARTNER_AUTH = "SET_SINGLE_PARTNER_AUTH";
export const API_ERROR = "API_ERROR";
