import React, { Fragment, useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect, useSelector } from "react-redux";

import { Alert, Col, Row } from "reactstrap";
import ReactDatePicker from "react-datepicker";
import { addMonths } from "date-fns";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

import moment from "moment";
// import tz from "moment-timezone";
import { dctTimezones } from "../../../helpers/dctTimezones";
import { dctTimes } from "../../../helpers/dctTimes";

import {
  updateConsultStepwise,
  getSingleConsultation,
  getMemberAndDependents,
  getDraftConsultation,
} from "../../../store/actions";

import {
  dctGetMemberAndDependPastData,
  loadingMessage,
  dctClsAddRemove,
  dctAlert,
} from "../../../helpers/Commons";

import ConsultFormFooter from "./consultFormFooter";
import { Config } from "../../../Config";

import { SweetAlert } from "../../../components/DoctegritySweetAlert/DoctegritySweetAlert";
import axios from "axios";

function Schedule(props) {
  const history = useHistory();
  const [showConsultation, setShowConsultation] = useState(false);

  const [selectTime, setSelectTime] = useState(dctTimes);
  useEffect(() => {
    setSelectTime(selectTime);
  }, [selectTime]);

  const [startDate, setStartDate] = useState(new Date());
  const [isScheduleDirty, setIsScheduleDirty] = useState(false);
  const [scheduleValidationMsg, setScheduleValidationMsg] = useState(
    "Please fill all the required fields."
  );

  let defaultUTC = moment.tz.guess();
  let defaultAbbr = moment.tz(defaultUTC).format("z");
  const [schedule, setSchedule] = useState({
    date: moment().format("YYYY/MM/DD"),
    fromTime: moment()
      .startOf("hour")
      .add(2, "hours")
      .format("YYYY/MM/DD hh:mm A"),
    toTime: moment()
      .startOf("hour")
      .add(4, "hours")
      .format("YYYY/MM/DD hh:mm A"),
    timezoneAbbr: defaultAbbr,
    timezoneUTC: defaultUTC,
    language: "English",
    isFutureConsultation: false,
  });
  let memberObj = [];
  memberObj = useSelector((state) => state.Member);
  const { singleConsult, memberAndDependents } = memberObj;

  const [_timezoneAbbr, set_timezoneAbbr] = useState("");
  const [_timezoneUTC, set_timezoneUTC] = useState("");

  const [timeZineErr, setTimeZineErr] = useState(false);

  useEffect(() => {
    let _timezoneInfo = dctGetMemberAndDependPastData(
      memberAndDependents,
      singleConsult
    );

    if (_timezoneInfo) {
      if (
        _timezoneInfo.timezoneUTC === "" &&
        _timezoneInfo.timezoneAbbr === ""
      ) {
        if (defaultAbbr && defaultUTC) {
          set_timezoneAbbr(defaultAbbr);
          set_timezoneUTC(defaultUTC);

          /**
           * API call for Profile update with current timezone Abbr and utc
           */
          updateMemberTimeZone()

        } else {
          setTimeZineErr(true);
        }
      } else {
        set_timezoneAbbr(_timezoneInfo.timezoneAbbr);
        set_timezoneUTC(_timezoneInfo.timezoneUTC);
      }
    }

    props.pageTitle(
      "Please select a convenient time for this Diagnostic Phone Medical Consultation:"
    );
  }, [singleConsult, memberAndDependents]);

  const updateMemberTimeZone = () => {
    let bearer_token = localStorage.getItem("auth_token");

    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const url = `${process.env.REACT_APP_API_PATH}/member/${memberAndDependents.id}`;

    const data = {
      timezoneAbbr: defaultAbbr,
      timezoneUTC: defaultUTC,
    };

    axios
      .patch(url, data, headers)
      .then((res) => {
        console.log("response", res);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  const handleChooseScheduleOption = (e) => {
    // if (window.ReactNativeWebView)
    //   window.ReactNativeWebView.postMessage("SCROLL_TO_TOP");
    e.persist();
    let getId = e.target.id;
    if (getId === "futureConsultation") {
      if (
        schedule.toTime === "Invalid date" ||
        schedule.fromTime === "Invalid date"
      ) {
        setSchedule({
          ...schedule,
          fromTime: moment()
            .startOf("hour")
            .add(3, "hours")
            .format("YYYY/MM/DD h:mm A"),
          toTime: moment()
            .startOf("hour")
            .add(5, "hours")
            .format("YYYY/MM/DD hh:mm A"),
        });
      }
      setShowConsultation(true);
      //Calculate future dates
      setSchedule((prevState) => ({
        ...prevState,
        isFutureConsultation: true,
      }));
    } else if (getId === "within2hoursConsultation") {
      setShowConsultation(false);
      //Calculate within 2 hours dates
      setSchedule((prevState) => ({
        ...prevState,
        date: moment().format("YYYY/MM/DD"),
        fromTime: moment()
          .startOf("hour")
          .add(2, "hours")
          .format("YYYY/MM/DD h:mm A"),
        toTime: moment()
          .startOf("hour")
          .add(4, "hours")
          .format("YYYY/MM/DD h:mm A"),
        isFutureConsultation: false,
      }));
    } else return setShowConsultation();
  };

  const handleLanguageOption = (e) => {
    e.persist();
    let getId = e.target.id;
    if (getId === "English") {
      setSchedule((prevState) => ({
        ...prevState,
        language: e.target.value,
      }));
    } else if (getId === "Español") {
      setSchedule((prevState) => ({
        ...prevState,
        language: e.target.value,
      }));
    } else return setSchedule();
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    let newDate = moment(date).format("YYYY/MM/DD");
    setSchedule((prevState) => ({
      ...prevState,
      date: newDate,
    }));
  };

  const handleTimeChange = (e) => {
    let defaultFromTime = moment()
      .startOf("hour")
      .add(2, "hours")
      .format("hh:mm A");
    let defaultToTime = moment()
      .startOf("hour")
      .add(4, "hours")
      .format("hh:mm A");
    let scheduledDate = schedule.date
      ? schedule.date
      : moment().format("YYYY/MM/DD");
    let getFromTime = e.target.value ? e.target.value : defaultFromTime;
    let calculateToTime = moment(`"${scheduledDate} ${getFromTime}"`)
      .add(2, "hours")
      .format("hh:mm A");
    // console.log("selected------", e.target.value)
    // console.log("from Time----", getFromTime);
    setSchedule((prevState) => ({
      ...prevState,
      fromTime: `${scheduledDate} ${getFromTime}`,
      toTime: calculateToTime
        ? (scheduledDate + " " + calculateToTime).toString()
        : defaultToTime,
    }));
  };

  const handleTimezone = (e) => {
    setSchedule((prevState) => ({
      ...prevState,
      timezoneAbbr: e.abbr ? e.abbr : defaultAbbr,
      timezoneUTC: e.utc ? e.utc[0] : defaultUTC,
    }));
  };

  //Update the consultation
  function handleValidSubmit(event, values) {
    if (schedule.toTime === "Invalid date") {
      setIsScheduleDirty(true);
      setScheduleValidationMsg("Please check your to date before proceeding.");
      return;
    }

    let fromTime = moment(schedule.fromTime).format("hh:mm A");

    let fromTimeIso = moment(`${schedule.date} ${fromTime}`)
      .tz(schedule.timezoneUTC)
      .format();
    let toDateIso = moment(fromTimeIso)
      .add(2, "hours")
      .tz(schedule.timezoneUTC)
      .format();

    // This is for Consultation within 2 hours
    if (schedule.isFutureConsultation === false) {
      let currentFromTime = moment()
        // .startOf("minute")
        // .add(20, "minutes")
        .format("YYYY/MM/DD h:mm A");
      fromTimeIso = moment(`${currentFromTime}`)
        .tz(schedule.timezoneUTC)
        .format();
      let currentToTime = moment().add(2, "hours").format("YYYY/MM/DD h:mm A");
      toDateIso = moment(`${currentToTime}`).tz(schedule.timezoneUTC).format();
    }

    values.isFutureConsultation = schedule.isFutureConsultation;
    values.schedule_from = fromTimeIso;
    values.schedule_to = toDateIso;
    values.schedule_physicianLanguages = schedule.language;
    values.schedule_timezoneAbbr = schedule.timezoneAbbr;
    values.schedule_timezoneUTC = schedule.timezoneUTC;

    if (!values.schedule_from || !values.schedule_to) {
      setIsScheduleDirty(true);
      setScheduleValidationMsg(
        "Please select a convenient time for Medical Consultation."
      );
      return;
    }
    dctClsAddRemove("dr-form-spinner", "d-none", "remove");
    props.updateConsultStepwise(values, props.history, 6);
  }

  const setFromTimeValue = (value) => {
    // console.log("from time-----", value);
    let minutes = moment(value).format("hh:mm").split(":")[1];
    // console.log("from time-----", minutes);
    if (minutes === "30") {
      return moment(value).format("hh:mm A");
    }
    return moment(value).format("hh:00 A");
  };

  return (
    <Fragment>
      {timeZineErr && (
        <SweetAlert
          title={props.t(`Timezone has not been set. Please update.`)}
          warning
          closeOnClickOutside={false}
          allowEscape={false}
          confirmBtnBsStyle="success"
          confirmBtnText={props.t("Yes")}
          onConfirm={() => {
            history.push("/member/my-account");
          }}
        ></SweetAlert>
      )}
      <AvForm
        className="form-horizontal pl-2 pr-2"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        {isScheduleDirty ? dctAlert(3, scheduleValidationMsg) : ""}

        <AvField name="step" type="hidden" value="6" />
        <AvField
          name="id"
          type="hidden"
          value={singleConsult ? singleConsult.id : ""}
        />
        <AvField name="stepType" type="hidden" value="schedule" />

        <div className="row">
          <Col lg="12">
            {!singleConsult ? (
              <div className="mt-1 mb-3">{loadingMessage("Loading...")}</div>
            ) : null}

            <Col lg={12}>
              <Row>
                <Col md={6}>
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="consultationChoice"
                      id="within2hoursConsultation"
                      value="option1"
                      defaultChecked
                      onClick={handleChooseScheduleOption}
                    />
                    <label
                      className="form-check-label font-weight-bold font-size-13"
                      htmlFor="within2hoursConsultation"
                    >
                      {props.t("Consultation now (within 2hrs)")}.
                    </label>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="consultationChoice"
                      id="futureConsultation"
                      value="option1"
                      onClick={handleChooseScheduleOption}
                    />
                    <label
                      className="form-check-label font-weight-bold font-size-13"
                      htmlFor="futureConsultation"
                    >
                      {props.t("Pick your Apt Time")}.
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>

            {showConsultation ? (
              <div>
                <div className="mt-4 font-weight-medium">
                  {props.t(
                    `The physician will have a two hour window to reach you. Make sure you select a time range when you will be available`
                  )}
                  .
                </div>
                <Col lg={8} className="mt-4">
                  <Row className="d-flex justify-content-between">
                    <Col lg={6}>
                      <h4 className="font-size-13 font-weight-bold">
                        {" "}
                        {props.t("Select A Date")}
                      </h4>
                      <div className="form-group">
                        <ReactDatePicker
                          className="form-control"
                          selected={startDate}
                          minDate={new Date()}
                          maxDate={addMonths(new Date(), 6)}
                          showDisabledMonthNavigation
                          onChange={handleDateChange}
                          dateFormat="yyyy/MM/dd"
                          placeholderText="YYYY/MM/DD"
                          inline
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="font-weight-medium mb-2">
                        {props.t("Select a Time Range")}
                      </div>

                      <div className="form-group row">
                        <div className="col-md-10">
                          <AvField
                            type="select"
                            name="fromTime"
                            className="custom-select"
                            label={props.t("From")}
                            value={setFromTimeValue(schedule.fromTime)}
                            onChange={handleTimeChange}
                          >
                            {selectTime.map((value, key) => (
                              <option key={key}> {value}</option>
                            ))}
                          </AvField>
                        </div>
                      </div>
                      <p className="font-size-12 font-weight-bold mt-1">
                        {props.t("To")}
                      </p>
                      <p className="font-size-15 mt-0">
                        {moment(schedule.toTime).format("hh:mm A")}
                      </p>
                      <AvField
                        name="toTime"
                        type="hidden"
                        value={schedule.toTime}
                      />
                      <label htmlFor="groupId">
                        {props.t("Select Timezone")}
                      </label>
                      <Select
                        defaultValue={{
                          value: _timezoneUTC ? _timezoneUTC : defaultUTC,
                          abbr: _timezoneAbbr ? _timezoneAbbr : defaultAbbr,
                        }}
                        placeholder="Select Timezone"
                        onChange={handleTimezone}
                        name="timezone"
                        getOptionLabel={(e) => e.value}
                        getOptionValue={(e) => e.abbr}
                        className={`${Config.names.slugName}-custom-select-box`}
                        options={dctTimezones}
                      />
                    </Col>
                  </Row>
                  <Alert className="mt-5 text-center" variant="success">
                    {props.t("Scheduled For")} : <b>{schedule.date}</b>{" "}
                    {props.t("between")}{" "}
                    <b>{moment(schedule.fromTime).format("hh:mm A")}</b>{" "}
                    {props.t("and")}{" "}
                    <b>{moment(schedule.toTime).format("hh:mm A")}</b>
                  </Alert>
                </Col>
              </div>
            ) : (
              ""
            )}

            <Col lg={12}>
              <div className="mt-3 mb-1 font-weight-medium">
                {props.t("What language should the physician speak?")}
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="schedule_physicianLanguages"
                  id="English"
                  value="English"
                  defaultChecked
                  onChange={handleLanguageOption}
                />
                <label className="form-check-label" htmlFor="English">
                  {props.t(`English`)}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="schedule_physicianLanguages"
                  id="Español"
                  value="Español"
                  onChange={handleLanguageOption}
                />
                <label className="form-check-label" htmlFor="Español">
                  {props.t(`Español`)}
                </label>
              </div>
            </Col>
          </Col>
        </div>
        <ConsultFormFooter />
      </AvForm>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { memberAndDependents, draftConsult, consultation, singleConsult } =
    state.Member;
  return {
    memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    updateConsultStepwise,
    getSingleConsultation,
    getMemberAndDependents,
    getDraftConsultation,
  })(withNamespaces()(Schedule))
);
