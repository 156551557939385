import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import DctHeroBanner from "../../components/Common/DctHeroBanner";
import UpcomingAppointments from "./UpcomingAppointments";
import Facilities from "./Facilities";
import UpdateMemberModal from "./UpdateMemberModal";
import SweetAlert from "react-bootstrap-sweetalert";

const Dashboard = (props) => {

  const [showModal, setShowModal] = useState(false)
  const [alertData, setAlertData] = useState({
    show: false,
    success: false,
    title: "",
    message: ""
  })
  let { authUser } = useSelector((state) => state.Login);
  useEffect(() => {
    // console.log("user data------", authUser)
    if (!authUser.mtm) {
      if (authUser.userType === "member" && !authUser.is_onboarding_confirmation_done) {
        setShowModal(true)
      }
    }

  }, []);

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <DctHeroBanner
                mainTitle=""
                subTitle=""
                isInfoBar={true}
                infoBarTitle=""
                isCall911={true}
              ></DctHeroBanner>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <UpcomingAppointments />
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Facilities />
            </Col>
          </Row>
        </Container>
      </div>
      {showModal && <UpdateMemberModal setShowModal={setShowModal} setAlertData={setAlertData} alertData={alertData} />}
      {alertData.show && <>
        {alertData.success ?
          <>
            <SweetAlert
              success
              title={alertData.title}
              onConfirm={() => {
                setAlertData({
                  ...alertData,
                  show: false
                })
              }}
            >
              {alertData.message}
            </SweetAlert>
          </>
          :
          <>
            <SweetAlert
              error
              title={alertData.title}
              onConfirm={() => {
                setAlertData({
                  ...alertData,
                  show: false
                })
              }}
            >
              {alertData.message}
            </SweetAlert>
          </>}

      </>}

    </Fragment>
  );
};

export default Dashboard;
