import React, { Component, lazy, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';
import { jsPanel } from 'jspanel4/es6module/jspanel';
import 'jspanel4/es6module/extensions/modal/jspanel.modal';
import 'jspanel4/dist/jspanel.min.css';

// Normal components
import ActionButton from './components/ActionButton';
import CreatePortal from './components/createPortal';

// jsPanel default options
import jsPanelOptions from './jsPanelOptions';

// lazy loaded components
const DisplayName = lazy(() => import('./components/DisplayName'));
// const Countries = lazy(() => import('./components/Countries'));
// const TodoApp = lazy(() => import('./components/Todo/TodoApp'));
// const SampleUsers = lazy(() => import('./components/SampleUsers'));
// const RandomImage = lazy(() => import('./components/RandomImage'));

// Top level React component
class VideoCallJsPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			panels: {},
			data:props.data
		};
	}

	createJsPanel = (action, comp, modal = false) => {
		// keep Main component refrence
		const app = this;
		// check if its already mounted, bring it to front
		if (app.state.panels[action]) {
			return app.state.panels[action]['panel'].front(() => {
				app.state.panels[action]['panel'].resize({
					height: window.innerHeight * 0.5,
					width: window.innerWidth * 0.45,
				});
				app.state.panels[action]['panel'].reposition('left-top 0% 45%');
			});
		}

		const options = {
			...jsPanelOptions,
			headerTitle: action,
			position: 'right-top 0% 45%',
			panelSize: {
				width: () => { return window.innerWidth * 0.45; },
				height: () => { return window.innerHeight * 0.5; }
			},
			addClass :'dctCustom',
			animateIn: 'jsPanelFadeIn',
			animateOut: 'jsPanelFadeOut',
			theme: 'none filled',
			closeOnEscape: true,
			borderRadius: '.5rem',

			// maximizedMargin: ()=>{
			// 	return 	[69, 5, 5, 250 ]
			// },
			zIndex:100000,

			setStatus: 'maximized',
			onclosed: () => {
				// remove closed jsPanel and its mounted component from state
				const appPanels = app.state.panels;
				if (appPanels[action]) {
					delete appPanels[action];
					app.setState({ panels: { ...appPanels } });
				}
				this.props.updateLogsData(this.props.data)
			},
			// onbeforeclose: function () {
			// 	return alert('Do you really want to close the Video Call?');
			// }
			

		};
		// create jsPanel
		const panel = modal ? jsPanel.modal.create(options) : jsPanel.create(options);
		// save panel and compponent (this will be mounted later inside panel body) reference inside state
		app.setState({ panels: { ...app.state.panels, [action]: { panel, comp } } });
	};

	renderJsPanlesInsidePortal() {
		const panels = this.state.panels;
		return Object.keys(panels).map(action => {
			const jsPanel = panels[action].panel;
			const Comp = panels[action].comp;
			const node = document.getElementById(`${jsPanel.id}-node`);
			let counter = 0;
			if (!Comp) return null;
			return (
				<CreatePortal rootNode={node} key={jsPanel.id}> 
					{Array.isArray(Comp) ? (
						Comp.map(C => (
							<Suspense key={`${jsPanel.id}-${counter++}`} fallback={<div className="alert alert-info">Loading...</div>}>
								<C jsPanel={jsPanel} />
							</Suspense>
						))
					) : (
							<Suspense fallback={<div className="alert alert-info">Loading...</div>}>
								<Comp jsPanel={jsPanel} />
							</Suspense>
						)}
				</CreatePortal>
			);
		});
	}

	render() {
		const jsPanels = Object.keys(this.state.panels);
		const actionButtonProps = {
			className: 'btn btn-outline-primary ml-2 mb-2',
			handleClick: this.createJsPanel
		};
		return (
			< div >
				<ActionButton {...actionButtonProps} title="Video Call" comp={DisplayName} consult={this.state.data}/>
				{jsPanels.length > 0 && this.renderJsPanlesInsidePortal()}
			</div>
		);
	}
}

export default VideoCallJsPanel;
