import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";
// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
import { dctGetDosespotItems } from "../../../helpers/backend_helper";
import { capitalizeWords, dctDefaultAvatar } from "../../../helpers/Commons";

import { setProfilePhoto } from "../../../store/actions";
import axios from "axios";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  let { authUser } = useSelector((state) => state.Login);
  if(!authUser){
    authUser= JSON.parse(localStorage.getItem('auth_user'));
  }
  const { profilePhoto } = useSelector((state) => state.Profile);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserInfoById();
  }, [props.success]);

  const getUserInfoById = async () => {
    let url = `user/${authUser.id}/?populate=false`;
    const response = await dctGetDosespotItems(url, "").then((data) => data);
    if (response && response.avatarUrl) {
      dispatch(setProfilePhoto(response.avatarUrl));
    };

    //call the refresh token API.
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let refreshData = {
      refreshToken: response.latestRefreshToken,
    }

    let refreshAPI = 'https://next.doctegrity.net/refresh-token';

    axios
      .post(refreshAPI, refreshData, headers)
      .then(function (response) {
        if (response.data.success && response.data.result) {
          localStorage.setItem("auth_token", "Bearer " + response.data.result.bearer);
        }
      })
      .catch(function (err) {
        // console.log("error", err)
      })
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profilePhoto ? profilePhoto : dctDefaultAvatar}
            alt={authUser?.firstName}
          />
          <span className="d-none d-xl-inline-block ml-2 mr-1">
            {capitalizeWords(authUser?.firstName)}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
            {props.t("Profile")}{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="#">
            <span className="badge badge-success float-right">11</span>
            <i className="bx bx-cog font-size-17 align-middle mr-1"></i>
            {props.t("Settings")}
          </DropdownItem> */}
          <div className="dropdown-divider"></div>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ProfileMenu))
);
