import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  PROFILE_PHOTO,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  avatar: "",
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case PROFILE_PHOTO:
      state = { ...state, profilePhoto: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
