import { CREATE_RXPHARMACY, UPDATE_RXPHARMACY, GET_RXPHARMACY, GET_RXPHARMACY_LIST, SET_RXPHARMACY_LIST, CREATE_RXPHARMACY_SUCCESS, CREATE_RXPHARMACY_FAILED, API_ERROR, SET_SINGLE_RXPHARMACY, SET_RXPHARMACY_LISTING } from './actionTypes';

export const createrxPharmacy = (rxpharmacy) => ({
    type: CREATE_RXPHARMACY,
    payload: { rxpharmacy }
})

export const updaterxPharmacy = (rxpharmacy) => ({
    type: UPDATE_RXPHARMACY,
    payload: { rxpharmacy }
})

export const getrxPharmacy = (rxpharmacy) => ({
    type: GET_RXPHARMACY,
    payload: { rxpharmacy }
})

export const getrxPharmacyList = (rxpharmacy) => ({
    type: GET_RXPHARMACY_LIST,
    payload: { rxpharmacy }
})

export const setrxPharmacyList = (rxpharmacy, message, action) => {
    return {
        type: SET_RXPHARMACY_LIST,
        payload: rxpharmacy,
        message: message,
        action_type: action
    }
}


export const setSinglerxPharmacy = (rxpharmacy, message, action) => {
    return {
        type: SET_SINGLE_RXPHARMACY,
        payload: rxpharmacy,
        message: message,
        action_type: action
    }
}

export const setrxPharmacyListing = (rxpharmacy, message) => {
    return {
        type: SET_RXPHARMACY_LISTING,
        payload: rxpharmacy,
        rxPharmacyListing: rxpharmacy,
        message: message,
    }
}

export const rxPharmacyActionSuccessful = (rxpharmacy, message, action) => {
    return {
        type: CREATE_RXPHARMACY_SUCCESS,
        payload: rxpharmacy,
        message: message,
        action_type: action
    }
}

export const createrxPharmacyFailed = (error) => {
    return {
        type: CREATE_RXPHARMACY_FAILED,
        payload: error
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}