import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { CREATE_PARTNER_AUTH, UPDATE_PARTNER_AUTH, GET_PARTNER_LIST_AUTH } from "./actionTypes";
import {
    partnerActionSuccessfulAuth,
    setSinglePartnerAuth,
    createPartnerFailedAuth,
    setPartnerListAuth,
} from "./actions";

//Include Both Helper File with needed methods
import {
    dctPostRawData,
    dctPutRawData,
    // getPartnerListObj,
    dctGetRawData,
} from "../../helpers/backend_helper";
import { Config } from "../../Config";


// Create Partner
function* createPartnerSaga({ payload: { partner, history } }) {
    try {
        const partner_data = partner;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN},
        };
        const response = yield call(
            dctPostRawData,
            process.env.REACT_APP_API_PATH + "/partnerauth",
            partner_data,
            headers
        );

        document
        .getElementsByClassName("dr-cform-spinner")[0]
        .classList.add("d-none");
        if (response.id) {
            yield put(
                partnerActionSuccessfulAuth(response, "Partner Created Successfully.", 1)
            );
            yield put(
                setSinglePartnerAuth(response, "Partner Created Successfully.", 1),
            );
            if (document.querySelector(`.${Config.names.slugName}-partner-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-partner-add-edit-modal .close`).click();
            }
        } else {
            yield put(createPartnerFailedAuth(response.message));
        }
    } catch (error) {
        yield put(createPartnerFailedAuth(error));
    }
}

// Update Partner
function* updatePartnerSaga({ payload: { partner, history } }) {
    try {
        const partner_data = partner;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,
                PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN },
        };
        
        const response = yield call(
            dctPutRawData,
            process.env.REACT_APP_API_PATH + "/partnerauth/"+partner_data.partnerId,
            partner_data,
            headers
        );
       

        document
        .getElementsByClassName("dr-cform-spinner")[0]
        .classList.add("d-none");
        if (response.id) {
            yield put(
                partnerActionSuccessfulAuth(response, "Partner Updated Successfully. #1", 2),
            );
            yield put(
                setSinglePartnerAuth(response, "Partner Updated Successfully #2.", 2),
            );
            
            if (document.querySelector(`.${Config.names.slugName}-partner-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-partner-add-edit-modal .close`).click();
            }
        } else {
            yield put(createPartnerFailedAuth(response.message));
        }
    } catch (error) {
        yield put(createPartnerFailedAuth(error));
    }
}

// Get Partner
function* getPartnerListSaga({ payload: { partner } }) {
    try {
        const partner_data = partner;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,
                PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN  },
        };
        const response = yield call(
            dctGetRawData,
            process.env.REACT_APP_API_PATH + "/partner",
            headers
        );
       
        
        if (response.id) {
            yield put(
                setPartnerListAuth(
                response.result.data,
                "Partner data fetched Successfully.",
                ""
                )
            );
        } else {
            yield put(createPartnerFailedAuth(response.message));
        }
    } catch (error) {
        yield put(createPartnerFailedAuth(error));
    }
}

export function* watchCreatePartner() {
    yield takeEvery(CREATE_PARTNER_AUTH, createPartnerSaga);
}

export function* watchUpdatePartner() {
    yield takeEvery(UPDATE_PARTNER_AUTH, updatePartnerSaga);
}
export function* watchGetPartner() {
    yield takeEvery(GET_PARTNER_LIST_AUTH, getPartnerListSaga);
}

function* partnerSaga() {
    yield all([
        fork(watchCreatePartner),
        fork(watchUpdatePartner),
        fork(watchGetPartner),
    ]);
}

export default partnerSaga;
