import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  CardBody,
  Alert,
  Container,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import login from "../../store/auth/login/reducer";
import { Config } from "../../Config";
import axios from "axios";
import AlertMessage from "./Components/Alerts/AlertMessage";
// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link, Redirect, useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { loginUser, apiError, setAuthToken } from "../../store/actions";

// import CryptoJS from "crypto-js";

// import ConfigDefaults from "../Config/ConfigDefaults";
// import { useState } from "react";
// import NonAuthLayout from "../components/Layout/NonAuthLayout";
// import Header from "../components/Layout/Header";
// import Link from "next/link";
// import AlertMessage from "../components/Alerts/AlertMessage";
// import { animate, motion } from "framer-motion";
// import Loader from "../components/loader/Loader";

// Redux Tool-Kit
// import { useSelector, useDispatch } from "react-redux";
// import { auth_user } from "../redux/auth/authDataReducer";

const Login = () => {
  // const router = useRouter();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loginData, setloginData] = useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState({
    email: false,
    password: false,
  });
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    message: "",
  });
  const HandleRememberMe = async () => {
    const slug = process.env.REACT_APP_SLUG;

    // const encryptPassWord = CryptoJS.AES.encrypt(
    //     loginData.password,
    //     slug
    // ).toString();
    // const data = {
    //     slug: slug,
    //     email: loginData.email,
    //     password: encryptPassWord,
    // };
    // localStorage.setItem("rememberMe", JSON.stringify(data));
  };

  const HandleLoginSubmit = (e) => {
    e.preventDefault();

    if (!loginData.email) {
      setLoginError({ ...loginError, email: true });
      return;
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(loginData.email)
    ) {
      setLoginError({ ...loginError, email: true });
      return;
    } else if (!loginData.password) {
      setLoginError({ ...loginError, password: true });
      return;
    }

    // if remember me is active---------
    if (remember) {
      HandleRememberMe();
    } else {
      localStorage.removeItem("rememberMe");
    }

    const headers = {
      headers: { PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN },
    };
    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_PATH + "/login", loginData, headers)
      .then((res) => {
        setLoading(false);

        // if (res?.data?.result?.user?.userType === "member") {
        //   setAlertData({
        //     ...alertData,
        //     type: "error",
        //     title: "Error",
        //     message: "Member login not allowed.",
        //   });
        //   setShowAlert(true);
        //   setTimeout(() => setShowAlert(false), 4000);
        //   return;
        // }

        if (res.status === 200 && res.data.success === true) {
          // store data to redux-----------
          dispatch(
            setAuthToken(
              "Bearer " + res.data.result.bearer,
              res.data.result.user
            )
          );

          // store data to localStorage---------
          localStorage.setItem(
            "auth_user",
            JSON.stringify(res.data.result.user)
          );
          localStorage.setItem(
            "auth_token",
            `Bearer ${res.data.result.bearer}`
          );

          if (window.localStorage.getItem("redirect_to_url")) {
            // console.log(
            //   "redirect_to_url ---",
            //   window.localStorage.getItem("redirect_to_url")
            // );
            let redirect_url =
              window.localStorage.getItem("redirect_to_url") === "/"
                ? "/login"
                : window.localStorage.getItem("redirect_to_url");

            if (redirect_url === "/login") {
              localStorage.setItem("redirect_to_url", "/dashboard");
              // router.replace("/dashboard");
              history.push("/dashboard");
            } else {
              history.push(redirect_url);
            }
          } else {
            localStorage.setItem("redirect_to_url", "/dashboard");
            // router.replace("/dashboard");
            history.push("/dashboard");
          }
        }
        if (res.status === 200 && res.data.success === false) {
          setAlertData({
            ...alertData,
            type: "error",
            title: "Error",
            message: res.data.message,
          });
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 4000);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);

        setAlertData({
          ...alertData,
          type: "error",
          title: "Error",
          message: err.response.data,
        });
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
      });
  };

  // const checkRememberMe = () => {
  //     const rememberMe = JSON.parse(window.localStorage.getItem("rememberMe"));
  //     if (rememberMe) {
  //         if (process.env.NEXT_PUBLIC_APP_SLUG === rememberMe.slug) {
  //             setRemember(true);
  //             const passphrase = process.env.NEXT_PUBLIC_APP_SLUG;
  //             const bytes = CryptoJS.AES.decrypt(rememberMe.password, passphrase);
  //             const originalText = bytes.toString(CryptoJS.enc.Utf8);
  //             setloginData({
  //                 ...loginData,
  //                 email: rememberMe.email,
  //                 password: originalText,
  //             });
  //         }
  //     }
  // };

  return (
    <>
      <React.Fragment>
        <div
          className={`py-5 bg-black app-${process.env.REACT_APP_SLUG} global-gradient`}
          style={{ minHeight: "100vh", height: "100%", marginTop: 0 }}
        >
          <div className="account-pages my-5 pt-sm-2">
            <Container className={`${Config.names.slugName}-container-sm`}>
              <Row className="justify-content-center mt-2">
                <Col
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img
                    src={Config.logos.logoDark}
                    alt=""
                    style={{ width: "14rem" }}
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center">
                <Col lg="5" md="6" sm="8" xs="10" className="p-0">
                  <AlertMessage
                    forgot={false}
                    login={true}
                    show={showAlert}
                    type={alertData.type}
                    title={alertData.title}
                    message={alertData.message}
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-center align-items-center">
                <Col
                  lg="5"
                  md="6"
                  sm="8"
                  xs="10"
                  className={`${Config.names.slugName}-box-shadow rounded-lg mt-5`}
                >
                  <div className="py-5 px-3">
                    <h5 className="text-primary font-weight-bold">
                      WELCOME BACK
                    </h5>
                    <p className="mt-3 font-size-14 font-weight-light">
                      Sign in to continue to{" "}
                      <span className="font-weight-bold text-primary">{`${Config.names.nickName}`}</span>
                      {Config.names.slugName === "hbaehealth" && (
                        <span
                          className="position-absolute"
                          style={{ fontSize: "6px" }}
                        >
                          SM
                        </span>
                      )}
                    </p>

                    <Form
                      className="mt-4"
                      onSubmit={(e) => HandleLoginSubmit(e)}
                    >
                      <input
                        type="text"
                        value={loginData.email}
                        onChange={(e) => {
                          setloginData({
                            ...loginData,
                            email: e.target.value,
                          });
                          setLoginError({
                            ...loginError,
                            email: false,
                          });
                        }}
                        className={`form-control mb-4 ${loginError.email && "border-danger"
                          }`}
                        placeholder="Email"
                      />

                      <input
                        type="password"
                        value={loginData.password}
                        onChange={(e) => {
                          setloginData({
                            ...loginData,
                            password: e.target.value,
                          });
                          setLoginError({
                            ...loginError,
                            password: false,
                          });
                        }}
                        className={`form-control mb-4 ${loginError.password && "border-danger"
                          }`}
                        placeholder="Password"
                      />

                      <div className="d-flex flex-row">
                        <div className="font-weight-light">
                          <input
                            type="checkbox"
                            onChange={() => {
                              setRemember(!remember);
                            }}
                            checked={remember}
                          />
                          <label className=" ml-2 font-weight-light">
                            Remember me
                          </label>
                        </div>
                        {/* <div className="ml-auto"> */}
                        <Link
                          className="font-weight-normal ml-auto"
                          to="/forgot-password"
                        >
                          Forgot Password?
                        </Link>
                        {/* </div> */}
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary mt-3 w-100 btn-shadow"
                      >
                        {loading ? (
                          <>
                            <span className="dr-mform-spinner">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>
                            </span>
                          </>
                        ) : (
                          "Log In"
                        )}
                      </button>
                    </Form>
                  </div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center mt-4">
                <Col lg="5" md="6" sm="8" xs="10">
                  <h5
                    className="text-primary font-weight-bold font-size-16 text-uppercase word"
                    style={{ wordSpacing: "2px" }}
                  >
                    new to {Config.names.appName}? need help?
                  </h5>
                  <p className="font-size-14 my-3">
                    {Config.names.appName} connects you to the right care when
                    you need it most.
                  </p>

                  <Link
                    to="/activate-benefit"
                    className="btn btn-shadow rounded-lg bg-white border-primary text-primary font-weight-bold w-100 mt-3"
                  >
                    Activate Your Benefit
                  </Link>
                  <div className="d-flex justify-content-center">
                    <p className="text-size-14 my-3">OR</p>
                  </div>
                  <Link
                    to="/find-account"
                    className="btn btn-shadow rounded-lg bg-white border-primary text-primary font-weight-bold w-100"
                  >
                    Find Your Account
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default Login;
