import React, { useState } from "react";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import axios from "axios";

import ReactDatePicker from "react-datepicker";
import {
  Row,
  Col,
  CardBody,
  Alert,
  Container,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

// availity-reactstrap-validation
import {
  AvForm,
  AvInput,
  AvFeedback,
  AvGroup,
} from "availity-reactstrap-validation";

import { DctFooterCredit } from "../helpers/Commons";

import { Config } from "../Config";
import moment from "moment";

const Benefit = (props) => {
  const [memberDob, setMemberDob] = useState();
  const [error, seterror] = useState(false);
  const [success, setsuccess] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    let formatDob = moment(memberDob).format("YYYY-MM-DD");

    values.dob = formatDob;

    document
      .getElementsByClassName("dr-login-spinner")[0]
      .classList.remove("d-none");

    let bearer_token = localStorage.getItem("auth_token");
    const data = {
      lastName: values.lastName,
      dob: values.dob,
      postalCode: values.postalCode,
    };
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/member/mtm-activation`;
    await axios
      .post(url, data, headers)
      .then((response) => {
        seterror(false);
        setsuccess(true);
        setsuccessMessage(response.data.message);
        document
          .getElementsByClassName("dr-login-spinner")[0]
          .classList.add("d-none");
      })
      .catch((err) => {
        setsuccess(false);
        console.log(err.response.data.message);
        seterror(true);
        seterrorMessage(err.response.data.message);

        document
          .getElementsByClassName("dr-login-spinner")[0]
          .classList.add("d-none");
      });

    document
      .getElementsByClassName("dr-login-spinner")[0]
      .classList.add("d-none");

    values = {};
  };

  const handleDateChange = (date) => {
    setMemberDob(new Date(date));


    if (typeof memberDob == undefined) {
      document.querySelector("#date-dob").classList.add("text-danger");
    } else {
      document.querySelector("#date-dob").classList.remove("text-danger");
    }
  };

  const layout_footer = (slug) => {
    switch (slug) {
      case "doctegrity":
        return (
          <div className={`mt-5 text-center ${Config.names.slugName}-copyright-section-2 text-primary`}>
            <p>
              {props.t(`Go back to`)}{" "}
              <Link to="/login" className="font-weight-medium text-primary">
                {props.t(`Login`)}
              </Link>{" "}
            </p>
            {DctFooterCredit(Config.names.appName)}
          </div>
        );
      
      case "telaq":
        return (
          <div className={`mt-5 text-center ${Config.names.slugName}-copyright-section-2 text-primary`}>
            <p>
              {props.t(`Go back to`)}{" "}
              <Link to="/login" className="font-weight-medium text-primary">
                {props.t(`Login`)}
              </Link>{" "}
            </p>
            {DctFooterCredit(Config.names.appName)}
          </div>
        );

      default:
        return (
          ""
        );
    }

  };

  return (
    <React.Fragment>
      <div
        className={`py-5 bg-black app-${process.env.REACT_APP_SLUG}`}
        style={{ minHeight: "100vh", height: "100%", marginTop: 0 }}
      >
        <div className="account-pages my-5 pt-sm-2">
          <Container className={`${Config.names.slugName}-container-sm`}>
            <div className={`${Config.names.slugName}-box-shadow ${Config.names.slugName}-login-container`}>
              <Row className="justify-content-center mt-5">
                <Col md={8} lg={6} xl={6} className={`${Config.names.slugName}-desktop`}>
                  <img
                    src={Config.images.loginBanner}
                    alt="Banner"
                    className="img-fluid"
                    style={{ minHeight: "100%" }}
                  />
                </Col>
                <Col md={8} lg={6} xl={6}>
                  <div className={`overflow-hidden ${Config.names.slugName}-auth-form`}>
                    <div className={`${Config.names.slugName}-login-logo`}>
                      <img className={"telaImg"} src={Config.logos.loginLogo} alt="App" height="35" />
                    </div>
                    <div className={`${Config.names.slugName}-auth-header ${Config.names.slugName}-bg-black`}>
                      <Row>
                        <Col className="col-12">
                          <div className={`mt-5 ml-4 mt-sm-0 ${Config.names.slugName}-auth-header-wrap`}>
                            <h4 className={`${Config.names.slugName}-auth-header`}>
                              {props.t("Activate Your")}{" "}
                              <strong>{props.t("Benefits")}</strong>
                            </h4>
                            <p className="">
                              {`${props.t(
                                "Fill out the form below to activate your"
                              )}`}{" "}
                              <strong>{`${Config.names.nickName}`}</strong>{" "}
                              {`${props.t("benefits.")}`}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className={`pt-0 ${Config.names.slugName}-bg-black`}>
                      <div className="">
                        <AvForm
                          className={`form-horizontal ${Config.names.slugName}-dark-theme`}
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v);
                          }}
                        >
                          {error && (
                            <Alert color="danger">{errorMessage}</Alert>
                          )}
                          {success && (
                            <Alert color="success">{successMessage}</Alert>
                          )}

                          <div className="form-group">
                            <AvGroup>
                              <Label for="lastName">
                                {props.t("Your Last Name")}
                              </Label>
                              <InputGroup className={`${Config.names.slugName}-input-group`}>
                                <InputGroupAddon addonType="prepend">
                                  {/* <InputGroupText>
                                  </InputGroupText> */}
                                </InputGroupAddon>
                                <AvInput
                                  name="lastName"
                                  required
                                  placeholder="Enter your Last Name"
                                />
                                <AvFeedback>
                                  {props.t("This field is required")}.
                                </AvFeedback>
                              </InputGroup>
                            </AvGroup>
                          </div>
                          <div className={` ${Config.names.slugName}-date-box`} id={`date-dob`}>
                            <AvGroup>
                              <Label for="dateOfBirth">
                                {props.t("Date Of Birth")}
                              </Label>
                              <ReactDatePicker
                                name="dateOfBirth"
                                className="form-control"
                                selected={memberDob}
                                dateFormat="yyyy-MM-dd"
                                onChange={handleDateChange}
                                placeholderText="YYYY-MM-DD"
                                showYearDropdown
                                required
                              />
                            </AvGroup>
                          </div>

                          <div className="form-group">
                            <AvGroup>
                              <Label for="postalCode">
                                {props.t("Postal Code")}
                              </Label>
                              <InputGroup className={`${Config.names.slugName}-input-group`}>
                                <InputGroupAddon addonType="prepend">
                                  {/* <InputGroupText></InputGroupText> */}
                                </InputGroupAddon>
                                <AvInput
                                  name="postalCode"
                                  type="text"
                                  required
                                  placeholder="Enter Postal code"
                                />
                                <AvFeedback>
                                  {props.t("This field is required.")}
                                </AvFeedback>
                              </InputGroup>
                            </AvGroup>
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light text-uppercase"
                              type="submit"
                            >
                              <span className="dr-login-spinner d-none">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                              </span>{" "}
                              {props.t("Activate my Doctegrity Benefit")}
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </div>
                  {process.env.REACT_APP_SLUG === "canelo" ? (
                    <div className={`mt-5 text-center ${Config.names.slugName}-copyright-section-1`}>
                      {DctFooterCredit(Config.names.appName)}
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
            {layout_footer(process.env.REACT_APP_SLUG)}
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(connect(mapStatetoProps)(withNamespaces()(Benefit)));
