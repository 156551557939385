import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  // Table,
  Label,
  Badge,
  ModalFooter,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import { dctDateFormat } from "../../helpers/Commons";
import { Config } from "../../Config";

const Notification = (props) => {
  const { authDoctorId } = useSelector((state) => state.Login);
  const [acceptModal, setacceptModal] = useState(false);
  const [rejectModal, setrejectModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentNotification, setCurrentNotification] = useState([]);
  const [currentReply, setCurrentReply] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    loadNotifications();
  }, []);

  const loadNotifications = async () => {
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/consultation/available?doctorId=${authDoctorId}`;
    const result = await axios.get(url, headers);
    setNotifications(result.data);
    setCurrentNotification(result.data[0]);
    setIsRefresh(false);
    setInterval(function () {
      loadNotifications();
    }, 3000000);
  };

  const handleAcceptAndReply = async () => {
    let bearer_token = localStorage.getItem("auth_token");
    const data = {
      message: currentReply.data,
      doctorId: authDoctorId,
    };
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/consultation/${currentNotification.id}/accepted`;
    await axios.post(url, data, headers).then((response) => {
      loadNotifications();
      setacceptModal(false);
    });
  };
  const handleRejectAndReply = async () => {
    let bearer_token = localStorage.getItem("auth_token");
    const data = {
      message: currentReply.data,
      doctorId: authDoctorId,
    };
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/consultation/${currentNotification.id}/rejected`;
    await axios.patch(url, data, headers).then((response) => {
      loadNotifications();
      setrejectModal(false);
    });
  };
  const onReplyChange = (event) => {
    setCurrentReply({
      data: event.target.value,
    });
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col lg="7">
            <div className="mt-3">
              <h5>{props.t("Consultations")}</h5>
            </div>
          </Col>
          <Col lg="5">
            <div className="text-right mb-2">
              <button
                className="btn btn-success waves-effect waves-light"
                type="button"
                onClick={() => {
                  loadNotifications();
                  setIsRefresh(true);
                }}
              >
                {isRefresh ? (
                  <span className="dr-gform-spinner">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>
                  </span>
                ) : (
                  <i className="mdi mdi-refresh font-size-18 align-middle"></i>
                )}
              </button>
            </div>
          </Col>
        </Row>
        <Fragment>
          <Modal
            centered={true}
            size="lg"
            isOpen={acceptModal}
            toggle={() => {
              setacceptModal(!acceptModal);
            }}
            scrollable={true}
            onClosed={(e) => {
              //toggleModalClose(e);
            }}
          >
            <ModalHeader
              toggle={() => {
                setacceptModal(!acceptModal);
              }}
            >
              {"Message Section"}
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="form-group">
                      <Label>{props.t("Cheif Complaint")}:</Label>
                      <br />
                      <Label>
                        <strong>
                          {currentNotification
                            ? currentNotification.details_chiefcomplaint
                            : ""}
                        </strong>
                      </Label>
                      <br />
                      <Label>{props.t("Main Symptom")}:</Label>
                      <br />
                      <Label>
                        <strong>
                          {currentNotification
                            ? currentNotification.details_commonSymptoms
                            : ""}
                        </strong>
                      </Label>
                      <br />
                      <Label>{props.t("Other Symptoms")}:</Label>
                      <br />
                      <Label>
                        <strong>
                          {currentNotification
                            ? currentNotification.details_allOtherSymptoms
                            : ""}
                        </strong>
                      </Label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="form-group">
                      <Label>{props.t("Reply")}:</Label>
                      <br />
                      <textarea
                        className="form-control"
                        name="replay"
                        style={{ height: "130px" }}
                        onChange={onReplyChange}
                      ></textarea>
                    </div>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col lg={8}>
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                    onClick={handleAcceptAndReply}
                  >
                    {props.t("Accept & Reply")}
                    <span className="dr-gform-spinner d-none">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    </span>{" "}
                  </button>
                </Col>
                <Col lg={4}>
                  <button
                    className="btn btn-secondary waves-effect waves-light"
                    type="submit"
                    onClick={() => setacceptModal(!acceptModal)}
                  >
                    {props.t("Cancel")}
                    <span className="dr-gform-spinner d-none">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    </span>{" "}
                  </button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
          <Modal
            size="lg"
            isOpen={rejectModal}
            toggle={() => {
              setrejectModal(!rejectModal);
            }}
            scrollable={true}
            onClosed={(e) => {
              //toggleModalClose(e);
            }}
          >
            <ModalHeader
              toggle={() => {
                setrejectModal(!rejectModal);
              }}
            >
              {"Reject Message Section"}
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="form-group">
                      <Label>{props.t("Cheif Complaint")}:</Label>
                      <br />
                      <Label>
                        <strong>
                          {currentNotification
                            ? currentNotification.details_chiefcomplaint
                            : ""}
                        </strong>
                      </Label>
                      <br />
                      <Label>{props.t("Main Symptom")}:</Label>
                      <br />
                      <Label>
                        <strong>
                          {currentNotification
                            ? currentNotification.details_commonSymptoms
                            : ""}
                        </strong>
                      </Label>
                      <br />
                      <Label>{props.t("Other Symptoms")}:</Label>
                      <br />
                      <Label>
                        <strong>
                          {currentNotification
                            ? currentNotification.details_allOtherSymptoms
                            : ""}
                        </strong>
                      </Label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="form-group">
                      <Label>{props.t("Reject for Reason(optional)")}:</Label>
                      <br />
                      <textarea
                        className="form-control"
                        name="replay"
                        style={{ height: "130px" }}
                        onChange={onReplyChange}
                      ></textarea>
                    </div>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col lg={6}>
                  <button
                    className="btn btn-danger waves-effect waves-light"
                    type="submit"
                    onClick={handleRejectAndReply}
                  >
                    {props.t("Reject")}
                    <span className="dr-gform-spinner d-none">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    </span>{" "}
                  </button>
                </Col>
                <Col lg={6}>
                  <button
                    className="btn btn-secondary waves-effect waves-light"
                    type="submit"
                    onClick={() => setrejectModal(!rejectModal)}
                  >
                    {props.t("Cancel")}
                    <span className="dr-gform-spinner d-none">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    </span>{" "}
                  </button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
          {/* start */}
          <Modal
            size="lg"
            isOpen={detailsModal}
            toggle={() => {
              setDetailsModal(!detailsModal);
            }}
            scrollable={true}
            onClosed={(e) => {
              //toggleModalClose(e);
            }}
          >
            <ModalHeader
              toggle={() => {
                setDetailsModal(!detailsModal);
              }}
            >
              {props.t("Consultation Details")} -{" "}
              {currentNotification ? (
                <Badge
                  color={
                    currentNotification.status === "confirmed"
                      ? "success"
                      : "info"
                  }
                  className="font-size-12 ml-1 text-capitalize"
                >
                  {currentNotification.status}
                </Badge>
              ) : (
                ""
              )}
            </ModalHeader>
            <ModalBody>
              <Container>
                {currentNotification ? (
                  <div>
                    <Row>
                      <Col lg={6}>
                        <p className="text-capitalize">
                          <strong>{props.t("Patient")}: </strong>{" "}
                          {currentNotification.patient}
                        </p>
                        <p>
                          <strong>{props.t("Type")}: </strong>{" "}
                          {currentNotification.consultationType}
                        </p>
                        <p>
                          <strong>{props.t("Phone")}: </strong>{" "}
                          {currentNotification.phone_tel}
                        </p>
                        <p>
                          <strong>{props.t("Service")}: </strong>{" "}
                          {currentNotification.phone_service}
                        </p>
                        <p>
                          <strong>{props.t("State")}: </strong>{" "}
                          {currentNotification.state_currentState}
                        </p>
                        <p>
                          <strong>{props.t("Common Symptoms")}: </strong>{" "}
                          {currentNotification.details_commonSymptoms}
                        </p>
                        <p>
                          <strong>{props.t("Other Symptoms")}: </strong>{" "}
                          {currentNotification.details_allOtherSymptoms}
                        </p>
                      </Col>
                      <Col lg={6}>
                        <p>
                          <strong>{props.t("Complaint")}: </strong>{" "}
                          {currentNotification.details_chiefcomplaint}
                        </p>
                        <p>
                          <strong>{props.t("Description")}: </strong>{" "}
                          {currentNotification.details_description}
                        </p>
                        <p>
                          <strong>{props.t("Schedule From")}: </strong>{" "}
                          {dctDateFormat(currentNotification.schedule_from)}
                        </p>
                        <p>
                          <strong>{props.t("Schedule To")}: </strong>{" "}
                          {dctDateFormat(currentNotification.schedule_to)}
                        </p>
                        <p>
                          <strong>{props.t("Physician Language")}: </strong>{" "}
                          {currentNotification.schedule_physicianLanguages}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        {currentNotification.reasonForRejection ? (
                          <p>
                            <strong>{props.t("Reason")}: </strong>{" "}
                            {currentNotification.reasonForRejection}
                          </p>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <p>
                          <strong>{props.t("Created At")}: </strong>{" "}
                          {dctDateFormat(currentNotification.createdAt)}
                        </p>
                      </Col>
                      <Col lg={6}>
                        <p>
                          <strong>{props.t("Updated At")}: </strong>{" "}
                          {dctDateFormat(currentNotification.updatedAt)}
                        </p>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  "No data found."
                )}
              </Container>
            </ModalBody>
          </Modal>
          {/* end */}
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <div className="table-responsive">
                    <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table mt-2`}>
                      <Thead className="thead-light">
                        <Tr>
                          <Th scope="col" style={{ width: "70px" }}>
                            {" "}
                            {props.t("#")}
                          </Th>
                          <Th scope="col">{props.t("Patient Name ")}</Th>
                          <Th scope="col">{props.t("Main Symptom ")}</Th>
                          <Th scope="col" style={{ width: "70px" }}>
                            {props.t("Accept/Reject ")}
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {notifications?.map((notification, key) => (
                          <Tr key={key}>
                            <Td>
                              <Link
                                to="#"
                                id={"detail"}
                                onClick={() => {
                                  setDetailsModal(true);
                                  setCurrentNotification(notifications[key]);
                                }}
                              >
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle text-capitalize">
                                    {notification.patient[0]}
                                  </span>
                                </div>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={"detail"}
                                >
                                  {" "}
                                  {props.t("Details")}{" "}
                                </UncontrolledTooltip>
                              </Link>
                            </Td>
                            <Td>{notification.patient} </Td>
                            <Td>{notification.details_commonSymptoms} </Td>
                            <Td>
                              <Link
                                to="#"
                                id={"add"}
                                onClick={() => {
                                  setacceptModal(true);
                                  setCurrentNotification(notifications[key]);
                                }}
                              >
                                <i className="bx bxs-message-check text-primary ml-3 font-size-20"></i>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={"add"}
                                >
                                  {" "}
                                  {props.t("Accept")}{" "}
                                </UncontrolledTooltip>
                              </Link>
                              <Link
                                to="#"
                                id={"reject"}
                                onClick={() => {
                                  setrejectModal(true);
                                }}
                              >
                                <i className="bx bxs-message-x text-danger ml-3 font-size-20"></i>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={"reject"}
                                >
                                  {" "}
                                  {props.t("Reject")}{" "}
                                </UncontrolledTooltip>
                              </Link>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Fragment>
      </Container>
    </Fragment>
  );
};
export default withRouter(withNamespaces()(Notification));
