import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  // setMySingleConsult,
  setConsultationListing,
} from "../../store/actions";

import { Row, Col, UncontrolledTooltip } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

//Include Both Helper File with needed methods
import {
  dctChangeStatus,
  dctGetDosespotItems,
} from "../../helpers/backend_helper";
import {
  loadingMessage,
  dctDateFormat,
  capitalizeWords,
} from "../../helpers/Commons";

//SweetAlert
// import SweetAlert from "react-bootstrap-sweetalert";
import { SweetAlert } from "../../components/DoctegritySweetAlert/DoctegritySweetAlert";
import $ from "jquery";
import { Config } from "../../Config";
import ExcuseNote from "../Member/Admin/ExcuseNote";
import SuccessModal from "../Member/Admin/SuccessModal";
import moment from "moment";

// import axios from "axios";

// import moment from "moment";

const ConsultationList = (props) => {
  const { currentPage, perPage, total, getDataOnEnter, ajaxLoader ,postsPerPage } = props;
  let ConsultObj = [];

  ConsultObj = useSelector((state) => state.Member.consultListing);

  const filterDependentData = ConsultObj?.filter((item) => {
    return item.dependentId == props.dependentId;
  });
  ConsultObj = props.dependentId ? filterDependentData : ConsultObj;

  const dispatch = useDispatch();

  const [customPageNo, setCustomPageNo] = useState("");
  const [confirm_both, setconfirm_both] = useState(false);
  const [isPDFDownload, setIsPDFDownload] = useState(false);
  const [pdfLinkForDownload, setPdfLinkForDownload] = useState(false);
  const [pdfDownloadMsg, setPdfDownloadMsg] = useState("");
  const [pdfDownloadTitle, setPdfDownloadTitle] = useState("");
  const [showPDFconfrBtn, setShowPDFconfrBtn] = useState(false);

  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [consultStatusMeta, setConsultationStatusMeta] = useState({
    rowIndex: "",
    consultationId: "",
    memberId: 0,
  });
  const [consultRejectReasonText, setConsultRejectReasonText] = useState("");
  const [excuseNoteModal, setExcuseNoteModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [pdfModal, setOpenPdfModal] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [editNoteModal, setEditNoteModal] = useState(false);
  const [statusUpdateMessage, setStatusUpdateMessage] = useState("");
  const [emailSentMessage, setEmailSentMessage] = useState("");
  const [singleConsultData, setSingleConsultData] = useState({});
  // Open Excuse Note Modal
  const openExcuseNoteModal = (consultationExcuseNote) => {
    singleConsultData.consultationId =
      consultationExcuseNote[0]?.consultationId;
    singleConsultData.id = consultationExcuseNote[0]?.id;
    singleConsultData.to = consultationExcuseNote[0]?.to;
    singleConsultData.date = consultationExcuseNote[0]?.date;
    singleConsultData.pleaseExcuse = capitalizeWords(
      consultationExcuseNote[0]?.pleaseExcuse
    );
    singleConsultData.from = consultationExcuseNote[0]?.from;
    singleConsultData.through = consultationExcuseNote[0]?.through;
    singleConsultData.notes = consultationExcuseNote[0]?.notes;
    setExcuseNoteModal(true);
  };
  useEffect(() => {
    if (window.ReactNativeWebView) {
      $(".my-consultation-table:first tr").each(function () {
        $(this).find("td:last").removeClass("pivoted");
      });
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Perform your asynchronous task here using await
        const response = await props.fetchConultationData();
        // Process the response as needed
      } catch (error) {
        // Handle any errors that occur during the asynchronous task
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function

    // Return the cleanup function
    return () => {
      // Perform any necessary cleanup operations
    };
  }, []);

  // const openConsultDetailsModal = (data) => {
  //   document.querySelector("#consult_details_modal").click();
  //   //Push Single Consultation Data to store
  //   dispatch(setMySingleConsult(data, "", ""));
  // };

  const cancelConsultation = async (consultStatusMeta) => {
    if (!consultStatusMeta) return;

    const { consultationId, rowIndex, memberId } = consultStatusMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Cancellation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    let postData = {
      memberId: memberId,
      message: consultRejectReasonText,
    };
    const url = `consultation/${consultationId}/cancelled`;
    const response = await dctChangeStatus(postData, url).then((data) => data);
    if (response.success === true || response.id) {
      seterror_dlg(false);
      setdynamic_title("Rejected");
      setdynamic_description("Cancelled successfully.");
      ConsultObj[rowIndex].status = "cancelled";
      dispatch(setConsultationListing(ConsultObj, null));
    } else if (response.success === false) {
      seterror_dlg(true);
      setsuccess_dlg(false);
      setdynamic_title(response.message);
      setdynamic_description("");
    } else {
      let title = response ? response.code : "";
      let desc = response ? response.details : "";
      seterror_dlg(true);
      setsuccess_dlg(false);
      setdynamic_title(title);
      setdynamic_description(desc);
    }
  };

  const printConsultation = async (consultId) => {
    if (!consultId) return;
    setPdfLinkForDownload(false);
    setShowPDFconfrBtn(false);
    setPdfDownloadTitle("Preparing PDF");
    setPdfDownloadMsg("Your PDF is preparing for download. Please wait!");

    let url = `consultation/${consultId}/summary-pdf`;

    const response = await dctGetDosespotItems(url, "").then((data) => data);

    if (response && response.success === true) {
      setPdfLinkForDownload(response.accessUrl);
      setPdfDownloadTitle("Your PDF is ready");
      setPdfDownloadMsg("Your PDF is ready for Download.");
      setShowPDFconfrBtn(true);
    } else {
      setPdfDownloadTitle("Error!");
      setPdfDownloadMsg("Something went wrong.");
      setShowPDFconfrBtn(true);
    }
  };
  const consultItemsBody = (ConsultObj) => {
    return ConsultObj?.length > 0 ? (
      ConsultObj.map((consult, index) => {
        return (
          <Tr key={index} data-row-id={consult.id}>
            <Td>
              <div className="avatar-xs">
                <Link
                  to={"/televisits-overview/id/" + consult.id}
                  className="text-dark text-capitalize"
                  onClick={() => {
                    localStorage.setItem(
                      "televisits_location",
                      props.location.pathname
                    );
                  }}
                >
                  <span className="avatar-title rounded-circle text-capitalize">
                    {consult.consultationType === "video" ? (
                      <i className="bx bxs-video"></i>
                    ) : (
                      <i className="bx bxs-phone-call"></i>
                    )}
                  </span>
                </Link>
              </div>
            </Td>
            <Td className="text-left">
              <h5 className="text-truncate font-size-14">
                <Link
                  to={"/televisits-overview/id/" + consult.id}
                  className="text-capitalize"
                  onClick={() => {
                    localStorage.setItem(
                      "televisits_location",
                      props.location.pathname
                    );
                  }}
                >
                  {consult.patient}
                </Link>
              </h5>
            </Td>
            <Td className="text-left">
              {/* <div>{consult?.schedule_timezoneAbbr}, {consult?.schedule_timezoneUTC}</div>
              <div>&nbsp;</div> */}
              <div>
                {!consult?.schedule_from
                  ? ""
                  : dctDateFormat(
                      consult.schedule_from,
                      consult.schedule_timezoneUTC
                    )}
              </div>
              {/* <div>{consult.schedule_from}</div> */}
              <div>&nbsp;</div>
              <div>
                {!consult?.schedule_to
                  ? ""
                  : dctDateFormat(
                      consult.schedule_to,
                      consult.schedule_timezoneUTC
                    )}
              </div>
              {/* <div>{consult.schedule_to}</div> */}
            </Td>
            <Td>{consult.companyId?.companyName}</Td>
            <Td className="text-left">
              {consult.consultationPlatform == "MTM" &&
              consult?.mtmConsultationDetails
                ? Object.keys(consult?.mtmConsultationDetails?.physician)
                    .length > 0
                  ? consult?.mtmConsultationDetails?.physician?.firstName +
                    " " +
                    consult?.mtmConsultationDetails?.physician?.lastName
                  : "unassigned"
                : "Doctor"}
            </Td>
            <Td className="text-center">
              <Link
                to="#"
                className={
                  consult.mtmConsultationDetails
                    ? consult.mtmConsultationDetails.status == "Inactive"
                      ? "badge badge-soft-danger font-size-11 m-1 text-capitalize"
                      : "badge badge-soft-primary font-size-11 m-1 text-capitalize"
                    : consult.status === "cancelled"
                    ? "badge badge-soft-danger font-size-11 m-1 text-capitalize"
                    : "badge badge-soft-primary font-size-11 m-1 text-capitalize"
                }
              >
                {consult.mtmConsultationDetails
                  ? consult.mtmConsultationDetails.status == "Inactive"
                    ? "cancelled"
                    : consult.status
                  : consult.status}
              </Link>
            </Td>
            <Td className="text-center">
              <ul className="list-inline font-size-20 contact-links mb-0 text-center">
                {consult.status !== "draft" ? (
                  <li className="list-inline-item">
                    <Link
                      to={`/consultation/${consult.id}/logs`}
                      id={"logs_" + consult.id}
                    >
                      <i className="bx bx-info-circle text-info"></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={"logs_" + consult.id}
                      >
                        {props.t("Consultation Logs")}
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {/* Excuse Note icon */}
                {consult?.consultationExcuseNote?.length > 0 ? (
                  <li className="list-inline-item">
                    <Link
                      to="#"
                      id={"excuse" + 1}
                      onClick={() => {
                        openExcuseNoteModal(consult?.consultationExcuseNote);
                      }}
                    >
                      <i className="mdi mdi-file-outline"></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={"excuse" + 1}
                      >
                        {props.t("Excuse Note")}
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                ) : (
                  ""
                )}

                {consult.status === "draft" ? (
                  <li className="list-inline-item">
                    <Link
                      to="#"
                      id={"cancel_" + consult.id}
                      onClick={() => {
                        setconfirm_both(true);
                        setConsultationStatusMeta({
                          rowIndex: index,
                          consultationId: consult.id,
                          memberId: consult.memberId,
                        });
                      }}
                    >
                      <i className="bx bx bx-x-circle text-danger"></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={"cancel_" + consult.id}
                      >
                        {props.t("Cancel Consultation")}
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                ) : null}
                {consult.status === "confirmed" ||
                consult.status === "completed" ||
                consult.status === "scheduled" ? (
                  <React.Fragment>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        id={"print_" + consult.id}
                        onClick={() => {
                          setIsPDFDownload(true);
                          printConsultation(consult.id);
                        }}
                      >
                        <i className="bx bxs-printer"></i>
                        <UncontrolledTooltip
                          placement="top"
                          target={"print_" + consult.id}
                        >
                          {props.t("Print")}
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to={`/televisits-overview/id/${consult.id}`}
                        id={"view_" + consult.id}
                        onClick={() => {
                          localStorage.setItem(
                            "televisits_location",
                            props.location.pathname
                          );
                        }}
                      >
                        <i className="bx bx-show-alt"></i>
                        <UncontrolledTooltip
                          placement="top"
                          target={"view_" + consult.id}
                        >
                          {props.t("View")}
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                  </React.Fragment>
                ) : (
                  ""
                )}

                {consult.status === "scheduled" ? (
                  <li className="list-inline-item">
                    <Link
                      to={`/consultation/${consult.id}/room/${consult.id}`}
                      id={"video_" + consult.id}
                      // onClick={() => {

                      // }}
                    >
                      <i className="bx bx-video-recording text-success"></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={"video_" + consult.id}
                      >
                        {props.t("Join Video Call")}
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              {isPDFDownload ? (
                <SweetAlert
                  title={props.t(`${pdfDownloadTitle}`)}
                  showConfirm={
                    window.ReactNativeWebView && showPDFconfrBtn ? true : false
                  }
                  confirmBtnText="Download"
                  showCancel={showPDFconfrBtn ? true : false}
                  cancelBtnBsStyle="secondary"
                  onConfirm={() => {
                    setIsPDFDownload(false);
                    window.ReactNativeWebView.postMessage(
                      "PDF:" + pdfLinkForDownload
                    );
                  }}
                  onCancel={() => {
                    setIsPDFDownload(false);
                  }}
                >
                  <p className="mb-2">{props.t(`${pdfDownloadMsg}`)}</p>
                  {pdfLinkForDownload ? (
                    <p>
                      <a
                        className="btn btn-success text-left waves-light"
                        href={pdfLinkForDownload}
                        target="_blank"
                      >
                        {props.t(`Download PDF`)}
                        <i className="mdi mdi-file-pdf-box font-size-18 align-middle ml-2"></i>{" "}
                      </a>
                    </p>
                  ) : (
                    <div>
                      <i className="bx bx-loader bx-spin text-success align-middle font-size-20"></i>
                    </div>
                  )}
                </SweetAlert>
              ) : null}
              {confirm_both ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="secondary"
                  onConfirm={() => {
                    cancelConsultation(consultStatusMeta);
                  }}
                  onCancel={() => {
                    setconfirm_both(false);
                    setsuccess_dlg(false);
                  }}
                >
                  <div className="mb-2">
                    {props.t("This process will cancel the Consultation!")}
                  </div>

                  <textarea
                    type="textarea"
                    name="cancelReason"
                    className="form-control"
                    onChange={(e) => {
                      setConsultRejectReasonText(e.target.value);
                    }}
                  />
                </SweetAlert>
              ) : null}
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="5" className="text-center"></Td>
      </Tr>
    );
  };
  const handleSearchPage = (e) => {
    if (e.key === "Enter") {
      getDataOnEnter(e.currentTarget.value);
    }
  };
  return (
    <React.Fragment>
      <div className={`consultation-list ${Config.names.slugName}-sm--15`}>
        <Row>
          <Col lg="12">
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {error_dlg ? (
              <SweetAlert
                error
                title={dynamic_title}
                onConfirm={() => {
                  seterror_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {ajaxLoader ? (
              <div className={`${Config.names.slugName}-listing-loader`}>
                {loadingMessage("Loading...")}
              </div>
            ) : (
              ""
            )}
            <div className="table-responsive">
              <Table
                className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table my-consultation-table`}
              >
                <Thead className="thead-light">
                  <Tr>
                    <Th
                      scope="col"
                      className="text-center"
                      style={{ width: "70px" }}
                    >
                      {props.t("Type")}
                    </Th>
                    <Th scope="col" className="text-left">
                      {props.t("Patient")}
                    </Th>
                    <Th scope="col" className="text-left">
                      {props.t("Scheduled")}
                    </Th>
                    <Th scope="col" className="text-left">
                      {props.t("Company")}
                    </Th>
                    <Th scope="col" className="text-left">
                      {props.t("Physician")}
                    </Th>
                    <Th
                      scope="col"
                      className="text-center"
                      style={{ width: "70px" }}
                    >
                      {props.t("Status")}
                    </Th>
                    <Th
                      scope="col"
                      className="text-center"
                      style={{ width: "120px" }}
                    >
                      {!window.ReactNativeWebView ? (
                        <center>{props.t("Action")}</center>
                      ) : (
                        ""
                      )}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>{consultItemsBody(ConsultObj)}</Tbody>
              </Table>
              {props.consultListMessage || ConsultObj?.length == 0 ? (
                <div className="text-center text-danger">
                  <i className="bx bx-search-alt font-size-18 align-middle mr-2"></i>{" "}
                  {/* {props.consultListMessage}{" "} */}
                  <span>No records found</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div
              data-total={total}
              data-per={perPage}
              className={
                total > 1
                  ? `${Config.names.slugName}-pager`
                  : `${Config.names.slugName}-pager d-none`
              }
            >
              {ConsultObj?.length >= 0 && (
                <nav className="d-flex justify-content-center mt-4">
                  <ReactPaginate
                    initialPage={currentPage}
                    forcePage={currentPage}
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={total}
                    itemsCountPerPage={perPage}
                    breakLabel={"..."}
                    label={<input />}
                    breakClassName={"break-me"}
                    onPageChange={props.getUserData}
                    containerClassName={
                      "pagination pagination-rounded align-items-center"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    activeLinkClassName={"active"}
                  />
                  {/* <input
                  className="ml-3 text-center"
                  type="text"
                  size="5"
                  placeholder="Go to"
                  value={customPageNo}
                  onChange={(e) => setCustomPageNo(e.currentTarget.value)}
                  onKeyPress={handleSearchPage}
                  style={{ height: "25px" }}
                /> */}
                </nav>
              )}
            </div>
          </Col>
        </Row>
        {/* Excuse note modal */}
        {excuseNoteModal && (
          <ExcuseNote
            modal={excuseNoteModal}
            setModal={setExcuseNoteModal}
            setOpenSuccessModal={setOpenSuccessModal}
            openSuccessModal={openSuccessModal}
            setEmailMessage={setEmailMessage}
            setOpenPdfModal={setOpenPdfModal}
            singleConsultData={singleConsultData}
            setEmailSentMessage={setEmailSentMessage}
            t={props?.t}

          />
        )}
        {/* success modal */}
        {openSuccessModal && (
          <SuccessModal
            modal={openSuccessModal}
            setModal={setOpenSuccessModal}
            emailMessage={emailMessage}
            setEmailMessage={setEmailMessage}
            pdfModal={pdfModal}
            setOpenPdfModal={setOpenPdfModal}
            statusUpdate={statusUpdate}
            setStatusUpdate={setStatusUpdate}
            editNoteModal={editNoteModal}
            setEditNoteModal={setEditNoteModal}
            statusUpdateMessage={statusUpdateMessage}
            emailSentMessage={emailSentMessage}
            t={props?.t}

          />
        )}
      </div>
    </React.Fragment>
  );
};
export default withRouter(withNamespaces()(ConsultationList));
