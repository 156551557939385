import React, { Fragment, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  Button,
  Col,
  Label,
  Row,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Input,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import ReactDatePicker from "react-datepicker";
import { format } from "date-fns";
import moment from "moment";
import scrollToComponent from "react-scroll-to-component";

import {
  dctAlert,
  dctClsAddRemove,
  allergyReactionTypes,
  allergyStatusType,
  loadingMessage,
} from "../../../helpers/Commons";
import {
  dctPostDosespotData,
  dctGetDosespotItems,
  dctPutDosespotData,
} from "../../../helpers/backend_helper";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Config } from "../../../Config";

function MedicationAllergy(props) {
  let MAformSection = React.createRef();

  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState(false);

  const [onsetDateChange, setOnsetDateChange] = useState(new Date());
  const [onsetDate, setOnsetDate] = useState(moment().format());

  const dispatch = useDispatch();
  const { authMemberId, auth_token } = useSelector((state) => state.Login);

  const [openAllergies, setOpenAllergies] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [isEditAllergy, setIsEditAllergy] = useState(false);

  const EMPTY_ALLERGY = {
    name: "Search Allergy",
    code: "",
    codeType: "",
    reaction: "",
    reactionType: "",
    status: "",
    RxCUI: ""
  };

  const [allergies, setAllergies] = useState(EMPTY_ALLERGY);

  const [_allergyList, _setAllergyList] = useState({
    data: [],
    loading: true,
    error: "",
  });
  const [_singleAllergy, _setSingleAllergy] = useState({});

  useEffect(() => {
    if (props.location.hash === "#allergies") {
      setOpenAllergies(true);
    }
    getAllergyById();
  }, []);

  const handleDateChange = (date) => {
    setOnsetDateChange(date);
    let newDate = format(date, "MM/dd/yyyy");
    newDate = moment(newDate).format();
    setOnsetDate(newDate);
  };

  const getAllergyById = async () => {
    let url = `member/${authMemberId}/allergy`;
    if (props.patientType === "dependent") {
      url = `dependent/${props.activePatientId}/allergy`;
    }
    const response = await dctGetDosespotItems(url, "").then((data) => data);

    let responseData = response?.data;
    if (responseData && response.success === true) {
      _setAllergyList({
        data: responseData,
        loading: false,
        error: "",
      });
    } else {
      _setAllergyList({
        data: null,
        loading: false,
        error: response,
      });
      setErrorMsg(response);
      setSuccessMsg("");
    }
  };

  // Load the fetched Allergies data for the select dropdown
  const loadAllergiesOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length > 2) {
      let apiUrl = "allergy";
      const axiosArgs = {
        name: inputValue,
      };
      dctGetDosespotItems(apiUrl, axiosArgs).then((results) =>
        callback(results.data)
      );
    }
    return;
  };
  const allergiesListOptions = debounce(loadAllergiesOptions, 250);

  const handleAllergyName = (e) => {
    if (!e) return;
    document.querySelector("#allergyName").classList.remove("text-danger");
    setAllergies((prevState) => ({
      ...prevState,
      name: e ? e.Name : "",
      code: e ? e.Code : "",
      codeType: e ? e.CodeType : "",
      RxCUI: e ? e.RxCUI : "",
    }));
  };

  const handleAllergiesSubmit = async (event, values) => {
    setFormErrorMsg(false);

    if (!allergies.name) {
      document.querySelector("#allergyName").classList.add("text-danger");
      return;
    }

    values.name = allergies.name;
    values.code = allergies.code;
    values.codeType = allergies.codeType;
    values.onsetDate = onsetDate;
    values.RxCUI = allergies.RxCUI;

    const headers = {
      headers: {
        Authorization: auth_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    dctClsAddRemove("dr-search-spinner", "d-none", "remove");
    let response = "";
    // For edit action

    if (values.action === "edit" && values.id) {
      let editId = values.id;
      let url = `${process.env.REACT_APP_API_PATH}/member/${authMemberId}/allergy/${editId}`;
      if (values.patientType === "dependent") {
        url = `${process.env.REACT_APP_API_PATH}/dependent/${values.dependentId}/allergy/${editId}`;
      }
      response = await dctPutDosespotData(url, values, headers).then(
        (data) => data
      );
    } else {
      // For create action
      let url = `${process.env.REACT_APP_API_PATH}/member/${authMemberId}/allergy`;
      if (values.patientType === "dependent") {
        url = `${process.env.REACT_APP_API_PATH}/dependent/${values.dependentId}/allergy`;
      }
      response = await dctPostDosespotData(url, values, headers).then(
        (data) => data
      );
    }

    if (response.success === true) {
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      if (response.data.Result.ResultCode === "ERROR") {
        setFormErrorMsg(response.data.Result.ResultDescription);
        setSuccessMsg("");
        return;
      }

      let message = props.t(
        `Allergy record ${values.id ? "updated" : "created"} succuessfully.`
      );
      setSuccessMsg(message);
      setFormErrorMsg("");
      //Call the Get Medication method
      getAllergyById();
      setOpenEditForm(!openEditForm);
    } else {
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      setFormErrorMsg(
        response && response.data.message
          ? response.data.message
          : "Something went wrong. Please try again."
      );
      setSuccessMsg("");
    }
  };

  //Allergies body
  const allergyItemsBody = (_medicationList) => {
    return _medicationList && _medicationList.length > 0 ? (
      _medicationList.map((data, index) => {
        return (
          <Tr key={index} data-row-id={data.id}>
            <Td>{data.Name}</Td>
            <Td>{data.Code}</Td>
            <Td>{data.CodeType}</Td>
            <Td>{data.Reaction}</Td>
            <Td>{allergyReactionTypes[data.ReactionType]}</Td>
            <Td>{data.onsetDate}</Td>
            <Td>{allergyStatusType[data.StatusType]}</Td>
            <Td>
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      openAllergyForm(data);
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                  </span>
                </li>
              </ul>
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="8" className="text-center">
          <div className="text-danger">{props.t("No record found...")}</div>
        </Td>
      </Tr>
    );
  };

  const openAllergyForm = (data) => {
    if (data && data.Name) {
      setOpenAllergies(true);
      setOpenEditForm(true);
      setIsEditAllergy(true);
      setAllergies((prevState) => ({
        ...prevState,
        name: data.Name ? data.Name : "Search Allergy",
        code: data.Code ? data.Code : "",
        codeType: data.CodeType ? data.CodeType : "",
      }));
      let formatDate = moment(data.OnsetDate).format("YYYY-MM-DD");
      setOnsetDate(formatDate);

      _setSingleAllergy(data);
    } else {
      setOpenEditForm(true);
      setIsEditAllergy(false);
      setAllergies(EMPTY_ALLERGY);
      _setSingleAllergy({});
    }
    if (openEditForm) {
      scrollToComponent(MAformSection.current, {
        offset: 0,
        align: "middle",
        duration: 500,
        ease: "inExpo",
      });
    }
  };

  const closeAllergyForm = () => {
    setOpenEditForm(false);
    setIsEditAllergy(false);
  };

  const onEntered = () => {
    scrollToComponent(MAformSection.current, {
      offset: 0,
      align: "middle",
      duration: 500,
      ease: "inExpo",
    });
  };

  return (
    <Fragment>
      <Card className="mb-0 mt-3">
        <CardHeader
          className="p-3 bg-white cursor-pointer"
          onClick={() => {
            setOpenAllergies(!openAllergies);
          }}
        >
          <div className="float-right ml-2 dropdown show">
            <i aria-haspopup="true" className="text-muted" aria-expanded="true">
              <i className="mdi mdi-square-edit-outline font-size-18"></i>
            </i>
          </div>

          <div className="d-flex align-items-center">
            <div className={`avatar-xs mr-3 ${Config.names.slugName}-desktop`}>
              <span className="avatar-title text-white rounded-circle font-size-18">
                <i className="mdi mdi-allergy"></i>
              </span>
            </div>
            <div className="head">
              <h6 className="mb-1 font-14 font-weight-bold text-uppercase">
                {props.t(`Medication Allergies`)}
              </h6>
              <Label className="mb-0 font-size-14 font-weight-normal">
                {props.t(
                  `Indicate any known drug allergies that you may have.`
                )}
              </Label>
            </div>
          </div>
        </CardHeader>
      </Card>

      <Collapse isOpen={openAllergies}>
        <Card>
          <CardBody className="mb-0">
            {errorMsg ? dctAlert(3, errorMsg) : ""}
            {successMsg ? dctAlert(1, successMsg) : ""}

            <Fragment>
              <div className="${Config.names.slugName}-sm--15">
                <Row>
                  <Col lg={12} className="mb-3">
                    <div className="table-responsive">
                      <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}>
                        <Thead className="thead-light">
                          <Tr>
                            <Th scope="col">{props.t("Name")}</Th>
                            <Th scope="col" style={{ width: "70px" }}>
                              {props.t("Code")}
                            </Th>
                            <Th scope="col" style={{ width: "70px" }}>
                              {props.t("Code Type")}
                            </Th>
                            <Th scope="col" style={{ width: "70px" }}>
                              {props.t("Reaction")}
                            </Th>
                            <Th scope="col" style={{ width: "70px" }}>
                              {props.t("Reaction Type")}
                            </Th>
                            <Th scope="col">{props.t("Date")}</Th>
                            <Th scope="col" style={{ width: "70px" }}>
                              {props.t("Status")}
                            </Th>
                            <Th scope="col" style={{ width: "70px" }}>
                              {props.t("Action")}
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>{allergyItemsBody(_allergyList?.data)}</Tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </div>
            </Fragment>

            <div>
              <Button
                color="primary"
                onClick={openAllergyForm}
                style={{ marginBottom: "1rem" }}
              >
                {props.t(`Add Medication Allergy`)}
              </Button>

              <Collapse isOpen={openEditForm} onEntered={onEntered}>
                <div className="mt-2" ref={MAformSection}>
                  <h4>{props.t(`Add Medication Allergy Record`)}</h4>
                  <AvForm
                    className="form-horizontal needs-validation"
                    onValidSubmit={(e, v) => {
                      handleAllergiesSubmit(e, v);
                    }}
                  >
                    <AvField
                      name="id"
                      type="hidden"
                      value={
                        _singleAllergy && _singleAllergy.id
                          ? _singleAllergy.id
                          : ""
                      }
                    />
                    {_singleAllergy && _singleAllergy.id ? (
                      <AvField name="action" type="hidden" value="edit" />
                    ) : null}

                    <AvField
                      name="memberId"
                      type="hidden"
                      value={authMemberId}
                    />
                    {props.patientType === "dependent" ? (
                      <AvField
                        name="dependentId"
                        type="hidden"
                        value={props.activePatientId}
                      />
                    ) : null}
                    <AvField
                      name="patientType"
                      type="hidden"
                      value={props.patientType}
                    />

                    <AvField
                      name="memberId"
                      type="hidden"
                      value={authMemberId}
                    />

                    <Row>
                      <Col lg={6}>
                        <div
                          className={`form-group ${Config.names.slugName}-select-box`}
                          id="allergyName"
                        >
                          <label htmlFor="allergyName">
                            {props.t("Allergy")}
                          </label>
                          {isEditAllergy ? (
                            <Input
                              name="name"
                              type="text"
                              placeholder={allergies?.name}
                              disabled
                            />
                          ) : (
                            <AsyncSelect
                              isClearable={true}
                              placeholder={props.t("Search Allergy")}
                              name="name"
                              getOptionLabel={(e) => e.Name}
                              getOptionValue={(e) => e.Name}
                              className={`${Config.names.slugName}-custom-select-box`}
                              cacheOptions
                              defaultOptions
                              loadOptions={allergiesListOptions}
                              value={{
                                Name: allergies?.name,
                                Code: allergies?.code,
                                CodeType: allergies?.codeType,
                              }}
                              onChange={handleAllergyName}
                              required
                            />
                          )}
                        </div>
                      </Col>
                      <Col lg={6}></Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <AvField
                          name="reaction"
                          label={props.t(`Reaction`)}
                          placeholder={props.t(`Reaction`)}
                          type="textarea"
                          maxLength="250"
                          required
                          className="form-control"
                          value={
                            _singleAllergy && _singleAllergy.Reaction
                              ? _singleAllergy.Reaction
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          type="select"
                          name="reactionType"
                          id="reactionType"
                          placeholder={props.t(`Reaction Type`)}
                          label={props.t(`Reaction Type`)}
                          className="form-control"
                          required
                          value={
                            _singleAllergy && _singleAllergy.ReactionType
                              ? _singleAllergy.ReactionType
                              : ""
                          }
                        >
                          <option value="">{props.t("Select Reaction")}</option>
                          {Object.keys(allergyReactionTypes).map(
                            (key, index) => (
                              <option key={index} value={key}>
                                {allergyReactionTypes[key]}
                              </option>
                            )
                          )}
                        </AvField>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          type="select"
                          name="status"
                          id="statusType"
                          placeholder={props.t(`Status`)}
                          label={props.t(`Status`)}
                          className="form-control"
                          required
                          value={
                            _singleAllergy && _singleAllergy.StatusType
                              ? _singleAllergy.StatusType
                              : ""
                          }
                        >
                          <option value="">{props.t("Select Status")}</option>
                          {Object.keys(allergyStatusType).map((key, index) => (
                            <option key={index} value={key}>
                              {allergyStatusType[key]}
                            </option>
                          ))}
                        </AvField>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <div className="form-group mb-3">
                          <Label>{props.t(`On set Date`)}</Label>
                          <ReactDatePicker
                            className="form-control"
                            selected={onsetDateChange}
                            onChange={handleDateChange}
                            placeholderText="YYYY-MM-DD"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        {formErrorMsg ? dctAlert(3, formErrorMsg) : ""}
                        <div className="form-group text-right">
                          <button
                            type="button"
                            className="btn btn-secondary mr-2"
                            onClick={closeAllergyForm}
                          >
                            {props.t("Cancel")}
                          </button>

                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => {}}
                          >
                            <span className="dr-search-spinner d-none">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                            </span>{" "}
                            {props.t(`Save`)}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </div>
              </Collapse>
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(MedicationAllergy))
);
