import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import {
  CREATE_MEMBER,
  UPDATE_MEMBER,
  GET_MEMBER_LIST,
  GET_SINGLE_MEMBER,
  GET_MEMBER_AND_DEPENDENTS,
  GET_MEMBER_TAB_LIST,
  CREATE_DEPENDENT,
  UPDATE_DEPENDENT,
  CREATE_CONSULTATION,
  UPDATE_CONSULTATION,
  UPDATE_CONSULTATION_STEPWISE,
  GET_SINGLE_CONSULTATION,
  GET_DRAFT_CONSULTATION,
} from "./actionTypes";
import {
  memberActionSuccessful,
  setSingleMember,
  setMemberAndDependents,
  setMemberTabList,
  createMemberFailed,
  setMemberList,
  dependentActionSuccessful,
  createDependentFailed,
  setSingleConsultation,
  setConsultationMsg,
  setDraftConsultation,
} from "./actions";

//Include Both Helper File with needed methods
import {
  dctPostRawData,
  dctPutRawData,
  dctGetRawData,
  cloneMemebrDataToDependents,
} from "../../helpers/backend_helper";

import { dctClsAddRemove } from "../../helpers/Commons";
import { Config } from "../../Config";

// Create Consultation
function* createConsultationSaga({ payload: { consultation, history } }) {
  try {
    const consultationData = consultation;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctPostRawData,
      process.env.REACT_APP_API_PATH + "/consultation",
      consultationData,
      headers
    );
    if (response.id) {
      yield put(setSingleConsultation(response, ""));
      yield put(
        setConsultationMsg(
          "Patient details created successfully. Please complete the other setps.",
          1
        )
      );
      if (document.querySelector("#consultNextTab")) {
        document.querySelector("#consultNextTab").click();
      }
    } else {
      yield put(setSingleConsultation("", response.cause.message));
      yield put(setConsultationMsg(response.cause.message, 3));
    }
  } catch (error) {
    yield put(setSingleConsultation("", error));
    yield put(setConsultationMsg(error, 3));
  }
}

// Update Consultation Data Stepwise
function* updateConsultStepwiseSaga({
  payload: { consultation, history, step },
}) {
  try {
    const consultationData = consultation;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    //actionType = Step Name
    let url = `${process.env.REACT_APP_API_PATH}/consultation/${consultationData.id}/${consultationData.stepType}`;
    const response = yield call(dctPutRawData, url, consultationData, headers);

    if (response.id) {
      yield put(setSingleConsultation(response, ""));
      yield put(
        setConsultationMsg("Consultation data updated successfully.", 1)
      );
      if (document.querySelector("#consultNextTab")) {
        document.querySelector("#consultNextTab").click();
      }
      // history.push(
      //   `/member/consultation/${response.consultationType}/step/${step}`
      // );
    } else {
      yield put(setConsultationMsg(response.cause.message, 3));
    }
    dctClsAddRemove("dr-form-spinner", "d-none", "add");
  } catch (error) {
    yield put(setConsultationMsg(error, 3));
    dctClsAddRemove("dr-form-spinner", "d-none", "add");
  }
}

// Update Consultation
function* updateConsultationSaga({ payload: { consultation, history } }) {
  try {
    const consultationData = consultation;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    let url = `${process.env.REACT_APP_API_PATH}/consultation/${consultationData.id}`;
    if (consultationData._actionType === "cancelled") {
      url = `${process.env.REACT_APP_API_PATH}/consultation/${consultationData.id}/${consultationData._actionType}`;
    }

    const response = yield call(dctPutRawData, url, consultationData, headers);

    if (response.id) {
      yield put(setSingleConsultation(response, ""));
      yield put(
        setConsultationMsg("Consultation data updated successfully.", 1)
      );
      if (consultationData._actionType === "cancelled") {
        window.location.href = `${process.env.REACT_APP_HOST_URL}/member/schedule-center`;
        yield put(
          setConsultationMsg(
            "Your Consultation has been cancelled successfully",
            2
          )
        );
      }
    } else {
      yield put(setSingleConsultation("", response.cause.message));
      yield put(setConsultationMsg(response.cause.message, 3));
    }
  } catch (error) {
    yield put(setSingleConsultation("", error));
    yield put(setConsultationMsg(error, 3));
  }
}

// Get Draft Consultation
function* getDraftConsultationSaga({ payload: { memberId } }) {
  try {
    const member_id = memberId;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctGetRawData,
      process.env.REACT_APP_API_PATH +
        "/member/" +
        member_id +
        "/consultation?status=draft&sort=createdAt DESC&limit=1",
      headers
    );
    if (response) {
      yield put(setSingleConsultation(response[0], ""));
      yield put(setDraftConsultation(response[0], ""));
    } else {
      yield put(setDraftConsultation("", "No Draft consultation found."));
    }
  } catch (error) {
    yield put(setDraftConsultation("", error));
    yield put(setConsultationMsg(error, 3));
  }
}

// Get Single Consultation
function* getSingleConsultationSaga({ payload: { consultId } }) {
  try {
    const consult_id = consultId;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctGetRawData,
      process.env.REACT_APP_API_PATH + "/consultation/" + consult_id,
      headers
    );
    if (response.id) {
      yield put(setSingleConsultation(response, ""));
      if (response.status === "draft")
        yield put(setDraftConsultation(response[0], ""));
    } else {
      yield put(setSingleConsultation("", "No Record found."));
      yield put(setConsultationMsg(response.cause.message, 3));
    }
  } catch (error) {
    yield put(setSingleConsultation("", error));
    yield put(setConsultationMsg(error, 3));
  }
}

// Create Member
function* createMemberSaga({ payload: { member, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true

  try {
    const member_data = member;

    let bearer_token = localStorage.getItem("auth_token");

    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPostRawData,
      process.env.REACT_APP_API_PATH + "/member",
      member_data,
      headers
    );

    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false

    if (response.id) {
      yield put(
        memberActionSuccessful(response, "Member Created Successfully.", 1)
      );
      yield put(setSingleMember(response, "", 1));
      if (document.querySelector(`.${Config.names.slugName}-member-add-edit-modal .close`)) {
        document.querySelector(`.${Config.names.slugName}-member-add-edit-modal .close`).click();
      }
    } else {
      yield put(createMemberFailed(response.cause.message));
    }
  } catch (error) {
    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    yield put(createMemberFailed(error));
  }
}

// Update Member
function* updateMemberSaga({ payload: { member, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true

  try {
    const member_data = member;

    let bearer_token = localStorage.getItem("auth_token");

    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctPutRawData,
      process.env.REACT_APP_API_PATH + "/member/" + member_data.id,
      member_data,
      headers
    );
    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.add("d-none");
    // document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
    document.getElementsByClassName('btn-primary')[0].disabled = false


    if (response.id) {
      yield put(
        memberActionSuccessful(response, "Member Updated Successfully.", 2)
      );
      yield put(setSingleMember(response, "", 2));

      if (document.querySelector(`.${Config.names.slugName}-member-add-edit-modal .close`)) {
        document.querySelector(`.${Config.names.slugName}-member-add-edit-modal .close`).click();
      }
    } else {
      yield put(createMemberFailed(response.message));
    }
  } catch (error) {
    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    yield put(createMemberFailed(error));
  }
}

// Get Member
function* getMemberListSaga({ payload: { member } }) {
  try {
    const member_data = member;

    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctGetRawData,
      process.env.REACT_APP_API_PATH + "/member/",
      headers
    );

    if (response.id) {
      yield put(
        setMemberList(
          response.result.data,
          "Member data fetched Successfully.",
          ""
        )
      );
    } else {
      yield put(createMemberFailed(response.message));
    }
  } catch (error) {
    yield put(createMemberFailed(error));
  }
}

// Get Single Member
function* getSingleMemberSaga({ payload: { memberId } }) {
  try {
    const member_id = memberId;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctGetRawData,
      `${process.env.REACT_APP_API_PATH}/member/${member_id}?populate=dependent,userId,partnerId,companyId`,
      headers
    );
    if (response.id) {
      yield put(
        setSingleMember(response, "Member data fetched Successfully.", "")
      );
    } else {
      yield put(setSingleMember("", "No Member found.", ""));
    }
  } catch (error) {
    yield put(setSingleMember("", error, ""));
  }
}

// Get Member Tab List
function* getMemberTabListSaga({ payload: { memberId } }) {
  try {
    const member_id = memberId;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctGetRawData,
      process.env.REACT_APP_API_PATH + "/member/" + member_id + "/tab-list",
      headers
    );
    if (response[0].id) {
      yield put(
        setMemberTabList(response, "Member Tab List fetched Successfully.", "")
      );
    } else {
      yield put(setMemberTabList("", "No record found.", ""));
    }
  } catch (error) {
    yield put(setMemberTabList("", error, ""));
  }
}

// Get Member and Dependents
function* getMemberAndDependentsSaga({ payload: { memberId } }) {
  try {
    const member_id = memberId;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctGetRawData,
      process.env.REACT_APP_API_PATH + "/member/" + member_id + "/dependents",
      headers
    );
    if (response.id) {
      const data = cloneMemebrDataToDependents(response);
      yield put(setMemberAndDependents(data, "", ""));
    } else {
      yield put(setMemberAndDependents("", "No record found.", ""));
    }
  } catch (error) {
    yield put(setSingleMember("", error, ""));
  }
}

const memerAndDependentsListAPI = (member_id) => {
  return (
    process.env.REACT_APP_API_PATH + "/member/" + member_id + "/dependents"
  );
};

// Create Dependent
function* createDependentSaga({ payload: { dependent, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true

  try {
    const dependent_data = dependent;

    let bearer_token = localStorage.getItem("auth_token");

    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPostRawData,
      process.env.REACT_APP_API_PATH + "/dependent",
      dependent_data,
      headers
    );

    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false

    if (response.id) {
      yield put(
        dependentActionSuccessful(
          response,
          "Dependent Created Successfully.",
          1
        )
      );

      // Get All the member and dependent list
      const memebrAndDependents = yield call(
        dctGetRawData,
        memerAndDependentsListAPI(response.memberId.id),
        headers
      );
      if (memebrAndDependents.id) {
        yield put(
          setMemberAndDependents(
            memebrAndDependents,
            "Dependent Created Successfully.",
            ""
          )
        );
      } else {
        yield put(setMemberAndDependents("", "No Record found.", ""));
      }
    } else {
      yield put(createDependentFailed(response.cause.message));
    }
  } catch (error) {
    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    yield put(createDependentFailed(error));
  }
}

// Update Dependent
function* updateDependentSaga({ payload: { dependent, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
  try {
    const dependent_data = dependent;

    let bearer_token = localStorage.getItem("auth_token");

    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctPutRawData,
      process.env.REACT_APP_API_PATH + "/dependent/" + dependent_data.id,
      dependent_data,
      headers
    );

    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    if (response.id) {
      yield put(
        dependentActionSuccessful(
          response,
          "Dependent Updated Successfully.",
          2
        )
      );
      // Get All the member and dependent list
      const memebrAndDependents = yield call(
        dctGetRawData,
        memerAndDependentsListAPI(response.memberId.id),
        headers
      );
      if (memebrAndDependents.id) {
        yield put(
          setMemberAndDependents(
            memebrAndDependents,
            "Dependent Updated Successfully.",
            ""
          )
        );
      } else {
        yield put(setMemberAndDependents("", "No Record found.", ""));
      }
    } else {
      yield put(createDependentFailed(response.message));
    }
  } catch (error) {
    yield put(createDependentFailed(error));
  }
}

export function* watchCreateMember() {
  yield takeEvery(CREATE_MEMBER, createMemberSaga);
}

export function* watchUpdateMember() {
  yield takeEvery(UPDATE_MEMBER, updateMemberSaga);
}
export function* watchGetMember() {
  yield takeEvery(GET_MEMBER_LIST, getMemberListSaga);
}
export function* watchGetSingleMember() {
  yield takeEvery(GET_SINGLE_MEMBER, getSingleMemberSaga);
}
export function* watchGetMemberAndDependents() {
  yield takeEvery(GET_MEMBER_AND_DEPENDENTS, getMemberAndDependentsSaga);
}

export function* watchGetMemberTabList() {
  yield takeEvery(GET_MEMBER_TAB_LIST, getMemberTabListSaga);
}

export function* watchCreateDependent() {
  yield takeEvery(CREATE_DEPENDENT, createDependentSaga);
}

export function* watchUpdateDependent() {
  yield takeEvery(UPDATE_DEPENDENT, updateDependentSaga);
}

export function* watchCreateConsultation() {
  yield takeEvery(CREATE_CONSULTATION, createConsultationSaga);
}

export function* watchUpdateConsultation() {
  yield takeEvery(UPDATE_CONSULTATION, updateConsultationSaga);
}
export function* watchUpdateConsultStepwise() {
  yield takeEvery(UPDATE_CONSULTATION_STEPWISE, updateConsultStepwiseSaga);
}

export function* watchGetDraftConsultation() {
  yield takeEvery(GET_DRAFT_CONSULTATION, getDraftConsultationSaga);
}
export function* watchGetSingleConsultation() {
  yield takeEvery(GET_SINGLE_CONSULTATION, getSingleConsultationSaga);
}

function* memberSaga() {
  yield all([
    fork(watchCreateMember),
    fork(watchUpdateMember),
    fork(watchGetMember),
    fork(watchGetSingleMember),
    fork(watchGetMemberAndDependents),
    fork(watchGetMemberTabList),

    fork(watchCreateDependent),
    fork(watchUpdateDependent),

    fork(watchCreateConsultation),
    fork(watchUpdateConsultation),
    fork(watchUpdateConsultStepwise),
    fork(watchGetDraftConsultation),
    fork(watchGetSingleConsultation),
  ]);
}

export default memberSaga;
