import React, { useState, useEffect } from "react";

//Style from Reactstrap
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

import {
  getPostMessage,
  postMessageActionSuccessful,
  setPostMessageListing,
  setSinglePostMessage,
  getSinglePostMessage,
} from "../../store/actions";

//Import PostMessageList
import PostMessageList from "../../components/PostMessage/PostMessageList";
import PostMessageChatWindow from "./PostMessageChatWindow";

import axios from "axios";
import DctHeroBanner from "../../components/Common/DctHeroBanner";
import { DctIcon } from "../../components/Common/Icons/DctIcon";
import { Config } from "../../Config";

const PostMessage = (props) => {
  const [postsPerPage, setPostsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [isCommentData, setIsCommentData] = useState(false);
  const [openPostListPanel, setOpenPostListPanel] = useState(true);
  const [openCommentWindow, setOpenCommentWindow] = useState(true);
  const [composeMessageButton, setComposeMessageButton] = useState(true);

  const [pmdata, setGData] = useState({
    loading: true,
    postMessages: null,
    message: null,
  });

  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    postMessageSearchParam: "",
    type: "filter",
    reset: false,
  });

  const { authMemberId } = useSelector((state) => state.Login);

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("search");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        postMessageSearchParam: msearch,
        type: "filter",
      };

      setSearchFormDetails({
        reset: false,
      });
      return fetchPostMessageData(searchParam, selectedPage);
    }
    fetchPostMessageData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("search");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        postMessageSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      return fetchPostMessageData(searchParam, selectedPage);
    }
    // if(selectedPage > 1)
    fetchPostMessageData(searchParam, selectedPage);
  };

  const fetchPostMessageData = (param, currentPage = 1) => {
    let searchParam = "";
    if (param) {
      searchParam = param.postMessageSearchParam
        ? "&q=" + param.postMessageSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    let url =
      process.env.REACT_APP_API_PATH +
      `/member/${authMemberId}/posts?_dc=` + ((Math.random() * Math.pow(10, 17)).toString(35)) + `&page=${currentPage}&limit=${postsPerPage}` +
      searchParam;
      

    axios
      .get(url, headers)
      .then(function (response) {
        const data = response.data;
        let message = "";
        message = data.data && data.data.length > 0 ? "" : "No record found.";
        dispatch(setPostMessageListing(data.data, message));
        setGData({
          loading: false,
          postMessages: data.data,
          message: message,
        });
        setTotalPosts(Math.ceil(data.total / postsPerPage));
        setCurrentPage(currentPage - 1);
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = err.response.data.cause.details;
          }
        }
        setGData({
          loading: false,
          postMessages: null,
          message: message,
        });
        setTotalPosts(0);
        setCurrentPage(0);
        dispatch(setPostMessageListing(null, message));

        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage("LOGOUT_NATIVE");
        }
      })
      .then(function () {});
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (window.ReactNativeWebView) {
      setComposeMessageButton(false);
    }
    let urlParams = new URL(document.location).searchParams;
    let search = urlParams.get("search");
    let searchParam = "";
    if (search) {
      searchParam = {
        postMessageSearchParam: search,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      fetchPostMessageData(searchParam);
    }
  }, []);

  // Apply PostMessage Search
  function handleSearch(e) {
    e.preventDefault();

    fetchPostMessageData(searchFormDetails);
    setSearchFormDetails({
      reset: true,
    });
    let param = searchFormDetails.postMessageSearchParam
      ? searchFormDetails.postMessageSearchParam
      : "";
    props.history.push("/member/ask-doctor?search=" + param);
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    fetchPostMessageData();
    setSearchFormDetails({
      reset: false,
    });
    document.getElementById("postMessage-search-form").reset();
    props.history.push("/member/ask-doctor");
  }

  const { loading, postMessages, message } = pmdata;
  const postMessage_action = useSelector(
    (state) => state.PostMessage.action_type
  );

  const openPostMessageCreateModal = () => {
    dispatch(setSinglePostMessage(null, "", ""));
    props.history.push(`/member/ask-doctor/create`);
  };

  const stopNativeLoader = () => {
    window.ReactNativeWebView.postMessage("STOP_NATIVE_LOADER");
  };

  return (
    <React.Fragment>
      {window.ReactNativeWebView ? stopNativeLoader() : ""}
      <div className="page-content">
        <Container fluid>
          {window.ReactNativeWebView ? (
            ""
          ) : (
            <Row>
              <Col xl={12}>
                <DctHeroBanner
                  mainTitle="Message A Doctor"
                  subTitle="Secure, Confidential Advice"
                  isInfoBar={true}
                  infoBarTitle=""
                  isCall911={true}
                ></DctHeroBanner>
              </Col>
            </Row>
          )}
          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                {/* Chat list panel Start */}

                <div
                  className={
                    openPostListPanel
                      ? "chat-leftsidebar mr-lg-4 mb-4 true"
                      : "chat-leftsidebar mr-lg-4 mb-4 false d-none d-sm-block"
                  }
                >
                  {composeMessageButton && (
                    <div className={`border-bottom ${Config.names.slugName}-sm--12`}>
                      <div className="mb-2 pb-2 text-lg-left text-center text-md-left">
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={() => {
                            openPostMessageCreateModal();
                          }}
                        >
                          <i className="mdi mdi-plus align-middle mr-2"></i>
                          {props.t(`Compose Message`)}
                        </Link>
                      </div>
                    </div>
                  )}

                  <div className="search-box chat-search-box py-4">
                    <div className="position-relative">
                      <Form
                        onSubmit={handleSearch}
                        id="postMessage-search-form"
                        className={`mb-2 ${Config.names.slugName}-search-form`}
                      >
                        <FormGroup className="mr-sm-2 mb-sm-0 mb-0">
                          <Input
                            type="text"
                            name="postMessageSearchParam"
                            placeholder={props.t(`Search...`)}
                            onChange={(e) => {
                              let { value } = e.target;
                              setSearchFormDetails((prevState) => ({
                                ...prevState,
                                postMessageSearchParam: value,
                              }));
                            }}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                          {searchFormDetails.reset ? (
                            <span
                              className={`${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-float-reset-search text-muted`}
                              onClick={resetSearch}
                            >
                              <i className="mdi mdi-reload font-size-22 align-middle mr-2"></i>
                            </span>
                          ) : null}
                        </FormGroup>
                      </Form>
                    </div>
                  </div>

                  <h5 className="font-size-14 mb-3">{props.t(`Recent`)}</h5>
                  <PostMessageList
                    {...props}
                    postMessageListMessage={message}
                    total={totalPosts}
                    getDataOnEnter={getDataOnEnter}
                    getUserData={getUserData}
                    currentPage={currentPage}
                    perPage={postsPerPage}
                    ajaxLoader={loading}
                    isCommentData={setIsCommentData}
                    openPostListPanel={setOpenPostListPanel}
                    openCommentWindow={setOpenCommentWindow}
                  ></PostMessageList>
                </div>
                {/* Chat list panel end */}

                <div
                  className={
                    openCommentWindow
                      ? "w-100 user-chat true"
                      : "w-100 user-chat false d-none d-sm-block"
                  }
                >
                  {isCommentData && isCommentData.id ? (
                    <PostMessageChatWindow
                      setPostData={isCommentData}
                      isCommentData={setIsCommentData}
                      openPostListPanel={setOpenPostListPanel}
                      openCommentWindow={setOpenCommentWindow}
                      isItemOpen={true}
                    ></PostMessageChatWindow>
                  ) : (
                    <div className="text-center text-center d-flex card align-items-center justify-content-center h-100 p-4">
                      {composeMessageButton && (
                        <div>
                          <div className="p-4">
                            <DctIcon
                              name={`${Config.names.slugName}-message-icon`}
                              size="50"
                            ></DctIcon>
                          </div>

                          <h4 className="font-weight-normal">
                            {props.t(`Start Discussion.`)}
                          </h4>

                          <hr className="mt-1" />
                          <p className="font-size-12 text-muted">
                            {props.t(
                              `Secure & Confidential . Upload test results or images`
                            )}
                          </p>
                          <div className="text-center mb-2 pb-2">
                            <Link
                              to="#"
                              className="btn btn-primary"
                              onClick={() => {
                                openPostMessageCreateModal();
                              }}
                            >
                              <i className="mdi mdi-plus align-middle mr-2"></i>
                              {props.t(`Compose Message`)}
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <div className="mb-4"></div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { postMessage } = state.PostMessage;
  const { singlePostMessage } = state.PostMessage;
  const { message } = state.PostMessage;
  return { postMessage, message, singlePostMessage };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPostMessage,
    postMessageActionSuccessful,
    setSinglePostMessage,
    getSinglePostMessage,
  })(withNamespaces()(PostMessage))
);
