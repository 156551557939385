import {
  CREATE_BRAND,
  UPDATE_BRAND,
  GET_BRAND,
  GET_BRAND_LIST,
  SET_BRAND_LIST,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_FAILED,
  API_ERROR,
  SET_SINGLE_BRAND,
  SET_BRAND_LISTING,
} from "./actionTypes";

const initialState = {
  createBrandError: null,
  message: null,
  loading: false,
  action_type: null,
};

const brand = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BRAND:
      state = {
        ...state,
        brand: action.payload,
        loading: true,
        createBrandError: null,
      };
      break;
    case UPDATE_BRAND:
      state = {
        ...state,
        brand: action.payload,
        loading: true,
        createBrandError: null,
      };
      break;
    case GET_BRAND:
      state = {
        ...state,
        brand: action.payload.result,
        loading: true,
        createBrandError: null,
      };
      break;
    case GET_BRAND_LIST:
      state = {
        ...state,
        brand: action.payload.result,
        loading: true,
        createBrandError: null,
      };
      break;
    case SET_SINGLE_BRAND:
      state = {
        ...state,
        brand: null,
        singleBrand: action.payload,
        loading: false,
        createBrandError: null,
        action_type: action.action_type,
      };
      break;
    case SET_BRAND_LIST:
      state = {
        ...state,
        loading: false,
        brand: action.payload,
        message: action.message,
        createBrandError: null,
        action_type: action.action_type,
      };
      break;
    case SET_BRAND_LISTING:
      state = {
        ...state,
        loading: false,
        brand: null,
        brandListing: action.payload,
        message: action.message,
        createBrandError: null,
      };
      break;
    case CREATE_BRAND_SUCCESS:
      state = {
        ...state,
        loading: false,
        brand: action.payload,
        message: action.message,
        createBrandError: null,
        action_type: action.action_type,
      };
      break;
    case CREATE_BRAND_FAILED:
      state = {
        ...state,
        brand: null,
        createBrandError: action.payload,
        loading: false,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default brand;
