import React, { useState, useEffect } from "react";
import { Row, Col, Container, Progress } from "reactstrap";
import Dropzone from "react-dropzone";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import DctHeroBanner from "../../components/Common/DctHeroBanner";

import {
  createPostMessage,
  updatePostMessage,
  createPostMessageFailed,
  // postMessageActionSuccessful,
  setSinglePostMessage,
  getSinglePostMessage,
} from "../../store/actions";
import { dctAlert, dctDefaultThumb } from "../../helpers/Commons";
import { Config } from "../../Config";

const CreateUpdatePostMessage = (props) => {
  // const [modal, setmodal] = useState(false);
  // const [msgEnable, setmsgEnable] = useState(true);
  const [pageReidecct, setPageReidecct] = useState(true);

  const [textareabadge, settextareabadge] = useState(0);
  const [textcount, settextcount] = useState(0);

  const [selectedFiles, setselectedFiles] = useState([]);
  const [uploadPercent, setuploadPercent] = useState({ percent: 0 });
  const dispatch = useDispatch();
  const { authMemberId } = useSelector((state) => state.Login);

  useEffect(() => {
    let post_id = "";
    let check_edit = false;
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      post_id = split_url ? split_url[split_url.length - 1] : "";
      check_edit = split_url ? split_url[split_url.length - 3] : "";
    }

    if (check_edit === "edit" && post_id) {
      props.getSinglePostMessage(post_id);
    }
  }, []);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleQuestionField = (event) => {
    let count = event.target.value.length;
    let text = event.target.value;
    if (count > 0) {
      settextareabadge(true);
    } else {
      settextareabadge(false);
    }
    settextcount(count);
  };

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-form-spinner")[0]
      .classList.remove("d-none");
    values.doc = selectedFiles;
    //call reducer method
    if (values.actionType === "edit") {
      props.updatePostMessage(values, props.history);
    } else {
      props.createPostMessage(values, props.history);
    }
  }

  let postMessageData = useSelector(
    (state) => state.PostMessage.singlePostMessage
  );
  postMessageData = postMessageData ? postMessageData : [];

  let postData = useSelector((state) => state.PostMessage);

  if (postData && postData.redirect && pageReidecct) {
    setTimeout(function () {
      setPageReidecct(false);
      props.history.push("/member/ask-doctor");
    }, 3000);
  }
  const { percent } = uploadPercent;

  let action_type = postMessageData && postMessageData.id ? "edit" : "create";
  let button_label =
    postMessageData && postMessageData.id ? "Update" : "Create";

  const dctDefaultSrc = (e) => {
    e.target.src = dctDefaultThumb;
  };
  const renderDocThumb = (data) => {
    if (!data.docUrl) return;
    if (data.docType === "application/pdf") {
      return (
        <h2 className={`${Config.names.slugName}-upload-preview-icon mb-0`}>
          <a href={data.docUrl} download>
            <i className="bx bxs-file-pdf mr-1"></i>
          </a>
        </h2>
      );
    } else {
      return (
        <div className="mb-3">
          <img
            // onError={dctDefaultSrc}
            data-dz-thumbnail=""
            height="100"
            className="avatar-sm rounded bg-light"
            src={data.docUrl}
          />
        </div>
      );
    }
  };

  const stopNativeLoader = () => {
    window.ReactNativeWebView.postMessage("STOP_NATIVE_LOADER");
  };

  return (
    <React.Fragment>
      {window.ReactNativeWebView ? stopNativeLoader() : ""}
      <div className="page-content">
        <Container fluid>
          {window.ReactNativeWebView ? (
            ""
          ) : (<>
            <Row>
              <Col xl={12}>
                <DctHeroBanner
                  mainTitle={props.t("Message A Doctor")}
                  subTitle={props.t("Secure, Confidential Advice")}
                  isInfoBar={true}
                  infoBarTitle=""
                  isCall911={true}
                ></DctHeroBanner>
              </Col>
            </Row>
            <Row className="justify-content-between">
              <Col xl="8"></Col>
              <Col xl="4">
                <div className="text-right">
                  <Link to="/member/ask-doctor" className="btn btn-secondary">
                    {props.t(`Back to List`)}
                  </Link>
                </div>
              </Col>
            </Row>
            </>
          )}


          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v);
            }}
          >
            {postData.message ? dctAlert(1, postData.message) : null}

            {postData.createPostMessageError
              ? dctAlert(
                  3,
                  postData.createPostMessageError &&
                    postData.createPostMessageError.length > 0
                    ? postData.createPostMessageError
                    : null
                )
              : null}

            <AvField
              name="id"
              type="hidden"
              value={
                postMessageData.id != undefined
                  ? postMessageData.id
                  : postMessageData._id
              }
              readOnly={true}
            />

            <AvField name="actionType" type="hidden" value={action_type} />
            <AvField name="memberId" type="hidden" value={authMemberId} />

            <Row>
              <Col lg={12}>
                <div className="form-postMessage">
                  <AvField
                    name="question"
                    label={props.t("What is your question?")}
                    className="form-control"
                    placeholder={props.t("What is your question?")}
                    type="textarea"
                    onChange={handleQuestionField}
                    required
                    value={postMessageData.question}
                  />
                  {textareabadge ? (
                    <span className="badgecount badge badge-warning mb-2">
                      {" "}
                      {textcount} / 225{" "}
                    </span>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="form-postMessage">
                  <AvField
                    name="details"
                    label={props.t("Details")}
                    className="form-control"
                    placeholder={props.t("Details")}
                    type="textarea"
                    required
                    value={postMessageData.details}
                  />

                  {renderDocThumb(postMessageData)}
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Dropzone
                  accept={process.env.REACT_APP_ACCEPTED_IMG_FILES}
                  maxSize={parseInt(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE)}
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                        </div>
                        <h4>
                          {props.t("Drop files here or click to upload")}.
                        </h4>
                        <div className="font-size-14 text-danger">
                          {props.t(
                            "Only *.jpeg, *.jpg, *.png and *.gif files will be accepted."
                          )}
                        </div>
                        <p className="font-size-14 text-danger">
                          {props.t("Max file 20MB.")}
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="mt-3 d-none">
                  <Progress color="primary" value={percent}>
                    {percent}%
                  </Progress>
                </div>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFiles.map((f, i) => {
                    return (
                      <div
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              {f.type === "text/csv" ? (
                                <h2>
                                  <i className="bx bx bx-file mr-1"></i>
                                </h2>
                              ) : (
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              )}
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="modal-footer">
                  <Link to="#" className="btn btn-secondary" onClick={()=>{
                    if(window.ReactNativeWebView) {
                      window.ReactNativeWebView.postMessage("GO_TO_MESSAGE");
                    }
                    props.history.goBack();
                  }}>
                    {props.t(`Cancel`)}
                  </Link>

                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >
                    <span className="dr-form-spinner d-none">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    </span>{" "}
                    {props.t(`${button_label}`)}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createPostMessageError } = state.PostMessage;
  const { postMessage } = state.PostMessage;
  const { message } = state.PostMessage;
  return { createPostMessageError, postMessage, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createPostMessage,
    updatePostMessage,
    createPostMessageFailed,
    setSinglePostMessage,
    getSinglePostMessage,
  })(withNamespaces()(CreateUpdatePostMessage))
);
