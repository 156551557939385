import React, { useState, useEffect } from "react";

//Style from Reactstrap
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Dropdown,
  Label,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Select from "react-select";
import AsyncSelect from "react-select/async";
//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

import {
  getrxPharmacy,
  rxPharmacyActionSuccessful,
  setrxPrescriptionListing,
  setSinglerxPharmacy,
} from "../../store/actions";

import ArchiveList from "../../components/rxPharmacy/ArchiveList";

//Import Create and Update partner
import CreateUpdatePharmacy from "../../components/rxPharmacy/CreateUpdatePharmacy";

import axios from "axios";
import { Config } from "../../Config";
import { capitalizeWords } from "../../helpers/Commons";

const RxArchive = (props) => {
  const [postsPerPage, setPostsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);

  const [gdata, setGData] = useState({
    loading: true,
    pharmacy: {},
    message: null,
  });

  const [editState, setEditState] = useState({});

  const [dropdownValue, setdropdownValue] = useState("");

  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    partnerSearchParam: "",
    type: "filter",
    reset: false,
  });

  const [pData, setPdata] = useState([])

  const [className, setClassName] = useState('');

  let toggaleValue = [
    {
      options: [
        { label: "All", value: "all" },
        { label: "Rejected", value: "rejected" },
        { label: "Accepted", value: "accepted" },
        { label: "Pending", value: "pending" },
      ],
    },
  ];

  const getPharmacyList = () => {
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        SecretKey: props.secretkey,
      },
    };

    let url = process.env.REACT_APP_API_PATH + `/rxpharmacy?populate=userId`;

    axios
      .get(url, headers)
      .then((response) => {
        // console.log(response.data)
        setPdata(response?.data?.data)
        // setLeftToggleValue(response.data.data)
      })
      .catch((err) => {
        console.log(err);

      })


  }

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("csearch");
    let filterByPharmacy = urlParams.get("pharmacy");

    let searchParam = "";
    if (msearch) {
      let searchParam = {
        // partnerSearchParam: msearch,
        leftSearch: filterByPharmacy ? filterByPharmacy : 'all', 
        rightSearch: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        partnerSearchParam: "",
        type: "filter",
        reset: false,
      });
      return fetchrxPharmacyData(searchParam, selectedPage);
    }
    fetchrxPharmacyData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("csearch");
    let filterByPharmacy = urlParams.get("pharmacy");

    let searchParam = "";
    if (msearch) {
      let searchParam = {
        // partnerSearchParam: msearch,
        leftSearch: filterByPharmacy ? filterByPharmacy : 'all', 
        rightSearch: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        partnerSearchParam: "",
        type: "filter",
        reset: true,
      });
      return fetchrxPharmacyData(searchParam, selectedPage);
    }

    fetchrxPharmacyData(searchParam, selectedPage);
  };

  const fetchrxPharmacyData = (param, currentPage = 1) => {
    let searchParam = "";
    if (param) {
      if (param.partnerSearchParam) searchParam = "&q=" + param.partnerSearchParam;

      if (param.leftSearch && param.rightSearch) {

        if(param.leftSearch !== 'all' && param.rightSearch === "all")  searchParam = "&filterByPharmacy=" + param.leftSearch;

        if(param.leftSearch === 'all' && param.rightSearch !== "all")  searchParam = "&q=" + param.rightSearch;

        if (param.leftSearch !== "all" && param.rightSearch !== "all") searchParam = "&filterByPharmacy=" + param.leftSearch + "&q=" + param.rightSearch;

      }
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        SecretKey: props.secretkey,
      },
    };
    setGData({
      loading: true,
    });
    let url =
      process.env.REACT_APP_API_PATH +
      `/prescriptionfill` +
      `?page=${currentPage}&limit=${postsPerPage}&archive=true` +
      searchParam;

    axios
      .get(url, headers)
      .then(function (response) {
        const data = response.data;
        let message = "";
        message = data?.data.length > 0 ? "" : "No record found.";
        dispatch(setrxPrescriptionListing(data?.data, message));
        setGData({
          loading: false,
          pharmacy: response.data.data,
          message: message,
        });

        setTotalPosts(data.total / postsPerPage);
        setCurrentPage(currentPage - 1);
        if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
          document
            .getElementsByClassName("dr-csearch-spinner")[0]
            .classList.add("d-none");
        }
      })
      .catch(function (err) {
        console.log(err);
        if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
          document
            .getElementsByClassName("dr-csearch-spinner")[0]
            .classList.add("d-none");
        }
        var message = checkAjaxError(err);
        setGData({
          loading: false,
          phamacy: null,
          message: message,
        });
        setTotalPosts(0);
        setCurrentPage(0);
        dispatch(setrxPrescriptionListing(null, message));
      })
      .then(function () {});
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let csearch = urlParams.get("csearch");
    let filterByPharmacy = urlParams.get("pharmacy");

    let searchParam = "";
    if (csearch) {
      let searchParam = {
        // partnerSearchParam: csearch,
        leftSearch: filterByPharmacy ? filterByPharmacy : 'all', 
        rightSearch: csearch,
        type: "filter",
      };
      setSearchFormDetails({
        partnerSearchParam: "",
        type: "filter",
        reset: true,
      });
      fetchrxPharmacyData(searchParam);
    } else {
      // fetchrxPharmacyData(searchParam);
    }
    getPharmacyList();
  }, []);

  // Apply Partner Search
  function handleSearch(e) {
    setdropdownValue("");
    e.preventDefault();
    if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
      document
        .getElementsByClassName("dr-csearch-spinner")[0]
        .classList.remove("d-none");
    }
    fetchrxPharmacyData(searchFormDetails);
    setSearchFormDetails({
      partnerSearchParam: searchFormDetails.partnerSearchParam,
      type: "filter",
      reset: true,
    });
    let param = searchFormDetails.partnerSearchParam
      ? searchFormDetails.partnerSearchParam
      : "";

    if (props.propsHistoryPush) {
      props.history.push(`${props.propsHistoryPush}?csearch=${param}`);
    } else {
      props.history.push("/rxarchive?csearch=" + param);
    }
  }

  const handaleTogglesearch = () => {

    let left = document.getElementById('leftToggle')?.value;
    let right = document.getElementById('rightToggle').value;

    
    if(left === 'all' && right === 'all') {
      fetchrxPharmacyData();
    }
    else if(left !== 'all' && right === 'all'){
      fetchrxPharmacyData({ leftSearch: left, rightSearch: 'all' });
    }
    else if(left === 'all' && right !== 'all'){
      fetchrxPharmacyData({ leftSearch: 'all', rightSearch: right });
    }
    else if(left !== 'all' && right !== 'all'){
      fetchrxPharmacyData({ leftSearch: left, rightSearch: right });
    }

    setSearchFormDetails({
      partnerSearchParam: "",
      type: "filter",
      reset: false,
    });
    document.getElementById("partner-search-form").reset();

    if (props.propsHistoryPush) {
      props.history.push(`${props.propsHistoryPush}`);
    } else {
      props.history.push("/rxarchive?csearch=" + right + '&pharmacy=' + left);
    }

    // if (props.propsHistoryPush) {
    //   props.history.push(`${props.propsHistoryPush}`);
    // } else {
    //   props.history.push("/rxprescriptionlist");
    // }

    // if (val == "all") {
    //   fetchrxPharmacyData();
    //   setSearchFormDetails({
    //     partnerSearchParam: "",
    //     type: "filter",
    //     reset: false,
    //   });
    //   document.getElementById("partner-search-form").reset();

    //   if (props.propsHistoryPush) {
    //     props.history.push(`${props.propsHistoryPush}`);
    //   } else {
    //     props.history.push("/rxprescriptionlist");
    //   }
    // } else {
    //   const searchvalue = { partnerSearchParam: val };
    //   fetchrxPharmacyData(searchvalue);

    //   if (props.propsHistoryPush) {
    //     props.history.push(`${props.propsHistoryPush}?csearch=${val}`);
    //   } else {
    //     props.history.push("/rxprescriptionlist?csearch=" + val);
    //   }
    // }
  };

  // Reset Search
  function resetSearch(e) {
    setdropdownValue("");
    e.preventDefault();
    fetchrxPharmacyData();
    setSearchFormDetails({
      partnerSearchParam: "",
      type: "filter",
      reset: false,
    });
    document.getElementById("partner-search-form").reset();

    if (props.propsHistoryPush) {
      props.history.push(`${props.propsHistoryPush}`);
    } else {
      props.history.push("/rxarchive");
    }
  }

  const { loading, partners, message } = gdata;
  const partner_action = useSelector((state) => state.Partner.action_type);

  const propsUpdatePartnerData = () => {
    if (!partners && !props.singlePartner) {
      return;
    }
    if (partners && props.singlePartner) {
      let partnerid = props.singlePartner.id;
      //Update record
      if (partner_action === 2) {
        var index = partners.data.findIndex((x) => x.id === partnerid);
        partners.data[index] = props.singlePartner;
        dispatch(setrxPrescriptionListing(partners.data, ""));
      }

      //Insert new record
      if (partner_action === 1) {
        partners.data.unshift(props.singlePartner);
        dispatch(setrxPrescriptionListing(partners.data, ""));
      }

      let activeRow = document.querySelectorAll(`[data-row-id="${partnerid}"]`);
      if (activeRow[0]) {
        activeRow[0].scrollIntoView({ behavior: "smooth" });
        activeRow[0].classList.add("active");
        const timer = setTimeout(() => {
          activeRow[0].classList.remove("active");
        }, 3000);
        return () => clearTimeout(timer);
      }
      setGData({
        loading: false,
        partners: partners,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Row>
            <Col lg="6">
              <Form
                inline
                onSubmit={handleSearch}
                id="partner-search-form"
                className={`mb-2 ${Config.names.slugName}-search-form`}
              >
                <FormGroup className="mr-sm-2 mb-sm-0 mb-0">
                  <Input
                    type="text"
                    name="partnerSearchParam"
                    style={{ minWidth: "250px" }}
                    placeholder={props.t("Search...")}
                    value={searchFormDetails.partnerSearchParam}
                    onChange={(e) => {
                      let { value } = e.target;
                      setSearchFormDetails((prevState) => ({
                        ...prevState,
                        partnerSearchParam: value,
                      }));
                    }}
                  />
                </FormGroup>
                <Button onClick={handleSearch}>
                  <span className="dr-csearch-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                  {props.t("Search")}
                </Button>
                {searchFormDetails.reset ? (
                  <span
                    className={`ml-2 ${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-reset-search`}
                    onClick={resetSearch}
                  >
                    <i className="bx bx-reset font-size-22 align-middle mr-2"></i>
                  </span>
                ) : (
                  ""
                )}
              </Form>
            </Col>
            <Col lg="3" md="auto" className="align-self-center">
              <div
                id={`multi-state`}
                mb="10"
                className={`align-self-center mb-2 ${className}`}
              >
                <select
                  id="leftToggle"
                  className="form-control"
                  aria-label="Default select example"
                  onChange={handaleTogglesearch}>
                  <option value="all">All</option>
                  {pData.map((val, i) => {
                    return (
                      <option key={i} value={val.id}>{capitalizeWords(val.userId.fullName)}</option>
                    )
                  })}
                </select>



                {/* <Select
                  id="leftToggle"
                  value={leftDropdownValue}
                  onChange={async (val) => {
                    setLeftDropdownValue(val);
                   
                    handaleTogglesearch();

                    // setSearchFormDetails((prevState) => ({
                    //   ...prevState,
                    //   partnerSearchParam: val.value,
                    // }));
                  }}
                  options={leftToggleValue}
                  classNamePrefix="select2-selection"
                /> */}
              </div>
            </Col>
            <Col lg="3" md="auto" className="align-self-center">
              <div
                id={`multi-state`}
                mb="10"
                className="align-self-center mb-2"
              >

                <select
                  id="rightToggle"
                  className="form-control"
                  aria-label="Default select example"
                  onChange={handaleTogglesearch}>
                  <option value="all">All</option>
                  <option value="accepted">Accepted</option>
                  <option value="rejected">Rejected</option>
                  <option value="pending">Pending</option>
                </select>

                {/* <Select
                  id="rightToggle"
                  value={rightDropdownValue}
                  onChange={(val) => {
                    setRightDropdownValue(val);

                    handaleTogglesearch();

                    // setSearchFormDetails((prevState) => ({
                    //   ...prevState,
                    //   partnerSearchParam: val.value,
                    // }));
                  }}
                  options={toggaleValue}
                  classNamePrefix="select2-selection"
                /> */}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ArchiveList
                    {...props}
                    pharmacyListMessage={message}
                    total={totalPosts}
                    getDataOnEnter={getDataOnEnter}
                    getUserData={getUserData}
                    currentPage={currentPage}
                    perPage={postsPerPage}
                    ajaxLoader={loading}
                    data={gdata}
                    setEditState={setEditState}
                    dataReload={fetchrxPharmacyData}
                  ></ArchiveList>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { partner } = state.Partner;
  const { singlePartner } = state.Partner;
  const { message } = state.Partner;
  return { partner, message, singlePartner };
};

export default withRouter(
  connect(mapStatetoProps, {
    getrxPharmacy,
    rxPharmacyActionSuccessful,
    setSinglerxPharmacy,
  })(withNamespaces()(RxArchive))
);
