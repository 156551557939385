import React, { useState, useEffect } from "react";

import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import axios from "axios";
//i18n
import { withNamespaces } from "react-i18next";
import { jsPanel } from "jspanel4/es6module/jspanel";
import "jspanel4/es6module/extensions/modal/jspanel.modal";
import "jspanel4/dist/jspanel.min.css";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [totalErrorCount, settotalErrorCount] = useState(0);
  const [errorList, seterrorList] = useState([]);

  const notificationUI = async () => {
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/dosespot/notifications-ui-data`;
    const result = await axios.get(url, headers).then((response) => {
      const options = {
        headerTitle: "action",
        position: "right-top 0% 0%",
        panelSize: {
          width: () => {
            return window.innerWidth;
          },
          height: () => {
            return window.innerHeight;
          },
        },
        dragit: {
          disable: true,
        },
        addClass: "dctCustom",
        animateIn: "jsPanelFadeIn",
        animateOut: "jsPanelFadeOut",
        theme: "none filled",
        closeOnEscape: true,
        borderRadius: ".1rem",
        borderStyle: "none",
        // maximizedMargin: ()=>{
        // 	return 	[69, 5, 5, 250 ]
        // },
        zIndex: 100000,

        setStatus: "maximized",
        header: false,
        border: "dimgrey",
        addCloseControl: 1,
        callback: function () {
          this.content.innerHTML = `<iframe
                                  src=${response.data.uiPath}
                                  width="100%"
                                  height="100%"
                                />`;
        },

        onclosed: () => {
          // remove closed jsPanel and its mounted component from state
          // const appPanels = app.state.panels;
          // if (appPanels[action]) {
          //   delete appPanels[action];
          //   app.setState({ panels: { ...appPanels } });
          // }
        },
      };
      const panel = jsPanel.create(options);
    });
  };

  const getErrorCount = async () => {
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/dosespot/notifications/count`;
    const result = await axios.get(url, headers).then((response) => {
      settotalErrorCount(response.data.data.TransactionErrorsCount);
    });
  };

  const renderErrorList = (errorList) => {
    return errorList && errorList.length > 0 ? (
      errorList.map((Error) => {
        return (
          <div className="font-size-12 text-muted">
            <p className="m-2 p-2 ">{Error.ErrorDetails}</p>
          </div>
        );
      })
    ) : (
      <div className="mt-4 mb-2 text-reset text-center notification-item">
        <div className="text-center">{props.t(`No Appointments found.`)}</div>
      </div>
    );
  };

  useEffect(() => {
    getErrorCount();
  }, []);

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        {/* <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        > */}
        <button
          className="btn header-item noti-icon waves-effect"
          onClick={notificationUI}
        >
          <i className="mdi mdi-prescription" style={{ fontSize: "20px" }}></i>
          <span className="badge badge-danger badge-pill mb-2">
            {totalErrorCount}
          </span>
        </button>

        {/* </DropdownToggle> */}

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {renderErrorList(errorList)}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withNamespaces()(NotificationDropdown);
