import React, { useEffect, useState } from 'react'
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import {
    Row, Col, Card, CardBody, Modal, FormGroup, ModalHeader, Button, ButtonGroup, ModalBody, Alert, Container
} from "reactstrap";
import { dctAlert } from '../../helpers/Commons';

const {
    Parser,
    transforms: { unwind },
} = require("json2csv");

const CompanyExport = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState('')
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [dropdownValue, setDropdownValue] = useState("");
    const [allGroups, setAllGroups] = useState([{
        options: []
    }])


    const [cSelected, setCSelected] = useState([]);
    const [rSelected, setRSelected] = useState(1);

    useEffect(() => {
        fetchAllGroups();
    }, [])

    const fetchAllGroups = () => {
        axios
            .get(`${process.env.REACT_APP_API_PATH}/group`, {
                headers: {
                    Authorization: localStorage.getItem("auth_token"),
                    PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
                },
            }).then(res => {
                // console.log("all groups---", res.data.data);
                let groups = res.data.data;
                let groupList = [];
                groups.forEach(element => {
                    groupList.push({ label: element.groupName, value: element.id })
                });
                setAllGroups([{
                    options: groupList
                }]);


            }).catch(err => {
                console.error("fetch groups err-----");
            })
    }

    const submitExport = () => {
        if (rSelected !== 1) {
            if (dropdownValue === "") {
                setErrorMsg("Please select one Group.")
                setError(true)
                return;
            }
        }

        setIsLoading(true);
        let url = `${process.env.REACT_APP_API_PATH}/company/export`;
        if (rSelected !== 1) {
            url = `${process.env.REACT_APP_API_PATH}/company/export?groupId=${dropdownValue.value}`;
        }

        console.log(url);
        // return;

        axios
            .get(url, {
                headers: {
                    Authorization: localStorage.getItem("auth_token"),
                    PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
                },
            }).then(res => {
                setIsLoading(false);
                setError(false);
                console.log("export json-------", res.data.data);
                let data = res.data.data;

                const fields = [
                    "companyName",
                    "groupName",
                    "enrolledMembers",
                    "totalMembers",
                    "dctPlanId",
                    "groupCode",
                    "status",
                ];

                let json2csvParser = new Parser({ delimiter: ",", fields });

                json2csvParser.opts.fields[0].label = "Company Name";
                json2csvParser.opts.fields[1].label = "Group Name";
                json2csvParser.opts.fields[2].label = "Enrolled Members";
                json2csvParser.opts.fields[3].label = "Total Members";
                json2csvParser.opts.fields[4].label = "DCT Plan ID";
                json2csvParser.opts.fields[5].label = "Group Code";
                json2csvParser.opts.fields[6].label = "Status";

                const tsv = json2csvParser.parse(data);
                // console.log(tsv);
                const uid = uuidv4();
                const exportedFileName = uid + ".csv";

                let blob = new Blob([tsv], { type: "text/csv;charset=utf-8;" });
                let link = document.createElement("a");
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFileName);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setSuccessMsg("Export Successful.")
                setSuccess(true);

            }).catch(err => {
                setIsLoading(false);
                console.error(err);
                setErrorMsg("Something Went wrong ! Please contact the support team.")
                setError(true);
            })
    }
    return (
        <React.Fragment>
            <Col lg="4" md="6" sm="6">
                <Card style={{ height: "100%" }}>
                    <CardBody>
                        {success && <div> {dctAlert(1, successMsg)}</div>}
                        {error && <div> {dctAlert(3, errorMsg)}  </div>}
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs mr-3">
                                <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                    <i className="mdi mdi-file"></i>
                                </span>
                            </div>
                            <h5 className="font-size-14 mb-0">Company Export</h5>
                        </div>
                        <Row className='d-flex flex-row container-fluid px-3'>
                            <ButtonGroup>
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => { setRSelected(1); setDropdownValue(""); }}
                                    active={rSelected === 1}
                                >
                                    Company
                                </Button>
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => setRSelected(2)}
                                    active={rSelected === 2}
                                >
                                    By Group
                                </Button>
                            </ButtonGroup>

                        </Row>
                        {rSelected === 2 &&
                            <Row className="d-flex flex-row px-3 container-fluid mt-3">

                                <FormGroup className="w-100">
                                    <Select
                                        value={dropdownValue}
                                        onChange={(e) => {
                                            setDropdownValue(e);
                                            setError(false)
                                        }}
                                        options={allGroups}
                                        classNamePrefix="select2-selection"
                                    />
                                </FormGroup>
                            </Row>
                        }


                        <Row className="mt-3 container-fluid">
                            <Col lg={12} className="text-right">
                                <button
                                    onClick={() => { submitExport() }}
                                    className="btn btn-primary waves-effect waves-light" type="button">

                                    {isLoading ? <>

                                        <span className="dr-cform-spinner">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                                        </span>

                                    </> : <i className="mdi mdi-file-export-outline mr-1"></i>}

                                    Export
                                </button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>


        </React.Fragment>
    )
}

export default CompanyExport