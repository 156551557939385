import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Form,
    CardBody,
    Alert,
    Container,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import login from "../../store/auth/login/reducer";
import { Config } from "../../Config";
import axios from "axios";
import AlertMessage from "./Components/Alerts/AlertMessage";
import ActivateBenefitCard from "./Components/ActivateBenefit/ActivateBenefitCard";
// Redux
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { loginUser, apiError } from "../../store/actions";


// import CryptoJS from "crypto-js";

// import ConfigDefaults from "../Config/ConfigDefaults";
// import { useState } from "react";
// import NonAuthLayout from "../components/Layout/NonAuthLayout";
// import Header from "../components/Layout/Header";
// import Link from "next/link";
// import AlertMessage from "../components/Alerts/AlertMessage";
// import { animate, motion } from "framer-motion";
// import Loader from "../components/loader/Loader";


// Redux Tool-Kit
// import { useSelector, useDispatch } from "react-redux";
// import { auth_user } from "../redux/auth/authDataReducer";

const FindAccount = () => {
    // const router = useRouter();
    // const dispatch = useDispatch();
    const [loginData, setloginData] = useState({
        email: "",
        password: "",
    });
    const [loginError, setLoginError] = useState({
        email: false,
        password: false,
    });
    const [remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState({
        type: "",
        title: "",
        message: "",
    });



    return (
        <>
            <React.Fragment>
                <div
                    className={`py-5 bg-black app-${process.env.REACT_APP_SLUG} global-gradient`}
                    style={{ minHeight: "100vh", height: "100%", marginTop: 0 }}
                >
                    <div className="account-pages my-5 pt-sm-2">
                        <Container className={`${Config.names.slugName}-container-sm`}>
                            <Row className="justify-content-center mt-2">
                                <Col md={12} lg={12} xl={12} className="d-flex justify-content-center align-items-center">
                                    <img src={Config.logos.logoDark} alt="" style={{ width: '14rem' }} />
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center align-items-center">
                                <Col lg="5" md="6" sm="8" xs="10" className="p-0">
                                    <AlertMessage
                                        forgot={false}
                                        login={true}
                                        show={showAlert}
                                        type={alertData.type}
                                        title={alertData.title}
                                        message={alertData.message}
                                    />
                                </Col>
                            </Row>

                            <Row className="d-flex flex-column justify-content-center align-items-center">
                                <Col lg="5" md="6" sm="8" xs="10" className="mt-5">
                                    <i className="fa fa-arrow-left font-size-14 text-secondary"></i>
                                    <Link to="/login" className="text-secondary font-size-14 font-weight-light ml-2">Go back</Link>
                                </Col>
                                <ActivateBenefitCard
                                    title="find your account"
                                    setAlertData={setAlertData}
                                    setShowAlert={setShowAlert} />

                            </Row>
                            <Row className="d-flex justify-content-center mt-4">
                                <Link className="text-primary font-weight-bold font-size-13" style={{ wordSpacing: "2px" }} to="/login">Already a Member? Click Here to Sign In</Link>

                            </Row>


                        </Container>
                    </div>
                </div>
            </React.Fragment>
        </>
    );
};

export default FindAccount

