import { CREATE_GROUP, UPDATE_GROUP, GET_GROUP, GET_GROUP_LIST, SET_GROUP_LIST, CREATE_GROUP_SUCCESS, CREATE_GROUP_FAILED, API_ERROR, SET_SINGLE_GROUP, SET_GROUP_LISTING } from './actionTypes';

export const createGroup = (group) => ({
    type: CREATE_GROUP,
    payload: { group }
})

export const updateGroup = (group) => ({
    type: UPDATE_GROUP,
    payload: { group }
})

export const getGroup = (group) => ({
    type: GET_GROUP,
    payload: { group }
})

export const getGroupList = (group) => ({
    type: GET_GROUP_LIST,
    payload: { group }
})

export const setGroupList = (group, message, action) => {
    return {
        type: SET_GROUP_LIST,
        payload: group,
        message: message,
        action_type: action
    }
}


export const setSingleGroup = (group, message, action) => {
    return {
        type: SET_SINGLE_GROUP,
        payload: group,
        message: message,
        action_type: action
    }
}

export const setGroupListing = (group, message) => {
    return {
        type: SET_GROUP_LISTING,
        payload: group,
        groupListing: group,
        message: message,
    }
}

export const groupActionSuccessful = (group, message, action) => {
    return {
        type: CREATE_GROUP_SUCCESS,
        payload: group,
        message: message,
        action_type: action
    }
}

export const createGroupFailed = (error) => {
    return {
        type: CREATE_GROUP_FAILED,
        payload: error
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}