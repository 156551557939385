import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { CREATE_RXPHARMACY, UPDATE_RXPHARMACY, GET_RXPHARMACY_LIST } from "./actionTypes";
import {
    rxPharmacyActionSuccessful,
    setSinglerxPharmacy,
    createrxPharmacyFailed,
    setrxPharmacyList,
} from "./actions";

//Include Both Helper File with needed methods
import {
    dctPostRawData,
    dctPutRawData,
    // getrxPharmacyListObj,
    dctGetRawData,
} from "../../helpers/backend_helper";
import { Config } from "../../Config";


// Create rxPharmacy
function* createrxPharmacySaga({ payload: { rxpharmacy, history } }) {
    try {
        const rxpharmacy_data = rxpharmacy;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN},
        };
        const response = yield call(
            dctPostRawData,
            process.env.REACT_APP_API_PATH + "/rxpharmacy",
            rxpharmacy_data,
            headers
        );

        document
        .getElementsByClassName("dr-cform-spinner")[0]
        .classList.add("d-none");
        if (response.id) {
            yield put(
                rxPharmacyActionSuccessful(response, "rxPharmacy Created Successfully.", 1)
            );
            yield put(
                setSinglerxPharmacy(response, "rxPharmacy Created Successfully.", 1),
            );
            if (document.querySelector(`.${Config.names.slugName}-rxpharmacy-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-rxpharmacy-add-edit-modal .close`).click();
            }
        } else {
            yield put(createrxPharmacyFailed(response.message));
        }
    } catch (error) {
        yield put(createrxPharmacyFailed(error));
    }
}

// Update rxPharmacy
function* updaterxPharmacySaga({ payload: { rxpharmacy, history } }) {
    try {
        const rxpharmacy_data = rxpharmacy;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,
                PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN },
        };
        
        const response = yield call(
            dctPutRawData,
            process.env.REACT_APP_API_PATH + "/rxpharmacy/"+rxpharmacy_data.id,
            rxpharmacy_data,
            headers
        );
       
        document
        .getElementsByClassName("dr-cform-spinner")[0]
        .classList.add("d-none");
        if (response.id) {
            yield put(
                rxPharmacyActionSuccessful(response, "rxPharmacy Updated Successfully. #1", 2),
            );
            yield put(
                setSinglerxPharmacy(response, "rxPharmacy Updated Successfully #2.", 2),
            );
            
            if (document.querySelector(`.${Config.names.slugName}-rxpharmacy-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-rxpharmacy-add-edit-modal .close`).click();
            }
        } else {
            yield put(createrxPharmacyFailed(response.message));
        }
    } catch (error) {
        yield put(createrxPharmacyFailed(error));
    }
}

// Get rxPharmacy
function* getrxPharmacyListSaga({ payload: { rxpharmacy } }) {
    try {
        
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,
                PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN  },
        };
        const response = yield call(
            dctGetRawData,
            process.env.REACT_APP_API_PATH + "/rxpharmacy",
            headers
        );
       
        
        if (response.id) {
            yield put(
                setrxPharmacyList(
                response.result.data,
                "rxPharmacy data fetched Successfully.",
                ""
                )
            );
        } else {
            yield put(createrxPharmacyFailed(response.message));
        }
    } catch (error) {
        yield put(createrxPharmacyFailed(error));
    }
}

export function* watchCreaterxPharmacy() {
    yield takeEvery(CREATE_RXPHARMACY, createrxPharmacySaga);
}

export function* watchUpdaterxPharmacy() {
    yield takeEvery(UPDATE_RXPHARMACY, updaterxPharmacySaga);
}
export function* watchGetrxPharmacy() {
    yield takeEvery(GET_RXPHARMACY_LIST, getrxPharmacyListSaga);
}

function* rxpharmacySaga() {
    yield all([
        fork(watchCreaterxPharmacy),
        fork(watchUpdaterxPharmacy),
        fork(watchGetrxPharmacy),
    ]);
}

export default rxpharmacySaga;
