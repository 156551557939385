import React, { Fragment, useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import Records from "./Records";
import {
  getMemberAndDependents,
  getMemberTabList,
} from "../../../store/actions";
import DctHeroBanner from "../../../components/Common/DctHeroBanner";

function HealthRecords(props) {
  const { authMemberId } = useSelector((state) => state.Login);
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  useEffect(() => {
    props.getMemberAndDependents(authMemberId);
    props.getMemberTabList(authMemberId);
  }, []);

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          {window.ReactNativeWebView ? (
            ""
          ) : (
            <Row>
              <Col xl={12}>
                <DctHeroBanner
                  mainTitle="Health Records"
                  subTitle="Secure Electronic Health Records"
                  isInfoBar={true}
                  infoBarTitle=""
                  isCall911={true}
                ></DctHeroBanner>
              </Col>
            </Row>
          )}
          <Row className="justify-content-between">
            <Col xl={12}>
              <h5 className="mt-2 mb-2">
                {props.t(`Welcome to My Health Center!`)}
              </h5>
              <p>
                {props.t(
                  `To ensure that you receive the most informed care possible, we encourage you to keep your online health history up to date.`
                )}{" "}
                <span
                  className="font-weight-medium cursor-pointer"
                  onClick={(e) => {
                    setShowMoreInfo(!showMoreInfo);
                    e.target.classList.add("d-none");
                  }}
                >
                  {props.t(`Read More`)}
                </span>
              </p>
              {showMoreInfo ? (
                <p>
                  {props.t(
                    `Some of the information listed below may have been provided by your health plan. Please review your history regularly and add any new or missing information. Click Add to add an item to an area.`
                  )}
                </p>
              ) : null}
            </Col>

            <Records />
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { memberAndDependents } = state.Member;
  return { memberAndDependents };
};

export default withRouter(
  connect(mapStatetoProps, { getMemberAndDependents, getMemberTabList })(
    withNamespaces()(HealthRecords)
  )
);
