import React, { Fragment } from "react";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Notification from "./Notification";
import AccepetRejectMessage from "./AccepetRejectMessage";
import DctHeroBanner from "../../components/Common/DctHeroBanner";

const NotificationPage = (props) => {
  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <DctHeroBanner
                mainTitle="Notification"
                subTitle="Check, Respond and Reply"
                isHideBannerImg={true}
                isInfoBar={false}
                infoBarTitle=""
                isCall911={false}
              ></DctHeroBanner>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Notification></Notification>
            </Col>
            <Col lg={6}>
              <AccepetRejectMessage></AccepetRejectMessage>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(withNamespaces()(NotificationPage));
