import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { CREATE_RXPRESCRIPTION, UPDATE_RXPRESCRIPTION, GET_RXPRESCRIPTION_LIST } from "./actionTypes";
import {
    rxPrescriptionActionSuccessful,
    setSinglerxPrescription,
    createrxPrescriptionFailed,
    setrxPrescriptionList,
} from "./actions";

//Include Both Helper File with needed methods
import {
    dctPostRawData,
    dctPutRawData,
    // getrxPrescriptionListObj,
    dctGetRawData,
} from "../../helpers/backend_helper";
import { Config } from "../../Config";


// Create rxPrescriptionPhar
function* createrxPrescriptionSaga({ payload: { rxprescription, history } }) {
    try {
        const rxprescription_data = rxprescription;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN},
        };
        const response = yield call(
            dctPostRawData,
            process.env.REACT_APP_API_PATH + "/prescriptionfill",
            rxprescription_data,
            headers
        );

        document
        .getElementsByClassName("dr-cform-spinner")[0]
        .classList.add("d-none");
        if (response.id) {
            yield put(
                rxPrescriptionActionSuccessful(response, "rxPrescription Created Successfully.", 1)
            );
            yield put(
                setSinglerxPrescription(response, "rxPrescription Created Successfully.", 1),
            );
            if (document.querySelector(`.${Config.names.slugName}-rxprescription-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-rxprescription-add-edit-modal .close`).click();
            }
        } else {
            yield put(createrxPrescriptionFailed(response.message));
        }
    } catch (error) {
        yield put(createrxPrescriptionFailed(error));
    }
}

// Update rxPrescription
function* updaterxPrescriptionSaga({ payload: { rxprescription, history } }) {
    try {
        const rxprescription_data = rxprescription;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,
                PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN },
        };
        
        const response = yield call(
            dctPutRawData,
            process.env.REACT_APP_API_PATH + "/prescriptionfill/"+rxprescription_data.id,
            rxprescription_data,
            headers
        );
       
        document
        .getElementsByClassName("dr-cform-spinner")[0]
        .classList.add("d-none");
        if (response.id) {
            yield put(
                rxPrescriptionActionSuccessful(response, "rxPrescription Updated Successfully. #1", 2),
            );
            yield put(
                setSinglerxPrescription(response, "rxPrescription Updated Successfully #2.", 2),
            );
            
            if (document.querySelector(`.${Config.names.slugName}-rxprescription-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-rxprescription-add-edit-modal .close`).click();
            }
        } else {
            yield put(createrxPrescriptionFailed(response.message));
        }
    } catch (error) {
        yield put(createrxPrescriptionFailed(error));
    }
}

// Get rxPrescription
function* getrxPrescriptionListSaga({ payload: { rxprescription } }) {
    try {
        
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: { Authorization: bearer_token,
                PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN  },
        };
        const response = yield call(
            dctGetRawData,
            process.env.REACT_APP_API_PATH + "/prescriptionfill",
            headers
        );
       
        
        if (response.id) {
            yield put(
                setrxPrescriptionList(
                response.result.data,
                "rxPrescription data fetched Successfully.",
                ""
                )
            );
        } else {
            yield put(createrxPrescriptionFailed(response.message));
        }
    } catch (error) {
        yield put(createrxPrescriptionFailed(error));
    }
}

export function* watchCreaterxPrescription() {
    yield takeEvery(CREATE_RXPRESCRIPTION, createrxPrescriptionSaga);
}

export function* watchUpdaterxPrescription() {
    yield takeEvery(UPDATE_RXPRESCRIPTION, updaterxPrescriptionSaga);
}
export function* watchGetrxPrescription() {
    yield takeEvery(GET_RXPRESCRIPTION_LIST, getrxPrescriptionListSaga);
}

function* rxprescriptionSaga() {
    yield all([
        fork(watchCreaterxPrescription),
        fork(watchUpdaterxPrescription),
        fork(watchGetrxPrescription),
    ]);
}

export default rxprescriptionSaga;
