import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";

import { withRouter } from "react-router-dom";
//i18n
import { withNamespaces } from "react-i18next";

import CardWelcome from "./CardWelcome";
import CardUser from "./CardUser";
import DetailsForm from "./DetailsForm";

function AccountSetting(props) {
  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={6}>
              <CardWelcome />
            </Col>

            <Col xl={6}>
              <CardUser />
            </Col>
          </Row>
          <Row>
            <DetailsForm />
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}

export default withRouter(withNamespaces()(AccountSetting));
