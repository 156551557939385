export const CREATE_MEMBER = "CREATE_MEMBER";
export const UPDATE_MEMBER = "UPDATE_MEMBER";

export const GET_MEMBER = "GET_MEMBER";
export const GET_MEMBER_LIST = "GET_MEMBER_LIST";

export const GET_SINGLE_MEMBER = "GET_SINGLE_MEMBER";

export const SET_MEMBER_LIST = "SET_MEMBER_LIST";

export const SET_MEMBER_LISTING = "SET_MEMBER_LISTING";

export const CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS";
export const CREATE_MEMBER_FAILED = "CREATE_MEMBER_FAILED";

export const SET_SINGLE_MEMBER = "SET_SINGLE_MEMBER";

export const GET_MEMBER_AND_DEPENDENTS = "GET_MEMBER_AND_DEPENDENTS";
export const SET_MEMBER_AND_DEPENDENTS = "SET_MEMBER_AND_DEPENDENTS";

export const GET_MEMBER_TAB_LIST = "GET_MEMBER_TAB_LIST";
export const SET_MEMBER_TAB_LIST = "SET_MEMBER_TAB_LIST";

export const CREATE_DEPENDENT = "CREATE_DEPENDENT";
export const UPDATE_DEPENDENT = "UPDATE_DEPENDENT";
export const CREATE_DEPENDENT_SUCCESS = "CREATE_DEPENDENT_SUCCESS";
export const CREATE_DEPENDENT_FAILED = "CREATE_DEPENDENT_FAILED";

/* CONSULTATION */
export const CREATE_CONSULTATION = "CREATE_CONSULTATION";
export const UPDATE_CONSULTATION = "UPDATE_CONSULTATION";
export const UPDATE_CONSULTATION_STEPWISE = "UPDATE_CONSULTATION_STEPWISE";

export const SET_CONSULT_TAB_INDEX = "SET_CONSULT_TAB_INDEX";
export const SET_SINGLE_CONSULTATION = "SET_SINGLE_CONSULTATION";
export const GET_SINGLE_CONSULTATION = "GET_SINGLE_CONSULTATION";
export const CONSULTATION_MSG = "CONSULTATION_MSG";
export const GET_DRAFT_CONSULTATION = "GET_DRAFT_CONSULTATION";
export const SET_DRAFT_CONSULTATION = "SET_DRAFT_CONSULTATION";

export const SET_CONSULTATION_LISTING = "SET_CONSULTATION_LISTING";
export const SET_MY_SINGLE_CONSULTATION = "SET_MY_SINGLE_CONSULTATION";
export const SCHEDULED_CONSULTATION_LIST = "SCHEDULED_CONSULTATION_LIST";
export const SET_DEPENDENT_DATA = "SET_DEPENDENT_DATA";

export const API_ERROR = "API_ERROR";
