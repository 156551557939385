import Axios from "axios";
import React, { Fragment, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { setConsultationListing } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
const ConsultationPreview = (props) => {
  const modal = props.modal;
  const setPreview = props.setPreview;
  const consultationData = props.consultationData;
  const setConsultationModal = props.setConsultationModal;
  const [sweetAlert, setSweetAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  // let ConsultationListing = [];
  let ConsultationListing = useSelector(
    (state) => state.Member?.consultListing
  );

  const ConfirmConsultation = () => {
    setLoader(true);
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const data = {
      dependentId: consultationData?.dependentId,
      memberId: consultationData?.memberId,
      consultationType: consultationData?.consultationType,
      patient: consultationData?.patientName,
      ehr_acceptEMR: true,
      ehr_acceptInformedConsent: true,
      phone_service: consultationData?.services,
      phone_tel: consultationData?.phone,
      state_country: consultationData?.country,
      state_currentState: consultationData?.state,
      details_allOtherSymptoms: consultationData?.allOtherSymptoms,
      details_chiefcomplaint: consultationData?.chiefComplaint,
      details_commonSymptoms: consultationData?.commonSymptoms,
      details_description: consultationData?.description,
      details_medicationNameAndFrequency: consultationData?.medicationRefill,
      details_refillType: consultationData?.refillType,
      schedule_from: consultationData?.scheduleFrom,
      schedule_physicianLanguages: consultationData?.physicianLanguage,
      schedule_timezoneAbbr: consultationData?.timezoneAbbr,
      schedule_timezoneUTC: consultationData?.timezoneUTC,
      schedule_to: consultationData?.scheduleTo,
      pharmacy_pharmacyId: consultationData?.pharmacyDetails?.pharmacyId,
    };

    let url = `${process.env.REACT_APP_API_PATH}/consultation/consultation-without-step`;
    Axios.post(url, data, options)
      .then((res) => {
        if (res?.data?.success) {
          setLoader(false);
          setSweetAlert(true);
          dispatch(
            setConsultationListing([...res?.data.data, ...ConsultationListing])
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        setErrorAlert(true);
        setErrorAlertMessage(err?.response?.data?.cause?.message || 'Something went wrong');
      });
  };
  return (
    <div>
      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setPreview(!modal);
        }}
        scrollable={true}
      >
        <ModalHeader
          toggle={() => {
            setPreview(!modal);
          }}
        >
          Consultation Preview
        </ModalHeader>
        <ModalBody>
          <Container>
            <Fragment>
              <h5 className="font-size-15 mt-4">
                {props.props.t("Televisit Details")} :
              </h5>

              <div className="text-muted mt-4">
                <Row>
                  <Col lg="6">
                    <p>
                      <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                      <strong>{props.props.t("Main Complaint")}: </strong>{" "}
                      {consultationData?.chiefComplaint}
                    </p>
                    <p>
                      <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                      <strong>{props.props.t("Common Symptoms")}: </strong>{" "}
                      {consultationData?.commonSymptoms}
                    </p>

                    <p>
                      <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                      <strong>{props.props.t("Other Symptoms")}: </strong>{" "}
                      {consultationData?.allOtherSymptoms}
                    </p>

                    <div>
                      <i className="bx bx-plus-medical text-primary mr-1"></i>{" "}
                      <strong>{props.props.t("Pharmacy")}: </strong>{" "}
                      <p className="text-muted font-weight-13 ">
                        <b>{consultationData?.pharmacyDetails?.storeName}</b>
                        <br />
                        <span className="text-justify">
                          {consultationData?.pharmacyDetails?.address1}
                          <br />
                          <span>
                            {consultationData?.pharmacyDetails?.city},{" "}
                            {consultationData?.pharmacyDetails?.state}{" "}
                            {consultationData?.pharmacyDetails?.Z}
                          </span>
                          <br />
                          {props.props.t("P")}:{" "}
                          {consultationData?.pharmacyDetails?.P}
                          <br />
                          {props.props.t("F")}:{" "}
                          {consultationData?.pharmacyDetails?.F}
                        </span>
                      </p>
                    </div>
                  </Col>

                  <Col lg="6">
                    <p className="text-capitalize">
                      <i className="bx bx-user text-primary mr-1"></i>{" "}
                      <strong>{props.props.t("Patient Name")} : </strong>
                      {consultationData?.patientName}
                    </p>
                    <p>
                      <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                      <strong>{props.props.t("Main Contact Email")}: </strong>{" "}
                      <a href={"mailto:" + "s@d.com"} className="text-dark">
                        {consultationData?.contactEmail}
                      </a>
                    </p>

                    <p>
                      <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                      <strong>{props.props.t("Primary Phone")}: </strong>{" "}
                      <a href={"tel:" + "+16456789034"} className="text-dark">
                        {consultationData?.phone}
                      </a>
                    </p>
                    <p>
                      <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                      <strong>{props.props.t("Consultation Type")}: </strong>{" "}
                      {consultationData?.consultationType}
                    </p>
                    <p>
                      <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                      <strong>{props.props.t("Phone Service")}: </strong>{" "}
                      {consultationData?.services}
                    </p>
                    <p>
                      <i className="bx bxs-map text-primary mr-1"></i>{" "}
                      <strong>{props.props.t("Country")}: </strong>{" "}
                      {consultationData?.country}
                    </p>
                    <p>
                      <i className="bx bxs-map text-primary mr-1"></i>{" "}
                      <strong>{props.props.t("State")}: </strong>{" "}
                      {consultationData?.state}
                    </p>
                  </Col>
                  <Col lg="12">
                    <strong>{props.props.t("Details")}: </strong>
                  </Col>
                  <Col lg="12" className="mr-1">
                    {consultationData?.medicationRefill}
                  </Col>
                </Row>
              </div>

              <div className="mt-3">
                <p className="alert alert-primary mb-3">
                  {props.props.t(
                    `Please Submit your consultation by clicking the below button.`
                  )}{" "}
                </p>
              </div>

              <div className="mb-3 mt-4">
                <button
                  type="button"
                  className="btn btn-outline-primary waves-light mr-2"
                  onClick={() => {
                    setPreview(!modal);
                  }}
                >
                  <span className="d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                  {props.props.t("Back")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light mr-2"
                  onClick={() => {
                    ConfirmConsultation();
                  }}
                >
                  {loader ? (
                    <i className="bx bx-loader bx-spin font-size-18 mr-2 align-middle"></i>
                  ) : (
                    " "
                  )}
                  {props.props.t("Schedule My Call")}
                </button>
              </div>
            </Fragment>
            {sweetAlert && (
              <SweetAlert
                title={props.props.t("Success")}
                success
                confirmBtnBsStyle="success"
                onConfirm={() => {
                  setSweetAlert(false);
                  setPreview(!modal);
                  setConsultationModal(false);
                }}
              >
                {props.props.t("The consultation is confirmed")}
              </SweetAlert>
            )}
            {errorAlert && (
              <SweetAlert
                title={props.props.t("Warning")}
                warning
                confirmBtnBsStyle="warning"
                onConfirm={() => {
                  setSweetAlert(false);
                  setPreview(!modal);
                  setConsultationModal(false);
                }}
              >
                {props.props.t(errorAlertMessage)}
              </SweetAlert>
            )}
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ConsultationPreview;
