import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Media } from "reactstrap";

import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import { DctAvatar } from "../../components/Common/Icons/DctAvatar";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import { Config } from "../../Config";

const UpcomingAppointments = (props) => {
  // const [upcomingAppointments, setUpcomingAppointments] = useState(
  //   "No upcoming appointments"
  // );
  const [upAppointment, setUpAppointment] = useState([]);

  // const { authMemberId, authUser } = useSelector((state) => state.Login);

  const [cal, setCal] = useState([]);

  let temp = [];

  //Fetch Widget Data
  const getUpcomingAppointments = () => {
    let url = `${process.env.REACT_APP_API_PATH}/consultation/scheduled` 
      + `?sort=updatedAt%20DESC&populate=false&page=1&limit=3&q=confirmed`;
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    axios.get(url, options).then(function (response) {
      // let data = response.data;
      // setUpcomingAppointments(
      //   data.count !== 0
      //     ? data.count + " Appointments"
      //     : "No upcoming appointments"
      // );
      setUpAppointment(response.data.data);
      response.data.data.map(async (appointment, index) => {
        if(appointment.schedule_from!=="")
          temp.push(appointment.schedule_from)
        setCal(temp)
      });
    });
  };
  const color = ["text-success", "text-warning", "text-info"];

  useEffect(() => {
    getUpcomingAppointments();
  }, []);

  return (
    <Fragment>
      <Card className="p-4">
        <span className={`${Config.names.slugName}-card-action`}>
          <Row>
            <Col lg={11} md={11} sm={11}>
              <Row>
                <Col>
                <DatePicker 
                  type="icon"
                  value={cal}
                  showOtherDays
                  animation
                  hideOnScroll
                  mapDays={({ date }) => {
                    return {onClick: () => props.history.push("/member/my-consultations/?&csearch="+(moment(new Date(date)).format("YYYY-MM-DD")))}
                  }}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </span>
        <CardTitle className="font-size-16 text-primary card-title font-weight-normal mb-4">
          {upAppointment?.length > 0
            ? props.t("Upcoming Appointments")
            : props.t("No Upcoming Appointments")}
        </CardTitle>
        <CardBody className="p-0">
          <Row className={`${Config.names.slugName}-appointment-list`}>
            {upAppointment?.map((appointment, index) => (
              <Col md={4} className={`${Config.names.slugName}-dashboard-item-list`} key={index}>
                <div className={`${Config.names.slugName}-h-border bg-soft-primary ml-2 ${Config.names.slugName}-desktop`}></div>
                <div
                  className={`float-left ${Config.names.slugName}-sm-width-100`}
                  style={{ width: "96%" }}
                >
                  <div className={color[index] + " mb-4"}>
                    {moment(appointment.schedule_from).format("DD.MM.YYYY")}
                  </div>
                  <Row>
                    <Col md={4}>
                      <div className="font-size-13 line-height-13 mb-3">
                        {appointment.details_chiefcomplaint}
                      </div>
                    </Col>
                    <Col md={8}>
                      <Media>
                        <div className="mr-3">
                          <DctAvatar size="44" />
                        </div>
                        <Media body className="align-self-center">
                          <div className="text-muted">
                            <p className="mb-1 font-size-12">
                              {moment(appointment.schedule_from).format("LT")}
                            </p>
                            <h5 className="">{appointment.patient}</h5>
                          </div>
                        </Media>
                      </Media>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default withRouter(withNamespaces()(UpcomingAppointments));
