import { CREATE_PARTNER_AUTH, UPDATE_PARTNER_AUTH, GET_PARTNER_AUTH, GET_PARTNER_LIST_AUTH, SET_PARTNER_LIST_AUTH, CREATE_PARTNER_SUCCESS_AUTH, CREATE_PARTNER_FAILED_AUTH, API_ERROR, SET_SINGLE_PARTNER_AUTH, SET_PARTNER_LISTING_AUTH } from './actionTypes';

export const createPartnerAuth = (partner) => ({
    type: CREATE_PARTNER_AUTH,
    payload: { partner }
})

export const updatePartnerAuth = (partner) => ({
    type: UPDATE_PARTNER_AUTH,
    payload: { partner }
})

export const getPartnerAuth = (partner) => ({
    type: GET_PARTNER_AUTH,
    payload: { partner }
})

export const getPartnerListAuth = (partner) => ({
    type: GET_PARTNER_LIST_AUTH,
    payload: { partner }
})

export const setPartnerListAuth = (partner, message, action) => {
    return {
        type: SET_PARTNER_LIST_AUTH,
        payload: partner,
        message: message,
        action_type: action
    }
}


export const setSinglePartnerAuth = (partner, message, action) => {
    return {
        type: SET_SINGLE_PARTNER_AUTH,
        payload: partner,
        message: message,
        action_type: action
    }
}

export const setPartnerListingAuth = (partner, message) => {
    return {
        type: SET_PARTNER_LISTING_AUTH,
        payload: partner,
        partnerListingAuth: partner,
        message: message,
    }
}

export const partnerActionSuccessfulAuth = (partner, message, action) => {
    return {
        type: CREATE_PARTNER_SUCCESS_AUTH,
        payload: partner,
        message: message,
        action_type: action
    }
}

export const createPartnerFailedAuth = (error) => {
    return {
        type: CREATE_PARTNER_FAILED_AUTH,
        payload: error
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}