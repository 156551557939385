export const CREATE_DOCTOR = "CREATE_DOCTOR";
export const UPDATE_DOCTOR = "UPDATE_DOCTOR";
export const GET_DOCTOR = "GET_DOCTOR";
export const GET_DOCTOR_LIST = "GET_DOCTOR_LIST";
export const GET_SINGLE_DOCTOR = "GET_SINGLE_DOCTOR";
export const SET_DOCTOR_LIST = "SET_DOCTOR_LIST";
export const SET_DOCTOR_LISTING = "SET_DOCTOR_LISTING";
export const CREATE_DOCTOR_SUCCESS = "CREATE_DOCTOR_SUCCESS";
export const CREATE_DOCTOR_FAILED = "CREATE_DOCTOR_FAILED";
export const SET_SINGLE_DOCTOR = "SET_SINGLE_DOCTOR";
export const API_ERROR = "API_ERROR";

// Televisit
export const UPDATE_TELEVISIT = "UPDATE_TELEVISIT";
export const GET_TELEVISIT = "GET_TELEVISIT";
export const GET_TELEVISIT_LIST = "GET_TELEVISIT_LIST";

export const SET_TELEVISIT_LIST = "SET_TELEVISIT_LIST";
export const SET_TELEVISIT_LISTING = "SET_TELEVISIT_LISTING";
export const SET_SINGLE_TELEVISIT = "SET_SINGLE_TELEVISIT";

//Televisit History

export const GET_TELEVISIT_HISTORY_LIST = "GET_TELEVISIT_HISTORY_LIST";
export const SET_TELEVISIT_HISTORY_LIST = "SET_TELEVISIT_HISTORY_LIST";
export const SET_TELEVISIT_HISTORY_LISTING = "SET_TELEVISIT_HISTORY_LISTING";
