import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";

import featuresSetting from "../../../assets/images/features-settings.png";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "axios";

const CardUser = (props) => {
  const { authDoctorId } = useSelector((state) => state.Login);

  const [dctScheduledTelevisit, setDctScheduledTelevisit] = useState();

  useEffect(() => {
    /**
     * Consultation is no more assigned to any specific doctor
     **/
    // let bearer_token = localStorage.getItem("auth_token");
    // let doctorId = authDoctorId;
    // const headers = {
    //   headers: {
    //     Authorization: bearer_token,
    //     PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
    //   },
    // };
    // let url = `${process.env.REACT_APP_API_PATH}/consultation/total-scheduled?doctorId=${doctorId}`;
    // axios
    //   .get(url, headers)
    //   .then((response) => {
    //     console.log(response.data);
    //     setDctScheduledTelevisit(response.data.total);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, []);

  const [dctCompletedTelevisit, setDctCompletedTelevisit] = useState();
  useEffect(() => {
    /**
     * Consultation is no more assigned to any specific doctor
     **/
    // let bearer_token = localStorage.getItem("auth_token");
    // const headers = {
    //   headers: {
    //     Authorization: bearer_token,
    //     PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
    //   },
    // };
    // let doctorId = authDoctorId;
    // let url = `${process.env.REACT_APP_API_PATH}/consultation/total-completed?doctorId=${doctorId}`;
    // axios
    //   .get(url, headers)
    //   .then((response) => {
    //     console.log(response.data);
    //     setDctCompletedTelevisit(response.data.total);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, []);

  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Card>
        <div>
          <Row>
            <Col lg="8" sm="9">
              <div className="p-5 mb-3">
                <h5 className="text-primary">{props.t("Welcome Back !")}</h5>
                <p>{props.t("Doctor")}</p>

                <div className="text-muted">
                  <p className="mb-2">
                    <i className="mdi mdi-video align-middle text-primary mr-1"></i>{" "}
                    {dctScheduledTelevisit} {props.t("Scheduled Televisits")}
                  </p>
                  <p className="mb-2">
                    <i className="mdi mdi-star-circle align-middle text-primary mr-1"></i>{" "}
                    {dctCompletedTelevisit} {props.t("Completed Televisits")}
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" sm="3" className="align-self-center">
              <div>
                <img
                  src={featuresSetting}
                  alt=""
                  className="img-fluid d-block"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(CardUser));
