import React, { Fragment, useState } from "react";
import { Col, Row } from "reactstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { SweetAlert } from "../../../components/DoctegritySweetAlert/DoctegritySweetAlert";

import {
  updateConsultation,
  setDraftConsultation,
} from "../../../store/actions";
import { Config } from "../../../Config";

const ConsultFormFooter = (props) => {
  const [cancelAlertBox, setCancelAlertBox] = useState(false);
  const [draftCancelledReasonText, setDraftCancelledReasonText] = useState("");

  const dispatch = useDispatch();

  let memberObj = useSelector((state) => state.Member);
  const { singleConsult } = memberObj;

  function cancelledConsultation() {
    if (singleConsult) {
      let cancellationData = {
        id: singleConsult.id,
        message: draftCancelledReasonText,
        _actionType: "cancelled",
      };
      props.updateConsultation(cancellationData);
      setCancelAlertBox(false);
      dispatch(setDraftConsultation(null, ""));
      if (window.ReactNativeWebView)
        window.ReactNativeWebView.postMessage("BACK_TO_HOME");
    }
  }

  return (
    <Fragment>
      {cancelAlertBox ? (
        <SweetAlert
          title={props.t(`Are you sure you want to cancel the consultation?`)}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText={props.t("Yes")}
          cancelBtnText={props.t("No")}
          onConfirm={() => {
            cancelledConsultation();
          }}
          onCancel={() => {
            setCancelAlertBox(false);
          }}
        >
          <div>
            <h5 className="mt-3">
              {props.t(`Please enter the cancellation Reason.`)}
            </h5>
            <textarea
              type="textarea"
              name="cancelReason"
              className="form-control"
              onChange={(e) => {
                setDraftCancelledReasonText(e.target.value);
              }}
            />
          </div>
        </SweetAlert>
      ) : null}

      <div className="mt-4">
        <Row>
          <Col lg="12">
            <div className={`${Config.names.slugName}-form-footer`}>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light mr-2"
                  onClick={() => {}}
                >
                  <span className="dr-form-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                  {props.t("Continue")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary waves-light"
                  onClick={() => {
                    setCancelAlertBox(true);
                    
                  }}
                >
                  {props.t("Cancel Consult")}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
// export default withRouter(withNamespaces("translation")(ConsultFormFooter));

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {
    updateConsultation,
  })(withNamespaces()(ConsultFormFooter))
);
