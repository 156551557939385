import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

//Account Redux states
import { REGISTER_USER } from './actionTypes';
import { registerUserSuccessful, registerUserFailed } from './actions';

//Include Both Helper File with needed methods
import { postRegister } from '../../../helpers/backend_helper';


// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
    try {
        const response = yield call(postRegister, process.env.REACT_APP_API_PATH + '/auth/activation', {lastName: user.lastName, postalCode: user.postalCode, dob: user.dob});
        document.getElementsByClassName("dr-login-spinner")[0].classList.add("d-none");
        document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
        if(response.success === true) {
            yield put(registerUserSuccessful(response.message));
            // history.push('/dashboard');
        } else {
            yield put(registerUserFailed(response.message));
        }

    } catch (error) {
        yield put(registerUserFailed(error));
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
    yield all([fork(watchUserRegister)]);
}

export default accountSaga;