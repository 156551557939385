import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import {
  CREATE_POST_MESSAGE,
  UPDATE_POST_MESSAGE,
  CREATE_POST_COMMENT,
  UPDATE_POST_COMMENT,
  GET_POST_MESSAGE_LIST,
  GET_SINGLE_POST_MESSAGE,
} from "./actionTypes";
import {
  postMessageActionSuccessful,
  postCommentActionSuccessful,
  setSinglePostMessage,
  createPostMessageFailed,
  createPostCommentFailed,
  setPostMessageList,
  setPostCommentList,
} from "./actions";

//Include Both Helper File with needed methods
import {
  dctPostRawData,
  dctPutRawData,
  dctGetRawData,
} from "../../helpers/backend_helper";
import { Config } from "../../Config";

const commentListAPI = (id) => {
  return (
    process.env.REACT_APP_API_PATH +
    "/post/" +
    id +
    "/comment?limit=2000&page=1&sort=createdAt%20ASC"
  );
};

// Create PostMessage
function* createPostMessageSaga({ payload: { postMessage, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true

  try {
    let bearer_token = localStorage.getItem("auth_token");

    let doc = postMessage.doc ? postMessage.doc[0] : "";
    let bodyFormData = new FormData();
    bodyFormData.append("question", postMessage.question);
    bodyFormData.append("details", postMessage.details);
    bodyFormData.append("memberId", postMessage.memberId);
    bodyFormData.append("doc", doc);

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPostRawData,
      process.env.REACT_APP_API_PATH + "/post",
      bodyFormData,
      headers
    );

    document
      .getElementsByClassName("dr-form-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    if(response.id){
      if(window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage("GO_TO_MESSAGE");
      }
    }
    if (response.id) {
      yield put(
        postMessageActionSuccessful(
          response,
          "Message Created Successfully.",
          1
        )
      );
      yield put(
        setSinglePostMessage(response, "Message Created Successfully.", 1)
      );
      yield put(
        setPostCommentList(
          response.comments,
          "Message Created Successfully.",
          1
        )
      );
    } else {
      yield put(
        createPostMessageFailed(response.message ? response.message : response)
      );
    }
  } catch (error) {
    yield put(createPostMessageFailed(error));
  }
}

// Update PostComment
function* createPostCommentSaga({ payload: { postComment, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
  try {
    const postComment_data = postComment;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPostRawData,
      process.env.REACT_APP_API_PATH + "/comment",
      postComment_data,
      headers
    );

    document
      .getElementsByClassName("dr-form-spinner")[0]
      .classList.add("d-none");
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled =false
    if (response.id) {
      yield put(
        postCommentActionSuccessful(
          response,
          "Comment Created Successfully.",
          1
        )
      );

      // Get All the comment List by Post ID
      const comments = yield call(
        dctGetRawData,
        commentListAPI(response.postId),
        headers
      );
      if (comments.data) {
        yield put(
          setPostCommentList(comments, "Data fetched Successfully.", "")
        );
      } else {
        yield put(setPostCommentList("", "No Record found.", ""));
      }
    } else {
      yield put(createPostCommentFailed(response.message));
    }
  } catch (error) {
    document
      .getElementsByClassName("dr-form-spinner")[0]
      .classList.add("d-none");
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled =false
    yield put(createPostCommentFailed(error));
  }
}

// Update PostMessage
function* updatePostMessageSaga({ payload: { postMessage, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
  try {
    let bearer_token = localStorage.getItem("auth_token");
    let doc = postMessage.doc ? postMessage.doc[0] : "";

    let bodyFormData = new FormData();
    bodyFormData.append("question", postMessage.question);
    bodyFormData.append("details", postMessage.details);
    bodyFormData.append("memberId", postMessage.memberId);
    bodyFormData.append("doc", doc);

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPutRawData,
      process.env.REACT_APP_API_PATH + "/post/" + postMessage.id,
      bodyFormData,
      headers
    );

    document
      .getElementsByClassName("dr-form-spinner")[0]
      .classList.add("d-none");
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
    if (response.id) {
      yield put(
        postMessageActionSuccessful(
          response,
          "Post Message Updated Successfully.",
          2
        )
      );
      yield put(
        setSinglePostMessage(response, "Post Message Updated Successfully.", 2)
      );
    } else {
      yield put(createPostMessageFailed(response.message));
    }
  } catch (error) {
    yield put(createPostMessageFailed(error));
  }
}

// Update PostComment
function* updatePostCommentSaga({ payload: { postComment, history } }) {
  document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
  try {
    const postComment_data = postComment;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const response = yield call(
      dctPutRawData,
      process.env.REACT_APP_API_PATH + "/comment/" + postComment_data.id,
      postComment_data,
      headers
    );

    document
      .getElementsByClassName("dr-form-spinner")[0]
      .classList.add("d-none");
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = false
    if (response.id) {
      yield put(
        postCommentActionSuccessful(
          response,
          "Comment Updated Successfully.",
          2
        )
      );

      // Get All the comment List by Post ID
      const comments = yield call(
        dctGetRawData,
        commentListAPI(response.postId),
        headers
      );
      if (comments.data) {
        yield put(
          setPostCommentList(comments, "Data fetched Successfully.", "")
        );
      } else {
        yield put(setPostCommentList("", "No Record found.", ""));
      }

      if (document.querySelector(`.${Config.names.slugName}-postComment-add-edit-modal .close`)) {
        document
          .querySelector(`.${Config.names.slugName}-postComment-add-edit-modal .close`)
          .click();
      }
    } else {
      yield put(createPostCommentFailed(response.message));
    }
  } catch (error) {
    yield put(createPostCommentFailed(error));
  }
}

// Get PostMessage
function* getPostMessageListSaga({ payload: { postMessage } }) {
  try {
    // const postMessage_data = postMessage;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    const response = yield call(
      dctGetRawData,
      process.env.REACT_APP_API_PATH + "/post",
      headers
    );

    if (response.id) {
      yield put(
        setPostMessageList(
          response.result.data,
          "Message data fetched Successfully.",
          ""
        )
      );
    } else {
      yield put(createPostMessageFailed(response.message));
    }
  } catch (error) {
    yield put(createPostMessageFailed(error));
  }
}

// Get Single PostMessage
function* getSinglePostMessageSaga({ payload: { postId } }) {
  try {
    const postMessage_Id = postId;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    yield put(setPostCommentList(null, "Loading...", ""));

    const response = yield call(
      dctGetRawData,
      process.env.REACT_APP_API_PATH +
        "/post/" +
        postMessage_Id +
        "/?populate=memberId,doctorId",
      headers
    );

    if (response.id) {
      yield put(
        setSinglePostMessage(response, "Data fetched Successfully. 1", "")
      );

      // Get All the comment List by Post ID
      const comments = yield call(
        dctGetRawData,
        commentListAPI(response.id),
        headers
      );
      if (comments.data) {
        yield put(
          setPostCommentList(comments, "Data fetched Successfully.", "")
        );
      } else {
        yield put(setPostCommentList("", "No Record found.", ""));
      }
    } else {
      yield put(setSinglePostMessage("", "No Record found.", ""));
      yield put(setPostCommentList("", "No Record found.", ""));
    }
  } catch (error) {
    yield put(setSinglePostMessage("", error, ""));
    yield put(setPostCommentList("", error, ""));
  }
}

export function* watchCreatePostComment() {
  yield takeEvery(CREATE_POST_COMMENT, createPostCommentSaga);
}

export function* watchUpdatePostComment() {
  yield takeEvery(UPDATE_POST_COMMENT, updatePostCommentSaga);
}

export function* watchCreatePostMessage() {
  yield takeEvery(CREATE_POST_MESSAGE, createPostMessageSaga);
}

export function* watchUpdatePostMessage() {
  yield takeEvery(UPDATE_POST_MESSAGE, updatePostMessageSaga);
}
export function* watchGetPostMessage() {
  yield takeEvery(GET_POST_MESSAGE_LIST, getPostMessageListSaga);
}

export function* watchGetSinglePostMessage() {
  yield takeEvery(GET_SINGLE_POST_MESSAGE, getSinglePostMessageSaga);
}

function* PostMessageSaga() {
  yield all([
    fork(watchCreatePostMessage),
    fork(watchUpdatePostMessage),
    fork(watchCreatePostComment),
    fork(watchUpdatePostComment),
    fork(watchGetPostMessage),
    fork(watchGetSinglePostMessage),
  ]);
}

export default PostMessageSaga;
