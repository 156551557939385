import {
  CREATE_POST_MESSAGE,
  UPDATE_POST_MESSAGE,
  CREATE_POST_COMMENT,
  UPDATE_POST_COMMENT,
  GET_POST_MESSAGE,
  GET_POST_MESSAGE_LIST,
  SET_POST_MESSAGE_LIST,
  SET_POST_COMMENT_LIST,
  CREATE_POST_MESSAGE_SUCCESS,
  CREATE_POST_MESSAGE_FAILED,
  CREATE_POST_COMMENT_SUCCESS,
  CREATE_POST_COMMENT_FAILED,
  API_ERROR,
  SET_SINGLE_POST_MESSAGE,
  SET_POST_MESSAGE_LISTING,
  GET_SINGLE_POST_MESSAGE,
} from "./actionTypes";

export const createPostMessage = (postMessage) => ({
  type: CREATE_POST_MESSAGE,
  payload: { postMessage },
});

export const updatePostMessage = (postMessage) => ({
  type: UPDATE_POST_MESSAGE,
  payload: { postMessage },
});

export const createPostComment = (postComment) => ({
  type: CREATE_POST_COMMENT,
  payload: { postComment },
});

export const updatePostComment = (postComment) => ({
  type: UPDATE_POST_COMMENT,
  payload: { postComment },
});

export const postMessageActionSuccessful = (postMessage, message, action) => {
  return {
    type: CREATE_POST_MESSAGE_SUCCESS,
    payload: postMessage,
    message: message,
    action_type: action,
  };
};

export const createPostMessageFailed = (error) => {
  return {
    type: CREATE_POST_MESSAGE_FAILED,
    payload: error,
  };
};

export const postCommentActionSuccessful = (postComment, message, action) => {
  return {
    type: CREATE_POST_COMMENT_SUCCESS,
    payload: postComment,
    message: message,
    action_type: action,
  };
};

export const createPostCommentFailed = (error) => {
  return {
    type: CREATE_POST_COMMENT_FAILED,
    payload: error,
  };
};

export const getPostMessage = (postMessage) => ({
  type: GET_POST_MESSAGE,
  payload: { postMessage },
});

export const getPostMessageList = (postMessage) => ({
  type: GET_POST_MESSAGE_LIST,
  payload: { postMessage },
});

export const setPostMessageList = (postMessage, message, action) => {
  return {
    type: SET_POST_MESSAGE_LIST,
    payload: postMessage,
    message: message,
    action_type: action,
  };
};

export const setPostCommentList = (postComment, message, action) => {
  return {
    type: SET_POST_COMMENT_LIST,
    payload: postComment,
    message: message,
    action_type: action,
  };
};
export const getSinglePostMessage = (postId) => ({
  type: GET_SINGLE_POST_MESSAGE,
  payload: { postId },
});

export const setSinglePostMessage = (postMessage, message, action) => {
  return {
    type: SET_SINGLE_POST_MESSAGE,
    payload: postMessage,
    message: message,
    action_type: action,
  };
};

export const setPostMessageListing = (postMessage, message) => {
  return {
    type: SET_POST_MESSAGE_LISTING,
    payload: postMessage,
    postMessageListing: postMessage,
    message: message,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
