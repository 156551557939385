import React, { useState } from "react";
import { Button, Modal, ModalBody, Container, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Config } from "../../../Config";
import excuseNoteLogo from "../../../assets/images/ExcuseDoctegrityLogo.png";
import closeIcon from "../../../assets/images/closeIcon.png";
import successLogo from "../../../assets/images/successLogo.png";

const SuccessModal = (props) => {
  const modal = props?.modal;
  const setModal = props?.setModal;
  const setOpenPdfModal = props?.setOpenPdfModal;
  const pdfModal = props?.pdfModal;
  const setEmailMessage = props?.setEmailMessage;
  const emailMessage = props?.emailMessage;
  const setStatusUpdate = props?.setStatusUpdate;
  const statusUpdate = props?.statusUpdate;
  const setEditNoteModal = props?.setEditNoteModal;
  const editNoteModal = props?.editNoteModal;
  const statusUpdateMessage = props?.statusUpdateMessage;
  var messageArray = statusUpdateMessage?.split("from");
  var statusUpdateMessage1 = messageArray[0]?.trim();
  var statusUpdateMessage2 = "from " + messageArray[1]?.trim();
  const emailSentMessage = props?.emailSentMessage;
  var delimiter = "Email send to member successfully. ";
  var sentEmailMessage1 = delimiter;
  var sentEmailMessage2 = emailSentMessage?.slice(delimiter.length);

  return (
    <React.Fragment>
      <Modal
        centered={true}
        size="md"
        isOpen={modal}
        toggle={() => {
          setOpenPdfModal(false);
          setEmailMessage(false);
          setStatusUpdate(false);
          setEditNoteModal(false);
          setModal(!modal);
        }}
        // scrollable={true}
        className={`${Config.names.slugName}-member-add-edit-modal`}
      >
        <ModalBody>
          <Container>
            <div className="form-horizontal" style={{height:290}}>
              <div
                className="d-flex justify-content-end cursor-pointer"
                onClick={() => {
                  setModal(!modal);
                  setOpenPdfModal(false);
                  setEmailMessage(false);
                  setStatusUpdate(false);
                  setEditNoteModal(false);
                }}
              >
                <img src={closeIcon} alt="close" />
              </div>
              {/* logo */}
              <div className="justify-content-center mt-2">
                <div
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img
                    src={excuseNoteLogo}
                    alt="logo"
                    style={{ width: "12rem" }}
                  />
                </div>
              </div>
              {/* success */}
              <div className="justify-content-center mt-5">
                <div
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img src={successLogo} alt="logo" style={{ width: "5rem" }} />
                </div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <p className="mr-3 mt-2" style={{ fontWeight: 600 }}>
                  {props.t("Success")}
                </p>
                {pdfModal ? (
                  <p className="mb-1 mt-3">
                    {props.t("Your Excuse Note has being")}{" "}
                    <span className="text-primary">
                      {props.t("downloaded successfully")}
                    </span>
                  </p>
                ) : statusUpdate ? (
                  <>
                    <p className="mb-1 mt-3">{props.t(statusUpdateMessage1)}</p>
                    <p className="text-primary">{props.t(statusUpdateMessage2)}</p>
                  </>
                ) : emailMessage ? (
                  <>
                    <p className="mb-1 mt-3">{props.t(sentEmailMessage1)}</p>
                    <p className="text-primary">{props.t(sentEmailMessage2)}</p>
                  </>
                ) : editNoteModal ? (
                  <>
                    <p className="mb-1 mt-3">
                      {props.t("Admin Note has being updated successfully")}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="mb-1 mt-3">
                      {props.t("Admin New Note has being created successfully")}
                    </p>
                  </>
                )}
              </div>
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SuccessModal;
