import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { setSingleCompany, setCompanyListing } from "../../../store/actions";

import { Row, Col, UncontrolledTooltip } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Include Both Helper File with needed methods
import { dctUpdateStatus } from "../../../helpers/backend_helper";
import { loadingMessage } from "../../../helpers/Commons";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { BarLoader, BeatLoader } from 'react-spinners';
import { Config } from "./../../../Config";

const CompanyList = (props) => {
  const { currentPage, perPage, total, getDataOnEnter, ajaxLoader } = props;

  let companiesObj = [];

  companiesObj = useSelector((state) => state.Company.companyListing);
  companiesObj = companiesObj ? companiesObj : [];
  const dispatch = useDispatch();

  const [customPageNo, setCustomPageNo] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);

  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [companyStatusMeta, setCompanyStatusMeta] = useState({
    rowIndex: "",
    companyId: "",
    companyStatus: 0,
  });

  useEffect(() => {
    // sleep()
  }, []);

  const openCompanyEditModal = (data) => {
    document.querySelector("#company_update_modal").click();
    //Push Single Company Data to store
    dispatch(setSingleCompany(data, "", ""));
  };

  const openCSVuploadModal = (data) => {
    document.getElementById("csv_upload_modal").click();
    //Push Single Company Data to store
    dispatch(setSingleCompany(data, "", ""));
  };

  const toggleCompanyStatus = async (companyStatusMeta) => {
    if (!companyStatusMeta) return;

    const { companyId, companyStatus, rowIndex } = companyStatusMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Activation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    const loaderCls = ["bx-loader", "bx-spin", "font-size-18"];
    let toogleUserCls = companyStatus === 1 ? "bx-user-check" : "bx-user-x";
    setStatusLoader(true);

    // Adding the bx-loader and removing the box-user-check class
    document
      .querySelector("#status_" + companyId)
      .children[0].classList.add(...loaderCls);
    document
      .querySelector("#status_" + companyId)
      .children[0].classList.remove(toogleUserCls);

    let gStatus = companyStatus === "active" ? "inactive" : "active";
    const postData = {
      id: companyId,
      status: gStatus,
    };
    const url = "company/" + companyId;
    const response = await dctUpdateStatus(postData, url).then((data) => data);
    if (response.status === "active") {
      setdynamic_description("Activated successfully.");
      setdynamic_title("Activated");
    }
    if (response.status === "inactive") {
      setdynamic_description("Deactivated successfully.");
      setdynamic_title("Deactivated");
    }

    if (response.id) {
      seterror_dlg(false);
    } else {
      seterror_dlg(true);
      setsuccess_dlg(false);
    }

    companiesObj[rowIndex].status = response.status;
    dispatch(setCompanyListing(companiesObj, null));
    setStatusLoader(false);
    toogleUserCls =
      response.status === "active" ? "bx-user-check" : "bx-user-x";
    document
      .querySelector("#status_" + companyId)
      .children[0].classList.remove(...loaderCls);
    document
      .querySelector("#status_" + companyId)
      .children[0].classList.add(toogleUserCls);
  };

  const countActiveMembers = (members) => {
    let count = 0;
    let v = 0;
    for (v = 0; v < members.length; v++) {
      if (members[v].status === "active") {
        count++;
      }
    }
    return count;
  };
  
  const companyItemsBody = (companiesObj) => {
    return companiesObj.length > 0 ? (
      companiesObj.map((company, index) => {
        return (
          <Tr key={index} data-row-id={company.id}>
            <Td className="text-center">
              <div className="avatar-xs">
                <Link
                  to={"/company-overview/id/" + company.id}
                  className="text-dark"
                >
                  <span className="avatar-title rounded-circle text-capitalize">
                    {company.companyName.charAt(0)}
                  </span>
                </Link>
              </div>
            </Td>
            <Td className="text-start">
              <h5 className="text-truncate font-size-14">
                <Link
                  to={"/company-overview/id/" + company.id}
                  className="text-dark"
                >
                  {company.companyName}
                </Link>
              </h5>
            </Td>
            <Td className="text-start">
              {company.groupId ? (
                <Link to={"groups-overview/id/" + company.groupId.id}>
                  <span className="text-truncate font-size-14 text-dark d-block">
                    {company.groupId.groupName}
                  </span>
                </Link>
              ) : (
                ""
              )}
            </Td>
            <Td className="text-center">
              {company.activeMembers}
            </Td>
            <Td className="text-center">{company.totalMembers}</Td>
            <Td className="text-center">
              <Link
                to="#"
                className={
                  company.status === "active"
                    ? "badge badge-soft-primary font-size-11 m-1"
                    : "badge badge-soft-danger font-size-11 m-1"
                }
              >
                {company.status === "active" ? "Active" : "Inactive"}
              </Link>
            </Td>
            <Td className="text-center">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id="message_1"
                    data-company-id={company.id}
                    onClick={() => {
                      openCSVuploadModal(company);
                    }}
                  >
                    <i className="bx bx-cloud-upload"></i>
                    <UncontrolledTooltip placement="top" target="message_1">
                      {props.t("Upload")}
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"edit" + company.id}
                    onClick={() => {
                      openCompanyEditModal(company);
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"edit" + company.id}
                    >
                      {props.t("Edit")}
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"status_" + company.id}
                    data-status={company.status}
                    data-id={company.id}
                    onClick={() => {
                      setconfirm_both(true);
                      setCompanyStatusMeta({
                        rowIndex: index,
                        companyId: company.id,
                        companyStatus: company.status,
                      });
                    }}
                  >
                    {company.status === "active" ? (
                      <i className="bx bx-user-check text-success"></i>
                    ) : (
                      <i className="bx bx-user-x text-danger"></i>
                    )}
                    <UncontrolledTooltip
                      placement="top"
                      target={"status_" + company.id}
                    >
                      {company.status ? "Active" : "Inactive"}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
              {confirm_both ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="secondary"
                  onConfirm={() => {
                    toggleCompanyStatus(companyStatusMeta);
                  }}
                  onCancel={() => {
                    setconfirm_both(false);
                    setsuccess_dlg(false);
                  }}
                >
                  {props.t("This process will change the Company status!")}
                </SweetAlert>
              ) : null}
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="7" className="text-center"></Td>
      </Tr>
    );
  };
  const handleSearchPage = (e) => {
    if (e.key === "Enter") {
      getDataOnEnter(e.currentTarget.value);
    }
  };
  return (
    <React.Fragment>
      <div className="company-list">
        <Row>
          <Col lg="12">
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {error_dlg ? (
              <SweetAlert
                error
                title={dynamic_title}
                onConfirm={() => {
                  seterror_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {/* {ajaxLoader ? (
              <div className="${Config.names.slugName}-listing-loader">
                {loadingMessage("Loading...")}
              </div>
            ) : (
              ""
            )} */}
            <div className="table-responsive">
              <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}>
                <Thead className="thead-light">
                  <Tr>
                    <Th scope="col" className="text-center" style={{ width: "70px" }}>
                      #
                    </Th>
                    <Th scope="col" className="text-start">{props.t("Company Name")}</Th>
                    <Th scope="col" className="text-start">{props.t("Group Name")}</Th>
                    <Th scope="col" className="text-center">{props.t("Enrolled Members")}</Th>
                    <Th scope="col" className="text-center">{props.t("Total Members")}</Th>
                    <Th scope="col" className="text-center" style={{ width: "70px" }}>
                      {props.t("Status")}
                    </Th>
                    <Th scope="col" className="text-center" style={{ width: "70px" }}>
                      <center>{props.t("Action")}</center>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>{companyItemsBody(companiesObj)}</Tbody>
              </Table>
              {props.companyListMessage ? (
                <div className="text-center text-danger">
                  <i className="bx bx-search-alt font-size-18 align-middle mr-2"></i>{" "}
                  {props.companyListMessage}{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className={total > perPage ? `${Config.names.slugName}-pager d-none` : `${Config.names.slugName}-pager d-none`}>
              <nav className="d-flex justify-content-center mt-4">
                <ReactPaginate
                  initialPage={currentPage}
                  forcePage={currentPage}
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={total}
                  // itemsCountPerPage={perPage}
                  breakLabel={"..."}
                  label={<input />}
                  breakClassName={"break-me"}
                  onPageChange={props.getUserData}
                  containerClassName={
                    "pagination pagination-rounded align-items-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  activeLinkClassName={"active"}
                />
                <input
                  className="ml-3 text-center"
                  type="text"
                  size="5"
                  placeholder="Go to"
                  value={customPageNo}
                  onChange={(e) => setCustomPageNo(e.currentTarget.value)}
                  onKeyPress={handleSearchPage}
                  style={{ height: "25px" }}
                />
              </nav>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <BeatLoader size={12} color={Config.colors.pieChartActiveColor} loading={ajaxLoader} />
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withRouter(withNamespaces()(CompanyList));

// export default CompanyList;
