import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row, Label, Button } from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";
import PasswordManagement from "./PasswordManagement";

// import { dctAlert } from "../../../../helpers/Commons";
import { dctPutDosespotData } from "../../../../helpers/backend_helper";

function AccountSettings(props) {
  const [isEmail, setIsEmail] = useState(false);
  const [isSms, setIsSms] = useState(false);
  const [isSmsLoading, setSmsIsLoading] = useState(false);
  const [isEmailLoading, setEmailIsLoading] = useState(false);

  const { authDoctorId, auth_token } = useSelector((state) => state.Login);
  const { singleDoctor } = useSelector((state) => state.Doctor);

  let notificationPreference = singleDoctor?.notificationPreference;
  let emailSettingValue = notificationPreference?.email === true ? true : false;
  let smsSettingValue = notificationPreference?.sms === true ? true : false;

  useEffect(() => {
    setIsSms(smsSettingValue);
    setIsEmail(emailSettingValue);
  }, [singleDoctor]);

  const updateNotificationSettings = async (type) => {
    let notificaitonType = type ? type : "email";

    let postData = "";
    if (notificaitonType === "email") {
      setSmsIsLoading(false);
      setEmailIsLoading(true);
      postData = {
        notificationPreference: "email",
      };
    }
    if (notificaitonType === "sms") {
      setSmsIsLoading(true);
      setEmailIsLoading(false);
      postData = {
        notificationPreference: "sms",
      };
    }

    const headers = {
      headers: {
        Authorization: auth_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    let response = "";
    let url = `${process.env.REACT_APP_API_PATH}/doctor/${authDoctorId}/toggle-notification-preference`;
    response = await dctPutDosespotData(url, postData, headers).then(
      (data) => data
    );

    if (response.id) {
      setSmsIsLoading(false);
      setEmailIsLoading(false);
      // setIsEmail(!isEmail);
    } else {
      setSmsIsLoading(false);
      setEmailIsLoading(false);
      // setIsEmail(!isEmail);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col xl={5}>
          <PasswordManagement />
        </Col>

        <Col xl={7}>
          <div className="my-4">
            <div className="bg-soft-primary p-2">
              <Label className="font-weight-bold">
                {props.t(`Notification Settings`)}
              </Label>
              <p className="text-muted">
                {props.t(
                  `You'll be automatically notified via sms 15 minutes prior to the teleconference, here you can setup additional notifications.`
                )}
              </p>
              <div className="mt-2 d-flex">
                <div>
                  <p className="font-weight-bold">
                    {props.t(`Notify me via e-mail`)}{" "}
                  </p>
                </div>
                <div>
                  <div className="ml-3 square-switch">
                    <input
                      type="checkbox"
                      id="square-switch1"
                      switch="bool"
                      name="email"
                      checked={isEmail}
                      onChange={(e) => {
                        setIsEmail(!isEmail);
                        updateNotificationSettings("email");
                      }}
                    />
                    <label
                      htmlFor="square-switch1"
                      data-on-label={props.t("YES")}
                      data-off-label={props.t("NO")}
                    ></label>
                    {isEmailLoading ? (
                      <i className="bx bx-loader bx-spin font-size-20 align-top ml-2 text-success"></i>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <p>{props.t(`Prior to scheduled appointment`)}</p>

              <hr className="my-2 " />

              <div className="mt-3 d-flex">
                <div>
                  <p className="font-weight-bold">
                    {props.t(`Notify me via SMS`)}{" "}
                  </p>
                </div>
                <div>
                  <div className="ml-3 square-switch">
                    <input
                      type="checkbox"
                      name="sms"
                      id="square-switch2"
                      switch="bool"
                      checked={isSms}
                      onChange={() => {
                        setIsSms(!isSms);
                        updateNotificationSettings("sms");
                      }}
                    />
                    <label
                      htmlFor="square-switch2"
                      data-on-label="YES"
                      data-off-label="NO"
                    ></label>
                    {isSmsLoading ? (
                      <i className="bx bx-loader bx-spin font-size-20 align-top ml-2 text-success"></i>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <p>{props.t(`Prior to scheduled appointment`)}</p>
            </div>
          </div>
          {/* <div className="my-4 float-right">
            <Button color="secondary" className="mx-2">
              {props.t("Cancel")}
            </Button>
            <Button color="warning" className="mx-2">
              {props.t("Save changes")}
            </Button>
          </div> */}
        </Col>
      </Row>
    </Fragment>
  );
}

export default withRouter(withNamespaces()(AccountSettings));
