import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import axios from "axios";
//i18n
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { dctDateFormat } from "../../../helpers/Commons";

import { setSceduleConsultation } from "../../../store/actions";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [totalConsultation, setTotalConsultation] = useState(0);
  const [viewAllConsultLink, setViewAllConsultLink] = useState(
    "/member/my-consultations"
  );
  const dispatch = useDispatch();
  let scheduledConsultationList = [];
  const { scheduledConsultation } = useSelector((state) => state.Member);
  const { authDoctorId, authUserType } = useSelector((state) => state.Login);
  scheduledConsultationList = scheduledConsultation;

  useEffect(() => {
    if (
      authUserType === "member" ||
      authUserType === "doctor" ||
      authUserType === "admin"
    ) {
      getScheduledConsultations(); // works for doctor and member
    }
    if (authUserType === "doctor") {
      setViewAllConsultLink("/dashboard");
    }
  }, [viewAllConsultLink]);

  const getScheduledConsultations = () => {
    let url = "";
    if (authUserType === "member") {
      url =
        `${process.env.REACT_APP_API_PATH}/consultation/scheduled` +
        `?sort=updatedAt%20DESC&populate=false&page=1&limit=10`;
    }
    if (authUserType === "doctor") {
      url =
        `${process.env.REACT_APP_API_PATH}/consultation/available` +
        `?doctorId=${authDoctorId}&populate=false&page=1&limit=10`;
    }
    if (authUserType === "admin") {
      url =
        `${process.env.REACT_APP_API_PATH}/consultation/scheduled` +
        `?sort=updatedAt%20DESC&populate=false&page=1&limit=10`;
    }
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios.get(url, options).then(function (response) {
      if (response.data) {
        if (response.data.data) {
          setTotalConsultation(response.data.total);
          dispatch(setSceduleConsultation(response.data.data));
        }
      }
    });
  };

  const renderConsultationList = (scheduledConsultationList) => {
    return scheduledConsultationList && scheduledConsultationList.length > 0 ? (
      scheduledConsultationList.map((consult, index) => {
        return (
          <Link
            key={index}
            to={`/televisits-overview/id/${consult.id}`}
            onClick={() => {
              if (props.location) {
                localStorage.setItem(
                  "televisits_location",
                  props.location.pathname
                );
              }
              menu ? setMenu(!menu) : setMenu(menu);
            }}
            className="text-reset notification-item"
          >
            <div className="media">
              <div className="avatar-xs mr-3">
                <span className="avatar-title bg-primary rounded-circle font-size-16">
                  {consult.consultationType === "video" ? (
                    <i className="bx bx-video"></i>
                  ) : (
                    <i className="bx bx-phone"></i>
                  )}
                </span>
              </div>
              <div className="media-body">
                <h6 className="mt-0 mb-1">{consult?.details_chiefcomplaint}</h6>
                <div className="font-size-12 text-muted">
                  <p className="mb-1">{consult?.phone_service}</p>
                  <p className="mb-0">
                    <i className="mdi mdi-clock-outline"></i>{" "}
                    {dctDateFormat(consult.schedule_from)} -{" "}
                    {dctDateFormat(consult.schedule_to)}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        );
      })
    ) : (
      <div className="mt-4 mb-2 text-reset text-center notification-item">
        <div className="text-center">{props.t(`No Appointments found.`)}</div>
      </div>
    );
  };

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell"></i>
          <span className="badge badge-danger badge-pill">
            {totalConsultation}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href={viewAllConsultLink} className="small">
                  {" "}
                  View All
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {renderConsultationList(scheduledConsultationList)}
          </SimpleBar>
          <div className="p-2 border-top">
            {scheduledConsultation ? (
              <Link
                className="btn btn-sm btn-link font-size-14 btn-block text-center text-primary"
                to={viewAllConsultLink}
              >
                {props.t("View all")}
              </Link>
            ) : null}
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withNamespaces()(NotificationDropdown);
