import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Row } from "reactstrap";
import discountImgTelaQ from "../../assets/images/member/discount-card-telaq.png";
import discountImgDCT from "../../assets/images/member/discount-card-dct.png";

const DiscountCard = () => {
    return (
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    {process.env.REACT_APP_SLUG === 'doctegrity' &&
                        <Row>
                            <Col xl={12}>
                                <img style={{ width: "37rem", maxWidth: "100%" }} src={discountImgDCT} alt="DiscountCard" />
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </Fragment>
    )
}

export default DiscountCard