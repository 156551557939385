import React from 'react'
import { jsPanel } from "jspanel4/es6module/jspanel";
import "jspanel4/es6module/extensions/modal/jspanel.modal";
import "jspanel4/dist/jspanel.min.css";

const TBVideoCallJsPanel = (props) => {
    const VCjspanel = async () => {
        const url = "/tokbox?apikey="+props.apikey+"&session="+props.session+"&token="+props.token;
        const options = {
            headerTitle: "action",
            position: "right-top 0% 0%",
            panelSize: {
                width: () => {
                    return window.innerWidth;
                },
                height: () => {
                    return window.innerHeight;
                },
            },
            dragit: {
                disable: true,
            },
            addClass: "dctCustom",
            animateIn: "jsPanelFadeIn",
            animateOut: "jsPanelFadeOut",
            theme: "none filled",
            closeOnEscape: true,
            borderRadius: ".1rem",
            borderStyle: "none",
            // maximizedMargin: ()=>{
            // 	return 	[69, 5, 5, 250 ]
            // },
            zIndex: 100000,

            setStatus: "maximized",
            header: false,
            border: "dimgrey",
            addCloseControl: 1,
            callback: function () {
                console.log(url)
                this.content.innerHTML = `<iframe
                                  src=${url}
                                  width="100%"
                                  height="100%"
                                />`;
            },

            onclosed: () => {
                // remove closed jsPanel and its mounted component from state
                // const appPanels = app.state.panels;
                // if (appPanels[action]) {
                //   delete appPanels[action];
                //   app.setState({ panels: { ...appPanels } });
                // }
            },
        };

        const panel = jsPanel.create(options);
    }


    return (
        <button className="btn btn-success" onClick={VCjspanel}>
            Video Call
        </button>
    )
}

export default TBVideoCallJsPanel
