import React, { useState, useEffect } from "react";

//Style from Reactstrap
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  CardHeader,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../../helpers/backend_helper";

import {
  getrxPharmacy,
  rxPharmacyActionSuccessful,
  setrxPrescriptionListing,
  setSinglerxPharmacy,
} from "../../../store/actions";

import PrescriptionList from "../../../components/rxPharmacy/PrescriptionList";

//Import Create and Update partner

import Pusher from "pusher-js";
import axios from "axios";

import PushNotification from "./PushNotification";
import { dctAlert } from "../../../helpers/Commons";

const Prescription = (props) => {
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [gdata, setGData] = useState({
    loading: true,
    pharmacy: {},
    message: null,
  });

  const [editState, setEditState] = useState({});

  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    pharmacySearchParam: "",
    type: "filter",
    reset: false,
  });

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        pharmacySearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: false,
      });
      return fetchrxPharmacyData(searchParam, selectedPage);
    }
    fetchrxPharmacyData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        partnerSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      return fetchrxPharmacyData(searchParam, selectedPage);
    }
    // if(selectedPage > 1)
    fetchrxPharmacyData(searchParam, selectedPage);
  };

  const fetchrxPharmacyData = (param, currentPage = 1) => {
    let searchParam = "";
    if (param) {
      searchParam = param.partnerSearchParam
        ? "&q=" + param.partnerSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        SecretKey: props.secretkey,
      },
    };
    setGData({
      loading: true,
    });
    let url =
      process.env.REACT_APP_API_PATH +
      `/prescriptionfill` +
      `?page=${currentPage}&limit=${postsPerPage}` +
      searchParam;

    axios
      .get(url, headers)
      .then(function (response) {
        const data = response.data;
        let message = "";
        message = data?.data.length > 0 ? "" : "No record found.";
        dispatch(setrxPrescriptionListing(data?.data, message));
        setGData({
          loading: false,
          pharmacy: response.data.data,
          message: message,
        });

        setTotalPosts(data.total / postsPerPage);
        setCurrentPage(currentPage - 1);
        if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
          document
            .getElementsByClassName("dr-csearch-spinner")[0]
            .classList.add("d-none");
        }
      })
      .catch(function (err) {
        console.log(err);
        if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
          document
            .getElementsByClassName("dr-csearch-spinner")[0]
            .classList.add("d-none");
        }
        var message = checkAjaxError(err);
        setGData({
          loading: false,
          phamacy: null,
          message: message,
        });
        setTotalPosts(0);
        setCurrentPage(0);
        dispatch(setrxPrescriptionListing(null, message));
      })
      .then(function () {});
  };

  // Similar to componentDidMount and componentDidUpdate:
  // useEffect(() => {
  //   fetchrxPharmacyData();
  // }, []);

  const { partners, message } = gdata;
  const partner_action = useSelector((state) => state.Partner.action_type);
  /////////////////////////////////////////////////////////////////////////////
  const [notifications, setNotifications] = useState([]);

  const [pageSize, setPageSize] = useState(
    9999999999 // process.env.REACT_APP_PER_PAGE
  );
  const [totalPages, setTotalPages] = useState(0);

  const [doctorData, setDoctorData] = useState(null);

  const { authDoctorId } = useSelector((state) => state.Login);

  const [loading, setLoading] = useState(false);

  const loadDoctorData = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_API_PATH}/doctor/${authDoctorId}?populate=false`;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios.get(url, headers).then((response) => {
      setDoctorData(response.data);
    });
    setLoading(false);
  };

  const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_APP_ID}`, {
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
  });

  const subscribePrescriptionCreatedNotifications = async () => {
    if (!doctorData || !doctorData.state) return;
    let states = [];
    doctorData.multiStates &&
      doctorData.multiStates.map((items, index) => {
        states.push(items.value);
      });

    let consultsChannel = states.map((channelName) =>
      pusher.subscribe(channelName)
    );
    for (let items of consultsChannel) {
      items.bind("prescription-filled", function (data) {
        pushNotification(data.eventId);
      });
    }
  };

  let localNotifications = [];

  const pushNotification = async (eventId) => {
    let url = `${process.env.REACT_APP_API_PATH}/eventlog/${eventId}`;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios.get(url, headers).then((response) => {
      localNotifications = [response.data, ...localNotifications];
      setNotifications(
        localNotifications.length < 10
          ? localNotifications
          : localNotifications.slice(0, 10)
      );
    });
  };

  const loadNotifications = async () => {
    if (!doctorData) return;
    let arr = ["%22prescription-filled%22"];
    // doctorData.multiStates &&
    //   doctorData.multiStates.map((items, index) => {
    //     arr.push("%22" + items.value + "%22");
    //   });
    if (doctorData.licenseInfo) {
      let licenseInfo = doctorData.licenseInfo;
      licenseInfo.map((items) => {
        Object.keys(items).map(function (key) {
          arr.push("%22" + key + "%22");
        });
      });
    } else {
      arr.push("%22" + doctorData.state + "%22");
    }
    setLoading(true);
    let nin = ["%22posts-created%22,%22consult-confirmed%22"];
    let where = `{"isActive":true,"eventType":{"nin": [${nin}]}, "channelName":{"in": [${arr}]}}`;
    let url =
      `${process.env.REACT_APP_API_PATH}/eventlog?limit=${pageSize}&page=${currentPage}` +
      `&where=${where}&populate=false&sort=createdAt%20ASC`;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios
      .get(url, headers)
      .then((response) => {
        if (response.data.data) {
          localNotifications = [...response.data.data];
          localNotifications = localNotifications.filter(p => p?.prescription?.isDeleted == false && p?.prescription?.archive == false);
          setNotifications(localNotifications);
        }
        if (response.data.total) {
          setTotalPages(Math.ceil(response.data.total / pageSize));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("Could not fetch eventlog. Error -> ", error.message);
        setLoading(false);
        setError(true);
        setErrorMsg(error?.response?.data?.message);
        return;
      });
  };

  useEffect(() => {
    loadNotifications();
  }, [currentPage]);

  useEffect(() => {
    loadDoctorData();
  }, [authDoctorId]);

  // useEffect(() => {
  //   setReports(reports)
  // }, [reports])

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize]);

  const cleanup = () => {
    pusher.unsubscribe("prescription");
    if (doctorData) {
      pusher.unsubscribe(doctorData.state);
    }
  };

  useEffect(() => {
    if (!doctorData) return;
    loadNotifications();
    subscribePrescriptionCreatedNotifications();
    return cleanup;
  }, [doctorData]);
  /////////////////////////////////////////////////////////////////////////////////
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
          {error ? 
          <Col lg="12">
            <div className="text-left mb-2">
              {true ? dctAlert(3, errorMsg) : ""}
            </div>
          </Col>
          :
            <Col lg="12">
              <Card>
                <CardBody>
                  <PushNotification
                    {...props}
                    notifications={notifications}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    //getUserData={getUserData}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    ajaxLoader={loading}
                  />
                </CardBody>
              </Card>
            </Col>}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { partner } = state.Partner;
  const { singlePartner } = state.Partner;
  const { message } = state.Partner;
  return { partner, message, singlePartner };
};

export default withRouter(
  connect(mapStatetoProps, {
    getrxPharmacy,
    rxPharmacyActionSuccessful,
    setSinglerxPharmacy,
  })(withNamespaces()(Prescription))
);
