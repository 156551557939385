import React, { useState } from 'react';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { withRouter} from 'react-router-dom';
import { withNamespaces } from "react-i18next";
const EmailToolbar = (props) => {

 const [folder_Menu, setfolder_Menu] = useState(false);
 const [tag_Menu, settag_Menu] = useState(false);
 const [more_Menu, setmore_Menu] = useState(false);

    return (
              <React.Fragment>
                <div className="btn-toolbar p-3" role="toolbar">
                    <div className="btn-group mr-2 mb-2 mb-sm-0">
                        <Button type="button" color="primary" className="waves-light waves-effect"><i className="fa fa-inbox"></i></Button>
                        <Button type="button" color="primary" className="waves-light waves-effect"><i className="fa fa-exclamation-circle"></i></Button>
                        <Button type="button" color="primary" className="waves-light waves-effect"><i className="far fa-trash-alt"></i></Button>
                    </div>
                    <Dropdown isOpen={folder_Menu} toggle={() => { setfolder_Menu(!folder_Menu) } } className="btn-group mr-2 mb-2 mb-sm-0">
                        <DropdownToggle className="btn btn-primary waves-light waves-effect dropdown-toggle" tag="i">
                            <i className="fa fa-folder"></i> <i className="mdi mdi-chevron-down ml-1"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem to="#">{props.t("Updates")}</DropdownItem>
                            <DropdownItem to="#">{props.t("Social")}</DropdownItem>
                            <DropdownItem to="#">{props.t("Team Manage")}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown isOpen={tag_Menu} toggle={() => { settag_Menu(!tag_Menu) } } className="btn-group mr-2 mb-2 mb-sm-0">
                        <DropdownToggle className="btn btn-primary waves-light waves-effect dropdown-toggle" tag="i">
                            <i className="fa fa-tag"></i> <i className="mdi mdi-chevron-down ml-1"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem to="#">{props.t("Updates")}</DropdownItem>
                            <DropdownItem to="#">{props.t("Social")}</DropdownItem>
                            <DropdownItem to="#">{props.t("Team Manage")}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown isOpen={more_Menu} toggle={() => { setmore_Menu(!more_Menu) } } className="btn-group mr-2 mb-2 mb-sm-0">
                        <DropdownToggle className="btn btn-primary waves-light waves-effect dropdown-toggle" tag="div">
                            More <i className="mdi mdi-dots-vertical ml-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem to="#">{props.t("Mark as Unread")}</DropdownItem>
                            <DropdownItem to="#">{props.t("Mark as Important")}</DropdownItem>
                            <DropdownItem to="#">{props.t("Add to Tasks")}</DropdownItem>
                            <DropdownItem to="#">{props.t("Add Star")}</DropdownItem>
                            <DropdownItem to="#">{props.t("Mute")}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </React.Fragment>
          );
    }
export default withRouter(withNamespaces()(EmailToolbar));