import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  createService,
  updateService,
  createServiceFailed,
  serviceActionSuccessful,
  setSingleService,
} from "../../store/actions";

//Include Both Helper File with needed methods
import { dctGetAsyncData } from "../../helpers/backend_helper";
import { Config } from "../../Config";

const CreateUpdateService = (props) => {
  const [modal, setmodal] = useState(false);
  const [msgEnable, setmsgEnable] = useState(true);

  function toggleModalClose(e) {
    //checking Store props
    if (props.singleService) {
      props.updateServiceDataFlag();
    }
  }

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    document.getElementsByTagName('button')[document.getElementsByTagName('button').length - 1].disabled = true
    document
      .getElementsByClassName("dr-gform-spinner")[0]
      .classList.remove("d-none");

    setmsgEnable(true);

    //call reducer method
    if (values.actionType === "edit") {
      props.updateService(values, props.history);
    } else {
      props.createService(values, props.history);
    }
  }

  const dispatch = useDispatch();
  const editFormServiceItem = (e) => {
    setmsgEnable(false);
    dispatch(serviceActionSuccessful(null, "", ""));
  };

  const createFormServiceItem = (e) => {
    setmsgEnable(false);
    dispatch(serviceActionSuccessful(null, "", ""));
    dispatch(setSingleService(null, "", ""));
  };

  let serviceData = useSelector((state) => state.Service.singleService);

  serviceData = serviceData ? serviceData : [];
  const action_type = props.service_action_type === "edit" ? "edit" : "create";
  const button_label =
    props.service_action_type === "edit" ? "Submit" : "Create Service";
  const modal_title =
    props.service_action_type === "edit" ? "Update Service" : "Create Service";

  return (
    <React.Fragment>
      {(() => {
        switch (props.service_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="service_update_modal"
                onClick={() => {
                  setmodal(!modal);
                  editFormServiceItem();
                }}
              ></i>
            );
          case "edit_btn":
            return (
              <Button
                type="button"
                color="primary"
                id="service_update_modal"
                className="waves-effect waves-light mr-2"
                onClick={() => {
                  setmodal(!modal);
                  editFormServiceItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1"></i> {props.t("Edit")}
              </Button>
            );
          default:
            return (
              <Link
                onClick={() => {
                  setmodal(!modal);
                  createFormServiceItem();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id="service_create_modal"
              >
                <i className="mdi mdi-plus mr-1"></i>{" "}
                {props.t("Create Service")}
              </Link>
            );
        }
      })()}

      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setmodal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-service-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal(!modal);
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.createServiceError &&
              props.createServiceError &&
              msgEnable ? (
                <Alert color="danger">{props.createServiceError}</Alert>
              ) : null}
              {props.message && props.message && msgEnable ? (
                <Alert color="success">{props.message}</Alert>
              ) : null}

              {action_type === "edit" ? (
                <AvField
                  name="id"
                  type="hidden"
                  //label = {props.t("service Id")}
                  value={
                    serviceData.id != undefined
                      ? serviceData.id
                      : serviceData._id
                  }
                  readOnly={true}
                />
              ) : (
                ""
              )}
              <AvField name="actionType" type="hidden" value={action_type} />

              <Row>
                <Col lg={12}>
                  <div className="form-service">
                    <AvField
                      name="name"
                      label={props.t("Service Name")}
                      className="form-control"
                      placeholder={props.t("Service Name")}
                      type="text"
                      required
                      value={serviceData.name}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <AvField
                      name="description"
                      label={props.t("Service Description")}
                      className="form-control"
                      placeholder={props.t("service Description")}
                      type="textarea"
                      value={serviceData.description}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setmodal(!modal)}
                    >
                      {props.t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                    >
                      <span className="dr-gform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      {button_label}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createServiceError } = state.Service;
  const { service } = state.Service;
  const { message } = state.Service;
  return { createServiceError, service, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createService,
    updateService,
    createServiceFailed,
    setSingleService,
  })(withNamespaces()(CreateUpdateService))
);
