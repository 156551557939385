import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Badge,
  Alert,
} from "reactstrap";

// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { connect, useSelector, useDispatch } from "react-redux";

import { brandActionSuccessful, setSingleBrand } from "../../store/actions";

import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

//Include Both Helper File with needed methods
import { loadingMessage, dctDateFormat } from "../../helpers/Commons";

import axios from "axios";

//Import Create and Update Group
import CreateUpdateBrand from "../../components/Brand/CreateUpdateBrand";
// import CreateUpdateCompany from "../../components/Group/CreateUpdateCompany";
import Company from "./Company/company";

const BrandOverview = (props) => {
  const [brandData, setBrandData] = useState({
    gloading: true,
    gdata: null,
    gid: null,
    gmessage: null,
  });

  const dispatch = useDispatch();

  const fetchSingleGroup = (goup_id) => {
    if (!goup_id) {
      return;
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = process.env.REACT_APP_API_PATH + "/brand/" + goup_id;

    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data;
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(setSingleBrand(response_data, message, null));
        setBrandData({
          gloading: false,
          gdata: response_data,
          gid: goup_id,

          gmessage: message,
        });
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response.status === 400) {
          message = "No record found.";
        }
        setBrandData({
          gloading: false,
          gdata: null,
          gid: null,
          gmessage: message,
        });
        dispatch(setSingleBrand(null, message));
      })
      .then(function () {});
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let goup_id = "";

    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    fetchSingleGroup(goup_id);
  }, []);

  const propsUpdateBrandData = () => {};

  const { gloading, gid, gmessage } = brandData;
  const gdata = useSelector((state) => state.Brand.singleBrand);

  const backToGroupListLink = () => {
    return (
      <Link to="/brand" className="btn btn-info waves-effect waves-light mr-2">
        <i className="mdi mdi-arrow-left mr-1"></i> {props.t("Back to List")}
      </Link>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                {gmessage ? (
                  <Alert color="danger" className="text-center">
                    {gmessage}
                  </Alert>
                ) : null}

                {gdata ? (
                  <>
                    <CardBody>
                      <div className="group-details-section" data-id={gdata.id}>
                        <Row>
                          <Col lg="8">
                            <Media>
                              <div className="avatar-sm mr-4">
                                <span className="avatar-title font-size-20 rounded-circle text-capitalize">
                                  {gdata.name?.charAt(0)}
                                </span>
                              </div>
                              <Media className="overflow-hidden" body>
                                <h5 className="text-truncate font-size-15">
                                  {gdata.name}
                                </h5>
                                <p className="text-muted">
                                  {props.t("Brand Status")}:
                                  <Badge
                                    color="primary"
                                    className="font-size-12 ml-2"
                                  >
                                    {gdata.status === "active"
                                      ? "Active"
                                      : "Inactive"}
                                  </Badge>
                                </p>
                              </Media>
                            </Media>
                          </Col>

                          <Col lg="4">
                            <div className="text-right">
                              <CreateUpdateBrand
                                updateBrandDataFlag={propsUpdateBrandData}
                                brand_action_type="edit"
                                brand_button_type="edit_btn"
                                gdata={gdata}
                                {...props}
                              />
                              {backToGroupListLink()}
                            </div>
                          </Col>
                        </Row>

                        <h5 className="font-size-15 mt-4">
                          {props.t("Brand Details")} :
                        </h5>

                        <div className="text-muted mt-4">
                          <Row>
                            <Col lg="6">
                              <p>
                                <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("URL of Frontend App Logo")}:{" "}
                                </strong>{" "}
                                {gdata.pdfLogoUrl}
                              </p>

                              <p>
                                <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                                <strong>{props.t("Member Welcome")}: </strong>{" "}
                                {gdata.emailTemplateMemberWelcome}
                              </p>
                              <p>
                                <i className="bx bxs-map text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Update Email ID Notice")}:{" "}
                                </strong>{" "}
                                {gdata.emailTemplateUpdateEmailID}
                              </p>
                              <p>
                                <i className="bx bxs-map text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Consultation Reminder")}:{" "}
                                </strong>{" "}
                                {gdata.emailTemplateConsultReminder}
                              </p>
                              <p>
                                <i className="bx bxs-map text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Late Consultation Reminder")}:{" "}
                                </strong>{" "}
                                {gdata.emailTemplateLateConsultReminder}
                              </p>
                            </Col>

                            <Col lg="6">
                              <p>
                                <i className="bx bx-user text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Consultation Confirmed")} :{" "}
                                </strong>{" "}
                                {gdata.emailTemplateConsultSubmitted}
                              </p>
                              <p>
                                <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Video Call Invite")}:{" "}
                                </strong>{" "}
                                {gdata.emailTemplateVideoCall}
                              </p>

                              <p>
                                <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Member Activation")}:{" "}
                                </strong>{" "}
                                {gdata.emailTemplateMemberActivation}
                              </p>
                              <p>
                                <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Password Reset Invite")}:{" "}
                                </strong>{" "}
                                {gdata.emailTemplatePasswordReset}
                              </p>
                            </Col>
                          </Row>
                        </div>

                        <Row className="task-dates">
                          <Col sm="6" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14">
                                <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                                {props.t(" Created Date")}
                              </h5>
                              <p className="text-muted mb-0">
                                {dctDateFormat(gdata.createdAt)}
                              </p>
                            </div>
                          </Col>

                          <Col sm="6" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14">
                                <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                                {props.t("Updated Date")}
                              </h5>
                              <p className="text-muted mb-0">
                                {dctDateFormat(gdata.updatedAt)}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>

                    <Company
                      groupId={gdata.id}
                      groupName={gdata.groupName}
                    ></Company>
                  </>
                ) : (
                  <CardBody>
                    {gmessage ? (
                      <div className="text-center">{backToGroupListLink()}</div>
                    ) : (
                      <div className="text-center">
                        {loadingMessage("Loading...")}
                      </div>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { brand } = state.Brand;
  const { message } = state.Brand;
  return { brand, message };
};

export default withRouter(
  connect(mapStatetoProps, { brandActionSuccessful, setSingleBrand })(
    withNamespaces()(BrandOverview)
  )
);
