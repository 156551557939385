export const CREATE_RXPHARMACY = "CREATE_RXPHARMACY";
export const UPDATE_RXPHARMACY = "UPDATE_RXPHARMACY";
export const GET_RXPHARMACY = "GET_RXPHARMACY";
export const GET_RXPHARMACY_LIST = "GET_RXPHARMACY_LIST";
export const SET_RXPHARMACY_LIST = "SET_RXPHARMACY_LIST";
export const SET_RXPHARMACY_LISTING = "SET_RXPHARMACY_LISTING";
export const CREATE_RXPHARMACY_SUCCESS = "CREATE_RXPHARMACY_SUCCESS";
export const CREATE_RXPHARMACY_FAILED = "CREATE_RXPHARMACY_FAILED";
export const SET_SINGLE_RXPHARMACY = "SET_SINGLE_RXPHARMACY";
export const API_ERROR = "API_ERROR";
