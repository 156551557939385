import React, { Fragment, useState, useEffect } from "react";
import { Container, Row } from "reactstrap";

import CardWelcome from "./card-welcome";

//Include Both Helper File with needed methods
import { DctCustomHeader } from "../../helpers/Commons";

import axios from "axios";
import MiniWidget from "./mini-widget";
import Earning from "../Dashboard-partner/earning";
import SalesAnalytics from "../Dashboard-partner/sales-analytics";
import { useSelector } from "react-redux";
import PharmacyOverview from "../Pharmacy/Pharmacy/pharmacy-overview"
const Dashboard = (props) => {
  const { authPartnerId } = useSelector(
    (state) => state.Login
  );
  // const [reports, setReports] = useState([
  //   { icon: "bx bx-group", title: "Groups", value: 0 },
  //   { icon: "bx bx-building", title: "Companies", value: 0 },
  //   { icon: "bx bxs-user-detail", title: "Active Members", value: 0 },
  // ]);
  const [gData,setGData]=useState();

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_PATH}/partner/${authPartnerId}/essentials`;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    axios.get(url, headers).then(function (response) {
        let data = response.data;
        setGData(data);
    });
  }, [])

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          {DctCustomHeader("Partner Dashboard")}
          <Row>
            <CardWelcome />
            <MiniWidget gData={gData?.result} />
          </Row>
         <PharmacyOverview/>
        </Container>
      </div>
    </Fragment>
  );
};

export default Dashboard;
