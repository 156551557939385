import React, { Component } from 'react';
import { uniqueId } from 'lodash';
import { Config } from '../../../../Config';

export default class CheckBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: uniqueId('Checkbox'),
      isChecked: this.props.initialChecked
    };
  }

  onChange = (event) => {
    let isChecked = !this.state.isChecked;
    this.setState({ isChecked });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isChecked !== this.state.isChecked &&
      typeof this.props.onChange === 'function'
    ) {
      this.props.onChange(this.state.isChecked);
    }
  }

  render() {
    return (
      <div>
        {/* <label htmlFor={this.state.id}>
          {this.props.label}
        </label>
        <input
          type="checkbox"
          checked={this.state.isChecked}
          id={this.state.id}
          onChange={this.onChange}
        /> */}
        {this.props.label === "Publish Audio" ? this.state.isChecked ? 
          <button onClick={this.onChange} className={`btn btn-success ${Config.names.slugName}-btn`}>
            <i class="bx bx-microphone" ></i> 
          </button>
          : 
          <button onClick={this.onChange} className={`btn btn-danger ${Config.names.slugName}-btn`}>
            <i class="bx bx-microphone-off" ></i> 
          </button>
        : null}


          {this.props.label === "Publish Video" ? this.state.isChecked ? 
          <button onClick={this.onChange} className={`btn btn-success ${Config.names.slugName}-btn`}>
            <i class="bx bx-camera"></i> 
          </button>
          : 
          <button onClick={this.onChange} className={`btn btn-danger ${Config.names.slugName}-btn`}>
            <i class="bx bx-camera-off"></i> 
          </button>
        : null}
      </div>
    );
  }
}
