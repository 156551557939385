import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
  Label,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

import AsyncSelect from "react-select/async";

import debounce from "lodash/debounce";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";

import {
  createMember,
  updateMember,
  createMemberFailed,
  memberActionSuccessful,
  setSingleMember,
} from "../../../store/actions";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { dctGetAsyncData } from "../../../helpers/backend_helper";
import Select from "react-select";
import { Config } from "../../../Config";
import AuthMTMGroupCode from "./AuthMTMGroupCode";
import {
  dctHumanReadableGlobalFormat,
  dctDobGlobalFormatForDatePicker,
} from "../../../helpers/Commons";

const CreateUpdateMember = (props) => {
  const [modal, setModal] = useState(false);
  // const [cst_modal, setCstModal] = useState(false);
  const cstModal = props.modal;
  const setCstModal = props.setModal;
  const [msgEnable, setMsgEnable] = useState(true);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [memberDob, setMemberDob] = useState(new Date());
  const [memberAge, setMemberAge] = useState(0);
  const [phone, setPhone] = useState("");
  const [partnerOptions, setPartnerOptions] = useState(null);
  const [partnerDisable, setPartnerDisable] = useState(true);
  const [code, setCode] = useState({
    primaryExternalId: "",
    mtmGroupCode: "",
    extIdLock: true,
    mtmCodeLock: true,
    codeAction: "",
    errMsg: "",
    err: false,
  });
  const [authModal, setAuthModal] = useState(false);

  const dispatch = useDispatch();

  let memberData = useSelector((state) => state.Member.singleMember);
  const { authUserType, authPartnerId } = useSelector((state) => state.Login);

  if (!memberData) memberData = {};
  const action_type = props.member_action_type === "edit" ? "edit" : "create";

  const button_label =
    props.member_action_type === "edit" ? "Submit" : "Create Member";

  const modal_title =
    props.member_action_type === "edit" ? "Update Member" : "Create Member";

  //check modal for company
  useEffect(() => {
    if (props.origin === "company") {
      const defaultCompany = {
        companyName: props.companyName,
        id: props.companyId,
      };
      setSelectedCompany(defaultCompany);
      getPartnerListByCompanyId(defaultCompany?.id);
      setPartnerDisable(false);
    }
  }, [props.origin]);

  useEffect(() => {
    if (memberData.dob) {
      setMemberDob(memberData.dob);
      // setMemberDob(dctDobGlobalFormatForDatePicker(memberData.dob));
    }
    if (memberData.primaryPhone) {
      setPhone(memberData.primaryPhone);
    }
    if (memberData.partnerId) {
      setSelectedPartner(memberData.partnerId);
    }
    if (memberData.companyId) {
      setSelectedCompany(memberData.companyId);
    }
    if (memberData.state) {
      setSelectedState(memberData.state);
    }
    if (memberData.country) {
      setSelectedCountry(memberData.country);
    }

    if (memberData.primaryExternalId || memberData.mtmGroupCode) {
      setCode({
        ...code,
        primaryExternalId: memberData.primaryExternalId,
        mtmGroupCode: memberData.mtmGroupCode,
      });
    }
  }, [memberData]);

  const getPartnerListByCompanyId = (cId) => {
    const url = `${process.env.REACT_APP_API_PATH}/company/${cId}/partner`;
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios.get(url, options).then((response) => {
      setPartnerOptions(response?.data);
    });
  };
  // Load the fetched Company data for the select dropdown
  const loadCompanyOptions = (inputValue, callback) => {
    const axiosArgs = {
      params: inputValue,
      URL:
        process.env.REACT_APP_API_PATH +
        "/company/names/?limit=3000&fields=companyName&q=" +
        inputValue,
    };
    dctGetAsyncData(axiosArgs).then((results) => callback(results));
    return;
  };

  const companyOptions = debounce(loadCompanyOptions, 250);

  const handlePartnerChange = (selectedPartner) => {
    setSelectedPartner(selectedPartner);
    if (!selectedPartner) {
      document.querySelector("#select-partner").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-partner").classList.remove("text-danger");
  };

  const handleCompanyChange = (selectedCompany) => {
    setSelectedPartner(null);
    setSelectedCompany(selectedCompany);
    getPartnerListByCompanyId(selectedCompany?.id);
    setPartnerDisable(false);

    if (!selectedCompany) {
      document.querySelector("#select-company").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-company").classList.remove("text-danger");
  };

  const handleStateChange = (state) => {
    setSelectedState(state);
  };

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setSelectedState("");
  };

  const handlePhoneChange = (phone) => {
    setPhone("+" + phone);
  };

  const dctCustomValidations = (fieldID, wrapperID) => {
    if (!fieldID && !wrapperID) return;
    let inputEl = document.querySelectorAll(`input[name="${fieldID}"]`);
    if (inputEl.length) {
      let inputIdValue = inputEl[0].value;
      if (inputIdValue) {
        document.querySelector(`#${wrapperID}`).classList.remove("text-danger");
        return inputIdValue;
      } else {
        document.querySelector(`#${wrapperID}`).classList.add("text-danger");
        return;
      }
    }
  };

  function toggleModalClose(e) {
    if (props.singleMember) {
      props.updateMemberDataFlag();
    }
  }
  function handleValidSubmit(event, values) {
    if (!selectedCompany || selectedCompany == "") {
      document.querySelector("#select-company").classList.add("text-danger");
      return;
    }
    if (!selectedPartner.id || selectedPartner.id == "") {
      document.querySelector("#select-partner").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-partner").classList.remove("text-danger");

    values.partnerId = selectedPartner.id;
    values.companyId = selectedPartner.companyId;
    if (!selectedCountry || selectedCountry == "") {
      document.querySelector("#select-country").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-country").classList.remove("text-danger");
    values.country = selectedCountry;

    if (!selectedState || selectedState == "") {
      document.querySelector("#select-state").classList.add("text-danger");
      return;
    }
    document.querySelector("#select-state").classList.remove("text-danger");
    values.state = selectedState;

    if (!phone || phone == "") {
      document.querySelector("#phone").classList.add("text-danger");
      return;
    }
    document.querySelector("#phone").classList.remove("text-danger");
    values.primaryPhone = `${phone}`;

    let formatDob = dctHumanReadableGlobalFormat(memberDob);
    let dobAge = moment().diff(formatDob, "years");
    if (dobAge < 18) {
      document.querySelector("#date-dob").classList.add("text-danger");
      return;
    }

    if (code.extIdLock === false || code.mtmCodeLock === false) {
      setCode({
        ...code,
        errMsg:
          "Please Lock Primary External-Id and MTM Group Code before submit.",
        err: true,
      });

      setTimeout(() => {
        setCode({
          ...code,
          err: false,
        });
      }, 8000);
      return;
    }

    document.querySelector("#date-dob").classList.remove("text-danger");
    values.dob = dctHumanReadableGlobalFormat(memberDob);
    // values.dob = memberDob;

    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.remove("d-none");
    setMsgEnable(true);

    //call reducer method
    if (values.actionType === "edit") {
      values.mtmGroupCode = code.mtmGroupCode;
      values.primaryExternalId = code.primaryExternalId;
      // console.log("values -------", values);

      props.updateMember(values, props.history);
    } else {
      props.createMember(values, props.history);
    }
  }

  const editFormMemberItem = (e) => {
    setMsgEnable(false);
    dispatch(memberActionSuccessful(null, "", ""));
  };

  const createFormMemberItem = (e) => {
    memberData = {};
    setMsgEnable(false);
    setPhone("");
    setMemberDob("");
    setMemberAge(0);
    dispatch(memberActionSuccessful(null, "", ""));
    dispatch(setSingleMember(null, "", ""));
  };

  const handleDateChange = (event) => {
    // const selectedDate = event.target.value;
    const rawInput = event.target.value
      .replace(/^(\d\d)(\d)$/g, "$1-$2")
      .replace(/^(\d\d-\d\d)(\d+)$/g, "$1-$2")
      .replace(/[^\d-]/g, "");
    const selectedDate = rawInput.slice(0, 10); // dob must be 10 characters
    setMemberDob(selectedDate);
    if (selectedDate.length === 10) {
      let formatDob = moment(selectedDate, "MM-DD-YYYY");
      let dobAge = moment().diff(formatDob, "years");
      setMemberAge(dobAge);
      if (dobAge <= 18) {
        document.querySelector("#date-dob").classList.add("text-danger");
      } else {
        document.querySelector("#date-dob").classList.remove("text-danger");
      }
    }
  };

  return (
    <React.Fragment>
      {(() => {
        switch (props.member_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="member_update_modal"
                onClick={() => {
                  setModal(!modal);
                  editFormMemberItem();
                }}
              ></i>
            );
          case "edit_btn":
            return (
              <Button
                type="button"
                color="primary"
                id="member_update_modal"
                className="waves-effect waves-light mr-2"
                onClick={() => {
                  setModal(!modal);
                  editFormMemberItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1"></i> {props.t("Edit")}
              </Button>
            );
          case "borderless_edit_btn":
            return (
              <Link
                color="primary"
                to="#"
                id="member_update_modal"
                onClick={() => {
                  setModal(!modal);
                  editFormMemberItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1 text-muted"></i>
                <span className="text-muted">{props.t("Edit")}</span>
              </Link>
            );
          default:
            return (
              <Link
                onClick={() => {
                  setModal(!modal);
                  createFormMemberItem();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id="member_create_modal"
              >
                <i className="mdi mdi-plus mr-1"></i> {props.t("Create Member")}
              </Link>
            );
        }
      })()}

      <Modal
        centered={true}
        size="lg"
        isOpen={modal || cstModal}
        toggle={() => {
          setModal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-member-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.createMemberError && msgEnable ? (
                <Alert color="danger">{props.createMemberError}</Alert>
              ) : null}
              {props.message && props.message && msgEnable ? (
                <Alert color="success">{props.message}</Alert>
              ) : null}
              {code.err && <Alert color="danger">{code.errMsg}</Alert>}

              {action_type === "edit" ? (
                <AvField
                  name="id"
                  type="hidden"
                  value={
                    memberData.id != undefined ? memberData.id : memberData._id
                  }
                />
              ) : (
                ""
              )}
              <AvField name="actionType" type="hidden" value={action_type} />

              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <AvGroup>
                      <Label for="example">
                        {props.t("First Name")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={
                          memberData.userId != undefined
                            ? memberData.userId.firstName
                            : ""
                        }
                        name="firstName"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("First Name")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Last Name")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={
                          memberData.userId != undefined
                            ? memberData.userId.lastName
                            : ""
                        }
                        name="lastName"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Last Name")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Email")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={memberData.userId ? memberData.userId.email : ""}
                        name="email"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Email")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <AvGroup>
                    <div
                      className={`form-group ${Config.names.slugName}-phone-box`}
                      id={`phone`}
                    >
                      <label>{props.t(`Phone`)}</label>{" "}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                      <PhoneInput
                        inputProps={{ name: "phone", required: true }}
                        placeholder={""}
                        country={
                          !selectedCountry || selectedCountry == ""
                            ? "us"
                            : selectedCountry.toLowerCase()
                        }
                        value={phone}
                        onChange={handlePhoneChange}
                        enableSearch={true}
                        onlyCountries={["us", "mx"]}
                      />
                    </div>
                  </AvGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <AvGroup>
                    <div
                      className={`form-group ${Config.names.slugName}-select-box`}
                      id="select-company"
                    >
                      <label htmlFor="companyId">{props.t("Company")}</label>{" "}
                      <i className="fas fa-star-of-life text-danger custom-star " />
                      <AsyncSelect
                        isClearable={true}
                        placeholder={props.t(`Select Company`)}
                        name="companyId"
                        id="companyId"
                        getOptionLabel={(e) => e.companyName}
                        getOptionValue={(e) => e.id}
                        className={`${Config.names.slugName}-custom-select-box form-label`}
                        cacheOptions
                        defaultOptions
                        loadOptions={companyOptions}
                        value={selectedCompany}
                        onChange={handleCompanyChange}
                        required
                      />
                    </div>
                  </AvGroup>
                </Col>

                <Col lg={6}>
                  <div
                    className={`form-group ${Config.names.slugName}-select-box`}
                    id="select-partner"
                  >
                    <label htmlFor="partnerId">{props.t("Partner")}</label>{" "}
                    <i className="fas fa-star-of-life text-danger custom-star " />
                    {authUserType === "partner" ? (
                      <Select
                        defaultValue={selectedPartner}
                        placeholder={props.t(`Select Partner`)}
                        onChange={handlePartnerChange}
                        name="partnerId"
                        id="partnerId"
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.id}
                        className={`${Config.names.slugName}-custom-select-box`}
                        options={partnerOptions}
                        isDisabled={partnerDisable}
                      />
                    ) : (
                      <Select
                        defaultValue={""}
                        value={selectedPartner}
                        placeholder={props.t(`Select Partner`)}
                        onChange={handlePartnerChange}
                        name="partnerId"
                        id="partnerId"
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.id}
                        className={`${Config.names.slugName}-custom-select-box`}
                        options={partnerOptions}
                        isDisabled={partnerDisable}
                      />
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <AvGroup>
                    <div
                      className={`form-member ${Config.names.slugName}-date-box`}
                      id={`date-dob`}
                    >
                      <label htmlFor="dob">{props.t("Date of Birth")}</label>{" "}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                      {/* <ReactDatePicker
                        name="dob"
                        className="form-control"
                        selected={memberDob}
                        dateFormat="MM-dd-yyyy"
                        onChange={handleDateChange}
                        placeholderText="MM-DD-YYYY"
                        showYearDropdown
                        required
                      /> */}
                      <AvInput
                        required
                        pattern="^(0[1-9]|1[0-2])-(0[1-9]|1\d|2[0-8])-(?!0000)[1-9]\d{3}|(0[13-9]|1[0-2])-(29|30)-(?!0000)[1-9]\d{3}|(0[13578]|1[02])-31-(?!0000)[1-9]\d{3}|02-29-(?!0000)(([1-9]\d)(0[48]|[2468][048]|[13579][26])|([2468][048]|[13579][26])00)$"
                        value={memberDob}
                        minLength="10"
                        maxLength="10"
                        name="dob"
                        id="dob"
                        list="dates_pattern4_datalist"
                        placeholder="MM-DD-YYYY"
                        onChange={handleDateChange}
                      />
                      {memberAge < 18 ? (
                        <div className="invalid-feedback">
                          {props.t(`Age should 18 above`)}
                        </div>
                      ) : null}
                    </div>
                  </AvGroup>
                </Col>
                <Col lg={6}>
                  <AvGroup>
                    <div
                      className={`form-group ${Config.names.slugName}-select-box`}
                      id="select-country"
                    >
                      <label htmlFor="state">{props.t(`Select Country`)}</label>{" "}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                      <CountryDropdown
                        name="country"
                        labelType="short"
                        valueType="short"
                        whitelist={["US", "MX"]}
                        className="form-control"
                        value={selectedCountry}
                        defaultOptionLabel={"Select Country"}
                        onChange={handleCountryChange}
                        required
                      />
                    </div>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div
                    className={`form-group ${Config.names.slugName}-select-box`}
                    id="select-state"
                  >
                    <AvGroup>
                      <label htmlFor="state">{props.t(`Select State`)}</label>{" "}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                      <RegionDropdown
                        name="state"
                        country={
                          !selectedCountry || selectedCountry == ""
                            ? "US"
                            : selectedCountry
                        }
                        countryValueType="short"
                        labelType="full"
                        valueType="short"
                        className="form-control"
                        id="select-state"
                        value={selectedState}
                        defaultOptionLabel={"Select State"}
                        onChange={handleStateChange}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <AvGroup>
                      <Label for="example">
                        {props.t("City")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={memberData.city}
                        name="city"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("City")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Postal Code")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={memberData.postalCode}
                        name="postalCode"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Postal Code")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <AvGroup>
                    <div
                      className={`form-group ${Config.names.slugName}-select-box`}
                    >
                      <Label for="example">
                        {props.t("Gender")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvField
                        type="select"
                        name="gender"
                        // label={props.t(`Gender`)}
                        value={
                          memberData.gender === "male"
                            ? "m"
                            : memberData.gender === "female"
                            ? "f"
                            : "u"
                        }
                        required
                      >
                        <option value="" disabled>
                          {props.t("Select Gender")}
                        </option>
                        <option value="u">{props.t("Other")}</option>
                        <option value="m">{props.t("Male")}</option>
                        <option value="f">{props.t("Female")}</option>
                      </AvField>
                    </div>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                {action_type === "edit" ? (
                  <Col lg={6}>
                    <div className="form-group">
                      <label className="dropbox_label">
                        Primary External Id
                      </label>
                      <div
                        className={`form-control d-flex justify-content-between align-items-center ${
                          code.extIdLock ? "bg-inp-light-gray" : "bg-white"
                        }`}
                      >
                        <input
                          className={`outline-none border-none ${
                            code.extIdLock ? "bg-inp-light-gray" : "bg-white"
                          } `}
                          type="text"
                          name="primaryExternalId"
                          readOnly={code.extIdLock}
                          value={code.primaryExternalId}
                          placeholder={"External Id"}
                          onChange={(e) => {
                            setCode({
                              ...code,
                              primaryExternalId: e.target.value,
                            });
                          }}
                        />
                        {code.extIdLock ? (
                          <i
                            className="fa fa-lock text-primary mr-1 cursor-pointer font-size-14"
                            onClick={() => {
                              setCode({
                                ...code,
                                codeAction: "external_id",
                              });

                              setAuthModal(true);
                            }}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-unlock text-primary mr-1 cursor-pointer font-size-14"
                            onClick={() => {
                              setCode({
                                ...code,
                                extIdLock: true,
                              });
                            }}
                          ></i>
                        )}
                      </div>
                    </div>
                  </Col>
                ) : (
                  <Col lg={6}>
                    <div className="form-group">
                      <AvGroup>
                        <Label for="example">
                          {props.t("Primary External Id")}{" "}
                        </Label>
                        <AvInput
                          value={memberData?.primaryExternalId}
                          name="primaryExternalId"
                          className="form-control"
                          id="example"
                          type="text"
                          placeholder={props.t("External Id")}
                        />
                      </AvGroup>
                    </div>
                  </Col>
                )}
                {action_type === "edit" &&
                  memberData?.companyId?.fulfilment === "MTM" && (
                    // <Col lg={6}>
                    //   <div className="form-group">
                    //     <AvGroup>
                    //       <Label for="example">
                    //         {props.t("MTM Group Code")}{" "}
                    //       </Label>
                    //       <AvInput
                    //         value={
                    //           memberData?.mtmGroupCode
                    //             ? memberData.mtmGroupCode
                    //             : ""
                    //         }
                    //         name="mtmGroupCode"
                    //         className="form-control"
                    //         id="example"
                    //         type="text"
                    //         placeholder={props.t("MTM Group Code")}
                    //       />
                    //     </AvGroup>
                    //   </div>
                    // </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        <label className="dropbox_label">MTM Group Code</label>
                        <div
                          className={`form-control d-flex justify-content-between align-items-center ${
                            code.mtmCodeLock ? "bg-inp-light-gray" : "bg-white"
                          }`}
                        >
                          <input
                            className={`outline-none border-none ${
                              code.mtmCodeLock
                                ? "bg-inp-light-gray"
                                : "bg-white"
                            } `}
                            type="text"
                            name="mtmGroupCode"
                            readOnly={code.mtmCodeLock}
                            value={code.mtmGroupCode}
                            placeholder={"MTM Group Code"}
                            onChange={(e) => {
                              setCode({
                                ...code,
                                mtmGroupCode: e.target.value,
                                codeAction: "mtm_group_code",
                              });
                            }}
                          />
                          {code.mtmCodeLock ? (
                            <i
                              className="fa fa-lock text-primary mr-1 cursor-pointer font-size-14"
                              onClick={(x) => {
                                setCode({
                                  ...code,
                                  codeAction: "mtm_group_code",
                                });
                                setAuthModal(true);
                              }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-unlock text-primary mr-1 cursor-pointer font-size-14"
                              onClick={() => {
                                setCode({
                                  ...code,
                                  mtmCodeLock: true,
                                });
                              }}
                            ></i>
                          )}
                        </div>
                      </div>
                    </Col>
                  )}
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Address")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={memberData.address}
                        name="address"
                        className="form-control"
                        id="example"
                        type="text"
                        placeholder={props.t("Address")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <AvField
                      name="addressTwo"
                      label={props.t(`Address Two`)}
                      className="form-control"
                      placeholder={props.t(`Address Two`)}
                      type="text"
                      value={memberData.addressTwo}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setModal(!modal)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                      onClick={(e) => {
                        dctCustomValidations("partnerId", "select-partner");
                        // dctCustomValidations("state", "select-state");
                      }}
                    >
                      <span className="dr-mform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      {button_label}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
      {/* Admin password modal for ExtID and GroupCode edit */}
      <AuthMTMGroupCode
        setAuthModal={setAuthModal}
        authModal={authModal}
        code={code}
        setCode={setCode}
      />
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createMemberError } = state.Member;
  const { member } = state.Member;
  const { message } = state.Member;
  return { createMemberError, member, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createMember,
    updateMember,
    createMemberFailed,
    setSingleMember,
  })(withNamespaces()(CreateUpdateMember))
);
