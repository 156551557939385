import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, ModalBody, Container, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Config } from "../../../Config";
import closeIcon from "../../../assets/images/closeIcon.png";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { capitalizeWords } from "../../../helpers/Commons";

// import { Dropdown } from 'react-bootstrap';

const ConsultationUpdateStatusModal = (props) => {
  const modal = props?.modal;
  const setModal = props?.setModal;
  const setOpenSuccessModal = props?.setOpenSuccessModal;
  const setStatusUpdate = props?.setStatusUpdate;
  const setStatusUpdateMessage = props?.setStatusUpdateMessage;
  const consultationId = props?.consultationId;
  const currentStatus = props?.currentStatus;
  const setSuccessAdminNote = props?.setSuccessAdminNote;
  const setShowWaring = props?.setShowWaring;
  const setWaringMessage = props?.setWaringMessage;
  const [resetLoader, setResetLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const status = [
    { label: "Pending", value: "confirmed" },
    // { label: "Upcoming", value: "upcoming" },
    { label: "Completed", value: "completed" },
    { label: "Flagged", value: "flagged" },
    { label: "Cancelled", value: "cancelled" },
    { label: "No Answer/Incomplete", value: "noanswer" },
  ];
  const statusColors = {
    pending: "#ffe5b3",
    upcoming: "#c0eeff",
    completed: "#c7f5d7",
    flagged: "#ececec",
    cancelled: "#ffdbdf",
    confirmed: "#ffe5b3",
    noanswer: "#e1e7ff",
  };

  const statusTextColors = {
    pending: "#f19337",
    upcoming: "#5593aa",
    completed: "#39a557",
    flagged: "#878787",
    cancelled: "#ff6174",
    confirmed: "#f19337",
    noanswer: "#788cec",
  };
  const defaultValues = {
    status: {
      value: status[0].label,
    },
  };
  const [selectedValue, setSelectedValue] = useState(currentStatus);
  const handleSubmit = (event, values) => {
    event.preventDefault();
    // Call API
    setResetLoader(true);
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    let url = `${process.env.REACT_APP_API_PATH}/consultation/${consultationId}/status-update`;
    let data = {
      status: selectedValue === "confirmed" ? "reset" : selectedValue,
    };

    axios
      .patch(url, data, headers)
      .then(function (response) {
        setResetLoader(false);
        setStatusUpdateMessage(response?.data?.message);
        setSuccessAdminNote(true);
        setModal(!modal);
        setOpenSuccessModal(true);
        setStatusUpdate(true);
      })
      .catch(function (err) {
        setModal(!modal);
        setSuccessAdminNote(false);
        setResetLoader(false);
        setShowWaring(true);
        setWaringMessage(err?.response?.data?.message);
      });
  };
  const handleStatusClick = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
  };
  return (
    <React.Fragment>
      {" "}
      <Modal
        centered={true}
        size="md"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-member-add-edit-modal`}
      >
        <ModalBody style={{ maxHeight: 300, overflow: "hidden" }}>
          <Container>
            <AvForm
              className="form-horizontal"
              model={defaultValues}
              onValidSubmit={handleSubmit}
            >
              <div
                className="d-flex justify-content-end cursor-pointer"
                onClick={() => {
                  setModal(!modal);
                }}
              >
                <img src={closeIcon} alt="close" />
              </div>
              <div className="ml-10 mt-2">
                <div
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-start "
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                    marginLeft: 10,
                  }}
                >
                  {props.t("Change Consultation Status")}
                </div>
              </div>
              <div className="form-group row mt-4 ml-1 justify-content-start">
                <label htmlFor="to" className="col-sm-2 col-form-label">
                {props.t("Status")}
                </label>
                <div
                  className="col-sm-5"
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  {/* New drop down */}
                  <div className="total-dropdown">
                    <div
                      className="dropdown-label selected-item d-flex justify-content-between"
                      onClick={() => setIsOpen(!isOpen)}
                      style={{
                        cursor: "pointer",
                        color: `${
                          selectedValue in statusTextColors
                            ? statusTextColors[selectedValue]
                            : ""
                        }`,
                        backgroundColor: `${
                          selectedValue in statusColors
                            ? statusColors[selectedValue]
                            : ""
                        }`,
                        border: "none",
                        borderRadius: "5px",
                        padding: "10px",
                        width: "200px",
                      }}
                    >
                      {selectedValue === "confirmed"
                        ?props.t("Pending")
                        : selectedValue === "noanswer"
                        ? props.t("No Answer/Incomplete")
                        : capitalizeWords(props.t(selectedValue))}
                      <span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                      </span>
                    </div>
                    <div
                      className={isOpen ? "p-1 shadow-lg" : ""}
                      style={{
                        width: "200px",
                        borderRadius: "5px",
                        marginTop: "2px",
                        position: "fixed",
                        backgroundColor: "white",
                        marginTop: "5px",
                      }}
                    >
                      {isOpen &&
                        status?.map((status, i) => (
                          <div
                            onClick={() => handleStatusClick(status?.value)}
                            key={i}
                            style={{
                              color: `${
                                status?.value in statusTextColors
                                  ? statusTextColors[status?.value]
                                  : ""
                              }`,
                              backgroundColor: `${
                                status?.value in statusColors
                                  ? statusColors[status?.value]
                                  : ""
                              }`,
                              cursor: "pointer",
                              border: "1px",
                              width: "190px",
                              borderRadius: "5px",
                              padding: "10px",
                              marginTop: "5px",
                            }}
                          >
                            {props.t(status?.label)}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* Button */}
              <div className="d-flex justify-content-end mt-5">
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#1259ec",
                    // width: "90px",
                    padding: "10px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    borderRadius: "5px",
                  }}
                  onClick={handleSubmit}
                >
                  {resetLoader ? (
                    <div className="d-flex flex-row align-items-center">
                      <i className="bx bx-loader bx-spin font-size-18"></i>
                      <span className="ml-2">{props.t("Update")}</span>
                    </div>
                  ) : (
                    props.t("Update")
                  )}
                </Button>
              </div>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
      {/* {showWaring && (
        <SweetAlert
          title="Oops !"
          warning
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowWaring(false);
          }}
        >
          {waringMessage}
        </SweetAlert>
      )} */}
    </React.Fragment>
  );
};

export default ConsultationUpdateStatusModal;
