import React, { useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Alert,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import SweetAlert from "react-bootstrap-sweetalert";

import axios from "axios";

import moment from "moment";
import { Config } from "../../../Config";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import ReactDatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { dctTimezones } from "../../../helpers/dctTimezones";
import Select from "react-select";
import { setSingleMember } from "../../../store/actions";
import { dctAlert, dctHumanReadableGlobalFormat } from "../../../helpers/Commons";
import { checkAjaxError } from "../../../helpers/backend_helper";

const DependentModal = (props) => {
  const dependentModal = props.dependentModal;
  const setDependentModal = props.setDependentModal;
  const dispatch = useDispatch();
  const [secondaryPhone, setSecondaryPhone] = useState("");
  const [stateName, setStateName] = useState("TX");
  const [countryName, setCountryName] = useState("US");
  const [emailErrorMsg, setEmailErrorMsg] = useState(false);
  const [timezoneData, setTimezoneData] = useState({
    timezoneAbbr: "PST",
    timezoneUTC: "America/Los_Angeles",
  });


  const [loader, setLoader] = useState(false);
  const [_medicalHistoryList, _setMedicalHistoryList] = useState({
    data: [],
    loading: true,
  });
  let membersData = useSelector((state) => state.Member.singleMember);
  membersData = membersData ? membersData : [];

  const [depDob, setDepDob] = useState();
  const [depAge, setDepAge] = useState(0);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("male");
  const [status, setStatus] = useState("active");
  const [relationship, setRelationship] = useState("child");
  const [weightMetric, setWeightMetric] = useState(1);
  const [heightMetric, setHeightMetric] = useState(1);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [primaryPhone, setPrimaryPhone] = useState("");
  const mdata = useSelector((state) => state.Member.singleMember);
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [city, setCity] = useState(mdata?.city);
  const [zipCode, setZipCode] = useState(mdata?.postalCode);
  const [_singleMedicalHistory, _setSingleMedicalHistory] = useState([]);
  const [mobileError, setMobileError] = useState(false);
  const [secondaryMobileError, setSecondaryMobileError] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [nameErrorMsg, setNameErrorMsg] = useState("");
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState("");
  const [address1, setAddress1] = useState(mdata?.address);
  const [address2, setAddress2] = useState(mdata?.addressTwo);
  const zipCodeRegex = /^[0-9]{5}$/;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const nameRegex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
  const addressRegex = /^(?!.*(<|>|{[^}]*}))/;
  const heightWeightValidation = /^[0-9]{2,3}$/;

  const [_allergyList, _setAllergyList] = useState({
    data: [],
    loading: true,
    error: "",
  });

  const handleDateChange = (event) => {
    // const selectedDate = event.target.value;
    const rawInput = event.target.value
      .replace(/^(\d\d)(\d)$/g, "$1-$2")
      .replace(/^(\d\d-\d\d)(\d+)$/g, "$1-$2")
      .replace(/[^\d-]/g, "");
    const selectedDate = rawInput.slice(0, 10); // dob must be 10 characters
    if (!selectedDate) {
      return;
    }
    setDepDob(selectedDate);
    if (selectedDate.length === 10) {
      // let formatDob = dctHumanReadableGlobalFormat(date);
      let formatDob = moment(selectedDate, "MM-DD-YYYY");
      let dobAge = moment().diff(formatDob, "years");
      setDepAge(dobAge);
    }
  };

  const resetModalState = () => {
    setDependentModal(false);
    setName("");
    setLastName("");
    setGender("male");
    setStatus("active");
    setRelationship("child");
    setPrimaryPhone("");
    setSecondaryPhone("");
    setEmail("");
    setAddress1(mdata?.address);
    setAddress2("");
    setCity(mdata?.city);
    setZipCode(mdata?.postalCode);
    setHeight("");
    setWeight("");
    setCountryName("US");
    setStateName("TX");
    setSecondaryMobileError(false);
    setMobileError(false);
    setTimezoneData({
      timezoneAbbr: "PST",
      timezoneUTC: "America/Los_Angeles",
    });
  };

  const validateFields = () => {
    const trimmedFields = {
      name: name?.trim(),
      lastName: lastName?.trim(),
      address1: address1?.trim() || "",
      city: city?.trim() || "",
      zipCode: zipCode?.trim() || "",
      stateName: stateName?.trim(),
      countryName: countryName?.trim(),
      primaryPhone: primaryPhone?.trim(),
    };

    const trimmedPrimaryPhone = trimmedFields.primaryPhone;
    const trimmedSecondaryPhone = secondaryPhone?.trim();
    if (
      trimmedPrimaryPhone.length < 3 ||
      (trimmedPrimaryPhone.includes("+1") &&
        trimmedPrimaryPhone.length !== 12) ||
      (trimmedPrimaryPhone.includes("+52") && trimmedPrimaryPhone.length !== 13)
    ) {
      setMobileError(true);
      return false;
    }

    if (
      (trimmedSecondaryPhone.length > 2 &&
        trimmedSecondaryPhone.includes("+1") &&
        trimmedSecondaryPhone.length !== 12) ||
      (trimmedSecondaryPhone.length > 3 &&
        trimmedSecondaryPhone.includes("+52") &&
        trimmedSecondaryPhone.length !== 13)
    ) {
      setSecondaryMobileError(true);
      return false;
    }

    if (!nameRegex.test(trimmedFields.name)) {
      setNameErrorMsg("Please fill first name correctly");
      return false;
    }

    if (!nameRegex.test(trimmedFields.lastName)) {
      setLastNameErrorMsg("Please fill last name correctly");
      return false;
    }

    if (depAge >= 18 && !emailRegex?.test(email)) {
      setEmailError(true);
      return false;
    }

    const isFieldEmpty = Object.values(trimmedFields).some(
      (field) => !field || field.length === 0
    );
    if (isFieldEmpty || (depAge >= 18 && email.length === 0)) {
      return false;
    }

    if (!zipCodeRegex?.test(trimmedFields.zipCode)) {
      return false;
    }

    if (!heightWeightValidation?.test(height) && height.length > 0) {
      return false;
    }

    if (!heightWeightValidation?.test(weight) && weight.length > 0) {
      return false;
    }

    if (!addressRegex?.test(address1)) {
      return false;
    }

    if (!addressRegex?.test(address2)) {
      return false;
    }

    return true;
  };

  // API call for Create Dependent
  const handleSubmit = () => {
    if (!validateFields()) {
      return;
    }
    const addDependent = {
      actionType: "create",
      memberId: mdata?.id,
      firstName: name?.trim(),
      lastName: lastName?.trim(),
      gender: gender,
      dob: depDob,
      primaryPhone: primaryPhone,
      relation: relationship,
      address: address1?.trim(),
      addressTwo: address2?.trim(),
      city: city?.trim(),
      state: stateName,
      country: countryName,
      postalCode: zipCode,
      status: status,
      timezoneAbbr: timezoneData.timezoneAbbr,
      timezoneUTC: timezoneData.timezoneUTC,
      heightMetric: heightMetric,
      secondaryPhone: secondaryPhone.length < 10 ? "" : secondaryPhone,
      height: height.trim(),
      weight: weight.trim(),
      weightMetric: weightMetric,
      email: email?.trim(),
    };
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = process.env.REACT_APP_API_PATH + "/dependent";
    setLoader(true);
    axios
      .post(url, addDependent, headers)
      .then((res) => {
        setLoader(false);
        setDependentModal(!dependentModal);
        dispatch(
          setSingleMember({
            ...membersData,
            dependent: [...membersData.dependent, res.data],
          })
        );
        setErrorMsg("");
        setSuccessModal(true);
        setTimeout(() => {
          setSuccessModal(false);
        }, 3000);
        resetModalState();
      })
      .catch((err) => {
        setLoader(false);
        setEmailErrorMsg(true);
        setTimeout(() => {
          setEmailErrorMsg(false);
        }, 3000);
        setErrorMsg(err?.response?.data?.cause?.message || 'Something went wrong');
      });
  };

  const handleSecondaryPhoneClick = () => {
    setSecondaryMobileError(false);
  };

  const handleCountryChange = (country) => {
    if (country === "US") {
      setCountryName(country);
      setStateName("TX");
    } else {
      setCountryName(country);
      setStateName("");
    }
  };

  return (
    <Fragment>
      <Modal
        centered={true}
        size="lg"
        isOpen={dependentModal}
        toggle={resetModalState}
        scrollable={true}
        className={`${Config.names.slugName}-member-add-edit-modal`}
      >
        <ModalHeader
          toggle={() => {
            setDependentModal(!dependentModal);
          }}
        >
          Create Dependent
        </ModalHeader>
        <ModalBody>
          <Container>
            {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}
            <AvForm className="form-horizontal">
              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <Label for="">
                      {props.t("First Name")}{" "}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvInput
                      name="firstName"
                      className={`form-control ${
                        nameErrorMsg ? "is-invalid" : ""
                      }`}
                      placeholder={props.t(`First Name`)}
                      type="text"
                      required
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameErrorMsg("");
                      }}
                    />
                    {nameErrorMsg && (
                      <span className="text-danger">{nameErrorMsg}</span>
                    )}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <Label for="">
                      {props.t("Last Name")}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvInput
                      name="lastName"
                      className={`form-control ${
                        lastNameErrorMsg ? "is-invalid" : ""
                      }`}
                      placeholder={props.t(`Last Name`)}
                      type="text"
                      required
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameErrorMsg("");
                      }}
                    />
                    {lastNameErrorMsg && (
                      <span className="text-danger">{lastNameErrorMsg}</span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <div
                      className={`form-group ${Config.names.slugName}-select-box`}
                    >
                      <Label for="">
                        {props.t("Gender")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvField
                        type="select"
                        name="gender"
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <option value="unknown">{props.t("Other")}</option>
                        <option value="male">{props.t("Male")}</option>
                        <option value="female">{props.t("Female")}</option>
                      </AvField>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className={`form-member ${Config.names.slugName}-date-box pb-3`}
                    id={`date-dob`}
                  >
                    <Label for="">
                      {props.t("Date of Birth")}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    {/* <ReactDatePicker
                      name="dob"
                      className="form-control"
                      selected={depDob}
                      dateFormat="MM-dd-yyyy"
                      onChange={handleDateChange}
                      placeholderText="MM-DD-YYYY"
                      showYearDropdown
                      maxDate={new Date()}
                      required
                    /> */}
                     <AvInput
                        required
                        pattern="^(0[1-9]|1[0-2])-(0[1-9]|1\d|2[0-8])-(?!0000)[1-9]\d{3}|(0[13-9]|1[0-2])-(29|30)-(?!0000)[1-9]\d{3}|(0[13578]|1[02])-31-(?!0000)[1-9]\d{3}|02-29-(?!0000)(([1-9]\d)(0[48]|[2468][048]|[13579][26])|([2468][048]|[13579][26])00)$"
                        value={depDob}
                        minLength="10"
                        maxLength="10"
                        name="dob"
                        id="dob"
                        list="dates_pattern4_datalist"
                        placeholder="MM-DD-YYYY"
                        onChange={handleDateChange}
                      />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div
                    className={`${Config.names.slugName}-dependent-phone form-group ${Config.names.slugName}-phone-box`}
                    id={`primary_phone`}
                  >
                    <Label>
                      {props.t(`Primary Phone`)}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <PhoneInput
                      inputProps={{
                        name: "primaryPhone",
                        required: true,
                        style: {
                          borderColor: mobileError ? "red" : "",
                        },
                      }}
                      country={"us"}
                      value={primaryPhone}
                      onChange={(primaryPhone) => {
                        setPrimaryPhone("+" + primaryPhone);
                        setMobileError(false);
                      }}
                      enableSearch={true}
                      onlyCountries={["us", "mx"]}
                      required
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group" id={`secondary_phone`}>
                    <label>{props.t(`Secondary Phone`)}</label>
                    <PhoneInput
                      inputProps={{
                        name: "secondaryPhone",
                        required: true,
                        style: {
                          borderColor: secondaryMobileError ? "red" : "",
                        },
                        onClick: handleSecondaryPhoneClick,
                      }}
                      country={"us"}
                      value={secondaryPhone}
                      onChange={(secondaryPhone) => {
                        setSecondaryPhone("+" + secondaryPhone);
                        setSecondaryMobileError(false);
                      }}
                      enableSearch={true}
                      onlyCountries={["us", "mx"]}
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-member">
                    <AvField
                      type="select"
                      name="depStatus"
                      label={props.t("Status")}
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="active">{props.t("Active")}</option>
                      <option value="inactive">{props.t("Inactive")}</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-member">
                    <Label for="">
                      {props.t("Relationship")}{" "}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvField
                      type="select"
                      name="relation"
                      value={relationship}
                      onChange={(e) => setRelationship(e.target.value)}
                    >
                      <option value="spouse">{props.t("Spouse")}</option>
                      <option value="child">{props.t("Child")}</option>
                      <option value="other">{props.t("Other")}</option>
                    </AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-member">
                    <Label for="">
                      {props.t("Address")}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvField
                      name="address"
                      className="form-control"
                      placeholder={props.t("Address")}
                      validate={{
                        required: { value: true },
                        pattern: { value: "^(?!.*(<|>|{[^}]*}))" },
                      }}
                      type="textarea"
                      required
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-member">
                    <AvField
                      name="addressTwo"
                      label={props.t("Address Line 2")}
                      className={`form-control ${
                        !addressRegex?.test(address2) ? "is-invalid" : ""
                      }`}
                      placeholder={props.t("Address Line 2")}
                      type="textarea"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-member">
                    <Label for="">
                      {props.t("City")}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvField
                      name="city"
                      className="form-control"
                      placeholder={props.t("City")}
                      type="text"
                      required
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-member">
                    <Label for="">
                      {props.t("Postal Code")}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <AvField
                      name="postalCode"
                      className="form-control"
                      placeholder={props.t("Postal Code")}
                      type="text"
                      validate={{
                        required: { value: true },
                        pattern: { value: "^[0-9]{5}$" },
                        minLength: {
                          value: 5,
                          errorMessage: "Postal Code should be 5 digits.",
                        },
                      }}
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                      maxLength={5}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div
                    className={`form-group ${Config.names.slugName}-select-box`}
                    // id={`select-dep-country_${tabIndex}`}
                  >
                    <Label for="">
                      {props.t("Country")}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <CountryDropdown
                      defaultOptionLabel={"Select Country"}
                      value={countryName}
                      name="country"
                      labelType="short"
                      valueType="short"
                      whitelist={["US", "MX"]}
                      classes={`form-control ${
                        countryName.length == 0 ? "is-invalid" : ""
                      }`}
                      onChange={handleCountryChange}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className={`form-group ${Config.names.slugName}-select-box`}
                  >
                    <Label for="">
                      {props.t("State")}
                      <i className="fas fa-star-of-life text-danger custom-star" />
                    </Label>
                    <RegionDropdown
                      country={countryName}
                      countryValueType="short"
                      name="state"
                      labelType="full"
                      valueType="short"
                      value={stateName}
                      classes={`form-control ${
                        stateName.length == 0 ? "is-invalid" : ""
                      }`}
                      defaultOptionLabel={"Select State"}
                      onChange={(val) => {
                        setStateName(val);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-member pb-3">
                    <div className="form-member">
                      <label htmlFor="groupId">
                        {props.t("Select Timezone")}
                      </label>
                      <Select
                        defaultValue={{
                          value: timezoneData?.timezoneUTC,
                          abbr: timezoneData?.timezoneAbbr,
                        }}
                        placeholder="Select Timezone"
                        onChange={(e) => {
                          setTimezoneData({
                            timezoneAbbr: e?.abbr,
                            timezoneUTC: e?.value,
                          });
                        }}
                        name="timezone"
                        getOptionLabel={(e) => e.value}
                        getOptionValue={(e) => e.abbr}
                        className={`${Config.names.slugName}-custom-select-box`}
                        options={dctTimezones}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <Label htmlFor="validationCustom03">
                    {props.t(`Height`)}
                    {/* <i className="fas fa-star-of-life text-danger custom-star" /> */}
                  </Label>
                  <Row>
                    <Col sm={6}>
                      <AvField
                        name="height"
                        placeholder={props.t(`Height`)}
                        type="text"
                        className="form-control"
                        validate={{
                          pattern: { value: "^[0-9]{2,3}$" },
                        }}
                        minLength={2}
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                        maxLength={3}
                      ></AvField>
                    </Col>
                    <Col sm={6}>
                      <AvField
                        name="heightMetric"
                        type="select"
                        errormessage={props.t(`Select Metric`)}
                        className={`${Config.names.slugName}-custom-select-box`}
                        value={heightMetric}
                        onChange={(e) => setHeightMetric(e.target.value)}
                      >
                        <option value="1">INCH</option>
                        <option value="2">CM</option>
                      </AvField>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Label htmlFor="validationCustom03">
                    {props.t(`Weight`)}
                    {/* <i className="fas fa-star-of-life text-danger custom-star" /> */}
                  </Label>
                  <Row>
                    <Col sm={6}>
                      <AvField
                        name="weight"
                        placeholder={props.t(`Weight`)}
                        type="text"
                        className="form-control"
                        validate={{
                          pattern: { value: "^[0-9]{2,3}$" },
                        }}
                        minLength={2}
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                        maxLength={3}
                      ></AvField>
                    </Col>
                    <Col sm={6}>
                      <AvField
                        name="weightMetric"
                        type="select"
                        errormessage={props.t(`Select Metric`)}
                        className={`${Config.names.slugName}-custom-select-box`}
                        value={weightMetric}
                        onChange={(e) => setWeightMetric(e.target.value)}
                      >
                        <option value="1">LB</option>
                        <option value="2">KG</option>
                      </AvField>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {depAge >= 18 && (
                <Row>
                  <Col lg={6}>
                    <div className="form-member">
                      <AvField
                        name="email"
                        label={props.t("Email")}
                        className={`form-control ${
                          emailError ? "is-invalid" : ""
                        }`}
                        placeholder={props.t("Email")}
                        type="text"
                        required
                        value={email}
                        maxLength={50}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailError(false);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              <div className="mt-4">
                <Row>
                  <Col lg={12}>
                    <div className="modal-footer">
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                        onClick={() => handleSubmit()}
                      >
                        {loader ? (
                          <span className="dr-mform-spinner">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                          </span>
                        ) : (
                          ""
                        )}
                        {props.t("Create Dependent")}
                      </button>
                      <button
                        className="btn btn-outline-primary waves-effect waves-light"
                        type="button"
                        onClick={() =>
                          resetModalState() &&
                          setDependentModal(!dependentModal)
                        }
                      >
                        {props.t("Cancel")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
      {successModal && (
        <SweetAlert
          success
          title="Dependent Created successfully"
          onConfirm={() => {
            setSuccessModal(false);
          }}
        ></SweetAlert>
      )}
    </Fragment>
  );
};

export default DependentModal;
