import React, { useState, useEffect } from "react";

import {
  dctGetPharmacyList,
  dctUpdateStatus,
} from "../../../helpers/backend_helper";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Badge,
  Table,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

import { connect, useSelector, useDispatch } from "react-redux";

import {
  partnerActionSuccessful,
  setSinglePartner,
  setSinglePartnerAuth,
} from "../../../store/actions";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../../helpers/backend_helper";

//Include Both Helper File with needed methods
import {
  dctHumanReadableFormat,
  loadingMessage,
} from "../../../helpers/Commons";
import Prescription from "../rxprescription";

import axios from "axios";

//Import Create and Update Group

const PharmacyOverview = (props) => {
  const [partnerData, setPartnerData] = useState({
    gloading: true,
    gdata: null,
    gid: null,
    gmessage: null,
  });

  const [statusLoader, setStatusLoader] = useState(false);

  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [prep, setPrep] = useState(false);
  const [dosespotPharmacyDetails, setDosespotPharmacyDetails] = useState({
    doseSpotPharmacyData: null,
  });

  const [partnerStatusMeta, setPartnerStatusMeta] = useState({
    rowIndex: "",
    groupId: "",
    groupStatus: 0,
  });

  const partner_action = useSelector((state) => state.Partner.action_type);

  const { authUserType, authPartnerId } = useSelector((state) => state.Login);

  const gdata = useSelector((state) => state.Partner.singlePartner);

  const togglePartnerStatus = async (partnerStatusMeta) => {
    if (!partnerStatusMeta) return;

    const { partnerId, partnerStatus, rowIndex } = partnerStatusMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Activation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    const loaderCls = ["bx-loader", "bx-spin", "font-size-18"];
    let toogleUserCls =
      partnerStatus === "active" ? "bx-user-check" : "bx-user-x";
    setStatusLoader(true);

    // Adding the bx-loader and removing the box-user-check class
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.add(...loaderCls);
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.remove(toogleUserCls);
    let gStatus = partnerStatus === "active" ? "inactive" : "active";
    const postData = {
      id: partnerId,
      status: gStatus,
    };
    const url = "partnerauth/" + partnerId;
    const response = await dctUpdateStatus(postData, url).then((data) => data);

    if (response.status === "active") {
      setdynamic_description("Activated successfully.");
      setdynamic_title("Activated");
    }
    if (response.status === "inactive") {
      setdynamic_description("Deactivated successfully.");
      setdynamic_title("Deactivated");
    }

    if (response.id) {
      seterror_dlg(false);
    } else {
      seterror_dlg(true);
      setsuccess_dlg(false);
    }

    gdata.partnerAuth[rowIndex].status = response.status;
    setPartnerData({
      gdata: gdata,
    });

    setStatusLoader(false);
    toogleUserCls = response.status === "active" ? "bx-check" : "bx-x";
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.remove(...loaderCls);
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.add(toogleUserCls);
  };

  const openPartnerEditModal = (data) => {
    document.querySelector("#partner_update_modal").click();
    //Push Single Group Data to store
    dispatch(setSinglePartnerAuth(data, "", ""));
  };

  const dispatch = useDispatch();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let goup_id = "";

    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    // fetchSinglePartner(goup_id);

    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let uid = JSON.parse(localStorage.getItem("auth_user")).rxpharmacyId;
    let url = process.env.REACT_APP_API_PATH + "/rxpharmacy/" + uid;

    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data;
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(setSinglePartner(response_data, message, null));
        setPartnerData({
          gloading: false,
          gdata: response_data,
          gid: goup_id,
          gmessage: message,
        });
        setPrep(true);
        getPharmacyById(
          response_data.pharmacy_pharmacyId
            ? response_data.pharmacy_pharmacyId
            : ""
        );
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = "No record found.";
          }
        }
        setPartnerData({
          gloading: false,
          gdata: null,
          gid: null,
          gmessage: message,
        });
        dispatch(setSinglePartner(null, message));
      })
      .then(function () {});
  }, []);

  const propsUpdatePartnerData = () => {};

  const { gloading, gid, gmessage } = partnerData;

  const getPharmacyById = async (pharmacyId) => {
    if (!pharmacyId) return;
    let url = `pharmacy/${pharmacyId}`;
    const response = await dctGetPharmacyList(url, "").then((data) => data);
    if (response.success === true) {
      // dispatch(setSinglePharmacy(response.data, ""));
      setDosespotPharmacyDetails({
        doseSpotPharmacyData: response.data,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                {/* {gmessage ? (
                  <Alert color="danger" className="text-center">
                    {gmessage}
                  </Alert>
                ) : null} */}

                {gdata ? (
                  <CardBody>
                    <div className="group-details-section" data-id={gdata.id}>
                      <Row>
                        <Col lg="6">
                          <Media>
                            <div className="avatar-sm mr-4">
                              <span className="avatar-title font-size-20 rounded-circle">
                                {gdata.userId?.firstName?.charAt(0)}
                              </span>
                            </div>
                            <Media className="overflow-hidden" body>
                              <h5 className="text-truncate font-size-15">
                                {gdata.userId?.firstName +
                                  " " +
                                  gdata.userId?.lastName}
                              </h5>
                              <p className="text-muted">
                                {props.t("Pharmacy Status")}:
                                <Badge
                                  color="primary"
                                  className="font-size-12 ml-2 text-capitalize"
                                >
                                  {gdata.status}
                                </Badge>
                              </p>
                            </Media>
                          </Media>
                        </Col>
                      </Row>

                      <h5 className="font-size-15 mt-4">
                        {props.t("Pharmacy Details")}:{" "}
                      </h5>

                      <div className="text-muted mt-4">
                        <Row>
                          <Col lg="6">
                            <p>
                              <i className="bx bx-building text-primary mr-1"></i>{" "}
                              <strong>{props.t("Pharmacy Name")}: </strong>{" "}
                              {gdata.userId?.firstName +
                                " " +
                                gdata.userId?.lastName}
                            </p>

                            <p>
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <strong>{props.t("Created At")}: </strong>{" "}
                              {dctHumanReadableFormat(gdata.createdAt)}
                            </p>

                            <p>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("Updated At")}:</strong>{" "}
                              {dctHumanReadableFormat(gdata.updatedAt)}
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Address 1")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.Address1
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Primary Phone")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.PrimaryPhone
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("City")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.City
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Zip Code")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.ZipCode
                              }
                            </p>
                          </Col>

                          <Col lg="6">
                            <p>
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <strong>{props.t("Pharmacy Email")}: </strong>{" "}
                              {gdata.userId?.email}
                            </p>
                            <p>
                              <i className="bx bxs-key text-primary mr-1"></i>{" "}
                              <strong>{props.t("Secret Key")}: </strong>{" "}
                              {gdata.secretkey}
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Store Name")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.StoreName
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Address 2")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.Address2
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("Primary Fax")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.PrimaryFax
                              }
                            </p>
                            <p>
                              <i className="bx bx-clinic text-primary mr-1"></i>{" "}
                              <strong>{props.t("State")}: </strong>{" "}
                              {
                                dosespotPharmacyDetails?.doseSpotPharmacyData
                                  ?.State
                              }
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                ) : (
                  <CardBody>
                    {gmessage ? (
                      <div className="text-center">{}</div>
                    ) : (
                      <div className="text-center">
                        {loadingMessage("Loading...")}
                      </div>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {prep && gdata && gdata.secretkey && (
        <Prescription
          secretkey={gdata?.secretkey}
          propsHistoryPush={`/dashboard`}
        ></Prescription>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { partner } = state.Partner;
  //const { member } = state.Member;
  const { message } = state.Partner;
  return { partner, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    partnerActionSuccessful,
    setSinglePartner,
    setSinglePartnerAuth,
  })(withNamespaces()(PharmacyOverview))
);
