import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect, useSelector } from "react-redux";

import { Col, Row, Alert } from "reactstrap";

import {
  updateConsultStepwise,
  getSingleConsultation,
  getMemberAndDependents,
  getDraftConsultation,
} from "../../../store/actions";
import { dctClsAddRemove } from "../../../helpers/Commons";
import { dctPutDosespotData } from "../../../helpers/backend_helper";
import axios from "axios";
import { Config } from "../../../Config";
import moment from "moment";

function Finish(props) {
  let memberObj = [];
  memberObj = useSelector((state) => state.Member);
  const { singleConsult, draftConsult, memberAndDependents } = memberObj;
  const [isCompleted, setIsCompleted] = useState(false);
  const [pharmacyData, setPharmacyData] = useState(null);
  const [cDataError, setCdataError] = useState(null);
  const consultData = useSelector((state) => state.Member);
  console.log();
  useEffect(() => {
    props.pageTitle(
      "Please select a convenient time for this Diagnostic Phone Medical Consultation:"
    );
  }, [props]);

  const retReactNative = () => {
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage("BACK_TO_HOME");
  };

  const submitConsultation = async () => {
    let _consultData = [];
    _consultData = draftConsult ? draftConsult : singleConsult;
    if (!_consultData || !_consultData.id) {
      return;
    }

    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    dctClsAddRemove("dr-form-spinner", "d-none", "remove");
    let response = "";

    let url = `${process.env.REACT_APP_API_PATH}/consultation/${_consultData.id}/confirmed`;

    response = await dctPutDosespotData(url, "", headers).then((data) => data);

    if (response && response.success === true) {
      dctClsAddRemove("dr-form-spinner", "d-none", "add");
      setIsCompleted(true);
    } else if (response && !response.data.success) {
      setCdataError(response.data);
      dctClsAddRemove("dr-form-spinner", "d-none", "add");
      setIsCompleted(false);
    } else {
      dctClsAddRemove("dr-form-spinner", "d-none", "add");
      setIsCompleted(false);
    }
  };

  const cancelConsultation = async () => {
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    // dctClsAddRemove("dr-form-spinner", "d-none", "remove");
    let response = "";

    let url = `${process.env.REACT_APP_API_PATH}/consultation/${singleConsult?.id}/cancelled`;

    response = await dctPutDosespotData(url, "", headers).then((data) => data);
    if (response) {
      props.history.push("/member/schedule-center");
    }
    // return;
  };
  const fetchPharmacyData = (pharmacyId) => {
    if (memberAndDependents?.companyId?.fulfilment == "MTM") {
      let pharmaciesList = memberAndDependents?.mtmPharmacies;

      pharmaciesList =
        pharmaciesList?.length > 0
          ? pharmaciesList[pharmaciesList?.length - 1]
          : [];
      setPharmacyData(pharmaciesList?.pharmacy);
    } else {
      let url = process.env.REACT_APP_API_PATH + `/pharmacy/${pharmacyId}`;
      const options = {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      };
      axios
        .get(url, options)
        .then((response) => {
          setPharmacyData(response.data?.data);
        })
        .catch((err) => {
          setPharmacyData(null);
        });
    }
  };
  const renderPharmacyDetails = (pharmacyData) => {
    return (
      <p className="text-muted font-weight-13 ">
        <b>{pharmacyData.StoreName}</b>
        <br />
        <span className="text-justify">
          {pharmacyData.Address1}
          <br />
          <span>
            {pharmacyData.City}, {pharmacyData.State} {pharmacyData.ZipCode}
          </span>
          <br />
          {props.t("P")}: {pharmacyData.PrimaryPhone}
          <br />
          {props.t("F")}: {pharmacyData.PrimaryFax}
        </span>
      </p>
    );
  };
  useEffect(() => {
    if (memberAndDependents?.companyId?.fulfilment == "MTM") {
      fetchPharmacyData();
    }
    if (!pharmacyData)
      if (singleConsult?.pharmacy_pharmacyId) {
        fetchPharmacyData(singleConsult?.pharmacy_pharmacyId);
      }
  });

  return (
    <Fragment>
      <div className="row justify-content-center">
        <Col lg="12">
          <div className="">
            {isCompleted ? (
              <>
                <div>
                  <i className="mdi mdi-check-circle text-success display-4 mr-2 align-middle"></i>
                  <span className="font-size-20 font-weight-medium align-middle">
                    {props.t("You're all set!")}
                  </span>
                </div>
                <Fragment>
                  <div className="mt-3">
                    <p className="mb-3">
                      {props.t(
                        `Your Diagnostic Consultation has been successfully submitted to a Physician. You will be receiving a call ${
                          consultData?.singleConsult?.schedule_to &&
                          moment(
                            consultData?.singleConsult?.schedule_to
                          ).fromNow()
                        }. If you have any questions please contact member services at`
                      )}{" "}
                      {""} <strong>888-243-4505</strong>
                    </p>
                    <p className="alert alert-primary mb-3">
                      {props.t(
                        `The call you will receive will be from this number`
                      )}{" "}
                      : <strong>{props.t(`888-243-4505`)}</strong>.{" "}
                      {props.t(
                        `We suggest you save this number as a contact into your phone. That way, when we call, you will know it's us.`
                      )}
                    </p>
                    <p className="font-weight-medium mt-3 mb-0">
                      {props.t(`Wishing you good health,`)}
                    </p>

                    <p className="text-primary">
                      {props.t(`- ${Config.names.appName} Team`)}
                    </p>
                  </div>

                  <div className="mb-3 mt-4">
                    <button
                      type="button"
                      className="btn btn-secondary waves-effect waves-light mr-2"
                      onClick={() => {
                        props.history.push("/member/my-consultations/");
                        retReactNative();
                      }}
                    >
                      {props.t("Check your Consultation Status")}
                    </button>
                  </div>
                </Fragment>
              </>
            ) : (
              <Fragment>
                {cDataError && !cDataError.success ? (
                  <Alert color="danger">
                    {cDataError?.message
                      ? cDataError.message
                      : "Something went wrong!"}
                  </Alert>
                ) : null}
                <h5 className="font-size-15 mt-4">
                  {props.t("Televisit Details")} :
                </h5>

                <div className="text-muted mt-4">
                  <Row>
                    <Col lg="6">
                      <p>
                        <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                        <strong>{props.t("Main Complaint")}: </strong>{" "}
                        {singleConsult?.details_chiefcomplaint}
                      </p>
                      <p>
                        <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                        <strong>{props.t("Common Systoms")}: </strong>{" "}
                        {singleConsult?.details_commonSymptoms?.map(
                          (value, index) => {
                            return index <=
                              singleConsult?.details_commonSymptoms.length - 2
                              ? value + ", "
                              : value;
                          }
                        )}
                      </p>

                      <p>
                        <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                        <strong>{props.t("Other Systoms")}: </strong>{" "}
                        {singleConsult?.details_allOtherSymptoms && singleConsult?.details_allOtherSymptoms?.map(
                          (value, index) => {
                            return index <=
                              singleConsult?.details_allOtherSymptoms.length - 2
                              ? value + ", "
                              : value;
                          }
                        )}
                      </p>
                      {/* <p>
                              <i className="bx bxs-droplet-half text-primary mr-1"></i>{" "}
                              <strong>{props.t("Blood Type")}: </strong>{" "}
                              {singleConsult?.memberId.bloodType}
                            </p>
                            <p>
                              <i className="bx bx-donate-blood text-primary mr-1"></i>{" "}
                              <strong>
                                {props.t("Systolic Blood Pressure")}:{" "}
                              </strong>{" "}
                              {singleConsult?.memberId.bloodPressureSystolic}
                            </p>
                            <p>
                              <i className="bx bx-donate-blood text-primary mr-1"></i>{" "}
                              <strong>
                                {props.t("Diastolic Blood Pressure")}:{" "}
                              </strong>{" "}
                              {singleConsult?.memberId.bloodPressureDiastolic}
                            </p>
                            <p>
                              <i className="bx bx-plus-medical text-primary mr-1"></i>{" "}
                              <strong>{props.t("Smoke")}: </strong>{" "}
                              {singleConsult?.memberId.smoke}
                            </p>
                            <p>
                              <i className="bx bxs-drink text-primary mr-1"></i>{" "}
                              <strong>{props.t("Drink")}: </strong>{" "}
                              {singleConsult?.memberId.drink}
                            </p>
                            <p>
                              <i className="bx bx-dumbbell text-primary mr-1"></i>{" "}
                              <strong>{props.t("Exercise")}: </strong>{" "}
                              {singleConsult?.memberId.exercise}
                            </p> */}
                      <div>
                        <i className="bx bx-plus-medical text-primary mr-1"></i>{" "}
                        <strong>{props.t("Pharmacy")}: </strong>{" "}
                        {pharmacyData
                          ? renderPharmacyDetails(pharmacyData)
                          : memberAndDependents?.mtmPharmacies
                          ? renderPharmacyDetails(
                              memberAndDependents?.mtmPharmacies[
                                memberAndDependents?.mtmPharmacies?.length - 1
                              ].pharmacy
                            )
                          : singleConsult?.pharmacy_pharmacyId}
                      </div>
                    </Col>

                    <Col lg="6">
                      <p className="text-capitalize">
                        <i className="bx bx-user text-primary mr-1"></i>{" "}
                        <strong>{props.t("Patient Name")} : </strong>{" "}
                        {singleConsult?.patient}
                      </p>
                      <p>
                        <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                        <strong>{props.t("Main Contact Email")}: </strong>{" "}
                        <a
                          href={"mailto:" + singleConsult?.mainContactEmail}
                          className="text-dark"
                        >
                          {singleConsult?.mainContactEmail}
                        </a>
                      </p>

                      <p>
                        <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                        <strong>{props.t("Primary Phone")}: </strong>{" "}
                        <a
                          href={"tel:" + singleConsult?.phone_tel}
                          className="text-dark"
                        >
                          {singleConsult?.phone_tel}
                        </a>
                      </p>
                      <p>
                        <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                        <strong>{props.t("Consultation Type")}: </strong>{" "}
                        {singleConsult?.consultationType === "video"
                          ? "Video Call"
                          : "Audio Call"}
                      </p>
                      <p>
                        <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                        <strong>{props.t("Phone Service")}: </strong>{" "}
                        {singleConsult?.phone_service}
                      </p>
                      <p>
                        <i className="bx bxs-map text-primary mr-1"></i>{" "}
                        <strong>{props.t("Country")}: </strong>{" "}
                        {singleConsult?.state_country}
                      </p>
                      <p>
                        <i className="bx bxs-map text-primary mr-1"></i>{" "}
                        <strong>{props.t("State")}: </strong>{" "}
                        {singleConsult?.state_currentState}
                      </p>
                    </Col>
                    <Col lg="12">
                      <strong>{props.t("Details")}: </strong>
                    </Col>
                    <Col lg="12" className="mr-1">
                      {singleConsult?.details_description}
                    </Col>
                    {/* <Col lg="12">
                            <strong>{props.t("Notes")}: </strong>
                          </Col>
                          <Col lg="12" className="mr-1">
                            {singleConsult?.notes[singleConsult?.notes?.length - 1]?.notes ?? ""}
                          </Col> */}
                  </Row>
                </div>

                <div className="mt-3">
                  <p className="alert alert-primary mb-3">
                    {props.t(
                      `Please Submit your consultation by clicking the below button.`
                    )}{" "}
                  </p>
                </div>

                <div className="mb-3 mt-4">
                  <button
                    type="button"
                    className="btn btn-outline-primary waves-light mr-2"
                    onClick={() => {
                      cancelConsultation();
                    }}
                  >
                    <span className="d-none">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    </span>{" "}
                    {props.t("Cancel")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light mr-2"
                    onClick={() => {
                      submitConsultation();
                    }}
                  >
                    <span className="dr-form-spinner d-none">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    </span>{" "}
                    {props.t("Submit Consultation")}
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        </Col>
      </div>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { memberAndDependents, draftConsult, consultation, singleConsult } =
    state.Member;
  return {
    memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    updateConsultStepwise,
    getSingleConsultation,
    getMemberAndDependents,
    getDraftConsultation,
  })(withNamespaces()(Finish))
);
