import React,{useState} from 'react'
import VoiceCall from './VoiceCall'

const VoiceCallInterface = (props) => {
  const [bool, setBool] = useState(props.flag);
  const handleOnClick = ()=>{
      setBool(!bool)
  }
    return (
        <div>
            {bool?
            <VoiceCall addtionalID={props} phone={props.phone}/>:<button onClick={handleOnClick} className="btn btn-primary"> Call <i className="bx bxs-phone"></i> </button>}
        </div>
    )
}

export default VoiceCallInterface
