import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Media,
  Badge,
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { connect, useSelector, useDispatch } from "react-redux";

import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

//Include Both Helper File with needed methods
import { loadingMessage, dctDateFormat } from "../../helpers/Commons";

import axios from "axios";
import { Config } from "../../Config";


const TelevisitsLogs = (props) => {
  
  const [televisitLogs, setTelevisitLogs] = useState([]);

  const fetchTelevisitLogs = () => {
    let pathname = props?.history?.location?.pathname;
    let pathParts = pathname.split("/");
    let consultationId = pathParts[pathParts.length - 2];
    if (!consultationId || consultationId == '') {
      return;
    }
    const headers = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/consultation/${consultationId}/logs`;
    axios
      .get(url, headers)
      .then((response) => {
        setTelevisitLogs(response?.data ? response.data : []);
      })
      .catch((err) => {
        console.log(err.response);
        setTelevisitLogs([]);
      });
  };

  useEffect(() => {
    fetchTelevisitLogs();
    return cleanup;
  }, []);

  const cleanup = () => { };

  const backToListLink = () => {
    return (
      <button
        className="btn btn-info waves-effect waves-light mr-2"
        onClick={() => {
          props.history.push("/member/my-consultations");
        }}
      >
        <i className="mdi mdi-arrow-left mr-1"></i> {props.t("Back to List")}
      </button>
    );
  };

  const renderTelevisitLogs = (televisitLogs) => {
    return televisitLogs.length > 0 ? (
      televisitLogs.map((log, index) => {
        return (
          <Tr key={index} data-row-id={log.id}>
            <Td>
              <div>{dctDateFormat(log.createdAt)}</div>
            </Td>
            <Td>
              <h5 className="text-truncate font-size-14">
              {log.actionBy?.firstName + ' ' + log.actionBy?.lastName}
              </h5>
            </Td>
            <Td>{log.action}</Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="3" className="text-center"></Td>
      </Tr>
    );
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="8">
              {/**TODO */}
            </Col>
            <Col lg="4">
              <div className="text-right">
                {backToListLink()}
              </div>
            </Col>
          </Row>
          <h5 className="font-size-15 mt-4">
            {props.t("Televisit Logs")} :
          </h5>
          <Row>
            <Col lg="12">
              <div className="table-responsive">
                <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}>
                  <Thead className="thead-light">
                    <Tr>
                      <Th scope="col">{props.t("Date/Time")}</Th>
                      <Th scope="col">{props.t("User")}</Th>
                      <Th scope="col">{props.t("Action")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>{renderTelevisitLogs(televisitLogs)}</Tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(TelevisitsLogs))
);
