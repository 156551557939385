import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";

import { Row, Col, Card, CardBody, Container, Badge } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import axios from "axios";

import { loadingMessage } from "../../helpers/Commons";

import DctHeroBanner from "../../components/Common/DctHeroBanner";
import { DctIcon } from "../../components/Common/Icons/DctIcon";
import { Config } from "../../Config";

const ScheduleCenter = (props) => {
  const { authMemberId, auth_token } = useSelector((state) => state.Login);

  const [pageLoader, setPageLoader] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const serviceMetaData = [
    {
      title: props.t("By Phone"),
      textInside:
        "Get an in-depth phone consultation with  physician. Obtain medical advice. recommendations, diagnosis and perscription medication, when approprite",
      icon: <DctIcon name={`${Config.names.slugName}-phone-icon`} size="80" />,
      link: "/member/consultation/phone/step/1",
    },
    {
      title: props.t("By Video"),
      textInside:
        "Talk to a physician face-to-face. Obtain medical advice, treatment recommendations, diagnosis and perscription medication, when approprite",
      icon: <DctIcon name={`${Config.names.slugName}-video-icon`} size="90" />,
      link: "/member/consultation/video/step/1",
    },
    {
      title: props.t("Message a Doctor"),
      textInside:
        "Chat with a physician. Obtain medical advice, treatment recommendations, diagnosis and perscription medication, when approprite",
      icon: <DctIcon name={`${Config.names.slugName}-message-icon`} size={90} />,
      link: "/member/ask-doctor/create",
    },
    {
      title: props.t("Behavioral Health"),
      textInside:
        "Telephonic Counseling is a convenient first step in getting such support",
      icon: <DctIcon name={`${Config.names.slugName}-health-icon`} size="90" />,
      link: "/member/behavioral-health",
    },
  ];

  const fetchUrl = async () => {
    let companyId;
    let url = `${process.env.REACT_APP_API_PATH}/member/${authMemberId}?populate=partnerId`;

    const headers = {
      headers: {
        Authorization: auth_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    axios
      .get(url, headers)
      .then(function (response) {
        companyId = response.data.partnerId?.companyId;
      })
      .then(function (response) {
        let url2 = `${process.env.REACT_APP_API_PATH}/member/${authMemberId}/service`;

        axios.get(url2, headers).then(function (response) {
          setServiceList(response.data);
          setPageLoader(false);
        });
      });
  };

  useEffect(() => {
    fetchUrl();
  }, []);

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <DctHeroBanner
                mainTitle={props.t("Schedule Center")}
                subTitle="Schedule a Consultation"
                isInfoBar={true}
                infoBarTitle=""
                isCall911={true}
              ></DctHeroBanner>
            </Col>
          </Row>

          <Row className="text-center mt-2">
            <Col xl="12">
              <div className="font-size-16">
                {props.t("What type of")}{" "}
                <strong>{props.t("diagonstic consultation")}</strong>{" "}
                {props.t("do you need?")}
              </div>
            </Col>
          </Row>
          {pageLoader ? (
            <div className={`${Config.names.slugName}-page-spinner text-center mt-5 mb-5`}>
              {loadingMessage("Please wait services are fetching....")}
            </div>
          ) : null}

          <Row className={`mt-5 mb-5 ${Config.names.slugName}-service-list`}>
            {serviceList.map((service, key) => (
              <Fragment key={key}>
                {service.status === "active" && service.name !== "Life Vitals" ? (
                  <Col
                    md="4"
                    xs="6"
                    className="text-center mb-4 cursor-pointer "
                    key={key}
                    onClick={() => {
                      service.name.toLowerCase() === "phone"
                        ? props.history.push(serviceMetaData[0].link)
                        : service.name.toLowerCase() === "message"
                          ? props.history.push(serviceMetaData[2].link)
                          : service.name.toLowerCase() === "video"
                            ? props.history.push(serviceMetaData[1].link)
                            : props.history.push(serviceMetaData[3].link);
                    }}
                  >
                    <Card className="text-center mb-3 d-flex">
                      <CardBody className={`${Config.names.slugName}-link`}>
                        {service.name.toLowerCase() === "phone" ? (
                          <p className={`${Config.names.slugName}-icon-link`}>
                            {serviceMetaData[0].icon}
                          </p>
                        ) : service.name.toLowerCase() === "message" ? (
                          <p className={`${Config.names.slugName}-icon-link`}>
                            {serviceMetaData[2].icon}
                          </p>
                        ) : service.name.toLowerCase() === "video" ? (
                          <p className={`${Config.names.slugName}-icon-link`}>
                            {serviceMetaData[1].icon}
                          </p>
                        ) : (
                          <p className={`${Config.names.slugName}-icon-link`}>
                            {serviceMetaData[3].icon}
                          </p>
                        )}
                        {/* <div className="text-muted mb-3">
                          {service.name.toLowerCase() === "phone" ? (
                            <span>
                              {props.t(serviceMetaData[0].textInside)}
                            </span>
                          ) : service.name.toLowerCase() === "message" ? (
                            <span>
                              {props.t(serviceMetaData[2].textInside)}
                            </span>
                          ) : (
                            <span>
                              {props.t(serviceMetaData[1].textInside)}
                            </span>
                          )}
                        </div> */}
                        {service.name.toLowerCase() === "phone" ? (
                          <h4 className="font-size-14 font-weight-normal">
                            {props.t(serviceMetaData[0].title)}
                          </h4>
                        ) : service.name.toLowerCase() === "message" ? (
                          <h4 className="font-size-14 font-weight-normal">
                            {props.t(serviceMetaData[2].title)}
                          </h4>
                        ) : service.name.toLowerCase() === "video" ? (
                          <h4 className="font-size-14 font-weight-normal">
                            {props.t(serviceMetaData[1].title)}
                          </h4>
                        ) : (
                          <h4 className="font-size-14 font-weight-normal">
                            {props.t(serviceMetaData[3].title)}
                          </h4>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                ) : (
                  ""
                )}
              </Fragment>
            ))}
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default withRouter(withNamespaces()(ScheduleCenter));
