import React from 'react'
import { connect, useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect, Fragment, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Config } from "../../../Config";
import { withRouter, Link } from "react-router-dom";
import { capitalizeWords, dctAlert, dctTimeStampToHumanDate } from "../../../helpers/Commons";
import { loadingMessage } from "../../../helpers/Commons";


const JoinHaze = () => {
  const { authDoctorId } = useSelector((state) => state.Login);
  const [joinHazeData, setJoinHazeData] = useState([])
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false)




  const GetAllQuestionnaire = () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_API_PATH}/api/questionnaire`
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    axios.get(url, options).then((res) => {
      // console.log("response", res.data)
      setJoinHazeData(res.data)
      setLoading(false)
    }).catch((err) => {
      setErrorMsg(
        "Could not fetch details... Error: " + err?.response?.data?.message
      );
      setLoading(false)
    })

  }

  useEffect(() => {
    GetAllQuestionnaire();
  }, [])



  return (
    <Fragment>
      <Container fluid style={{ marginTop: "5rem" }}>

        <Fragment>
          <Card >
            <Row>
              <Col lg="4">
                <div className="mt-3 ml-4">
                  <h5>{"Join Haze"}</h5>
                </div>
              </Col>
              <Col lg="8">
                <div className="text-left mb-2">
                  {errorMsg ? dctAlert(3, errorMsg) : ""}
                </div>
              </Col>
            </Row>
            <CardBody>
              <Row>
                <Col lg={12}>
                  {loading ? (
                    <div className={`${Config.names.slugName}-listing-loader`}>
                      {loadingMessage("Loading...")}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="table-responsive shadow-sm" >
                    <div style={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}>
                      <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table mt-2 `}>
                        <Thead className="thead-light">
                          <Tr>
                            <Th scope="col" style={{ width: "70px" }}>
                              #
                            </Th>
                            <Th scope="col" className="text-left" >{"First Name"}</Th>
                            <Th scope="col" className="text-center">{"Last Name"}</Th>
                            <Th scope="col" className="text-left">{"Email"}</Th>
                            <Th scope="col" className="text-center">{"Phone Number"}</Th>
                            <Th scope="col" className="text-left">{"Haze Id"}</Th>
                            <Th scope="col" className="text-center">{"Submission Date"}</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {joinHazeData?.map((data) =>
                            data.isApproved === "pending" ? (
                              <Tr
                                key={data.id}
                                data-row-id={data.id}
                              >

                                <Td className="text-center">
                                  <div className="avatar-xs">
                                    <Link
                                      to={"/joinhaze-overview/id/" + data.id}
                                      className="text-dark"
                                    >
                                      <span className="avatar-title rounded-circle text-capitalize">
                                        {data.formData && data.formData.fullName
                                          ? data.formData.fullName.first.charAt(0)
                                          : "D"}
                                      </span>
                                    </Link>
                                  </div>
                                </Td>

                                <Td className="text-left">
                                  <span
                                    className="text-dark"
                                  >
                                    {data.formData && data.formData.fullName
                                      ? data.formData.fullName.first
                                      : ""}
                                  </span>
                                </Td>
                                <Td className="text-center">
                                  <span
                                    className="text-dark"
                                  >
                                    {data.formData && data.formData.fullName
                                      ? data.formData.fullName.last
                                      : ""}
                                  </span>
                                </Td>
                                <Td className="text-left">
                                  <span
                                    className="text-dark"
                                  >
                                    {data.formData && data.formData.email
                                      ? data.formData.email
                                      : ""}
                                  </span>
                                </Td>
                                <Td className="text-center">
                                  <span
                                    className="text-dark"
                                  >
                                    {data.formData && data.formData.phoneNumber
                                      ? data.formData.phoneNumber.full
                                      : ""}
                                  </span>
                                </Td>
                                <Td className="text-left">
                                  <span
                                    className="text-dark"
                                  >
                                    {data.formData && data.formData.hazeId
                                      ? data.formData.hazeId
                                      : ""}
                                  </span>
                                </Td>
                                <Td className="text-center">
                                  <span
                                    className="text-dark"
                                  >
                                    {data.formData && data.formData.submissionDate
                                      ? `${data.formData.submissionDate.month}/${data.formData.submissionDate.day}/${data.formData.submissionDate.year}`
                                      : ""}
                                  </span>
                                </Td>
                              </Tr>) : ""
                          )}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Fragment>
      </Container>
    </Fragment>
  )
}

export default JoinHaze