import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { withRouter, Link } from "react-router-dom";

import ReactPaginate from "react-paginate";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { withNamespaces } from "react-i18next";

import moment from "moment";

import {
  capitalizeWords,
  dctAlert,
  dctTimeStampToHumanDate,
} from "../../../helpers/Commons";
import { loadingMessage } from "../../../helpers/Commons";

import axios from "axios";
import { useSelector } from "react-redux";
import { Config } from "../../../Config";

const PushNotification = (props) => {
  const {
    notifications,
    currentPage,
    pageSize,
    totalPages,
    setCurrentPage,
    ajaxLoader,
  } = props;

  const [errorMsg, setErrorMsg] = useState(false);
  const loginInfo = useSelector((state) => state.Login);
  const [doctorData, setDoctorData] = useState("");

  const reserveThenShowDetails = (key) => {
    const notification = notifications[key];
    const eventId = notification?.id;
    if (!eventId || eventId == "") {
      return;
    }

    let url = `${process.env.REACT_APP_API_PATH}/eventlog/${eventId}/reserved`;
    let bearer_token = localStorage.getItem("auth_token");
    const options = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const data = {
      userId: JSON.parse(localStorage.getItem("auth_user")).id,
    };
    axios
      .patch(url, data, options)
      .then((response) => {
        if (notification.objectType == "post") {
          localStorage.setItem("eventId", `${notification.id}`);
          props.history.push(`/lock/message/id/${notification.objectId}`);
          return;
        }
        if (notification.objectType == "consultation") {
          localStorage.setItem("eventId", `${notification.id}`);
          localStorage.setItem("televisits_location", props.location.pathname);
          props.history.push(
            `/televisits-overview/id/${notification.objectId}`
          );
          return;
        }
        if (notification.objectType == "prescriptionfill") {
          localStorage.setItem("eventId", `${notification.id}`);
          props.history.push(
            `/prescription-overview/id/${notification.objectId}`
          );
          return;
        }
      })
      .catch((error) => {
        setErrorMsg(
          "Could not fetch details... Error: " + error?.response?.data?.message
        );
        return;
      });
  };

  const fetchDoctorData = () => {
    if (loginInfo.authUserType !== "doctor") return;
    let url =
      process.env.REACT_APP_API_PATH +
      `/doctor/${loginInfo.authDoctorId}?populate=false`;
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios
      .get(url, options)
      .then((response) => {
        setDoctorData(response?.data);
      })
      .catch((err) => {
        console.log(err.message);
        setDoctorData(null);
      });
  };

  useEffect(() => {
    fetchDoctorData();
  }, []);

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col lg="4">
            <div className="mt-3">
              <h5>{props.t("Prescriptions")}</h5>
            </div>
          </Col>
          <Col lg="8">
            <div className="text-left mb-2">
              {errorMsg ? dctAlert(3, errorMsg) : ""}
            </div>
          </Col>
        </Row>
        <Fragment>
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  {ajaxLoader ? (
                    <div className={`${Config.names.slugName}-listing-loader`}>
                      {loadingMessage("Loading...")}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="table-responsive">
                    <Table
                      className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table mt-2`}
                    >
                      <Thead className="thead-light">
                        <Tr>
                          <Th scope="col" style={{ width: "70px" }}>
                            #
                          </Th>
                          <Th scope="col" className="text-left">
                            {props.t("Name")}
                          </Th>
                          <Th scope="col" className="text-center">
                            {props.t("State")}
                          </Th>
                          <Th scope="col" className="text-left">
                            {props.t("Pharmacy")}
                          </Th>
                          <Th scope="col" className="text-center">
                            {props.t("Date/Time ")}
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {notifications.length > 0 ? (
                          notifications?.map((notification, key) => (
                            <Tr
                              key={notification.id}
                              data-row-id={notification.id}
                              className={`cursor-pointer ${
                                notification.objectType === "consultation"
                                  ? " alert-info "
                                  : ""
                              }`}
                              onClick={() => {
                                reserveThenShowDetails(key);
                              }}
                            >
                              <Td>
                                <div className="avatar-xs">
                                  <Link
                                    to={"#"}
                                    onClick={() => {
                                      reserveThenShowDetails(key);
                                    }}
                                    className="avatar-title rounded-circle"
                                  >
                                    <i className="bx bx-list-ol"></i>
                                  </Link>
                                </div>
                              </Td>
                              <Td className="text-left">
                                <Link
                                  to={"#"}
                                  onClick={() => {
                                    reserveThenShowDetails(key);
                                  }}
                                  className="text-dark"
                                >
                                  {notification.prescription
                                    ? notification.prescription.firstName +
                                      " " +
                                      notification.prescription.lastName
                                    : null}
                                </Link>
                              </Td>
                              <Td className="text-center">
                                {notification.prescription
                                  ? notification.prescription.state
                                  : null}
                              </Td>
                              <Td className="text-left">
                                {notification.prescription
                                  ? notification.prescription.rxpharmacyId
                                    ? capitalizeWords(
                                        notification.prescription.rxpharmacyId
                                          .userId?.fullName
                                      )
                                    : ""
                                  : null}
                              </Td>
                              <Td className="text-center">
                                {dctTimeStampToHumanDate(
                                  notification.createdAt,
                                  doctorData?.timezoneAbbr
                                )}
                              </Td>
                            </Tr>
                          ))
                        ) : (
                          <Tr>
                            <Td
                              colSpan="5"
                              className="text-center text-danger text-capitalize"
                            >
                              {""}
                            </Td>
                          </Tr>

                          // <Tr>"No prescription found."</Tr>
                        )}
                      </Tbody>
                    </Table>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <div
                    className={
                      totalPages > 1
                        ? `${Config.names.slugName}-pager`
                        : `${Config.names.slugName}-pager d-none`
                    }
                  >
                    <nav className="d-flex justify-content-center mt-4">
                      <ReactPaginate
                        initialPage={currentPage - 1}
                        forcePage={currentPage - 1}
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={totalPages}
                        pageSize={pageSize}
                        breakLabel={"..."}
                        label={<input />}
                        breakClassName={"break-me"}
                        onPageChange={(e) => {
                          setCurrentPage(e.selected + 1);
                        }}
                        containerClassName={
                          "pagination pagination-rounded align-items-center"
                        }
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        activeLinkClassName={"active"}
                      />
                      <input
                        className="ml-3 text-center"
                        type="text"
                        size="5"
                        placeholder="Go to"
                        defaultValue={""}
                        // onChange={(e) => {
                        // }}
                        onKeyPress={(e) => {
                          if (
                            e.key === "Enter" &&
                            e.currentTarget.value != ""
                          ) {
                            setCurrentPage(e.currentTarget.value);
                            e.currentTarget.value = "";
                          }
                        }}
                        style={{ height: "25px" }}
                      />
                    </nav>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Fragment>
      </Container>
    </Fragment>
  );
};

export default withRouter(withNamespaces()(PushNotification));
