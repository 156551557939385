import React, { useState } from "react";
import { Container, Row, Col, Alert, Label } from "reactstrap";
import { withRouter, Link, Redirect } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import axios from "axios";

const PasswordManagement = (props) => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMes, setErrorMes] = useState("false");
  const [successmes, setSuccessmes] = useState("false");
  const handleRejectAndReply = async (props) => {
    let bearer_token = localStorage.getItem("auth_token");
    const data = {
      email: email,
      password: newPassword,
    };
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    let url = `${process.env.REACT_APP_API_PATH}/user/update-password`;
    await axios
      .patch(url, data, headers)
      .then((response) => {
        setError(false);
        setSuccess(true);

        setSuccessmes(response.data.message);
        document.querySelector(".dr-spinner").classList.add("d-none");
      })
      .catch((err) => {
        setSuccess(false);
        setError(true);

        setErrorMes(err.response.data.message);
        document.querySelector(".dr-spinner").classList.add("d-none");
      });
  };

  const handleValidSubmit = (e, v) => {
    if (email == "" && newPassword == "") {
      return;
    } else {
      handleRejectAndReply();

      document.querySelector(".dr-spinner").classList.remove("d-none");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="bg-white p-2">
            <Col md={8} lg={6} xl={6}>
              <Label className="font-size-18 e">
                {props.t("Password Management")}
              </Label>
              <p>
                {props.t(`Here you can change User Account's password.`)}
                {""}
              </p>
              <div className="">
                {success && (
                  <Alert color="success" style={{ marginTop: "13px" }}>
                    {successmes}
                  </Alert>
                )}

                {error && (
                  <Alert color="danger" style={{ marginTop: "13px" }}>
                    {errorMes}
                  </Alert>
                )}

                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}
                >
                  <div className="form-group">
                    <AvField
                      name="email"
                      label={props.t("Email")}
                      className="form-control"
                      placeholder={props.t("Email")}
                      type="text"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      required
                    />
                  </div>
                  <div className={"form-group"}>
                    <AvField
                      name="newPass"
                      label={props.t("New Password")}
                      className="form-control"
                      placeholder={props.t("New Password")}
                      type="text"
                      value={newPassword}
                      required
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                    />
                  </div>

                  <Row className="form-group">
                    <Col>
                      <AvField
                        name="userId"
                        type="hidden"
                        // value={getAuthIds.user}
                        required
                      />

                      <button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                        onClick={() => {
                          handleValidSubmit();
                        }}
                      >
                        <span className="dr-spinner d-none">
                          <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                        </span>
                        {props.t(`Reset`)}
                      </button>
                    </Col>
                  </Row>
                </AvForm>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(PasswordManagement));
