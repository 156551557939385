import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import axios from "axios";

import { withNamespaces } from "react-i18next";

const SidebarContent = (props) => {
  let { authUserType, authPartnerId, authUser } = useSelector(
    (state) => state.Login
  );

  if (!authUserType) {
    authUserType = JSON.parse(localStorage.getItem("auth_user")).userType;
    authPartnerId = JSON.parse(localStorage.getItem("auth_user")).partnerId;
  }

  const doctor_rx = JSON.parse(localStorage.getItem("auth_user")).rx;
  const [menus, setMenus] = useState([
    {
      admin: [
        {
          label: props.t("Dashboard"),
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          label: props.t("Groups"),
          link: "/groups",
          icon: "bx bx-group",
        },
        {
          label: props.t("Companies"),
          link: "/company",
          icon: "bx bxs-buildings",
          // className: "has-arrow waves-effect",
          // submenu: [
          //   {
          //     label: props.t("Companies"),
          //     link: "/company",
          //   },
          //   // {
          //   //   label: props.t("Partner"),
          //   //   link: "/partners",
          //   // },
          // ],
        },
        {
          label: props.t("Members"),
          link: "/members",
          icon: "mdi mdi-account-group",
        },

        // {
        //   label: props.t("Brands"),
        //   link: "/brand",
        //   icon: "mdi mdi-layers-triple",
        // },

        {
          label: props.t("Services"),
          link: "/#",
          icon: "fas fa-cog",
          className: "has-arrow waves-effect",
          submenu: [
            {
              label: props.t("Services"),
              link: "/services",
              // icon: "fas fa-cog",
            },
            {
              label: props.t("Brands"),
              link: "/brand",
              // icon: "mdi mdi-layers-triple",
            },
            {
              label: props.t("Partners"),
              link: "/partners",
              // icon: "mdi mdi-human-male-male",
            },
          ],
        },

        {
          label: props.t("Consultations"),
          link: "/#",
          icon: "mdi mdi-stethoscope",
          className: "has-arrow waves-effect",

          submenu: [
            // {
            //   label: props.t("Members"),
            //   link: "/members",
            // },
            {
              label: props.t("All Consultations"),
              link: "/member/my-consultations",
            },
            {
              label: props.t("Consultations Report"),
              link: "/member/consultationsreport",
            },
            {
              label: props.t("Messages"),
              link: "/member/messages",
            },
          ],
        },
        {
          label: props.t("Doctors"),
          link: "/doctors",
          icon: "mdi mdi-doctor",
        },
        // {
        //   label: props.t("Doctors"),
        //   link: "/#",
        //   icon: "mdi mdi-doctor",
        //   className: "has-arrow waves-effect",
        //   submenu: [
        //     {
        //       label: props.t("Doctors"),
        //       link: "/doctors",
        //     },
        //   ],
        // },
        {
          label: props.t("RxGroup"),
          link: "/#",
          icon: "mdi mdi-file",
          className: "has-arrow waves-effect",
          submenu: [
            {
              label: props.t("RxGroup"),
              link: "/pharmacies",
            },
            {
              label: props.t("RxReport"),
              link: "/rxreport",
            },
            {
              label: props.t("RxPrescriptionList"),
              link: "/rxprescriptionlist",
            },
            {
              label: props.t("RxArchive"),
              link: "/rxarchive",
            },
            {
              label: props.t("Join Haze"),
              link: "/joinhaze",
            },
          ],
        },

        {
          label: props.t("Logs"),
          link: "/eventlogs",
          icon: "mdi mdi-clipboard-list",
          // className: "has-arrow waves-effect",
          // submenu: [
          //   {
          //     label: props.t("Event Logs"),
          //     link: "",
          //   },
          // ],
        },
        {
          label: props.t("Profile"),
          link: "/profile",
          icon: "bx bx-user-circle",
        },
        {
          label: props.t("Failed Consultations"),
          link: "/failed-consultations",
          icon: "mdi mdi-clipboard-list",
        },
        {
          label: props.t("Password Management"),
          link: "/password-management",
          icon: "bx bx-lock",
        },

        // {
        //   label: props.t("Physician Directories"),
        //   link: "/physician-directories",
        //   icon: "mdi mdi-clipboard-list",
        // },
      ],
    },
    {
      partner: [
        {
          label: props.t("Dashboard"),
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          label: props.t("Members"),
          link: "/members",
          icon: "mdi mdi-account-group",
        },

        {
          label: props.t("Consultations"),
          link: "/#",
          icon: "mdi mdi-stethoscope",
          className: "has-arrow waves-effect",

          submenu: [
            {
              label: props.t("Consultations"),
              link: "/member/my-consultations",
            },
            {
              label: props.t("Messages"),
              link: "/member/messages",
            },
          ],
        },
        {
          label: props.t("Partner"),
          link: `/partners-overview/id/${authPartnerId}`,
          icon: "mdi mdi-human-male-male",
        },
        {
          label: props.t("Profile"),
          link: "/profile",
          icon: "bx bx-user-circle",
        },
      ],
    },
    {
      member: [
        {
          label: props.t("Dashboard"),
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          label: props.t("Health Records"),
          link: "/member/health-records",
          icon: "bx bx-test-tube",
        },
        {
          label: props.t("Message A doctor"),
          link: "/member/ask-doctor",
          icon: "bx bx-message-add",
        },
        {
          label: props.t("Behavioral Health"),
          link: "/member/behavioral-health",
          icon: "bx bx-home-heart",
        },
        {
          label: props.t("Consultation"),
          link: "/#",
          icon: "bx bx-briefcase",
          className: "has-arrow waves-effect",
          submenu: [
            {
              label: props.t("Schedule a Consultation"),
              link: "/member/schedule-center",
            },
            {
              label: props.t("My Consultations"),
              link: "/member/my-consultations",
            },
          ],
        },
        {
          label: props.t("Profile"),
          link: "/member/my-account",
          icon: "bx bx-user-circle",
        },
        {
          label:
            process.env.REACT_APP_SLUG === "doctegrity"
              ? props.t("Discount Card")
              : "",
          link:
            process.env.REACT_APP_SLUG === "doctegrity"
              ? "/member/discount-card"
              : "",
          icon:
            process.env.REACT_APP_SLUG === "doctegrity"
              ? "bx bx-credit-card"
              : "",
        },
      ],
    },
    {
      doctor: [
        {
          label: props.t("Dashboard"),
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
        {
          label: props.t("Prescription"),
          link: "/rxprescriptionlist",
          icon: "mdi mdi-prescription",
        },
        {
          label: props.t("Messages"),
          link: "/doctor/messages",
          icon: "bx bx-message-add",
        },
        {
          label: props.t("Televisits"),
          link: "/doctor/televisits",
          icon: "bx bx-briefcase",
        },
        {
          label: props.t("Profile"),
          link: "/doctor/my-account",
          icon: "bx bx-user-circle",
        },
        {
          label: authUser?.joinHaze ? props.t("Join Haze") : "",
          link: authUser?.joinHaze ? "/doctor/join-haze" : "",
          icon: authUser?.joinHaze ? "far fa-file-alt" : "",
        },
      ],
    },
    {
      pharmacy: [
        {
          label: props.t("Dashboard"),
          link: "/dashboard",
          icon: "bx bx-home-circle",
        },
      ],
    },
    {
      customerservice: [
        {
          label: props.t("Members"),
          link: "/members",
          icon: "mdi mdi-account-group",
        },
      ],
    },
  ]);

  useEffect(() => {
    if (authUserType === "doctor" && doctor_rx === true) {
      menus[3].doctor.splice(3, 0);
      setMenus(menus);
      return;
    } else {
      // menus[3].doctor.splice(3, 0);
      // setMenus(menus);
      // Find the index of the "doctor" object in the array
      let index = menus.findIndex((obj) => obj.hasOwnProperty("doctor"));

      // Find the index of the "Prescription" object in the "doctor" array
      let prescriptionIndex = menus[index].doctor.findIndex(
        (obj) => obj.label === "Prescription"
      );

      // Remove the "Prescription" object using the splice() method
      menus[index].doctor.splice(prescriptionIndex, 1);
    }

    if (authUser) setMenus(menus);
  }, [menus]);

  const renderMenu = (role) => {
    let roleMenu = menus.find((menu) => Object.keys(menu)[0] === role);

    return roleMenu !== undefined
      ? roleMenu[role].map((menu, key) => {
          return (
            <li key={key}>
              <Link to={menu.link} className={menu.className}>
                <i className={menu.icon}></i>
                <span>{props.t(menu.label)}</span>
              </Link>
              {menu.submenu ? (
                <ul className="sub-menu" aria-expanded="false">
                  {menu.submenu
                    ? menu.submenu.map((submenu, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to={submenu.link}
                              className={submenu.className}
                            >
                              {submenu.icon ? (
                                <i className={submenu.icon}></i>
                              ) : (
                                ""
                              )}
                              {props.t(submenu.label)}
                            </Link>
                          </li>
                        );
                      })
                    : []}
                </ul>
              ) : (
                ""
              )}
            </li>
          );
        })
      : [];
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show");
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  }

  const initMenu = (pathName) => {
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        activateParentDropdown(items[i]);
        break;
      }
    }
  };

  useEffect(() => {
    initMenu(props.location.pathname);
  }, [props.location.pathname]);

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title d-none">{props.t("Menu")} </li>
          {renderMenu(authUserType)}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(SidebarContent));
