export const CREATE_POST_MESSAGE = "CREATE_POST_MESSAGE";
export const UPDATE_POST_MESSAGE = "UPDATE_POST_MESSAGE";
export const GET_POST_MESSAGE = "GET_POST_MESSAGE";
export const GET_POST_MESSAGE_LIST = "GET_POST_MESSAGE_LIST";
export const SET_POST_MESSAGE_LIST = "SET_POST_MESSAGE_LIST";

export const SET_POST_MESSAGE_LISTING = "SET_POST_MESSAGE_LISTING";
export const CREATE_POST_MESSAGE_SUCCESS = "CREATE_POST_MESSAGE_SUCCESS";
export const CREATE_POST_MESSAGE_FAILED = "CREATE_POST_MESSAGE_FAILED";
export const SET_SINGLE_POST_MESSAGE = "SET_SINGLE_POST_MESSAGE";
export const GET_SINGLE_POST_MESSAGE = "GET_SINGLE_POST_MESSAGE";

export const CREATE_POST_COMMENT = "CREATE_POST_COMMENT";
export const UPDATE_POST_COMMENT = "UPDATE_POST_COMMENT";
export const CREATE_POST_COMMENT_SUCCESS = "CREATE_POST_COMMENT_SUCCESS";
export const CREATE_POST_COMMENT_FAILED = "CREATE_POST_COMMENT_FAILED";
export const SET_POST_COMMENT_LIST = "SET_POST_COMMENT_LIST";

export const API_ERROR = "API_ERROR";
