import * as React from 'react';
import BaseSweetAlert from 'react-bootstrap-sweetalert';

export class SweetAlert extends BaseSweetAlert {
  constructor(props) {
    super(props);
    this.state = {
        bodyHeight: document.body.offsetHeight
    }
  } 
  render() {
    let reactNativeCls;
    if(window.ReactNativeWebView) {
        reactNativeCls = ' for-react-native '
    }
    let originalOnShow = this.props.afterMount;
    let originalOnConfirm = this.props.onConfirm;
    let originalOnCancel = this.props.onCancel;
    let dctCustomClassName = this.props.customClass ? this.props.customClass + reactNativeCls : reactNativeCls;
    let originalProps = Object.assign({}, this.props);
    delete originalProps.show;
    delete originalProps.onConfirm;

    return (
      <BaseSweetAlert {...this.props} customClass = {dctCustomClassName} 
      afterMount={(e) => {
        if(typeof window.reactNativeViewportHeight !== "undefined") {
          document.querySelectorAll('.sweet-alert').forEach((el) => {
            el.parentElement.classList.add("sweet-alert-overlay");
          });
            this.setState({
                bodyHeight: document.body.offsetHeight
            });
          document.body.style.height = window.reactNativeViewportHeight + "px"; // Run when Modal Opens
				}
        originalOnShow(e);
      }} 
      onConfirm={() => {
        if(typeof window.reactNativeViewportHeight !== "undefined") {
            document.body.style.height = "100%"; // Run when Modal Closes
        }
        originalOnConfirm();
      }}
      onCancel={() => {
        if(typeof window.reactNativeViewportHeight !== "undefined") {
            document.body.style.height = "100%"; // Run when Modal Closes
        }
        originalOnCancel();
      }}>{this.props.children}</BaseSweetAlert>
    )
  }
}
