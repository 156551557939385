import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { setSingleService, setServiceListing } from "../../store/actions";

import { Row, Col, UncontrolledTooltip } from "reactstrap";

//Include Both Helper File with needed methods
import { dctUpdateStatus } from "../../helpers/backend_helper";
import { loadingMessage } from "../../helpers/Commons";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { Config } from "../../Config";

const ServiceList = (props) => {
  const { currentPage, perPage, total, getDataOnEnter, ajaxLoader } = props;

  let servicesObj = [];

  servicesObj = useSelector((state) => state.Service.serviceListing);
  servicesObj = servicesObj ? servicesObj : [];
  const dispatch = useDispatch();

  const [customPageNo, setCustomPageNo] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);

  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [serviceStatusMeta, setServiceStatusMeta] = useState({
    rowIndex: "",
    serviceId: "",
    serviceStatus: 0,
  });

  useEffect(() => {
    // sleep()
  }, []);

  const openServiceEditModal = (data) => {
    document.querySelector("#service_update_modal").click();
    //Push Single Group Data to store
    dispatch(setSingleService(data, "", ""));
  };

  const openCSVuploadModal = (data) => {
    document.getElementById("csv_upload_modal").click();
    //Push Single Group Data to store
    dispatch(setSingleService(data, "", ""));
  };

  const toggleServiceStatus = async (serviceStatusMeta) => {
    if (!serviceStatusMeta) return;

    const { serviceId, serviceStatus, rowIndex } = serviceStatusMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Activation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    const loaderCls = ["bx-loader", "bx-spin", "font-size-18"];
    let toogleUserCls =
      serviceStatus === "active" ? "bx-check" : "bx-user-x";
    setStatusLoader(true);

    // Adding the bx-loader and removing the box-user-check class
    document
      .querySelector("#status_" + serviceId)
      .children[0].classList.add(...loaderCls);
    document
      .querySelector("#status_" + serviceId)
      .children[0].classList.remove(toogleUserCls);

    let gStatus = serviceStatus === "active" ? "inactive" : "active";
    const postData = {
      id: serviceId,
      status: gStatus,
    };
    const url = "servicelist/" + serviceId;
    const response = await dctUpdateStatus(postData, url).then((data) => data);
    if (response.status === "active") {
      setdynamic_description("Activated successfully.");
      setdynamic_title("Activated");
    }
    if (response.status === "inactive") {
      setdynamic_description("Deactivated successfully.");
      setdynamic_title("Deactivated");
    }

    if (response.id) {
      seterror_dlg(false);
    } else {
      seterror_dlg(true);
      setsuccess_dlg(false);
    }

    servicesObj[rowIndex].status = response.status;
    // dispatch(setGroupListing(null));
    dispatch(setServiceListing(servicesObj, null));
    setStatusLoader(false);
    toogleUserCls =
      response.status === "active" ? "bx-check" : "bx-user-x";
    document
      .querySelector("#status_" + serviceId)
      .children[0].classList.remove(...loaderCls);
    document
      .querySelector("#status_" + serviceId)
      .children[0].classList.add(toogleUserCls);
  };
  const serviceItemsBody = (servicesObj) => {
    return servicesObj.length > 0 ? (
      servicesObj.map((service, index) => {
        return (
          <Tr key={index} data-row-id={service.id}>
            <Td className="text-center">
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle text-capitalize">
                  {service?.name?.charAt(0)}
                </span>
              </div>
            </Td>
            <Td className="text-left">
              <h5 className="text-truncate font-size-14">{service.name}</h5>
            </Td>
            <Td className="text-left">
              <h5 className="text-truncate font-size-14">
                {service.description}
              </h5>
            </Td>
            <Td className="text-center">
              <Link
                to="#"
                className={
                  service.status === "inactive"
                    ? "badge badge-soft-danger font-size-11 m-1"
                    : "badge badge-soft-primary font-size-11 m-1"
                }
              >
                {service.status === "inactive" ? "Inactive" : "Active"}
              </Link>
            </Td>
            <Td className="text-center">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2 d-none">
                  <Link
                    to="#"
                    id="message_1"
                    data-service-id={service.id}
                    onClick={() => {
                      openCSVuploadModal(service);
                    }}
                  >
                    <i className="bx bx-cloud-upload"></i>
                    <UncontrolledTooltip placement="top" target="message_1">
                      {props.t("Upload")}
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"edit" + service.id}
                    onClick={() => {
                      openServiceEditModal(service);
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"edit" + service.id}
                    >
                      {props.t("Edit")}
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"status_" + service.id}
                    data-status={service.status}
                    data-id={service.id}
                    onClick={() => {
                      setconfirm_both(true);
                      setServiceStatusMeta({
                        rowIndex: index,
                        serviceId: service.id,
                        serviceStatus: service.status,
                      });
                    }}
                  >
                    {service.status === "active" ? (
                      <i className="bx bx-check text-success"></i>
                    ) : (
                      <i className="bx bx-x text-danger"></i>
                    )}
                    <UncontrolledTooltip
                      placement="top"
                      target={"status_" + service.id}
                    >
                      {service.status ? "Active" : "Inactive"}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
              {confirm_both ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="secondary"
                  onConfirm={() => {
                    toggleServiceStatus(serviceStatusMeta);
                  }}
                  onCancel={() => {
                    setconfirm_both(false);
                    setsuccess_dlg(false);
                  }}
                >
                  {props.t(`This process will change the Service status!`)}
                </SweetAlert>
              ) : null}
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="7" className="text-center"></Td>
      </Tr>
    );
  };
  const handleSearchPage = (e) => {
    if (e.key === "Enter") {
      getDataOnEnter(e.currentTarget.value);
    }
  };
  return (
    <React.Fragment>
      <div className="group-list">
        <Row>
          <Col lg="12">
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {error_dlg ? (
              <SweetAlert
                error
                title={dynamic_title}
                onConfirm={() => {
                  seterror_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {ajaxLoader ? (
              <div className={`${Config.names.slugName}-listing-loader`}>
                {loadingMessage("Loading...")}
              </div>
            ) : (
              ""
            )}
            <div className="table-responsive">
              <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}>
                <Thead className="thead-light">
                  <Tr>
                    <Th scope="col" className="text-center" style={{ width: "70px" }}>
                      #
                    </Th>
                    <Th scope="col" className="text-left">{props.t("Services Name")}</Th>
                    <Th scope="col" className="text-left">{props.t("Service Description")}</Th>
                    <Th scope="col" className="text-center" style={{ width: "70px" }}>
                      {props.t("Status")}
                    </Th>
                    <Th scope="col" className="text-center" style={{ width: "70px" }}>
                      {props.t("Action")}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>{serviceItemsBody(servicesObj)}</Tbody>
              </Table>
              {props.serviceListMessage ? (
                <div className="text-center text-danger">
                  <i className="bx bx-search-alt font-size-18 align-middle mr-2"></i>{" "}
                  {props.serviceListMessage}{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className={total > perPage ? `${Config.names.slugName}-pager` : `${Config.names.slugName}-pager d-none`}>
              <nav className="d-flex justify-content-center mt-4">
                <ReactPaginate
                  initialPage={currentPage}
                  forcePage={currentPage}
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={total}
                  itemsCountPerPage={perPage}
                  breakLabel={"..."}
                  label={<input />}
                  breakClassName={"break-me"}
                  onPageChange={props.getUserData}
                  containerClassName={
                    "pagination pagination-rounded align-items-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  activeLinkClassName={"active"}
                />
                <input
                  className="ml-3 text-center"
                  type="text"
                  size="5"
                  placeholder="Go to"
                  value={customPageNo}
                  onChange={(e) => setCustomPageNo(e.currentTarget.value)}
                  onKeyPress={handleSearchPage}
                  style={{ height: "25px" }}
                />
              </nav>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(ServiceList));
