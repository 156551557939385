import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import AsyncSelect from "react-select/async";

import debounce from "lodash/debounce";

import {
  createPartnerAuth,
  updatePartnerAuth,
  createPartnerFailedAuth,
  partnerActionSuccessfulAuth,
  setSinglePartnerAuth,
} from "../../store/actions";

//Include Both Helper File with needed methods
import { dctGetAsyncData } from "../../helpers/backend_helper";
import partner from "../../store/partner/reducer";
import axios from "axios";
import { Config } from "../../Config";

const CreateUpdatePartnerAuth = (props) => {
  const [modal, setmodal] = useState(false);
  const [msgEnable, setmsgEnable] = useState(true);
  const [partnerId, setPartnerId] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setSecretkey] = useState("");

  // Load the fetched Company data for the select dropdown
  const loadCompanyOptions = (inputValue, callback) => {
    const axiosArgs = {
      params: inputValue,
      URL:
        process.env.REACT_APP_API_PATH +
        "/company/names/?limit=3000&fields=companyName&q=" +
        inputValue,
    };
    dctGetAsyncData(axiosArgs).then((results) => callback(results));
    return;
  };
  const companyListOptions = debounce(loadCompanyOptions, 250);

  const defualtCompanyValue = (data) => {
    if (!data.companyId) {
      return {
        companyName: "Select company",
        id: "",
      };
    }
    return {
      companyName: data.companyId.companyName ? data.companyId.companyName : "",
      id: data.companyId.id,
    };
  };

  function toggleModalClose(e) {
    //checking Store props
    if (props.singlePartnerAuth) {
      props.updatePartnerDataFlag();
    }
  }

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-cform-spinner")[0]
      .classList.remove("d-none");

    setmsgEnable(true);

    //call reducer method
    if (values.actionType === "edit") {
      // props.updatePartnerAuth(values, props.history);
    } else {
      props.createPartnerAuth(values, props.history);
    }
  }

  const dispatch = useDispatch();

  const editFormPartnerItem = (e) => {
    setmsgEnable(false);
    dispatch(partnerActionSuccessfulAuth(null, "", ""));
  };

  const createFormPartnerItem = (e) => {
    setmsgEnable(false);
    dispatch(partnerActionSuccessfulAuth(null, "", ""));
    dispatch(setSinglePartnerAuth(null, "", ""));
    setmodal(!modal);
  };

  const getApikey = () => {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();
    }

    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    setApiKey(uuid);
    // return uuid;
  };

  const getSecretKey = () => {
    var generator = require("generate-password");

    var password = generator.generate({
      length: 25,
      uppercase: true,
      numbers: true,
      symbols: true,
    });
    setSecretkey(password);
  };

  let partnerAuthData = useSelector((state) => state.PartnerAuth.singlePartner);
  partnerAuthData = partnerAuthData ? partnerAuthData : [];
  const action_type = props.partner_action_type === "edit" ? "edit" : "create";
  const button_label =
    props.partner_action_type === "edit" ? "Update" : "Create PartnerAuth";
  const modal_title =
    props.partner_action_type === "edit"
      ? "Update  Partner"
      : "Create PartnerAuth";

  useEffect(() => {
    const v = window.location.pathname.split("/");
    setPartnerId(v[v.length - 1]);
  }, []);

  const createUpdatePartnerAuthKeys = (partnerAuthId) => {
    const data = {
      apiKey: apiKey,
      apiSecret: apiSecret,
      partnerId: partnerId,
    };
    const data1 = {
      apiKey: apiKey,
      apiSecret: apiSecret,
    };
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    if (props.partner_action_type === "edit") {
      const url =
        process.env.REACT_APP_API_PATH + "/partnerauth/" + partnerAuthId;
      axios
        .patch(url, data1, headers)
        .then((response) => {
          setmodal(!modal);
          props.fetchSinglePartner(partnerId);
        })
        .catch((err) => {
          console.log("error", err);
        });
    } else {
      const url = process.env.REACT_APP_API_PATH + "/partnerauth";
      axios
        .post(url, data, headers)
        .then((response) => {
          setmodal(!modal);
          props.fetchSinglePartner(partnerId);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  return (
    <React.Fragment>
      {(() => {
        switch (props.partner_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="partner_update_modal"
                onClick={() => {
                  setmodal(!modal);
                  editFormPartnerItem();
                }}
              ></i>
            );
          default:
            return (
              <Link
                onClick={() => {
                  createFormPartnerItem();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id=" partner_create_modal"
              >
                <i className="mdi mdi-plus mr-1"></i>
                {props.t("Create PartnerAuth ")}
              </Link>
            );
        }
      })()}

      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setmodal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-partner-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal(!modal);
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.createPartnerAuthError &&
              props.createPartnerAuthError &&
              msgEnable ? (
                <Alert color="danger">{props.createPartnerAuthError}</Alert>
              ) : null}
              {props.message && props.message && msgEnable ? (
                <Alert color="success">{props.message}</Alert>
              ) : null}

              {action_type === "edit" ? (
                <AvField name="id" type="hidden" value={partnerAuthData.id} />
              ) : (
                ""
              )}
              <AvField name="actionType" type="hidden" value={action_type} />

              <Row>
                <Col lg={12}>
                  <Row>
                    <Col lg={4}>
                      <Link
                        onClick={getApikey}
                        to="#"
                        className="popup-form btn btn-primary"
                        id="apiKeyGen"
                        style={{ width: "150px", marginLeft: "50px" }}
                      >
                        {props.t(" Api Key ")} <i className="bx bxs-key"></i>
                      </Link>
                    </Col>
                    <Col lg={8}>
                      <AvField
                        name="apiKey"
                        id={"apikey"}
                        className="form-control"
                        placeholder={props.t("Api Key")}
                        type="text"
                        required
                        value={
                          apiKey === undefined
                            ? partnerAuthData?.apiKey
                            : apiKey
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <Row>
                    <Col lg={4}>
                      <Link
                        onClick={getSecretKey}
                        to="#"
                        className="popup-form btn btn-primary"
                        id="secKeyGen"
                        style={{ width: "150px", marginLeft: "50px" }}
                      >
                        {props.t("Seceret Key")}
                        <i
                          className="bx bxs-key"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      </Link>
                    </Col>
                    <Col lg={8}>
                      <AvField
                        name="apiSecret"
                        id={"apiSecret"}
                        className="form-control"
                        placeholder={props.t("Api SecretKey")}
                        type="text"
                        required
                        // value={apiSecret}
                        value={
                          apiSecret === undefined
                            ? partnerAuthData?.apiSecret
                            : apiSecret
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-company">
                    <AvField
                      name="partnerId"
                      className="form-control"
                      placeholder={props.t("id")}
                      type="hidden"
                      required
                      value={partnerAuthData.id}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setmodal(!modal)}
                    >
                      {props.t("Cancel")}
                    </button>
                    <button
                      onClick={() => {
                        createUpdatePartnerAuthKeys(partnerAuthData?.id);
                      }}
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                    >
                      <span className="dr-cform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      {button_label}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createPartnerAuthError } = state.Partner;
  const { partner } = state.Partner;
  const { message } = state.Partner;
  return { createPartnerAuthError, partner, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createPartnerAuth,
    updatePartnerAuth,
    createPartnerFailedAuth,
    setSinglePartnerAuth,
  })(withNamespaces()(CreateUpdatePartnerAuth))
);
