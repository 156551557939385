import {
  CREATE_POST_MESSAGE,
  UPDATE_POST_MESSAGE,
  CREATE_POST_COMMENT,
  UPDATE_POST_COMMENT,
  GET_POST_MESSAGE,
  GET_POST_MESSAGE_LIST,
  SET_POST_MESSAGE_LIST,
  SET_POST_COMMENT_LIST,
  CREATE_POST_MESSAGE_SUCCESS,
  CREATE_POST_MESSAGE_FAILED,
  CREATE_POST_COMMENT_SUCCESS,
  CREATE_POST_COMMENT_FAILED,
  API_ERROR,
  SET_SINGLE_POST_MESSAGE,
  SET_POST_MESSAGE_LISTING,
  GET_SINGLE_POST_MESSAGE,
} from "./actionTypes";

const initialState = {
  createPostMessageError: null,
  createPostCommentError: null,
  message: null,
  pcMessage: null,
  loading: false,
  redirect: false,
  action_type: null,
};

const postMessage = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_POST_MESSAGE:
      state = {
        ...state,
        postMessage: action.payload,
        loading: true,
        redirect: true,
        createPostMessageError: null,
      };
      break;
    case UPDATE_POST_MESSAGE:
      state = {
        ...state,
        postMessage: action.payload,
        loading: true,
        redirect: true,
        createPostMessageError: null,
      };
      break;

    case CREATE_POST_COMMENT:
      state = {
        ...state,
        postComment: action.payload,
        loading: true,
        createPostCommentError: null,
      };
      break;
    case UPDATE_POST_COMMENT:
      state = {
        ...state,
        postComment: action.payload,
        loading: true,
        createPostCommentError: null,
      };
      break;

    case CREATE_POST_MESSAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        postMessage: action.payload,
        message: action.message,
        createPostMessageError: null,
        action_type: action.action_type,
      };
      break;
    case CREATE_POST_MESSAGE_FAILED:
      state = {
        ...state,
        postMessage: null,
        createPostMessageError: action.payload,
        loading: false,
      };
      break;
    case CREATE_POST_COMMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        postComment: action.payload,
        message: action.message,
        createPostCommentError: null,
        action_type: action.action_type,
      };
      break;
    case CREATE_POST_COMMENT_FAILED:
      state = {
        ...state,
        postComment: null,
        createPostCommentError: action.payload,
        loading: false,
      };
      break;
    case GET_POST_MESSAGE:
      state = {
        ...state,
        postMessage: action.payload.result,
        loading: true,
        createPostMessageError: null,
      };
      break;
    case GET_POST_MESSAGE_LIST:
      state = {
        ...state,
        postMessage: action.payload.result,
        loading: true,
        createPostMessageError: null,
      };
      break;

    case GET_SINGLE_POST_MESSAGE:
      state = {
        ...state,
        postMessage: action.payload.result,
        loading: true,
        redirect: false,
        createPostMessageError: null,
      };
      break;

    case SET_SINGLE_POST_MESSAGE:
      state = {
        ...state,
        postMessage: null,
        singlePostMessage: action.payload,
        loading: true,
        redirect: false,
        createPostMessageError: null,
        action_type: action.action_type,
      };
      break;
    case SET_POST_MESSAGE_LIST:
      state = {
        ...state,
        loading: true,
        redirect: false,
        postMessage: action.payload,
        message: action.message,
        createPostMessageError: null,
        action_type: action.action_type,
      };
      break;
    case SET_POST_COMMENT_LIST:
      state = {
        ...state,
        loading: true,
        redirect: false,
        postComment: action.payload,
        pcMessage: action.message,
        createPostCommentError: null,
        action_type: action.action_type,
      };
      break;
    case SET_POST_MESSAGE_LISTING:
      state = {
        ...state,
        loading: true,
        redirect: false,
        postMessage: null,
        postMessageListing: action.payload,
        message: action.message,
        createPostMessageError: null,
      };
      break;

    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        redirect: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default postMessage;
