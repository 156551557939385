import React, { useState, useEffect } from "react";

import { dctUpdateStatus } from "../../helpers/backend_helper";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Badge,
  Table,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

import { connect, useSelector, useDispatch } from "react-redux";

import {
  partnerActionSuccessful,
  setSinglePartner,
  setSinglePartnerAuth,
} from "../../store/actions";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

//Include Both Helper File with needed methods
import { loadingMessage } from "../../helpers/Commons";

import axios from "axios";

import CreateUpdatePartnerAuth from "../../components/PartnerAuth/CreateUpdatePartnerAuth";
import { Config } from "../../Config";

//Import Create and Update Group

const PartnerOverview = (props) => {
  const [partnerData, setPartnerData] = useState({
    gloading: true,
    gdata: null,
    gid: null,
    gmessage: null,
  });

  const [statusLoader, setStatusLoader] = useState(false);

  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const [partnerStatusMeta, setPartnerStatusMeta] = useState({
    rowIndex: "",
    groupId: "",
    groupStatus: 0,
  });

  const partner_action = useSelector((state) => state.Partner.action_type);

  const { authUserType, authPartnerId } = useSelector((state) => state.Login);

  const gdata = useSelector((state) => state.Partner.singlePartner);

  const togglePartnerStatus = async (partnerStatusMeta) => {
    if (!partnerStatusMeta) return;

    const { partnerId, partnerStatus, rowIndex } = partnerStatusMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Activation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    const loaderCls = ["bx-loader", "bx-spin", "font-size-18"];
    let toogleUserCls =
      partnerStatus === "active" ? "bx-user-check" : "bx-user-x";
    setStatusLoader(true);

    // Adding the bx-loader and removing the box-user-check class
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.add(...loaderCls);
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.remove(toogleUserCls);
    let gStatus = partnerStatus === "active" ? "inactive" : "active";
    const postData = {
      id: partnerId,
      status: gStatus,
    };
    const url = "partnerauth/" + partnerId;
    const response = await dctUpdateStatus(postData, url).then((data) => data);

    if (response.status === "active") {
      setdynamic_description("Activated successfully.");
      setdynamic_title("Activated");
    }
    if (response.status === "inactive") {
      setdynamic_description("Deactivated successfully.");
      setdynamic_title("Deactivated");
    }

    if (response.id) {
      seterror_dlg(false);
    } else {
      seterror_dlg(true);
      setsuccess_dlg(false);
    }

    gdata.partnerAuth[rowIndex].status = response.status;
    setPartnerData({
      gdata: gdata,
    });

    setStatusLoader(false);
    toogleUserCls = response.status === "active" ? "bx-check" : "bx-x";
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.remove(...loaderCls);
    document
      .querySelector("#status_" + partnerId)
      .children[0].classList.add(toogleUserCls);
  };

  const openPartnerEditModal = (data) => {
    document.querySelector("#partner_update_modal").click();
    //Push Single Group Data to store
    dispatch(setSinglePartnerAuth(data, "", ""));
  };

  const dispatch = useDispatch();

  const fetchSinglePartner = (goup_id) => {
    if (!goup_id) {
      return;
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = process.env.REACT_APP_API_PATH + "/partner/" + goup_id;

    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data;
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(setSinglePartner(response_data, message, null));
        setPartnerData({
          gloading: false,
          gdata: response_data,
          gid: goup_id,
          gmessage: message,
        });
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = "No record found.";
          }
        }
        setPartnerData({
          gloading: false,
          gdata: null,
          gid: null,
          gmessage: message,
        });
        dispatch(setSinglePartner(null, message));
      })
      .then(function () { });
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let goup_id = "";

    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    fetchSinglePartner(goup_id);
  }, []);

  const propsUpdatePartnerData = () => { };

  const { gloading, gid, gmessage } = partnerData;

  const backToPartnerListLink = () => {
    return authUserType === "partner" ? (
      ""
    ) : (
        <Link
          to="/partners"
          className="btn btn-info waves-effect waves-light mr-2 ml-2"
        >
          <i className="mdi mdi-arrow-left mr-1"></i> {props.t("Back to List")}
        </Link>
      );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                {gmessage ? (
                  <Alert color="danger" className="text-center">
                    {gmessage}
                  </Alert>
                ) : null}

                {gdata ? (
                  <CardBody>
                    <div className="group-details-section" data-id={gdata.id}>
                      <Row>
                        <Col lg="6">
                          <Media>
                            <div className="avatar-sm mr-4">
                              <span className="avatar-title font-size-20 rounded-circle">
                                {gdata.name?.charAt(0)}
                              </span>
                            </div>
                            <Media className="overflow-hidden" body>
                              <h5 className="text-truncate font-size-15">
                                {gdata.name}
                              </h5>
                              <p className="text-muted">
                                {props.t("Partner Status")}:
                                <Badge
                                  color="primary"
                                  className="font-size-12 ml-2"
                                >
                                  {gdata.status === "active"
                                    ? "Active"
                                    : "Inactive"}
                                </Badge>
                              </p>
                            </Media>
                          </Media>
                        </Col>

                        <Col lg="6">
                          <div className="text-right">
                            <CreateUpdatePartnerAuth
                              fetchSinglePartner={fetchSinglePartner}
                              updatePartnerDataFlag={propsUpdatePartnerData}
                              partner_action_type="edit"
                              partner_button_type="edit_icon"
                              gdata={gdata}
                              {...props}
                            />
                            <CreateUpdatePartnerAuth
                              fetchSinglePartner={fetchSinglePartner}
                              updatePartnerDataFlag={propsUpdatePartnerData}
                              partner_action_type="create"
                              partner_button_type="create_btn"
                              gdata={gdata}
                              {...props}
                            />
                            {backToPartnerListLink()}
                          </div>
                        </Col>
                      </Row>

                      <h5 className="font-size-15 mt-4">
                        {props.t("Partner Details")}:{" "}
                      </h5>

                      <div className="text-muted mt-4">
                        <Row>
                          <Col lg="6">
                            <p>
                              <i className="bx bx-building text-primary mr-1"></i>{" "}
                              <strong>{props.t("Partner Name")}: </strong>{" "}
                              {gdata.name}
                            </p>
                            <p>
                              <i className="bx bx-building text-primary mr-1"></i>{" "}
                              <strong>{props.t("Partner Email")}: </strong>{" "}
                              {gdata.userId?.email}
                            </p>
                            <p>
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <strong>{props.t("Created At")}: </strong>{" "}
                              {gdata.createdAt}
                            </p>

                            <p>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("Updated At")}:</strong>{" "}
                              {gdata.updatedAt}
                            </p>
                          </Col>

                          <Col lg="6">
                            <p>
                              <i className="bx bx-map text-primary mr-1"></i>{" "}
                              <strong>{props.t("Company Name")}: </strong>{" "}
                              {gdata.companyId?.companyName}
                            </p>
                            <p>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("Company Details")}: </strong>{" "}
                              {gdata.companyId?.companyDetails}
                            </p>
                            <p>
                              <i className="bx bx-user text-primary mr-1"></i>{" "}
                              <strong>{props.t("Company Email")}: </strong>{" "}
                              {gdata.companyId?.companyEmail}
                            </p>
                            <p>
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <strong>{props.t("Company Type")}: </strong>{" "}
                              {gdata.companyId?.companyType}
                            </p>
                            <p>
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <strong>{props.t("Company Phone")}: </strong>{" "}
                              {gdata.companyId?.companyPhone}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                ) : (
                    <CardBody>
                      {gmessage ? (
                        <div className="text-center">
                          {backToPartnerListLink()}
                        </div>
                      ) : (
                          <div className="text-center">
                            {loadingMessage("Loading...")}
                          </div>
                        )}
                    </CardBody>
                  )}
              </Card>
              <Card>
                {gmessage ? (
                  <Alert color="danger" className="text-center">
                    {gmessage}
                  </Alert>
                ) : null}

                {gdata ? (
                  <CardBody>
                    <div className="group-details-section" data-id={gdata.id}>
                      <h5 className="font-size-15 mt-4">
                        {props.t("Partner Auth Details")}:{" "}
                      </h5>

                      <Row>
                        <Col lg="12">
                          <div className="table-responsive">
                            <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}>
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">{props.t("Api Key")}</th>
                                  <th scope="col">{props.t("Secret Key")}</th>
                                  <th scope="col" style={{ width: "70px" }}>
                                    {props.t("Status")}
                                  </th>
                                  <th scope="col" style={{ width: "70px" }}>
                                    <center>{props.t("Action")}</center>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {gdata.partnerAuth &&
                                  gdata.partnerAuth.map((patnAuth, index) => (
                                    <tr key={index}>
                                      <td>{patnAuth.apiKey}</td>
                                      <td>{patnAuth.apiSecret}</td>
                                      <td>
                                        <Link
                                          to="#"
                                          className={
                                            patnAuth.status === "active"
                                              ? "badge badge-soft-primary font-size-11 m-1"
                                              : "badge badge-soft-danger font-size-11 m-1"
                                          }
                                        >
                                          {patnAuth.status === "active"
                                            ? "Active"
                                            : "Inactive"}
                                        </Link>
                                      </td>
                                      <td>
                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                          <li className="list-inline-item px-2">
                                            <Link
                                              to="#"
                                              id={"edit" + patnAuth.id}
                                              onClick={() => {
                                                openPartnerEditModal(patnAuth);
                                              }}
                                            >
                                              <i className="bx bx-edit-alt"></i>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={"edit" + patnAuth.id}
                                              >
                                                {props.t("Edit")}
                                              </UncontrolledTooltip>
                                            </Link>
                                          </li>

                                          <li className="list-inline-item px-2">
                                            <Link
                                              to="#"
                                              id={"status_" + patnAuth.id}
                                              data-status={patnAuth.status}
                                              data-id={patnAuth.id}
                                              onClick={() => {
                                                setconfirm_both(true);
                                                setPartnerStatusMeta({
                                                  rowIndex: index,
                                                  partnerId: patnAuth.id,
                                                  partnerStatus:
                                                    patnAuth.status,
                                                });
                                              }}
                                            >
                                              {patnAuth?.status === "active" ? (
                                                <i className="bx bx-check text-success"></i>
                                              ) : (
                                                  <i className="bx bx-x text-danger"></i>
                                                )}
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={"status_" + patnAuth.id}
                                              >
                                                {patnAuth.status
                                                  ? "Active"
                                                  : "Inactive"}
                                              </UncontrolledTooltip>
                                            </Link>
                                          </li>
                                        </ul>
                                        {confirm_both ? (
                                          <SweetAlert
                                            title={props.t("Are you sure?")}
                                            warning
                                            showCancel
                                            confirmBtnBsStyle="success"
                                            cancelBtnBsStyle="secondary"
                                            onConfirm={() => {
                                              togglePartnerStatus(
                                                partnerStatusMeta
                                              );
                                            }}
                                            onCancel={() => {
                                              setconfirm_both(false);
                                              setsuccess_dlg(false);
                                            }}
                                          >
                                            {props.t(
                                              `This process will change the Partner status!`
                                            )}
                                          </SweetAlert>
                                        ) : null}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                ) : (
                    ""
                  )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { partner } = state.Partner;
  //const { member } = state.Member;
  const { message } = state.Partner;
  return { partner, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    partnerActionSuccessful,
    setSinglePartner,
    setSinglePartnerAuth,
  })(withNamespaces()(PartnerOverview))
);
