import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect, Fragment, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Config } from "../../../Config";
import { withRouter, Link, useHistory } from "react-router-dom";
import {
  capitalizeWords,
  dctAlert,
  dctTimeStampToHumanDate,
} from "../../../helpers/Commons";
import { loadingMessage } from "../../../helpers/Commons";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import SweetAlert from "react-bootstrap-sweetalert";
const {
  Parser,
  transforms: { unwind },
} = require("json2csv");

const JoinHazeAdmin = () => {
  const history = useHistory();
  const { authDoctorId } = useSelector((state) => state.Login);
  const [joinHazeData, setJoinHazeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ApiErr, setApiErr] = useState(false);
  const [ApiErrMsg, setApiErrMsg] = useState("");
  const [fromFullDate, setFromFullDate] = useState("");
  const [toFullDate, setToFullDate] = useState("");
  const [searchFormDetails, setSearchFormDetails] = useState("");
  const [Reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success_Export, setsuccess_Export] = useState(false);

  const handleFromDateChange = (date) => {
    setFromFullDate(date);
  };
  const handleToDateChange = (date) => {
    setToFullDate(date);
  };

  const GetAllQuestionnaire = () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_PATH}/api/questionnaire`;
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    axios
      .get(url, options)
      .then((res) => {
        // console.log("response", res.data)

        setJoinHazeData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setApiErr(true);
        setApiErrMsg("Could not fetch details... Error: " + err);
        setLoading(false);
      });
  };
  function ResetSearch(e) {
    e.preventDefault();
    setSearchFormDetails("");
    GetAllQuestionnaire();
    setReset(false);
  }

  function HanddleSearch(e) {
    e.preventDefault();
    setLoading(true);
    if (!searchFormDetails) {
      setJoinHazeData([]);
      setReset(true);
      setLoading(false);
      return;
    }
    setIsLoading(true);

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PATH}/api/questionnaire/search/?q=${searchFormDetails}`,
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios(config)
      .then((res) => {
        if (res.data.length === 0) {
          setReset(true);
        }
        setIsLoading(false);
        setLoading(false);
        setJoinHazeData(res.data);
        setReset(true);
      })
      .catch((err) => {
        setApiErr(true);
        setApiErrMsg("Could not fetch details... Error: " + err);
        setLoading(false);
      });
  }

  function handleExport() {
    setFromFullDate("");
    setToFullDate("");
    if (!fromFullDate) {
      return;
    }
    if (!toFullDate) {
      return;
    }
    setApiErr(false);

    var config = {
      method: "get",
      url: `${
        process.env.REACT_APP_API_PATH
      }/api/questionnaire/export/?timezoneUTC=Asia/Calcutta&fromdate=${moment(
        fromFullDate
      ).format("MM-DD-YYYY")}&todate=${moment(toFullDate).format(
        "MM-DD-YYYY"
      )}`,
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setsuccess_Export(true);
    axios(config)
      .then((res) => {
        let data = res.data;
        const fields = [
          "formData.fullName.first",
          "formData.fullName.last",
          "formData.email",
          "formData.phoneNumber.full",
          "formData.hazeId",
          {
            value: (row, field) => row?.isApproved,
          },
          // "doctorFullName",
          {
            value: (row, field) => capitalizeWords(row.doctorFullName),
          },
          {
            value: (row, field) => moment(row.updatedAt).format("MM-DD-YYYY"),
          },
          {
            value: (row, field) =>
              moment(row.updatedAt).add(6, "M").format("MM-DD-YYYY"),
          },
        ];
        let json2csvParser = new Parser({ delimiter: "\t", fields });

        json2csvParser.opts.fields[0].label = "First Name";
        json2csvParser.opts.fields[1].label = "Last Name";
        json2csvParser.opts.fields[2].label = "Email";
        json2csvParser.opts.fields[3].label = "Phone Number ";
        json2csvParser.opts.fields[4].label = "Haze Id";
        json2csvParser.opts.fields[5].label = "Status";
        json2csvParser.opts.fields[6].label = "Prescriber";
        json2csvParser.opts.fields[7].label = "Issued Date";
        json2csvParser.opts.fields[8].label = "Expiry Date";

        const tsv = json2csvParser.parse(data);
        // console.log(tsv);
        const uid = uuidv4();
        const exportedFileName = uid + ".tsv";

        let blob = new Blob([tsv], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
        console.log("errr------->", err);
        setApiErr(true);
        setApiErrMsg("Could not fetch details... Error: " + err);
      });
  }

  useEffect(() => {
    GetAllQuestionnaire();
  }, []);

  return (
    <Fragment>
      <Container fluid style={{ marginTop: "5rem" }}>
        <Row className="mx-2">
          <Col>
            <Form
              inline
              onSubmit={HanddleSearch}
              id="member-search-form"
              className={`mb-2 ${Config.names.slugName}-search-form`}
            >
              <FormGroup className="mr-sm-2 mb-sm-0 mb-0">
                <Input
                  type="text"
                  name="joinHazeSearch"
                  style={{ minWidth: "250px" }}
                  placeholder={"Search..."}
                  value={searchFormDetails}
                  onChange={(e) => {
                    setSearchFormDetails(e.target.value);
                  }}
                />
              </FormGroup>
              <Button onClick={HanddleSearch}>
                {isLoading && (
                  <>
                    <span className="dr-cform-spinner">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    </span>
                  </>
                )}
                Search
              </Button>
              {Reset && (
                <span
                  className={`ml-2 ${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-reset-search`}
                  onClick={ResetSearch}
                >
                  <i className="bx bx-reset font-size-22 align-middle mr-2"></i>
                </span>
              )}
            </Form>
          </Col>

          <Col className="d-flex flex-md-row justify-content-md-end flex-sm-column flex-column justify-content-sm-between align-items-sm-center">
            <Col className="d-flex align-items-center" lg="4">
              <label
                htmlFor="dateFrom"
                className="mb-0 mr-2"
                style={{ width: "2rem" }}
              >
                From
              </label>
              <ReactDatePicker
                name="dateFrom"
                className="form-control"
                selected={fromFullDate}
                dateFormat="MM-dd-yyyy"
                onChange={handleFromDateChange}
                placeholderText="MM-DD-YYYY"
                showYearDropdown
                maxDate={new Date()}
              />
            </Col>
            <Col className="d-flex align-items-center" lg="4">
              <label
                htmlFor="dateTo"
                className="mb-0 mr-2"
                style={{ width: "2rem" }}
              >
                To
              </label>
              <ReactDatePicker
                name="dateTo"
                className="form-control"
                selected={toFullDate}
                dateFormat="MM-dd-yyyy"
                onChange={handleToDateChange}
                placeholderText="MM-DD-YYYY"
                showYearDropdown
                maxDate={new Date()}
              />
            </Col>
            <div className="d-flex align-items-center">
              <Link
                onClick={() => {
                  handleExport();
                }}
                to="#"
                className="popup-form btn btn-primary"
                id="group_create_modal"
              >
                <i className="mdi mdi-file-export-outline mr-1"></i> {"Export"}
              </Link>
            </div>
            {success_Export && (
              <SweetAlert
                success
                title="Csv file will be exported soon."
                onConfirm={() => {
                  setsuccess_Export(false);
                }}
              >
                {"Please Wait ..."}
              </SweetAlert>
            )}
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Card style={{ margin: "1rem" }}>
              <CardBody>
                {loading ? (
                  <div className={`${Config.names.slugName}-listing-loader`}>
                    {loadingMessage("Loading...")}
                  </div>
                ) : (
                  ""
                )}
                {ApiErr && (
                  <div
                    lg="12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "600",
                      fontSize: "medium",
                      padding: "0.2rem",
                      backgroundColor: "#eee",
                    }}
                  >
                    <div className="text-left mb-2 text-danger ">
                      {ApiErrMsg}
                    </div>
                  </div>
                )}
                <div className="table-responsive">
                  <Table
                    className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table mt-2 `}
                  >
                    <Thead className="thead-light">
                      <Tr>
                        <Th scope="col" style={{ width: "70px" }}>
                          #
                        </Th>
                        <Th scope="col" className="text-left">
                          {"First Name"}
                        </Th>
                        <Th scope="col" className="text-center">
                          {"Last Name"}
                        </Th>
                        <Th scope="col" className="text-left">
                          {"Email"}
                        </Th>
                        <Th scope="col" className="text-center">
                          {"Phone Number"}
                        </Th>
                        <Th scope="col" className="text-left">
                          {"Haze Id"}
                        </Th>
                        <Th scope="col" className="text-center">
                          {"Submission Date"}
                        </Th>
                        <Th
                          scope="col"
                          className="text-left"
                          style={{ width: "70px" }}
                        >
                          {"Status"}
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {joinHazeData?.map((data) => (
                        <Tr key={data.id} data-row-id={data.id}>
                          <Td className="text-center">
                            <div className="avatar-xs">
                              <Link
                                to={"/joinhaze-overview-patient/id/" + data.id}
                                className="text-dark"
                              >
                                <span className="avatar-title rounded-circle text-capitalize">
                                  {data.formData && data.formData.fullName
                                    ? data.formData.fullName.first.charAt(0)
                                    : "D"}
                                </span>
                              </Link>
                            </div>
                          </Td>
                          <Td className="text-left">
                            <Link
                              to={"/joinhaze-overview-patient/id/" + data.id}
                              className="text-dark"
                            >
                              <span className="text-dark">
                                {data.formData &&
                                  data.formData.fullName &&
                                  capitalizeWords(data.formData.fullName.first)}
                              </span>
                            </Link>
                          </Td>
                          <Td className="text-center">
                            <Link
                              to={"/joinhaze-overview-patient/id/" + data.id}
                              className="text-dark"
                            >
                              <span className="text-dark">
                                {data.formData &&
                                  data.formData.fullName &&
                                  capitalizeWords(data.formData.fullName.last)}
                              </span>
                            </Link>
                          </Td>
                          <Td className="text-left">
                            <Link
                              to={"/joinhaze-overview-patient/id/" + data.id}
                              className="text-dark"
                            >
                              <span className="text-dark">
                                {data.formData &&
                                  data.formData.email &&
                                  data.formData.email}
                              </span>
                            </Link>
                          </Td>
                          <Td className="text-center">
                            <span className="text-dark">
                              {data.formData &&
                                data.formData.phoneNumber &&
                                data.formData.phoneNumber.full}
                            </span>
                          </Td>
                          <Td className="text-left">
                            <span className="text-dark">
                              {data.formData &&
                                data.formData.hazeId &&
                                data.formData.hazeId}
                            </span>
                          </Td>
                          <Td className="text-center">
                            <span className="text-dark">
                              {data.formData && data.formData.submissionDate
                                ? moment(data.createdAt).format("MM-DD-YYYY")
                                : ""}
                            </span>
                          </Td>
                          <Td className="text-left">
                            {data.isApproved === "accepted" && (
                              <span
                                className={
                                  "badge badge-soft-primary font-size-11 m-1"
                                }
                              >
                                {capitalizeWords("accepted")}
                              </span>
                            )}
                            {data.isApproved === "pending" && (
                              <span
                                className={
                                  "badge badge-soft-warning font-size-11 m-1"
                                }
                              >
                                {capitalizeWords("pending")}
                              </span>
                            )}
                            {data.isApproved === "rejected" && (
                              <span
                                className={
                                  "badge badge-soft-danger font-size-11 m-1"
                                }
                              >
                                {capitalizeWords("rejected")}
                              </span>
                            )}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default JoinHazeAdmin;
