import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Badge,
  Alert,
} from "reactstrap";

import { connect, useSelector, useDispatch } from "react-redux";

import {
  doctorActionSuccessful,
  setSingleDoctor,
} from "../../../store/actions";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../../helpers/backend_helper";

//Include Both Helper File with needed methods
import { loadingMessage, dctDateFormat, renderPhysicanLincenseInfo, capitalizeWords } from "../../../helpers/Commons";

import axios from "axios";

//Import Create and Update Group
import CreateUpdateDoctor from "../../../components/Doctor/Admin/CreateUpdateDoctor";

const DoctorOverview = (props) => {
  const [doctorData, setDoctorData] = useState({
    mloading: true,
    mdata: null,
    mid: null,
    mmessage: null,
  });

  const dispatch = useDispatch();

  const fetchSingleDoctor = (goup_id) => {
    if (!goup_id) {
      return;
    }
    let bearer_token = localStorage.getItem("auth_token");

    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/doctor/${goup_id}?populate=userId`;

    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data;
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data.data ? "No record found." : null;
        dispatch(setSingleDoctor(response_data, message, null));
        setDoctorData({
          mloading: false,
          mdata: response_data,
          mid: goup_id,
          mmessage: message,
        });
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err?.response?.status === 400) {
          message = "No record found.";
        }
        setDoctorData({
          mloading: false,
          mdata: null,
          mid: null,
          mmessage: message,
        });
        dispatch(setSingleDoctor(null, message));
      })
      .then(function () {});
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let goup_id = 0;
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    fetchSingleDoctor(goup_id);
  }, []);

  const propsUpdateDoctorData = () => {};

  const { mmessage } = doctorData;
  // const doctor_action = useSelector((state) => state.Doctor.action_type);
  const mdata = useSelector((state) => state.Doctor.singleDoctor);

  const backToDoctorListLink = () => {
    return (
      <Link
        to="#"
        className="btn btn-info waves-effect waves-light mr-2"
        onClick={() => {
          props.history.goBack();
        }}
      >
        <i className="mdi mdi-arrow-left mr-1"></i> {props.t("Back to List")}
      </Link>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                {mmessage ? (
                  <Alert color="danger" className="text-center">
                    {mmessage}
                  </Alert>
                ) : null}

                {mdata ? (
                  <CardBody>
                    <div className="member-details-section" data-id={mdata.id}>
                      <Row>
                        <Col lg="8">
                          <Media>
                            <div className="avatar-sm mr-4">
                              <span className="avatar-title font-size-20 rounded-circle">
                                {mdata.userId.firstName
                                  ? mdata.userId.firstName.charAt(0)
                                  : ""}
                              </span>
                            </div>
                            <Media className="overflow-hidden" body>
                              <h5 className="text-truncate font-size-15">
                                {mdata.userId.firstName + ' ' + mdata.userId.lastName}
                              </h5>
                              <p className="text-muted">
                                {props.t("Doctor Status")}:
                                <Badge
                                  color="primary"
                                  className="font-size-12 ml-2"
                                >
                                  {mdata.status === "active"
                                    ? "Active"
                                    : "Inactive"}
                                </Badge>
                              </p>
                            </Media>
                          </Media>
                        </Col>

                        <Col lg="4">
                          <div className="text-right">
                            <CreateUpdateDoctor
                              updateDoctorDataFlag={propsUpdateDoctorData}
                              doctor_action_type="edit"
                              doctor_button_type="edit_btn"
                              mdata={mdata}
                              {...props}
                            />
                            {backToDoctorListLink()}
                          </div>
                        </Col>
                      </Row>

                      <h5 className="font-size-15 mt-4">
                        {props.t("Doctor Details")} :
                      </h5>

                      <div className="text-muted mt-4">
                        <Row>
                          <Col lg="6">
                            <p>
                              <i className="bx bxs-crown text-primary mr-1"></i>{" "}
                              <strong>{props.t("Qualification")}: </strong>{" "}
                              {mdata.qualification}
                            </p>
                            <p>
                              <i className="bx bxs-spa text-primary mr-1"></i>{" "}
                              <strong>
                                {props.t("Awards & Achivements")}:{" "}
                              </strong>{" "}
                              {mdata.awardsAndAchievements}
                            </p>
                            <div>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("Licensed States")}: </strong>{" "}
                                {
                                  mdata?.licenseInfo ? (
                                    <p dangerouslySetInnerHTML={{__html: renderPhysicanLincenseInfo(mdata.licenseInfo)}}></p>
                                  ) : null
                                }
                            </div>

                            <p>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("NPI Number")}: </strong>{" "}
                              {mdata.npi}
                            </p>

                            <p>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("Tax Id Number")}: </strong>{" "}
                              {mdata.taxIdNumber}
                            </p>

                            <p>
                              <i className="bx bxs-map text-primary mr-1"></i>{" "}
                              <strong>{props.t("Address")}: </strong>
                              {mdata.address +
                                ", " +
                                mdata.addressTwo +
                                ", " +
                                mdata.city +
                                ", " +
                                mdata.state}
                            </p>
                          </Col>

                          <Col lg="6">
                            <p>
                              <i className="bx bx-user text-primary mr-1"></i>{" "}
                              <strong>{props.t("Main Contact Name")} : </strong>{" "}
                              {capitalizeWords(mdata.userId.firstName +
                                " " +
                                mdata.userId.lastName)}
                            </p>
                            <p>
                              <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                              <strong>{props.t("Main Contact Email")}: </strong>{" "}
                              <a
                                href={"mailto:" + mdata.userId.email}
                                className="text-dark"
                              >
                                {mdata.userId.email}
                              </a>
                            </p>

                            <p>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("Main Contact Phone")}: </strong>{" "}
                              <a
                                href={"tel:" + mdata.primaryPhone}
                                className="text-dark"
                              >
                                {mdata.primaryPhone}
                              </a>
                            </p>
                            <p>
                              <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                              <strong>{props.t("Secondary Phone")}: </strong>{" "}
                              <a
                                href={"tel:" + mdata.secondaryPhone}
                                className="text-dark"
                              >
                                {mdata.secondaryPhone}
                              </a>
                            </p>
                            <p>
                              <i className="bx bx-volume text-primary mr-1"></i>{" "}
                              <strong>{props.t("Prefered Language")}: </strong>{" "}
                              {mdata.language}
                            </p>
                            <p>
                              <i className="bx bx-bell-plus text-primary mr-1"></i>{" "}
                              <strong>
                                {props.t("Notification Preferance")}:{" "}
                              </strong>{" "}
                              {mdata.notificationPreference?.email
                                ? "Email "
                                : ""}
                              {mdata.notificationPreference?.sms ? "SMS" : ""}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="12">
                            {mdata.groupDescription ? (
                              <div>
                                <p>
                                  <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                                  <strong>{props.t("Description")}: </strong>{" "}
                                </p>
                                <p>{mdata.groupDescription}</p>
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </div>

                      <Row className="task-dates">
                        <Col sm="6" xs="6">
                          <div className="mt-4">
                            <h5 className="font-size-14">
                              <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                              {props.t("Created Date")}
                            </h5>
                            <p className="text-muted mb-0">
                              {dctDateFormat(mdata.createdAt)}
                            </p>
                          </div>
                        </Col>

                        <Col sm="6" xs="6">
                          <div className="mt-4">
                            <h5 className="font-size-14">
                              <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                              {props.t("Updated Date")}
                            </h5>
                            <p className="text-muted mb-0">
                              {dctDateFormat(mdata.updatedAt)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                ) : (
                  <CardBody>
                    {mmessage ? (
                      <div className="text-center">
                        {backToDoctorListLink()}
                      </div>
                    ) : (
                      <div className="text-center">
                        {loadingMessage("Loading...")}
                      </div>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { doctor } = state.Doctor;
  const { message } = state.Doctor;
  return { doctor, message };
};

export default withRouter(
  connect(mapStatetoProps, { doctorActionSuccessful, setSingleDoctor })(
    withNamespaces()(DoctorOverview)
  )
);
