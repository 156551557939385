import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Badge,
  Alert,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Label,
} from "reactstrap";

import { connect, useSelector, useDispatch } from "react-redux";

import {
  memberActionSuccessful,
  setSingleMember,
  setSingleGroup,
} from "../../../store/actions";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import SweetAlert from "react-bootstrap-sweetalert";

//Include Both Helper File with needed methods
import {
  checkAjaxError,
  dctUpdateStatus,
} from "../../../helpers/backend_helper";

//Include Both Helper File with needed methods
import {
  loadingMessage,
  dctDateFormat,
  dctAlert,
  capitalizeWords,
  dctHumanReadableGlobalFormat
} from "../../../helpers/Commons";

import axios from "axios";

//Import Create and Update Group
import CreateUpdateMember from "../../../components/Member/Admin/CreateUpdateMember";
// import CustomCreateUpdateMember from "../../../components/Member/Admin/CustomCreateUpdateMember";
import CreateUpdateDependent from "../../../components/Dependent/Admin/CreateUpdateDependent";
import ChangePasswordModal from "../../../components/Member/Admin/ChangePasswordModal";

import { UncontrolledTooltip } from "reactstrap";

import moment from "moment";
import { Config } from "../../../Config";
import DependencyModal from "../../../components/Member/Admin/DependentModal";
import PersonalInformation from "../../../components/Member/Admin/PersonalInformation";
import MedicationRecord from "../../../components/Member/Admin/MedicationRecord";
import MedicalHistory from "../../../components/Member/Admin/MedicalHistory";
import MedicationAllergy from "../../../components/Member/Admin/MedicationAllergy";
import Documents from "../../../components/Member/Admin/Documents";
import MyConsultations from "../../../components/Member/Admin/MyConsultations";
import BookConsultation from "../../../components/Member/Admin/BookConsultation";

const MemberOverview = (props) => {
  const [menu, setMenu] = useState(false);
  const [modal, setmodal] = useState(false);
  const [dModal, setDModal] = useState(false);
  const [index, setIndex] = useState(null);
  const [statusLoader, setStatusLoader] = useState(false);
  const [confirm_both, setconfirm_both] = useState(false);
  const [confirm_both_email, setconfirm_both_email] = useState(false);
  const [confirm_both_resetPassword, setconfirm_both_resetPassword] =
    useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [mtmSweetAlert, setmtmSweetAlert] = useState(false);
  const [archiveSuccess, setArchiveSuccess] = useState(false);
  const [archiveError, setArchiveError] = useState(false);

  const [id, setId] = useState(null);
  const history = useHistory();

  // Get the current URL
  const url = window.location.href;

  // Split the URL by '/'
  const urlParts = url.split("/");

  // Find the index of the 'id' segment
  const idIndex = urlParts.indexOf("id");

  // Extract the 'id' value
  const memberId = urlParts[idIndex + 1];

  // Dependency Code
  const [memberData, setMemberData] = useState({
    mloading: true,
    mdata: null,
    mid: null,
    mmessage: null,
  });
  const [brandName, setBrandName] = useState("");
  const [medicalHistory, setMedicalHistory] = useState({
    conditionName: "",
    description: "",
    status: "",
  });
  const [groupData, setGroupData] = useState({
    mloading: true,
    mdata: null,
    mid: null,
    mmessage: null,
  });
  const [memberServices, setMemberServices] = useState([]);
  const { authUserType, authPartnerId, authUser } = useSelector(
    (state) => state.Login
  );
  const [_medicalHistoryList, _setMedicalHistoryList] = useState({
    data: [],
    loading: true,
  });
  let membersData = useSelector((state) => state.Member.singleMember);
  membersData = membersData ? membersData : [];
  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const [services, setServices] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [successServices, setSuccessServices] = useState(false);
  // Modal for opening Create Dependency modal
  const [dependentModal, setDependentModal] = useState(false);
  const mdata = useSelector((state) => state.Member.singleMember);
  //Filter the archive false dependent
  const filterDependents = mdata?.dependent?.filter((item) => {
    return item.isArchive == false;
  });
  const currentPath = window.location.pathname;
  const pathId = currentPath.split("/").pop();
  // Book consultations
  const [consultationModal, setConsultationModal] = useState(false);
  const [error, setError] = useState(false);

  const handleDependentModal = () => {
    if (filterDependents?.length == 7) {
      setError(true);
    } else {
      setDependentModal(!dependentModal);
    }
  };

  const openDependentEditModal = (data, index) => {
    setDModal(true);
    setIndex(data);
  };

  const fetchSingleMember = (goup_id) => {
    if (!goup_id) {
      return;
    }
    setId(goup_id);
    let bearer_token = localStorage.getItem("auth_token");

    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/member/${goup_id}?populate=userId,partnerId,dependent,companyId`;

    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data;
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(setSingleMember(response_data, message, null));
        setMemberData({
          mloading: false,
          mdata: response_data,
          mid: goup_id,
          mmessage: message,
        });
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = "No record found.";
          }
        }

        setMemberData({
          mloading: false,
          mdata: null,
          mid: null,
          mmessage: message,
        });
        dispatch(setSingleMember(null, message));
      })
      .then(function () {});
  };

  const toggleGroupStatus = async (memberId) => {
    if (!memberId) return;
    let bearer_token = localStorage.getItem("auth_token");
    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Activation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    setStatusLoader(true);

    let data = {
      id: memberId,
    };
    const URL = `${process.env.REACT_APP_API_PATH}/member/mtm-activation`;
    let message = "";
    let status = "";
    const response = await axios
      .post(URL, data, {
        headers: {
          Authorization: bearer_token,
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      })
      .then((response) => {})
      .catch((err) => {
        if (err.response.status) {
          // message = "Something Went Wrong.";
          message = err.response.data.data.message;
          status = err.response.status;
        }
      });

    if (status === 400 || status === 406) {
      setdynamic_description(message);
      setdynamic_title("Error");
      seterror_dlg(true);
      setsuccess_dlg(false);
    }
  };

  const fetchSingleGroup = (goup_id) => {
    if (!goup_id) {
      return;
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = process.env.REACT_APP_API_PATH + "/member/" + goup_id;

    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data.companyId;
        response_data = response_data ? response_data : null;

        // console.log("response -- ", response_data.brandId)
        if (response_data.brandId) {
          // console.log("if response -- ", response_data.brandId)
          fetchBrand(response_data.brandId);
        }

        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(setSingleGroup(response_data, message, null));
        setGroupData({
          gloading: false,
          gdata: response_data,

          //gid: goup_id,

          gmessage: message,
        });
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err?.response?.status === 400) {
          message = "No record found.";
        }
        setGroupData({
          gloading: false,
          gdata: null,
          gid: null,
          gmessage: message,
        });
        dispatch(setSingleGroup(null, message));
      })
      .then(function () {});
  };
  const [member, setMember] = useState("");
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let goup_id = "";
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    setMember(goup_id);
    fetchSingleMember(goup_id);
  }, []);

  useEffect(() => {
    let goup_id = "";
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    fetchSingleGroup(goup_id);
  }, []);

  const fetchBrand = (brand_id) => {
    if (!brand_id) {
      return;
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = process.env.REACT_APP_API_PATH + "/brand/" + brand_id;

    axios
      .get(url, headers)
      .then(function (response) {
        if (response.data.name) {
          // console.log("res brand -- ", response.data.name)
          setBrandName(response.data.name);
        }
      })
      .catch(function (err) {
        console.log("error occur to find brand name -- ", err);
      });
  };

  const propsUpdateMemberData = () => {};

  const { mloading, mid, mmessage } = memberData;
  const member_action = useSelector((state) => state.Member.action_type);
  const { gloading, gid, gmessage } = groupData;
  const group_action = useSelector((state) => state.Group.action_type);
  const gdata = useSelector((state) => state.Group.singleGroup);

  //redirect to update dependent page

  const handleRedirect = (id, memberId) => {
    history.push(`/member-overview/id/${memberId}/dependent-overview/${id}`);
  };

  const backToMemberListLink = () => {
    return (
      <Link
        to="/members"
        className="btn btn-info waves-effect waves-light mr-2"
      >
        <i className="mdi mdi-arrow-left mr-1"></i> {props.t("Back to List")}
      </Link>
    );
  };
  const mtmActivationNow = () => {
    return (
      <Button
        onClick={() => {
          setconfirm_both(true);
        }}
        className="btn btn-success waves-effect waves-light mr-2"
      >
        {props.t("Activate MTM")}
      </Button>
    );
  };

  const resetPassword = () => {
    return (
      <Button
        onClick={() => {
          setconfirm_both_resetPassword(true);
        }}
        className="btn btn-warning waves-effect waves-light mr-2"
      >
        {props.t("Reset Password")}
      </Button>
    );
  };

  const welcomeEmailButton = () => {
    return (
      <Button
        onClick={() => {
          setconfirm_both_email(true);
        }}
        className="btn btn-primary waves-effect waves-light mr-2"
      >
        {props.t("Send Welcome Email")}
      </Button>
    );
  };
  // Book Consultation Button
  const bookConsultationButton = () => {
    return (
      <Button
        onClick={() => {
          setConsultationModal(true);
        }}
        className="btn bg-primary waves-effect waves-light mr-2"
      >
        {props.t("Book Consultation")}
      </Button>
    );
  };
  const values = { isArchive: true };
  //Archive dependent
  const dependentArchive = async (dependentId) => {
    const url = `dependent/${dependentId}`;
    var result = await dctUpdateStatus(values, url);
    // dispatch(setSingleMember(values, null));
    if (typeof result === "string") {
      setArchiveError(true);
      setTimeout(() => {
        setArchiveError(false);
      }, 3000);
    } else {
      setArchiveSuccess(true);
      setTimeout(() => {
        setArchiveSuccess(false);
      }, 3000);
      fetchSingleMember(member);
    }
  };

  const dependentItemsBody = (dependentsObj) => {
    if (!dependentsObj) {
      return;
    }
    return dependentsObj.length > 0 ? (
      dependentsObj.map((dependent, index) => {
        return (
          <Tr key={index} data-row-id={dependent?.id}>
            <Td>
              <div className="avatar-xs cursor-pointer">
                <Link
                  to={`/member-overview/id/${memberId}/dependent-overview/${dependent?.id}`}
                  className="text-dark"
                >
                  <span className="avatar-title rounded-circle text-capitalize">
                    {dependent?.firstName.charAt(0)}
                  </span>
                </Link>
              </div>
            </Td>
            <Td>
              <h5
                className="text-truncate font-size-14 cursor-pointer"
                onClick={() => handleRedirect(dependent?.id, memberId)}
              >
                {capitalizeWords(dependent?.firstName)}
              </h5>
            </Td>
            <Td>
              <h5
                className="text-truncate font-size-14 cursor-pointer"
                onClick={() => handleRedirect(dependent?.id, memberId)}
              >
                {capitalizeWords(dependent?.lastName)}
              </h5>
            </Td>

            <Td
              className="cursor-pointer"
              onClick={() => handleRedirect(dependent?.id, memberId)}
            >
              {dependent?.email}
            </Td>
            <Td
              className="cursor-pointer"
              onClick={() => handleRedirect(dependent?.id, memberId)}
            >
              {dependent?.address}
            </Td>
            <Td
              className="cursor-pointer"
              onClick={() => handleRedirect(dependent?.id, memberId)}
            >
              {dependent?.primaryPhone}
            </Td>
            <Td>
              <Link
                to="#"
                className={
                  dependent.status === "active"
                    ? "badge badge-soft-primary font-size-11 m-1"
                    : "badge badge-soft-danger font-size-11 m-1"
                }
              >
                {dependent?.status === "active" ? "Active" : "Inactive"}
              </Link>
            </Td>
            <Td className="text-center">
              <Link
                className="text-dark"
                to="#"
                id={"sync_" + dependent.id}
                // data-status={prescription.status}
                // data-id={prescription.id}
                onClick={() => {
                  dependentArchive(dependent.id);
                }}
              >
                <i className="bx bx-archive-in font-size-18"></i>
                <UncontrolledTooltip
                  placement="top"
                  target={"sync_" + dependent.id}
                >
                  {props.t("Archive")}
                </UncontrolledTooltip>
              </Link>
            </Td>
            <Td>
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"edit" + dependent?.id}
                    onClick={() => {
                      openDependentEditModal(dependent, index);
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"edit" + dependent?.id}
                    >
                      {props.t(" Edit")}
                    </UncontrolledTooltip>
                  </Link>
                </li>
                {!dependent?.dependentMemberId &&
                moment().diff(dependent?.dob, "years") >= 18 ? (
                  <li className="list-inline-item px-2">
                    <Link
                      to="#"
                      color="primary"
                      id={"upgrade-" + dependent?.id}
                      onClick={() => {
                        registerAsMember(dependent);
                      }}
                    >
                      <i className="bx bx-user-plus"></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={"upgrade-" + dependent?.id}
                      >
                        {props.t(" Register as member")}
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="7" className="d-flex justify-center text-danger">
          {" "}
          User has no dependent
        </Td>
      </Tr>
    );
  };

  const sendWelcomeEmail = (memberId) => {
    if (!memberId || memberId == "") {
      return;
    }
    setconfirm_both_email(false);
    setsuccess_dlg(true);
    setdynamic_title("Sending Email...");
    setdynamic_description("Please wait while its processeing!");

    setStatusLoader(true);
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/member/${memberId}/welcome-email`;
    axios
      .post(url, {}, options)
      .then((response) => {
        setdynamic_description("Welcome email sent.");
        setdynamic_title("Success");
        setsuccess_dlg(true);
        setSuccessMsg("Welcome email sent.");
      })
      .catch((err) => {
        setdynamic_description("Welcome email could not be sent.");
        setdynamic_title("Error");
        seterror_dlg(true);
        setsuccess_dlg(false);
        // setErrorMsg("Welcome email could not be sent.");
      });
  };

  const sendResetPasswordEmail = (memberId) => {
    if (!memberId || memberId == "") {
      return;
    }
    setconfirm_both_resetPassword(false);
    setsuccess_dlg(true);
    setdynamic_title("Sending Email...");
    setdynamic_description("Please wait while its processeing!");

    setStatusLoader(true);
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/member/${memberId}/reset-password-screen`;
    axios
      .post(url, options)
      .then((response) => {
        setdynamic_description("Reset Password email sent.");
        setdynamic_title("Success");
        setsuccess_dlg(true);
        setSuccessMsg("Reset Password email sent.");
      })
      .catch((err) => {
        setdynamic_description("Reset Password email could not be sent.");
        setdynamic_title("Error");
        seterror_dlg(true);
        setsuccess_dlg(false);
      });
  };

  const registerAsMember = (dependent) => {
    let dependentId = dependent?.id;
    if (!dependentId || dependentId == "") {
      return;
    }
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/dependent/${dependentId}/registration`;
    axios
      .post(url, options)
      .then((response) => {
        dependent.dependentMemberId = response.data?.data?.id;
        setSuccessMsg("Dependent registered as a member.");
      })
      .catch((err) => {
        let message = err.response?.data?.message
          ? err.response.data.message
          : "Dependent could not be registered as a member.";
        setErrorMsg(message);
      });
  };

  const AllMemberServices = (goup_id) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PATH}/member/${goup_id}/service`,
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios(config)
      .then((res) => {
        // console.log("All services ==>", res.data);
        setMemberServices(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function updateServices(servicelist) {
    let goup_id = "";
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }

    var data = {
      memberId: goup_id,
      serviceListId: servicelist,
    };
    // console.log("data", data);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PATH}/member/update-service`,
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        // console.log("update res", res.data);
        setServices(false);
        setSuccessServices(true);
        AllMemberServices(goup_id);
        // setMemberServices(res.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    let goup_id = "";
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      goup_id = split_url ? split_url[3] : "";
    }
    AllMemberServices(goup_id);
  }, []);

  const WelcomeModal = () => {
    setSuccessMsg(null);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                {mmessage ? (
                  <Alert color="danger" className="text-center">
                    {mmessage}
                  </Alert>
                ) : null}
                {errorMsg ? dctAlert(3, errorMsg) : ""}
                {successMsg ? dctAlert(1, successMsg, false, WelcomeModal) : ""}
                {mdata ? (
                  <>
                    <CardBody>
                      <div
                        className="member-details-section"
                        data-id={mdata?.id}
                      >
                        <Row>
                          <Col lg="3">
                            <Media>
                              <div className="avatar-sm mr-4">
                                <span className="avatar-title font-size-20 rounded-circle">
                                  {mdata?.userId?.firstName
                                    ? mdata?.userId?.firstName.charAt(0)
                                    : "D"}
                                </span>
                              </div>
                              <Media className="overflow-hidden" body>
                                <h5 className="font-size-15">
                                  {mdata.userId?.firstName +
                                    " " +
                                    mdata?.userId?.lastName}
                                </h5>
                                <p className="text-muted">
                                  {props.t("Member Status")}:
                                  <Badge
                                    color="primary"
                                    className="font-size-12 ml-2"
                                  >
                                    {mdata?.status === "active"
                                      ? "Active"
                                      : "Inactive"}
                                  </Badge>
                                </p>
                              </Media>
                            </Media>
                          </Col>

                          <Col lg="9">
                            <div className="text-right">
                              <Dropdown
                                isOpen={menu}
                                toggle={() => setMenu(!menu)}
                                className="float-right ml-2"
                              >
                                <DropdownToggle tag="i" className="text-muted">
                                  <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      // setmodal(!modal);
                                      return document
                                        .querySelector("#member_update_modal")
                                        ?.click();
                                    }}
                                  >
                                    {/* <i className="bx bx-edit-alt mr-2"></i>{" "}
                                  <span>{props.t("Edit")}</span> */}
                                    <i className="mdi mdi-pencil mr-1 text-muted"></i>
                                    <span className="text-muted">
                                      {props.t("Edit")}
                                    </span>
                                    {/* <CreateUpdateMember
                                    updateMemberDataFlag={propsUpdateMemberData}
                                    member_action_type="edit"
                                    member_button_type="borderless_edit_btn"
                                    mdata={mdata}
                                    // button={false}
                                    {...props}
                                  /> */}
                                  </DropdownItem>
                                  {authUserType == "admin" ? (
                                    <DropdownItem
                                      href="#"
                                      onClick={() => {
                                        // setmodal(!modal);
                                        return document
                                          .querySelector(
                                            "#member-password-modal"
                                          )
                                          ?.click();
                                      }}
                                    >
                                      {/* <i className="bx bx-edit-alt mr-2"></i>{" "}
                                  <span>{props.t("Edit")}</span> */}
                                      <i className="mdi mdi-lock mr-1 text-muted"></i>
                                      <span className="text-muted">
                                        {props.t("Change Password")}
                                      </span>
                                      {/* <CreateUpdateMember
                                    updateMemberDataFlag={propsUpdateMemberData}
                                    member_action_type="edit"
                                    member_button_type="borderless_edit_btn"
                                    mdata={mdata}
                                    // button={false}
                                    {...props}
                                  /> */}
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {/* <DropdownItem
                                    href="#"
                                    onClick={() => {
                                    }}
                                  >
                                    <span
                                      id="welcome-email-modal"
                                      onClick={() => {
                                        sendWelcomeEmail(mdata?.id);
                                      }}
                                    >
                                      <i className="bx bx-mail-send mr-1 text-muted"></i>
                                      <span className="text-muted">
                                        {props.t("Send Welcome Email")}
                                      </span>
                                    </span>
                                  </DropdownItem> */}
                                </DropdownMenu>
                              </Dropdown>
                              {bookConsultationButton()}
                              {welcomeEmailButton()}
                              {resetPassword()}
                              {mdata?.companyId?.fulfilment == "MTM" &&
                                mtmActivationNow()}
                              {backToMemberListLink()}
                            </div>
                          </Col>
                        </Row>
                        <div style={{ display: "none" }}>
                          <CreateUpdateMember
                            updateMemberDataFlag={propsUpdateMemberData}
                            member_action_type="edit"
                            member_button_type="edit_btn"
                            mdata={mdata}
                            // button={false}
                            modal={modal}
                            setModal={setmodal}
                            updateData={fetchSingleMember}
                            param={mdata.id}
                            {...props}
                          />
                          <ChangePasswordModal change={setPasswordChange} />
                        </div>

                        <h5 className="font-size-15 mt-4">
                          {props.t("Member Details")} :
                        </h5>

                        <div className="text-muted mt-4">
                          <Row>
                            <Col lg="6">
                              <p>
                                <i className="bx bx-user text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Main Contact Name")} :{" "}
                                </strong>{" "}
                                <b>
                                  {capitalizeWords(
                                    mdata?.userId?.firstName +
                                      " " +
                                      mdata?.userId?.lastName
                                  )}
                                </b>
                              </p>
                              <p>
                                <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Main Contact Email")}:{" "}
                                </strong>{" "}
                                {mdata?.userId?.email}
                              </p>

                              <p>
                                <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                <strong>
                                  {props.t("Main Contact Phone")}:{" "}
                                </strong>{" "}
                                {mdata?.primaryPhone}
                              </p>
                              <p>
                                <i className="bx bx-calendar-check text-primary mr-1"></i>{" "}
                                <strong>{props.t("Date Of Birth")}: </strong>{" "}
                                {moment(mdata?.dob,"MM-DD-YYYY").format("MM-DD-YYYY")}
                              </p>
                              <p>
                                <i className="bx bx-user text-primary mr-1"></i>{" "}
                                <strong>{props.t("Member Id")}: </strong>{" "}
                                {mdata?.primaryExternalId}
                              </p>

                              {mdata?.companyId?.fulfilment === "MTM" && (
                                <p>
                                  <i className="bx bx-user text-primary mr-1"></i>{" "}
                                  <strong>{props.t("MTM Group Code")}: </strong>{" "}
                                  {mdata?.mtmGroupCode
                                    ? mdata.mtmGroupCode
                                    : ""}
                                </p>
                              )}
                            </Col>
                            <Col lg="6">
                              <p>
                                <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                <strong>{props.t("Company Name")}: </strong>{" "}
                                {mdata?.companyId
                                  ? mdata?.companyId.companyName
                                  : ""}
                              </p>
                              <p>
                                <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                <strong>{props.t("Partner Name")}: </strong>{" "}
                                {mdata?.partnerId ? mdata?.partnerId.name : ""}
                              </p>

                              <p>
                                <i className="bx bx-user text-primary mr-1"></i>{" "}
                                <strong>{props.t("Member Type")}: </strong>{" "}
                                {mdata?.memberType}
                              </p>
                              <p>
                                <i className="bx bx-user text-primary mr-1"></i>{" "}
                                <strong>{props.t("Gender")}: </strong>{" "}
                                {mdata?.gender}
                              </p>

                              <p>
                                <i className="bx bxs-map text-primary mr-1"></i>{" "}
                                <strong>{props.t("Address")}: </strong>
                                {/* {mdata?.address+
                                  ", " +
                                  mdata?.addressTwo && mdata?.addressTwo +
                                  ", " +
                                  mdata?.city +
                                  ", " +
                                  mdata?.state +
                                  ", "} */}
                                {mdata?.address}
                                {mdata?.addressTwo && `, ${mdata?.addressTwo}`}
                                {`,${mdata?.city}`}, {mdata?.state},
                                {mdata?.postalCode}
                              </p>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12">
                              {mdata?.groupDescription ? (
                                <div>
                                  <p>
                                    <i className="bx bx-bulb text-primary mr-1"></i>{" "}
                                    <strong>{props.t("Description")}: </strong>{" "}
                                  </p>
                                  <p>{mdata?.groupDescription}</p>
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        </div>
                        <Row className="task-dates">
                          <Col sm="6" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14">
                                <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                                {props.t("Created Date")}
                              </h5>
                              <p className="text-muted mb-0">
                                {dctDateFormat(mdata?.createdAt)}
                              </p>
                            </div>
                          </Col>

                          <Col sm="6" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14">
                                <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                                {props.t("Updated Date")}
                              </h5>
                              <p className="text-muted mb-0">
                                {dctDateFormat(mdata?.updatedAt)}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-5">
                          <Col lg={6}>
                            <Label className="mb-4 font-size-16">
                              <strong>{props.t("Services")}</strong>
                              {/* {isServiceLoader
                                ? loadingMessage(props.t("Updating Service...."))
                                : null}  */}
                            </Label>
                            <Row>
                              {services && (
                                <SweetAlert
                                  title={props.t("Are you sure?")}
                                  warning
                                  showCancel
                                  confirmBtnBsStyle="success"
                                  cancelBtnBsStyle="secondary"
                                  onConfirm={() => {
                                    updateServices(serviceId);
                                  }}
                                  onCancel={() => {
                                    setServices(false);
                                    // setsuccess_dlg(false);
                                  }}
                                >
                                  {props.t(
                                    "This process will be changed this service status."
                                  )}
                                </SweetAlert>
                              )}

                              {successServices && (
                                <SweetAlert
                                  success
                                  title="service status changed successfully."
                                  onConfirm={() => {
                                    setSuccessServices(false);
                                  }}
                                ></SweetAlert>
                              )}

                              <Col lg={7}>
                                <div className="">
                                  <ul className="list-unstyled">
                                    {memberServices.map(
                                      (servicelist, index) => (
                                        <li
                                          key={index}
                                          className="card-header mb-2"
                                        >
                                          <h5 className="font-size-14">
                                            <Label>{servicelist.name}</Label>
                                            {servicelist.isDefault && (
                                              <span className="badge badge-primary font-size-11 m-1">
                                                {"Default"}{" "}
                                              </span>
                                            )}
                                            <Link
                                              onClick={() => {
                                                setServiceId(servicelist.id);
                                                setServices(true);
                                              }}
                                              type="checkbox"
                                              id={servicelist?.id}
                                              value={servicelist?.id}
                                              checked={
                                                servicelist.status === "active"
                                              }
                                              to="#"
                                              className={
                                                servicelist.status === "active"
                                                  ? "badge badge-soft-primary font-size-11 m-1"
                                                  : "badge badge-soft-danger font-size-11 m-1"
                                              }
                                            >
                                              {servicelist.status === "active"
                                                ? "Active"
                                                : "Inactive"}
                                            </Link>
                                          </h5>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <Label className=" font-size-16">
                              <strong>{props.t("Brand Affiliation")}</strong>
                            </Label>
                            <Row>
                              <Col sm="12" xs="12">
                                <div>
                                  <p className="text-primary font-size-14 mt-2">
                                    {brandName && capitalizeWords(brandName)}
                                  </p>
                                  <p>
                                    <strong>{props.t("Login URL")} : </strong>
                                    <a
                                      target="_blank"
                                      className="ml-2"
                                      style={{ fontStyle: "italic" }}
                                      href={`https://${
                                        brandName === "doctegrity"
                                          ? "app.doctegrity.com"
                                          : brandName === "hbaehealth"
                                          ? "app.hbaehealth.com"
                                          : brandName === "telemedicine"
                                          ? "app.telemedicine.com"
                                          : brandName === "telaq"
                                          ? "labq.telaq.com"
                                          : brandName === "canelo"
                                          ? "app.doctegrity.com"
                                          : ""
                                      }`}
                                    >
                                      {brandName === "doctegrity"
                                        ? "app.doctegrity.com"
                                        : brandName === "hbaehealth"
                                        ? "app.hbaehealth.com"
                                        : brandName === "telemedicine"
                                        ? "app.telemedicine.com"
                                        : brandName === "telaq"
                                        ? "labq.telaq.com"
                                        : brandName === "canelo"
                                        ? "app.doctegrity.com"
                                        : ""}
                                    </a>
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                    <CardBody>
                      {/* {mdata?.dependent && mdata?.dependent.length > 0 ? ( */}
                      <div className="d-flex justify-content-between pb-3">
                        <h4 className="mb-2 mt-2 font-size-18">
                          {props.t("Dependent List")}
                        </h4>
                        {/* Create Dependent Button for opening modal */}
                        <button
                          id="member_create_modal"
                          className="popup-form btn btn-primary"
                          // onClick={() => {
                          //   setDependentModal(!dependentModal);
                          // }}
                          onClick={() => {
                            handleDependentModal();
                          }}
                        >
                          <i className="mdi mdi-plus mr-1"></i>{" "}
                          {props.t("Create Dependent")}
                        </button>
                      </div>

                      <div className="table-responsive">
                        {archiveError && (
                          <Alert color="danger" style={{ color: "black" }}>
                            Error in archived dependent
                          </Alert>
                        )}
                        {archiveSuccess && (
                          <Alert color="success" style={{ color: "black" }}>
                            Dependent archived successfully{" "}
                          </Alert>
                        )}
                        {filterDependents?.length > 0 ? (
                          <Table
                            className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}
                          >
                            <Thead className="thead-light">
                              <Tr>
                                <Th scope="col" style={{ width: "70px" }}>
                                  #
                                </Th>
                                <Th scope="col">{props.t("First Name")}</Th>
                                <Th scope="col">{props.t("Last Name")}</Th>
                                <Th scope="col">{props.t("Email")}</Th>
                                <Th scope="col">{props.t("Address")}</Th>
                                <Th scope="col">{props.t("Phone")}</Th>
                                <Th scope="col" style={{ width: "70px" }}>
                                  {props.t("Status")}
                                </Th>
                                <Th scope="col" style={{ width: "70px" }}>
                                  {props.t("Archive")}
                                </Th>
                                <Th scope="col" style={{ width: "70px" }}>
                                  {props.t("Action")}
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {dependentItemsBody(filterDependents)}
                            </Tbody>
                          </Table>
                        ) : (
                          <span className="d-flex justify-content-center text-danger">
                            User has no dependent
                          </span>
                        )}
                      </div>
                    </CardBody>
                  </>
                ) : (
                  <CardBody>
                    {mmessage ? (
                      <div className="text-center">
                        {backToMemberListLink()}
                      </div>
                    ) : (
                      <div className="text-center">
                        {loadingMessage("Loading...")}
                      </div>
                    )}
                  </CardBody>
                )}
                <CardBody>
                  <h4 className="mb-4 mt-2 font-size-18">
                    {props.t("Medication History")}
                  </h4>
                  {/* Component of medical history */}
                  <MedicalHistory pathId={pathId} />
                </CardBody>
                <CardBody>
                  <h4 className="mb-4 mt-2 font-size-18">
                    {props.t("Medication Allergy")}
                  </h4>
                  <MedicationAllergy pathId={pathId} />
                </CardBody>
                <CardBody>
                  <h4 className="mb-4 mt-2 font-size-18">
                    {props.t("Patient Information")}
                  </h4>
                  <PersonalInformation pathId={pathId} />
                </CardBody>
                <CardBody>
                  <h4 className="mt-2 font-size-18">
                    {props.t("Medication Records")}
                  </h4>
                </CardBody>
                <MedicationRecord pathId={pathId} />
                <CardBody>
                  <h4 className="mt-2 mb-4 font-size-18">
                    {props.t("Uploaded Documents")}
                  </h4>{" "}
                  <Documents pathId={pathId} />
                </CardBody>
                <CardBody>
                  <h4 className="mt-2 mb-4 font-size-18">
                    {props.t("Consultations History")}
                  </h4>
                  <MyConsultations pathId={pathId} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {dModal && (
          <CreateUpdateDependent
            fetchSingleMember={fetchSingleMember}
            // updateMemberDataFlag={propsUpdateMemberData}
            dependent_action_type="edit"
            dependent_button_type="edit_icon"
            modal={dModal}
            mdata={mdata?.dependent}
            index={index}
            setModal={setDModal}
            {...props}
          />
        )}
        {/* Book Consultation  */}
        {consultationModal && (
          <BookConsultation
            modal={consultationModal}
            setConsultationModal={setConsultationModal}
            memberData={memberData}
            filterDependentsData={filterDependents}
            {...props}
          />
        )}
        {confirm_both && (
          <SweetAlert
            title={props.t("Are you sure?")}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="secondary"
            onConfirm={() => {
              toggleGroupStatus(mdata?.id);
            }}
            onCancel={() => {
              setconfirm_both(false);
              setsuccess_dlg(false);
            }}
          >
            {props.t(`This process will change the Member status!`)}
            {dynamic_description}
          </SweetAlert>
        )}
        {confirm_both_email && (
          <SweetAlert
            title={props.t("Are you sure?")}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="secondary"
            onConfirm={() => {
              sendWelcomeEmail(mdata?.id);
            }}
            onCancel={() => {
              setconfirm_both_email(false);
              setsuccess_dlg(false);
            }}
          >
            {props.t(`This process will send the welcome email to Member!`)}
            {dynamic_description}
          </SweetAlert>
        )}
        {confirm_both_resetPassword && (
          <SweetAlert
            title={props.t("Are you sure?")}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="secondary"
            onConfirm={() => {
              sendResetPasswordEmail(mdata?.id);
            }}
            onCancel={() => {
              setconfirm_both_resetPassword(false);
              setsuccess_dlg(false);
            }}
          >
            {props.t(
              `This process will send the reset password mail to Member!`
            )}
            {dynamic_description}
          </SweetAlert>
        )}
        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              setsuccess_dlg(false);
              setdynamic_description("");
              setdynamic_title("");
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
        {error_dlg ? (
          <SweetAlert
            danger
            title={dynamic_title}
            onConfirm={() => {
              seterror_dlg(false);
              setdynamic_description("");
              setdynamic_title("");
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
        {passwordChange ? (
          <SweetAlert
            success
            title={"Password Change"}
            onConfirm={() => {
              setPasswordChange(false);
            }}
          >
            Password Changed Successfully.
          </SweetAlert>
        ) : null}
      </div>
      {/* Create Dependency Modal */}
      {/* {mdata?.dependent?.length < 7 ? ( */}
      <DependencyModal
        {...props}
        setDependentModal={setDependentModal}
        dependentModal={dependentModal}
      />
      {error && (
        <SweetAlert
          danger
          title="Oops! You've hit the max limit of 7 dependents. Archive one before adding another"
          onConfirm={() => {
            setError(false);
            // setDependentModal(!dependencyModal);
          }}
        ></SweetAlert>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { member } = state.Member;
  const { message } = state.Member;
  return { member, message };
};

export default withRouter(
  connect(mapStatetoProps, { memberActionSuccessful, setSingleMember })(
    withNamespaces()(MemberOverview)
  )
);
