import React, { useState, useEffect } from "react";

//Style from Reactstrap
import { Row, Col, Form, Input, InputGroup } from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../../helpers/backend_helper";

import { useLocation } from "react-router-dom";

import {
  setConsultationListing,
  setMySingleConsult,
} from "../../../store/actions";

import ConsultationList from "../../MyConsultations/ConsultationList";

import ConsultationDetails from "../../MyConsultations/ConsultationDetails";

import axios from "axios";
import { Config } from "../../../Config";

const MyConsultations = (props) => {
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const pathId = props.pathId;

  const [gdata, setGData] = useState({
    loading: true,
    consultations: null,
    message: null,
  });
  const { authMemberId } = useSelector((state) => state.Login);
  const ConsultationListing = useSelector(
    (state) => state.Member.consultListing
  );

  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    consultSearchParam: "",
    type: "filter",
    reset: false,
  });

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        consultSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: false,
      });
      return fetchConultationData(searchParam, selectedPage);
    }
    fetchConultationData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        consultSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      return fetchConultationData(searchParam, selectedPage);
    }
    fetchConultationData(searchParam, selectedPage);
  };

  const fetchConultationData = (param, currentPage = 1) => {
    let searchParam = "";
    if (param) {
      searchParam = param.consultSearchParam
        ? "&q=" + param.consultSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    let url =
      `${process.env.REACT_APP_API_PATH}/consultation` +
      `?sort=createdAt%20DESC&populate=false&page=${currentPage}&limit=${postsPerPage}` +
      searchParam;
    if (pathId !== "undefined") {
      url =
        `${process.env.REACT_APP_API_PATH}/member/${pathId}/consultations` +
        `?sort=createdAt%20DESC&populate=false&page=${currentPage}&limit=${postsPerPage}` +
        searchParam;
    }
    // else if(props.dependentId !== null && props.dependentId )
    axios
      .get(url, headers)
      .then(function (response) {
        const data = response.data;
        let message = "";
        message = data.data.length > 0 ? "" : "No record found.";
        dispatch(setConsultationListing(data.data, message));
        setGData({
          loading: false,
          consultations: data.data,
          message: message,
        });

        setTotalPosts(Math.ceil(data.total / postsPerPage));
        setCurrentPage(currentPage - 1);
        if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
          document
            .getElementsByClassName("dr-csearch-spinner")[0]
            .classList.add("d-none");
        }
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = err.response.data.cause.details;
          }
        }
        if (document.getElementsByClassName("dr-csearch-spinner")[0]) {
          document
            .getElementsByClassName("dr-csearch-spinner")[0]
            .classList.add("d-none");
        }
        setGData({
          loading: false,
          consultations: null,
          message: message,
        });
        setTotalPosts(0);
        setCurrentPage(0);
        dispatch(setConsultationListing(null, message));
      })
      .then(function () {});
  };

  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let csearch = urlParams.get("csearch");
    let searchParam = "";
    if (csearch) {
      searchParam = {
        consultSearchParam: csearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      fetchConultationData(searchParam);
    } else {
      // fetchConultationData(searchParam);
    }
  }, []);

  // Apply Company Search
  function handleSearch(e) {
    e.preventDefault();
    // if (document.getElementsByClassName("dr-csearch-spinner")) {
    //   document
    //     .getElementsByClassName("dr-csearch-spinner")[0]
    //     .classList.add("d-none");
    // }
    fetchConultationData(searchFormDetails);
    setSearchFormDetails({
      reset: true,
    });
    let param = searchFormDetails.consultSearchParam
      ? searchFormDetails.consultSearchParam
      : "";
      if(props.patientType == "dependent"){
        props.history.push(`/member-overview/id/${pathId}/dependent-overview/${props.activePatientId}?&csearch=${param}`)
      }else{
        props.history.push(`/member-overview/id/${pathId}?&csearch=${param}`);
      }
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    fetchConultationData();
    setSearchFormDetails({
      reset: false,
    });
    document.getElementById("consult-search-form").reset();
    // props.history.push(`/member-overview/id/${pathId}`);
    if(props.patientType == "dependent"){
      props.history.push(`/member-overview/id/${pathId}/dependent-overview/${props.activePatientId}`)
    }else{
      props.history.push(`/member-overview/id/${pathId}`);
    }
  }

  const { loading, consultations, message } = gdata;

  const stopNativeLoader = () => {
    window.ReactNativeWebView.postMessage("STOP_NATIVE_LOADER");
  };

  return (
    <React.Fragment>
      {window.ReactNativeWebView ? stopNativeLoader() : ""}
      {/* <div className="page-content"> */}
      {/* <Container fluid> */}
      <Row className="mb-2">
        <Col lg="3">
          <Form
            inline
            onSubmit={handleSearch}
            id="consult-search-form"
            className={`mb-2 ${Config.names.slugName}-search-form`}
          >
            <InputGroup className="mr-sm-2 mb-sm-0 mb-0 my__consultations__input">
              <span className="my__consultations__wrapper">
                <Input
                  type="text"
                  name="consultSearchParam"
                  placeholder={props.t("Search...")}
                  onChange={(e) => {
                    let { value } = e.target;
                    setSearchFormDetails((prevState) => ({
                      ...prevState,
                      consultSearchParam: value,
                    }));
                  }}
                />
                <i
                  onClick={handleSearch}
                  className="bx bx-search font-size-18 my__consultations__search"
                ></i>
                {searchFormDetails.reset ? (
                  <i
                    onClick={resetSearch}
                    className="dripicons-cross font-size-18 my__consultations__reset"
                  ></i>
                ) : (
                  ""
                )}
              </span>
            </InputGroup>
          </Form>
        </Col>
        <Col lg="9">
          <div className="text-right mb-2">
            <ConsultationDetails {...props} />
          </div>
        </Col>
      </Row>



      
      <Row>
        <Col lg="12">
          <ConsultationList
            {...props}
            consultListMessage={message}
            total={totalPosts}
            getDataOnEnter={getDataOnEnter}
            getUserData={getUserData}
            currentPage={currentPage}
            perPage={postsPerPage}
            ajaxLoader={loading}
            dependentId={props.activePatientId}
            fetchConultationData={fetchConultationData}
          ></ConsultationList>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { consultListing, mySingleConsult, message } = state.Member;
  return { consultListing, message, mySingleConsult };
};

export default withRouter(
  connect(mapStatetoProps, { setConsultationListing, setMySingleConsult })(
    withNamespaces()(MyConsultations)
  )
);
