import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";

import { Form, Input, Label, FormGroup, Alert, Col, Row } from "reactstrap";
import MapLeaflet from "./LeafLetMap";
import { Table, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { AvForm, AvField } from "availity-reactstrap-validation";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";

import {
  getMemberAndDependents,
  setPharmacyList,
  setSinglePharmacy,
  setPharmacyMarkers,
  updateConsultStepwise,
  getSingleConsultation,
  getDraftConsultation,
} from "../../store/actions";

import {
  dctGetMemberAndDependPastData,
  loadingMessage,
  dctClsAddRemove,
  dctAlert,
} from "../../helpers/Commons";
import {
  dctGetAsyncData,
  dctGetPharmacyList,
  dctAsyncPutData,
} from "../../helpers/backend_helper";
import ConsultFormFooter from "../../pages/Members/Consultation/consultFormFooter";
import { Config } from "../../Config";

function SearchPharmacy(props) {
  const [changePharmacy, setChangePharmacy] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [pharmacySearchFields, setPharmacySearchFields] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  });
  const { authMemberId } = useSelector((state) => state.Login);
  const [pharmacyState, setPharmacyState] = useState({
    name: "",
    abbreviation: "",
  });
  const [pharmacyData, setPharmacyData] = useState(null);
  const [savedPharmacyData, setSavedPharmacyData] = useState(null);
  const [pharmacyClassName, setPharmacyClassName] = useState("");


  let ajaxLoading = true;

  const dispatch = useDispatch();

  let memberObj = [];
  let memberPharma = [];
  let preferredPharma = [];
  memberObj = useSelector((state) => state.Member);
  const { singlePharmacy } = useSelector((state) => state.Pharmacy)
  const {
    loading,
    memberAndDependents,
    singleMember,
    singleConsult,
    draftConsult,
  } = memberObj;
  memberPharma = memberAndDependents
    ? memberAndDependents.dosespotPharmacies
    : [];

  if (memberPharma && memberPharma.length > 0) {
    preferredPharma = memberPharma[memberPharma.length - 1];
  }
  useEffect(() => {
    let pharmacyId =
      preferredPharma && preferredPharma.pharmacyId
        ? preferredPharma.pharmacyId
        : memberPharma?.pharmacyId;

    getPharmacyById(pharmacyId);
    if (props.pageTitle) {
      props.pageTitle("Verify Pharmacy Details");
    }
  }, [preferredPharma]);

  useEffect(() => {
    if (props?.history.location.pathname.includes("/member/consultation/phone/step")) {
      setPharmacyClassName(`${Config.names.slugName}-consult-scrollbar-hidden`);
    }
    if (props?.location.pathname.includes("/member/my-account/pharmacy")) {
      setPharmacyClassName(`${Config.names.slugName}-myaccount-scrollbar-hidden`);
    }
  }, [])

  const getPharmacyById = async (pharmacyId) => {
    if (memberAndDependents?.companyId?.fulfilment == 'MTM') {
      let pharmaciesList = memberAndDependents?.mtmPharmacies;

      pharmaciesList = pharmaciesList?.length > 0 ? pharmaciesList[pharmaciesList?.length - 1] : [];
      if (singlePharmacy) {
        memberAndDependents.mtmPharmacies = [{
          isPreferred: true,
          pharmacy: {
            Address1: singlePharmacy.Address1,
            Address2: singlePharmacy.Address2,
            City: singlePharmacy.City,
            Latitude: singlePharmacy.Latitude,
            Longitude: singlePharmacy.Longitude,
            NCPDPID: singlePharmacy.NCPDPID,
            PharmacyId: singlePharmacy.PharmacyId,
            PrimaryFax: singlePharmacy.PrimaryFax,
            PrimaryPhone: singlePharmacy.PrimaryPhone,
            State: singlePharmacy.State,
            StoreName: singlePharmacy.StoreName,
            ZipCode: singlePharmacy.ZipCode,
            nonSureScriptForeign_id: singlePharmacy.nonSureScriptForeign_id,
            state_id: singlePharmacy.state_id,
            twentyFourHour: singlePharmacy.twentyFourHour,
          }
        }]
        setSavedPharmacyData(singlePharmacy);
      }
      else {
        setSavedPharmacyData(pharmaciesList?.pharmacy);
      }
    } else {
      if (!pharmacyId) return;
      let url = `pharmacy/${pharmacyId}`;
      const response = await dctGetPharmacyList(url, "").then((data) => data);
      if (response.success === true) {
        dispatch(setSinglePharmacy(response.data, ""));
        setSavedPharmacyData(response.data);
      }
    }

  };

  // Load the fetched State data for the select dropdown
  const loadStateOptions = (inputValue, callback) => {
    let query = inputValue ? `&q=${inputValue}` : "";
    var apiUrl = `${process.env.REACT_APP_API_PATH}/state?country=us${query}`;
    const axiosArgs = {
      URL: apiUrl,
    };
    dctGetAsyncData(axiosArgs).then((results) => callback(results));
    return;
  };
  const stateListOptions = debounce(loadStateOptions, 250);

  // handlePharmacySearch
  const handlePharmacySearch = async (event, values) => {
    var errormsg = props.t(`Please enter value to search.`);
    values.state = pharmacyState.abbreviation
      ? pharmacyState.abbreviation
      : "";
    if (
      values.name == "" &&
      values.address == "" &&
      values.city == "" &&
      values.state == "" &&
      values.zip == ""
    ) {
      setErrorMsg(errormsg);
      setSuccessMsg(false);
      return false;
    }
    let url = "pharmacy";
    dctClsAddRemove("dr-search-spinner", "d-none", "remove");
    const response = await dctGetPharmacyList(url, values).then((data) => data);

    if (response.data && response.data.length > 0) {
      let responseData = response.data;
      setPharmacyData(responseData); //state/prop
      let msg = props.t(`Pharmacy data fetched successfully.`);
      setSuccessMsg(msg); //state/prop
      setErrorMsg(false);
      dispatch(setPharmacyList(responseData, msg)); //store
      let latLangs = [];
      for (var i in responseData) {
        latLangs.push({
          lat: responseData[i].Latitude,
          lng: responseData[i].Longitude,
          name: responseData[i].StoreName,
          key: responseData[i].PharmacyId,
          address: responseData[i].Address1,
          city: responseData[i].City,
          state: responseData[i].State,
          zipCode: responseData[i].ZipCode,
          primaryPhone: responseData[i].PrimaryPhone,
          primaryFax: responseData[i].PrimaryFax,
          pharmacyId: responseData[i].PharmacyId,
        });
      }
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      dispatch(setPharmacyMarkers(latLangs));
    } else {
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      setErrorMsg(props.t(`No data found.`));
      setSuccessMsg(false);
    }
  };

  const handleStateChange = (e) => {
    if (!e) return;
    setPharmacyState((prevState) => ({
      ...prevState,
      name: e ? e.name : "",
      abbreviation: e ? e.abbreviation : "",
    }));
  };

  // For the Consultation page
  const selectConsultationPharmacy = async (pharmacy) => {
    setSuccessMsg(
      props.t(
        `New Pharmacy has been selected. Please continue the Consultation steps.`
      )
    );
    setErrorMsg(false);
    dctClsAddRemove(`${Config.names.slugName}-block-loader`, "d-none", "remove");
    setSavedPharmacyData(pharmacy);
    setTimeout(() => {
      dctClsAddRemove(`${Config.names.slugName}-block-loader`, "d-none", "add");
    }, 1000);
  };

  //Save Pharamcy ID to member Collection
  const updatePharmacy = async (pharmacy) => {
    let _memberId = authMemberId ? authMemberId : "";
    let memberId = memberAndDependents.id ? memberAndDependents.id : _memberId;

    if (!pharmacy.PharmacyId) {
      setErrorMsg(props.t(`Please select Pharmacy.`));
      setSuccessMsg(false);
      return;
    }

    const postData = {
      pharmacyObj: pharmacy,
      pharmacyId: pharmacy.PharmacyId,
      isPreferred: true,
    };
    dctClsAddRemove(`${Config.names.slugName}-block-loader`, "d-none", "remove");
    const url = `member/${memberId}/pharmacy`;
    //const response = await dctAsyncPutData(postData, url).then((data) => data);
    dctAsyncPutData(postData, url).then(function (result) {
      if (result.success === true) {
        let msg = result.message;
        setSuccessMsg(msg);
        setErrorMsg(false);
        setSavedPharmacyData(pharmacy);
        dispatch(setSinglePharmacy(pharmacy), "")
        dctClsAddRemove(`${Config.names.slugName}-block-loader`, "d-none", "add");
        setChangePharmacy(false);
      } else {
        setErrorMsg(props.t(`Something went wrong.`));
        setSuccessMsg(false);
        dctClsAddRemove(`${Config.names.slugName}-block-loader`, "d-none", "add");
      }
    });
  };


  const renderPharmacyList = (pharmacyData) => {
    return pharmacyData
      ? pharmacyData.map((pharmacy, index) => {
        return (
          <Tr key={index} data-row-id={pharmacy.PharmacyId} className="mb-2">
            <Td style={{ width: "50px" }} className={`${Config.names.slugName}-desktop`}>
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">
                  {pharmacy.StoreName ? pharmacy.StoreName.charAt(0) : "P"}
                </span>
              </div>
            </Td>
            <Td>
              <div className="info">
                <h5 className="font-size-15 mb-1 dct__pharmacy_text_ellipses">{pharmacy.StoreName}</h5>
                <p className="mb-1">
                  PH: {pharmacy.PrimaryPhone}, Fax: {pharmacy.PrimaryFax}
                </p>
                <div>
                  <span>{pharmacy.Address1} </span>
                </div>
                <div>
                  <span>{pharmacy.Address2}</span>{" "}
                </div>
                <p className="mb-2">
                  <span>{pharmacy.City}</span>
                  <span>, {pharmacy.State}</span>-{" "}
                  <span>{pharmacy.ZipCode}</span>
                </p>
              </div>
            </Td>
            <Td>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light mr-2 mt-4"
                  onClick={() => {
                    if (props._pharmacyComponentType === "consultation") {
                      selectConsultationPharmacy(pharmacy); // For the Consultation page
                      updatePharmacy(pharmacy);
                    } else {
                      updatePharmacy(pharmacy);
                    }
                  }}
                >
                  {props.t("Use Pharmacy")}
                </button>
              </div>
            </Td>
          </Tr>
        );
      })
      : null;
  };

  const handleConsultationPharmacySave = (event, values) => {
    var errormsg = props.t(`Please select pharmacy.`);
    if (savedPharmacyData?.PharmacyId) {
      values.pharmacy_pharmacyId = savedPharmacyData.PharmacyId;
    }
    if (!values.pharmacy_pharmacyId) {
      setErrorMsg(errormsg);
      setSuccessMsg(false);
      return false;
    }
    dctClsAddRemove("dr-form-spinner", "d-none", "remove");
    props.updateConsultStepwise(values, props.history, 7);
  };

  const handleConsultationPharmacySaveForNonUS = (event, values) => {
    dctClsAddRemove("dr-form-spinner", "d-none", "remove");
    props.updateConsultStepwise(values, props.history, 7);
  };

  return (
    <Fragment>
      {!props._isDoseSpotPharmacy ? (
        <div>
          <Alert color="primary">
            <p className="text-muted font-weight-13 mb-0">
              {props.t(
                `This Pharmacy module is currently not available for Non US country.`
              )}
            </p>
          </Alert>

          {
            // For the Consultation page
            props._pharmacyComponentType === "consultation" ? (
              <div>
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleConsultationPharmacySaveForNonUS(e, v);
                  }}
                >
                  <AvField name="step" type="hidden" value="7" />
                  <AvField
                    name="id"
                    type="hidden"
                    value={singleConsult ? singleConsult.id : ""}
                  />
                  <AvField name="stepType" type="hidden" value="pharmacy" />
                  <Row className="mt-3">
                    <Col lg={12}>
                      <ConsultFormFooter />
                    </Col>
                  </Row>
                </AvForm>
              </div>
            ) : (
              ""
            )
          }
        </div>
      ) : (
        // US country section
        <Fragment>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleConsultationPharmacySave(e, v);
            }}
          >
            <div>
              {
                // For the Consultation page
                props._pharmacyComponentType === "consultation" ? (
                  <div>
                    <p className="text-muted font-weight-13">
                      {props.t(`In the event a doctor determines that a prescription is needed we can issue this prescription at a pharmacy of your choice.`)}
                    </p>
                    <p className="text-muted font-weight-13 mt-4">
                      {props.t(`If the pharmacy below is not the correct pharmacy for this consult, click the "Change Pharmacy" button to find the correct pharmacy.`)}
                    </p>
                  </div>
                ) : (
                  ""
                )
              }
              {savedPharmacyData ? (
                <Alert color="primary">
                  <h4 className="font-size-18">
                    {props.t("Preferred Pharmacy")}
                  </h4>
                  <p className="text-muted font-weight-13 ">
                    <b>{savedPharmacyData.StoreName}</b>
                    <br />
                    <span className="text-justify">
                      {savedPharmacyData.Address1}
                      <br />
                      <span>
                        {savedPharmacyData.City}, {savedPharmacyData.State}
                      </span><br />
                      <abbr title="Zip"> {props.t("Z")}: </abbr>
                      {savedPharmacyData.ZipCode} <br />
                      <abbr title="Phone">{props.t("P")}: </abbr>
                      {savedPharmacyData.PrimaryPhone} <br />
                      <abbr title="Fax">{props.t("F")}: </abbr>{" "}
                      {savedPharmacyData.PrimaryFax}
                    </span>
                  </p>

                  <button
                    type="button"
                    className="btn btn-primary mt-2"
                    onClick={() => {
                      setChangePharmacy(true);
                      // if (window.ReactNativeWebView)
                      //   window.ReactNativeWebView.postMessage("SCROLL_TO_TOP");
                    }}
                  >
                    {props.t("Change Pharmacy")}
                  </button>
                </Alert>
              ) :
                <>
                  {memberObj?.memberAndDependents?.dosespotPharmacies === null || memberObj?.memberAndDependents?.dosespotPharmacies === "" ? (
                    <p>
                      <button
                        type="button"
                        className="btn btn-primary mt-2"
                        onClick={() => {
                          setChangePharmacy(true);
                        }}
                      >
                        {props.t("Add Pharmacy")}
                      </button>
                    </p>) :
                    ajaxLoading ? (
                      <div className={`${Config.names.slugName}-container-loader-`}>
                        {loadingMessage("Loading...")}
                      </div>
                    ) : (
                      ""
                    )
                  }
                </>
              }
              {
                // For the Consultation page
                props._pharmacyComponentType === "consultation" ? (
                  <div>
                    <AvField name="step" type="hidden" value="7" />
                    <AvField
                      name="id"
                      type="hidden"
                      value={singleConsult ? singleConsult.id : ""}
                    />
                    {singleConsult && singleConsult.pharmacy_pharmacyId ? (
                      <AvField
                        name="pharmacy_pharmacyId"
                        type="hidden"
                        value={
                          singleConsult ? singleConsult.pharmacy_pharmacyId : ""
                        }
                      />
                    ) : (
                      <AvField
                        name="pharmacy_pharmacyId"
                        type="hidden"
                        value={
                          savedPharmacyData && savedPharmacyData.pharmacyId
                            ? savedPharmacyData.pharmacyId
                            : ""
                        }
                      />
                    )}
                    <AvField name="stepType" type="hidden" value="pharmacy" />
                  </div>
                ) : (
                  ""
                )
              }
            </div>

            <Row>
              <Col lg={12}>
                {props._pharmacyComponentType === "consultation" ? (
                  <ConsultFormFooter />
                ) : null}
              </Col>
            </Row>
          </AvForm>

          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handlePharmacySearch(e, v);
            }}
          >
            {errorMsg ? dctAlert(3, errorMsg) : ""}

            {successMsg ? dctAlert(1, successMsg) : ""}

            <div className="row">
              {changePharmacy ? (
                <Col lg={12} className="mt-5">
                  <Row className="d-flex">
                    <Col md={5}>
                      {/* <Row>
                          <Col lg={12}>
                            <div className="form-group">
                              <AvField
                                name="name"
                                label={props.t("Name")}
                                className="form-control"
                                placeholder={props.t("Name")}
                                type="text"
                                value={
                                  savedPharmacyData
                                    ? savedPharmacyData.StoreName
                                    : ""
                                }
                              />
                            </div>
                          </Col>
                        </Row> */}
                      {/* <Row>
                          <Col lg={12}>
                            <div className="form-group">
                              <AvField
                                name="address"
                                label={props.t("Address")}
                                className="form-control"
                                placeholder={props.t("Address")}
                                type="text"
                                value={
                                  savedPharmacyData
                                    ? savedPharmacyData.Address1
                                    : ""
                                }
                              />
                            </div>
                          </Col>
                        </Row> */}
                      <Row>
                        <Col lg={6}>
                          <div className="form-group">
                            <AvField
                              name="city"
                              label={props.t("City")}
                              className="form-control"
                              placeholder={props.t("City")}
                              type="text"
                              value={
                                savedPharmacyData ? savedPharmacyData.City : ""
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className={`form-group ${Config.names.slugName}-select-box`}>
                            <label htmlFor="state">{props.t("State")}</label>
                            <AsyncSelect
                              isClearable={true}
                              placeholder={props.t("Select State")}
                              name="state"
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e.abbreviation}
                              className={`${Config.names.slugName}-custom-select-box`}
                              cacheOptions
                              defaultOptions
                              loadOptions={stateListOptions}
                              defaultValue={
                                savedPharmacyData && savedPharmacyData.State
                                  ? savedPharmacyData.State
                                  : ""
                              }
                              onChange={handleStateChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="form-group">
                            <AvField
                              name="zip"
                              label={props.t("Zipcode")}
                              className="form-control"
                              placeholder={props.t("Zipcode")}
                              value={savedPharmacyData?.ZipCode}
                              type="text"
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light mr-2 mt-4 mb-3"
                              onClick={() => { }}
                            >
                              <span className="dr-search-spinner d-none">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                              </span>{" "}
                              {props.t("Search")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <div className="pharmacy-footer">
                        <p>
                          {props.t(
                            `The map only shows pharmacies that accept e-prescriptions.`
                          )}
                        </p>
                        <p className="d-none">
                          {props.t(`Click here to use a pharmacy that does not accept e-prescriptions.`)}
                        </p>
                      </div>
                    </Col>
                    <Col md={7} className="mb-2">
                      <MapLeaflet
                        oldPharmacy={savedPharmacyData}
                        updatePharmacy={updatePharmacy}
                      />
                    </Col>
                  </Row>
                </Col>
              ) : (
                ""
              )}
            </div>
          </AvForm>

          {changePharmacy ? (
            <Row>
              <Col lg={12}>
                <div className="mt-1 mb-3 dr-search-spinner d-none">
                  {loadingMessage("Pharmacy data fetching...")}
                </div>
                <div className="mt-1 mb-3 ${Config.names.slugName}-block-loader d-none">
                  {loadingMessage("Pharmacy is saving...")}
                </div>
                <div
                  className={`table-responsive mt-3 ${pharmacyClassName}`}
                  style={{ maxHeight: "400px" }}
                >
                  <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table ${Config.names.slugName}-table-no-header`}>
                    <Tbody>{renderPharmacyList(pharmacyData)}</Tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
const mapStatetoProps = (state) => {
  const {
    memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  } = state.Member;
  const { pharmacy, singlePharmacy, message, markers } = state.Pharmacy;
  return {
    memberAndDependents,
    pharmacy,
    singlePharmacy,
    markers,
    message,
    draftConsult,
    consultation,
    singleConsult,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    updateConsultStepwise,
    getSingleConsultation,
    getDraftConsultation,
    getMemberAndDependents,
  })(withNamespaces()(SearchPharmacy))
);
