import React from 'react'
import { useLocation, Redirect, useHistory, Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Media,
    Badge,
    Alert,
    Button
} from "reactstrap";
import { loadingMessage, dctAlert, renderPhysicanLincenseInfo, capitalizeWords, dctHumanReadableGlobalFormat } from '../../helpers/Commons';
import { Config } from '../../Config';
import SweetAlert from 'react-bootstrap-sweetalert'
import moment from 'moment'


const JoinHazePatientOverview = () => {

    let history = useHistory();
    // const { authDoctorId } = useSelector((state) => state.Login);

    const [errorMsg, setErrorMsg] = useState(false);
    const [patientData, setPatientData] = useState({})
    console.log("patient Data overViewPage >>", patientData)
    const [patientAddress, setPatientAddress] = useState("")
    const [loading, setLoading] = useState(false)
    const [pdfDownload, setPdfDownload] = useState('')
    const [loadingComment, setLoadingComment] = useState(false)
    const [erroe_feedback, setError_Feedback] = useState(false);
    const [feedBackError, setFeedBackError] = useState('')

    const location = useLocation()
    const patientId = location.pathname.split('/')[3]

    const GetPatientData = () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_PATH}/api/questionnaire/${patientId}`
        const options = {
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
        };
        axios.get(url, options).then((res) => {
            // console.log("response", res.data)
            setPatientData(res.data)
            if (res.data.formData.address) {
                let addressArr = Object.values(res.data.formData.address)
                let final = []
                addressArr.forEach((val, i) => {
                    if (val) {
                        if (i === addressArr.length - 1) {
                            final.push(val)
                            setPatientAddress(final.join(' '))
                        }
                        else
                            final.push(val + ',')
                    }
                })
            }
            setLoading(false)
        }).catch((err) => {
            setErrorMsg(
                "Could not fetch details... Error: " + err?.response?.data?.message
            );
            setLoading(false)
        })
    }

    function DownloadPrescription() {
        console.log("DownloadPrescription ==== ", patientId);
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_PATH}/api/questionnaire/regenerate/${patientId}?action=download`,
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            }
        };
        axios(config).then((res) => {
            console.log("Response >>> ", res.data);
            setPdfDownload(res.data.url)
        })
            .catch((err) => {
                console.log("Error :");
            });
    }

    function HandleRelease() {
        // console.log("HandleRelese --- ")
        setLoadingComment(true)

        const data = {
            doctorId: patientData.doctorId.id,
            isApproved: "pending",
        }

        var config = {
            method: 'put',
            url: `${process.env.REACT_APP_API_PATH}/api/questionnaire/${patientId}`,
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
            data: data
        };
        axios(config).then((feedback) => {
            // setConfirm_feedback(false);
            setLoadingComment(false);
            history.push("/joinhaze");
            // setsuccess_feedBack(true);
        }).catch((err) => { setFeedBackError(err); setLoadingComment(false); setError_Feedback(true); });
    }

    useEffect(() => {
        DownloadPrescription();
        GetPatientData();
    }, [])

    const backToDoctorListLink = () => {
        return (
            <Link
                to="/joinhaze"
                className="btn btn-info waves-effect waves-light mr-2"
            >
                <i className="mdi mdi-arrow-left mr-1"></i> {"Back to List"}
            </Link>
        );
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            {loading && (
                                <div className={`${Config.names.slugName}-listing-loader`}>
                                    {loadingMessage("Loading...")}
                                </div>
                            )}
                            {loadingComment && (
                                <div className={`${Config.names.slugName}-listing-loader`}>
                                    {loadingMessage("Please Wait...")}
                                </div>
                            )}
                            <Card>
                                {patientData && (
                                    <CardBody>

                                        <div className="member-details-section" data-id={patientData.id}>
                                            <Row>
                                                <Col lg="6">
                                                    <Media>
                                                        <div className="avatar-sm mr-4">
                                                            <span className="avatar-title font-size-20 rounded-circle">
                                                                {patientData.formData && patientData.formData.fullName &&
                                                                    patientData.formData.fullName.first.charAt(0)
                                                                }
                                                            </span>
                                                        </div>
                                                        <Media className="overflow-hidden" body>
                                                            <h5 className="text-truncate font-size-15">
                                                                {patientData.formData && patientData.formData.fullName && capitalizeWords(patientData.formData.fullName.first + " " + patientData.formData.fullName.last)}
                                                            </h5>
                                                            <p className="text-muted">
                                                                {"Status"}:
                                                                {patientData.isApproved === "accepted" &&
                                                                    <span
                                                                        className={"badge badge-soft-primary font-size-11 m-1"}
                                                                    >
                                                                        {capitalizeWords("accepted")}
                                                                    </span>
                                                                }
                                                                {patientData.isApproved === "pending" &&
                                                                    <span
                                                                        className={"badge badge-soft-warning font-size-11 m-1"}
                                                                    >
                                                                        {capitalizeWords("pending")}
                                                                    </span>
                                                                }
                                                                {patientData.isApproved === "rejected" &&
                                                                    <span
                                                                        className={"badge badge-soft-danger font-size-11 m-1"}
                                                                    >
                                                                        {capitalizeWords("rejected")}
                                                                    </span>
                                                                }
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="d-flex flex-md-row justify-content-md-end flex-sm-column flex-column justify-content-sm-between align-items-sm-start">
                                                        {patientData.isApproved === "accepted" &&
                                                            (<>
                                                                <a
                                                                    href={pdfDownload}
                                                                    className="btn btn-primary waves-effect waves-light mr-2 mb-2"
                                                                >
                                                                    {"prescription"}
                                                                    <i className="mdi mdi-arrow-collapse-down ml-1"></i>
                                                                </a>
                                                                {/* <Button
                                                                    // onClick={() => {
                                                                    //   setconfirm_both_email(true);
                                                                    // }}
                                                                    className="btn btn-primary waves-effect waves-light mr-2 mb-2"
                                                                >
                                                                    {"Send Email"}
                                                                </Button> */}
                                                            </>)
                                                        }
                                                        {patientData.isApproved === "rejected" &&
                                                            (<>
                                                                <Button className='mr-2 btn bg-success bg-lighten-xl waves-effect waves-light'
                                                                    onClick={() => { HandleRelease(); }}
                                                                >
                                                                    Release
                                                                </Button>
                                                            </>)
                                                        }
                                                        {backToDoctorListLink()}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <h5 className="font-size-15 mt-4">
                                                {"Patient Details"} :
                                            </h5>
                                            <div className="text-muted mt-4">
                                                <Row>
                                                    <Col lg="8">
                                                        <div className="text-left mb-2">
                                                            {errorMsg ? dctAlert(3, errorMsg) : ""}
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <p>
                                                            <i className="bx bx-user text-primary mr-1"></i>{" "}
                                                            <strong>{"Full Name"}: </strong>{" "}
                                                            {patientData.formData && patientData.formData.fullName && capitalizeWords(patientData.formData.fullName.first + " " + patientData.formData.fullName.last)}
                                                        </p>
                                                        <p>
                                                            <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                                                            <strong>{"Email"}: </strong>{" "}
                                                            {patientData.formData && patientData.formData.email && patientData.formData.email}
                                                        </p>
                                                        <p>
                                                            <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                                            <strong>{"Phone Number"}: </strong>{" "}
                                                            {patientData.formData && patientData.formData.phoneNumber && patientData.formData.phoneNumber.full}
                                                        </p>
                                                        <p>
                                                            <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                                                            <strong>{"Submission Date"}: </strong>{" "}
                                                            {patientData.formData && patientData.formData.submissionDate && dctHumanReadableGlobalFormat(patientData.createdAt)}
                                                        </p>
                                                        <p>
                                                            <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                                                            <strong>{"Haze Id"}: </strong>{" "}
                                                            {patientData.formData && patientData.formData.hazeId && patientData.formData.hazeId}
                                                        </p>
                                                        <p>
                                                            <i className="bx bxs-map text-primary mr-1"></i>{" "}
                                                            <strong>{"Address"}: </strong>
                                                            {patientAddress}
                                                        </p>
                                                    </Col>
                                                    {patientData.formData && patientData.formData.licenseImage &&
                                                        <Col lg="2">
                                                            <p>
                                                                <i className="bx bxs-crown text-primary mr-1"></i>
                                                                <strong >
                                                                    {"License Image"} :
                                                                </strong>
                                                            </p>
                                                            <div>
                                                                <img style={{ height: "11rem", width: "15rem" }} src={patientData.formData.licenseImage} alt="licenseImage" />
                                                            </div>
                                                        </Col>
                                                    }
                                                </Row>
                                            </div>
                                        </div>
                                        {erroe_feedback && (
                                            <SweetAlert
                                                error
                                                title={`Error : ${feedBackError}`}
                                                onConfirm={() => {
                                                    setError_Feedback(false);
                                                }}
                                            >

                                            </SweetAlert>
                                        )}
                                    </CardBody>
                                )}

                            </Card>
                            <Card>
                                {
                                    patientData.doctorId && patientData.isApproved === "accepted" &&
                                    (
                                        <CardBody>
                                            <>
                                                <h5 className="font-size-15 mt-4">
                                                    {"Doctor Details"} :
                                                </h5>
                                                <div className="text-muted mt-4">
                                                    <Row>
                                                        <Col lg="6">
                                                            {patientData.doctorDetails && patientData.doctorDetails.fullName &&
                                                                <p>
                                                                    <i className="bx bx-user text-primary mr-1"></i>{" "}
                                                                    <strong>{"Name"}: </strong>{" "}
                                                                    {capitalizeWords(patientData.doctorDetails.fullName)}
                                                                </p>
                                                            }
                                                            {patientData.doctorDetails && patientData.doctorDetails.email &&
                                                                <p>
                                                                    <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                                                                    <strong>{"Email"}: </strong>{" "}
                                                                    {patientData.doctorDetails.email}
                                                                </p>
                                                            }
                                                            {patientData.doctorId && patientData.doctorId.primaryPhone &&
                                                                <p>
                                                                    <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                                                    <strong>{"Primary Phone"}: </strong>{" "}
                                                                    {patientData.doctorId.primaryPhone}
                                                                </p>
                                                            }
                                                            {patientData.doctorId && patientData.doctorId.secondaryPhone &&
                                                                <p>
                                                                    <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                                                    <strong>{"Secondary Phone"}: </strong>{" "}
                                                                    {patientData.doctorId.secondaryPhone}
                                                                </p>
                                                            }
                                                            {patientData.doctorId && patientData.doctorId.awardsAndAchievements &&
                                                                <p>
                                                                    <i className="bx bxs-spa text-primary mr-1"></i>{" "}
                                                                    <strong>{"Awards & Achivements"}: </strong>{" "}
                                                                    {patientData.doctorId.awardsAndAchievements}
                                                                </p>
                                                            }
                                                            {patientData.doctorId && patientData.doctorId.licenseInfo &&
                                                                <div>
                                                                    <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                                                    <strong>{"Licensed States"}: </strong>{" "}
                                                                    {
                                                                        patientData.doctorId?.licenseInfo ? (
                                                                            <p dangerouslySetInnerHTML={{ __html: renderPhysicanLincenseInfo(patientData.doctorId.licenseInfo) }}></p>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            }
                                                            {patientData.doctorId && patientData.doctorId.address &&
                                                                <p>
                                                                    <i className="bx bxs-map text-primary mr-1"></i>{" "}
                                                                    <strong>{"Address"}: </strong>{" "}
                                                                    {
                                                                        `${patientData.doctorId.address}, ${patientData.doctorId.addressTwo}, ${patientData.doctorId.city},  ${patientData.doctorId.country}`
                                                                    }
                                                                </p>
                                                            }
                                                        </Col>
                                                        <Col>
                                                            {patientData.doctorId && patientData.doctorId.qualification &&
                                                                <p>
                                                                    <i className="bx bxs-crown text-primary mr-1"></i>{" "}
                                                                    <strong>{"Qualification"}: </strong>{" "}
                                                                    {patientData.doctorId.qualification}
                                                                </p>
                                                            }
                                                            {patientData.doctorId && patientData.doctorId.npi &&
                                                                <p>
                                                                    <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                                                    <strong>{"NPI Number"}: </strong>{" "}
                                                                    {patientData.doctorId.npi}
                                                                </p>
                                                            }
                                                            {patientData.doctorId && patientData.doctorId.taxIdNumber &&
                                                                <p>
                                                                    <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                                                    <strong>{"Tax Id Number"}: </strong>{" "}
                                                                    {patientData.doctorId.taxIdNumber}
                                                                </p>
                                                            }
                                                            {patientData.doctorId && patientData.doctorId.doctorSignatureUrl &&
                                                                <img style={{ height: "11rem", width: "15rem" }} src={patientData.doctorId.doctorSignatureUrl} alt="doctorSignatureUrl" />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </>
                                        </CardBody>
                                    )
                                }
                            </Card>
                            <Card>
                                {
                                    patientData.formData && (
                                        <CardBody>
                                            <div data-id={patientData.id}>

                                                <h5 className="font-size-15 mt-4">
                                                    {"Join Haze Questionnaire"} :
                                                </h5>

                                                <div className="text-muted mt-4 ml-3">
                                                    <Row>
                                                        <Col lg="10">
                                                            {patientData.formData && patientData.formData.isThisRenewal &&
                                                                <p>
                                                                    <strong>{"Is this a renewal?"} </strong> <br />
                                                                    {patientData.formData.isThisRenewal}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.sufferingConditions &&
                                                                <p>
                                                                    <strong>{"What conditions are you suffering from?"} </strong> <br />
                                                                    {patientData.formData.sufferingConditions.map((ele) => ele)}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.experiencedDiagnosed &&
                                                                <p>
                                                                    <strong>{"Have you experienced of been diagnosed with any of the following?"} </strong> <br />
                                                                    {patientData.formData.experiencedDiagnosed.map((ele) => ele)}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.usingMedications &&
                                                                <p>
                                                                    <strong>{"Are you currently using medications for your condition(s)?"} </strong> <br />
                                                                    {patientData.formData.usingMedications}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.allergicToMedications &&
                                                                <p>
                                                                    <strong>{"Are you allergic to any medications?"} </strong> <br />
                                                                    {patientData.formData.allergicToMedications}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.haveYouHospitalized &&
                                                                <p>
                                                                    <strong>{"Have you been hospitalized or had any surgeries?"} </strong> <br />
                                                                    {patientData.formData.haveYouHospitalized}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.doYouExercise &&
                                                                <p>
                                                                    <strong>{"Do you exercise?"} </strong> <br />
                                                                    {patientData.formData.doYouExercise}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.doYouSmokeTobacco &&
                                                                <p>
                                                                    <strong>{"Do you smoke tobacco?"} </strong> <br />
                                                                    {patientData.formData.doYouSmokeTobacco}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.doYouDrinkAlcohol &&
                                                                <p>
                                                                    <strong>{"Do you drink alcohol?"} </strong> <br />
                                                                    {patientData.formData.doYouDrinkAlcohol}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.havePryCareProvider &&
                                                                <p>
                                                                    <strong>{"Do you have a primary care provider?"} </strong> <br />
                                                                    {patientData.formData.havePryCareProvider}
                                                                </p>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="text-muted mt-4 ml-3">
                                                    <Row>
                                                        <Col>
                                                            {patientData.additionalComment && patientData.isApproved === "accepted" &&
                                                                (<div>
                                                                    <h5 className="font-size-15 mt-4">
                                                                        {"Doctor Comment"} :
                                                                    </h5>
                                                                    <p>{patientData.additionalComment}</p>
                                                                </div>)
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </CardBody>
                                    )
                                }
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default JoinHazePatientOverview