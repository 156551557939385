import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
    Row, Col, Card, CardBody, Modal, ModalHeader, Button, ModalBody, Alert, Container
} from "reactstrap";
import axios from "axios";
import { dctAlert } from './../../helpers/Commons';

const MemberExport = (props) => {
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState('')
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const handleExport = async () => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(email) === false) {
            setSuccess(false);
            setErrorMsg('Please enter a valid mail address');
            setError(true)
            return;
        }

        setSuccess(false);
        let baseurl = process.env.REACT_APP_API_PATH + `/member/export`;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: {
                Authorization: bearer_token,
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
        };

        setError(false);
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
            setSuccess(true);
            setSuccessMsg('Your CSV Report will be generated and sent to your mail in few minutes.')
            setEmail('');
        }, 3000)

        const data = {
            email: email
        }
        await axios
            .post(baseurl, data, headers)
        return;

    }

    return (

        <React.Fragment>
            <Col lg="5" md="6" sm="6">
                <Card>
                    <CardBody>
                        {success && <div> {dctAlert(1, successMsg)}</div>}
                        {error && <div> {dctAlert(3, errorMsg)}  </div>}
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs mr-3">
                                <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                    <i className="fas fa-users"></i>
                                </span>
                            </div>
                            <h5 className="font-size-14 mb-0">Export Members</h5>
                        </div>
                        <Row className="d-flex flex-row px-3 container-fluid">
                            <Col md="12">
                                <h6>Email</h6>
                                <input
                                    className="form-control"
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        setError(false);
                                        setEmail(e.currentTarget.value)
                                    }}
                                />
                            </Col>

                        </Row>
                        <Row className="mt-3 container-fluid">
                            <Col lg={12} className="text-right">
                                <button
                                    onClick={handleExport}
                                    className="btn btn-primary waves-effect waves-light" type="button">

                                    {isLoading ? <>

                                        <span className="dr-cform-spinner">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                                        </span>

                                    </> : <i className="mdi mdi-file-export-outline mr-1"></i>}

                                    Export
                                </button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default withRouter(
    withNamespaces()(MemberExport)
);