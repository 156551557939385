import React, { useState, useEffect } from "react";

//Style from Reactstrap
import { Container, Row, Col, Form, FormGroup, Input, Label, } from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError, processLogout } from "../../../helpers/backend_helper";

import {
  getPostMessage,
  setPostMessageListing,
  setSinglePostMessage,
  getSinglePostMessage,
} from "../../../store/actions";

//Import PostMessageList
import PostMessageList from "../../../components/PostMessage/PostMessageList";
import PostMessageChatWindow from "../../PostMessage/PostMessageChatWindow";

import axios from "axios";
import DctHeroBanner from "../../../components/Common/DctHeroBanner";
import { DctMessageIcon } from "../../../components/Common/Icons/DctMessageIcon";

const LockMessage = (props) => {
  const [postsPerPage, setPostsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [isCommentData, setIsCommentData] = useState(false);
  const [openPostListPanel, setOpenPostListPanel] = useState(true);
  const [openCommentWindow, setOpenCommentWindow] = useState(true);
  const [currentPost, setCurrentPost] = useState(null);
  const [currentPostComment, setCurrentPostComment] = useState('');

  const [pmdata, setGData] = useState({
    loading: true,
    postMessages: null,
    message: null,
  });

  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    postMessageSearchParam: "",
    type: "filter",
    reset: false,
  });

  const { authMemberId, authDoctorId } = useSelector((state) => state.Login);

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("search");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        postMessageSearchParam: msearch,
        type: "filter",
      };

      setSearchFormDetails({
        reset: false,
      });
      return fetchPostMessageData(searchParam, selectedPage);
    }
    fetchPostMessageData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("search");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        postMessageSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      return fetchPostMessageData(searchParam, selectedPage);
    }
    // if(selectedPage > 1)
    fetchPostMessageData(searchParam, selectedPage);
  };

  const fetchPostMessageData = (param, currentPage = 1) => {
    let searchParam = "";
    if (param) {
      searchParam = param.postMessageSearchParam
        ? "&q=" + param.postMessageSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    let url =
      process.env.REACT_APP_API_PATH +
      `/doctor/${authDoctorId}/posts?page=${currentPage}&limit=${postsPerPage}` +
      searchParam;

    axios
      .get(url, headers)
      .then(function (response) {
        const data = response.data;
        let message = "";
        message = data.data && data.data.length > 0 ? "" : "No record found.";
        dispatch(setPostMessageListing(data.data, message));
        setGData({
          loading: false,
          postMessages: data.data,
          message: message,
        });
        setTotalPosts(Math.ceil(data.total / postsPerPage));
        setCurrentPage(currentPage - 1);
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = err.response.data.cause.details;
          }
        }
        setGData({
          loading: false,
          postMessages: null,
          message: message,
        });
        setTotalPosts(0);
        setCurrentPage(0);
        dispatch(setPostMessageListing(null, message));
      })
      .then(function () {});
  };

  const loadCurrentPost = () => {
    let pathname = props?.history?.location?.pathname;
    let pathParts = pathname.split('/');
    let postId = pathParts[pathParts.length -1];

    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/post/${postId}`;
    axios
      .get(url, options)
      .then((response) => {
        if (!response.data.doctorId) {
          setCurrentPost(response.data);
        }
      })
      .catch((err) => {
        console.log('Failed to fetch post data. Error: ', err.response.data);
      });
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let search = urlParams.get("search");
    let searchParam = "";
    if (search) {
      searchParam = {
        postMessageSearchParam: search,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      fetchPostMessageData(searchParam);
    }
    loadCurrentPost();
    return cleanup;
  }, []);

  const cleanup = () => {
    let eventId = localStorage.getItem('eventId');
    if (eventId) {
      releaseEventLog(eventId);
    }
  }

  const releaseEventLog = async (eventId) => {
    if (!eventId || eventId == '') return;
    let url = `${process.env.REACT_APP_API_PATH}/eventlog/${eventId}/released`;
    let bearer_token = localStorage.getItem("auth_token");
    const options = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    const data= {
      userId: JSON.parse(localStorage.getItem("auth_user")).id,
    };
    axios
    .patch(url, data, options)
    .then((response) => {
      localStorage.removeItem("eventId");
    })
    .catch((error)=> {
      console.log('Could not release. Error -> ', error.response.data);
    }); 
  };

  // Apply PostMessage Search
  function handleSearch(e) {
    e.preventDefault();

    fetchPostMessageData(searchFormDetails);
    setSearchFormDetails({
      reset: true,
    });
    let param = searchFormDetails.postMessageSearchParam
      ? searchFormDetails.postMessageSearchParam
      : "";
    props.history.push("/doctor/messages?search=" + param);
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    fetchPostMessageData();
    setSearchFormDetails({
      reset: false,
    });
    document.getElementById("postMessage-search-form").reset();
    props.history.push("/doctor/messages");
  }

  const { loading, postMessages, message } = pmdata;
  const postMessage_action = useSelector(
    (state) => state.PostMessage.action_type
  );

  const handleCurrentPostReply = async () => {
    if (!currentPost) return;
    let url = `${process.env.REACT_APP_API_PATH}/post/${currentPost.id}/accepted`;
    const data = {
      comment: currentPostComment,
      doctorId: authDoctorId,
    };
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios
      .patch(url, data, options)
      .then((response) => {
        props.history.push('/dashboard');
      })
      .catch((err) => {
        console.log("Accept & reply failed.", err.response.data);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        { 
          currentPost 
          ? (
            <Container fluid>
              <Row>
                <Col xl={12}>
                  <div className="form-group">
                    <Label>{props.t("Question")}:</Label><br/>
                    <Label>
                      <strong>{currentPost?.question}</strong>
                    </Label><br/>
                    <Label>
                    {props.t("Details")}:
                    </Label><br/>
                    <Label><strong>{currentPost?.details}</strong></Label><br/>
                    <Label>
                    {props.t("Document")}:
                    </Label><br/>
                    {currentPost.docUrl ? <img src={currentPost.docUrl}  alt="Document" className="img-fluid"/> : props.t("No document available")}
                  </div>
                </Col>
              </Row>
              <Row><Col xl={12}>
                <div className="form-group">
                  <Label>{props.t("Comment")}:</Label>
                  <br />
                  <textarea
                    className="form-control"
                    name="comment"
                    style={{ height: "150px" }}
                    onChange={(event) => {
                      setCurrentPostComment(event.target.value);
                    }}
                  />
                </div> 
              </Col></Row>
              <Row><Col xl={12}>
                <button type="submit"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={handleCurrentPostReply}
                >
                  {props.t("Reply")}
                  <span className="dr-gform-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                </button>
                {" "}
                <button type="submit"
                  className="btn btn-secondary waves-effect waves-light"
                  onClick={() => {
                    props.history.push('/dashboard');
                  }}
                >
                  {props.t("Cancel")}
                  <span className="dr-gform-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                </button>
              </Col></Row>
              <Row>
                <p>{" "}</p>
              </Row>
            </Container>
          ) : "" 
        }
        
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { postMessage } = state.PostMessage;
  const { singlePostMessage } = state.PostMessage;
  const { message } = state.PostMessage;
  return { postMessage, message, singlePostMessage };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPostMessage,
    setSinglePostMessage,
    getSinglePostMessage,
  })(withNamespaces()(LockMessage))
);
