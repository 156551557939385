import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
    Row, Col, Card, CardBody, Modal, ModalHeader, Button, ModalBody, Alert, Container
} from "reactstrap";
import axios from "axios";
import { dctAlert } from './../../helpers/Commons';
import UploadModal from "../../components/Dashboard/UploadModal";

const MultiplePlanCSV = (props) => {
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState('')
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [modal, setModal] = useState(false)

    const openCSVuploadModal = () => {
        // document.getElementById("multi_plan_csv_upload_modal").click();
        setModal(!modal);
    }

    return (

        <React.Fragment>
            <Col lg="4" md="6" sm="6">
                <Card style={{ height: "100%" }}>
                    <CardBody>
                        {success && <div> {dctAlert(1, successMsg)}</div>}
                        {error && <div> {dctAlert(3, errorMsg)}  </div>}
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs mr-3">
                                <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                    <i className="mdi mdi-file"></i>
                                </span>
                            </div>
                            <h5 className="font-size-14 mb-0">Multi-Company EDI File Upload</h5>
                        </div>
                        <Row className=" px-3 container-fluid">
                            <Col md="12" className='d-flex justify-content-center align-items-center'>
                                <div className="rounded-circle bg-soft-primary font-size-30 text-primary upload-container"
                                    onClick={() => {
                                        openCSVuploadModal();
                                    }}>
                                    <i className="bx bx-cloud-upload"></i>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <UploadModal modal={modal} setModal={setModal} />

        </React.Fragment>
    );
}

export default withRouter(
    withNamespaces()(MultiplePlanCSV)
);