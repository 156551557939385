import { FIND_YOUR_ACCOUNT, ACTIVATE_ACCOUNT_DATA } from "./actionTypes";

const initialState = {
  findAccountData: [],
  activeAccountData: {},
};

const activateBenefit = (state = initialState, action) => {
  switch (action.type) {
    case FIND_YOUR_ACCOUNT:
      console.log("from reducer FIND_YOUR_ACCOUNT----", action);
      state = {
        ...state,
        findAccountData: action.payload,
      };
      break;
    case ACTIVATE_ACCOUNT_DATA:
      console.log("from reducer---- ACTIVATE_ACCOUNT_DATA", action);
      state = {
        ...state,
        activeAccountData: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default activateBenefit;
