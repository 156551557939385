import React from 'react'
import { Config } from '../../../Config'
import VideoApp from '../../Dashboard-doctor/index1'

const VideoCall = () => {
    return (
        <div className={`${Config.names.slugName}-override-screen`}>
            
            <VideoApp></VideoApp>
        </div> 
    )
}

export default VideoCall
