export const phoneFormatter = (phone, pattern) => {
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    if (pattern === 1) {
      return "(" + match[1] + ")" + " " + match[2] + "-" + match[3];
    } else if (pattern === 2) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
  }
  return null;
};
