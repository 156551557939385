import React from 'react'
import { useLocation, Redirect, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Media,
    Badge,
    Alert,
    Button,
} from "reactstrap";
import { loadingMessage, dctAlert } from '../../helpers/Commons';
import { Config } from '../../Config';
import SweetAlert from 'react-bootstrap-sweetalert'


const JoinHazeOverView = () => {
    let history = useHistory();
    const { authDoctorId } = useSelector((state) => state.Login);

    const [errorMsg, setErrorMsg] = useState(false);
    const [patientData, setPatientData] = useState({})
    const [feedBackData, setFeedBackData] = useState("")
    // console.log("patientData", patientData);
    const [loading, setLoading] = useState(false)
    const [loadingComment, setLoadingComment] = useState(false)
    const [confirm_feedback, setConfirm_feedback] = useState(false);
    const [success_feedBack, setsuccess_feedBack] = useState(false);
    const [erroe_feedback, setError_Feedback] = useState(false);
    const [feedBackError, setFeedBackError] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [patientAddress, setPatientAddress] = useState("")
    const [doctorAccept, setDoctorAccept] = useState(false)


    const location = useLocation()
    const patientId = location.pathname.split('/')[3]

    const GetPatientData = () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_PATH}/api/questionnaire/${patientId}`
        const options = {
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
        };
        axios.get(url, options).then((res) => {
            // console.log("response", res.data)
            setPatientData(res.data)
            if (res.data.formData.address) {
                let addressArr = Object.values(res.data.formData.address)
                let final = []
                addressArr.forEach((val, i) => {
                    if (val) {
                        if (i === addressArr.length - 1) {
                            final.push(val)
                            setPatientAddress(final.join(' '))
                        }
                        else
                            final.push(val + ',')
                    }
                })
            }
            setLoading(false)
            if (res.data.formData.address) {
                let addressArr = Object.values(res.data.formData.address)
                let final = []
                addressArr.forEach((val, i) => {
                    if (val) {
                        if (i === addressArr.length - 1) {
                            final.push(val)
                            setPatientAddress(final.join(' '))
                        }
                        else
                            final.push(val + ',')
                    }
                })
            }
        }).catch((err) => {
            setErrorMsg(
                "Could not fetch details... Error: " + err?.response?.data?.message
            );
            setLoading(false)
        })
    }

    useEffect(() => {
        GetPatientData();
    }, [])

    const DoctorFeedbackSubmit = () => {

        setLoadingComment(true)

        const data = {
            doctorId: authDoctorId,
            isApproved: "accepted",
            additionalComment: feedBackData
        }

        var config = {
            method: 'put',
            url: `${process.env.REACT_APP_API_PATH}/api/questionnaire/${patientId}`,
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
            data: data
        };
        axios(config).then((feedback) => {
            // setConfirm_feedback(false);
            setLoadingComment(false);
            setsuccess_feedBack(true);
        }).catch((err) => { setFeedBackError(err); setLoadingComment(false); setError_Feedback(true); });

    }

    function DoctorRejectSubmit() {
        // console.log("Reject");
        setLoadingComment(true)

        const data = {
            doctorId: authDoctorId,
            isApproved: "rejected",
        }

        var config = {
            method: 'put',
            url: `${process.env.REACT_APP_API_PATH}/api/questionnaire/${patientId}`,
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
            data: data
        };
        axios(config).then((feedback) => {
            // setConfirm_feedback(false);
            setLoadingComment(false);
            history.push("/doctor/join-haze");
            // setsuccess_feedBack(true);
        }).catch((err) => { setFeedBackError(err); setLoadingComment(false); setError_Feedback(true); });
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            {loading && (
                                <div className={`${Config.names.slugName}-listing-loader`}>
                                    {loadingMessage("Loading...")}
                                </div>
                            )}
                            {loadingComment && (
                                <div className={`${Config.names.slugName}-listing-loader`}>
                                    {loadingMessage("Please Wait...")}
                                </div>
                            )}
                            <Card>
                                {patientData && (
                                    <CardBody>
                                        <div className="member-details-section" data-id={patientData.id}>
                                            <Row>
                                                <Col>
                                                    <h5 className="font-size-15 mt-4">
                                                        {"Patient Details"} :
                                                    </h5>
                                                </Col>
                                                <Col>
                                                    <div className="d-flex flex-md-row justify-content-md-end flex-sm-column flex-column justify-content-sm-between align-items-sm-start"

                                                    >
                                                        <Button className='mr-2 mb-2 btn  bg-success bg-lighten-xl waves-effect waves-light'
                                                            onClick={() => { setDoctorAccept(true); }}
                                                        >
                                                            Accept
                                                        </Button>
                                                        <Button className='bg-danger bg-lighten-xs'
                                                            onClick={() => { DoctorRejectSubmit(); }}
                                                        >
                                                            Reject
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="text-muted mt-4">
                                                <Row>
                                                    <Col lg="8">
                                                        <div className="text-left mb-2">
                                                            {errorMsg ? dctAlert(3, errorMsg) : ""}
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <p>
                                                            <i className="bx bx-user text-primary mr-1"></i>{" "}
                                                            <strong>{"Full Name"}: </strong>{" "}
                                                            {patientData.formData && patientData.formData.fullName && patientData.formData.fullName.first + " " + patientData.formData.fullName.last}
                                                        </p>
                                                        <p>
                                                            <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                                                            <strong>{"Email"}: </strong>{" "}
                                                            {patientData.formData && patientData.formData.email && patientData.formData.email}
                                                        </p>
                                                        <p>
                                                            <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                                                            <strong>{"Phone Number"}: </strong>{" "}
                                                            {patientData.formData && patientData.formData.phoneNumber && patientData.formData.phoneNumber.full}
                                                        </p>
                                                        <p>
                                                            <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                                                            <strong>{"Submission Date"}: </strong>{" "}
                                                            {patientData.formData && patientData.formData.submissionDate && `${patientData.formData.submissionDate.month}/${patientData.formData.submissionDate.day}/${patientData.formData.submissionDate.year}`}
                                                        </p>
                                                        <p>
                                                            <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                                                            <strong>{"Haze Id"}: </strong>{" "}
                                                            {patientData.formData && patientData.formData.hazeId && patientData.formData.hazeId}
                                                        </p>
                                                        <p>
                                                            <i className="bx bxs-map text-primary mr-1"></i>{" "}
                                                            <strong>{"Address"}: </strong>
                                                            {patientAddress}
                                                        </p>


                                                    </Col>

                                                    {patientData.formData && patientData.formData.licenseImage &&
                                                        <Col lg="2">
                                                            <p>
                                                                <i className="bx bxs-crown text-primary mr-1"></i>
                                                                <strong >
                                                                    {"License Image"} :
                                                                </strong>
                                                            </p>
                                                            <div>
                                                                <img style={{ height: "11rem", width: "15rem" }} src={patientData.formData.licenseImage} alt="licenseImage" />
                                                            </div>

                                                        </Col>
                                                    }
                                                </Row>


                                            </div>

                                        </div>
                                    </CardBody>
                                )}
                            </Card>
                            <Card>
                                {
                                    patientData && (
                                        <CardBody>
                                            <div data-id={patientData.id}>

                                                <h5 className="font-size-15 mt-4">
                                                    {"Join Haze Questionnaire"} :
                                                </h5>

                                                <div className="text-muted mt-4 ml-3">
                                                    <Row>
                                                        <Col lg="10">
                                                            {patientData.formData && patientData.formData.isThisRenewal &&
                                                                <p>
                                                                    <strong>{"Is this a renewal?"} </strong> <br />
                                                                    {patientData.formData.isThisRenewal}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.sufferingConditions &&
                                                                <p>
                                                                    <strong>{"What conditions are you suffering from?"} </strong> <br />
                                                                    {patientData.formData.sufferingConditions.map((ele) => ele)}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.experiencedDiagnosed &&
                                                                <p>
                                                                    <strong>{"Have you experienced of been diagnosed with any of the following?"} </strong> <br />
                                                                    {patientData.formData.experiencedDiagnosed.map((ele) => ele)}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.usingMedications &&
                                                                <p>
                                                                    <strong>{"Are you currently using medications for your condition(s)?"} </strong> <br />
                                                                    {patientData.formData.usingMedications}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.allergicToMedications &&
                                                                <p>
                                                                    <strong>{"Are you allergic to any medications?"} </strong> <br />
                                                                    {patientData.formData.allergicToMedications}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.haveYouHospitalized &&
                                                                <p>
                                                                    <strong>{"Have you been hospitalized or had any surgeries?"} </strong> <br />
                                                                    {patientData.formData.haveYouHospitalized}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.doYouExercise &&
                                                                <p>
                                                                    <strong>{"Do you exercise?"} </strong> <br />
                                                                    {patientData.formData.doYouExercise}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.doYouSmokeTobacco &&
                                                                <p>
                                                                    <strong>{"Do you smoke tobacco?"} </strong> <br />
                                                                    {patientData.formData.doYouSmokeTobacco}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.doYouDrinkAlcohol &&
                                                                <p>
                                                                    <strong>{"Do you drink alcohol?"} </strong> <br />
                                                                    {patientData.formData.doYouDrinkAlcohol}
                                                                </p>
                                                            }
                                                            {patientData.formData && patientData.formData.havePryCareProvider &&
                                                                <p>
                                                                    <strong>{"Do you have a primary care provider?"} </strong> <br />
                                                                    {patientData.formData.havePryCareProvider}
                                                                </p>
                                                            }
                                                        </Col>
                                                    </Row>


                                                </div>

                                            </div>
                                        </CardBody>
                                    )
                                }
                            </Card>
                            {
                                doctorAccept &&
                                <Card>
                                    {
                                        patientData && (
                                            <CardBody>
                                                <div data-id={patientData.id}>

                                                    <h5 className="font-size-15 mt-4">
                                                        {"Doctor Feedback"} :
                                                    </h5>

                                                    <div className="text-muted mt-4 ml-3">
                                                        <Row>
                                                            <Col >
                                                                <textarea name="feedback" cols="30" rows="10" style={{ width: "100%", padding: "0.7rem" }}

                                                                    onChange={(e) => setFeedBackData(e.target.value)}
                                                                />
                                                            </Col>
                                                        </Row>


                                                    </div>
                                                    <button onClick={() => { setConfirm_feedback(true); setIsLoading(true); }} style={{ marginTop: "1rem", marginLeft: "1rem" }} className="btn btn-primary" >
                                                        {isLoading && <>

                                                            <span className="dr-cform-spinner">
                                                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                                                            </span>

                                                        </>}
                                                        Submit
                                                    </button>
                                                    { }

                                                    {confirm_feedback && (
                                                        <SweetAlert
                                                            title={"Are you sure?"}
                                                            warning
                                                            showCancel
                                                            confirmBtnBsStyle="success"
                                                            cancelBtnBsStyle="secondary"
                                                            onConfirm={() => {
                                                                setIsLoading(false);
                                                                setConfirm_feedback(false)
                                                                DoctorFeedbackSubmit();
                                                            }}
                                                            onCancel={() => {
                                                                setIsLoading(false);
                                                                setConfirm_feedback(false)
                                                                // setsuccess_feedBack(false);
                                                            }}
                                                        >
                                                            {"This process will be submit your feedback !"}
                                                        </SweetAlert>
                                                    )}
                                                    {success_feedBack && (
                                                        <SweetAlert
                                                            success
                                                            title="Feedback Submited Successfully"
                                                            onConfirm={() => {
                                                                setsuccess_feedBack(false);
                                                                history.push("/doctor/join-haze");

                                                            }}
                                                        >

                                                        </SweetAlert>
                                                    )}
                                                    {erroe_feedback && (
                                                        <SweetAlert
                                                            error
                                                            title={`Error : ${feedBackError}`}
                                                            onConfirm={() => {
                                                                setIsLoading(false);
                                                                setError_Feedback(false);
                                                                setConfirm_feedback(false);
                                                            }}
                                                        >

                                                        </SweetAlert>
                                                    )}
                                                </div>
                                            </CardBody>
                                        )
                                    }
                                </Card>
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default JoinHazeOverView