import React, { useState } from "react";
import {
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import debounce from "lodash/debounce";

import { AvForm, AvField } from "availity-reactstrap-validation";
import AsyncSelect from "react-select/async";
import { Config } from "../../../Config";
import { Tbody, Td, Tr } from "react-super-responsive-table";
import { RegionDropdown } from "react-country-region-selector";
import {
  dctAsyncPutData,
  dctGetAsyncData,
} from "../../../helpers/backend_helper";
import Axios from "axios";
import { dctAlert, dctClsAddRemove } from "../../../helpers/Commons";
const ChangePharmacy = (props) => {
  const modal = props?.modal;
  const setChangePharmacy = props?.setChangePharmacy;
  const searchData = props?.searchData;
  const setSearchData = props?.setSearchData;
  const pharmacy = props?.pharmacy;
  const setPharmacy = props?.setPharmacy;
  const setSuccessMsg = props?.setSuccessMsg;
  const [pharmacyClassName, setPharmacyClassName] = useState("");
  const [pharmacyList, setPharmacyList] = useState([]);
  const [err, setErr] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const UsePharmacy = (val) => {
    let data = {
      isPreferred: true,
      pharmacyId: val?.PharmacyId,
      pharmacyObj: val,
    };

    const url = `member/${searchData?.memberId}/pharmacy`;
    dctAsyncPutData(data, url).then(function (result) {
      if (result.success === true) {
        let msg = result.message;
        setSuccessMsg(msg);
        setErrorMsg(false);
        setPharmacy({
          ...pharmacy,
          storeName: val?.StoreName,
          address1: val?.Address1,
          city: val?.City,
          state: val?.State,
          Z: val?.ZipCode,
          P: val?.PrimaryPhone,
          F: val?.PrimaryFax,
          pharmacyId: val?.PharmacyId,
        });
        dctClsAddRemove(
          `${Config.names.slugName}-block-loader`,
          "d-none",
          "add"
        );
        setChangePharmacy(false);
      } else {
        setErrorMsg(props.t(`Something went wrong.`));
        setSuccessMsg(false);
        dctClsAddRemove(
          `${Config.names.slugName}-block-loader`,
          "d-none",
          "add"
        );
      }
    });
  };

  const renderPharmacyList = (pharmacyList) => {
    return pharmacyList.map((pharmacy, index) => {
      return (
        <Tr key={index} data-row-id={pharmacy?.PharmacyId} className="mb-2">
          <Td>
            <div className="d-flex">
              <div className="info" style={{ width: "220px" }}>
                <h5 className="font-size-15 mb-1 dct__pharmacy_text_ellipses">
                  {pharmacy?.StoreName}
                </h5>
                <p className="mb-1">PH: {pharmacy?.PrimaryPhone}</p>
                <p className="mb-1">Fax: {pharmacy?.PrimaryFax}</p>
                <div>
                  <span>{pharmacy?.Address1} </span>
                </div>
                <div>
                  <span>{pharmacy?.Address2}</span>{" "}
                </div>
                <p className="mb-2">
                  <span>{pharmacy?.City}</span>
                  <span>, {pharmacy?.State}</span>-{" "}
                  <span>{pharmacy?.ZipCode}</span>
                </p>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light mr-2 mt-5"
                  onClick={() => UsePharmacy(pharmacy)}
                >
                  {props.props.t("Use Pharmacy")}
                </button>
              </div>
            </div>
          </Td>
        </Tr>
      );
    });
  };

  const loadStateOptions = (inputValue, callback) => {
    let query = inputValue ? `&q=${inputValue}` : "";
    var apiUrl = `${process.env.REACT_APP_API_PATH}/state?country=us${query}`;
    const axiosArgs = {
      URL: apiUrl,
    };
    dctGetAsyncData(axiosArgs).then((results) => callback(results));
    return;
  };
  const stateListOptions = debounce(loadStateOptions, 250);

  const SearchPharmacy = () => {
    setSearchData({
      ...searchData,
      cityErr: false,
      zipCodeErr: false,
      stateErr: false,
    });
    if (!searchData.city || !searchData.zipCode || !searchData.state) {
      if (!searchData.state) {
        setSearchData({ ...searchData, stateErr: true });
      }
      return;
    }
    setLoader(true);
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/pharmacy?city=${searchData.city}&zip=${searchData.zipCode}&state=${searchData.state}`;
    Axios.get(url, options)
      .then((res) => {
        setLoader(false);
        setPharmacyList(res.data.data);
        if (res.data.data.length == 0) {
          setErr(true);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(`err`, err);
      });
  };
  return (
    <div>
      <Modal
        centered={true}
        size="md"
        isOpen={modal}
        toggle={() => {
          setChangePharmacy(!modal);
        }}
        scrollable={true}
      >
        <ModalHeader
          toggle={() => {
            setChangePharmacy(!modal);
          }}
        >
          Change Pharmacy
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm className="form-horizontal">
              <Row>
                <Col lg={6}>
                  <div className="form-member">
                    <Label for="example">
                      {props.props.t("City")}
                      <span
                        style={{
                          color: "#f46a6a",
                          fontSize: "18px",
                          marginLeft: "3px",
                        }}
                      >
                        *
                      </span>
                    </Label>
                    <AvField
                      name="city"
                      className="form-control"
                      placeholder={props.props.t("City")}
                      type="text"
                      value={searchData.city}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          city: e.target.value,
                        });
                      }}
                      required
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className={`form-group ${Config.names.slugName}-select-box`}
                  >
                    <label htmlFor="state">
                      {props.props.t("State")}

                      <span
                        style={{
                          color: "#f46a6a",
                          fontSize: "18px",
                          marginLeft: "3px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <AsyncSelect
                      isClearable={true}
                      required
                      placeholder={props.props.t("Select State")}
                      name="state"
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.abbreviation}
                      className={`${
                        searchData.stateErr && "border border-danger"
                      }`}
                      loadOptions={stateListOptions}
                      cacheOptions
                      defaultOptions
                      value={{ name: searchData.state }}
                      onChange={(val) => {
                        setSearchData({
                          ...searchData,
                          state: val.abbreviation,
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <Label>
                      {props.props.t("Zipcode")}
                      <span
                        style={{
                          color: "#f46a6a",
                          fontSize: "18px",
                          marginLeft: "3px",
                        }}
                      >
                        *
                      </span>
                    </Label>
                    <AvField
                      name="zip"
                      // className={`${
                      //   searchData.zipCodeErr && "border border-danger"
                      // }`}
                      placeholder={props.props.t("Zipcode")}
                      // maxLength={5}
                      value={searchData?.zipCode}
                      required
                      type="number"
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          zipCode: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div style={{ marginTop: "35px" }}>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={SearchPharmacy}
                    >
                      {loader ? (
                        <i className="bx bx-loader bx-spin font-size-18 mr-2 align-middle"></i>
                      ) : (
                        " "
                      )}

                      {props.props.t("Search")}
                    </button>
                  </div>
                </Col>
              </Row>
              <div className="my-3">
                {errorMsg ? dctAlert(3, errorMsg) : ""}
              </div>
              {/* Pharmacy table */}
              <Row>
                <Col lg={12}>
                  <div
                    className={`table-responsive mt-3 ${pharmacyClassName}`}
                    style={{ maxHeight: "400px" }}
                  >
                    {pharmacyList?.length > 0 ? (
                      <Table
                        className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table ${Config.names.slugName}-table-no-header`}
                      >
                        <Tbody>{renderPharmacyList(pharmacyList)}</Tbody>
                      </Table>
                    ) : (
                      <>
                        {err && (
                          <span style={{ color: "red" }}>
                            {" "}
                            {props.props.t("No data found!")}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangePharmacy;
