import React, { Fragment, useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { Col, Row, Form, FormGroup, Alert } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
// import SweetAlert from "react-bootstrap-sweetalert";
import { SweetAlert } from "../../../components/DoctegritySweetAlert/DoctegritySweetAlert";

import {
  createConsultation,
  updateConsultStepwise,
  getSingleConsultation,
  getMemberAndDependents,
  getDraftConsultation,
} from "../../../store/actions";

import { capitalizeWords, loadingMessage } from "../../../helpers/Commons";
import { Config } from "../../../Config";

function Patient(props) {
  let memberAndDependents = props.memberAndDependents;
  let ajaxLoading = false;
  let memberObj = [];
  memberObj = useSelector((state) => state.Member);
  const { loading, message, singleConsult } = memberObj;
  ajaxLoading = loading;

  const [patientStepData, setPatientStepData] = useState({
    patient: null,
    memberId: null,
    dependentId: null,
    consultationType: "phone",
  });
  const [isCreateConsultation, setIsCreateConsultation] = useState(false);
  const [consultType, setConsultType] = useState("phone");
  const [checkValidPatient, setCheckValidPatient] = useState(false);
  const [isActivePatient, setIsActivePatient] = useState(true);

  useEffect(() => {
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      let consultType = split_url ? split_url[3] : "phone";
      setConsultType(consultType);
    }
    props.pageTitle("Who is the consultation for?");
  }, []);

  const handlePatientChange = (data, type) => {
    if (type === "member") {
      setPatientStepData({
        patient: data.firstName + " " + data.lastName,
        memberId: data.id,
        dependentId: "",
        consultationType: consultType ? consultType : "phone",
      });
    }

    if (type === "dependent") {
      if (data.age >= 18) {
        setIsActivePatient(true);
        setCheckValidPatient(true);
        return;
      }
      if (data.status === "inactive") {
        setIsActivePatient(false);
        setCheckValidPatient(true);
        return;
      }

      setPatientStepData({
        patient: data.firstName + " " + data.lastName,
        memberId: data.memberId,
        dependentId: data.id,
        consultationType: consultType ? consultType : "phone",
      });
    }
    setIsCreateConsultation(true);
  };

  const handleSubmit = (e) => {
    if (patientStepData.patient) {
      props.createConsultation(patientStepData, props.history, 2);
    }
  };

  // All dependent list
  const patientsList = (memberAndDependents) => {
    if (!memberAndDependents) return;

    let dependentsObj = memberAndDependents.dependent;
    return dependentsObj && dependentsObj.length > 0
      ? dependentsObj.map((dependent, index) => {
          return (
            <div
              key={index}
              className={`${Config.names.slugName}-patient-list mb-3 font-size-16`}
            >
              <a
                href="#"
                disabled={
                  dependent.age >= 18 || dependent.status === "inactive"
                }
                className={
                  dependent.age >= 18 || dependent.status === "inactive"
                    ? "d-none"
                    : "text-left text-dark"
                }
                onClick={() => {
                  dependent.memberId = memberAndDependents.id;
                  handlePatientChange(dependent, "dependent");
                }}
              >
                <i className="mdi mdi-circle-outline align-middle mr-2"></i>{" "}
                {capitalizeWords(
                  dependent.firstName + " " + dependent.lastName
                )}{" "}
                <span className="">{dependent.age >= 18 ? "*" : ""}</span>{" "}
                <span className="type font-size-12">{dependent.relation} </span>
              </a>
            </div>
          );
        })
      : "";
  };

  return (
    <Fragment>
      <Form>
        {isCreateConsultation ? (
          <SweetAlert
            title={props.t("Please Verify You Understand The Following")}
            success
            showCancel
            closeOnClickOutside={false}
            allowEscape={false}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText={props.t("I Understand")}
            cancelBtnText={props.t("Cancel")}
            onConfirm={() => {
              handleSubmit();
              setIsCreateConsultation(false);
            }}
            onCancel={() => {
              setIsCreateConsultation(false);
            }}
          >
            {props.t(
              "You are about to schedule a diagnostic video consultation with a physician. You are required to have a webcam, speakers and a microphone in order to consult with a physician."
            )}
          </SweetAlert>
        ) : null}

        {checkValidPatient ? (
          <SweetAlert
            title={props.t("Invalid Patient!")}
            warning
            closeOnClickOutside={false}
            allowEscape={false}
            confirmBtnBsStyle="success"
            confirmBtnText={props.t("OK")}
            onConfirm={() => {
              setCheckValidPatient(false);
            }}
          >
            {isActivePatient
              ? props.t(
                  `This Dependent is areldy a Primary member. Can create Consultation using own account.`
                )
              : props.t(
                  `This Patient is not activate yet. Please active the patient to continue.`
                )}
          </SweetAlert>
        ) : null}

        <Row>
          <Col lg="12">
            <FormGroup>
              <Col lg={12}>
                <Row>
                  <Col md={12} className="ml-auto">
                    {memberAndDependents ? (
                      <div
                        className={`${Config.names.slugName}-patient-list mb-3 font-size-16`}
                      >
                        <a
                          href="#"
                          className="text-left text-dark"
                          onClick={() => {
                            handlePatientChange(memberAndDependents, "member");
                          }}
                        >
                          <i className="mdi mdi-circle-outline align-middle mr-2"></i>{" "}
                          {capitalizeWords(
                            memberAndDependents.firstName +
                              " " +
                              memberAndDependents.lastName
                          )}
                        </a>
                      </div>
                    ) : (
                      <div className="">{loadingMessage("Loading...")}</div>
                    )}
                    <div className="dependents">
                      {patientsList(memberAndDependents)}
                    </div>
                  </Col>
                </Row>
                {/* <p className="font-size-13 mt-3 alert alert-info text-dark">
                  {props.t(
                    "* Dependent is over 18 and must manage their own records."
                  )}
                </p> */}
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { memberAndDependents, draftConsult, consultation, singleConsult } =
    state.Member;
  return {
    memberAndDependents,
    draftConsult,
    consultation,
    singleConsult,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createConsultation,
    updateConsultStepwise,
    getSingleConsultation,
    getMemberAndDependents,
    getDraftConsultation,
  })(withNamespaces()(Patient))
);
