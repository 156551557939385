import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import axios from "axios";

import { setSingleMember, setMemberListing } from "../../../store/actions";

import { Row, Col, UncontrolledTooltip } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import {
  dctUpdateStatus,
  dctSyncWithDoseSpot,
  checkAjaxError,
} from "../../../helpers/backend_helper";
import { loadingMessage, capitalizeWords } from "../../../helpers/Commons";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import { BarLoader, BeatLoader } from "react-spinners";
import { Config } from "./../../../Config";
import { useHistory } from "react-router-dom";
import CreateUpdateDependent from "../../Dependent/Admin/CreateUpdateDependent";

const MemberList = (props) => {
  const {
    currentPage,
    perPage,
    totalPages,
    totalItems,
    getDataOnEnter,
    ajaxLoader,
    searchInput,
  } = props;

  let membersObj = [];
  const history = useHistory();
  const type = props.type !== undefined ? props.type : "member";
  membersObj = useSelector((state) => state.Member.memberListing);
  membersObj = membersObj ? membersObj : [];
  const dispatch = useDispatch();

  const [customPageNo, setCustomPageNo] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);
  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const memberDetails = useSelector((state) => state.Member.singleMember);
  const [memberStatusMeta, setMemberStatusMeta] = useState({
    rowIndex: "",
    memberId: "",
    memberStatus: 0,
  });
  const [dModal, setDModal] = useState(false);
  const [index, setIndex] = useState(null);
  const [id, setId] = useState(null);

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Check if the current month and day are before the birth month and day
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    // sleep()
  }, []);

  const handleRedirectToMember = (e) => {
    history.push("/member-overview/id/" + e);
  };

  const handleRedirectToDependent = (memberId, dependentId) => {
    history.replace(
      `/member-overview/id/${memberId}/dependent-overview/${dependentId}`
    );
  };

  //checking
  // membersObj = membersObj.sort((a, b) => a.userId?.lastName.localeCompare(b.userId?.lastName))
  const openMemberEditModal = (data) => {
    document.querySelector("#member_update_modal").click();
    //Push Single Member Data to store
    dispatch(setSingleMember(data, "", ""));
  };

  const toggleMemberStatus = async (memberStatusMeta) => {
    if (!memberStatusMeta) return;

    const { memberId, memberStatus, rowIndex } = memberStatusMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Activation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    const loaderCls = ["bx-loader", "bx-spin", "font-size-18"];
    let toogleUserCls =
      memberStatus === "active" ? "bx-user-check" : "bx-user-x";
    setStatusLoader(true);

    // Adding the bx-loader and removing the box-user-check class
    document
      .querySelector("#status_" + memberId)
      .children[0].classList.add(...loaderCls);
    document
      .querySelector("#status_" + memberId)
      .children[0].classList.remove(toogleUserCls);

    let gStatus = memberStatus === "active" ? "inactive" : "active";
    const postData = {
      id: memberId,
      status: gStatus,
    };
    // const url = "member/" + memberId;
    const url = `member/${memberId}/update-status`;
    const response = await dctUpdateStatus(postData, url).then((data) => data);
    if (response.status === "active") {
      setdynamic_description("Activated successfully.");
      setdynamic_title("Activated");
    }
    if (response.status === "inactive") {
      setdynamic_description("Deactivated successfully.");
      setdynamic_title("Deactivated");
    }

    if (response.id) {
      seterror_dlg(false);
    } else {
      seterror_dlg(true);
      setsuccess_dlg(false);
    }

    membersObj[rowIndex].status = response.status;
    dispatch(setMemberListing(membersObj, null));
    setStatusLoader(false);
    toogleUserCls =
      response.status === "active" ? "bx-user-check" : "bx-user-x";
    document
      .querySelector("#status_" + memberId)
      .children[0].classList.remove(...loaderCls);
    document
      .querySelector("#status_" + memberId)
      .children[0].classList.add(toogleUserCls);
  };

  const syncWithDoseSpot = async (memberSyncMeta) => {
    const { memberId, rowIndex } = memberSyncMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Sync request sent...");
    setdynamic_description("Please wait while it's processeing!");

    const postData = {};
    const url = `member/${memberId}/registration`;
    const response = await dctSyncWithDoseSpot(postData, url).then(
      (data) => data
    );
    if (response.success) {
      setdynamic_description("Synced successfully.");
      setdynamic_title("Synced");
      seterror_dlg(false);
      setsuccess_dlg(true);
      if (
        response.data.dosespotPatientId &&
        response.data.dosespotPatientId != ""
      ) {
        membersObj[rowIndex].dosespotPatientId =
          response.data.dosespotPatientId;
        dispatch(setMemberListing(membersObj, null));
      }
    } else {
      setdynamic_title("Sync request failed...");
      setdynamic_description(response.message);
      seterror_dlg(true);
      setsuccess_dlg(false);
    }
  };

  const memberItemsBody = (membersObj) => {
    return membersObj.length > 0 ? (
      membersObj.map((member, index) => {
        const age = calculateAge(member.dob);
        return (
          <Tr
            key={index}
            data-row-id={member.id}
            className={
              member?.mtmGroupCode
                ? `${Config.names.slugName}-row-left-border`
                : ""
            }
          >
            {(member?.dependent && member.dependent.length >= 0) ||
            (member.companyId && age >= 18) ? (
              <Td className="text-center">
                <div className="avatar-xs">
                  <Link
                    to={"/member-overview/id/" + member.id}
                    className="text-dark"
                  >
                    <span className="avatar-title rounded-circle text-capitalize">
                      {member?.userId != undefined
                        ? member?.userId.firstName.charAt(0)
                        : member?.firstName.charAt(0)}
                    </span>
                  </Link>
                </div>
              </Td>
            ) : (
              <Td className="text-center">
                <div className="avatar-xs">
                  <Link
                    to={`/member-overview/id/${member?.memberId?.id}/dependent-overview/${member.id}`}
                    className="text-dark"
                  >
                    <span className="avatar-title rounded-circle text-capitalize">
                      {member?.userId != undefined
                        ? member?.userId.firstName.charAt(0)
                        : member?.firstName.charAt(0)}
                    </span>
                  </Link>
                </div>
              </Td>
            )}
            <Td
              className="pt-3 text-left cursor-pointer text-dark"
              onClick={
                (member?.dependent && member.dependent.length >= 0) ||
                (member.companyId && age >= 18)
                  ? () => handleRedirectToMember(member?.id)
                  : () =>
                      handleRedirectToDependent(member?.memberId?.id, member.id)
              }
            >
              <h5
                className="text-truncate font-size-14"
                style={{ paddingTop: "4px" }}
              >
                {member.userId != undefined
                  ? capitalizeWords(member.userId.firstName)
                  : member?.firstName}
              </h5>
            </Td>
            <Td
              className="pt-3 text-left cursor-pointer text-dark"
              onClick={
                (member?.dependent && member.dependent.length >= 0) ||
                (member.companyId && age >= 18)
                  ? () => handleRedirectToMember(member?.id)
                  : () =>
                      handleRedirectToDependent(member?.memberId?.id, member.id)
              }
            >
              <h5
                className="text-truncate font-size-14"
                style={{ paddingTop: "4px" }}
              >
                {member.userId != undefined
                  ? capitalizeWords(member.userId.lastName)
                  : member.lastName}
              </h5>
            </Td>
            <Td className="text-start">
              {member.userId != undefined ? member.userId.email : member.email}
            </Td>
            {/* {member.dependent && ( */}
            <Td className="text-center">
              {member.primaryExternalId ? member.primaryExternalId : ""}
            </Td>
            <Td className="text-center">
              {member.partnerId ? member.partnerId.name : ""}
            </Td>
            {/* )} */}
            <Td className="text-start">{member.primaryPhone}</Td>
            <Td className="text-center">
              <Link
                to="#"
                className={
                  member.status === "active"
                    ? "badge badge-soft-primary font-size-11 m-1"
                    : "badge badge-soft-danger font-size-11 m-1"
                }
              >
                {member.status === "active" ? "Active" : "Inactive"}
              </Link>
            </Td>
            <Td className="text-center">
              {(member?.dependent && member.dependent.length >= 0) ||
              (member.companyId && age >= 18)
                ? "Member"
                : "Dependent"}
            </Td>
            <Td className="text-center">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2">
                  {member.dependent ? (
                    <Link
                      to="#"
                      id={"edit" + member.id}
                      onClick={() => {
                        openMemberEditModal(member);
                      }}
                    >
                      <i className="bx bx-edit-alt"></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={"edit" + member.id}
                      >
                        {props.t(" Edit")}
                      </UncontrolledTooltip>
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      id={"edit" + member.id}
                      onClick={() => {
                        openDependentEditModal(member, index);
                      }}
                    >
                      <i className="bx bx-edit-alt"></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={"edit" + member.id}
                      >
                        {props.t(" Edit")}
                      </UncontrolledTooltip>
                    </Link>
                  )}
                </li>
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"status_" + member.id}
                    data-status={member.status}
                    data-id={member.id}
                    onClick={() => {
                      setconfirm_both(true);
                      setMemberStatusMeta({
                        rowIndex: index,
                        memberId: member.id,
                        memberStatus: member.status,
                      });
                    }}
                  >
                    {member.status === "active" ? (
                      <i className="bx bx-user-check text-success"></i>
                    ) : (
                      <i className="bx bx-user-x text-danger"></i>
                    )}
                    <UncontrolledTooltip
                      placement="top"
                      target={"status_" + member.id}
                    >
                      {member.status ? "Active" : "Inactive"}
                    </UncontrolledTooltip>
                  </Link>
                </li>
                {member.dependent ? (
                  member.country == "US" &&
                  member.companyId?.fulfilment !== "MTM" &&
                  (!member.dosespotPatientId ||
                    member.dosespotPatientId == "") ? (
                    <li className="list-inline-item px-2">
                      <Link
                        to="#"
                        id={"sync_" + member.id}
                        onClick={() => {
                          syncWithDoseSpot({
                            rowIndex: index,
                            memberId: member.id,
                          });
                        }}
                      >
                        <i className="bx bx-sync"></i>
                        <UncontrolledTooltip
                          placement="top"
                          target={"sync_" + member.id}
                        >
                          {props.t(" Sync ")}
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {/* {member.country == "US" &&
                member.companyId?.fulfilment !== "MTM" &&
                (!member.dosespotPatientId ||
                  member.dosespotPatientId == "") ? (
                  <li className="list-inline-item px-2">
                    <Link
                      to="#"
                      id={"sync_" + member.id}
                      onClick={() => {
                        syncWithDoseSpot({
                          rowIndex: index,
                          memberId: member.id,
                        });
                      }}
                    >
                      <i className="bx bx-sync"></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={"sync_" + member.id}
                      >
                        {props.t(" Sync ")}
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                ) : null} */}
              </ul>
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="7" className="text-center"></Td>
      </Tr>
    );
  };

  const handleSearchPage = (e) => {
    if (e.key === "Enter") {
      getDataOnEnter(e.currentTarget.value);
    }
  };
  // Dependency Code
  const [memberData, setMemberData] = useState({
    mloading: true,
    memberDetails: null,
    mid: null,
    mmessage: null,
  });
  //dependent modal
  const openDependentEditModal = (data, index) => {
    dispatch(setSingleMember(data, "", ""));
    setDModal(true);
    setIndex(data);
  };
  //fetch single member data
  const fetchSingleMember = (goup_id) => {
    if (!goup_id) {
      return;
    }
    setId(goup_id);
    let bearer_token = localStorage.getItem("auth_token");

    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/member/${goup_id}?populate=userId,partnerId,dependent`;

    axios
      .get(url, headers)
      .then(function (response) {
        let response_data = response.data;
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(setSingleMember(response_data, message, null));
        setMemberData({
          mloading: false,
          memberDetails: response_data,
          mid: goup_id,
          mmessage: message,
        });
      })
      .catch(function (err) {
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = "No record found.";
          }
        }

        setMemberData({
          mloading: false,
          memberDetails: null,
          mid: null,
          mmessage: message,
        });
        dispatch(setSingleMember(null, message));
      })
      .then(function () {});
  };
  return (
    <React.Fragment>
      <div className="member-list">
        <Row>
          <Col lg="12">
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {error_dlg ? (
              <SweetAlert
                error
                title={dynamic_title}
                onConfirm={() => {
                  seterror_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {confirm_both ? (
              <SweetAlert
                title={props.t("Are you sure?")}
                warning
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="secondary"
                onConfirm={() => {
                  toggleMemberStatus(memberStatusMeta);
                }}
                onCancel={() => {
                  setconfirm_both(false);
                  setsuccess_dlg(false);
                }}
              >
                {props.t("This process will change the current status!")}
              </SweetAlert>
            ) : null}
            <div className="table-responsive">
              <Table
                className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}
              >
                <Thead className="thead-light">
                  <Tr className="text-center">
                    <Th scope="col" style={{ width: "70px" }}>
                      #
                    </Th>
                    <Th scope="col" className="text-left">
                      {props.t("First Name")}
                    </Th>
                    <Th scope="col" className="text-left">
                      {props.t("Last Name")}
                    </Th>
                    <Th scope="col" className="text-left">
                      {props.t("Email")}
                    </Th>
                    {/* {membersObj.some((obj) => obj.dependent) && ( */}
                    {/* <> */}
                    <Th scope="col" className="text-center">
                      {props.t("Member-Id")}
                    </Th>
                    <Th scope="col" className="text-center">
                      {props.t("Partner Name")}
                    </Th>
                    {/* </> */}
                    {/* )} */}
                    <Th scope="col" className="text-left">
                      {props.t("Phone")}
                    </Th>
                    <Th
                      scope="col"
                      className="text-center"
                      style={{ width: "70px" }}
                    >
                      {props.t("Status")}
                    </Th>
                    <Th
                      scope="col"
                      className="text-center"
                      style={{ width: "70px" }}
                    >
                      {props.t("Type")}
                    </Th>
                    <Th
                      scope="col"
                      className="text-center"
                      style={{ width: "70px" }}
                    >
                      {props.t("Action")}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>{memberItemsBody(membersObj)}</Tbody>
              </Table>
              {props.memberListMessage ? (
                <div className="text-center text-danger">
                  <i className="bx bx-search-alt font-size-18 align-middle mr-2"></i>{" "}
                  {props.memberListMessage}{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div
              className={
                totalItems > perPage
                  ? `${Config.names.slugName}-pager d-none`
                  : `${Config.names.slugName}-pager d-none`
              }
            >
              <nav className="d-flex justify-content-center mt-4">
                <ReactPaginate
                  initialPage={currentPage}
                  forcePage={currentPage}
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={totalPages}
                  // itemsCountPerPage={perPage}
                  breakLabel={"..."}
                  label={<input />}
                  breakClassName={"break-me"}
                  onPageChange={props.getUserData}
                  containerClassName={
                    "pagination pagination-rounded align-items-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  activeLinkClassName={"active"}
                />
                <input
                  className="ml-3 text-center"
                  type="text"
                  size="5"
                  placeholder="Go to"
                  value={customPageNo}
                  onChange={(e) => setCustomPageNo(e.currentTarget.value)}
                  onKeyPress={handleSearchPage}
                  style={{ height: "25px" }}
                />
              </nav>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <BeatLoader
            size={12}
            color={Config.colors.pieChartActiveColor}
            loading={ajaxLoader}
          />
        </Row>
        {/* dependent modal */}
        {dModal && (
          <CreateUpdateDependent
            fetchSingleMember={fetchSingleMember}
            dependent_action_type="edit"
            dependent_button_type="edit_icon"
            modal={dModal}
            memberDetails={memberDetails?.dependent}
            index={index}
            setModal={setDModal}
            searchInput={searchInput}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(MemberList));
