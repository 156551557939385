import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import axios from "axios";
import Pusher from "pusher-js";
import { useSelector } from "react-redux";

//Import Components
import MiniWidget from "./mini-widget";
import PushNotification from "./PushNotification";
// import Televisits from "../Televisits/televisits";
import DctHeroBanner from "../../components/Common/DctHeroBanner";
import { dctAlert } from "../../../src/helpers/Commons";

const DashboardDoctor = (props) => {
  const [reports, setReports] = useState([
    { icon: "bx bx-group", title: "Groups", value: 0 },
    { icon: "bx bx-building", title: "Companies", value: 0 },
    { icon: "bx bxs-user-detail", title: "Active Members", value: 0 },
  ]);
  const doctor_rx = JSON.parse(localStorage.getItem("auth_user")).rx;

  const { authDoctorId } = useSelector((state) => state.Login);

  const [notifications, setNotifications] = useState([]);

  const [doctorData, setDoctorData] = useState(null);

  const [pageSize, setPageSize] = useState(
    10 // process.env.REACT_APP_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const loadDoctorData = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_API_PATH}/doctor/${authDoctorId}?populate=false`;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios.get(url, headers).then((response) => {
      setDoctorData(response.data);
    });
    setLoading(false);
  };

  const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_APP_ID}`, {
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
  });

  const subscribePostCreatedNotifications = async () => {
    var postsChannel = pusher.subscribe("posts");
    postsChannel.bind("post-created", function (data) {
      // console.log('post-created', data);
      pushNotification(data.eventId);
    });
  };

  const subscribeConsultConfirmedNotifications = async () => {
    // if (!doctorData || !doctorData.state) return;
    // var consultsChannel = pusher.subscribe(doctorData.state);
    // consultsChannel.bind('consult-confirmed', function (data) {
    //   pushNotification(data.eventId);
    // });
    if (!doctorData || !doctorData.state) return;
    let states = [];
    doctorData.licenseInfo &&
      doctorData.licenseInfo.map((items, index) => {
        let key = Object.keys(items);
        states.push(key[0]);
      });
    let consultsChannel = states.map((channelName) =>
      pusher.subscribe(channelName)
    );
    for (let items of consultsChannel) {
      items.bind("consult-confirmed", function (data) {
        // console.log('consult-confirmed', data);
        pushNotification(data.eventId);
      });
    }
  };

  let localNotifications = [];

  const pushNotification = async (eventId) => {
    let url = `${process.env.REACT_APP_API_PATH}/eventlog/${eventId}`;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios.get(url, headers).then((response) => {
      localNotifications = [response.data, ...localNotifications];
      setNotifications(
        localNotifications.length < 10
          ? localNotifications
          : localNotifications.slice(0, 10)
      );
    });
  };

  const loadNotifications = async () => {
    if (!doctorData) return;
    if (!doctorData.consultation) {
      setError(true);
      setErrorMsg("Unauthorized Access, consultation is not enable")
      return;
    }

    let arr = ["%22posts%22"];
    // doctorData.multiStates && doctorData.multiStates.map((items, index) => {
    //   arr.push("%22"+items.value+"%22")
    // })
    // doctorId----- 5fa3eae8dc86ba3e9df2a949
    if (doctorData.licenseInfo) {
      if (doctorData.id === "5fa3eae8dc86ba3e9df2a949") {
        let states = [
          { label: "Alabama", value: "AL" },
          { label: "Alaska", value: "AK" },
          { label: "American Samoa", value: "AS" },
          { label: "Arizona", value: "AZ" },
          { label: "Arkansas", value: "AR" },
          { label: "Armed Forces Americas", value: "AA" },
          { label: "Armed Forces Pacific", value: "AP" },
          { label: "California", value: "CA" },
          { label: "Colorado", value: "CO" },
          { label: "Connecticut", value: "CT" },
          { label: "Delaware", value: "DE" },
          { label: "District of Columbia", value: "DC" },
          { label: "Federated States of Micronesia", value: "FM" },
          { label: "Florida", value: "FL" },
          { label: "Georgia", value: "GA" },
          { label: "Guam", value: "GU" },
          { label: "Hawaii", value: "HI" },
          { label: "Idaho", value: "ID" },
          { label: "Illinois", value: "IL" },
          { label: "Indiana", value: "IN" },
          { label: "Iowa", value: "IA" },
          { label: "Kansas", value: "KS" },
          { label: "Kentucky", value: "KY" },
          { label: "Louisiana", value: "LA" },
          { label: "Maine", value: "ME" },
          { label: "Marshall Islands", value: "MH" },
          { label: "Maryland", value: "MD" },
          { label: "Massachusetts", value: "MA" },
          { label: "Michigan", value: "MI" },
          { label: "Minnesota", value: "MN" },
          { label: "Mississippi", value: "MS" },
          { label: "Missouri", value: "MO" },
          { label: "Montana", value: "MT" },
          { label: "Nebraska", value: "NE" },
          { label: "Nevada", value: "NV" },
          { label: "New Hampshire", value: "NH" },
          { label: "New Jersey", value: "NJ" },
          { label: "New Mexico", value: "NM" },
          { label: "New York", value: "NY" },
          { label: "North Carolina", value: "NC" },
          { label: "North Dakota", value: "ND" },
          { label: "Northern Mariana Islands", value: "MP" },
          { label: "Ohio", value: "OH" },
          { label: "Oklahoma", value: "OK" },
          { label: "Oregon", value: "OR" },
          { label: "Palau", value: "PW" },
          { label: "Pennsylvania", value: "PA" },
          { label: "Puerto Rico", value: "PR" },
          { label: "Rhode Island", value: "RI" },
          { label: "South Carolina", value: "SC" },
          { label: "South Dakota", value: "SD" },
          { label: "Tennessee", value: "TN" },
          { label: "Texas", value: "TX" },
          { label: "Utah", value: "UT" },
          { label: "Vermont", value: "VT" },
          { label: "Virgin Islands", value: "VI" },
          { label: "Virginia", value: "VA" },
          { label: "Washington", value: "WA" },
          { label: "West Virginia", value: "WV" },
          { label: "Wisconsin", value: "WI" },
          { label: "Wyoming", value: "WI" },
        ]
        states.forEach((val) => {
          arr.push(JSON.stringify(val.value))
        })
        // console.log("final-----", arr)

      }
      else {
        let licenseInfo = doctorData.licenseInfo;
        licenseInfo.map((items) => {
          Object.keys(items).map(function (key) {
            arr.push("%22" + key + "%22");
          });
        });
      }

    } else {
      arr.push("%22" + doctorData.state + "%22");
    }

    setLoading(true);
    let nin = ["%22prescription-filled%22"];
    let where = `{"isActive":true,"eventType":{"nin": [${nin}]}, "channelName":{"in": [${arr}]}}`;
    let url =
      `${process.env.REACT_APP_API_PATH}/eventlog?limit=${pageSize}&page=${currentPage}` +
      `&where=${where}&populate=false&sort=createdAt%20DESC&consultationData=true`;
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    axios
      .get(url, headers)
      .then((response) => {
        if (response.data.data) {
          localNotifications = [...response.data.data];
          setNotifications(localNotifications);
        }
        if (response.data.total) {
          setTotalPages(Math.ceil(response.data.total / pageSize));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("Could not fetch eventlog. Error -> ", error.message);
        setLoading(false);
        return;
      });
  };

  // const subscribeReserved = async () => {
  //   let reservedChannel = pusher.subscribe('reserved');
  //   reservedChannel.bind('eventlog-reserved', function (data) {
  //     console.log('eventlog-reserved -> ', data);
  //     updateNotification(data.eventId, data.servedBy);
  //   });
  //   //console.log('subscribed channel -> ', 'reserved');
  // };

  // const subscribeReleased = async () => {
  //   let releasedChannel = pusher.subscribe('released');
  //   releasedChannel.bind('eventlog-released', function (data) {
  //     console.log('eventlog-released -> ', data);
  //     updateNotification(data.eventId, data.servedBy);
  //   });
  //   //console.log('subscribed channel -> ', 'released');
  // };

  // const updateNotification = (eventId, servedBy) => {
  //   for (let index = 0; index < notifications.length; index++) {
  //     console.log('notification id -> ', notifications[index].id);
  //     if (notifications[index].id == eventId) {
  //       notifications[index].servedBy = servedBy;
  //       console.log('Reserved -> ', notifications[index]);
  //       break;
  //     }
  //   }
  // };

  useEffect(() => {
    loadNotifications();
  }, [currentPage]);

  useEffect(() => {
    loadDoctorData();
  }, [authDoctorId]);

  useEffect(() => {
    setReports(reports);
  }, [reports]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize]);

  const cleanup = () => {
    pusher.unsubscribe("posts");
    //pusher.unsubscribe('reserved');
    //console.log('unsubscribed channel -> ', 'reserved');
    //pusher.unsubscribe('released');
    //console.log('unsubscribed channel -> ', 'released');
    if (doctorData) {
      pusher.unsubscribe(doctorData.state);
    }
  };

  useEffect(() => {
    if (!doctorData) return;
    loadNotifications();
    subscribePostCreatedNotifications();
    subscribeConsultConfirmedNotifications();
    //subscribeReserved();
    //subscribeReleased();
    return cleanup;
  }, [doctorData]);

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <DctHeroBanner
                mainTitle=""
                subTitle=""
                isHideBannerImg={true}
                isInfoBar={false}
                infoBarTitle=""
                isCall911={false}
              ></DctHeroBanner>
            </Col>
          </Row>

          <Row>
            <MiniWidget reports={reports} />
          </Row>

          {error && dctAlert(3, errorMsg)}

          <Row>
            <Col lg={12}>
              {/* {doctor_rx === true ? null : (
                <PushNotification
                  {...props}
                  notifications={notifications}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  //getUserData={getUserData}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  ajaxLoader={loading}
                />
              )} */}

              {!error && (
                <PushNotification
                  {...props}
                  notifications={notifications}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  //getUserData={getUserData}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  ajaxLoader={loading}
                />
              )}

            </Col>
          </Row>

          {/* <Row>
            <Col lg="12">
              <Televisits />
            </Col>
          </Row> */}
        </Container>
      </div>
    </Fragment>
  );
};

export default DashboardDoctor;