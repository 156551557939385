import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { CREATE_SERVICE, UPDATE_SERVICE, GET_SERVICE_LIST } from "./actionTypes";
import {
    serviceActionSuccessful,
    setSingleService,
    createServiceFailed,
    setServiceList,
} from "./actions";

//Include Both Helper File with needed methods
import {
    dctPostRawData,
    dctPutRawData,
    // getServiceListObj,
    dctGetRawData,
} from "../../helpers/backend_helper";
import { Config } from "../../Config";


// Create Service
function* createServiceSaga({ payload: { service, history } }) {
    try {
        const service_data = service;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: {Authorization: bearer_token,
                PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN},
        };
        const response = yield call(
            dctPostRawData,
            process.env.REACT_APP_API_PATH + "/servicelist",
            service_data,
            headers
        );

        document
        .getElementsByClassName("dr-gform-spinner")[0]
        .classList.add("d-none");
        if (response.id) {
            yield put(
                serviceActionSuccessful(response, "Service Created Successfully #1.", 1)
            );
            yield put(
                setSingleService(response, "Service Created Successfully #2.", 1),
            );
            if (document.querySelector(`.${Config.names.slugName}-service-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-service-add-edit-modal .close`).click();
            }
        } else {
            yield put(createServiceFailed(response.message));
        }
    } catch (error) {
        yield put(createServiceFailed(error));
    }
}

// Update Service
function* updateServiceSaga({ payload: { service, history } }) {
    try {
        const service_data = service;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers: {Authorization: bearer_token,
                PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN},
        };
        const response = yield call(
            dctPutRawData,
            process.env.REACT_APP_API_PATH + "/servicelist/"+service_data.id,
            service_data,
            headers
        );

        document
        .getElementsByClassName("dr-gform-spinner")[0]
        .classList.add("d-none");
        if (response.id) {
            yield put(
                serviceActionSuccessful(response, "Service Updated Successfully. #1", 2),
            );
            yield put(
                setSingleService(response, "Service Updated Successfully #2.", 2),
            );
            
            if (document.querySelector(`.${Config.names.slugName}-service-add-edit-modal .close`)) {
                document.querySelector(`.${Config.names.slugName}-service-add-edit-modal .close`).click();
            }
        } else {
            yield put(createServiceFailed(response.message));
        }
    } catch (error) {
        yield put(createServiceFailed(error));
    }
}

// Get Service
function* getServiceListSaga({ payload: { service } }) {
    try {
        const service_data = service;
        let bearer_token = localStorage.getItem("auth_token");
        const headers = {
            headers:{
                Authorization: bearer_token, 
                PartnerAuth:process.env.REACT_APP_PARTNER_AUTH_TOKEN
            },
        };
        
        const response = yield call(
            dctGetRawData,
            process.env.REACT_APP_API_PATH + "/service",
            headers
        );
        
        if (response.id) {
            yield put(
                setServiceList(
                response.result.data,
                "Service data fetched Successfully.",
                ""
                )
            );
        } else {
            yield put(createServiceFailed(response.message));
        }
    } catch (error) {
        yield put(createServiceFailed(error));
    }
}

export function* watchCreateService() {
    yield takeEvery(CREATE_SERVICE, createServiceSaga);
}

export function* watchUpdateService() {
    yield takeEvery(UPDATE_SERVICE, updateServiceSaga);
}
export function* watchGetService() {
    yield takeEvery(GET_SERVICE_LIST, getServiceListSaga);
}

function* serviceSaga() {
    yield all([
        fork(watchCreateService),
        fork(watchUpdateService),
        fork(watchGetService),
    ]);
}

export default serviceSaga;
