import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  capitalizeWords,
  dctHumanReadableDate,
  loadingMessage,
} from "../../helpers/Commons";
import ReactDatePicker from "react-datepicker";
import { withRouter, Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Config } from "../../Config";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

//Style from Reactstrap
import { Container, Row, Col, Card, CardBody } from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  setConsultationListing,
  setMySingleConsult,
} from "../../store/actions";

const ConsultationReport = (props) => {
  const [ajaxLoader, setAjaxLoader] = useState(true);
  const [fromFullDate, setFromFullDate] = useState("");
  const [toFullDate, setToFullDate] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [consultationData, setConsultationData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [ajaxKey, setAjaxKey] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [postsPerPage, setPostsPerPage] = useState(
    process.env.REACT_APP_PER_PAGE
  );
  const {
    Parser,
    transforms: { unwind },
  } = require("json2csv");

  // Fetch consultation data
  const fetchData = (currentPage = 1) => {
    setAjaxKey(false);
    setAjaxLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/consultation` +
          `?sort=createdAt%20DESC&populate=false&page=${currentPage}&limit=${postsPerPage}&doctorName=true `,
        {
          headers: {
            Authorization: localStorage.getItem("auth_token"),
            PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
          },
        }
      )
      .then(function (response) {
        const data = response.data;
        let structured = data.data;
        if (data.total > 30) {
          structured = [...consultationData, ...data.data];
        }
        setConsultationData(structured);
        setAjaxLoader(false);
        setTotalPages(Math.ceil(response.data.total / postsPerPage));
        setAjaxKey(true);
      })
      .catch(function (err) {});
  };
  const [isFormDateSelected, setIsFormDateSelected] = useState(false);
  const [isToDateSelected, setIsToDateSelected] = useState(false);
  const [showWaring, setShowWaring] = useState(false);
  const [waringMessage, setWaringMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleFromDateChange = (date) => {
    setFromFullDate(date);
    setIsFormDateSelected(false);
  };
  const handleToDateChange = (date) => {
    setToFullDate(date);
    setIsToDateSelected(false);
  };

  // Export functions
  const handleExport = () => {
    setExportLoader(true);
    if (fromFullDate?.length == 0) {
      setIsFormDateSelected(true);
      setExportLoader(false);
    }
    if (toFullDate?.length == 0) {
      setIsToDateSelected(true);
      setExportLoader(false);
    }
    fromFullDate?.length == 0 || toFullDate?.length == 0
      ? console.log("Please enter the from and to date")
      : axios
          .get(
            `${
              process.env.REACT_APP_API_PATH
            }/consultation?scriptkey=true&fromdate=${moment(
              fromFullDate
            ).format("MM-DD-YYYY")}&todate=${moment(toFullDate).format(
              "MM-DD-YYYY"
            )}&timezoneUTC=${moment.tz.guess()}`,
            {
              headers: {
                Authorization: localStorage.getItem("auth_token"),
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
              },
            }
          )
          .then(function (response) {
            setExportLoader(false);
            let data = response?.data?.data;
            if (data?.length > 0) {
              const fields = [
                "createdAt",
                "Patient",
                "Primary Concern",
                "Company",
                "State",
                "Doctor",
                "Status",
              ];
              let json2csvParser = new Parser({ delimiter: "\t", fields });
              json2csvParser.opts.fields[0].label = "Consult Date";
              json2csvParser.opts.fields[1].label = "Patient Name";
              json2csvParser.opts.fields[2].label = "Primary Concern";
              json2csvParser.opts.fields[3].label = "Company";
              json2csvParser.opts.fields[4].label = "State";
              json2csvParser.opts.fields[5].label = "Doctor";
              json2csvParser.opts.fields[6].label = "Status";

              const tsv = json2csvParser.parse(data);
              const uid = uuidv4();
              const exportedFileName = uid + ".tsv";

              let blob = new Blob([tsv], { type: "text/csv;charset=utf-8;" });
              let link = document.createElement("a");
              let url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", exportedFileName);
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setToFullDate("");
              setFromFullDate("");
            } else {
              setWaringMessage(response?.data?.message);
              setShowWaring(true);
              setExportLoader(false);
            }
          })
          .catch(function (err) {
            console.log("err------>", err);
            setErrorMessage(err?.message);
            setShowError(true);
            setExportLoader(false);
          });
  };

  useEffect(() => {
    fetchData(currentPage + 1);
  }, [currentPage]);

  // Infinite scroll
  const onScrollToEnd = () => {
    setCurrentPage(currentPage + 1);
  };
  window.onscroll = function () {
    //check if the page has scrolled to the bottom
    let innerHeight =
      window.innerHeight + document.documentElement.scrollTop + 700;
    let offsetHeight = document.documentElement.offsetHeight;
    if (currentPage + 1 === totalPages || consultationData?.length == 0) return;

    if (innerHeight >= offsetHeight) {
      if (ajaxKey) {
        onScrollToEnd();
      }
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="d-flex flex-md-row float-md-right mb-2 ml-10 justify-content-md-end float-sm-left float-left flex-sm-column flex-column justify-content-sm-between align-items-sm-center">
                <Col
                  className="mr-2  d-flex flex-row align-items-center m-1"
                  lg="3"
                >
                  <label
                    htmlFor="dateFrom"
                    className="mb-0 mr-2"
                    style={{ width: "2rem" }}
                  >
                    {props.t("From")}
                  </label>
                  <ReactDatePicker
                    name="dateFrom"
                    className={
                      isFormDateSelected === true
                        ? "form-control border border-danger"
                        : "form-control"
                    }
                    selected={fromFullDate}
                    dateFormat="MM-dd-yyyy"
                    onChange={handleFromDateChange}
                    placeholderText="MM-DD-YYYY"
                    showYearDropdown
                  />
                </Col>
                <Col
                  className="mr-2  d-flex flex-row align-items-center m-1"
                  lg="3"
                >
                  <label
                    htmlFor="dateTo"
                    className="mb-0 mr-2"
                    style={{ width: "2rem" }}
                  >
                    {props.t("To")}
                  </label>
                  <ReactDatePicker
                    name="dateTo"
                    className={
                      isToDateSelected === true
                        ? "form-control border border-danger"
                        : "form-control"
                    }
                    selected={toFullDate}
                    dateFormat="MM-dd-yyyy"
                    onChange={handleToDateChange}
                    placeholderText="MM-DD-YYYY"
                    showYearDropdown
                    maxDate={new Date()}
                  />
                </Col>
                <Col
                  lg="2"
                  className=" d-flex float-right justify-content-md-start justify-content-end justify-content-sm-end m-1"
                >
                  <Link
                    onClick={() => {
                      handleExport();
                    }}
                    to="#"
                    className="popup-form btn btn-primary"
                    id="group_create_modal"
                  >
                    <i className="mdi mdi-file-export-outline mr-1"></i>{" "}
                    {props.t("Export")}
                    {exportLoader && (
                      <i className="bx bx-loader bx-spin font-size-18 align-middle ml-2"></i>
                    )}
                  </Link>
                </Col>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="group-list">
                <div className="table-responsive  ">
                  <Table
                    className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table rx_report `}
                  >
                    <Thead className="thead-light ">
                      <Tr>
                        <Th scope="col" className="text-center">
                          {props.t("Type")}
                        </Th>
                        <Th scope="col" className="text-left">
                          {props.t("Consult Date")}
                        </Th>
                        <Th scope="col" className="text-left">
                          {props.t("Patient")}
                        </Th>
                        <Th scope="col" className="text-left">
                          {props.t("Company")}
                        </Th>
                        <Th scope="col" className="text-left">
                          {props.t("State")}
                        </Th>
                        <Th scope="col" className="text-left">
                          {props.t("Doctor")}
                        </Th>
                        <Th scope="col" className="text-center">
                          {props.t("Main Complaint")}
                        </Th>
                        <Th scope="col" className="text-left">
                          {props.t("Status")}
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {consultationData &&
                        consultationData.map((consultData) => {
                          // if (rx.status === "accepted") {
                          return (
                            <Tr rx_reportscope="row" key={consultData?.id}>
                              <Td className="text-center">
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle text-capitalize">
                                    {consultData?.consultationType ===
                                    "video" ? (
                                      <i className="bx bxs-video"></i>
                                    ) : (
                                      <i className="bx bxs-phone-call"></i>
                                    )}
                                  </span>
                                </div>
                              </Td>

                              <Td className="text-left">
                                {/* {dctHumanReadableDate(consultData?.createdAt)} */}
                                {moment(consultData?.createdAt).format(
                                  "MM-DD-YYYY"
                                )}
                              </Td>

                              <Td className="text-left">
                                {consultData?.patient}
                              </Td>
                              <Td className="text-left">
                                {consultData?.companyId?.companyName}
                              </Td>
                              <Td className="text-left">
                                {consultData?.state_currentState}
                              </Td>
                              <Td className="text-left">
                                {consultData?.doctorName
                                  ? consultData?.doctorName
                                  : "NA"}
                              </Td>
                              <Td className="text-center">
                                {" "}
                                {consultData?.details_chiefcomplaint
                                  ? consultData?.details_chiefcomplaint
                                  : "NA"}
                              </Td>
                              <Td className="text-left">
                                <div
                                  className={
                                    consultData?.mtmConsultationDetails
                                      ? consultData?.mtmConsultationDetails
                                          .status == "Inactive"
                                        ? "badge badge-soft-danger font-size-11 m-1 text-capitalize"
                                        : "badge badge-soft-primary font-size-11 m-1 text-capitalize"
                                      : consultData?.status === "cancelled"
                                      ? "badge badge-soft-danger font-size-11 m-1 text-capitalize"
                                      : "badge badge-soft-primary font-size-11 m-1 text-capitalize"
                                  }
                                >
                                  {consultData?.mtmConsultationDetails
                                    ? consultData?.mtmConsultationDetails
                                        .status == "Inactive"
                                      ? "cancelled"
                                      : consultData?.status
                                    : consultData?.status}
                                </div>
                              </Td>
                            </Tr>
                          );
                          // }
                        })}
                    </Tbody>
                  </Table>
                </div>
                <Row className="d-flex justify-content-center">
                  <BeatLoader
                    size={12}
                    color={Config.colors.pieChartActiveColor}
                    loading={ajaxLoader}
                  />
                </Row>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
      {showWaring && (
        <SweetAlert
          title="Oops!"
          warning
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowWaring(false);
          }}
        >
          {waringMessage}
        </SweetAlert>
      )}
      {showError && (
        <SweetAlert
          title="Error !"
          danger
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowError(false);
          }}
        >
          {errorMessage}
        </SweetAlert>
      )}
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { consultListing, mySingleConsult, message } = state.Member;
  return { consultListing, message, mySingleConsult };
};

export default withRouter(
  connect(mapStatetoProps, { setConsultationListing, setMySingleConsult })(
    withNamespaces()(ConsultationReport)
  )
);

