import {
  CREATE_PERSONAL_INFO,
  UPDATE_PERSONAL_INFO,
  GET_PERSONAL_INFO,
  SET_PERSONAL_INFO,
  SET_PERSONAL_INFO_MSG,
  SET_MEDICATION_INFO,
  GET_MEDICATION_INFO,
  SET_ALLERGIES_INFO,
  GET_ALLERGIES_INFO,
  SET_MEDICAL_HISTORY_INFO,
  GET_MEDICAL_HISTORY_INFO,
  SET_DOCUMENT_INFO,
  GET_DOCUMENT_INFO,
} from "./actionTypes";

const initialState = {
  message: null,
  loading: false,
  personalInfo: null,
};

const healthRecords = (state = initialState, action) => {
  //https://medium.com/welldone-software/track-react-redux-redundant-re-renders-with-why-did-you-render-version-3-9f31f2797057
  // if (state.personalInfo === action.payload) {
  //   return state;
  // }

  switch (action.type) {
    case CREATE_PERSONAL_INFO:
      state = {
        ...state,
        personalInfo: action.payload,
        loading: false,
      };
      break;
    case UPDATE_PERSONAL_INFO:
      state = {
        ...state,
        personalInfo: action.payload,
        loading: false,
      };
      break;

    case GET_PERSONAL_INFO:
      state = {
        ...state,
        personalInfo: action.payload.result,
        loading: false,
      };
      break;

    case SET_PERSONAL_INFO:
      state = {
        ...state,
        personalInfo: action.payload,
        loading: false,
      };
      break;

    case SET_PERSONAL_INFO_MSG:
      state = {
        ...state,
        personalInfoMsg: action.payload,
      };
      break;

    case GET_MEDICATION_INFO:
      state = {
        ...state,
        medicationInfo: action.payload.result,
        loading: false,
      };
      break;

    case SET_MEDICATION_INFO:
      state = {
        ...state,
        medicationInfo: action.payload,
        loading: false,
      };
      break;

    case GET_ALLERGIES_INFO:
      state = {
        ...state,
        allergiesInfo: action.payload.result,
        loading: false,
      };
      break;

    case SET_ALLERGIES_INFO:
      state = {
        ...state,
        allergiesInfo: action.payload,
        loading: false,
      };
      break;

    case GET_MEDICAL_HISTORY_INFO:
      state = {
        ...state,
        medicalHistoryInfo: action.payload.result,
        loading: false,
      };
      break;

    case SET_MEDICAL_HISTORY_INFO:
      state = {
        ...state,
        medicalHistoryInfo: action.payload,
        loading: false,
      };
      break;

    case GET_DOCUMENT_INFO:
      state = {
        ...state,
        documentInfo: action.payload.result,
        loading: false,
      };
      break;

    case SET_DOCUMENT_INFO:
      state = {
        ...state,
        documentInfo: action.payload,
        loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default healthRecords;
