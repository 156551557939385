import React, { Fragment, useState } from "react";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  AvForm,
  AvInput,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import { Config } from "../../../Config";
import {
  AllOtherSymptoms,
  chiefcomplaintList,
  CommonSymptoms,
} from "../../../helpers/dctSymptoms";

const BookConsultationDetails = (props) => {
  const setConsultationData = props.setConsultationData;
  const consultationData = props.consultationData;
  const [detailsYes, setDetailsYes] = useState(false);
  const setIsSymptomsWarningWindow = props?.setIsSymptomsWarningWindow;
  // set the common symptoms data
  const [commonSymptoms, setCommonSymptoms] = useState([]);
  const handleCommonSymptomsChange = (value) => {
    var updatedList = [...commonSymptoms];
    if (value.target.checked) {
      updatedList = [...commonSymptoms, value.target.value];
    } else {
      updatedList.splice(commonSymptoms.indexOf(value.target.value), 1);
    }
    setCommonSymptoms(updatedList);
    setConsultationData({
      ...consultationData,
      commonSymptoms: updatedList,
    });
  };

  // set the other symptoms data
  const [otherSymptoms, setOtherSymptoms] = useState([]);
  const handleOtherSymptomsChange = (value) => {
    var updatedList = [...otherSymptoms];
    if (value.target.checked) {
      updatedList = [...otherSymptoms, value.target.value];
    } else {
      updatedList.splice(otherSymptoms.indexOf(value.target.value), 1);
    }
    setOtherSymptoms(updatedList);
    setConsultationData({
      ...consultationData,
      allOtherSymptoms: updatedList,
    });
  };

  const handleChiefcomplaintChange = (e) => {
    e.persist();
    if (chiefcomplaintList[e.target.selectedIndex].iswarning) {
      setIsSymptomsWarningWindow(true);
    }
  };
  const handleCommonSymptomsChangeWarning = (e) => {
    e.persist();
    if (CommonSymptoms[e.target.attributes.index.nodeValue].iswarning) {
      setIsSymptomsWarningWindow(true);
    }
  };
  const handleAllOtherSymptomsChange = (e) => {
    e.persist();
    if (AllOtherSymptoms[e.target.attributes.index.nodeValue].iswarning) {
      setIsSymptomsWarningWindow(true);
    }
  };
  return (
    <Row>
      <Col lg="12">
        <Row className="mt-2">
          <Col lg={6}>
            <span style={{ display: "none" }}>
              {props.props.t("Cold or Flu")} {props.props.t("Earache")}{" "}
              {props.props.t("Sinus congestion")} {props.props.t("Chills")}{" "}
              {props.props.t("Fever")} {props.props.t("Skin rash")}{" "}
              {props.props.t("Cough")} {props.props.t("Headache")}{" "}
              {props.props.t("Sore throat")} {props.props.t("Diarrhea")}{" "}
              {props.props.t("Nausea, vomiting")}{" "}
              {props.props.t("Urinary problems")}{" "}
              {props.props.t("General malaise")}{" "}
              {props.props.t("Lightheadedness or Dizziness")}{" "}
              {props.props.t("Abdominal pain")} {props.props.t("Foot pain")}{" "}
              {props.props.t("Tired")} {props.props.t("Chest pain")}{" "}
              {props.props.t("Female problems")} {props.props.t("Weak")}{" "}
              {props.props.t("Hypertension (High blood pressure)")}{" "}
              {props.props.t("Male problems")} {props.props.t("Eye problem")}{" "}
              {props.props.t("Loss of consciousness")}{" "}
              {props.props.t("Shortness of breath")} {props.props.t("Backache")}{" "}
              {props.props.t("Please choose one")}{" "}
              {props.props.t("Cold or Flu")} {props.props.t("Abdominal pain")}{" "}
              {props.props.t("Backache")} {props.props.t("Chest pain")}{" "}
              {props.props.t("Chills")} {props.props.t("Cough")}{" "}
              {props.props.t("Diarrhea")} {props.props.t("Earache")}{" "}
              {props.props.t("Female problems")} {props.props.t("Foot pain")}{" "}
              {props.props.t("General malaise")} {props.props.t("Headache")}{" "}
              {props.props.t("Hypertension (High blood pressure)")}{" "}
              {props.props.t("Lightheadedness or Dizziness")}{" "}
              {props.props.t("Loss of consciousness")}{" "}
              {props.props.t("Male problems")}{" "}
              {props.props.t("Nausea, vomiting")}{" "}
              {props.props.t("Shortness of breath")}{" "}
              {props.props.t("Sinus congestion")} {props.props.t("Skin rash")}{" "}
              {props.props.t("Sore throat")} {props.props.t("Tired")}{" "}
              {props.props.t("Urinary problems")} {props.props.t("Weak")}{" "}
              {props.props.t("Multiple")}
            </span>
            <AvField name="step" type="hidden" value="3" />
            <AvField name="id" type="hidden" />
            <AvField name="stepType" type="hidden" value="details" />
            <div className="mb-2 font-weight-medium">
              {props.props.t("Please choose what best describes your problem")}
              <span
                style={{
                  color: "#f46a6a",
                  fontSize: "18px",
                  marginLeft: "3px",
                }}
              >
                *
              </span>
            </div>
            <AvField
              className="custom-select mt-0"
              type="select"
              name="details_chiefcomplaint"
              id="details_chiefcomplaint"
              onChange={(e) => {
                setConsultationData({
                  ...consultationData,
                  chiefComplaint: e.target.value,
                });
                handleChiefcomplaintChange(e);
              }}
              required
            >
              {chiefcomplaintList.map((option, key) => (
                <option
                  data-iswarning={option.iswarning}
                  key={key}
                  value={option.value || ""}
                >
                  {props.props.t(option.label)}
                </option>
              ))}
            </AvField>
          </Col>
          <Col lg={12}>
            <div className="mb-2 font-weight-medium">
              {props.props.t(
                "Please check all other symptoms you are experiencing"
              )}
            </div>
            <Row className={`mt-1 ${Config.names.slugName}-checkbox-group`}>
              <Col lg={6}>
                <h5>
                  <Badge variant="info">
                    {props.props.t("Common Symptoms")}
                  </Badge>{" "}
                </h5>
                <AvCheckboxGroup
                  className={`${Config.names.slugName}-bg-none`}
                  name="details_commonSymptoms"
                  validate={{ min: { value: 1 } }}
                  onChange={handleCommonSymptomsChangeWarning}
                >
                  {CommonSymptoms.map((option, key) => (
                    <AvCheckbox
                      data-iswarning={option.iswarning}
                      key={key}
                      index={key}
                      label={props.props.t(option.label)}
                      value={option.value}
                      onChange={(e) => handleCommonSymptomsChange(e)}
                    />
                  ))}
                </AvCheckboxGroup>
              </Col>

              <Col lg={6}>
                <h5>
                  <Badge variant="info">
                    {props.props.t("All Other Symptoms")}
                  </Badge>{" "}
                </h5>
                <AvCheckboxGroup
                  className={`${Config.names.slugName}-bg-none`}
                  name="details_allOtherSymptoms"
                  validate={{ min: { value: 1 } }}
                  onChange={handleAllOtherSymptomsChange}
                >
                  {AllOtherSymptoms.map((option, key) => (
                    <AvCheckbox
                      data-iswarning={option.iswarning}
                      key={key}
                      index={key}
                      label={props.props.t(option.label)}
                      value={option.value}
                      onChange={(e) => handleOtherSymptomsChange(e)}
                    />
                  ))}
                </AvCheckboxGroup>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <div className="mb-2 font-weight-medium">
              {props.props.t(
                "Please describe the medical condition for which you are scheduling a consultation"
              )}
              <span
                style={{
                  color: "#f46a6a",
                  fontSize: "18px",
                  marginLeft: "3px",
                }}
              >
                *
              </span>
            </div>
            <div className="mt-1">
              <AvField
                type="textarea"
                name="details_description"
                maxLength="225"
                rows="3"
                md="8"
                required
                onChange={(e) =>
                  setConsultationData({
                    ...consultationData,
                    description: e.target.value.trim(),
                  })
                }
                value={consultationData?.description}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <div className="mt-3 mb-1 font-weight-medium">
              {props.props.t("Do you need a medication refill?")}
            </div>
            <div className="d-flex">
              <div className="form-check mr-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="details_refillType"
                  id="refillYes"
                  value="yes"
                  onChange={() => {
                    setConsultationData({
                      ...consultationData,
                      refillType: "yes",
                    });
                    setDetailsYes(true);
                  }}
                />
                <label className="form-check-label" htmlFor="refillYes">
                  {props.props.t(`Yes`)}
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="radio"
                  name="details_refillType"
                  id="refillNo"
                  value="no"
                  defaultChecked
                  onChange={(e) => {
                    setConsultationData({
                      ...consultationData,
                      refillType: "no",
                      medicationRefill: "",
                    });
                    setDetailsYes(false);
                  }}
                />
                <label className="form-check-label" htmlFor="refillNo">
                  {props.props.t(`No`)}
                </label>
              </div>
            </div>
          </Col>
        </Row>
        {detailsYes && (
          <Row>
            <Col lg={12} className="mt-4">
              <div className="mt-2 mb-2 font-weight-medium">
                {props.props.t("Medication Refill")}
                <span
                  style={{
                    color: "#f46a6a",
                    fontSize: "18px",
                    marginLeft: "3px",
                  }}
                >
                  *
                </span>
              </div>
              <Row>
                <Col lg={12}>
                  <p className="mb-2 text-muted font-size-13">
                    {props.props.t(
                      `Name of Medication(s) and Dosing Frequency`
                    )}
                    :
                  </p>
                  <div>
                    <AvField
                      type="textarea"
                      name="details_medicationNameAndFrequency"
                      maxLength="225"
                      rows="3"
                      md="8"
                      required
                      value={consultationData?.medicationRefill}
                      onChange={(e) => {
                        setConsultationData({
                          ...consultationData,
                          medicationRefill: e.target.value.trim(),
                        });
                      }}
                    />
                  </div>
                  <p className="mb-2 mt-2 text-muted font-size-12">
                    {props.props.t(
                      `Non-controlled prescription medication previously prescribed by another physician. Medication dosing frequency.`
                    )}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
export default BookConsultationDetails;
