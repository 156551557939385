import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  // setSingleTelevisit,
  setTelevisitHistoryListing,
} from "../../store/actions";

import { Row, Col } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

//Include Both Helper File with needed methods
import { dctChangeStatus } from "../../helpers/backend_helper";
import { loadingMessage } from "../../helpers/Commons";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
// import VideoCallJsPanel from "../../pages/Dashboard-doctor/VideoCallWindow/VideoCallJsPanel";
// import VoiceCallInterface from "../../pages/Dashboard-doctor/VoiceCallInterface";
import { getAuthIds } from "../../helpers/Commons";
// import televisits from "../../pages/Televisits/televisits";
import moment from "moment";
import { Config } from "../../Config";

const TelevisitList = (props) => {
  const { currentPage, perPage, total, getDataOnEnter, ajaxLoader } = props;

  const [consultRejectData, setConsultRejectData] = useState({
    doctorId: getAuthIds.doctor,
    consultationId: null,
    rowIndex: 0,
  });

  const [consultRejectReasonText, setConsultRejectReasonText] = useState("");

  let televisitsObj = [];

  televisitsObj = useSelector((state) => state.Doctor.televisitHistoryListing);
  televisitsObj = televisitsObj ? televisitsObj : [];
  const dispatch = useDispatch();

  const [customPageNo, setCustomPageNo] = useState("");

  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [televisitStatusMeta, setTelevisitStatusMeta] = useState({
    rowIndex: "",
    televisitId: "",
    televisitStatus: 0,
  });

  const Completionist = () => <span>Timed Out</span>;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  useEffect(() => {
    // sleep()
  }, []);

  const cancelConsultation = async (consultRejectData) => {
    if (!consultRejectData) return;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Cancellation Request Sent...");
    setdynamic_description("Please wait while its processeing!");
    const url = `consultation/${consultRejectData.consultationId}s/rejected`;

    let postData = {
      doctorId: consultRejectData.doctorId,
      message: consultRejectReasonText,
    };

    const response = await dctChangeStatus(postData, url).then((data) => data);
    if (response.success === true) {
      seterror_dlg(false);
      setdynamic_description("Rejected successfully.");
      setdynamic_title("Rejected");

      let rowInd = consultRejectData.rowIndex;
      delete televisitsObj[rowInd];
      dispatch(setTelevisitHistoryListing(televisitsObj, null));
    } else if (response.success === false) {
      seterror_dlg(true);
      setsuccess_dlg(false);
      setdynamic_title(response.message);
    } else {
      seterror_dlg(true);
      setsuccess_dlg(false);
      setdynamic_title(response);
      setdynamic_description("");
    }
  };

  const televisitItemsBody = (televisitsObj) => {
    return televisitsObj.length > 0 ? (
      televisitsObj.map((televisit, index) => {
        return (
          <Tr key={index} data-row-id={televisit.id}>
            <Td>
              <Link
                to={"/doctor/televisits/member/" + televisit.member[0]?._id}
                className="text-dark"
              >
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle text-capitalize">
                    {televisit?.userDetails[0].firstName.charAt(0)}
                  </span>
                </div>
              </Link>
            </Td>
            <Td className="text-left">
              <h5 className="text-truncate font-size-14">
                {televisit?.userDetails[0].firstName +
                  " " +
                  televisit?.userDetails[0].lastName}
              </h5>
            </Td>
            <Td className="text-left">
              <h5 className="text-truncate font-size-14">
                {televisit.totalVisits}
              </h5>
            </Td>
            <Td className="text-left">
              <h5 className="text-truncate font-size-14">
                {moment(televisit.lastVisit).format("MMM Do YY")}
              </h5>
            </Td>

            <Td className="text-left">
              <ul className="list-inline font-size-20 contact-links mb-0"></ul>

              {confirm_both ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="secondary"
                  onConfirm={() => {
                    cancelConsultation(consultRejectData);
                  }}
                  onCancel={() => {
                    setconfirm_both(false);
                    setsuccess_dlg(false);
                  }}
                >
                  <p>
                    {props.t("This process will cancelled the Consultation!")}
                  </p>

                  <textarea
                    type="textarea"
                    name="cancelReason"
                    className="form-control"
                    onChange={(e) => {
                      setConsultRejectReasonText(e.target.value);
                    }}
                  />
                </SweetAlert>
              ) : null}
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="6" className="text-center"></Td>
      </Tr>
    );
  };
  const handleSearchPage = (e) => {
    if (e.key === "Enter") {
      getDataOnEnter(e.currentTarget.value);
    }
  };
  return (
    <React.Fragment>
      <div className="televisit-list">
        <Row>
          <Col lg="12">
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {error_dlg ? (
              <SweetAlert
                error
                title={dynamic_title}
                onConfirm={() => {
                  seterror_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {ajaxLoader ? (
              <div className={`${Config.names.slugName}-listing-loader`}>
                {loadingMessage("Loading...")}
              </div>
            ) : (
              ""
            )}
            <div className="table-responsive">
              <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}>
                <Thead className="thead-light">
                  <Tr>
                    <Th scope="col" style={{ width: "70px" }}>
                      #
                    </Th>
                    <Th scope="col" className="text-left">{props.t("Name")}</Th>
                    <Th scope="col" className="text-left">{props.t("No. of visit")}</Th>
                    <Th scope="col" className="text-left">{props.t("Date of visit")}</Th>
                    <Th scope="col" className="text-left">{props.t("Action")}</Th>
                  </Tr>
                </Thead>
                <Tbody>{televisitItemsBody(televisitsObj)}</Tbody>
              </Table>
              {props.televisitListMessage ? (
                <div className="text-center text-danger">
                  <i className="bx bx-search-alt font-size-18 align-middle mr-2"></i>{" "}
                  {props.televisitListMessage}{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className={total > perPage ? `${Config.names.slugName}-pager` : `${Config.names.slugName}-pager d-none`}>
              <nav className="d-flex justify-content-center mt-4">
                <ReactPaginate
                  initialPage={currentPage}
                  forcePage={currentPage}
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={total}
                  itemsCountPerPage={perPage}
                  breakLabel={"..."}
                  label={<input />}
                  breakClassName={"break-me"}
                  onPageChange={props.getUserData}
                  containerClassName={
                    "pagination pagination-rounded align-items-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  activeLinkClassName={"active"}
                />
                <input
                  className="ml-3 text-center"
                  type="text"
                  size="5"
                  placeholder="Go to"
                  value={customPageNo}
                  onChange={(e) => setCustomPageNo(e.currentTarget.value)}
                  onKeyPress={handleSearchPage}
                  style={{ height: "25px" }}
                />
              </nav>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(TelevisitList));
