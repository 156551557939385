import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { Config } from "../../Config";

const Loader = () => {
  localStorage.clear();
  return (
    <Fragment>
      <div className="page-content-loader">
        <Container fluid>
          <div className={`${Config.names.slugName}-center-in-screen`}>
            <span className="dr-page-loader">
              <i className="bx bx-loader bx-spin font-size-20 align-middle"></i>
            </span>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default Loader;
