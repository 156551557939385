import { CREATE_PARTNER, UPDATE_PARTNER, GET_PARTNER, GET_PARTNER_LIST, SET_PARTNER_LIST, CREATE_PARTNER_SUCCESS, CREATE_PARTNER_FAILED, API_ERROR, SET_SINGLE_PARTNER, SET_PARTNER_LISTING } from './actionTypes';

const initialState = {
    createPartnerError: null, 
    message: null, 
    loading: false,
    action_type: null
}

const partner = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PARTNER:
            state = {
                ...state,
                partner: action.payload,
                loading: true,
                createPartnerError: null
            }
            break;
        case UPDATE_PARTNER:
            state = {
                ...state,
                partner: action.payload,
                loading: true,
                createPartnerError: null
            }
            break;
        case GET_PARTNER:
            state = {
                ...state,
                partner: action.payload.result,
                loading: true,
                createPartnerError: null
            }
            break;
        case GET_PARTNER_LIST:
            state = {
                ...state,
                partner: action.payload.result,
                loading: true,
                createPartnerError: null
            }
            break;
        case SET_SINGLE_PARTNER:
            state = {
                ...state,
                partner: null,
                singlePartner: action.payload,
                loading: false,
                createPartnerError: null,
                action_type: action.action_type,
            }
            break;
        case SET_PARTNER_LIST:
            state = {
                ...state,
                loading: false,
                partner: action.payload,
                message: action.message,
                createPartnerError: null,
                action_type: action.action_type,
            }
            break;
        case SET_PARTNER_LISTING:
            state = {
                ...state,
                loading: false,
                partner: null,
                partnerListing: action.payload,
                message: action.message,
                createPartnerError: null,
            }
            break;    
        case CREATE_PARTNER_SUCCESS:
            state = {
                ...state,
                loading: false,
                partner: action.payload,
                message: action.message,
                createPartnerError: null,
                action_type: action.action_type,
            }
            break;
        case CREATE_PARTNER_FAILED:
            state = {
                ...state,
                partner: null,
                createPartnerError: action.payload,
                loading: false,
            }
            break;
        case API_ERROR:
            state = { 
                ...state, 
                error: action.payload, 
                loading: false 
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default partner;


