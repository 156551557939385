import {
  GET_SINGLE_PHARMACY,
  GET_PHARMACY_LIST,
  SET_SINGLE_PHARMACY,
  SET_PHARMACY_LIST,
  SET_PHARMACY_MARKERS,
} from "./actionTypes";

const initialState = {
  message: null,
  loading: false,
};

const pharmacy = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_PHARMACY:
      state = {
        ...state,
        pharmacy: action.payload.result,
        loading: true,
      };
      break;
    case GET_PHARMACY_LIST:
      state = {
        ...state,
        pharmacy: action.payload.result,
        loading: true,
      };
      break;
    case SET_SINGLE_PHARMACY:
      state = {
        ...state,
        pharmacy: null,
        singlePharmacy: action.payload,
        loading: false,
      };
      break;
    case SET_PHARMACY_LIST:
      state = {
        ...state,
        loading: false,
        pharmacy: action.payload,
        message: action.message,
      };
      break;
    case SET_PHARMACY_MARKERS:
      state = {
        ...state,
        loading: false,
        markers: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default pharmacy;
