import React, { useState, useEffect } from "react";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Progress,
} from "reactstrap";

import Dropzone from "react-dropzone";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import axios from "axios";
import { checkAjaxError } from "../../helpers/backend_helper";

// actions
import { editProfile } from "../../store/actions";
import PasswordManagement from "./PasswordManagement";

import { dctGetDosespotItems } from "../../helpers/backend_helper";
import { dctDefaultAvatar, capitalizeWords } from "../../helpers/Commons";

import { setProfilePhoto } from "../../store/actions";

const UserProfile = (props) => {
  const [menu, setMenu] = useState(false);
  const [modal, setmodal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [uploadPercent, setuploadPercent] = useState({ percent: 0 });
  const [sendNotification, setSendNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const dispatch = useDispatch();
  const { percent } = uploadPercent;
  const { authUser } = useSelector((state) => state.Login);
  const { profilePhoto } = useSelector((state) => state.Profile);
  const [localData, setLocalData] = useState(
    JSON.parse(localStorage.getItem("auth_user"))
  );
  useEffect(() => {
    getUserInfoById();
  }, []);

  // default value for checkbox
  const defaultValues = {
    emailNotificationType:
      localData?.consultationNotificationEnabled === true ? "true" : "false",
  };

  const getUserInfoById = async () => {
    let url = `user/${authUser.id}/?populate=false`;
    const response = await dctGetDosespotItems(url, "").then((data) => data);
    if (response?.avatarUrl) {
      dispatch(setProfilePhoto(response.avatarUrl));
    }
  };
  //update local storage  data
  const updateLocalData = (value) => {
    localData.consultationNotificationEnabled = value;
    localStorage.setItem("auth_user", JSON.stringify(localData));
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  const updateProfilePhoto = (data) => {
    if (!data) return;

    let files = selectedFiles;
    if (!files[0]) return;

    setLoading(true);

    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
      onUploadProgress: (progressEvent) => {
        var percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percent >= 100) {
          setuploadPercent({ percent: 100 });
        } else {
          setuploadPercent({ percent });
        }
      },
    };

    let bodyFormData = new FormData();
    bodyFormData.append("avatar", files[0]);
    let url = `${process.env.REACT_APP_API_PATH}/user/${authUser.id}/avatar`;

    axios
      .patch(url, bodyFormData, headers)
      .then(function (response) {
        if (response.data.id) {
          if (response.data.avatarUrl)
            dispatch(setProfilePhoto(response.data.avatarUrl));
        } else {
          setuploadPercent({ percent: 0 });
        }
        //SET STORE TO update the profile data
        setLoading(false);
        setmodal(!modal);
      })
      .catch(function (err) {
        console.log(err);
        setuploadPercent({ percent: 0 });
        checkAjaxError(err);
        setLoading(false);
      })
      .then(function () {});
  };

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function toggleModalClose(e) {}

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    updateProfilePhoto(values);
  }
  // Send email
  async function handleEmailSubmit(event, values) {
    if (authUser?.id) {
      const url = `${process.env.REACT_APP_API_PATH}/user/${authUser?.id}`;
      let bearer_token = localStorage.getItem("auth_token");
      let data = {
        consultationNotificationEnabled: values?.emailNotificationType,
      };
      if (!data && !url) {
        return;
      } else {
        return await axios
          .patch(url, data, {
            headers: {
              Authorization: bearer_token,
              PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
          })
          .then((response) => {
            if (response.data) {
              updateLocalData(response?.data?.consultationNotificationEnabled);
              setSendNotification(true);
              setTimeout(() => {
                setSendNotification(false);
              }, 3000);
              return response?.data;
            }
            throw response.data;
          })
          .catch((err) => {
            setErrorNotification(true);
            setTimeout(() => {
              setErrorNotification(false);
            }, 3000);
            var message = checkAjaxError(err);
            return message;
          });
      }
    } else {
      setErrorNotification(true);
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success && props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <Dropdown
                    isOpen={menu}
                    toggle={() => setMenu(!menu)}
                    className="float-right ml-2"
                  >
                    <DropdownToggle tag="i" className="text-muted">
                      <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        href="#"
                        onClick={() => {
                          setmodal(!modal);
                        }}
                      >
                        <i className="bx bx-edit-alt mr-2"></i>{" "}
                        <span>{props.t("Edit Profile Photo")}</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Media>
                    <div className="mr-3">
                      <img
                        src={profilePhoto ? profilePhoto : dctDefaultAvatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                        onClick={() => {
                          setmodal(!modal);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>
                          {capitalizeWords(authUser.firstName)}{" "}
                          {capitalizeWords(authUser.lastName)}
                        </h5>
                        <p className="mb-1">{authUser.email}</p>
                        <p className="mb-0">
                          {props.t(`Id`)}: #{authUser.id}
                        </p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <PasswordManagement></PasswordManagement>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Check box for sent email */}
          {sendNotification && (
            <Alert color="success" style={{ color: "#34c38f" }}>
              Email Notification updated
            </Alert>
          )}
          {errorNotification && (
            <Alert color="danger" style={{ color: "#f46a6a" }}>
              Error in update email notification
            </Alert>
          )}
          {authUser?.userType === "admin" ? (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleEmailSubmit(e, v);
                      }}
                      model={defaultValues}
                    >
                      <AvRadioGroup
                        name="emailNotificationType"
                        label="Consultation Email Notification"
                      >
                        <div>
                          <AvRadio label="Yes" value="true" />
                        </div>
                        <div className="mt-2">
                          <AvRadio label="No" value="false" />
                        </div>
                      </AvRadioGroup>
                      <Row className="form-group">
                        <Col>
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            <span className="dr-spinner d-none">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                            </span>
                            {props.t(`Save`)}
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Container>
      </div>
      <React.Fragment>
        <Modal
          size="lg"
          centered={true}
          isOpen={modal}
          toggle={() => {
            setmodal(!modal);
          }}
          scrollable={true}
          onClosed={(e) => {
            toggleModalClose(e);
          }}
        >
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            {props.t(`Update Profie Photo`)}
          </ModalHeader>
          <ModalBody>
            <Container>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <AvField name="id" type="hidden" value={authUser.id} />
                <Row>
                  <Col>
                    <Dropzone
                      accept={process.env.REACT_APP_ACCEPTED_IMG_FILES}
                      maxSize={parseInt(
                        process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE
                      )}
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                            </div>
                            <h4>
                              {props.t("Drop files here or click to upload")}.
                            </h4>
                            <div className="font-size-14 text-danger">
                              {props.t(
                                "Only *.jpeg, *.jpg, *.png and *.gif files will be accepted."
                              )}
                            </div>
                            <p className="font-size-14 text-danger">
                              {props.t("Max file 20MB.")}
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="mt-3 d-none">
                      <Progress color="primary" value={percent}>
                        {percent}%
                      </Progress>
                    </div>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <div
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  {f.type === "text/csv" ? (
                                    <h2>
                                      <i className="bx bx bx-file mr-1"></i>
                                    </h2>
                                  ) : (
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  )}
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setmodal(!modal)}
                      >
                        {props.t("Cancel")}
                      </button>
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        {loading ? (
                          <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                        ) : null}
                        {props.t(`Save`)}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </Container>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile })(withNamespaces()(UserProfile))
);
