import React from "react";
import { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Config } from "../../../../Config/Config";
import { EmailFormatter } from "../../service/emailFormatter";
import { CheckIcon } from "@heroicons/react/solid";

const IdentifySuccess = (props) => {
  const setAlert = () => {
    props.setAlertData({
      ...props.alertData,
      type: "success",
      title: "Success",
      message:
        "An email has been sent. If you don't see the email right away, Please refresh your inbox and check your spam. Then follow the instructions.",
    });
    props.setShowAlert(true);
  };
  useEffect(() => {
    setAlert();
  }, []);

  return (
    <>
      <Col
        lg="5"
        md="6"
        sm="8"
        xs="10"
        className={`${Config.names.slugName}-box-shadow rounded-lg mt-3 px-4 py-3`}
      >
        <div className="d-flex align-items-center justify-content-center mt-5">
          <div className="d-flex flex-column justify-content-center align-items-center h-auto">
            <div
              className="d-flex justify-content-center align-items-center rounded-lg position-relative"
              style={{
                height: "3rem",
                width: "3rem",
                marginLeft: "0.8rem",
                background: "#EEF1FF",
              }}
            >
              <CheckIcon
                className="position-absolute v3-tick-green-color"
                style={{
                  height: "7rem",
                  marginLeft: "1.5rem",
                  marginBottom: "2rem",
                  marginRight: "1rem",
                }}
              />
            </div>
            <div
              className="v3-black-text-color d-flex justify-content-center align-items-center"
              style={{ fontWeight: "500", fontSize: "1.12rem" }}
            >
              Success
            </div>
          </div>
        </div>

        <div style={{ marginTop: "4rem" }} className="d-flex flex-column">
          <p
            className="font-weight-light v3-black-text-color"
            style={{ fontSize: "0.87rem", marginRight: "1rem" }}
          >
            Please check the email below for further instructions:
          </p>
          <div
            className="d-flex border border-primary bg-white rounded-lg"
            style={{
              padding: "0.75rem",
              paddingLeft: "1.25rem",
              marginTop: "1.5rem",
              marginBottom: "1.5rem",
            }}
          >
            <div
              className="font-weight-light v3-black-text-color"
              style={{ width: "90%", fontSize: "0.87rem" }}
            >
              {props.email ? EmailFormatter(props.email) : ""}
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default IdentifySuccess;
