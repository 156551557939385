import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";
import { showDependentData } from "../../../store/actions";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Media,
  Row,
} from "reactstrap";
import axios from "axios";
import { checkAjaxError } from "../../../helpers/backend_helper";
import {
  capitalizeWords,
  dctDateFormat,
  dctHumanReadableGlobalFormat,
  loadingMessage,
} from "../../../helpers/Commons";
import MedicalHistory from "../../../components/Member/Admin/MedicalHistory";
import MedicationAllergy from "../../../components/Member/Admin/MedicationAllergy";
import PersonalInformation from "../../../components/Member/Admin/PersonalInformation";
import MedicationRecord from "../../../components/Member/Admin/MedicationRecord";
import Documents from "../../../components/Member/Admin/Documents";
import MyConsultations from "../../../components/Member/Admin/MyConsultations";
import moment from "moment";

const DependentOverview = (props) => {
  const getIdFromUrl = () => {
    const urlParts = window.location.pathname.split("/");
    const idIndex = urlParts.indexOf("dependent-overview") + 1;
    return urlParts[idIndex];
  };
  const getMemberIdFromUrl = () => {
    const urlParts = window.location.pathname.split("/");
    const idIndex = urlParts.indexOf("dependent-overview") - 1;
    return urlParts[idIndex];
  };

  const ConsultationListing = useSelector(
    (state) => state.Member.consultListing
  );

  const dependentId = getIdFromUrl();

  // console.log('ConsultationListing....',ConsultationListing)
  const filterDependentData = ConsultationListing?.filter((item) => {
    return item.dependentId == dependentId;
  });
  // console.log("filteredData.....", filterDependentData);
  const memberId = getMemberIdFromUrl();
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const goBack = () => {
    history.goBack();
    // history.push("/member-overview/id/" + e);
  };

  const goToPrimaryMember = (e) => {
    history.push("/member-overview/id/" + e);
  };

  //Added Back Button

  const backToMemberListLink = () => {
    return (
      <Button onClick={() => goBack()} className="bg-primary mr-2">
        <i className="mdi mdi-arrow-left mr-1"></i> {props.t("Back To List")}
      </Button>
    );
  };

  const backToPrimaryMember = () => {
    return (
      <Button
        onClick={() => goToPrimaryMember(memberId)}
        className="btn btn-success bg-success mr-2"
      >
        {props.t("Go to Primary Member")}{" "}
      </Button>
    );
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Perform your asynchronous task here using await
        const response = await fetchSingleMember(dependentId);
        // Process the response as needed
      } catch (error) {
        // Handle any errors that occur during the asynchronous task
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function

    // Return the cleanup function
    return () => {
      // Perform any necessary cleanup operations
    };
  }, [dependentId]);

  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const dependentData = useSelector((state) => state.Member.singleDependent);

  const fetchSingleMember = async (dependent_id) => {
    if (!dependent_id) {
      return;
    }
    // setLoading(true);
    setId(dependent_id);
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    // let url = `${process.env.REACT_APP_API_PATH}/member/${dependent_id}?populate=dependent`;
    let url = `${process.env.REACT_APP_API_PATH}/dependent/${dependent_id}`;
    // Create a cancel token source
    const source = axios.CancelToken.source();
    await axios
      .get(url, {
        ...headers,
        cancelToken: source.token, // Assign the cancel token to the request
      })
      .then(function (response) {
        setLoading(false);
        let response_data = response.data;
        response_data = response_data.id ? response_data : null;
        let message = "";
        message = response_data ? null : "No record found.";
        dispatch(showDependentData(response_data, message, null));
      })
      .catch(function (err) {
        setLoading(false);
        var message = checkAjaxError(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = "No record found.";
          }
        }
        dispatch(showDependentData(null, message));
        return;
      })
      .then(function () {});

    // Return the cleanup function
    return () => {
      source.cancel("Request canceled"); // Cancel the request on unmount
    };
  };

  return (
    <div className="page-content">
      {loading ? (
        <div className="text-center">{loadingMessage("Loading...")}</div>
      ) : (
        <Container fluid>
          <Card>
            <CardBody>
              <div
                className="member-details-section"
                data-id={dependentData?.id}
                // key={index}
              >
                {dependentData ? (
                  <>
                    <Row>
                      <Col lg="6">
                        <Media>
                          <div className="avatar-sm mr-4">
                            <span className="avatar-title font-size-20 rounded-circle">
                              {dependentData?.firstName
                                ? dependentData?.firstName.charAt(0)
                                : "D"}
                            </span>
                          </div>
                          <Media className="overflow-hidden" body>
                            <h5 className="font-size-15">
                              {dependentData?.firstName +
                                " " +
                                dependentData?.lastName}
                            </h5>
                            <p className="text-muted">
                              {props.t("Dependent Status")}:
                              <Badge
                                color="primary"
                                className="font-size-12 ml-2"
                              >
                                {dependentData?.status === "active"
                                  ? "Active"
                                  : "Inactive"}
                              </Badge>
                            </p>
                          </Media>
                        </Media>
                      </Col>
                      <Col lg="6">
                        <div className="text-right">
                          {backToPrimaryMember()}
                          {backToMemberListLink()}
                        </div>
                      </Col>
                    </Row>
                    <h5 className="font-size-15 mt-4">
                      {props.t("Dependent Details")} :
                    </h5>
                    <div className="text-muted mt-4">
                      <Row>
                        <Col lg="6">
                          <p>
                            <i className="bx bx-user text-primary mr-1"></i>{" "}
                            <strong>{props.t("Main Contact Name")} : </strong>{" "}
                            <b>
                              {capitalizeWords(
                                dependentData?.firstName +
                                  " " +
                                  dependentData?.lastName
                              )}
                            </b>
                          </p>
                          <p>
                            <i className="bx bx-envelope text-primary mr-1"></i>{" "}
                            <strong>{props.t("Main Contact Email")}: </strong>{" "}
                            {dependentData?.email
                              ? dependentData?.email
                              : "N/A"}
                          </p>
                          <p>
                            <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                            <strong>{props.t("Main Contact Phone")}: </strong>{" "}
                            {dependentData?.primaryPhone}
                          </p>
                          <p>
                            <i className="bx bx-calendar-check text-primary mr-1"></i>{" "}
                            <strong>{props.t("Date Of Birth")}: </strong>{" "}
                            {moment(dependentData?.dob,"MM-DD-YYYY").format("MM-DD-YYYY")} 
                          </p>
                          <p>
                            <i className="bx bx-user text-primary mr-1"></i>{" "}
                            <strong>{props.t("Member Id")}: </strong>{" "}
                            {dependentData?.memberId}
                          </p>

                          {dependentData?.companyId?.fulfilment === "MTM" && (
                            <p>
                              <i className="bx bx-user text-primary mr-1"></i>{" "}
                              <strong>{props.t("MTM Dependent Id")}: </strong>{" "}
                              {dependentData?.mtmDependentUserId}
                            </p>
                          )}
                        </Col>
                        <Col lg="6">
                          <p>
                            <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                            <strong>{props.t("Dependent Type")}: </strong>{" "}
                            {dependentData?.dependentType}
                          </p>
                          <p>
                            <i className="bx bx-mobile text-primary mr-1"></i>{" "}
                            <strong>{props.t("Secondary Phone")}: </strong>{" "}
                            {dependentData?.secondaryPhone
                              ? dependentData?.secondaryPhone
                              : "N/A"}
                          </p>

                          <p>
                            <i className="bx bx-user text-primary mr-1"></i>{" "}
                            <strong>{props.t("Relation")}: </strong>{" "}
                            {dependentData?.relation}
                          </p>
                          <p>
                            <i className="bx bx-user text-primary mr-1"></i>{" "}
                            <strong>{props.t("Gender")}: </strong>{" "}
                            {dependentData?.gender}
                          </p>

                          <p>
                            <i className="bx bxs-map text-primary mr-1"></i>{" "}
                            <strong>{props.t("Address")}: </strong>
                            {dependentData?.address}
                            {dependentData?.addressTwo &&
                              `, ${dependentData?.addressTwo}`}
                            {`,${dependentData?.city}`}, {dependentData?.state},
                            {dependentData?.postalCode}
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <Row className="task-dates">
                      <Col sm="6" xs="6">
                        <div className="mt-4">
                          <h5 className="font-size-14">
                            <i className="bx bx-calendar mr-1 text-primary"></i>{" "}
                            {props.t("Created Date")}
                          </h5>
                          <p className="text-muted mb-0">
                            {dctDateFormat(dependentData?.createdAt)}
                          </p>
                        </div>
                      </Col>

                      <Col sm="6" xs="6">
                        <div className="mt-4">
                          <h5 className="font-size-14">
                            <i className="bx bx-calendar-check mr-1 text-primary"></i>{" "}
                            {props.t("Updated Date")}
                          </h5>
                          <p className="text-muted mb-0">
                            {dctDateFormat(dependentData?.updatedAt)}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <div className="text-center">
                    <div className="text-right">{backToMemberListLink()}</div>
                    <span className="text-center text-danger d-flex justify-content-center align-items-center pb-5">
                      Dependent data not found
                    </span>
                  </div>
                )}
              </div>
            </CardBody>
            <CardBody>
              <h4 className="mb-4 mt-2 font-size-18">
                {props.t("Medication History")}
              </h4>
              {/* Component of medical history */}
              <MedicalHistory
                patientType="dependent"
                activePatientId={dependentId}
              />
            </CardBody>
            <CardBody>
              <h4 className="mb-4 mt-2 font-size-18">
                {props.t("Medication Allergy")}
              </h4>
              {/* Component of Medication Allergy */}
              <MedicationAllergy
                patientType="dependent"
                activePatientId={dependentId}
              />
            </CardBody>
            <CardBody>
              <h4 className="mb-4 mt-2 font-size-18">
                {props.t("Patient Information")}
              </h4>
              {/* Component of  personal information */}
              <PersonalInformation
                patientType="dependent"
                activePatientId={dependentId}
              />
            </CardBody>
            <CardBody>
              <h4 className="mt-2 font-size-18">
                {props.t("Medication Records")}
              </h4>
            </CardBody>
            <MedicationRecord
              patientType="dependent"
              activePatientId={dependentId}
            />
            <CardBody>
              <h4 className="mt-2 mb-4 font-size-18">
                {props.t("Uploaded Documents")}
              </h4>
              {/* Component of Document */}
              <Documents
                patientType="dependent"
                activePatientId={dependentId}
              />
            </CardBody>
            <CardBody>
              <h4 className="mt-2 mb-4 font-size-18">
                {props.t("Consultations History")}
              </h4>
              {/* Component of MyConsultations*/}
              <MyConsultations
                patientType="dependent"
                activePatientId={dependentId}
                pathId={memberId}
              />
            </CardBody>
          </Card>
        </Container>
      )}
    </div>
  );
};

const mapStatedProps = (state) => {
  const { member } = state.Member;
  return { member };
};
export default withRouter(
  connect(mapStatedProps, { showDependentData })(
    withNamespaces()(DependentOverview)
  )
);
