import {
  CREATE_BRAND,
  UPDATE_BRAND,
  GET_BRAND,
  GET_BRAND_LIST,
  SET_BRAND_LIST,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_FAILED,
  API_ERROR,
  SET_SINGLE_BRAND,
  SET_BRAND_LISTING,
} from "./actionTypes";

export const createBrand = (brand) => ({
  type: CREATE_BRAND,
  payload: { brand },
});

export const updateBrand = (brand) => ({
  type: UPDATE_BRAND,
  payload: { brand },
});

export const getBrand = (brand) => ({
  type: GET_BRAND,
  payload: { brand },
});

export const getBrandList = (brand) => ({
  type: GET_BRAND_LIST,
  payload: { brand },
});

export const setBrandList = (brand, message, action) => {
  return {
    type: SET_BRAND_LIST,
    payload: brand,
    message: message,
    action_type: action,
  };
};

export const setSingleBrand = (brand, message, action) => {
  return {
    type: SET_SINGLE_BRAND,
    payload: brand,
    message: message,
    action_type: action,
  };
};

export const setBrandListing = (brand, message) => {
  return {
    type: SET_BRAND_LISTING,
    payload: brand,
    BrandListing: brand,
    message: message,
  };
};

export const brandActionSuccessful = (brand, message, action) => {
  return {
    type: CREATE_BRAND_SUCCESS,
    payload: brand,
    message: message,
    action_type: action,
  };
};

export const createBrandFailed = (error) => {
  return {
    type: CREATE_BRAND_FAILED,
    payload: error,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
