import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { setSingleService } from "../../../store/actions";

import { Row, Col } from "reactstrap";

//Include Both Helper File with needed methods
import { loadingMessage } from "../../../helpers/Commons";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { Config } from "../../../Config";

const PostMessageList = (props) => {
  const {
    currentPage,
    perPage,
    total,
    getDataOnEnter,
    ajaxLoader,
    totalItems,
  } = props;

  let postMessageObj = [];

  postMessageObj = useSelector((state) => state.Service.serviceListing);
  postMessageObj = postMessageObj ? postMessageObj : [];
  const dispatch = useDispatch();

  const [customPageNo, setCustomPageNo] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);

  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [serviceStatusMeta, setServiceStatusMeta] = useState({
    rowIndex: "",
    serviceId: "",
    serviceStatus: 0,
  });

  useEffect(() => {
    // sleep()
  }, []);

  const openServiceEditModal = (data) => {
    document.querySelector("#service_update_modal").click();
    //Push Single Group Data to store
    dispatch(setSingleService(data, "", ""));
  };

  const openCSVuploadModal = (data) => {
    document.getElementById("csv_upload_modal").click();
    //Push Single Group Data to store
    dispatch(setSingleService(data, "", ""));
  };

  const toggleServiceStatus = async (serviceStatusMeta) => {
    if (!serviceStatusMeta) return;

    const { serviceId, serviceStatus, rowIndex } = serviceStatusMeta;

    setconfirm_both(false);
    setsuccess_dlg(true);
    setdynamic_title("Activation Request Sent...");
    setdynamic_description("Please wait while its processeing!");

    const loaderCls = ["bx-loader", "bx-spin", "font-size-18"];
    let toogleUserCls =
      serviceStatus === "active" ? "bx-user-check" : "bx-user-x";
    setStatusLoader(true);

    // Adding the bx-loader and removing the box-user-check class
    document
      .querySelector("#status_" + serviceId)
      .children[0].classList.add(...loaderCls);
    document
      .querySelector("#status_" + serviceId)
      .children[0].classList.remove(toogleUserCls);

    let gStatus = serviceStatus === "active" ? "inactive" : "active";
    const postData = {
      id: serviceId,
      status: gStatus,
    };
    // const url = "servicelist/" + serviceId;
    // const response = await dctUpdateStatus(postData, url).then((data) => data);
    // if (response.status === "active") {
    //   setdynamic_description("Activated successfully.");
    //   setdynamic_title("Activated");
    // }
    // if (response.status === "inactive") {
    //   setdynamic_description("Deactivated successfully.");
    //   setdynamic_title("Deactivated");
    // }

    // if (response.id) {
    //   seterror_dlg(false);
    // } else {
    //   seterror_dlg(true);
    //   setsuccess_dlg(false);
    // }

    // postMessageObj[rowIndex].status = response.status;
    // // dispatch(setGroupListing(null));
    // dispatch(setServiceListing(postMessageObj, null));
    // setStatusLoader(false);
    // toogleUserCls =
    //   response.status === "active" ? "bx-user-check" : "bx-user-x";
    // document
    //   .querySelector("#status_" + serviceId)
    //   .children[0].classList.remove(...loaderCls);
    // document
    //   .querySelector("#status_" + serviceId)
    //   .children[0].classList.add(toogleUserCls);
  };
  const postMessageItemsBody = (postMessageObj) => {
    return postMessageObj.length > 0 ? (
      postMessageObj.map((message, index) => {
        return (
          <Tr key={index} data-row-id={message.id}>
            <Td>
              <Link to={"/member/message-overview/id/" + message.id}>
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle text-capitalize">
                    {message?.question?.charAt(0)}
                  </span>
                </div>
              </Link>
            </Td>
            <Td>
              <Link to={"/member/message-overview/id/" + message.id}>
                <h5 className="text-truncate font-size-14">
                  {message?.question}
                </h5>
              </Link>
            </Td>
            <Td>
              <h5 className="text-truncate font-size-14 text-truncate" style={{maxWidth:"200px"}}>{message.details}</h5>
            </Td>
            <Td>
              <Link
                to="#"
                className={
                  message.status === "inactive"
                    ? "badge text-capitalize badge-soft-danger font-size-11 m-1"
                    : "badge text-capitalize badge-soft-primary font-size-11 m-1"
                }
              >
                {message.status}
              </Link>
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="7" className="text-center"></Td>
      </Tr>
    );
  };
  const handleSearchPage = (e) => {
    if (e.key === "Enter") {
      getDataOnEnter(e.currentTarget.value);
    }
  };
  return (
    <React.Fragment>
      <div className="group-list">
        <Row>
          <Col lg="12">
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {error_dlg ? (
              <SweetAlert
                error
                title={dynamic_title}
                onConfirm={() => {
                  seterror_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {ajaxLoader ? (
              <div className={`${Config.names.slugName}-listing-loader`}>
                {loadingMessage("Loading...")}
              </div>
            ) : (
              ""
            )}
            <div className="table-responsive">
              <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}>
                <Thead className="thead-light">
                  <Tr>
                    <Th scope="col" style={{ width: "70px" }}>
                      #
                    </Th>
                    <Th scope="col">{props.t("Question")}</Th>
                    <Th scope="col">{props.t("Details")}</Th>
                    <Th scope="col" style={{ width: "70px" }}>
                      {props.t("Status")}
                    </Th>
                    {/* <Th scope="col" style={{ width: "70px" }}>
                      {props.t("Action")}
                    </Th> */}
                  </Tr>
                </Thead>
                <Tbody>{postMessageItemsBody(postMessageObj)}</Tbody>
              </Table>
              {props.serviceListMessage ? (
                <div className="text-center text-danger">
                  <i className="bx bx-search-alt font-size-18 align-middle mr-2"></i>{" "}
                  {props.serviceListMessage}{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div
              className={
                totalItems > perPage ? `${Config.names.slugName}-pager` : `${Config.names.slugName}-pager d-none`
              }
            >
              <nav className="d-flex justify-content-center mt-4">
                <ReactPaginate
                  initialPage={currentPage}
                  forcePage={currentPage}
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={total}
                  itemsCountPerPage={perPage}
                  breakLabel={"..."}
                  label={<input />}
                  breakClassName={"break-me"}
                  onPageChange={props.getUserData}
                  containerClassName={
                    "pagination pagination-rounded align-items-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  activeLinkClassName={"active"}
                />
                <input
                  className="ml-3 text-center"
                  type="text"
                  size="5"
                  placeholder="Go to"
                  value={customPageNo}
                  onChange={(e) => setCustomPageNo(e.currentTarget.value)}
                  onKeyPress={handleSearchPage}
                  style={{ height: "25px" }}
                />
              </nav>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(PostMessageList));
