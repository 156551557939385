// Canelo Logos
import logoDark from "../assets/images/canelo/logo-dark.svg";
import logo from "../assets/images/canelo/logo.svg";
import logoLightPng from "../assets/images/canelo/logo-light.png";
import logoLightSvg from "../assets/images/canelo/logo-light.svg";
import logoWhitePng from "../assets/images/canelo/logo-white.png";

// Canelo Images
import _caneloPatientFamily from "../assets/images/canelo/patient-details.jpg";
import caneloLoginBanner from "../assets/images/loginImage.png";
import defaultAvatar from "../assets/images/canelo/default-avatar.svg";

// Doctegrity Logos
import dctLogoDark from "../assets/images/logo-dark.svg";
import dctLogo from "../assets/images/logo.svg";
import dctLogoLightPng from "../assets/images/logo-light.png";
import dctLogoLightSvg from "../assets/images/logo-light.svg";
import dctLoginLogo from "../assets/images/dct-login-logo.png";

// Doctegrity Images
import familyBanner from "../assets/images/dct-family-banner.jpg"; // OLD---> patient-family.svg
import patientFamilyBanner from "../assets/images/dct-family-patient-banner.jpg";
import dctLoginBanner from "../assets/images/dct-login-banner.jpg";
import dctDefaultAvatar from "../assets/images/default-avatar.svg";

// Telaq Logos
import telaLogo from "../assets/images/tela-logo.png";
import telaBrandLogo from "../assets/images/tela-brand-logo.png";

// Telaq Images
import telaFamilyBanner from "../assets/images/tela-family-banner.jpg";
import telaPatientBanner from "../assets/images/tela-family-patient-banner.jpg";
import telaDefaultAvatar from "../assets/images/tela-default-avatar.svg";

// Telemedicine Logos
import telemedicineLogo from "../assets/images/telemedicine-logo.png";
import telemedicineBrandLogo from "../assets/images/telemedicine-brand-logo.png";

// Telemedicine Images
import telemedicineFamilyBanner from "../assets/images/telemedicine-family-banner.jpg";
import telemedicineLoginLogo from "../assets/images/telemedicine-login-logo.png";
import telemedicinePatientBanner from "../assets/images/telemedicine-family-patient-banner.jpg";
import telemedicineDefaultAvatar from "../assets/images/telemedicine-default-avatar.svg";

// HBA eHealth Logos
import hbaehealthLogo from "../assets/images/hba-logo.png";
import hbaehealthBrandLogo from "../assets/images/hba-brand-logo.png";

// HBA eHealth Images
import hbaehealthFamilyBanner from "../assets/images/hba-family-banner.jpg";
import hbaehealthLoginLogo from "../assets/images/hba-login-logo.png";
import hbaehealthPatientBanner from "../assets/images/hba-family-patient-banner.jpg";
import hbaehealthDefaultAvatar from "../assets/images/hba-brand-logo.png";

// CSI Health Logos
import csiHealthLogo from "../assets/images/csiHealth/csihealth-logo.png";
import csiHealthBrandLogo from "../assets/images/csiHealth/csihealth-brand-logo.png";

// CSI Health Images
import csiHealthFamilyBanner from "../assets/images/csiHealth/csihealth-family-banner.jpg";
import csiHealthPatientBanner from "../assets/images/csiHealth/csihealth-family-patient-banner.jpg";

// ZipDoctor Logos
import zipDoctorLogo from "../assets/images/zipDoctor/zipdoctor-logo.png";
import zipDoctorBrandLogo from "../assets/images/zipDoctor/zipdoctor-brand-logo.png";

// Zip Doctor Images
import zipDoctorFamilyBanner from "../assets/images/zipDoctor/zipdoctor-family-banner.jpg";
import zipDoctorPatientBanner from "../assets/images/zipDoctor/zipdoctor-family-patient-banner.jpg";

// GLobally Declared Variables
let slugName;
let webLogoDark;
let webLogo;
let webLogoLightPng;
let webLogoLightSvg;
let webLogoWhitePng;
let webFamilyBanner;
let webPatientFamilyBanner;
let webLoginBanner;
let webLoginLogo;
let webAppName;
let webAppNickName;
let webPrimaryColor;
let webSecondaryColor;
let webAvatar;
let webPieChartActiveColor;
let webPieChartInactiveColor;
// for V3
let supportContact;
let callContact;
let helpMail;

// Canelo
if (process.env.REACT_APP_SLUG === "canelo") {
  slugName = "canelo";
  webAppName = "Canelo Health";
  webAppNickName = "Canelo";
  webLogoDark = logoDark;
  webLogo = logo;
  webLogoLightPng = logoLightPng;
  webLogoLightSvg = logoLightSvg;
  webLogoWhitePng = logoWhitePng;
  webFamilyBanner = _caneloPatientFamily;
  webPatientFamilyBanner = _caneloPatientFamily;
  webLoginBanner = caneloLoginBanner;
  webPrimaryColor = "#1e1e1e";
  webSecondaryColor = "#be2120"; //#ce1715
  webAvatar = defaultAvatar;
  webPieChartActiveColor = "#1e1e1e";
  webPieChartInactiveColor = "#be2120";
}

// Doctegrity
if (process.env.REACT_APP_SLUG === "doctegrity") {
  slugName = "dct";
  webAppName = "Doctegrity";
  webAppNickName = "Doctegrity";
  webLogoDark = dctLogoDark;
  webLogo = dctLogo;
  webLogoLightPng = dctLogoLightPng;
  webLogoLightSvg = dctLogoLightSvg;
  webLogoWhitePng = dctLogoLightPng;
  webFamilyBanner = familyBanner;
  webPatientFamilyBanner = patientFamilyBanner;
  webLoginBanner = dctLoginBanner;
  webLoginLogo = dctLoginLogo;
  webPrimaryColor = "#556ee6";
  webSecondaryColor = "#556ee6";
  webAvatar = dctDefaultAvatar;
  webPieChartActiveColor = "#556ee6";
  webPieChartInactiveColor = "#34c38f";
  //V3
  supportContact = "8011234567";
  callContact = "8773425152";
  helpMail = "hello@doctegrity.com";
}

if (process.env.REACT_APP_SLUG === "telaq") {
  slugName = "tela";
  webAppName = "TelaQ";
  webAppNickName = "TelaQ";
  webLogoDark = telaLogo;
  webLogo = telaBrandLogo;
  webLogoLightPng = telaLogo;
  webLogoLightSvg = dctLogoLightSvg;
  webLogoWhitePng = telaLogo;
  webFamilyBanner = telaFamilyBanner;
  webPatientFamilyBanner = telaPatientBanner;
  webLoginBanner = dctLoginBanner;
  webLoginLogo = telaBrandLogo;
  webPrimaryColor = "#064874";
  webSecondaryColor = "#064874";
  webAvatar = telaDefaultAvatar;
  webPieChartActiveColor = "#064874";
  webPieChartInactiveColor = "#be2120";
  //V3
  supportContact = "8443682088";
  callContact = "8443682088";
  helpMail = "hello@telaq.com";
}

if (process.env.REACT_APP_SLUG === "telemedicine") {
  slugName = "telemedicine";
  webAppName = "Telemedicine";
  webAppNickName = "Telemedicine";
  webLogoDark = telemedicineLogo;
  webLogo = telemedicineBrandLogo;
  webLogoLightPng = telemedicineLogo;
  webLogoLightSvg = dctLogoLightSvg;
  webLogoWhitePng = telemedicineLogo;
  webFamilyBanner = telemedicineFamilyBanner;
  webPatientFamilyBanner = telemedicinePatientBanner;
  webLoginBanner = dctLoginBanner;
  webLoginLogo = telemedicineLoginLogo;
  webPrimaryColor = "#204379";
  webSecondaryColor = "#204379";
  webAvatar = telemedicineDefaultAvatar;
  webPieChartActiveColor = "#204379";
  webPieChartInactiveColor = "#be2120";
  //V3
  supportContact = "8443682088";
  callContact = "8443682088";
  helpMail = "mail@telemedicine.com";
}

//For HBA eHealth
if (process.env.REACT_APP_SLUG === "hbaehealth") {
  slugName = "hbaehealth";
  webAppName = "HBAeHealth";
  webAppNickName = "HBAeHealth";
  webLogoDark = hbaehealthLogo;
  webLogo = hbaehealthBrandLogo;
  webLogoLightPng = hbaehealthLogo;
  webLogoLightSvg = dctLogoLightSvg;
  webLogoWhitePng = hbaehealthLogo;
  webFamilyBanner = hbaehealthFamilyBanner;
  webPatientFamilyBanner = hbaehealthPatientBanner;
  webLoginBanner = dctLoginBanner;
  webLoginLogo = hbaehealthLoginLogo;
  webPrimaryColor = "#0c223e";
  webSecondaryColor = "#0c223e";
  webAvatar = hbaehealthDefaultAvatar;
  webPieChartActiveColor = "#0c223e";
  webPieChartInactiveColor = "#be2120";
  //V3
  supportContact = "8774226331";
  callContact = "8774226331";
  helpMail = "HBAeHealth@doctegrity.com";
}

// CSI Health
if (process.env.REACT_APP_SLUG === "csihealth") {
  slugName = "csihealth";
  webAppName = "CSI Health";
  webAppNickName = "CSI Health";
  webLogoDark = csiHealthLogo;
  webLogo = csiHealthBrandLogo;
  webLogoLightPng = csiHealthLogo;
  webLogoLightSvg = dctLogoLightSvg;
  webLogoWhitePng = csiHealthLogo;
  webFamilyBanner = csiHealthFamilyBanner;
  webPatientFamilyBanner = csiHealthPatientBanner;
  webLoginBanner = dctLoginBanner;
  webLoginLogo = csiHealthBrandLogo;
  webPrimaryColor = "#090756";
  webSecondaryColor = "#090756";
  webAvatar = csiHealthBrandLogo;
  webPieChartActiveColor = "#090756";
  webPieChartInactiveColor = "#34c38f";
  //V3
  supportContact = "8005339230";
  callContact = "8005339230";
  helpMail = "sales@csihealth.net";
}

// ZipDoctor
if (process.env.REACT_APP_SLUG === "zipdoctor") {
  slugName = "zipdoctor";
  webAppName = "ZipDoctor";
  webAppNickName = "ZipDoctor";

  webLogoDark = zipDoctorLogo;
  webLogo = zipDoctorBrandLogo;
  webLogoLightPng = zipDoctorLogo;
  webLogoLightSvg = dctLogoLightSvg;
  webLogoWhitePng = zipDoctorLogo;
  webFamilyBanner = zipDoctorFamilyBanner;
  webPatientFamilyBanner = zipDoctorPatientBanner;
  webLoginBanner = dctLoginBanner;
  webLoginLogo = zipDoctorBrandLogo;
  webPrimaryColor = "#231F20";
  webSecondaryColor = "#231F20";
  webAvatar = zipDoctorBrandLogo;
  webPieChartActiveColor = "#231F20";
  webPieChartInactiveColor = "#34c38f";
  //V3
  supportContact = "8334947362";
  callContact = "8334947362";
  helpMail = "hello@zipdoctor.co";
}

// Export defaults
export const ConfigDefaults = {
  names: {
    slugName: slugName,
    appName: webAppName,
    nickName: webAppNickName,
    supportContact: supportContact,
    callContact: callContact,
    helpMail: helpMail,
  },
  logos: {
    logoDark: webLogoDark,
    logo: webLogo,
    logoLightPng: webLogoLightPng,
    logoLightSvg: webLogoLightSvg,
    loginLogo: webLoginLogo,
    logoWhitePng: webLogoWhitePng,
  },
  images: {
    familyBanner: webFamilyBanner,
    patientFamilyBanner: webPatientFamilyBanner,
    loginBanner: webLoginBanner,
    dctDefaultAvatar: webAvatar,
  },
  colors: {
    primaryColor: webPrimaryColor,
    secondaryColor: webSecondaryColor,
    pieChartActiveColor: webPieChartActiveColor,
    pieChartInactiveColor: webPieChartInactiveColor,
  },
};
