export const CREATE_GROUP = "CREATE_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const GET_GROUP = "GET_GROUP";
export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const SET_GROUP_LIST = "SET_GROUP_LIST";
export const SET_GROUP_LISTING = "SET_GROUP_LISTING";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILED = "CREATE_GROUP_FAILED";
export const SET_SINGLE_GROUP = "SET_SINGLE_GROUP";
export const API_ERROR = "API_ERROR";
