import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { setSingleTelevisit, setTelevisitListing } from "../../store/actions";

import {
  Row,
  Col,
  // Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Include Both Helper File with needed methods
import { dctUpdateStatus } from "../../helpers/backend_helper";
import { loadingMessage } from "../../helpers/Commons";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { getAuthIds } from "../../helpers/Commons";

import axios from "axios";
import moment from "moment";
import { Config } from "../../Config";
const TelevisitListByMember = (props) => {
  const {
    currentPage,
    perPage,
    total,
    getDataOnEnter,
    ajaxLoader,
    fetchTelevisitData,
  } = props;

  const [consultRejectData, setConsultRejectData] = useState({
    doctorId: getAuthIds.doctor,
    consultationId: null,
    rowIndex: 0,
  });

  const [consultRejectReasonText, setConsultRejectReasonText] = useState("");

  let televisitsObj = [];

  televisitsObj = useSelector((state) => state.Doctor.televisitListing);
  televisitsObj = televisitsObj ? televisitsObj : [];
  const dispatch = useDispatch();

  const [customPageNo, setCustomPageNo] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);

  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [televisitStatusMeta, setTelevisitStatusMeta] = useState({
    rowIndex: "",
    televisitId: "",
    televisitStatus: 0,
  });

  const Completionist = () => <span>Timed Out</span>;

  useEffect(() => {
    // sleep()
  }, []);

  const [modal, setModal] = useState(false);
  const [currentNote, setCurrentNote] = useState("");
  const [currentConsultId, setCurrentConsultId] = useState("");
  function toggleModalClose(e) {
    //checking Store props
  }
  // handleValidSubmit
  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-gform-spinner")[0]
      .classList.remove("d-none");
    
    const URL =
      process.env.REACT_APP_API_PATH + "/consultation/" + currentConsultId;

    let bearer_token = localStorage.getItem("auth_token");
    let data = values;
    axios
      .patch(URL, data, {
        headers: {
          Authorization: bearer_token,
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      })
      .then((response) => {
        setModal(false);
        fetchTelevisitData();
      })
      .catch((err) => {
        // var message = checkAjaxError(err);
        // return message;
      });
  }

  const televisitItemsBody = (televisitsObj) => {
    return televisitsObj.length > 0 ? (
      televisitsObj.map((televisit, index) => {
        return (
          <Tr key={index} data-row-id={televisit.id}>
            <Td>
              <div className="avatar-xs">
                <Link
                  to={"/televisits-overview/id/" + televisit.id}
                  className="text-dark"
                  onClick={() => {
                    localStorage.setItem("televisits_location", props.location.pathname);
                  }}
                >
                  <span className="avatar-title rounded-circle text-capitalize">
                    {televisit?.patient.charAt(0)}
                  </span>
                </Link>
              </div>
            </Td>
            <Td>
              <h5 className="text-truncate font-size-14 text-capitalize">
                <Link
                  to={"/televisits-overview/id/" + televisit.id}
                  className="text-dark"
                  onClick={() => {
                    localStorage.setItem("televisits_location", props.location.pathname);
                  }}
                >
                  {televisit.patient}
                </Link>
              </h5>
            </Td>
            <Td>
              <h5 className="text-truncate font-size-14">
                {televisit.state_currentState}
              </h5>
            </Td>
            <Td>{televisit.details_chiefcomplaint}</Td>
            <Td>{moment(televisit.schedule_from).fromNow()}</Td>
            <Td>
              {televisit.consultationType === "phone" ? (
                <i className="bx bxs-phone font-size-14"></i>
              ) : (
                <i className="bx bx-video-recording font-size-14"></i>
              )}
              {televisit.status === "active" ||
              televisit.status === "scheduled" ? (
                <Badge
                  color="success"
                  className="font-size-12 ml-2 text-capitalize"
                >
                  {props.t(televisit.status)}
                </Badge>
              ) : (
                <Badge color="danger" className="font-size-12 ml-2">
                  {props.t(televisit.status)}
                </Badge>
              )}
            </Td>

            {/* <Td>
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2">
                  {televisit.consultationType !== "audio" ? (
                    <VideoCallJsPanel></VideoCallJsPanel>
                  ) : (
                    <VoiceCallInterface addtionalID={index} flag={false} />
                  )}
                </li>
                <li className="list-inline-item px-2">
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      setModal(true);
                      setCurrentNote(televisit.notes[televisit.notes?.length - 1]?.notes);
                      setCurrentConsultId(televisit.id);
                    }}
                  >
                    Notes <i className="bx bx-notepad"></i>
                  </button>
                </li>
                <li className="list-inline-item px-2">
                </li>
                <li className="list-inline-item px-2">
                  <Link
                    to="#"
                    id={"consult_" + televisit.id}
                    data-status={televisit.status}
                    data-id={televisit.id}
                    onClick={() => {
                      setconfirm_both(true);
                      setConsultRejectData({
                        doctorId: televisit.doctorId,
                        message: "",
                        rowIndex: index,
                        consultationId: televisit.id,
                      });
                    }}
                  >
                    <i className="mdi mdi-close-circle-outline text-primary"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"consult_" + televisit.id}
                    >
                      {televisit.status}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>

              {confirm_both ? (
                <SweetAlert
                  title={props.t("Are you sure?")}
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="secondary"
                  onConfirm={() => {
                    cancelConsultation(consultRejectData);
                  }}
                  onCancel={() => {
                    setconfirm_both(false);
                    setsuccess_dlg(false);
                  }}
                >
                  <p>
                    {props.t("This process will cancelled the Consultation!")}
                  </p>

                  <textarea
                    type="textarea"
                    name="cancelReason"
                    className="form-control"
                    onChange={(e) => {
                      setConsultRejectReasonText(e.target.value);
                    }}
                  />
                </SweetAlert>
              ) : null}
            </Td> */}
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="7" className="text-center"></Td>
      </Tr>
    );
  };
  const handleSearchPage = (e) => {
    if (e.key === "Enter") {
      getDataOnEnter(e.currentTarget.value);
    }
  };
  return (
    <React.Fragment>
      <div className="televisit-list">
        <Row>
          <Col lg="12">
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {error_dlg ? (
              <SweetAlert
                error
                title={dynamic_title}
                onConfirm={() => {
                  seterror_dlg(false);
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}

            {ajaxLoader ? (
              <div className={`${Config.names.slugName}-listing-loader`}>
                {loadingMessage("Loading...")}
              </div>
            ) : (
              ""
            )}
            <div className="table-responsive">
              <Table className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table`}>
                <Thead className="thead-light">
                  <Tr>
                    <Th scope="col" style={{ width: "70px" }}>
                      #
                    </Th>
                    <Th scope="col">{props.t("Televisits Name")}</Th>
                    <Th scope="col">{props.t("State")}</Th>
                    <Th scope="col">{props.t("Cheif Complaint")}</Th>
                    <Th scope="col">{props.t("Scheduled time")}</Th>
                    <Th scope="col" style={{ width: "50px" }}>
                      {props.t("Status")}
                    </Th>
                    {/* <Th scope="col" style={{ width: "70px" }}>
                      {props.t("Action")}
                    </Th> */}
                  </Tr>
                </Thead>
                <Tbody>{televisitItemsBody(televisitsObj)}</Tbody>
              </Table>
              {props.televisitListMessage ? (
                <div className="text-center text-danger">
                  <i className="bx bx-search-alt font-size-18 align-middle mr-2"></i>{" "}
                  {props.televisitListMessage}{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className={total > perPage ? `${Config.names.slugName}-pager` : `${Config.names.slugName}-pager d-none`}>
              <nav className="d-flex justify-content-center mt-4">
                <ReactPaginate
                  initialPage={currentPage}
                  forcePage={currentPage}
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={total}
                  itemsCountPerPage={perPage}
                  breakLabel={"..."}
                  label={<input />}
                  breakClassName={"break-me"}
                  onPageChange={props.getUserData}
                  containerClassName={
                    "pagination pagination-rounded align-items-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  activeLinkClassName={"active"}
                />
                <input
                  className="ml-3 text-center"
                  type="text"
                  size="5"
                  placeholder="Go to"
                  value={customPageNo}
                  onChange={(e) => setCustomPageNo(e.currentTarget.value)}
                  onKeyPress={handleSearchPage}
                  style={{ height: "25px" }}
                />
              </nav>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-company-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          Notes
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <AvField
                      name="notes"
                      label={props.t("Notes")}
                      className="form-control"
                      placeholder={props.t("Make a new note")}
                      type="textarea"
                      value={currentNote}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setModal(!modal)}
                    >
                      {props.t("Cancel")}
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                    >
                      <span className="dr-gform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(
  withNamespaces()(TelevisitListByMember)
);
