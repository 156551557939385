export const CREATE_PERSONAL_INFO = "CREATE_PERSONAL_INFO";
export const UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO";
export const GET_PERSONAL_INFO = "GET_PERSONAL_INFO";
export const SET_PERSONAL_INFO = "SET_PERSONAL_INFO";
export const SET_PERSONAL_INFO_MSG = "SET_PERSONAL_INFO_MSG";

export const SET_MEDICATION_INFO = "SET_MEDICATION_INFO";
export const GET_MEDICATION_INFO = "GET_MEDICATION_INFO";
export const SET_ALLERGIES_INFO = "SET_ALLERGIES_INFO";
export const GET_ALLERGIES_INFO = "GET_ALLERGIES_INFO";
export const SET_MEDICAL_HISTORY_INFO = "SET_MEDICAL_HISTORY_INFO";
export const GET_MEDICAL_HISTORY_INFO = "GET_MEDICAL_HISTORY_INFO";
export const SET_DOCUMENT_INFO = "SET_DOCUMENT_INFO";
export const GET_DOCUMENT_INFO = "GET_DOCUMENT_INFO";
