import React, { useState, useEffect, useCallback } from "react";
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Alert,
    Container,
    Label,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";

import {
    CountryDropdown,
    RegionDropdown,
    CountryRegionData,
} from "react-country-region-selector";
import { dctGetAsyncData } from "../../../helpers/backend_helper";
import Select from "react-select";
import { dctAlert } from "../../../helpers/Commons";
import { Config } from "../../../Config";

const ChangePasswordModal = (props) => {
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [cnfPassword, setCnfPassword] = useState('');
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    let memberData = useSelector((state) => state.Member.singleMember);
    const { authUserType, authPartnerId } = useSelector((state) => state.Login);

    if (!memberData) memberData = {}

    const button_label = "Update";
    const modal_title = "Change Password";

    useEffect(() => {
        { memberData && setEmail(memberData?.userId?.email) }
    }, [memberData]);

    const handleSubmit = () => {
        if (password.length < 1 || cnfPassword.length < 1) {
            setErrorMsg('')
            setError(true)
            return;
        }
        if (password !== cnfPassword) {
            setError(true)
            setErrorMsg("New Password and Confirm Password are not same.")
            return;
        }
        setIsLoading(true)
        const url = `${process.env.REACT_APP_API_PATH}/user/update-password`;
        const data = {
            email: email,
            password: password
        }
        const options = {
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
            },
        };
        axios.patch(url, data, options)
            .then((response) => {
                if (response.status === 200) {
                    setModal(!modal);
                    setIsLoading(false);
                    props.change(true);
                }
            })
            .catch(err => { console.log(err); setModal(!modal); setIsLoading(false)});

    }

    const WelcomeModal = () => {
        setErrorMsg(null);
    };

    return (
        <React.Fragment>
            <i
                className="bx bx-edit-alt d-none"
                id="member-password-modal"
                onClick={() => {
                    setModal(!modal);
                }}
            ></i>
            <Modal
                centered={true}
                size="lg"
                isOpen={modal}
                toggle={() => {
                    setModal(!modal);
                }}
                scrollable={true}
                className={`${Config.names.slugName}-member-add-edit-modal`}

            >
                <ModalHeader
                    toggle={() => {
                        setModal(!modal);
                    }}
                >
                    {modal_title}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        {error ? dctAlert(3, errorMsg, false, WelcomeModal) : ""}
                        <Col lg={12}>
                            <div className="form-group">
                                <Label>Email</Label>
                                <input type="text" readOnly value={email} className="form-control" />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="form-group">
                                <Label>New Password</Label>
                                <input type="password" value={password} className={error ? "form-control error" : "form-control"}
                                    onChange={(e) => { setPassword(e.target.value); setError(false) }}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="form-group">
                                <Label>Confirm Password</Label>
                                <input type="password" value={cnfPassword} className={error ? "form-control error" : "form-control"}
                                    onChange={(e) => { setCnfPassword(e.target.value); setError(false) }} />
                            </div>
                        </Col>

                        <Row>
                            <Col lg={12}>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setModal(!modal)
                                            setPassword('');
                                            setCnfPassword('');
                                            setError(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-success waves-effect waves-light"
                                        type="submit"
                                        onClick={(e) => {
                                            handleSubmit()
                                        }}
                                    >
                                        {isLoading && <>
                                            <span className="dr-mform-spinner">
                                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                                            </span>{" "}
                                        </>}

                                        {button_label}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ChangePasswordModal;
