import React, { useState } from "react";
import { Button, Modal, ModalBody, Container, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Config } from "../../../Config";
import closeIcon from "../../../assets/images/closeIcon.png";
import moment from "moment";
import { capitalizeWords } from "../../../helpers/Commons";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

const AdminNotesModal = (props) => {
  const modal = props?.modal;
  const setModal = props?.setModal;
  const setOpenSuccessModal = props?.setOpenSuccessModal;
  const editNoteModal = props?.editNoteModal;
  const setEditNoteModal = props?.setEditNoteModal;
  const singleAdminNote = props?.singleAdminNote;
  const setSuccessAdminNote = props?.setSuccessAdminNote;
  const newAdminNote = props?.newAdminNote;
  const [loader, setLoader] = useState(false);
  const [createNoteDate, setCreateNoteDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [showWaring, setShowWaring] = useState(false);
  const [waringMessage, setWaringMessage] = useState("");

  // select date function
  const handleDateChange = (date) => {
    setCreateNoteDate(date);
  };
  // select time function
  const handleTimeChange = (newTime) => {
    if (newTime) {
      setTime(newTime?.$d);
    } else {
      console.log("No time selected");
    }
  };
  const successModal = () => {
    setOpenSuccessModal(true);
    setModal(!modal);
  };
  const handleSubmit = async (event, value) => {
    // event.preventDefault();
    event.persist();
    if (editNoteModal) {
      setLoader(true);
      const options = {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      };
      const data = {
        notes: value?.notes,
        notesBy: newAdminNote?.notesBy,
        partnerId: newAdminNote?.partnerId,
        consultationId: newAdminNote?.consultationId,
        isActive: true,
        isInternal: true,
      };

      let url = `${process.env.REACT_APP_API_PATH}/consultationnote/${singleAdminNote?.id}`;
      axios
        .patch(url, data, options)
        .then((response) => {
          setSuccessAdminNote(true);
          setLoader(false);
          successModal();
        })
        .catch((error) => {
          setSuccessAdminNote(false);
          setShowWaring(true);
          setLoader(false);
          setWaringMessage(error?.response?.data?.message);
        });
    } else {
      //create admin notes code
      setLoader(true);
      const options = {
        headers: {
          Authorization: localStorage.getItem("auth_token"),
          PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
        },
      };
      const data = {
        notes: value?.notes,
        notesBy: newAdminNote?.notesBy,
        partnerId: newAdminNote?.partnerId,
        consultationId: newAdminNote?.consultationId,
        isActive: true,
        isInternal: true,
      };

      let url = `${process.env.REACT_APP_API_PATH}/consultationnote`;
      axios
        .post(url, data, options)
        .then((response) => {
          setSuccessAdminNote(true);
          setLoader(false);
          successModal();
        })
        .catch((error) => {
          setSuccessAdminNote(false);
          setShowWaring(true);
          setLoader(false);
          setWaringMessage(error?.response?.data?.message);
        });
    }
  };

  return (
    <React.Fragment>
      <Modal
        centered={true}
        size="md"
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
          setEditNoteModal(false);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-member-add-edit-modal`}
      >
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleSubmit(e, v);
              }}
            >
              <div
                className="d-flex justify-content-end cursor-pointer"
                onClick={() => {
                  setModal(!modal);
                  setEditNoteModal(false);
                }}
              >
                <img src={closeIcon} alt="close" />
              </div>
              <div className="p-2">
                <div style={{ fontSize: 20, fontWeight: "500" }}>
                  {" "}
                  {editNoteModal ? props.t("Admin Note") :  props.t("New Admin Note")}
                </div>
                {/*Admin body data*/}
                <div className="form-group row mt-4">
                  <label htmlFor="dateTo" className="col-sm-2 col-form-label">
                  {props.t('Date')}:
                  </label>
                  <div className="col-sm-4">
                    <AvField
                      id="date"
                      name="date"
                      type="text"
                      value={
                        editNoteModal
                          ? moment(singleAdminNote?.createdAt).format(
                              "MM-DD-YYYY"
                            )
                          : moment(new Date(), "MM-DD-YYYY").format(
                              "MM-DD-YYYY"
                            )
                      }
                      placeholder="MM-DD-YYYY"
                      readOnly={true}
                    />
                  </div>
                  <label
                    htmlFor="inputPassword3"
                    className="col-sm-2 col-form-label"
                  >
                   {props.t('Time')}:
                  </label>
                  <div className="col-sm-4 ">
                    <AvField
                      id="date"
                      name="date"
                      type="text"
                      value={
                        editNoteModal
                          ? moment(
                              new Date(singleAdminNote?.createdAt),
                              "HH:mm"
                            ).format("h:mm A")
                          : moment(new Date(), "HH:mm").format("h:mm A")
                      }
                      placeholder="MM-DD-YYYY"
                      readOnly={true}
                    />
                  </div>
                  <label htmlFor="to" className="col-sm-2 col-form-label mt-3">
                  {props.t('Patient')}:
                  </label>
                  <div className="col-sm-10  mt-3">
                    <AvField
                      id="patient"
                      name="patient"
                      type="text"
                      value={
                        editNoteModal
                          ? capitalizeWords(newAdminNote?.patientName)
                          : capitalizeWords(newAdminNote?.patientName)
                      }
                      placeholder="Patient Name"
                      readOnly={true}
                    />
                  </div>
                </div>
                <div lg={12} style={{ marginTop: "-10px" }}>
                  <Label>{props.t('Notes')}:</Label>
                  <AvField
                    name="notes"
                    className={`form-control h-xl`}
                    type="textarea"
                    value={editNoteModal ? singleAdminNote?.notes : ""}
                    style={{ height: "150px" }}
                    placeholder="Write a new note .."
                    required={true}
                  />
                </div>
                {/* Button */}
                <div className="mt-5 text-right">
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#1259EC",
                      height: "37px",
                      borderRadius: "5px",
                    }}
                  >
                    {loader && (
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                    )}
                    {editNoteModal ? props.t("Save") : props.t("Create")}
                  </Button>
                </div>
              </div>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
      {showWaring && (
        <SweetAlert
          title="Oops !"
          warning
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowWaring(false);
          }}
        >
          {waringMessage}
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

export default AdminNotesModal;
