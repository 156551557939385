import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
  Label,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Select from "react-select";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

import {
  createDoctor,
  updateDoctor,
  createDoctorFailed,
  doctorActionSuccessful,
  setSingleDoctor,
} from "../../../store/actions";

//Include Both Helper File with needed methods
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { Config } from "../../../Config";
import { dctDobGlobalFormatForDatePicker } from "../../../helpers/Commons";

let globalState;
const CreateUpdateDoctor = (props) => {
  const { editMenu, seteditMenu } = props;
  const [modal, setmodal] = useState(false);
  const [msgEnable, setmsgEnable] = useState(true);
  const dispatch = useDispatch();
  const [primaryPhone, setPrimaryPhone] = useState("");
  // const [primaryKey, setPrimaryKey] = useState(false);
  const [secondaryPhone, setSecondaryPhone] = useState("");
  // const [secondaryKey, setSecondaryKey] = useState(false);
  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [doctorDob, setDoctorDob] = useState();
  const [Secretkey, setSecretkey] = useState("");
  const [selectedMultiStates, setSelectedMultiStates] = useState(null);
  const [addMultiState, setAddMultiState] = useState(false);
  const [addStateNum, setAddStateNum] = useState([]);
  const [LicensedStateNum, setLicensedStateNum] = useState("");
  const [licenseInfo, setlicenseInfo] = useState([]);
  const [rx, setRx] = useState(false);
  const [consultation, setConsultation] = useState(false);
  // const licenseInfo = [];
  const [licenseModal, setLicesnseModal] = useState(false);

  let USstates = [
    {
      options: [
        { label: "Alabama", value: "AL" },
        { label: "Alaska", value: "AK" },
        { label: "American Samoa", value: "AS" },
        { label: "Arizona", value: "AZ" },
        { label: "Arkansas", value: "AR" },
        { label: "Armed Forces Americas", value: "AA" },
        { label: "Armed Forces Pacific", value: "AP" },
        { label: "California", value: "CA" },
        { label: "Colorado", value: "CO" },
        { label: "Connecticut", value: "CT" },
        { label: "Delaware", value: "DE" },
        { label: "District of Columbia", value: "DC" },
        { label: "Federated States of Micronesia", value: "FM" },
        { label: "Florida", value: "FL" },
        { label: "Georgia", value: "GA" },
        { label: "Guam", value: "GU" },
        { label: "Hawaii", value: "HI" },
        { label: "Idaho", value: "ID" },
        { label: "Illinois", value: "IL" },
        { label: "Indiana", value: "IN" },
        { label: "Iowa", value: "IA" },
        { label: "Kansas", value: "KS" },
        { label: "Kentucky", value: "KY" },
        { label: "Louisiana", value: "LA" },
        { label: "Maine", value: "ME" },
        { label: "Marshall Islands", value: "MH" },
        { label: "Maryland", value: "MD" },
        { label: "Massachusetts", value: "MA" },
        { label: "Michigan", value: "MI" },
        { label: "Minnesota", value: "MN" },
        { label: "Mississippi", value: "MS" },
        { label: "Missouri", value: "MO" },
        { label: "Montana", value: "MT" },
        { label: "Nebraska", value: "NE" },
        { label: "Nevada", value: "NV" },
        { label: "New Hampshire", value: "NH" },
        { label: "New Jersey", value: "NJ" },
        { label: "New Mexico", value: "NM" },
        { label: "New York", value: "NY" },
        { label: "North Carolina", value: "NC" },
        { label: "North Dakota", value: "ND" },
        { label: "Northern Mariana Islands", value: "MP" },
        { label: "Ohio", value: "OH" },
        { label: "Oklahoma", value: "OK" },
        { label: "Oregon", value: "OR" },
        { label: "Palau", value: "PW" },
        { label: "Pennsylvania", value: "PA" },
        { label: "Puerto Rico", value: "PR" },
        { label: "Rhode Island", value: "RI" },
        { label: "South Carolina", value: "SC" },
        { label: "South Dakota", value: "SD" },
        { label: "Tennessee", value: "TN" },
        { label: "Texas", value: "TX" },
        { label: "Utah", value: "UT" },
        { label: "Vermont", value: "VT" },
        { label: "Virgin Islands", value: "VI" },
        { label: "Virginia", value: "VA" },
        { label: "Washington", value: "WA" },
        { label: "West Virginia", value: "WV" },
        { label: "Wisconsin", value: "WI" },
        { label: "Wyoming", value: "WY" },
      ],
    },
  ];

  //MAXICO Multiple States LIST
  let MXstates = [
    {
      options: [
        { label: "Aguascalientes", value: "AGU" },
        { label: "Baja California Norte", value: "BCN" },
        { label: "Baja California Sur", value: "BCS" },
        { label: "Campeche", value: "CAM" },
        // { label: "Ciudad de México", value: "DIF" },
        { label: "Chiapas", value: "CHP" },
        { label: "Chihuahua", value: "CHH" },
        { label: "Coahuila de Zaragoza", value: "COA" },
        { label: "Colima", value: "COL" },
        { label: "Distrito Federal", value: "DF" },
        { label: "Durango", value: "DUR" },
        { label: "Estado de México", value: "MEX" },
        { label: "Guanajuato", value: "GUA" },
        { label: "Guerrero", value: "GRO" },
        { label: "Hidalgo", value: "HID" },
        { label: "Jalisco", value: "JAL" },
        { label: "Michoacán de Ocampo", value: "MIC" },
        { label: "Morelos", value: "MOR" },
        { label: "Nayarit", value: "NAY" },
        { label: "Nuevo León", value: "NLE" },
        { label: "Oaxaca", value: "OAX" },
        { label: "Puebla", value: "PUE" },
        { label: "Querétaro de Arteaga", value: "QUE" },
        { label: "Quintana Roo", value: "ROO" },
        { label: "San Luis Potosí", value: "SLP" },
        { label: "Sinaloa", value: "SIN" },
        { label: "Sonora", value: "SON" },
        { label: "Tabasco", value: "TAB" },
        { label: "Tamaulipas", value: "TAM" },
        { label: "Tlaxcala", value: "TLA" },
        { label: "Veracruz", value: "VER" },
        { label: "Yucatán", value: "YUC" },
        { label: "Zacatecas", value: "ZAC" },
      ],
    },
  ];

  const handleMultiState = (selectedStates) => {
    let states = [];
    selectedStates &&
      selectedStates.map((items) => {
        states.push({ label: items.label, value: items.value });
      });
    setSelectedMultiStates(selectedStates);

    if (selectedStates === null) {
      document.querySelector("#multi-state").classList.add("text-danger");
    } else {
      document.querySelector("#multi-state").classList.remove("text-danger");
    }
  };

  let doctorData = useSelector((state) => state.Doctor.singleDoctor);
  doctorData = doctorData ? doctorData : [];

  useEffect(() => {
    if (doctorData) {
      if (doctorData.consultation) {
        setConsultation(true);
      } else {
        setConsultation(false);
      }

      if (doctorData.rx) {
        setRx(true);
      } else {
        setRx(false);
      }
      if (doctorData.id) {
        setlicenseInfo([]);
      }
      if (doctorData.primaryPhone) {
        setPrimaryPhone(doctorData.primaryPhone);
      }
      if (doctorData.secondaryPhone) {
        setSecondaryPhone(doctorData.secondaryPhone);
      }
      if (doctorData.country) {
        setCountryName(doctorData.country);
      }
      if (doctorData.state) {
        setStateName(doctorData.state);
      }
      if (doctorData.dob) {
        // setDoctorDob(new Date(doctorData.dob));
        // setDoctorDob(dctDobGlobalFormatForDatePicker(doctorData.dob));
        setDoctorDob(doctorData.dob);
      }

      if (doctorData.licenseInfo) {
        setlicenseInfo(doctorData.licenseInfo);
      } else {
        // // if (!editMenu) {
        // // }
        // setlicenseInfo([]);
      }

      if (countryName == "us" || countryName == "US") {
        globalState = USstates;
      } else {
        globalState = MXstates;
      }
    }
  }, [doctorData]);

  const handleStateChange = (state) => {
    setStateName(state);
  };

  const handleCountryChange = (country) => {
    setCountryName(country);
    setStateName("");

    if (countryName == "us" || countryName == "US") {
      globalState = USstates;
    } else {
      globalState = MXstates;
    }
  };

  function toggleModalClose(e) {
    if (props.singleDoctor) {
      props.updateDoctorDataFlag();
    }
  }

  const addMultipaleStateLicence = () => {
    return (
      <>
        <Row lg={12} className="d-flex justify-content-around m-2">
          {licenseInfo?.map((items, index) => {
            for (const [key, value] of Object.entries(items)) {
              return (
                <div
                  key={index}
                  className="bg-primary d-flex align-items-center p-1 rounded mt-1 text-white mx-1"
                >
                  {key}-{value}{" "}
                  <i
                    className="fas fa-times"
                    style={{
                      fontSize: "12px",
                      cursor: "pointer",
                      color: "white",
                      marginLeft: "10px",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      const result = licenseInfo.filter(
                        (element, itemindex) => {
                          return itemindex !== index;
                        }
                      );

                      setlicenseInfo(result);
                    }}
                  ></i>
                </div>
              );
            }
          })}
        </Row>
        <Col
          lg={12}
          style={{ width: "700px" }}
          className="d-flex mb-5"
          style={{ zIndex: 111 }}
        >
          <Row className="mb-auto">
            <Col style={{ zIndex: 9000 }}>
              <AvGroup>
                <div id={"Add_multi_state"}>
                  <Select
                    onChange={(val) => {
                      setAddStateNum(val);
                    }}
                    // options={globalState}
                    options={USstates}
                    value={addStateNum}
                    // required
                  />
                </div>
              </AvGroup>
            </Col>
            <Col>
              <AvGroup>
                <div id={"Add_multi_state"}>
                  <Label for="example">
                    {props.t("Number")}{" "}
                    <i className="fas fa-star-of-life text-danger custom-star" />
                  </Label>
                  <AvInput
                    name="number"
                    type="text"
                    id="example"
                    placeholder={props.t("Number")}
                    onChange={(val) => {
                      setLicensedStateNum(val.target.value);
                    }}
                    value={LicensedStateNum}
                    // required
                  />
                </div>
              </AvGroup>
            </Col>
          </Row>
        </Col>
      </>
    );
  };

  const getSecretKey = () => {
    var generator = require("generate-password");
    var password = generator.generate({
      length: 25,
      uppercase: true,
      numbers: true,
      symbols: true,
    });
    setSecretkey(password);
  };
  const handleDateChange = (event) => {
    // const selectedDate = event.target.value;
    const rawInput = event.target.value
      .replace(/^(\d\d)(\d)$/g, "$1-$2")
      .replace(/^(\d\d-\d\d)(\d+)$/g, "$1-$2")
      .replace(/[^\d-]/g, "");
    const selectedDate = rawInput.slice(0, 10); // dob must be 10 characters
    setDoctorDob(selectedDate);
  };

  function handleValidSubmit(event, values) {
    document
      .getElementsByClassName("dr-mform-spinner")[0]
      .classList.remove("d-none");
    setmsgEnable(true);

    let password;
    if (values.actionType !== "edit") {
      password = document.querySelector(".generate-password").value;
    }

    const multiState = [];

    values = {
      ...values,
      generatePassword: password,
      multiStates: selectedMultiStates,
      licenseInfo: licenseInfo,
    };

    values.dob = doctorDob;

    if (
      !primaryPhone ||
      primaryPhone === "" ||
      primaryPhone.substring(2).length < 10
    ) {
      document.querySelector("#primary_phone").classList.add("text-danger");
      document
        .getElementsByClassName("dr-mform-spinner")[0]
        .classList.add("d-none");
      return;
    } else {
      document.querySelector("#primary_phone").classList.remove("text-danger");
      values.primaryPhone = `${primaryPhone}`;
    }

    values.secondaryPhone =
      `${secondaryPhone}`.length < 10 ? "" : `${secondaryPhone}`;

    values.country = countryName;
    values.state = stateName;
    values.rx = values.rx ? values.rx : rx;
    values.country = "US";
    values.consultation = values.consultation
      ? values.consultation
      : consultation;

    //call reducer method
    if (values.actionType === "edit") {
      props.updateDoctor(values, props.history);
    } else {
      props.createDoctor(values, props.history);
    }
  }

  const generetePasswordField = () => {
    if (editMenu) {
      return (
        <>
          <Col lg={6}>
            <div className="form-doctor">
              <AvGroup>
                <Label for="example">
                  {props.t("Generate password")}{" "}
                  <i className="fas fa-star-of-life text-danger custom-star " />
                </Label>
                <div className="input-group mb-3 ">
                  <input
                    type="text"
                    className="form-control generate-password"
                    name="Generetepassword"
                    placeholder={props.t("Generate password")}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    // required
                    onChange={(val) => {
                      setSecretkey(val.target.value);
                    }}
                    value={Secretkey}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      id="basic-addon2"
                      onClick={(e) => {
                        getSecretKey();
                      }}
                      style={{
                        cursor: "pointer",
                        background: "#2151ed",
                        color: "#ffffff",
                      }}
                    >
                      {props.t("Generate")}
                    </span>
                  </div>
                </div>
              </AvGroup>
            </div>
          </Col>
        </>
      );
    }
  };

  const editFormDoctorItem = (e) => {
    setmsgEnable(false);
    dispatch(doctorActionSuccessful(null, "", ""));
  };

  const createFormDoctorItem = (e) => {
    setmsgEnable(false);
    dispatch(doctorActionSuccessful(null, "", ""));
    dispatch(setSingleDoctor(null, "", ""));
  };

  const action_type = props.doctor_action_type === "edit" ? "edit" : "create";
  const button_label =
    props.doctor_action_type === "edit" ? "Submit" : "Create Doctor";
  const modal_title =
    props.doctor_action_type === "edit" ? "Update Doctor" : "Create Doctor";

  return (
    <React.Fragment>
      {(() => {
        switch (props.doctor_button_type) {
          case "edit_icon":
            return (
              <i
                className="bx bx-edit-alt d-none"
                id="doctor_update_modal"
                onClick={() => {
                  setmodal(!modal);
                  editFormDoctorItem();
                }}
              ></i>
            );
          case "edit_btn":
            return (
              <Button
                type="button"
                color="primary"
                id="doctor_update_modal"
                className="waves-effect waves-light mr-2"
                onClick={() => {
                  setmodal(!modal);
                  editFormDoctorItem();
                }}
              >
                <i className="mdi mdi-pencil mr-1"></i>
                {props.t("Edit")}
              </Button>
            );
          default:
            return (
              <Link
                onClick={() => {
                  setmodal(!modal);
                  createFormDoctorItem();
                  setlicenseInfo([]);
                  seteditMenu(true);
                }}
                to="#"
                className="popup-form btn btn-primary"
                id="doctor_create_modal"
              >
                <i className="mdi mdi-plus mr-1"></i>
                {props.t("Create Doctor ")}
              </Link>
            );
        }
      })()}

      <Modal
        centered={true}
        size="lg"
        isOpen={modal}
        toggle={() => {
          setmodal(!modal);
        }}
        scrollable={true}
        className={`${Config.names.slugName}-doctor-add-edit-modal`}
        onClosed={(e) => {
          toggleModalClose(e);
          // setlicenseInfo([]);
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal(!modal);
          }}
        >
          {modal_title}
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              {props.createDoctorError && msgEnable ? (
                <Alert color="danger">{props.createDoctorError}</Alert>
              ) : (
                ""
              )}
              {props.message && props.message && msgEnable ? (
                <Alert color="success">{props.message}</Alert>
              ) : null}

              {action_type === "edit" ? (
                <AvField
                  name="id"
                  type="hidden"
                  value={
                    doctorData.id !== undefined ? doctorData.id : doctorData.id
                  }
                />
              ) : (
                ""
              )}
              <AvField name="actionType" type="hidden" value={action_type} />

              <Row>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvGroup>
                      <Label for="example">
                        {props.t("First Name")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        value={
                          doctorData.userId !== undefined
                            ? doctorData.userId.firstName
                            : ""
                        }
                        name="firstName"
                        id="example"
                        type="text"
                        placeholder={props.t("First Name")}
                        required
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <AvGroup>
                      <Label for="example">
                        {props.t("Last Name")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        name="lastName"
                        type="text"
                        id="example"
                        required
                        placeholder={props.t("Last Name")}
                        value={
                          doctorData.userId !== undefined
                            ? doctorData.userId.lastName
                            : ""
                        }
                      />
                    </AvGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvGroup>
                      <Label for="example">
                        {props.t("NPI Number")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        name="npi"
                        type="text"
                        id="example"
                        placeholder={props.t("NPI Number")}
                        required
                        value={doctorData.npi}
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvGroup>
                      <Label for="example">
                        {props.t("License Number")}{" "}
                        {/* <i className="fas fa-star-of-life text-danger custom-star" /> */}
                      </Label>
                      <AvInput
                        name="licenseNumber"
                        type="text"
                        id="example"
                        placeholder={props.t("License Number")}
                        // required
                        value={doctorData.licenseNumber}
                      />
                    </AvGroup>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="form-doctor">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Email")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        name="email"
                        type="text"
                        id="example"
                        placeholder={props.t("Email")}
                        required
                        value={
                          doctorData.userId !== undefined
                            ? doctorData.userId.email
                            : ""
                        }
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvField
                      type="select"
                      name="language"
                      label={props.t("Prefered Language")}
                      value={doctorData.language}
                    >
                      <option value="English">{props.t("English")} </option>
                      <option value="Spanish">{props.t("Spanish")} </option>
                      <option value="Turkish">{props.t("Turkish")}</option>
                      <option value="Hindi">{props.t("Hindi")} </option>
                      <option value="Arobic">{props.t("Arobic")} </option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className={`mb-3 form-doctor ${Config.names.slugName}-phone-box`}
                    id={`primary_phone`}
                  >
                    <label>{props.t(`Phone`)}</label>
                    <PhoneInput
                      inputProps={{ name: "primaryPhone", required: true }}
                      country={
                        !countryName || countryName == ""
                          ? "us"
                          : countryName.toLowerCase()
                      }
                      value={doctorData.primaryPhone}
                      onChange={(phone) => {
                        setPrimaryPhone("+" + phone);
                        // setPrimaryKey(true);
                      }}
                      enableSearch={true}
                      onlyCountries={["us", "mx"]}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-doctor" id={`secondary_phone`}>
                    <label>{props.t(`Alternative Number`)}</label>
                    <PhoneInput
                      inputProps={{ name: "secondaryPhone" }}
                      country={
                        !countryName || countryName == ""
                          ? "us"
                          : countryName.toLowerCase()
                      }
                      value={doctorData.secondaryPhone}
                      onChange={(phone) => {
                        setSecondaryPhone("+" + phone);
                        // setSecondaryKey(true);
                      }}
                      enableSearch={true}
                      onlyCountries={["us", "mx"]}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Tax Id Number")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        name="taxIdNumber"
                        type="text"
                        id="example"
                        placeholder={props.t("Tax Id Number")}
                        required
                        value={doctorData.taxIdNumber}
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Primary Fax")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        name="primaryFax"
                        type="text"
                        id="example"
                        placeholder={props.t("Primary Fax")}
                        required
                        value={doctorData.primaryFax}
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvField
                      type="select"
                      name="gender"
                      label={props.t("Gender")}
                      value={doctorData.gender}
                    >
                      <option value="unknown">{props.t("Other")}</option>
                      <option value="male">{props.t("Male")}</option>
                      <option value="female">{props.t("Female")}</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className={`form-doctor ${Config.names.slugName}-date-box`}
                    id={`date-dob`}
                  >
                    <label htmlFor="dob">{props.t("Date of Birth")}</label>
                    {/* <ReactDatePicker
                      name="dob"
                      className="form-control"
                      selected={doctorDob}
                      dateFormat="MM-dd-yyyy"
                      onChange={handleDateChange}
                      placeholderText="MM-DD-YYYY"
                      showYearDropdown
                      required
                    /> */}
                    <AvInput
                      required
                      pattern="^(0[1-9]|1[0-2])-(0[1-9]|1\d|2[0-8])-(?!0000)[1-9]\d{3}|(0[13-9]|1[0-2])-(29|30)-(?!0000)[1-9]\d{3}|(0[13578]|1[02])-31-(?!0000)[1-9]\d{3}|02-29-(?!0000)(([1-9]\d)(0[48]|[2468][048]|[13579][26])|([2468][048]|[13579][26])00)$"
                      value={doctorDob}
                      minLength="10"
                      maxLength="10"
                      name="dob"
                      id="dob"
                      list="dates_pattern4_datalist"
                      placeholder="MM-DD-YYYY"
                      onChange={handleDateChange}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvField
                      type="select"
                      name="qualification"
                      label={props.t("Qualification")}
                      value={doctorData.qualification}
                    >
                      <option value="Unknown">{props.t("Other")}</option>
                      <option value="MBBS">{props.t("MBBS")}</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <label htmlFor="state">{props.t("Country")}</label>
                  <CountryDropdown
                    defaultOptionLabel={"Select Country"}
                    labelType="short"
                    valueType="short"
                    whitelist={["US", "MX"]}
                    classes="form-control"
                    value={countryName}
                    onChange={handleCountryChange}
                  />
                </Col>
                <Col lg={6}>
                  <div
                    className={`form-group ${Config.names.slugName}-select-box`}
                    id="select-state"
                  >
                    <label htmlFor="state">{props.t("State")}</label>
                    <RegionDropdown
                      defaultOptionLabel={"Select State"}
                      country={countryName}
                      countryValueType="short"
                      labelType="full"
                      valueType="short"
                      value={stateName}
                      classes="form-control"
                      onChange={handleStateChange}
                    />
                  </div>
                </Col>
                <Col lg={6} className="w-100 d-flex align-items-center mt-3">
                  <Button
                    onClick={() => {
                      // setLicesnseModal(!licenseModal)
                      setAddStateNum([]);
                      setLicensedStateNum("");
                      setAddMultiState(!addMultiState);
                    }}
                    variant="secondary"
                    style={{ backgroundColor: "#2C73D2" }}
                    className="w-100 d-flex align-items-center justify-content-around"
                  >
                    <i className="fas fa-plus-circle"></i>{" "}
                    {props.t("Add Licensed")}{" "}
                    {/* <span className="badge badge-light">
                      {licenseInfo.length}
                    </span> */}
                    <div
                      className="bg-light border border-secondary rounded text-center"
                      style={{ width: "20px", height: "20px" }}
                    >
                      <span className="text-dark">{licenseInfo.length}</span>
                    </div>
                  </Button>
                </Col>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvGroup>
                      <Label for="example">
                        {props.t("City")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        name="city"
                        type="text"
                        id="example"
                        placeholder={props.t("City")}
                        required
                        value={doctorData.city}
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Postal Code")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        name="postalCode"
                        type="text"
                        id="example"
                        placeholder={props.t("Postal Code")}
                        required
                        value={doctorData.postalCode}
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvField
                      type="select"
                      name="rx"
                      label={props.t("RX")}
                      value={doctorData.rx ? doctorData.rx : false}
                    >
                      <option value={false}>{props.t("Disable")} </option>
                      <option value={true}>{props.t("Enable")} </option>
                    </AvField>
                  </div>
                </Col>

                {/* consultation field */}
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvField
                      type="select"
                      name="consultation"
                      label={props.t("Consultation")}
                      value={
                        doctorData.consultation
                          ? doctorData.consultation
                          : false
                      }
                    >
                      <option value={false}>{props.t("Disable")} </option>
                      <option value={true}>{props.t("Enable")} </option>
                    </AvField>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="form-doctor">
                    <AvGroup>
                      <Label for="example">
                        {props.t("Address")}{" "}
                        <i className="fas fa-star-of-life text-danger custom-star" />
                      </Label>
                      <AvInput
                        name="address"
                        type="text"
                        id="example"
                        placeholder={props.t("Address")}
                        required
                        value={doctorData.address}
                      />
                    </AvGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-doctor">
                    <AvField
                      name="addressTwo"
                      label={props.t("Address Two")}
                      className="form-control"
                      placeholder={props.t("Address Two")}
                      type="text"
                      value={doctorData.addressTwo}
                      //value={(doctorData.doctor) ? doctorData.doctor.addressTwo : ''}
                    />
                  </div>
                </Col>
                {generetePasswordField()}

                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setmodal(!modal)}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                      onClick={(e) => {
                        // dctCustomValidations("state", "select-state");
                      }}
                    >
                      <span className="dr-mform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      {button_label}
                    </button>
                  </div>
                </Col>

                {/* {addMultiState && (
                  <SweetAlert
                    custom
                    showCancel
                    showCloseButton
                    confirmBtnText="Save"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="light"
                    onConfirm={() => {
                      const licenceStateName = addStateNum.value;
                      const stateNumber = LicensedStateNum;

                      if (
                        licenceStateName == "undefined" ||
                        stateNumber == ""
                      ) {
                        document
                          .querySelector("#Add_multi_state")
                          .classList.add("text-danger");
                      } else {
                        setlicenseInfo([
                          ...licenseInfo,
                          {
                            [licenceStateName]: stateNumber,
                          },
                        ]);
                      }
                      setAddStateNum([]);
                      setLicensedStateNum("");
                    }}
                  >
                    {addMultipaleStateLicence}
                  </SweetAlert>
                )} */}
                <Modal
                  centered={true}
                  size="md"
                  isOpen={addMultiState}
                  toggle={() => {
                    setAddMultiState(!addMultiState);
                  }}
                  scrollable={false}
                  className={`${Config.names.slugName}-company-add-edit-modal`}
                  // onClosed={(e) => {
                  //   setAddMultiState(!addMultiState)
                  // }}
                >
                  <ModalHeader
                    toggle={() => {
                      setAddMultiState(!addMultiState);
                    }}
                  ></ModalHeader>
                  <ModalBody>
                    <Container>
                      {addMultipaleStateLicence()}

                      <Row>
                        <Col lg={12}>
                          <div className="modal-footer justify-content-center">
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={() => {
                                // setlicenseInfo([])
                                setAddMultiState(!addMultiState);
                              }}
                            >
                              {props.t("Cancel")}
                            </button>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              onClick={() => {
                                const licenceStateName = addStateNum.value;
                                const stateNumber = LicensedStateNum;

                                if (
                                  licenceStateName == "undefined" ||
                                  stateNumber == ""
                                ) {
                                  document
                                    .querySelector("#Add_multi_state")
                                    .classList.add("text-danger");
                                } else {
                                  setlicenseInfo([
                                    ...licenseInfo,
                                    {
                                      [licenceStateName]: stateNumber,
                                    },
                                  ]);
                                }
                                setAddStateNum([]);
                                setLicensedStateNum("");
                              }}
                            >
                              <span className="dr-cform-spinner d-none">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                              </span>{" "}
                              {props.t("Save")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ModalBody>
                </Modal>
              </Row>
            </AvForm>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { createDoctorError } = state.Doctor;
  const { doctor } = state.Doctor;
  const { message } = state.Doctor;
  return { createDoctorError, doctor, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    createDoctor,
    updateDoctor,
    createDoctorFailed,
    setSingleDoctor,
  })(withNamespaces()(CreateUpdateDoctor))
);
