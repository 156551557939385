import React, { useState, useEffect } from "react";

//Style from Reactstrap
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

import {
  getPartner,
  partnerActionSuccessful,
  setPartnerListing,
  setSinglePartner,
} from "../../store/actions";

//Import partnerList
import PartnerList from "../../components/Partner/PartnerList";

//Import Create and Update partner
import CreateUpdatePartner from "../../components/Partner/CreateUpdatePartner";

//Import Upload CSV
import MemberCSVUpload from "../../components/Partner/MemberCSVUpload";

import axios from "axios";
import { withNamespaces } from "react-i18next";
import { Config } from "../../Config";

const Partner = (props) => {
  const [postsPerPage, setPostsPerPage] = useState(3);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);

  const [gdata, setGData] = useState({
    loading: true,
    partners: null,
    message: null,
  });

  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    partnerSearchParam: "",
    type: "filter",
    reset: false,
  });

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        partnerSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: false,
      });
      return fetchPartnerData(searchParam, selectedPage);
    }
    fetchPartnerData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("csearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        partnerSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      return fetchPartnerData(searchParam, selectedPage);
    }
    // if(selectedPage > 1)
    fetchPartnerData(searchParam, selectedPage);
  };

  const fetchPartnerData = (param, currentPage = 1) => {
    let searchParam = "";
    if (param) {
      searchParam = param.partnerSearchParam
        ? "&q=" + param.partnerSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    let url = process.env.REACT_APP_API_PATH + `/partner` + searchParam;

    axios
      .get(url, headers)
      .then(function (response) {
        const data = response.data;
        let message = "";
        message = data.length > 0 ? "" : "No record found.";

        dispatch(setPartnerListing(data, message));
        setGData({
          loading: false,
          partners: data,
          message: message,
        });

        setTotalPosts(data.total / postsPerPage);
        setCurrentPage(currentPage - 1);
        document
          .getElementsByClassName("dr-csearch-spinner")[0]
          .classList.add("d-none");
      })
      .catch(function (err) {
        document
          .getElementsByClassName("dr-csearch-spinner")[0]
          .classList.add("d-none");
        var message = checkAjaxError(err);
        // if(err.response.status === 400) {
        //       message = err.response.data.cause.details;
        //   }
        setGData({
          loading: false,
          partners: null,
          message: message,
        });
        setTotalPosts(0);
        setCurrentPage(0);
        dispatch(setPartnerListing(null, message));
      })
      .then(function () {});
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let csearch = urlParams.get("csearch");
    let searchParam = "";
    if (csearch) {
      let searchParam = {
        partnerSearchParam: csearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      fetchPartnerData(searchParam);
    } else {
      // fetchPartnerData(searchParam);
    }
  }, []);

  // Apply Partner Search
  function handleSearch(e) {
    e.preventDefault();
    document
      .getElementsByClassName("dr-csearch-spinner")[0]
      .classList.remove("d-none");
    fetchPartnerData(searchFormDetails);
    setSearchFormDetails({
      reset: true,
    });
    let param = searchFormDetails.partnerSearchParam
      ? searchFormDetails.partnerSearchParam
      : "";
    props.history.push("/partner?csearch=" + param);
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    fetchPartnerData();
    setSearchFormDetails({
      reset: false,
    });
    document.getElementById("partner-search-form").reset();
    props.history.push("/partner");
  }

  const { loading, partners, message } = gdata;
  const partner_action = useSelector((state) => state.Partner.action_type);

  const propsUpdatePartnerData = () => {
    if (!partners && !props.singlePartner) {
      return;
    }
    if (partners && props.singlePartner) {
      let partnerid = props.singlePartner.id;
      //Update record
      if (partner_action === 2) {
        var index = partners.findIndex((x) => x.id === partnerid);
        partners[index] = props.singlePartner;
        dispatch(setPartnerListing(partners, ""));
      }

      //Insert new record
      if (partner_action === 1) {
        partners.unshift(props.singlePartner);
        dispatch(setPartnerListing(partners, ""));
      }

      let activeRow = document.querySelectorAll(`[data-row-id="${partnerid}"]`);
      if (activeRow[0]) {
        activeRow[0].scrollIntoView({ behavior: "smooth" });
        activeRow[0].classList.add("active");
        const timer = setTimeout(() => {
          activeRow[0].classList.remove("active");
        }, 3000);
        return () => clearTimeout(timer);
      }
      setGData({
        loading: false,
        partners: partners,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="9">
              <Form
                className={`mb-2 ${Config.names.slugName}-search-form`}
                inline
                onSubmit={handleSearch}
                id="partner-search-form"
              >
                <FormGroup className="mr-sm-2 mb-sm-0 mb-0">
                  <Input
                    type="text"
                    name="partnerSearchParam"
                    style={{ minWidth: "250px" }}
                    placeholder={props.t(`Search...`)}
                    onChange={(e) => {
                      let { value } = e.target;
                      setSearchFormDetails((prevState) => ({
                        ...prevState,
                        partnerSearchParam: value,
                      }));
                    }}
                  />
                </FormGroup>
                <Button onClick={handleSearch}>
                  <span className="dr-csearch-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                  {props.t(`Search`)}
                </Button>
                {searchFormDetails.reset ? (
                  <span
                    className={`ml-2 ${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-reset-search`}
                    onClick={resetSearch}
                  >
                    <i className="bx bx-reset font-size-22 align-middle mr-2"></i>
                  </span>
                ) : (
                  ""
                )}
              </Form>
            </Col>
            <Col lg="3">
              <div className="text-right mb-2">
                <CreateUpdatePartner
                  updatePartnerDataFlag={propsUpdatePartnerData}
                  partner_action_type="create"
                  partner_button_type="create"
                  {...props}
                />
                <CreateUpdatePartner
                  updatePartnerDataFlag={propsUpdatePartnerData}
                  partner_action_type="edit"
                  partner_button_type="edit_icon"
                  {...props}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PartnerList
                    {...props}
                    partnerListMessage={message}
                    total={totalPosts}
                    getDataOnEnter={getDataOnEnter}
                    getUserData={getUserData}
                    currentPage={currentPage}
                    perPage={postsPerPage}
                    ajaxLoader={loading}
                  ></PartnerList>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { partner } = state.Partner;
  const { singlePartner } = state.Partner;
  const { message } = state.Partner;
  return { partner, message, singlePartner };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPartner,
    partnerActionSuccessful,
    setSinglePartner,
  })(withNamespaces()(Partner))
);
