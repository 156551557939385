import React, { useEffect, useState } from "react";
import { Row, Col, Form, CardBody, Alert, Container } from "reactstrap";
import { Config } from "../../Config";
import axios from "axios";
import AlertMessage from "./Components/Alerts/AlertMessage";
import { Link } from "react-router-dom";
import { CheckIcon, ChevronRightIcon } from "@heroicons/react/solid";

// Components
import IdentifyAccountComponent from "./Components/IdentifyAccount/IdentifyAccountComponent";
import IdentifySuccess from "./Components/IdentifyAccount/IdentifySuccess";
import IdentifyNoSeeEmail from "./Components/IdentifyAccount/IdentifyNoSeeEmail";
import IdentifyNoEmail from "./Components/IdentifyAccount/IdentifyNoEmail";
// Redux
import { useSelector } from "react-redux";

const IdentifyAccount = () => {
  const [renderOption, setRenderOption] = useState("account");
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    title: "",
    message: "",
  });
  const [email, setEmail] = useState("");

  const userData = useState(useSelector((state) => state.ActivateBenefit));
  // console.log("from redux userData find acc", userData[0].findAccountData);
  // console.log("from redux userData active acc", userData[0].activeAccountData);

  const renderComponent = (option) => {
    switch (option) {
      case "account": {
        return (
          <IdentifyAccountComponent
            setAlertData={setAlertData}
            alertData={alertData}
            setShowAlert={setShowAlert}
            userData={userData[0].findAccountData}
            setRenderOption={setRenderOption}
            setEmail={setEmail}
          />
        );
      }
      case "success": {
        return (
          <IdentifySuccess
            email={email}
            setAlertData={setAlertData}
            alertData={alertData}
            setShowAlert={setShowAlert}
          />
        );
      }
      case "noSeeEmail": {
        return <IdentifyNoSeeEmail />;
      }
      case "noEmail": {
        return <IdentifyNoEmail userData={userData[0].activeAccountData} />;
      }
    }
  };

  return (
    <>
      <React.Fragment>
        <div
          className={`py-5 bg-black app-${process.env.REACT_APP_SLUG} global-gradient`}
          style={{ minHeight: "100vh", height: "100%", marginTop: 0 }}
        >
          <div className="account-pages my-5 pt-sm-2">
            <Container className={`${Config.names.slugName}-container-sm`}>
              <Row className="justify-content-center mt-5">
                <Col
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <img
                    src={Config.logos.logoDark}
                    alt=""
                    style={{ width: "14rem" }}
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center">
                <Col lg="5" md="6" sm="8" xs="10" className="p-0">
                  <AlertMessage
                    login={false}
                    show={showAlert}
                    type={alertData.type}
                    title={alertData.title}
                    message={alertData.message}
                    forgot={false}
                  />
                </Col>
              </Row>

              <Row className="d-flex flex-column justify-content-center align-items-center">
                <Col lg="5" md="6" sm="8" xs="10" className="mt-5">
                  <i className="fa fa-arrow-left font-size-14 text-secondary"></i>
                  <Link
                    to="/login"
                    className="text-secondary font-size-14 font-weight-light ml-2"
                  >
                    Go back
                  </Link>
                </Col>

                {renderComponent(renderOption)}
              </Row>
              <Row className="d-flex justify-content-center mt-4">
                <Link
                  className="text-primary font-weight-bold font-size-13"
                  style={{ wordSpacing: "2px" }}
                  to="/login"
                >
                  Already a Member? Click Here to Sign In
                </Link>
                <ChevronRightIcon
                  className="text-primary"
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    marginLeft: "0.75rem",
                  }}
                />
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default IdentifyAccount;
