import React, { Component } from 'react';
import { Config } from '../../../../Config';

export default class ConnectionStatus extends Component {
  render() {
    let status = this.props.connected ? 'Connected' : 'Disconnected';
    return (
      <div className={`${Config.names.slugName}-tok-statusBar`}>
        <img src="/static/media/logo-dark.0ce46e93.svg" alt="" height="22"></img>
        <p className={`${Config.names.slugName}-tok-status `+(status==="Connected"? "text-success ":"text-muted ")} style={{textAlign: "right"}}>
          <strong>Status:</strong> {status}
        </p>
      </div>
    );
  }
}
