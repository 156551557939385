import React, { useState } from "react";
import { Config } from "../../Config";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { setAuthToken } from "../../store/auth/login/actions";

import axios from "axios";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";

const UpdateMemberModal = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState({
    firstName: false,
    lastName: false,
    email: false,
    dob: false,
    postalCode: false,
  });
  const [editDOB, setEditDOB] = useState("");
  const [userData, setUserData] = useState(
    useSelector((state) => state.Login).authUser
  );
  let { authUser, authMemberId, auth_token } = useSelector(
    (state) => state.Login
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    // let p = /\d{4}-\d{2}-\d{2}/
    let p = /\d{2}\/\d{2}\/\d{4}/;

    // Edit dob split ===================
    const splitDob = editDOB.split("/");

    // input data validation===============
    if (!userData.firstName) {
      setFormErr({
        ...formErr,
        firstName: true,
      });
      return;
    } else if (!userData.lastName) {
      setFormErr({
        ...formErr,
        lastName: true,
      });
      return;
    } else if (!userData.email) {
      setFormErr({
        ...formErr,
        email: true,
      });
      return;
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)
    ) {
      setFormErr({
        ...formErr,
        email: true,
      });
      return;
    } else if (!editDOB) {
      // console.log("1 date check");
      setFormErr({
        ...formErr,
        dob: true,
      });
      return;
    } else if (!p.test(editDOB)) {
      // console.log("2 date check");
      setFormErr({
        ...formErr,
        dob: true,
      });
      return;
    } else if (
      splitDob[0] > 12 ||
      splitDob[1] > 31 ||
      splitDob[2] > new Date().getFullYear()
    ) {
      // console.log("invalid date");
      setFormErr({
        ...formErr,
        dob: true,
      });
      return;
    } else if (!userData.postalCode) {
      setFormErr({
        ...formErr,
        postalCode: true,
      });
      return;
    }
    setLoading(true);
    setUserData({
      ...userData,
      dob: editDOB,
    });
    let data = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      //   dob: editDOB,
      dob: moment(editDOB).format("YYYY-MM-DD"),
      postalCode: userData.postalCode,
    };

    let url = `${process.env.REACT_APP_API_PATH}/member/${authMemberId}/update-onboarding`;

    const headers = {
      headers: {
        Authorization: auth_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    await axios
      .patch(url, data, headers)
      .then((res) => {
        console.log("success ----", res.data);
        setLoading(false);
        if (res.data.success) {
          localStorage.setItem(
            "auth_user",
            JSON.stringify(res.data.result.user)
          );
          // localStorage.setItem("auth_token", "Bearer " + response.data.result.bearer);
          dispatch(setAuthToken(auth_token, res.data.result.user));
          props.setShowModal(false);

          props.setAlertData({
            ...props.alertData,
            title: "Success",
            message: res.data.message,
            success: true,
            show: true,
          });
        } else {
          props.setAlertData({
            ...props.alertData,
            title: "Failure",
            message: "Something went wrong. Member onboarding failure.",
            success: false,
            show: true,
          });
        }
      })
      .catch((err) => {
        console.log("error------", err.response.data.message);
        setLoading(false);
        // props.setShowModal(false)
        props.setAlertData({
          ...props.alertData,
          title: "Failure",
          message: err.response.data.message,
          success: false,
          show: true,
        });
      });
  };

  return (
    <>
      <React.Fragment>
        <Modal
          centered={true}
          size="md"
          isOpen={true}
          // toggle={() => {
          //     setModal(!modal);
          // }}
          scrollable={true}
          className={`${Config.names.slugName}-member-add-edit-modal`}
          // onClosed={(e) => {
          //     toggleModalClose(e);
          // }}
        >
          {/* <ModalHeader
                    // toggle={() => {
                    //     setModal(!modal);
                    // }}
                    >

                    </ModalHeader> */}
          <ModalBody>
            <Container>
              <Row className="mt-3 mb-4 d-flex justify-content-center align-items-center">
                <img
                  src={Config.logos.logoDark}
                  alt=""
                  style={{ width: "180px" }}
                />
              </Row>

              <div className="px-3">
                <p className="mb-2 text-primary font-weight-bold text-uppercase h5">
                  welcome to {Config.names.appName}
                </p>
                <p className="mb-4">
                  Please verify your member information below:
                </p>
              </div>

              <form className="px-3" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="example">First Name</Label>
                  <input
                    type="text"
                    className={`form-control text-capitalize ${
                      formErr.firstName && "border border-danger"
                    }`}
                    value={userData.firstName}
                    onChange={(e) => {
                      setFormErr({
                        ...formErr,
                        firstName: false,
                      });
                      setUserData({
                        ...userData,
                        firstName: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="example">Last Name</Label>
                  <input
                    type="text"
                    className={`form-control text-capitalize ${
                      formErr.lastName && "border border-danger"
                    }`}
                    value={userData.lastName}
                    onChange={(e) => {
                      setFormErr({
                        ...formErr,
                        lastName: false,
                      });
                      setUserData({
                        ...userData,
                        lastName: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="example">Email</Label>
                  <input
                    type="email"
                    className={`form-control ${
                      formErr.email && "border border-danger"
                    }`}
                    value={userData.email}
                    onChange={(e) => {
                      setFormErr({
                        ...formErr,
                        email: false,
                      });
                      setUserData({
                        ...userData,
                        email: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="example">Date of Birth</Label>
                  {console.log(typeof userData.dob)}
                  <input
                    type="text"
                    className={`form-control ${
                      formErr.dob && "border border-danger"
                    }`}
                    value={editDOB}
                    placeholder={
                      userData.dob !== "01/01/1900"
                        ? moment(userData.dob).format("MM/DD/YYYY")
                        : "MM/DD/YYYY"
                    }
                    onChange={(e) => {
                      setFormErr({
                        ...formErr,
                        dob: false,
                      });

                      // setEditDOB(e.target.value)
                      setEditDOB(
                        e.target.value
                          .replace(/^(\d\d)(\d)$/g, "$1/$2")
                          .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
                          .replace(/[^\d\/]/g, "")
                      );
                    }}
                    size={10}
                    maxLength={10}
                  />
                  {formErr.dob && (
                    <p className="text-danger text-italic">
                      * DOB should be in MM/DD/YYYY
                    </p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="example">Zip Code</Label>
                  <input
                    type="text"
                    className={`form-control ${
                      formErr.postalCode && "border border-danger"
                    }`}
                    value={userData.postalCode}
                    onChange={(e) => {
                      setFormErr({
                        ...formErr,
                        postalCode: false,
                      });
                      setUserData({
                        ...userData,
                        postalCode: e.target.value,
                      });
                    }}
                  />
                </FormGroup>

                <Row className="px-4 mt-4 mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary text-white w-100"
                  >
                    {loading ? (
                      <span className="dr-cform-spinner">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>
                    ) : (
                      "Confirm"
                    )}
                  </button>
                </Row>
              </form>
            </Container>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default UpdateMemberModal;
