import React, { useState, useEffect } from "react";

//Style from Reactstrap
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Include Both Helper File with needed methods
import { checkAjaxError } from "../../helpers/backend_helper";

import {
  getTelevisit,
  setTelevisitHistoryListing,
  setSingleTelevisit,
} from "../../store/actions";

//Import TelevisitList
import TelevisitList from "../../components/Doctor/TelevisitList";

import axios from "axios";
import { Config } from "../../Config";

const Televisits = (props) => {
  const { authDoctorId } = useSelector((state) => state.Login);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);

  const [gdata, setGData] = useState({
    loading: true,
    televisits: null,
    message: null,
  });

  const dispatch = useDispatch();

  const [searchFormDetails, setSearchFormDetails] = useState({
    televisitSearchParam: "",
    type: "filter",
    reset: false,
  });

  const getDataOnEnter = (selectedPage) => {
    setCurrentPage(selectedPage - 1);
    let urlParams = new URL(document.location).searchParams;
    let msearch = urlParams.get("gsearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        televisitSearchParam: msearch,
        type: "filter",
      };

      setSearchFormDetails({
        reset: false,
      });
      return fetchTelevisitData(searchParam, selectedPage);
    }
    fetchTelevisitData(searchParam, selectedPage);
  };

  const getUserData = (e) => {
    setCurrentPage(e.selected);
    let selectedPage = e.selected + 1;

    let urlParams = new URL(document.location).searchParams;

    let msearch = urlParams.get("gsearch");
    let searchParam = "";
    if (msearch) {
      let searchParam = {
        televisitSearchParam: msearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      return fetchTelevisitData(searchParam, selectedPage);
    }
    // if(selectedPage > 1)
    fetchTelevisitData(searchParam, selectedPage);
  };

  const fetchTelevisitData = (param, currentPage = 1) => {
    let searchParam = "";
    if (param) {
      searchParam = param.televisitSearchParam
        ? "&q=" + param.televisitSearchParam
        : "";
    }
    let bearer_token = localStorage.getItem("auth_token");
    let doctorId = authDoctorId; //getAuthIds.doctor;
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    setGData({
      loading: true,
    });
    let url =
      process.env.REACT_APP_API_PATH +
      `/doctor/${doctorId}/televisits?page=${currentPage}&limit=${postsPerPage}` +
      searchParam;

    axios
      .get(url, headers)
      .then(function (response) {
        const data = response.data;
        let message = "";
        message = data.data && data.data.length > 0 ? "" : "No record found.";
        dispatch(setTelevisitHistoryListing(data.data, message));
        setGData({
          loading: false,
          televisits: data.data,
          message: message,
        });
        setTotalPosts(data.data.total / postsPerPage);
        setCurrentPage(currentPage - 1);
        // document
        //   .getElementsByClassName("dr-gsearch-spinner")[0]
        //   .classList.add("d-none");
      })
      .catch(function (err) {
        // document
        //   .getElementsByClassName("dr-gsearch-spinner")[0]
        //   .classList.add("d-none");
        var message = checkAjaxError(err);
        console.log(err);
        if (err.response) {
          if (err.response.status === 400) {
            message = err.response.data.cause.details;
          }
        }

        setGData({
          loading: false,
          televisits: null,
          message: message,
        });
        setTotalPosts(0);
        setCurrentPage(0);
        dispatch(setTelevisitHistoryListing(null, message));
      })
      .then(function () {});
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    let gsearch = urlParams.get("gsearch");
    // let searchParam = "";
    if (gsearch) {
      let searchParam = {
        televisitSearchParam: gsearch,
        type: "filter",
      };
      setSearchFormDetails({
        reset: true,
      });
      fetchTelevisitData(searchParam);
    } else {
      // fetchTelevisitData(searchParam);
    }
  }, []);

  // Apply Televisit Search
  function handleSearch(e) {
    e.preventDefault();
    // document
    //   .getElementsByClassName("dr-gsearch-spinner")[0]
    //   .classList.remove("d-none");
    fetchTelevisitData(searchFormDetails);
    setSearchFormDetails({
      reset: true,
    });
    let param = searchFormDetails.televisitSearchParam
      ? searchFormDetails.televisitSearchParam
      : "";
    props.history.push("/doctor/televisits?gsearch=" + param);
  }

  // Reset Search
  function resetSearch(e) {
    e.preventDefault();
    fetchTelevisitData();
    setSearchFormDetails({
      reset: false,
    });
    document.getElementById("televisit-search-form").reset();
    props.history.push("/doctor/televisits");
  }

  const { loading, televisits, message } = gdata;
  const televisit_action = 1; // useSelector((state) => state.Doctor.action_type);

  // const propsUpdateTelevisitData = () => {
  //   if (!televisits && !props.singleTelevisit) {
  //     return;
  //   }
  //   if (televisits && props.singleTelevisit) {
  //     let televisitid = props.singleTelevisit.id;
  //     //Update record
  //     if (televisit_action === 2) {
  //       var index = televisits.findIndex((x) => x.id === televisitid);
  //       televisits[index] = props.singleTelevisit;
  //       dispatch(setTelevisitHistoryListing(televisits, ""));
  //     }
  //     let activeRow = document.querySelectorAll(
  //       `[data-row-id="${televisitid}"]`
  //     );
  //     if (activeRow[0]) {
  //       activeRow[0].scrollIntoView({ behavior: "smooth" });
  //       activeRow[0].classList.add("active");
  //       const timer = setTimeout(() => {
  //         activeRow[0].classList.remove("active");
  //       }, 3000);
  //       return () => clearTimeout(timer);
  //     }
  //     setGData({
  //       loading: false,
  //       televisits: televisits,
  //     });
  //   }
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h5>Member's Televisits</h5>
            </Col>
          </Row>
          <Row>
            <Col lg="9">
              <Form
                className="mb-2"
                inline
                onSubmit={handleSearch}
                id="televisit-search-form"
                className={`mb-2 ${Config.names.slugName}-search-form`}
              >
                <FormGroup className="mr-sm-2 mb-sm-0 mb-0">
                  <Input
                    type="text"
                    name="televisitSearchParam"
                    style={{ minWidth: "250px" }}
                    placeholder={props.t("Search...")}
                    onChange={(e) => {
                      let { value } = e.target;
                      setSearchFormDetails((prevState) => ({
                        ...prevState,
                        televisitSearchParam: value,
                      }));
                    }}
                  />
                </FormGroup>
                <Button onClick={handleSearch}>
                  <span className="dr-gsearch-spinner d-none">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                  </span>{" "}
                  {props.t(`Search`)}
                </Button>
                {searchFormDetails.reset ? (
                  <span
                    className={`ml-2 ${Config.names.slugName}-link cursor-pointer ${Config.names.slugName}-reset-search`}
                    onClick={resetSearch}
                  >
                    <i className="bx bx-reset font-size-22 align-middle mr-2"></i>
                  </span>
                ) : (
                  ""
                )}
              </Form>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TelevisitList
                    {...props}
                    televisitListMessage={message}
                    total={totalPosts}
                    getDataOnEnter={getDataOnEnter}
                    getUserData={getUserData}
                    currentPage={currentPage}
                    perPage={postsPerPage}
                    ajaxLoader={loading}
                  ></TelevisitList>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { televisit } = state.Doctor;
  const { singleTelevisit } = state.Doctor;
  const { message } = state.Doctor;
  return { televisit, singleTelevisit, message };
};

export default withRouter(
  connect(mapStatetoProps, {
    getTelevisit,
    setSingleTelevisit,
  })(withNamespaces()(Televisits))
);
