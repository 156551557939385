import { CREATE_COMPANY, UPDATE_COMPANY, GET_COMPANY, GET_COMPANY_LIST, SET_COMPANY_LIST, CREATE_COMPANY_SUCCESS, CREATE_COMPANY_FAILED, API_ERROR, SET_SINGLE_COMPANY, SET_COMPANY_LISTING } from './actionTypes';

const initialState = {
    createCompanyError: null, 
    message: null, 
    loading: false,
    action_type: null
}

const company = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_COMPANY:
            state = {
                ...state,
                company: action.payload,
                loading: true,
                createCompanyError: null
            }
            break;
        case UPDATE_COMPANY:
            state = {
                ...state,
                company: action.payload,
                loading: true,
                createCompanyError: null
            }
            break;
        case GET_COMPANY:
            state = {
                ...state,
                company: action.payload.result,
                loading: true,
                createCompanyError: null
            }
            break;
        case GET_COMPANY_LIST:
            state = {
                ...state,
                company: action.payload.result,
                loading: true,
                createCompanyError: null
            }
            break;
        case SET_SINGLE_COMPANY:
            state = {
                ...state,
                company: null,
                singleCompany: action.payload,
                loading: false,
                createCompanyError: null,
                action_type: action.action_type,
            }
            break;
        case SET_COMPANY_LIST:
            state = {
                ...state,
                loading: false,
                company: action.payload,
                message: action.message,
                createCompanyError: null,
                action_type: action.action_type,
            }
            break;
        case SET_COMPANY_LISTING:
            state = {
                ...state,
                loading: false,
                company: null,
                companyListing: action.payload,
                message: action.message,
                createCompanyError: null,
            }
            break;    
        case CREATE_COMPANY_SUCCESS:
            state = {
                ...state,
                loading: false,
                company: action.payload,
                message: action.message,
                createCompanyError: null,
                action_type: action.action_type,
            }
            break;
        case CREATE_COMPANY_FAILED:
            state = {
                ...state,
                company: null,
                createCompanyError: action.payload,
                loading: false,
            }
            break;
        case API_ERROR:
            state = { 
                ...state, 
                error: action.payload, 
                loading: false 
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default company;


