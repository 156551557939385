import { CREATE_PARTNER_AUTH, UPDATE_PARTNER_AUTH, GET_PARTNER_AUTH, GET_PARTNER_LIST_AUTH, SET_PARTNER_LIST_AUTH, CREATE_PARTNER_SUCCESS_AUTH, CREATE_PARTNER_FAILED_AUTH, API_ERROR, SET_SINGLE_PARTNER_AUTH, SET_PARTNER_LISTING_AUTH } from './actionTypes';

const initialState = {
    createPartnerAuthError: null, 
    message: null, 
    loading: false,
    action_type: null
}

const partnerauth = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PARTNER_AUTH:
            state = {
                ...state,
                partner: action.payload,
                loading: true,
                createPartnerAuthError: null
            }
            break;
        case UPDATE_PARTNER_AUTH:
            state = {
                ...state,
                partner: action.payload,
                loading: true,
                createPartnerAuthError: null
            }
            break;
        case GET_PARTNER_AUTH:
            state = {
                ...state,
                partner: action.payload.result,
                loading: true,
                createPartnerAuthError: null
            }
            break;
        case GET_PARTNER_LIST_AUTH:
            state = {
                ...state,
                partner: action.payload.result,
                loading: true,
                createPartnerAuthError: null
            }
            break;
        case SET_SINGLE_PARTNER_AUTH:
            state = {
                ...state,
                partner: null,
                singlePartner: action.payload,
                loading: false,
                createPartnerAuthError: null,
                action_type: action.action_type,
            }
            break;
        case SET_PARTNER_LIST_AUTH:
            state = {
                ...state,
                loading: false,
                partner: action.payload,
                message: action.message,
                createPartnerAuthError: null,
                action_type: action.action_type,
            }
            break;
        case SET_PARTNER_LISTING_AUTH:
            state = {
                ...state,
                loading: false,
                partner: null,
                partnerListing: action.payload,
                message: action.message,
                createPartnerAuthError: null,
            }
            break;    
        case CREATE_PARTNER_SUCCESS_AUTH:
            state = {
                ...state,
                loading: false,
                partner: action.payload,
                message: action.message,
                createPartnerAuthError: null,
                action_type: action.action_type,
            }
            break;
        case CREATE_PARTNER_FAILED_AUTH:
            state = {
                ...state,
                partner: null,
                createPartnerAuthError: action.payload,
                loading: false,
            }
            break;
        case API_ERROR:
            state = { 
                ...state, 
                error: action.payload, 
                loading: false 
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default partnerauth;


