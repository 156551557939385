import React, { useState } from 'react'
import { connect } from 'react-redux';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import App from './components/App';
import { Button, Row, Col } from 'reactstrap';
import { Config } from '../../../Config';

export const TBVideoCall = (props) => {
    const [startVC,setStartVC] = useState(false);
    let tempAuthUser = (JSON.parse(""+localStorage.getItem("auth_user"))) ?? {}
    console.log(props.location.search)
    console.log(new URLSearchParams(props.location.search))

    const params = new URLSearchParams(props.location.search)


    console.log(params.get('apikey'))
    console.log(params.get('session'))
    console.log(params.get('token'))

    if(Object.keys(tempAuthUser).length === 0){
        tempAuthUser['firstName'] = params.get('fname');
        tempAuthUser['lastName'] = params.get('lname');

    }

    return (
        <div>
            {startVC ? <App
                apiKey={params.get('apikey')}
                sessionId={params.get('session')}
                token={params.get('token')}
                loadingDelegate={<div>Loading...</div>}
                opentokClientUrl="https://static.opentok.com/v2/js/opentok.min.js"
            /> :
                <div className="start-tok-box">
                    <div className={`container my-5 px-5 card ${Config.names.slugName}-cst-box`}>

                        <Row>
                            <Col xl={4} lg={4} sm={12} className=" left-col tok-full-height d-flex flex-column justify-content-center align-items-center">
                                <img src="/static/media/logo-light.69e2d762.png" className="tok-logo" />
                                <h6 className="tok-title"><b>eHealthcare &amp; Therapy</b></h6>
                            </Col>
                            <Col xl={8} lg={8} sm={12} className="tok-full-height right-col" style={{}}>
                                <div className="tok-cst-border">
                                    <div className="container right-col-tok d-flex flex-column justify-content-center align-items-center" style={{ height: "100%", padding: "0 10%" }}>

                                        <div className="tok-text mb-5">
                                            <h4>
                                                Hello {tempAuthUser?.firstName + " " + tempAuthUser?.lastName},<br />
                                                {/* whenScheduled<br/> */}
                                            </h4>
                                            <p className="text-white">
                                                To start the video call click "Join"
                                                </p>
                                        </div>

                                        <button className={`btn btn-primary ${Config.names.slugName}-tok-btn`} onClick={() => { setStartVC(true) }}>Join</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TBVideoCall)
