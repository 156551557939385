import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
// import CardUser from "./card-user";
import CardWelcome from "./card-welcome";
import MiniWidget from "./mini-widget";
import Earning from "./earning";
import SalesAnalytics from "./sales-analytics";

//Include Both Helper File with needed methods
import { DctCustomHeader } from "../../helpers/Commons";

import axios from "axios";
import DateRange from "./date-range";
import MemberExport from "./memeber-export";
import MultiplePlanCSV from "./multi-plan-csv-upload";
import CompanyExport from "./CompanyExport";

const Dashboard = (props) => {
  const [gData, setGData] = useState();

  useEffect(() => {
    let url = process.env.REACT_APP_API_PATH + "/members/essentials";
    let bearer_token = localStorage.getItem("auth_token");
    const headers = {
      headers: {
        Authorization: bearer_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };

    axios.get(url, headers).then(function (response) {
      let data = response.data;
      setGData(data);
    });
  }, []);

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {DctCustomHeader("Dashboard")}

          {/* Card User */}
          {/* <CardUser/> */}

          <Row>
            {/* welcome card */}
            <CardWelcome />

            <Col xl="8">
              <Row>
                {/*mimi widgets */}
                <MiniWidget gData={gData?.result} />
              </Row>
            </Col>
          </Row>

          <Row>
            {/* earning */}
            <Earning />

            {/* sales anytics */}
            <SalesAnalytics gData={gData?.result} />
          </Row>

          <Row>
            <DateRange />
            <MemberExport />
          </Row>
          <Row>
            <MultiplePlanCSV />
            <CompanyExport />
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Dashboard;
