import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Config } from "../../../Config";
// import { AvForm, AvField } from "availity-reactstrap-validation";
import { DctIcon } from "../../Common/Icons/DctIcon";
import BookConsultationDetails from "./BookConsultationDetails";
import ChangePharmacy from "./ChangePharmacy";
import ConsultationPreview from "./ConsultationPreview";
import Axios from "axios";
import { dctAlert } from "../../../helpers/Commons";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { setSinglePharmacy } from "../../../store/actions";

const BookConsultation = (props) => {
  const modal = props.modal;
  const memberData = props?.memberData?.mdata;
  const setConsultationModal = props.setConsultationModal;
  const filterDependentsData = props.filterDependentsData;
  const [step1, setStep1] = useState(false);
  const [changePharmacy, setChangePharmacy] = useState(false);
  const [pharmacyErr, setPharmacyErr] = useState(false);
  const [stateErr, setStateErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [sweetAlert, setSweetAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [isSymptomsWarningWindow, setIsSymptomsWarningWindow] = useState(false);

  const [changeState, setChangeState] = useState({
    name: "",
    state: "",
    country: "",
  });
  const [pharmacy, setPharmacy] = useState({
    storeName: "",
    address1: "",
    city: "",
    state: "",
    P: "",
    F: "",
    Z: "",
    pharmacyId: "",
    memberId: memberData?.id,
  });
  const [consultationData, setConsultationData] = useState({
    memberId: "",
    dependentId: "",
    consultationType: "",
    consultationFor: "",
    country: "",
    phone: "",
    services: "",
    state: "",
    chiefComplaint: "",
    commonSymptoms: [],
    allOtherSymptoms: [],
    description: "",
    refillType: "no",
    medicationRefill: "",
    physicianLanguage: "English",
    timezoneAbbr: "",
    timezoneUTC: "",
    scheduleFrom: "",
    scheduleTo: "",
    pharmacyDetails: "",
  });

  const [searchData, setSearchData] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    cityErr: false,
    stateErr: false,
    zipCodeErr: false,
    memberId: memberData?.id,
  });
  const [preview, setPreview] = useState(false);
  const dispatch = useDispatch();
  const { singlePharmacy } = useSelector((state) => state.Pharmacy);
  const ConsultationSubmit = () => {
    setPharmacyErr(false);
    setStateErr(false);
    setCountryErr(false);
    if (
      !consultationData?.consultationType ||
      !consultationData?.consultationFor ||
      !consultationData?.phone ||
      !consultationData?.services ||
      !consultationData?.chiefComplaint ||
      !consultationData?.country ||
      consultationData?.description.trim().length == 0 ||
      (consultationData.country == "US" && !pharmacy?.pharmacyId) ||
      !consultationData?.state ||
      (consultationData?.refillType === "yes" &&
        consultationData?.medicationRefill.trim().length == 0)
    ) {
      if (
        consultationData?.refillType === "yes" &&
        consultationData?.medicationRefill.trim().length == 0
      ) {
        setPreview(false);
      }
      if (consultationData.country == "US" && !pharmacy?.pharmacyId) {
        setPharmacyErr(true);
      }
      if (!consultationData?.state) {
        setStateErr(true);
      }
      if (!consultationData?.country) {
        setCountryErr(true);
      }
      setPreview(false);
    } else {
      setPreview(true);
      setConsultationData({
        ...consultationData,
        pharmacyDetails: pharmacy,
      });
    }
  };
  const consultationDetails = (id) => {
    if (memberData?.id === id) {
      if (memberData.timezoneUTC) {
      }

      let defaultUTC = memberData.timezoneUTC
        ? memberData.timezoneUTC
        : moment.tz.guess();
      let defaultAbbr = memberData.timezoneAbbr
        ? memberData.timezoneAbbr
        : moment.tz(defaultUTC).format("z");
      let currentFromTime = moment().format("YYYY/MM/DD h:mm A");
      let fromTimeIso = moment(`${currentFromTime}`).tz(defaultUTC).format();
      let currentToTime = moment().add(2, "hours").format("YYYY/MM/DD h:mm A");
      let toDateIso = moment(`${currentToTime}`).tz(defaultUTC).format();
      setConsultationData({
        ...consultationData,
        country: memberData?.country,
        phone: memberData?.primaryPhone,
        consultationFor: id,
        memberId: id,
        state: memberData?.state,
        dependentId: "",
        timezoneAbbr: defaultAbbr,
        timezoneUTC: defaultUTC,
        scheduleFrom: fromTimeIso,
        scheduleTo: toDateIso,
        pharmacyDetails: pharmacy,
        patientName: memberData?.userId?.fullName,
        contactEmail: memberData?.userId?.email,
      });
      setChangeState({
        ...changeState,
        name: memberData?.userId?.fullName,
        state: memberData?.state,
        country: memberData?.country,
      });
    } else {
      let dependentData = memberData.dependent.filter((dependent) => {
        return dependent.id === id;
      });
      let defaultUTC = dependentData[0].timezoneUTC
        ? dependentData[0].timezoneUTC
        : moment.tz.guess();
      let defaultAbbr = dependentData[0].timezoneAbbr
        ? dependentData[0].timezoneAbbr
        : moment.tz(defaultUTC).format("z");
      let currentFromTime = moment().format("YYYY/MM/DD h:mm A");
      let fromTimeIso = moment(`${currentFromTime}`).tz(defaultUTC).format();
      let currentToTime = moment().add(2, "hours").format("YYYY/MM/DD h:mm A");
      let toDateIso = moment(`${currentToTime}`).tz(defaultUTC).format();
      setConsultationData({
        ...consultationData,
        country: dependentData[0]?.country,
        phone: dependentData[0]?.primaryPhone,
        consultationFor: id,
        memberId: memberData.id,
        state: dependentData[0]?.state,
        dependentId: id,
        timezoneAbbr: defaultAbbr,
        timezoneUTC: defaultUTC,
        scheduleFrom: fromTimeIso,
        scheduleTo: toDateIso,
        pharmacyDetails: pharmacy,
        patientName:
          dependentData[0]?.firstName + " " + dependentData[0]?.lastName,
        contactEmail: dependentData[0]?.email,
      });
      setChangeState({
        ...changeState,
        name: dependentData[0]?.firstName + dependentData[0]?.lastName,
        state: dependentData[0]?.state,
        country: dependentData[0]?.country,
      });
    }
  };
  useEffect(() => {
    // ! check dependent of MTM/DoseSpot type

    if (singlePharmacy && singlePharmacy?.memberId === memberData?.id) {
      setPharmacy({
        ...pharmacy,
        storeName: singlePharmacy?.storeName,
        address1: singlePharmacy?.address1,
        city: singlePharmacy?.city,
        state: singlePharmacy?.state,
        P: singlePharmacy?.P,
        F: singlePharmacy?.F,
        Z: singlePharmacy?.Z,
        pharmacyId: singlePharmacy?.pharmacyId,
      });
      setSearchData({
        ...searchData,
        city: singlePharmacy?.city,
        zipCode: singlePharmacy?.Z,
        state: singlePharmacy?.state,
        memberId: memberData?.id,
      });
    } else {
      if (memberData?.mtmPharmacies) {
        const pharmacyData =
          memberData?.mtmPharmacies[memberData?.mtmPharmacies?.length - 1];
        setPharmacy({
          ...pharmacy,
          storeName: pharmacyData?.pharmacy?.StoreName,
          address1: pharmacyData?.pharmacy?.Address1,
          city: pharmacyData?.pharmacy?.City,
          state: pharmacyData?.pharmacy?.State,
          Z: pharmacyData?.pharmacy?.ZipCode,
          P: pharmacyData?.pharmacy?.PrimaryPhone,
          F: pharmacyData?.pharmacy?.PrimaryFax,
          pharmacyId: pharmacyData?.pharmacy?.PharmacyId,
        });
        setSearchData({
          ...searchData,
          city: pharmacyData?.pharmacy?.City,
          zipCode: pharmacyData?.pharmacy?.ZipCode,
          state: pharmacyData?.pharmacy?.State,
          memberId: memberData?.id,
        });
      } else {
        if (memberData?.dosespotPharmacies) {
          const pharmacy =
            memberData?.dosespotPharmacies[
              memberData?.dosespotPharmacies?.length - 1
            ];
          if (pharmacy?.pharmacyId) {
            fetchDosespotPharmacy(pharmacy?.pharmacyId);
          }
        }
      }
    }
  }, []);
  // console.log(`singlePharmacy`, singlePharmacy);
  // console.log(`pharmacy`, pharmacy);
  useEffect(() => {
    dispatch(setSinglePharmacy(pharmacy));
  }, [pharmacy]);

  const fetchDosespotPharmacy = (pharmacyId) => {
    const options = {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
    };
    let url = `${process.env.REACT_APP_API_PATH}/pharmacy/${pharmacyId}`;
    Axios.get(url, options)
      .then((response) => {
        setPharmacy({
          ...pharmacy,
          storeName: response?.data?.data?.StoreName,
          address1: response?.data?.data?.Address1,
          city: response?.data?.data?.City,
          state: response?.data?.data?.State,
          Z: response?.data?.data?.ZipCode,
          P: response?.data?.data?.PrimaryPhone,
          F: response?.data?.data?.PrimaryFax,
          pharmacyId: pharmacyId,
        });
        setSearchData({
          ...searchData,
          city: response?.data?.data?.City,
          zipCode: response?.data?.data?.ZipCode,
          state: response?.data?.data?.State,
          memberId: memberData?.id,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <>
      <Modal centered={true} size="lg" isOpen={modal} scrollable={true}>
        <ModalHeader
          toggle={() => {
            setConsultationModal(!modal);
          }}
        >
          Book Consultation
        </ModalHeader>
        <ModalBody>
          <Container>
            <AvForm className="form-horizontal">
              {/* consultationType & consultationFor */}
              <Row>
                <Col lg={6}>
                  <div className="form-group">
                    <Label>
                      {props.t(`Consultation Type`)}
                      <span
                        style={{
                          color: "#f46a6a",
                          fontSize: "18px",
                          marginLeft: "3px",
                        }}
                      >
                        *
                      </span>
                    </Label>
                    <AvField
                      type="select"
                      name="consultationType"
                      required
                      onChange={(e) =>
                        setConsultationData({
                          ...consultationData,
                          consultationType: e.target.value,
                        })
                      }
                    >
                      <option value="">
                        {props.t("Select Consultation Type")}
                      </option>
                      <option value="video">{props.t("Video Call")}</option>
                      <option value="phone">{props.t("Audio Call")}</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <Label>
                      {props.t(`Consultation For`)}
                      <span
                        style={{
                          color: "#f46a6a",
                          fontSize: "18px",
                          marginLeft: "3px",
                        }}
                      >
                        *
                      </span>
                    </Label>
                    <AvField
                      type="select"
                      required
                      name="consultationFor"
                      onChange={(e) => {
                        consultationDetails(e.target.value);
                      }}
                      id="select"
                    >
                      <option disabled value="">
                        {props.t("Select Consultation For")}
                      </option>
                      <option value={memberData?.id}>
                        {props.t(`${memberData?.userId?.fullName} (Primary)`)}
                      </option>
                      {filterDependentsData.map((dependent, index) => (
                        <option
                          disabled={
                            moment().diff(
                              moment(dependent?.dob, "YYYY-MM-DD"),
                              "years",
                              false
                            ) > 18 || dependent.status == "inactive"
                          }
                          key={index}
                          value={dependent?.id}
                          title={
                            moment().diff(
                              moment(dependent?.dob, "YYYY-MM-DD"),
                              "years",
                              false
                            ) > 18
                              ? props.t(
                                  `This dependent is over 18+. To schedule a consultation, they will need to log into their own account using the email associated with their profile.`
                                )
                              : "" || dependent.status == "inactive"
                              ? props.t(
                                  `This dependent is currently inactive , so cant't schedule a consultation.`
                                )
                              : ""
                          }
                        >
                          {props.t(
                            `${dependent?.firstName} ${dependent?.lastName} (${dependent?.relation})`
                          )}
                        </option>
                      ))}
                    </AvField>
                  </div>
                </Col>
              </Row>
              {/* phone  */}
              <Row>
                <Col lg={6} className="mt-3">
                  <div className="form-group">
                    <Label>
                      {props.t(`Phone`)}
                      <span
                        style={{
                          color: "#f46a6a",
                          fontSize: "18px",
                          marginLeft: "3px",
                        }}
                      >
                        *
                      </span>
                    </Label>

                    <PhoneInput
                      disabled
                      required
                      inputProps={{ name: "Phone", required: true }}
                      country={"us"}
                      value={consultationData?.phone}
                      enableSearch={true}
                      onlyCountries={["us", "mx"]}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group">
                    <Label>
                      {props.t(
                        `What would you have done if you didn't have the service?`
                      )}
                      <span
                        style={{
                          color: "#f46a6a",
                          fontSize: "18px",
                          marginLeft: "3px",
                        }}
                      >
                        *
                      </span>
                    </Label>
                    <AvField
                      type="select"
                      name="service"
                      required
                      onChange={(e) =>
                        setConsultationData({
                          ...consultationData,
                          services: e.target.value,
                        })
                      }
                    >
                      <option value=" ">{props.t("Select service")}</option>
                      <option value="Visit Primary Care Physician">
                        {props.t("Visit Primary Care Physician")}
                      </option>
                      <option value="Go To Urgent Care">
                        {props.t("Go To Urgent Care")}
                      </option>
                      <option value="Go To Emergency Room">
                        {props.t("Go To Emergency Room")}
                      </option>
                      <option value="Nothing">{props.t("Nothing")}</option>
                    </AvField>
                  </div>
                </Col>
              </Row>
              {/* Country & state */}
              <Row>
                <Col lg={6}>
                  <div className="mt-3 mb-1 font-weight-medium">
                    {props.t(
                      `Is ${changeState?.name} currently in ${changeState?.state}?`
                    )}
                  </div>
                  <div className="d-flex">
                    <div className="form-check mr-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="state"
                        id="stateYes"
                        value="yes"
                        defaultChecked
                        onChange={() => {
                          setStep1(false);
                          setConsultationData({
                            ...consultationData,
                            country: changeState.country,
                            state: changeState.state,
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="stateYes">
                        {props.t(`Yes`)}
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="state"
                        id="stateNo"
                        value="no"
                        onChange={() => setStep1(true)}
                      />
                      <label className="form-check-label" htmlFor="stateNo">
                        {props.t(`No`)}
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              {step1 && (
                <Row>
                  <Col lg={6}>
                    <div className="form-group mt-3">
                      <label htmlFor="state">{props.t(`Select Country`)}</label>
                      <CountryDropdown
                        value={consultationData?.country}
                        labelType="short"
                        valueType="short"
                        whitelist={["US", "MX"]}
                        className={`${
                          countryErr && "border border-danger"
                        } form-control`}
                        onChange={(val) => {
                          setConsultationData({
                            ...consultationData,
                            country: val,
                            state: "",
                          });
                          setCountryErr(false);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div
                      className={`form-group mt-3 ${Config.names.slugName}-select-box`}
                      id="select-state"
                    >
                      <label htmlFor="state">{props.t(`Select State`)}</label>
                      <RegionDropdown
                        required
                        country={consultationData?.country}
                        countryValueType="short"
                        labelType="full"
                        valueType="short"
                        value={consultationData?.state}
                        // classes=""
                        className={`${
                          stateErr && "border border-danger"
                        } form-control`}
                        onChange={(val) => {
                          setConsultationData({
                            ...consultationData,
                            state: val,
                          });
                          setStateErr(false);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {/* Details */}
              <BookConsultationDetails
                props={props}
                consultationData={consultationData}
                setConsultationData={setConsultationData}
                setIsSymptomsWarningWindow={setIsSymptomsWarningWindow}
              />
              {/* language */}
              <Row>
                <Col lg={6}>
                  <div className="mt-3 mb-1 font-weight-medium">
                    {props.t("What language should the physician speak?")}
                  </div>
                  <div className="d-flex">
                    <div className="form-check mr-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="schedule_physicianLanguages"
                        id="English"
                        value="English"
                        defaultChecked
                        onChange={(e) =>
                          setConsultationData({
                            ...consultationData,
                            physicianLanguage: e.target.value,
                          })
                        }
                      />
                      <label className="form-check-label" htmlFor="English">
                        {props.t(`English`)}
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="schedule_physicianLanguages"
                        id="Español"
                        value="Español"
                        onChange={(e) =>
                          setConsultationData({
                            ...consultationData,
                            physicianLanguage: e.target.value,
                          })
                        }
                      />
                      <label className="form-check-label" htmlFor="Español">
                        {props.t(`Español`)}
                      </label>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <Button disabled className="btn btn-success my-3" style={{cursor: 'not-allowed'}}>
                    {props.t("Consultation now (within 2hrs)")}
                  </Button>
                </Col>
              </Row>
              {/* Pharamcy */}
              <Row>
                <Col lg={12}>
                  <div className="my-3">
                    <div>
                      <span className="font-size-18">
                        {props.t("Preferred Pharmacy")}
                      </span>

                      <span
                        style={{
                          color: "#f46a6a",
                          fontSize: "18px",
                          marginLeft: "3px",
                        }}
                      >
                        *
                      </span>
                      {consultationData.country == "US" && pharmacyErr && (
                        <span className="text-danger ml-3">
                          {props.t("Pharmacy is required.")}
                        </span>
                      )}
                    </div>
                    {consultationData.country !== "US" ? (
                      <p>
                        {props.t(
                          "This Pharmacy module is currently not available for Non US country."
                        )}
                      </p>
                    ) : (
                      <>
                        {pharmacy.storeName ? (
                          <>
                            <p className="text-muted font-weight-13 ">
                              <b>{pharmacy?.storeName}</b>
                              <br />
                              <span className="text-justify">
                                {pharmacy?.address1}
                                <br />
                                <span>
                                  {pharmacy?.city},{pharmacy?.state}
                                </span>
                                <br />
                                <span title="Zip"> {props.t("Z")}: </span>
                                {pharmacy?.Z} <br />
                                <span title="Phone">{props.t("P")}: </span>
                                {pharmacy?.P}
                                <br />
                                <span title="Fax">{props.t("F")}: </span>{" "}
                                {pharmacy?.F}
                              </span>
                            </p>{" "}
                            <button
                              type="button"
                              className="btn btn-primary mt-2"
                              onClick={() => {
                                setChangePharmacy(true);
                              }}
                            >
                              {props.t("Change Pharmacy")}
                            </button>{" "}
                            <div className="my-3">
                              {successMsg ? dctAlert(1, successMsg) : ""}
                            </div>
                          </>
                        ) : (
                          <>
                            <p>{props.t("No Pharmacy selected")}</p>
                            <button
                              type="button"
                              className="btn btn-primary mt-2"
                              onClick={() => {
                                setChangePharmacy(true);
                              }}
                            >
                              {props.t("Add Pharmacy")}
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <ChangePharmacy
                setChangePharmacy={setChangePharmacy}
                searchData={searchData}
                setSearchData={setSearchData}
                setPharmacy={setPharmacy}
                pharmacy={pharmacy}
                modal={changePharmacy}
                setSuccessMsg={setSuccessMsg}
                props={props}
              />
              {/* consultation preview */}
              <ConsultationPreview
                setPreview={setPreview}
                modal={preview}
                props={props}
                setConsultationModal={setConsultationModal}
                consultationData={consultationData}
              />
              {/* Button */}
              <Row>
                <Col lg={12}>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setSweetAlert(true)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                      onClick={() => {
                        ConsultationSubmit();
                      }}
                    >
                      <span className="dr-mform-spinner d-none">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                      </span>{" "}
                      Preview
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
            {sweetAlert && (
              <SweetAlert
                title={props.t("Are you sure?")}
                warning
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="secondary"
                onConfirm={() => {
                  setConsultationModal(!modal);
                }}
                onCancel={() => {
                  setSweetAlert(false);
                }}
              >
                {props.t("You want to cancel the consultation.")}
              </SweetAlert>
            )}
            {isSymptomsWarningWindow ? (
              <SweetAlert
                show={true}
                closeOnClickOutside={false}
                title={props.t(`Warning!`)}
                danger
                confirmBtnBsStyle="danger"
                confirmBtnText={props.t("I Understand")}
                onConfirm={() => {
                  setIsSymptomsWarningWindow(false);
                }}
              >
                <p>
                  {props.t(
                    `You have selected a symptom that is sometimes associated with a life-threatening condition.`
                  )}{" "}
                  <strong className="text-danger">
                    {props.t(`This service is not for emergencies.`)}
                  </strong>
                  {props.t(`If you believe this is an emergency please`)}{" "}
                  <strong className="text-danger">dial 911 right away.</strong>
                </p>
              </SweetAlert>
            ) : null}
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};
export default BookConsultation;
