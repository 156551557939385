import React, { Fragment, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import Dct Icon
import { DctIcon } from "../../components/Common/Icons/DctIcon";
import { Config } from "../../Config";

const UpcomingAppointments = (props) => {
  // const { authMemberId, authUser } = useSelector((state) => state.Login);

  useEffect(() => {}, []);

  return (
    <Fragment>
      <Card className="p-4">
        <CardBody className="p-0">
          <Row>
            <Col md={3} className={`${Config.names.slugName}-dashboard-item-list`}>
              <div className={`${Config.names.slugName}-h-border bg-soft-primary ml-2 ${Config.names.slugName}-desktop`}></div>
              <Link
                to="/member/schedule-center"
                className={`text-center ${Config.names.slugName}-icon-link`}
              >
                <p>
                  <DctIcon name={`${Config.names.slugName}-speak-icon`} size={60} />
                </p>
                <div className="">{props.t("Speak to a Doctor")}</div>
              </Link>
            </Col>
            <Col md={3} className={`${Config.names.slugName}-dashboard-item-list`}>
              <div className={`${Config.names.slugName}-h-border bg-soft-primary ml-2 ${Config.names.slugName}-desktop`}></div>
              <Link
                to="/member/behavioral-health"
                className={`text-center ${Config.names.slugName}-icon-link`}
              >
                <p>
                  <DctIcon name={`${Config.names.slugName}-therapist-icon`} size={50} />
                </p>
                <div className="">{props.t("Speak to a Therapist")}</div>
              </Link>
            </Col>
            <Col md={3} className={`${Config.names.slugName}-dashboard-item-list`}>
              <div className={`${Config.names.slugName}-h-border bg-soft-primary ml-2 ${Config.names.slugName}-desktop`}></div>
              <Link
                to="/member/health-records"
                className={`${Config.names.slugName}-icon-link text-center`}
              >
                <p>
                  <DctIcon name={`${Config.names.slugName}-health-record-icon`} size={50} />
                </p>
                <div className="">{props.t("Health Profile")}</div>
              </Link>
            </Col>
            <Col md={3} className={`${Config.names.slugName}-dashboard-item-list`}>
              <Link
                to="/member/my-account/dependents"
                className={`text-center ${Config.names.slugName}-icon-link`}
              >
                <p>
                  <DctIcon name={`${Config.names.slugName}-family-icon`} size={50} />
                </p>
                <div className="">{props.t("Add a Family Member")}</div>
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default withRouter(withNamespaces()(UpcomingAppointments));
