import React, { Fragment, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import {
  Button,
  Col,
  Label,
  Row,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Progress,
  ModalBody,
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";
import scrollToComponent from "react-scroll-to-component";

import { dctAlert, dctClsAddRemove } from "../../../helpers/Commons";
import {
  dctPutDosespotData,
  dctPostDosespotData,
  dctGetDosespotItems,
} from "../../../helpers/backend_helper";

// import { Modal } from "./../../DoctegrityModal/DoctegrityModal";
import { Modal } from "../../DoctegrityModal/DoctegrityModal";
import axios from "axios";
import { Config } from "../../../Config";
// const qs = require('qs');

function Documents(props) {
  let DCformSection = React.createRef();

  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState(false);
  const pathId = props.pathId;

  const dispatch = useDispatch();
  const { authMemberId, auth_token, authUser } = useSelector(
    (state) => state.Login
  );

  const [openDocument, setOpenDocument] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openDocUploader, setOpenDocUploader] = useState(true);
  const [modal, setModal] = useState(false);
  const [gImg, setGImg] = useState({
    src: "",
    alt: "",
  });

  const [documents, setDocuments] = useState({
    document: "",
    title: "",
    description: "",
  });
  const [selectedFiles, setselectedFiles] = useState([]);
  const [uploadPercent, setuploadPercent] = useState({ percent: 0 });

  const [_documentList, _setDocumentList] = useState({
    data: [],
    loading: true,
  });
  const [_singleDocument, _setSingleDocument] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  useEffect(() => {
    if (props.location.hash === "#document") {
      setOpenDocument(true);
    }
    getDocumentById();
  }, []);

  const getDocumentById = async () => {
    let url = `member/${pathId}/document?populate=false`;
    if (props.patientType === "dependent") {
      url = `dependent/${props.activePatientId}/document?populate=false`;
    }
    const response = await dctGetDosespotItems(url, "").then((data) => data);

    let responseData = response;
    if (responseData && responseData.length > 0) {
      _setDocumentList({
        data: responseData,
        loading: false,
      });
    } else {
      _setDocumentList({
        data: null,
        loading: false,
      });
    }
  };

  const handleDocumentSubmit = async (event, values) => {
    setFormErrorMsg(false);
    let doc = values.doc ? values.doc[0] : "";
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth_token,
        PartnerAuth: process.env.REACT_APP_PARTNER_AUTH_TOKEN,
      },
      onUploadProgress: (progressEvent) => {
        var percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percent >= 100) {
          setuploadPercent({ percent: 100 });
        } else {
          setuploadPercent({ percent });
        }
      },
    };

    dctClsAddRemove("dr-search-spinner", "d-none", "remove");

    let bodyFormData = new FormData();
    bodyFormData.append("title", values.title);
    bodyFormData.append("description", values.description);
    bodyFormData.append("memberId", values.memberId);
    if (values.patientType === "dependent") {
      bodyFormData.append("dependentId", values.dependentId);
    }
    if (doc) {
      bodyFormData.append("document", doc);
    }

    dctClsAddRemove("dr-search-spinner", "d-none", "remove");

    let response = "";
    if (values.action === "edit" && values.id) {
      let docId = values.id;
      let url = `${process.env.REACT_APP_API_PATH}/document/${docId}`;
      response = await dctPutDosespotData(url, bodyFormData, headers).then(
        (data) => data
      );
    } else {
      // For create action
      let url = `${process.env.REACT_APP_API_PATH}/document`;
      response = await dctPostDosespotData(url, bodyFormData, headers).then(
        (data) => data
      );
    }

    if (response) {
      if (response.id) {
        dctClsAddRemove("dr-search-spinner", "d-none", "add");
        let message = props.t(`Document created succuessfully.`);
        setSuccessMsg(message);
        setFormErrorMsg("");
        //Call the Get Document method
        getDocumentById();
        setOpenEditForm(!openEditForm);
      }
      if (response.status >= 400 || !response.id || response.id == "") {
        dctClsAddRemove("dr-search-spinner", "d-none", "add");
        setFormErrorMsg(
          response && response.data
            ? response.data.message
            : "Something went wrong. Please try again."
        );
      }
    } else {
      dctClsAddRemove("dr-search-spinner", "d-none", "add");
      setFormErrorMsg(
        response && response.data
          ? response.data.message
          : "Something went wrong. Please try again."
      );
      setSuccessMsg("");
    }
  };

  const renderDocThumb = (data) => {
    if (!data) return;

    if (data.docType === "application/pdf") {
      return (
        <h2 className={`${Config.names.slugName}-upload-preview-icon mb-0`}>
          <a href={data.docUrl} download>
            <i className="bx bxs-file-pdf mr-1"></i>
          </a>
        </h2>
      );
    } else {
      return (
        <img
          style={{ cursor: "pointer" }}
          data-dz-thumbnail=""
          height="100"
          className="avatar-sm rounded bg-light"
          alt={data.title}
          src={data.docUrl}
          onClick={() => {
            setGImg({
              src: data.docUrl,
              alt: data.title,
            });
            setModal(true);
          }}
        />
      );
    }
  };
  const documentItemsBody = (_documentList) => {
    return _documentList && _documentList.length > 0 ? (
      _documentList.map((data, index) => {
        return (
          <Tr key={index} data-row-id={data.id}>
            <Td>{renderDocThumb(data)}</Td>
            <Td>{data.title}</Td>
            <Td>{data.description}</Td>
            {/* <Td>
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      editDocument(data);
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                  </span>
                </li>
              </ul>
            </Td> */}
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="4" className="text-center"></Td>
      </Tr>
    );
  };

  const editDocument = (data) => {
    setOpenDocument(true);
    setOpenEditForm(true);
    setOpenDocUploader(false);
    setDocuments({
      docUrl: data && data.docUrl ? data.docUrl : "",
      title: data && data.title ? data.title : "",
      description: data && data.description ? data.description : "",
    });
    _setSingleDocument(data);

    // if (openEditForm) {
    //   scrollToComponent(DCformSection.current, {
    //     offset: 0,
    //     align: "middle",
    //     duration: 500,
    //     ease: "inExpo",
    //   });
    // }
  };

  const onEntered = () => {
    scrollToComponent(DCformSection.current, {
      offset: 0,
      align: "middle",
      duration: 500,
      ease: "inExpo",
    });
  };
  const { percent } = uploadPercent;

  return (
    <Fragment>
      {errorMsg ? dctAlert(3, errorMsg) : ""}
      {successMsg ? dctAlert(1, successMsg) : ""}

      {_documentList?.data?.length !== undefined ? (
        <div className={`${Config.names.slugName}-sm--15`}>
          <Row>
            <Col lg={12} className="mb-3">
              <div className="table-responsive">
                <Table
                  className={`table table-centered table-nowrap table-hover ${Config.names.slugName}-table ${Config.names.slugName}-odd-even`}
                >
                  <Thead className="thead-light">
                    <Tr>
                      <Th scope="col" style={{ width: "200px" }}>
                        {props.t("Document")}
                      </Th>
                      <Th scope="col">{props.t("Title")}</Th>
                      <Th scope="col" style={{ width: "200px" }}>
                        {props.t("Description")}
                      </Th>
                      {/* <Th scope="col" style={{ width: "70px" }}>
                        {props.t("Action")}
                      </Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>{documentItemsBody(_documentList?.data)}</Tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <span className="d-flex justify-content-center text-danger">
          You have no documents on record.
        </span>
      )}
      {modal && (
        <Modal
          centered={true}
          size="md"
          isOpen={modal}
          scrollable={false}
          toggle={() => {
            setModal(!modal);
          }}
        >
          <img height="auto" alt={gImg.alt} src={gImg.src} />
        </Modal>
      )}
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(Documents))
);
