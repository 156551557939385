export const CREATE_RXPRESCRIPTION = "CREATE_RXPRESCRIPTION";
export const UPDATE_RXPRESCRIPTION = "UPDATE_RXPRESCRIPTION";
export const GET_RXPRESCRIPTION = "GET_RXPRESCRIPTION";
export const GET_RXPRESCRIPTION_LIST = "GET_RXPRESCRIPTION_LIST";
export const SET_RXPRESCRIPTION_LIST = "SET_RXPRESCRIPTION_LIST";
export const SET_RXPRESCRIPTION_LISTING = "SET_RXPRESCRIPTION_LISTING";
export const CREATE_RXPRESCRIPTION_SUCCESS = "CREATE_RXPRESCRIPTION_SUCCESS";
export const CREATE_RXPRESCRIPTION_FAILED = "CREATE_RXPRESCRIPTION_FAILED";
export const SET_SINGLE_RXPRESCRIPTION = "SET_SINGLE_RXPRESCRIPTION";
export const API_ERROR = "API_ERROR";
