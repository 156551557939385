import React, { Fragment, useEffect, useState } from "react";
import { connect, shallowEqual, useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { Col, Alert } from "reactstrap";

import Tabs from "react-responsive-tabs";
import "react-responsive-tabs/styles.css";

import PersonalInformation from "../../../components/Member/HealthRecords/PersonalInformation";
import MedicationRecord from "../../../components/Member/HealthRecords/MedicationRecord";
import MedicationAllergy from "../../../components/Member/HealthRecords/MedicationAllergy";
import MedicalHistory from "../../../components/Member/HealthRecords/MedicalHistory";
import Documents from "../../../components/Member/HealthRecords/Documents";
import { loadingMessage, capitalizeWords } from "../../../helpers/Commons";
import { Config } from "../../../Config";

function Records(props) {
  const [activeTab, setActiveTab] = useState(1);

  const dispatch = useDispatch();
  const { authMemberId } = useSelector((state) => state.Login);
  let memberObj = [];
  let member = [];
  // let dependents = [];
  memberObj = useSelector((state) => state.Member, shallowEqual);
  const { memberAndDependents, tabList } = memberObj;
  member = memberAndDependents ? memberAndDependents : [];
  // dependents = memberAndDependents ? memberAndDependents.dependent : [];

  useEffect(() => {
    if (props.location.pathname) {
      let split_url = props.location.pathname.split("/");
      let tabId = typeof split_url[4] !== "undefined" ? split_url[4] : "1";
      setActiveTab(tabId);
    }
  }, []);
  const dependentStatusMsg = (data) => {
    if (!data) return;
    if (data.status !== "active") {
      return (
        <Alert color="primary">
          {props.t(
            `This dependent is not activate yet. Please activate to continue.`
          )}
        </Alert>
      );
    }

    if (data.dependentType && data.age >= 18) {
      return (
        <Alert color="primary">
          {props.t(
            `This dependent is over 18 and must manage their own records.`
          )}
        </Alert>
      );
    }
  };
  function getDependentsTabs(tabList) {
    return tabList && tabList.length > 0
      ? tabList.map((mdata, index) => {
          let patientType =
            typeof mdata.dependentType === "undefined" ? "member" : "dependent";

          let isOver18 = mdata.dependentType && mdata.age >= 18 ? true : false;
          return {
            title: `${capitalizeWords(mdata.firstName)} ${capitalizeWords(mdata.lastName)}`,
            getContent: () =>
              mdata.status === "active" && !isOver18 ? (
                <Fragment>
                  <PersonalInformation
                    {...props}
                    member={member}
                    patientType={patientType}
                    activePatientId={mdata.id}
                    tabIndex={index + 1}
                  />
                  <MedicationRecord
                    {...props}
                    member={member}
                    patientType={patientType}
                    activePatientId={mdata.id}
                    tabIndex={index + 1}
                  />

                  <MedicationAllergy
                    {...props}
                    member={member}
                    patientType={patientType}
                    activePatientId={mdata.id}
                    tabIndex={index + 1}
                  />
                  <MedicalHistory
                    {...props}
                    member={member}
                    patientType={patientType}
                    activePatientId={mdata.id}
                    tabIndex={index + 1}
                  />
                  <Documents
                    {...props}
                    member={member}
                    patientType={patientType}
                    activePatientId={mdata.id}
                    tabIndex={index + 1}
                  />
                </Fragment>
              ) : (
                dependentStatusMsg(mdata)
              ),
            key: index + 1,
            showMore: true,
            transformWidth: 768,
            tabClassName: "tab btn btn-outline-primary",
            panelClassName: "panel",
          };
        })
      : null;
  }

  const checkPatientStatus = (e) => {
  };

  const tabChange = (tabId) => {
    if (tabId) {
      props.history.push(`/member/health-records/id/${tabId}`);
    }
  };

  return (
    <Fragment>
      <Col lg={12}>
        <h5 className="mt-2">{props.t(`Viewing records for`)}</h5>
        <p className="mt-2 mb-4 text-muted">
          <span className="text-danger">*</span>{" "}
          {props.t(`Dependent is over 18 and must manage their own records.`)}
        </p>

        {tabList && tabList.length > 0 ? (
          <div className={`${Config.names.slugName}-tab-container mb-4`}>
            <Tabs
              items={getDependentsTabs(tabList)}
              selectedTabKey={activeTab}
              onChange={tabChange}
              beforeChange={checkPatientStatus}
            />
          </div>
        ) : (
          <div className={`${Config.names.slugName}-page-spinner`}>
            {loadingMessage("Loading....")}
          </div>
        )}
      </Col>
      {/* <Col lg={12}>
        <div className="mt-2">
          <Link to="/member/my-account/dependents" className="font-size-20">
            {" "}
            <i className="mdi mdi-plus-circle-outline mr-2"></i>
            <span className="font-size-16 ${Config.names.slugName}-text-default">
              {props.t(`Add a new Family Member`)}
            </span>
          </Link>
        </div>
      </Col> */}
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(Records))
);
